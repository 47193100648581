/* Copyright */
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, SelectChangeEvent } from "@mui/material";
import {
  BackendFactory,
  CognitoAccountStatus,
  Maybe,
  Role,
  RoleIdentifier,
  SupportedLanguage,
  Team,
  User,
} from "@sade/data-access";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import {
  LanguageMap,
  UserRealmRole,
  UserStatusNames,
  UserStatuses,
  getAllRoles,
  getAssignableRoles,
} from "../../../utils/utils";
import Loading from "../../loading/loading";
import AddRealmAccessDialog from "../add-realm-access-dialog/add-realm-access-dialog";
import DeleteAccountDialog from "../delete-account-dialog/delete-account-dialog";
import DisableAccountDialog from "../disable-account-dialog/disable-account-dialog";
import ReEnableAccountDialog from "../enable-account-dialog/enable-account-dialog";
import RevokeRealmAccessDialog from "../revoke-realm-access-dialog/revoke-realm-access-dialog";
import UnsavedChangesDialog from "../unsaved-changes-dialog/unsaved-changes-dialog";
import AccountDetailsBasicInformation from "./account-details-basic-information";
import AccountDetailsDialogFooter from "./account-details-dialog-footer";
import AccountDetailsRealmInformation from "./account-details-realm-information";
import AccountDetailsTeamsInformation from "./account-details-teams-information";

type AccountDetailsDialogProps = {
  open: boolean;
  handleClose: () => void;
  userId: string;
  reFetchUserData: () => void;
  everThere: boolean;
};

type UpdatedRealmRole = {
  realmId: string;
  role: RoleIdentifier;
};

const AccountDetailsDialog: React.FC<AccountDetailsDialogProps> = ({
  open,
  handleClose,
  userId,
  reFetchUserData,
  everThere,
}) => {
  const backend = BackendFactory.getOrganizationBackend();
  const { currentOrganization } = useAuthenticatedUser();
  const [isLoadingUser, setIsLoadingUser] = React.useState<boolean>(false);
  const [isLoadingUserRealms, setIsLoadingUserRealms] = React.useState<boolean>(false);
  const [isLoadingUserTeams, setIsLoadingUserTeams] = React.useState<boolean>(false);
  const [isLoadingAssignableRoles, setIsLoadingAssignableRoles] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [userData, setUserData] = React.useState<Maybe<User>>(undefined);
  const [userRealmAccessData, setUserRealmAccessData] = React.useState<UserRealmRole[]>([]);
  const [userTeams, setUserTeams] = React.useState<Maybe<Team[]>>(undefined);
  const { showSnackbar } = useSnackbar();
  const [addRealmAccessDialogOpen, setAddRealmAccessDialogOpen] = React.useState(false);
  const [assignableRoles, setAssignableRoles] = React.useState<Role[]>([]);
  const [selectedRealm, setSelectedRealm] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");
  const [revokeRealmAccessDialogOpen, setRevokeRealmAccessDialogOpen] = React.useState(false);
  const [selectedRealmForRevoke, setSelectedRealmForRevoke] = React.useState<Maybe<UserRealmRole>>(undefined);
  const [updatedRealmRoles, setUpdatedRealmRoles] = React.useState<UpdatedRealmRole[]>([]);
  const [reEnableAccountDialogOpen, setReEnableAccountDialogOpen] = React.useState(false);
  const [disableAccountDialogOpen, setDisableAccountDialogOpen] = React.useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = React.useState(false);
  const [shouldFetchData, setShouldFetchData] = React.useState<boolean>(false);
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState(false);
  const [allRoles, setAllRoles] = React.useState<Maybe<Role[]>>(undefined);

  React.useEffect(() => {
    if (shouldFetchData) {
      // This timeout here is supposed to be a temporary solution.
      // For some reason (maybe some caching somewhere), re-fetching account data immediately after
      // any changes are made does not return changes just made
      setTimeout(() => {
        reFetchUserData();
      }, 2000);

      setShouldFetchData(false);
    }
  }, [shouldFetchData, reFetchUserData]);

  React.useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      setIsLoadingUser(true);
      try {
        const user = await backend.getUser(userId, true);
        setUserData(user);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingUser(false);
      }
    };

    if (userId) {
      fetchUser();
    }
  }, [userId, backend]);

  const getUserRealms = React.useCallback(() => {
    const fetchUserRealms = async (): Promise<void> => {
      setIsLoadingUserRealms(true);
      setUserRealmAccessData([]);
      try {
        const roles = await getAllRoles();
        setAllRoles(roles);
        const userRealms = await userData?.getOrganizations();
        if (userRealms) {
          for (const realm of userRealms) {
            const organizationRoles = await userData?.getOrganizationRoles(realm.getId());
            if (organizationRoles?.length) {
              const assignableRoles = await getAssignableRoles(realm.getId());
              const userRealmRole: UserRealmRole = {
                realmId: realm.getId(),
                realmName: realm.getFullName(),
                isHomeOrg: realm.getId() === userData?.getHomeOrganizationId(),
                // There can only be one role per organization
                role: organizationRoles[0].identifier,
                assignableRoles,
              };
              setUserRealmAccessData((oldState) => [...oldState, userRealmRole]);
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingUserRealms(false);
      }
    };

    const fetchUserTeams = async (): Promise<void> => {
      setIsLoadingUserTeams(true);
      try {
        const teams = await userData?.getTeams();
        setUserTeams(teams);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingUserTeams(false);
      }
    };

    if (!everThere) {
      fetchUserRealms();
    } else {
      fetchUserTeams();
    }
  }, [userData, everThere]);

  //#region Add Realm Access

  React.useEffect(() => {
    const fetchAssignableRoles = async (): Promise<void> => {
      if (selectedRealm) {
        setIsLoadingAssignableRoles(true);
        const roles = await getAssignableRoles(selectedRealm);
        setAssignableRoles(roles);
        setIsLoadingAssignableRoles(false);
      }
    };

    if (selectedRealm) fetchAssignableRoles();
  }, [selectedRealm]);

  React.useEffect(() => {
    if (userData) {
      setUserRealmAccessData([]);
      getUserRealms();
    }
  }, [userData, getUserRealms]);

  const clearNewRealmAccessState = (): void => {
    setSelectedRealm("");
    setSelectedRole("");
  };

  const handleOpenAddRealmAccessDialog = (): void => {
    setAddRealmAccessDialogOpen(true);
  };

  const handleCloseAddRealmAccessDialog = (): void => {
    clearNewRealmAccessState();
    setAddRealmAccessDialogOpen(false);
  };

  const handleAddRealmAccessClick = async (realm: string, selectedRole: string): Promise<void> => {
    setIsSaving(true);
    try {
      const org = await backend.getOrganization(realm);
      const role = assignableRoles.find((role) => role.name === selectedRole);
      if (org && userData && role) {
        const roles = [];
        roles.push(role ?? role);
        await org.addUser(userData, roles);
        showSnackbar(translations.accountDetailsDialog.texts.realmAccessAdded(), SeverityType.Success);
        handleCloseAddRealmAccessDialog();
        getUserRealms();
        setShouldFetchData(true);
      }
    } catch (error) {
      console.error(error);
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSelectedRealmChange = (realmId: string): void => {
    setSelectedRealm(realmId);
    if (realmId === "") setSelectedRole("");
  };

  const handleSelectedRoleChange = (event: SelectChangeEvent<typeof selectedRole>): void => {
    setSelectedRole(event.target.value);
  };

  //#endregion

  const userStatus = UserStatuses.find((status) =>
    userData?.getDetails().enabled
      ? status.identifiers.includes(userData?.getDetails().status)
      : status.name === UserStatusNames.DISABLED
  );

  // #region Revoke Realm Access

  const handleOpenRevokeRealmAccessDialog = (realmRole: UserRealmRole): void => {
    setSelectedRealmForRevoke(realmRole);
    setRevokeRealmAccessDialogOpen(true);
  };

  const handleCloseRevokeRealmAccessDialog = (): void => {
    setSelectedRealmForRevoke(undefined);
    setRevokeRealmAccessDialogOpen(false);
  };

  const handleSubmitRevokeRealmAccess = async (): Promise<void> => {
    setIsSaving(true);
    try {
      if (selectedRealmForRevoke) {
        const org = await backend.getOrganization(selectedRealmForRevoke?.realmId);
        if (org && userData) {
          await org.removeUser(userData);
          showSnackbar(translations.accountDetailsDialog.texts.realmAccessRevoked(), SeverityType.Success);
          getUserRealms();
          setShouldFetchData(true);
          handleCloseRevokeRealmAccessDialog();
        }
      }
    } catch (error) {
      console.error(error);
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  // #region Role change within Realm

  const handleRealmRoleSelectChange = (realmId: string, roleId: string): void => {
    const indexOfRoleIdentifier = (Object.values(RoleIdentifier) as string[]).indexOf(roleId);
    const role = Object.values(RoleIdentifier)[indexOfRoleIdentifier];

    const updatedRealmRolesList = [...updatedRealmRoles];
    if (updatedRealmRoles.some((realmRole) => realmRole.realmId === realmId)) {
      const index = updatedRealmRoles.findIndex((realmRole) => realmRole.realmId === realmId);
      updatedRealmRolesList[index] = { realmId, role };
    } else {
      updatedRealmRolesList.push({ realmId, role });
    }

    setUpdatedRealmRoles(updatedRealmRolesList);
  };

  const handleSaveChanges = async (): Promise<void> => {
    setIsSaving(true);
    try {
      const promises = updatedRealmRoles.map(async (updatedRealmRole) => {
        const roleList: Role[] = [];
        const roles = await getAssignableRoles(updatedRealmRole.realmId);
        if (roles) {
          const role = roles.find(
            (role) => role.identifier.toLocaleLowerCase() === updatedRealmRole.role.toLocaleLowerCase()
          );
          if (role) {
            roleList.push(role);
            return userData?.assignOrganizationRoles(updatedRealmRole.realmId, roleList);
          }
        }
      });
      await Promise.all(promises);
      showSnackbar(translations.accountDetailsDialog.texts.roleChanged(), SeverityType.Success);
      setUpdatedRealmRoles([]);
      setShouldFetchData(true);
    } catch (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  // #region Resend invitation

  const mapUserLanguage = (language: string): SupportedLanguage => {
    switch (language) {
      case LanguageMap.FRENCH:
        return SupportedLanguage.French;
      default:
        return SupportedLanguage.English;
    }
  };

  const handleResendInvitation = async (): Promise<void> => {
    setIsSaving(true);
    try {
      const userName = userData?.getEmail();
      const userLanguage = userData?.getDetails().language;

      if (currentOrganization && userName) {
        await currentOrganization.createUser({
          email: userName,
          resendInvitation: true,
          inviteLanguage: mapUserLanguage(userLanguage ?? ""),
          // Role information is not needed when resending invitation
          roles: [],
        });
      }
    } catch {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  // #region Re-enable account

  const handleOpenReEnableAccountDialog = (): void => {
    setReEnableAccountDialogOpen(true);
  };

  const handleCloseReEnableAccountDialog = (): void => {
    setReEnableAccountDialogOpen(false);
  };

  const handleRestoreAccountClick = async (): Promise<void> => {
    setIsSaving(true);
    try {
      await userData?.setAccountStatus(true);
      setShouldFetchData(true);
      showSnackbar(translations.accountDetailsDialog.texts.accountEnabled(), SeverityType.Success);
      handleCloseReEnableAccountDialog();
    } catch (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  // #region Disable account

  const handleDisableAccountClick = async (): Promise<void> => {
    setIsSaving(true);
    try {
      await userData?.setAccountStatus(false);
      setShouldFetchData(true);
      showSnackbar(translations.accountDetailsDialog.texts.accountDisabled(), SeverityType.Success);
      setDisableAccountDialogOpen(false);
    } catch (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  // #region Delete account

  const handleDeleteAccount = async (): Promise<void> => {
    setIsSaving(true);
    try {
      await userData?.delete();
      setShouldFetchData(true);
      showSnackbar(translations.accountDetailsDialog.texts.accountDeleted(), SeverityType.Success);
      setDeleteAccountDialogOpen(false);
      handleClose();
    } catch (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsSaving(false);
    }
  };

  // #endregion

  const handleCloseClick = (): void => {
    if (updatedRealmRoles.length) {
      setUnsavedDataDialogOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmChanges = async (): Promise<void> => {
    await handleSaveChanges();
    handleClose();
  };

  const handleDiscardChanges = (): void => {
    setUpdatedRealmRoles([]);
    handleClose();
  };

  const handleCloseUnsavedDataDialog = (): void => {
    setUnsavedDataDialogOpen(false);
  };

  const isAccountEnabled = userData?.getDetails().enabled ?? false;

  const isLoading = isLoadingUser || isLoadingUserRealms || isSaving;

  const isSaveChangesDisabled = !updatedRealmRoles.length || isLoading;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="md"
        fullWidth
        data-testid={TestIds.AccountDetailsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {everThere
            ? translations.accountDetailsDialog.texts.everThereAccountDetails()
            : translations.accountDetailsDialog.texts.dealerAccountDetails()}
          <IconButton
            data-testid={TestIds.AccountDetailsDialog.CloseButton}
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isLoadingUser ? (
            <Loading />
          ) : (
            <AccountDetailsBasicInformation accountDetails={userData} userStatus={userStatus} />
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />
          {isLoadingUser || isLoadingUserRealms || isLoadingUserTeams ? (
            <Loading />
          ) : everThere ? (
            <AccountDetailsTeamsInformation userId={userId} teams={userTeams ?? []} />
          ) : (
            <AccountDetailsRealmInformation
              isExternalUser={userData?.getDetails().status === CognitoAccountStatus.ExternalProvider}
              userRealmRoles={userRealmAccessData}
              allRoles={allRoles}
              isAccountEnabled={isAccountEnabled}
              handleOpenAddRealmAccessDialog={handleOpenAddRealmAccessDialog}
              handleOpenRevokeRealmAccessDialog={handleOpenRevokeRealmAccessDialog}
              handleRealmRoleSelectChange={handleRealmRoleSelectChange}
            />
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />
          <AccountDetailsDialogFooter
            isSaveChangesDisabled={isSaveChangesDisabled}
            isLoading={isLoading}
            isSaving={isSaving}
            userStatus={userStatus}
            everThere={everThere}
            handleSaveChanges={handleSaveChanges}
            handleResendInvitation={handleResendInvitation}
            handleOpenReEnableAccountDialog={handleOpenReEnableAccountDialog}
            handleOpenDisableAccountDialog={(): void => setDisableAccountDialogOpen(true)}
            handleOpenDeleteAccountDialog={(): void => setDeleteAccountDialogOpen(true)}
            handleCloseClick={handleCloseClick}
          />
        </DialogContent>
      </Dialog>
      <AddRealmAccessDialog
        open={addRealmAccessDialogOpen}
        handleClose={handleCloseAddRealmAccessDialog}
        userData={userData}
        userRealmAccessData={userRealmAccessData}
        handleSubmit={handleAddRealmAccessClick}
        isAddingRealmAccess={isSaving}
        isLoadingAssignableRoles={isLoadingAssignableRoles}
        assignableRoles={assignableRoles}
        selectedRealm={selectedRealm}
        selectedRole={selectedRole}
        handleSelectedRealmChange={handleSelectedRealmChange}
        handleSelectedRoleChange={handleSelectedRoleChange}
      />
      <RevokeRealmAccessDialog
        open={revokeRealmAccessDialogOpen}
        handleClose={handleCloseRevokeRealmAccessDialog}
        userData={userData}
        realmRole={selectedRealmForRevoke}
        isRevokingRealmAccess={isSaving}
        handleSubmit={handleSubmitRevokeRealmAccess}
      />
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleCloseUnsavedDataDialog}
        handleSave={handleConfirmChanges}
        handleDiscard={handleDiscardChanges}
      />
      <ReEnableAccountDialog
        open={reEnableAccountDialogOpen}
        handleClose={handleCloseReEnableAccountDialog}
        user={userData}
        handleSubmit={handleRestoreAccountClick}
        isLoading={isLoading}
      />
      <DisableAccountDialog
        open={disableAccountDialogOpen}
        handleClose={(): void => setDisableAccountDialogOpen(false)}
        handleSubmit={handleDisableAccountClick}
        isLoading={isLoading}
      />
      <DeleteAccountDialog
        open={deleteAccountDialogOpen}
        handleClose={(): void => setDeleteAccountDialogOpen(false)}
        user={userData}
        handleDelete={handleDeleteAccount}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default AccountDetailsDialog;
