/* Copyright */
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/index";
import { SnackbarProvider } from "../context/snackbar-context";
import { AuthenticatedUserProvider } from "../context/authenticated-user-context";
import { DeviceContextProvider } from "../context/device-context";
import { EverThereContextProvider } from "../context/everThere-context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <AuthenticatedUserProvider>
            <DeviceContextProvider>
              <EverThereContextProvider>{children}</EverThereContextProvider>
            </DeviceContextProvider>
          </AuthenticatedUserProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default AppProviders;
