/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIconProps,
  Typography,
} from "@mui/material";
import * as React from "react";
import theme from "../../../../app/theme";
import TestIds from "../../../../test-ids/test-ids";

export enum DeviceActionButtonSeverity {
  HIGH,
  MEDIUM,
  LOW,
}

type DeviceActionDialogProps = {
  open: boolean;
  title: string;
  message: string;
  icon?: React.ElementType<SvgIconProps>;
  proceedButtonText: string;
  buttonSeverity?: DeviceActionButtonSeverity;
  discardButtonText: string;
  onClose: () => void;
  onProceed: () => void;
};

const DeviceActionDialog: React.FC<DeviceActionDialogProps> = ({
  open,
  title,
  message,
  icon: Icon,
  proceedButtonText,
  buttonSeverity,
  discardButtonText,
  onClose,
  onProceed,
}) => {
  const getButtonColor = (severity?: DeviceActionButtonSeverity): "primary" | "error" | "warning" => {
    switch (severity) {
      case DeviceActionButtonSeverity.HIGH:
        return "error";
      case DeviceActionButtonSeverity.MEDIUM:
        return "warning";
      case DeviceActionButtonSeverity.LOW:
        return "primary";
      default:
        return "primary";
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.DeviceActionsDialog.CloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          color={getButtonColor(buttonSeverity)}
          sx={{ color: theme.palette.common.white }}
          onClick={(): void => {
            onProceed();
            onClose();
          }}
          data-testid={TestIds.DeviceActionsDialog.SaveChanges}
          startIcon={Icon ? <Icon sx={{ ml: 1, color: theme.palette.common.white }} /> : undefined}
        >
          {proceedButtonText}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
          data-testid={TestIds.DeviceActionsDialog.DiscardChanges}
        >
          {discardButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceActionDialog;
