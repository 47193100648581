/* Copyright */
import React from "react";
import { Box, Typography, Button, useTheme, Divider } from "@mui/material";
import { translations } from "../../../../generated/translationHelper";
import { Event, EventType, FallDetectionDisposition, Maybe } from "@sade/data-access";

type EventConfirmationProps = {
  event: Maybe<Event>;
  toggleIsMissedFallReportDialogOpen: () => void;
  fallConfirmed: Maybe<FallDetectionDisposition>;
  setFallConfirmed: (fallDetectionDisposition?: FallDetectionDisposition) => void;
};

const EventConfirmation: React.FC<EventConfirmationProps> = ({
  event,
  toggleIsMissedFallReportDialogOpen,
  fallConfirmed,
  setFallConfirmed,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const isFall = event?.type === EventType.Fall;

  React.useEffect(() => {
    setFallConfirmed(event?.fallDetectionDisposition ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const handleNoClicked = async (): Promise<void> => {
    try {
      setIsLoading(true);
      if (isFall) {
        await event?.addCallEventReport(FallDetectionDisposition.FalsePositive);
        setFallConfirmed(FallDetectionDisposition.FalsePositive);
      } else {
        await event?.addCallEventReport(FallDetectionDisposition.Correct);
        setFallConfirmed(FallDetectionDisposition.Correct);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYesClicked = async (): Promise<void> => {
    try {
      setIsLoading(true);
      if (isFall) {
        await event?.addCallEventReport(FallDetectionDisposition.Correct);
        setFallConfirmed(FallDetectionDisposition.Correct);
      } else {
        await event?.addCallEventReport(FallDetectionDisposition.FalseNegative);
        setFallConfirmed(FallDetectionDisposition.FalseNegative);
        toggleIsMissedFallReportDialogOpen();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {fallConfirmed ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            {isFall
              ? fallConfirmed === FallDetectionDisposition.Correct
                ? translations.activeCalls.texts.fallConfirmedText()
                : translations.activeCalls.texts.fallUnConfirmedText()
              : fallConfirmed === FallDetectionDisposition.Correct
              ? translations.activeCalls.texts.fallUnConfirmedText()
              : translations.activeCalls.texts.callConfirmedAsFallText()}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ bgcolor: theme.palette.grey[100], borderRadius: "8px", mt: 2 }}>
          <Box sx={{ p: 3 }}>
            <Typography variant="body1">
              {isFall
                ? translations.activeCalls.texts.wasThisActualFall()
                : translations.activeCalls.texts.wasThisMissedFall()}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-evenly", height: 58 }}>
            <Button
              variant="text"
              size="large"
              sx={{ width: "100%", height: "100%" }}
              onClick={handleNoClicked}
              disabled={isLoading}
            >
              {translations.common.texts.no().toLocaleUpperCase()}
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button
              variant="text"
              size="large"
              sx={{ width: "100%", height: "100%" }}
              onClick={handleYesClicked}
              disabled={isLoading}
            >
              {translations.common.texts.yes().toLocaleUpperCase()}
            </Button>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default EventConfirmation;
