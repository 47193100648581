/* Copyright */
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Toolbar, Box, Tabs, Tab } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../../../assets/images/Numera-Nice-brand-Logo-color.svg";
import { translations } from "../../../generated/translationHelper";
import AdminMenu from "../../admin/admin-menu";
import ProfileMenu from "../profile-menu";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { Nullable, RoleIdentifier } from "@sade/data-access";
import { getPath } from "../../../utils/ssoPathUtil";
import { NavTabs, Paths } from "../../../utils/utils";

const ToolbarLarge: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = React.useState<Nullable<string>>();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    switch (true) {
      case location.pathname === getPath(Paths.ROOT):
        setTabValue(null);
        break;
      case location.pathname.includes(getPath(Paths.MONITORING)):
        setTabValue(NavTabs.MONITORING);
        break;
      case location.pathname.includes(getPath(Paths.DEVICES)):
        setTabValue(NavTabs.DEVICES);
        break;
      case location.pathname.includes(getPath(Paths.ACCOUNTS)):
        setTabValue(NavTabs.ACCOUNTS);
        break;
      case location.pathname.includes(getPath(Paths.REPORTS)):
        setTabValue(NavTabs.REPORTS);
        break;
      case location.pathname.includes(getPath(Paths.PARTNERS)):
        setTabValue(NavTabs.PARTNERS);
        break;
      default:
        setTabValue(null);
        break;
    }
    if (location.pathname.includes("/admin/")) {
      setTabValue(NavTabs.ADMIN);
    }
  }, [location]);

  const handleCloseAdminMenu = (): void => {
    setMenuAnchorEl(null);
  };

  const handleAdminMenuVisibility = (event: React.SyntheticEvent<HTMLElement>): void => {
    if (menuAnchorEl !== event.currentTarget) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      handleCloseAdminMenu();
    }
  };

  const handleAdminMenuItemClick = (route: string): void => {
    handleCloseAdminMenu();
    navigate(route);
  };

  const hasMonitoringViewRights = useAccessControl(RoleIdentifier.Supervisor, RoleIdentifier.Operator);

  const hasDevicesViewRights = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.Supervisor,
    RoleIdentifier.Operator,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent,
    RoleIdentifier.WarehouseInstaller
  );

  const hasAccountsViewRights = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.Supervisor,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent
  );

  const hasReportsViewRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager);

  const hasPartnersViewRights = useAccessControl(RoleIdentifier.PowerUser);

  const hasAdminRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.Viewer);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Link to="/" data-testid={TestIds.Toolbar.LargeHomeLink}>
          <img src={logo} alt="Numera Nice brand logo" style={{ width: "150px" }} />
        </Link>
        <Box sx={{ display: "flex" }}>
          <Tabs value={tabValue ?? false} aria-label="main navigation" sx={{ maxHeight: "64px" }}>
            {hasMonitoringViewRights && (
              <Tab
                component="button"
                label={translations.appbar.links.monitoring()}
                onClick={(): void => {
                  navigate(getPath(Paths.MONITORING));
                }}
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargeMonitoringLink}
                value={NavTabs.MONITORING}
              />
            )}
            {hasDevicesViewRights && (
              <Tab
                component="button"
                onClick={(): void => {
                  navigate(getPath(Paths.DEVICES));
                }}
                label={translations.common.texts.devices()}
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargeDevicesLink}
                value={NavTabs.DEVICES}
              />
            )}
            {hasAccountsViewRights && (
              <Tab
                component="button"
                label={translations.appbar.links.accounts()}
                onClick={(): void => {
                  navigate(getPath(Paths.ACCOUNTS));
                }}
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargeAccountsLink}
                value={NavTabs.ACCOUNTS}
              />
            )}
            {hasReportsViewRights && (
              <Tab
                component="button"
                onClick={(): void => {
                  navigate(getPath(Paths.REPORTS));
                }}
                label={translations.appbar.links.reports()}
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargeReportsLink}
                value={NavTabs.REPORTS}
              />
            )}
            {hasPartnersViewRights && (
              <Tab
                component="button"
                label={translations.appbar.links.partners()}
                onClick={(): void => {
                  navigate(getPath(Paths.PARTNERS));
                }}
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargePartnersLink}
                value={NavTabs.PARTNERS}
              />
            )}
            {hasAdminRights && (
              <Tab
                component="button"
                onClick={handleAdminMenuVisibility}
                label={translations.appbar.links.admin()}
                icon={<ArrowDropDownIcon />}
                iconPosition="end"
                sx={{ fontWeight: 600 }}
                data-testid={TestIds.Toolbar.LargeAdminButton}
                value={NavTabs.ADMIN}
              />
            )}
          </Tabs>
          <AdminMenu
            menuAnchorEl={menuAnchorEl}
            handleCloseAdminMenu={handleCloseAdminMenu}
            handleAdminMenuItemClick={handleAdminMenuItemClick}
          />
          <ProfileMenu />
        </Box>
      </Toolbar>
    </>
  );
};

export default ToolbarLarge;
