/* Copyright */
import { useContext, useCallback } from "react";
import {
  SnackbarContext,
  SeverityType,
  SnackbarVerticalPosition,
  SnackbarHorizontalPosition,
} from "../context/snackbar-context";

type UseSnackbarReturnType = {
  showSnackbar: (
    message: string,
    severity?: SeverityType,
    vertical?: SnackbarVerticalPosition,
    horizontal?: SnackbarHorizontalPosition,
    info?: string
  ) => void;
};

export const useSnackbar = (): UseSnackbarReturnType => {
  const { show } = useContext(SnackbarContext);

  const showSnackbar = useCallback(
    (
      message: string,
      severity: SeverityType = SeverityType.Success,
      vertical = SnackbarVerticalPosition.Bottom,
      horizontal = SnackbarHorizontalPosition.Center,
      info = ""
    ) => {
      show(message, severity, vertical, horizontal, info);
    },
    [show]
  );

  return { showSnackbar };
};
