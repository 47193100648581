/* Copyright */
import React from "react";
import { AppBar, Box } from "@mui/material";
import ToolbarLarge from "./toolbar-large/toolbar-large";
import ToolbarSmall from "./toolbar-small/toolbar-small";

const MainAppBar: React.FC = () => {
  return (
    <AppBar position="sticky" className="layout-appbar">
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <ToolbarLarge />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <ToolbarSmall />
      </Box>
    </AppBar>
  );
};

export default MainAppBar;
