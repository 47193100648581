/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { AWSBackend } from "./backend/AWSBackend";
import { AWSOrganizationBackend } from "./organization/AWSOrganizationBackend";
import { AWSDeviceFactory } from "./device/AWSDeviceFactory";
import { AWSNotificationBackend } from "./events/AWSNotificationBackend";
import { AWSParameterBackend } from "./parameter/AWSParameterBackend";
export class BackendFactory {
    static getBackend(resetCache = false) {
        if (!BackendFactory.genericBackend || resetCache) {
            BackendFactory.genericBackend = new AWSBackend(new AWSDeviceFactory());
        }
        return BackendFactory.genericBackend;
    }
    static getOrganizationBackend(resetCache = false) {
        if (!BackendFactory.organizationBackend || resetCache) {
            BackendFactory.organizationBackend = new AWSOrganizationBackend();
        }
        return BackendFactory.organizationBackend;
    }
    static getNotificationBackend(resetCache = false) {
        if (!BackendFactory.notificationBackend || resetCache) {
            BackendFactory.notificationBackend = new AWSNotificationBackend();
        }
        return BackendFactory.notificationBackend;
    }
    static getParameterBackend(resetCache = false) {
        if (!BackendFactory.parameterBackend || resetCache) {
            BackendFactory.parameterBackend = new AWSParameterBackend();
        }
        return BackendFactory.parameterBackend;
    }
    static resetAllBackendCaches() {
        BackendFactory.getBackend(true);
        BackendFactory.getOrganizationBackend(true);
        BackendFactory.getNotificationBackend(true);
        BackendFactory.getParameterBackend(true);
    }
}
