/* Copyright */
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import theme from "../../../app/theme";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type DeviceWearerCardProps = {
  wearerName: string;
  address?: string;
};

const DeviceWearerCard: React.FC<DeviceWearerCardProps> = ({ wearerName, address }) => {
  const [streetAddres, setStreetAddress] = React.useState<string>();
  const [restOfAddress, setRestOfAddress] = React.useState<string>();

  React.useEffect(() => {
    if (address) {
      const addressParts = address.split(",");
      setStreetAddress(addressParts[0]);
      setRestOfAddress(addressParts.slice(1).join(","));
    }
  }, [address]);

  return (
    <React.Fragment>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.ActiveCalls.WearerCard}>
        <CardHeader
          title={translations.common.texts.deviceWearer()}
          titleTypographyProps={{ variant: "h6" }}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
            <Box sx={{ width: "55px" }}>
              <Avatar sx={{ bgcolor: theme.palette.primary.light }}>
                <PersonIcon />
              </Avatar>
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <Typography variant="body1">{wearerName}</Typography>
            </Box>
          </Box>
          <Typography variant="overline">{translations.activeCalls.texts.homeAddress()}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
            <Box sx={{ width: "55px" }}>
              <Avatar sx={{ bgcolor: theme.palette.success.light }}>
                <HomeIcon />
              </Avatar>
            </Box>
            {address ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignSelf: !location ? "center" : "" }}>
                <Typography variant="body1">{streetAddres}</Typography>
                <Typography variant="body1">{restOfAddress}</Typography>
              </Box>
            ) : (
              <Box sx={{ alignSelf: "center" }}>
                <Typography variant="body1">{translations.common.texts.unknown()}</Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default DeviceWearerCard;
