/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type UnsavedChangesDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  handleDiscard: () => void;
};

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  open,
  handleClose,
  handleSave,
  handleDiscard,
}) => {
  const handleSaveClick = (): void => {
    handleSave();
    handleClose();
  };

  const handleDiscardClick = (): void => {
    handleDiscard();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.unsavedChangesDialog.texts.unsavedChanges()}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.UnsavedChangesDialog.CloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography variant="body1">{translations.unsavedChangesDialog.texts.unsavedChangesInfo()}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button variant="contained" onClick={handleSaveClick} data-testid={TestIds.UnsavedChangesDialog.SaveChanges}>
          {translations.common.buttons.saveChanges()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleDiscardClick}
          sx={{ borderColor: "red", color: "red" }}
          data-testid={TestIds.UnsavedChangesDialog.DiscardChanges}
        >
          {translations.common.buttons.discardChanges()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
