/* Copyright */
import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import TestIds from "../../../test-ids/test-ids";
import { translations } from "../../../generated/translationHelper";
import { Maybe } from "@sade/data-access";

type MissedFallConfirmationDialogProps = {
  open: boolean;
  isFall: boolean;
  handleResult: (boolean: boolean) => void;
  isHandlingMissedFallResult: Maybe<boolean>;
};

const MissedFallConfirmationDialog: React.FC<MissedFallConfirmationDialogProps> = ({
  open,
  handleResult,
  isFall,
  isHandlingMissedFallResult,
}) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth data-testid={TestIds.ActiveCallsMissedCallConfirmationDialog.Dialog}>
      <DialogTitle variant="h5">
        {isFall
          ? translations.activeCalls.texts.wasThisActualFall()
          : translations.activeCalls.texts.wasThisMissedFall()}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography>
            {isFall
              ? translations.activeCalls.texts.wasThisActualFallText()
              : translations.activeCalls.texts.wasThisMissedFallText()}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => handleResult(false)}
          data-testid={TestIds.ActiveCallsMissedCallConfirmationDialog.NoButton}
          disabled={isHandlingMissedFallResult !== undefined}
          startIcon={isHandlingMissedFallResult === false && <CircularProgress size={16} color="secondary" />}
        >
          {translations.common.texts.no()}
        </Button>
        <Button
          variant="contained"
          onClick={(): void => handleResult(true)}
          data-testid={TestIds.ActiveCallsMissedCallConfirmationDialog.YesButton}
          disabled={isHandlingMissedFallResult !== undefined}
          startIcon={isHandlingMissedFallResult === true && <CircularProgress size={16} color="secondary" />}
        >
          {translations.common.texts.yes()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissedFallConfirmationDialog;
