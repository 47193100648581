/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, ListItemText, MenuItem, Select, useMediaQuery, useTheme } from "@mui/material";
import { Maybe, ParameterName, ParameterType, ParameterValueType } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../generated/translationHelper";
import { PartnerParameter } from "../../utils/utils";
import CustomTextField from "../common/textfield";

type ParameterManagementProps = {
  parameterOptions: Maybe<ParameterName[]>;
  parameterType: ParameterType;
  parameter: PartnerParameter;
  handleParameterNameChange: (parameterType: ParameterType, parameterName: string) => void;
  handleParameterValueChange: (
    parameterType: ParameterType,
    parameterName: string,
    parameterValue: ParameterValueType
  ) => void;
  handleRemoveParameter: (parameterType: ParameterType, parameterName: string) => void;
  handleParameterValueValidation: (parameter: string, validationResult: boolean) => void;
};

const ParameterManagement: React.FC<ParameterManagementProps> = ({
  parameterOptions,
  parameterType,
  parameter,
  handleParameterNameChange,
  handleParameterValueChange,
  handleRemoveParameter,
  handleParameterValueValidation,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("xs"));
  const [errorMessage, setErrorMessage] = React.useState("");

  /**
   * Convert JSON parameter to string, other parameter types are shown as is.
   */
  const parameterValue = (parameter: PartnerParameter): ParameterValueType => {
    if (typeof parameter.parameterValue === "object" && parameter.parameterType !== "string[]") {
      return JSON.stringify(parameter.parameterValue);
    }

    return parameter.parameterValue;
  };

  /**
   * Validate the parameter value based on the parameter value type.
   */
  const validateParameterValue = (event: React.FocusEvent<HTMLInputElement>): void => {
    let error = "";
    if (parameter.parameterValue == null) {
      error = translations.common.texts.valueMissingError();
    } else {
      switch (parameter.parameterType) {
        case "string":
          // No validation needed for string parameters.
          break;
        case "number":
          if (isNaN(Number(parameter.parameterValue)) || parameter.parameterValue === "") {
            error = translations.common.texts.invalidNumberError();
          }
          break;
        case "boolean":
          if (!["true", "false"].includes(String(parameter.parameterValue).toLowerCase())) {
            error = translations.common.texts.invalidBooleanError();
          }
          break;
        case "json":
          try {
            if (parameter.parameterValue === "") {
              return;
            }
            JSON.parse(event.target.value);
          } catch (e) {
            error = translations.common.texts.invalidJsonError();
          }
          break;
        default:
          break;
      }
    }

    handleParameterValueValidation(`${parameterType}-${parameter.parameterName}`, !error.length);
    setErrorMessage(error);
  };

  /**
   * Reset the error message when the parameter value changes.
   */
  const handleParameterValueChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setErrorMessage("");
    handleParameterValueChange(parameterType, parameter.parameterName, event.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Select
        size="small"
        onChange={(event): void => handleParameterNameChange(parameterType, event.target.value)}
        value={parameter.parameterName}
        fullWidth
        sx={{ mr: 1, maxHeight: "40px", width: isSmallViewPort ? "50%" : "100%", overflow: "hidden" }}
        disabled={parameter.parameterName !== ""}
      >
        {parameterOptions?.map((parameterOption, index) => {
          const paramName =
            parameterOption.name + (parameterOption.legacyName ? ` (${parameterOption.legacyName})` : "");
          return (
            <MenuItem key={index} value={parameterOption.name}>
              <ListItemText primary={paramName} />
            </MenuItem>
          );
        })}
      </Select>
      <CustomTextField
        label=""
        id="parameterValue"
        testId={`${parameterType}-${parameter.parameterName}-input`}
        value={parameterValue(parameter)}
        onChange={handleParameterValueChangeWrapper}
        onBlur={validateParameterValue}
        disabled={!parameter.parameterName}
        errorMessage={errorMessage}
        sx={{ overflow: "hidden" }}
      />
      <IconButton
        onClick={(): void => handleRemoveParameter(parameterType, parameter.parameterName)}
        color="secondary"
        data-testid=""
        disabled={parameter.parameterName === ""}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default ParameterManagement;
