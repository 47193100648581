/* Copyright */
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import LoginViewImage from "../../../assets/images/LoginView.png";
import PasswordViewImage from "../../../assets/images/PasswordView.png";
import RegistrationViewImage from "../../../assets/images/RegistrationView.png";
import ForgotPasswordViewImage from "../../../assets/images/ForgotPasswordView.jpg";
import AuthenticationLayoutFooter from "./authentication-layout-footer";
import { getPath } from "../../../utils/ssoPathUtil";
import { Paths } from "../../../utils/utils";

const AuthenticationLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  const resolveImage = (): string => {
    switch (location.pathname) {
      case getPath(Paths.LOGOUT):
        return LoginViewImage;
      case getPath(Paths.LOGIN):
        return LoginViewImage;
      case getPath(Paths.REGISTRATION):
        return RegistrationViewImage;
      case getPath(Paths.FORGOTPASSWORD):
        return ForgotPasswordViewImage;
      default:
        return PasswordViewImage;
    }
  };

  return (
    <Box className="root">
      <Grid container className="container">
        <Grid item xs md={6} className="form-container">
          {children}
          <AuthenticationLayoutFooter />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            backgroundImage: `url(${resolveImage()})`,
            backgroundSize: "cover",
          }}
        ></Grid>
      </Grid>
    </Box>
  );
};

export default AuthenticationLayout;
