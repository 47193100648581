class TokenHolder {
    hasToken() {
        return this.token !== undefined;
    }
}
/*
    This token holder is intended for anonymous tokens that are never refreshed, and are provided to the application
    via the URL. "Full" OIDC authentication should be implemented using Amplify Auth module in Amplify v6.
 */
export const OidcTokenHolder = new TokenHolder();
