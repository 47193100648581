/* Copyright */
import React from "react";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import { BackendFactory, Device, DeviceAttributeName, Maybe, invertedEventOrdering } from "@sade/data-access";
import { useNavigate } from "react-router-dom";

import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { DeviceEvent, formatEvent } from "../../../../utils/eventUtils";
import { formatTimestamp } from "../../../../utils/utils";
import CardEmptyState from "../../../common/card-empty-state";
import CardContentSkeleton from "../../../loading/card-content-skeleton";
import LatestEvent from "./latest-event";

type DeviceViewContentEventsLocationsProps = {
  device?: Device;
  isLoadingDetails: boolean;
};

const DeviceViewContentEventsLocations: React.FC<DeviceViewContentEventsLocationsProps> = ({
  device,
  isLoadingDetails,
}) => {
  const navigate = useNavigate();
  const [isLoadingEvent, setIsLoadingEvent] = React.useState<boolean>(false);
  const [latestEvents, setLatestEvents] = React.useState<Maybe<DeviceEvent[]>>();

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (!device) return;
      const patientId = device.getAttribute(DeviceAttributeName.patientId);
      if (!patientId) return;

      const backend = BackendFactory.getOrganizationBackend();
      try {
        setIsLoadingEvent(true);

        const patient = await backend.getPatient(patientId);
        if (patient) {
          const events = await patient.getLatestEvents(3);
          if (events) {
            const eventsProps = events.sort(invertedEventOrdering).map((event) => formatEvent(event, true));
            const formattedEvents = eventsProps;
            setLatestEvents(formattedEvents);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingEvent(false);
      }
    })();
  }, [device]);

  const isLoading = isLoadingDetails || isLoadingEvent;

  return (
    <Box>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.EventsLocationsCard}>
        <CardHeader
          title={translations.common.texts.eventsLocations()}
          action={
            <Button
              variant="outlined"
              startIcon={<MapOutlinedIcon />}
              disabled={isLoading}
              sx={{ mr: 3 }}
              onClick={(): void => navigate("events-and-locations")}
            >
              {translations.common.buttons.view()}
            </Button>
          }
        ></CardHeader>
        {isLoading && !latestEvents?.length ? (
          <CardContentSkeleton />
        ) : (
          <CardContent>
            {latestEvents && latestEvents.length > 0 ? (
              latestEvents.map((event, index) => (
                <LatestEvent
                  key={index}
                  icon={event.icon}
                  eventName={event.eventName}
                  eventTime={formatTimestamp(event.eventTimestamp)}
                  level={event?.eventLevel}
                />
              ))
            ) : (
              <CardEmptyState emptyStateText={translations.deviceEventsLocations.texts.emptyStateText()} />
            )}
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default DeviceViewContentEventsLocations;
