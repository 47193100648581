/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { AwsConfig, Maybe } from "@sade/data-access";
import { Paths } from "./utils";

export class AwsConfigFactory {
  private static awsConfig: Map<string, AwsConfig> = new Map();

  public static getConfig(redirectUrl?: string): AwsConfig {
    if (!AwsConfigFactory.awsConfig.get(redirectUrl ?? "nourl")) {
      AwsConfigFactory.awsConfig.set(redirectUrl ?? "nourl", AwsConfigFactory.generateConfig(redirectUrl));
    }

    return AwsConfigFactory.awsConfig.get(redirectUrl ?? "nourl")!;
  }

  private static generateConfig(redirectUrl: Maybe<string>): AwsConfig {
    return {
      Auth: {
        identityPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_IDENTITY_POOL_ID!,
        region: process.env.REACT_APP_AWS_CONFIG_AUTH_REGION!,
        userPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_ID!,
        userPoolWebClientId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID!,
        oauth: {
          domain: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_DOMAIN!,
          scope: ["aws.cognito.signin.user.admin", "email", "openid"],
          redirectSignIn: redirectUrl ?? "",
          redirectSignOut: `${redirectUrl}/${Paths.LOGOUT}` ?? "",
          clientId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID!,
          responseType: "code",
        },
      },
      Analytics: {
        disabled: true,
      },
      AppSync: {
        GqlEndpointUsers: process.env.REACT_APP_AWS_CONFIG_USERS_GRAPHQL_ENDPOINT!,
        GqlEndpointDevice: process.env.REACT_APP_AWS_CONFIG_DEVICE_GRAPHQL_ENDPOINT!,
        GqlEndpointEvents: process.env.REACT_APP_AWS_CONFIG_EVENTS_GRAPHQL_ENDPOINT!,
        GqlEndpointParameters: process.env.REACT_APP_AWS_CONFIG_PARAMETERS_GRAPHQL_ENDPOINT!,
        Region: process.env.REACT_APP_AWS_CONFIG_APPSYNC_REGION!,
        AuthenticationType: process.env.REACT_APP_AWS_CONFIG_APPSYNC_AUTH_TYPE!,
      },
    };
  }
}
