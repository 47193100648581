/** Copyright */

import { BackendFactory, Device, DeviceAttributeName, Maybe, Patient } from "@sade/data-access";

export interface PatientUrlText {
  display_name?: string;
  display_address?: string;
}

const retrieveDisplayText = async (url: string): Promise<Maybe<PatientUrlText>> => {
  try {
    const response = await fetch(url, { method: "GET", headers: { "Content-Type": "application/json" } });
    if (!response?.ok) return;
    const json = await response.json();
    if (!json) return;

    return {
      display_name: json.display_name,
      display_address: json.display_address,
    };
  } catch (e) {
    // TODO: Should we throw...?
    console.error("unable to retrieve patient info from partner system ", e);
  }
};

export const getPatientUrlText = async (patient: Patient, device: Device): Promise<Maybe<PatientUrlText>> => {
  const organizationId = device?.getAttribute(DeviceAttributeName.organization);
  if (!organizationId) return;
  const organization = await BackendFactory.getOrganizationBackend().getOrganization(organizationId);
  if (!organization) return;

  const patientTextUrl = organization?.getPatientTextUrl();
  const deviceId = device.getId();
  const callerId = device.getAttribute(DeviceAttributeName.callerIdNumber);
  const customerId = patient.getCustomerId();

  const patientInfo =
    deviceId && patientTextUrl?.includes("{patient.imei}")
      ? await retrieveDisplayText(patientTextUrl.replace("{patient.imei}", deviceId))
      : customerId && patientTextUrl?.includes("{patient.customer_id_only}")
      ? await retrieveDisplayText(patientTextUrl.replace("{patient.customer_id_only}", customerId))
      : callerId && patientTextUrl?.includes("{patient.caller_id}")
      ? await retrieveDisplayText(patientTextUrl.replace("{patient.caller_id}", callerId))
      : undefined;

  return {
    display_name: patientInfo?.display_name,
    display_address: patientInfo?.display_address,
  };
};
