/* Copyright */
import { TableCell, TableRow } from "@mui/material";
import { Event } from "@sade/data-access";
import React from "react";
import TestIds from "../../../test-ids/test-ids";
import { calculateDuration, formatTimestamp } from "../../../utils/utils";
import EventChip from "../event-chip";

type CallHistoryTableRowProps = {
  event: Event;
  tableRowIndex?: number;
  userHasAccessToActiveCalls: boolean;
  handleRowClick: (deviceId: string) => void;
};

const CallHistoryTableRow: React.FC<CallHistoryTableRowProps> = ({
  event,
  tableRowIndex,
  userHasAccessToActiveCalls,
  handleRowClick,
}) => {
  return (
    <TableRow
      onClick={(): void => handleRowClick(event.deviceId)}
      sx={{ cursor: userHasAccessToActiveCalls ? "pointer" : "default" }}
      data-testid={TestIds.CallHistoryTable.TableRow}
      hover
    >
      <TableCell data-testid={`${TestIds.CallHistoryTable.Timestamp}-${tableRowIndex}`}>
        {formatTimestamp(event.timestamp)}
      </TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.Imei}-${tableRowIndex}`}>{event.deviceId}</TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.CallerIdNumber}-${tableRowIndex}`}>
        {event.callerIdNumber}
      </TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.DeviceName}-${tableRowIndex}`}>{event.deviceName}</TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.Type}-${tableRowIndex}`}>
        <EventChip event={event} />
      </TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.AnsweredBy}-${tableRowIndex}`}>
        {event.callAnsweredBy}
      </TableCell>
      <TableCell data-testid={`${TestIds.CallHistoryTable.Duration}-${tableRowIndex}`}>
        {event.callEndedAt && event.callAnsweredAt ? calculateDuration(event.callEndedAt, event.callAnsweredAt) : "-"}
      </TableCell>
    </TableRow>
  );
};

export default CallHistoryTableRow;
