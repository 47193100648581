/* Copyright */
import React from "react";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  TextField,
} from "@mui/material";
import { Maybe } from "@sade/data-access";
import { translations } from "../../generated/translationHelper";
import { OrganizationInfo } from "../../utils/utils";

type RealmPickerProps = {
  isLoading?: boolean;
  childOrganizations: Maybe<OrganizationInfo[]>;
  onRealmPicked: (realmId: string) => void;
  selectedRealmId?: string;
  testId?: string;
  disabled?: boolean;
};
const RealmPicker: React.FC<RealmPickerProps> = ({
  isLoading,
  childOrganizations,
  onRealmPicked,
  selectedRealmId,
  testId,
  disabled,
}) => {
  // We need to do this trick, because if we do not pass the selectedRealmId directly to the Autocomplete component,
  // the Autocomplete component will not update its value when the selectedRealmId changes.
  const [currentRealm, setCurrentRealm] = React.useState<Maybe<string>>(selectedRealmId);
  React.useEffect(() => {
    setCurrentRealm(selectedRealmId);
  }, [selectedRealmId]);

  return (
    <FormControl id="realms-form-control" className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
      <InputLabel shrink disabled={isLoading} id="filter-by-realm-label">
        {translations.common.inputs.realm()}
      </InputLabel>
      <Autocomplete
        loading={isLoading || !childOrganizations}
        value={childOrganizations?.find((realm) => realm.id === currentRealm) ?? null}
        options={childOrganizations ?? []}
        onChange={(_event, value): void => {
          if (value) {
            setCurrentRealm(value.id);
            onRealmPicked(value.id);
          }
        }}
        data-testid={testId}
        getOptionLabel={(option: OrganizationInfo): string => option.fullName}
        renderInput={(params): React.ReactNode => <TextField {...params} />}
        loadingText={
          <Box sx={{ display: "flex", pt: "16px", pb: "16px", justifyContent: "center" }}>
            <CircularProgress size={16} />
          </Box>
        }
        renderOption={(props, option): React.ReactNode => <ListItem {...props}>{option.fullName}</ListItem>}
        disabled={disabled}
      />
      <FormHelperText id="form-helper">{String(" ")}</FormHelperText>
    </FormControl>
  );
};

export default RealmPicker;
