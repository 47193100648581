/* Copyright */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Chip, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Device, DeviceStatus, OrganizationParameterHierarchy, RoleIdentifier } from "@sade/data-access";
import React from "react";
import { useNavigate } from "react-router-dom";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { deviceSupportsSettingsAndActions } from "../../../utils/deviceDetailsUtils";
import { NAVIGATE_BACK, resolveDeviceStatusChipColor } from "../../../utils/utils";
import DeviceViewHeaderActionButtons from "./device-view-header-action-buttons";
import MaintenanceMenu from "./device-view-maintenance-menu";

type DeviceViewHeaderProps = {
  isLoading: boolean;
  deviceData?: Device;
  parameterHierarchy?: OrganizationParameterHierarchy;
  onReload: (delay?: number) => void;
  onDeviceStateUpdate: () => void;
};

const DeviceViewHeader: React.FC<DeviceViewHeaderProps> = ({
  isLoading,
  deviceData,
  parameterHierarchy,
  onReload,
  onDeviceStateUpdate,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeViewPort = useMediaQuery(theme.breakpoints.between("sm", "xl"));
  const isExtraLargeViewPort = useMediaQuery(theme.breakpoints.up("xl"));

  const deviceStatus = deviceData?.getDeviceStatus();

  const showActionButtons = (status?: DeviceStatus): boolean => {
    if (!status) return false;
    switch (status) {
      case DeviceStatus.activating:
      case DeviceStatus.unmanaged:
      case DeviceStatus.unsuccesful:
      case DeviceStatus.retired:
      case DeviceStatus.deactivating:
        return false;
      default:
        return true;
    }
  };

  const showMaintenanceMenu = (status?: DeviceStatus): boolean => {
    if (!status) return false;
    switch (status) {
      case DeviceStatus.active:
      case DeviceStatus.unmanaged:
      case DeviceStatus.unsuccesful:
        return true;
      default:
        return false;
    }
  };

  const maintenanceMenuAccess = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.WarehouseInstaller
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={(): void => navigate(NAVIGATE_BACK)}
          sx={{ alignSelf: "flex-start" }}
          data-testid={TestIds.DeviceDetails.BackToListButton}
        >
          {translations.common.buttons.backToList()}
        </Button>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {deviceData &&
            deviceSupportsSettingsAndActions(deviceData) &&
            showActionButtons(deviceStatus) &&
            isExtraLargeViewPort && <DeviceViewHeaderActionButtons isLoading={isLoading} deviceData={deviceData} />}
          {showMaintenanceMenu(deviceStatus) && !isSmallViewPort && maintenanceMenuAccess && (
            <MaintenanceMenu
              device={deviceData}
              parameterHierarchy={parameterHierarchy}
              onReload={onReload}
              onDeviceStateUpdate={onDeviceStateUpdate}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Box>
          {isLoading && !deviceData && !parameterHierarchy ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "start",
                alignItems: { xs: "flex-start", sm: "center" },
                mb: 2,
              }}
            >
              <Skeleton width={300} height={60} sx={{ mr: 2 }} /> <Skeleton variant="circular" width={70} height={40} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "start",
                alignItems: { xs: "flex-start", sm: "center" },
                mb: 2,
              }}
              data-testid={TestIds.DeviceDetails.DeviceName}
            >
              <Typography variant={isSmallViewPort ? "h5" : "h4"} sx={{ mr: 2 }}>
                {deviceData?.getName() ?? deviceData?.getId() ?? "-"}
              </Typography>
              {deviceStatus && (
                <Chip
                  size="medium"
                  label={deviceStatus}
                  sx={{
                    backgroundColor: resolveDeviceStatusChipColor(deviceStatus),
                    color: "white",
                  }}
                />
              )}
            </Box>
          )}
        </Box>
        {isLargeViewPort && !isSmallViewPort && (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {deviceData && deviceSupportsSettingsAndActions(deviceData) && showActionButtons(deviceStatus) && (
              <DeviceViewHeaderActionButtons isLoading={isLoading} deviceData={deviceData} />
            )}
          </Box>
        )}
      </Box>
      {isSmallViewPort && (
        <Box>
          {deviceData && deviceSupportsSettingsAndActions(deviceData) && showActionButtons(deviceStatus) && (
            <DeviceViewHeaderActionButtons isLoading={isLoading} deviceData={deviceData} />
          )}

          {showMaintenanceMenu(deviceStatus) && maintenanceMenuAccess && (
            <MaintenanceMenu
              device={deviceData}
              parameterHierarchy={parameterHierarchy}
              onReload={onReload}
              onDeviceStateUpdate={onDeviceStateUpdate}
              isLoading={isLoading}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DeviceViewHeader;
