/* Copyright */
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Maybe, OrganizationApiKeyInfo, ParameterName, ParameterType, ParameterValueType } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../generated/translationHelper";
import { PartnerParameter } from "../../utils/utils";
import ParameterManagement from "../parameter-management/parameter-management";

type PartnerDetailsViewParameterSettingsProps = {
  deviceParameterOptions: Maybe<ParameterName[]>;
  systemParameterOptions: Maybe<ParameterName[]>;
  deviceParameters: PartnerParameter[];
  systemParameters: PartnerParameter[];
  handleAddParameter: (parameterType: ParameterType) => void;
  handleRemoveParameter: (parameterType: ParameterType, parameterName: string) => void;
  handleParameterNameChange: (parameterType: ParameterType, parameterName: string) => void;
  handleParameterValueChange: (
    parameterType: ParameterType,
    parameterName: string,
    parameterValue: ParameterValueType
  ) => void;
  handleParameterValueValidation: (parameter: string, validationResult: boolean) => void;
  handleAddApiKey: () => void;
  handleRemoveApiKey: (keyId: string) => void;
  partnerApiKeyParameters: OrganizationApiKeyInfo[];
};

const PartnerDetailsViewParameterSettings: React.FC<PartnerDetailsViewParameterSettingsProps> = ({
  deviceParameterOptions,
  systemParameterOptions,
  deviceParameters,
  systemParameters,
  handleAddParameter,
  handleRemoveParameter,
  handleParameterNameChange,
  handleParameterValueChange,
  handleParameterValueValidation,
  handleAddApiKey,
  handleRemoveApiKey,
  partnerApiKeyParameters,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            {translations.partners.texts.deviceParameters()}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.partners.texts.deviceParametersInfo(),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", ml: 2, mb: -2 }}>
            <Box sx={{ width: "50%" }}>
              <InputLabel id="device-parameter-label" shrink>
                {translations.partners.texts.deviceParameter()}
              </InputLabel>
            </Box>
            <Box sx={{ width: "50%", ml: -1 }}>
              <InputLabel id="device-parameter-label" shrink>
                {translations.common.texts.value()}
              </InputLabel>
            </Box>
          </Box>
          {deviceParameters.map((param, index) => (
            <ParameterManagement
              key={index}
              parameterOptions={deviceParameterOptions}
              parameterType={ParameterType.device}
              parameter={param}
              handleRemoveParameter={handleRemoveParameter}
              handleParameterNameChange={handleParameterNameChange}
              handleParameterValueChange={handleParameterValueChange}
              handleParameterValueValidation={handleParameterValueValidation}
            />
          ))}
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ mt: 1 }}
            onClick={(): void => handleAddParameter(ParameterType.device)}
            disabled={!deviceParameters[deviceParameters.length - 1].parameterName}
          >
            {translations.partners.buttons.addDeviceParameter()}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            {translations.partners.texts.systemParameters()}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.partners.texts.systemParametersInfo(),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", ml: 2, mb: -2 }}>
            <Box sx={{ width: "50%" }}>
              <InputLabel id="system-parameter-label" shrink>
                {translations.partners.texts.systemParameter()}
              </InputLabel>
            </Box>
            <Box sx={{ width: "50%", ml: -1 }}>
              <InputLabel id="system-parameter-label" shrink>
                {translations.common.texts.value()}
              </InputLabel>
            </Box>
          </Box>
          {systemParameters.map((param, index) => (
            <ParameterManagement
              key={index}
              parameterOptions={systemParameterOptions}
              parameterType={ParameterType.system}
              parameter={param}
              handleRemoveParameter={handleRemoveParameter}
              handleParameterNameChange={handleParameterNameChange}
              handleParameterValueChange={handleParameterValueChange}
              handleParameterValueValidation={handleParameterValueValidation}
            />
          ))}
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ mt: 1 }}
            onClick={(): void => handleAddParameter(ParameterType.system)}
            disabled={!systemParameters[systemParameters.length - 1].parameterName}
          >
            {translations.partners.buttons.addSystemParameter()}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
            {translations.partners.texts.apiKey()}
          </Typography>
          {partnerApiKeyParameters.length ? (
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{translations.partners.texts.secretKey()}</TableCell>
                      <TableCell>{translations.partners.texts.appId()}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partnerApiKeyParameters.map((param, index) => (
                      <TableRow key={index}>
                        <TableCell>{param.secretKey}</TableCell>
                        <TableCell>{param.keyId}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="outlined"
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                sx={{ mt: 1 }}
                onClick={(): void => handleRemoveApiKey(partnerApiKeyParameters[0].keyId)}
                color="error"
              >
                {translations.partners.buttons.removeApiKey()}
              </Button>
            </Box>
          ) : (
            <Button variant="outlined" startIcon={<AddIcon />} sx={{ mt: 1, mb: 5 }} onClick={handleAddApiKey}>
              {translations.partners.buttons.createApiKey()}
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PartnerDetailsViewParameterSettings;
