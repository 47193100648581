/* Copyright */
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { TeamMember } from "@sade/data-access";
import * as React from "react";
import { useEverThereContext } from "../../../../../context/everThere-context";
import { SeverityType } from "../../../../../context/snackbar-context";
import { translations } from "../../../../../generated/translationHelper";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import TestIds from "../../../../../test-ids/test-ids";
import { everThereMemberProps } from "../../../../../utils/utils";

type EverThereAccountsDialogContentDataRowMenuProps = {
  deviceId?: string;
  member: TeamMember;
  memberProps: everThereMemberProps;
  handleDeleteMember: (member: TeamMember) => void;
  handleOpenTeamManagerDialog: (member: TeamMember) => void;
  handleOpenRemoveDeviceDialog: (member: TeamMember) => void;
  handleOpenMakeMemberAWearerDialog: (member: TeamMember) => void;
  userIndex?: number;
};

const EverThereAccountsDialogContentDataRowMenu: React.FC<EverThereAccountsDialogContentDataRowMenuProps> = ({
  deviceId,
  member,
  memberProps,
  handleDeleteMember,
  handleOpenTeamManagerDialog,
  handleOpenRemoveDeviceDialog,
  handleOpenMakeMemberAWearerDialog,
  userIndex,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isResendingInvitation, setIsResendingInvitation] = React.useState<boolean>(false);
  const { everThereData } = useEverThereContext();
  const { showSnackbar } = useSnackbar();

  const memberFirstName = member?.firstName ?? "User";

  const handleOpenMenu = (event: React.SyntheticEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleOpenDeleteMemberDialog = (): void => {
    handleDeleteMember(member);
    handleCloseMenu();
  };

  const handleResendInvitationClick = async (): Promise<void> => {
    setIsResendingInvitation(true);
    const role = member.isTeamManager
      ? translations.common.texts.teamManager()
      : translations.common.texts.teamMember();
    const email = member.email ?? "";
    try {
      await everThereData?.resendInvitation(member.userId);

      showSnackbar(
        translations.common.texts.invitationSuccessHeader(),
        SeverityType.Success,
        undefined,
        undefined,
        translations.common.texts.invitationSuccessInfo({ email, role })
      );

      handleCloseMenu();
    } catch (error) {
      console.log(error);
      showSnackbar(
        translations.common.texts.invitationFailedHeader(),
        SeverityType.Error,
        undefined,
        undefined,
        translations.common.texts.invitationFailedInfo({ email })
      );
    } finally {
      setIsResendingInvitation(false);
    }
  };

  const toggleIsMakeMemberAWearerDialogOpen = (): void => {
    handleOpenMakeMemberAWearerDialog(member);
    handleCloseMenu();
  };

  const toggleIsMakeMemberTeamManagerDialogOpen = (): void => {
    handleOpenTeamManagerDialog(member);
    handleCloseMenu();
  };

  const toggleIsRemoveDeviceDialogOpen = (): void => {
    handleOpenRemoveDeviceDialog(member);
    handleCloseMenu();
  };

  return (
    <Box>
      <IconButton onClick={handleOpenMenu} data-testid={`${TestIds.DeviceEverThereUserMenu.OpenButton}-${userIndex}`}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        {memberProps.invitationAccepted && !member.wearerOfDevice && (
          <MenuItem
            onClick={(): void => toggleIsMakeMemberAWearerDialogOpen()}
            data-testid={`${TestIds.DeviceEverThereUserMenu.MakeUserWearer}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemMakeWearer({ memberFirstName })}
          </MenuItem>
        )}
        {(deviceId ? member.wearerOfDevice === deviceId : member.wearerOfDevice) && (
          <MenuItem
            sx={{ color: "red" }}
            onClick={toggleIsRemoveDeviceDialogOpen}
            data-testid={`${TestIds.DeviceEverThereUserMenu.RemoveDeviceFromUser}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemRemoveFromDevice({ memberFirstName })}
          </MenuItem>
        )}
        {deviceId && member.wearerOfDevice && member.wearerOfDevice !== deviceId && (
          <MenuItem
            disabled={true}
            data-testid={`${TestIds.DeviceEverThereUserMenu.RemoveDeviceFromUser}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemWearerOf({
              deviceId: member.wearerOfDevice,
            })}
          </MenuItem>
        )}
        {memberProps.invitationAccepted && !member.isTeamManager && (
          <MenuItem
            onClick={toggleIsMakeMemberTeamManagerDialogOpen}
            data-testid={`${TestIds.DeviceEverThereUserMenu.MakeUserTeamManager}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemMakeManager({ memberFirstName })}
          </MenuItem>
        )}
        {memberProps.isPending && (
          <MenuItem
            onClick={handleResendInvitationClick}
            disabled={isResendingInvitation}
            data-testid={`${TestIds.DeviceEverThereUserMenu.ResendInvitation}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemResend()}
          </MenuItem>
        )}
        {!member.isTeamManager && (
          <MenuItem
            sx={{ color: "red" }}
            onClick={handleOpenDeleteMemberDialog}
            data-testid={`${TestIds.DeviceEverThereUserMenu.RemoveUser}-${userIndex}`}
          >
            {translations.deviceEverThereAccountsDialog.texts.memberMenuItemRemoveFromTeam({ memberFirstName })}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default EverThereAccountsDialogContentDataRowMenu;
