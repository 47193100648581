/* Copyright */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { translations } from "../../generated/translationHelper";
import { Paths } from "../../utils/utils";
import TestIds from "../../test-ids/test-ids";
import { getPath } from "../../utils/ssoPathUtil";

const SuccessView: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = (): void => {
    navigate(getPath(Paths.LOGIN));
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.forgotPasswordSuccess.texts.title()}
      </Typography>
      <Button
        data-testid={TestIds.ForgotPasswordForm.ForgotPasswordSuccess}
        size="large"
        variant="contained"
        className="button"
        onClick={handleButtonClick}
      >
        {translations.common.buttons.openNumeraPortal()}
      </Button>
    </React.Fragment>
  );
};

export default SuccessView;
