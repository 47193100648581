/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { Maybe } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import FileUpload from "../../common/file-upload";
import CustomTextField from "../../common/textfield";

type EnterImeisDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (imeis: string[]) => void;
  isLoading: boolean;
};

const EnterImeisDialog: React.FC<EnterImeisDialogProps> = ({ open, handleClose, handleSubmit, isLoading }) => {
  const [selectedImeiInputMethod, setSelectedImeiInputMethod] = React.useState<Maybe<number>>();
  const [selectedImeis, setSelectedImeis] = React.useState<string[]>([]);

  const handleImeiInputMethodChange = (event: SelectChangeEvent): void => {
    setSelectedImeiInputMethod(Number(event.target.value));
    setSelectedImeis([]);
  };

  const handleImeiInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const textValue = event.target.value;
    const imeiArray = textValue
      .split("\n")
      .map((imei) => imei.trim())
      .filter(Boolean);
    setSelectedImeis(imeiArray);
  };

  const parseCSV = (csvData: string): string[] => {
    const rows = csvData
      /* TODO: This should probably be added to utils or something later. We should have common REGEX files for this kind of stuff.
      /\r\n|\n|\r/gm is a regular expression that matches carriage return and newline characters.
        \r\n matches a carriage return followed by a newline (Windows-style line endings).
        \n matches a newline character (UNIX/Linux-style line endings).
        \r matches a carriage return character (old Mac-style line endings).
      gm are flags used with the regular expression:
        g is the global search flag, which makes the expression search for all matches in the string, not just the first.
        m is the multiline flag, which makes the expression treat the string as multiple lines.
      */
      .replace(/(\r\n|\n|\r)/gm, "")
      .trim()
      .split(",");
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }
    return rows;
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event): void => {
        const csvData = event.target?.result as string;
        const parsedData = parseCSV(csvData);
        setSelectedImeis(parsedData);
      };
    }
  };

  const handleRemoveFile = (): void => {
    setSelectedImeis([]);
  };

  const handleCloseClick = (): void => {
    setSelectedImeiInputMethod(undefined);
    setSelectedImeis([]);
    handleClose();
  };

  const handleSubmitClick = (): void => {
    handleSubmit(selectedImeis);
    handleCloseClick();
  };

  const isSubmitDisabled = isLoading || !selectedImeis.length;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.EnterImeisDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.common.texts.enterImeiCodes()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
            data-testid={TestIds.EnterImeisDialog.CloseButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {translations.common.texts.enterImeiCodesInfo()}
          <Box sx={{ mt: 3 }}>
            <FormControl>
              <RadioGroup
                row
                id="imeiInputMethod"
                value={selectedImeiInputMethod ?? ""}
                onChange={handleImeiInputMethodChange}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio size="small" />}
                  label={translations.common.texts.enterTheImeiCodes()}
                  data-testid={TestIds.EnterImeisDialog.EnterImeis}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio size="small" />}
                  label={translations.common.texts.uploadImeiCodes()}
                  data-testid={TestIds.EnterImeisDialog.UploadImeis}
                />
              </RadioGroup>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              {selectedImeiInputMethod === 1 && (
                <CustomTextField
                  id="imei-input"
                  testId={TestIds.EnterImeisDialog.ImeiInput}
                  multiline
                  type="number"
                  minRows={3}
                  maxRows={8}
                  label={translations.common.texts.imeis()}
                  placeholder={translations.common.texts.enterImeisPlaceholder()}
                  onChange={handleImeiInputChange}
                />
              )}
              {selectedImeiInputMethod === 2 && (
                <FileUpload
                  infoText={translations.common.texts.uploadImeisPlaceholder()}
                  acceptedFileType="text/csv"
                  handleFileChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2, mt: 0 }}>
          <Button
            variant="contained"
            onClick={handleSubmitClick}
            disabled={isSubmitDisabled}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
            data-testid={TestIds.EnterImeisDialog.SubmitButton}
          >
            {translations.common.buttons.submit()}
          </Button>
          <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.EnterImeisDialog.CancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EnterImeisDialog;
