/* Copyright */
import React from "react";
import { CallEventObserver, CallEventSet, Event, Maybe } from "@sade/data-access";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { translations } from "../../../generated/translationHelper";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import CallHistoryTableRow from "./call-history-table-row";
import TestIds from "../../../test-ids/test-ids";

type CallHistoryTableProps = {
  eventSet?: CallEventSet;
  userHasAccessToActiveCalls: boolean;
  handleRowClick: (deviceId: string) => void;
};

const CallHistoryTable: React.FC<CallHistoryTableProps> = ({
  eventSet,
  userHasAccessToActiveCalls,
  handleRowClick,
}) => {
  const tableElement = React.useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [events, setEvents] = React.useState<Event[]>([]);
  const [nextToken, setNextToken] = React.useState<Maybe<string>>(undefined);

  /**
   * Fetch events on mount.
   */
  React.useEffect(() => {
    const fetchEvents = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const fetchedNextToken = await eventSet?.fetchEndedCallEvents();
        setNextToken(fetchedNextToken);
        setEvents(eventSet?.getEndedCallEvents() ?? []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [eventSet]);

  /**
   * Subscribe to eventSet updates.
   */
  React.useEffect(() => {
    const callEventObserver: CallEventObserver = {
      onCallEventUpdate(callEventSet: CallEventSet): void {
        const events = callEventSet.getEndedCallEvents();
        setEvents(events);
      },
    };

    eventSet?.addObserver(callEventObserver);

    return (): void => {
      eventSet?.removeObserver(callEventObserver);
    };
  }, [eventSet]);

  /**
   * Hook to the scrolling to fetch new events
   */
  const loadMoreEvents = React.useCallback(() => {
    const getData = async (): Promise<void> => {
      const fetchedNextToken = await eventSet?.fetchEndedCallEvents(nextToken);
      const responseList = eventSet?.getEndedCallEvents();
      if (responseList) {
        setEvents((oldState) => [...oldState, ...responseList]);
      }
      setNextToken(fetchedNextToken);
    };

    if (nextToken) getData();
  }, [eventSet, nextToken]);

  const scrollListener = React.useCallback(() => {
    const element = tableElement.current;
    if (element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
        loadMoreEvents();
      }
    }
  }, [isLoading, loadMoreEvents]);

  React.useLayoutEffect(() => {
    const tableRef = tableElement.current;
    tableRef?.addEventListener("scroll", scrollListener);
    return (): void => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box
      ref={tableElement}
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.CallHistoryTable.Table}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.monitoring.texts.eventTime()}</TableCell>
              <TableCell>{translations.common.texts.imei()}</TableCell>
              <TableCell>{translations.common.texts.callerId()}</TableCell>
              <TableCell>{translations.common.texts.deviceName()}</TableCell>
              <TableCell>{translations.monitoring.texts.eventType()}</TableCell>
              <TableCell>{translations.monitoring.texts.answeredBy()}</TableCell>
              <TableCell>{translations.monitoring.texts.duration()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.length > 0
              ? events.map((event, index) => (
                  <CallHistoryTableRow
                    key={index}
                    event={event}
                    tableRowIndex={index}
                    userHasAccessToActiveCalls={userHasAccessToActiveCalls}
                    handleRowClick={handleRowClick}
                  />
                ))
              : !isLoading && <TableEmptyRow colspan={7} />}
            {isLoading && <TableLoadingRow colspan={7} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CallHistoryTable;
