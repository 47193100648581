/* Copyright */
import * as React from "react";
import { Avatar, Badge } from "@mui/material";
import theme from "../../app/theme";

type CustomBadgedAvatarProps = {
  icon: React.ReactNode;
  isBadgeActive: boolean;
  badgeColor: string;
  handleOnClick: (event: React.SyntheticEvent<HTMLElement>) => void;
};

const CustomBadgedAvatar: React.FC<CustomBadgedAvatarProps> = ({ icon, isBadgeActive, badgeColor, handleOnClick }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: isBadgeActive
            ? badgeColor === "warning"
              ? theme.palette.warning.main
              : theme.palette.primary.light
            : "",
        },
        cursor: "pointer",
      }}
    >
      <Avatar sx={{ bgcolor: "transparent", color: theme.palette.secondary.main }} onClick={handleOnClick}>
        {icon}
      </Avatar>
    </Badge>
  );
};

export default CustomBadgedAvatar;
