/* Copyright */
import { Event, Maybe } from "@sade/data-access";

import secondMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-02.svg";
import thirdMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-03.svg";
import fourthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-04.svg";
import fifthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-05.svg";
import sixthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-06.svg";
import seventhMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-07.svg";
import eightMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-08.svg";
import ninthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-09.svg";
import tenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-10.svg";
import eleventhMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-11.svg";
import twelfthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-12.svg";
import thirteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-13.svg";
import fourteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-14.svg";
import fifteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-15.svg";
import sixteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-16.svg";
import seventeenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-17.svg";
import eighteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-18.svg";
import nineteenthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-19.svg";
import twentiethMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-20.svg";
import twentyFirstMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-21.svg";
import twentySecondMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-22.svg";
import twentyThirdMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-23.svg";
import twentyFourthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-24.svg";
import twentyFifthMarker from "../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-25.svg";

export const getLocationHistoryMarker = (index: number): Maybe<string> => {
  switch (index) {
    case 2:
      return secondMarker;
    case 3:
      return thirdMarker;
    case 4:
      return fourthMarker;
    case 5:
      return fifthMarker;
    case 6:
      return sixthMarker;
    case 7:
      return seventhMarker;
    case 8:
      return eightMarker;
    case 9:
      return ninthMarker;
    case 10:
      return tenthMarker;
    case 11:
      return eleventhMarker;
    case 12:
      return twelfthMarker;
    case 13:
      return thirteenthMarker;
    case 14:
      return fourteenthMarker;
    case 15:
      return fifteenthMarker;
    case 16:
      return sixteenthMarker;
    case 17:
      return seventeenthMarker;
    case 18:
      return eighteenthMarker;
    case 19:
      return nineteenthMarker;
    case 20:
      return twentiethMarker;
    case 21:
      return twentyFirstMarker;
    case 22:
      return twentySecondMarker;
    case 23:
      return twentyThirdMarker;
    case 24:
      return twentyFourthMarker;
    case 25:
      return twentyFifthMarker;
    default:
      return undefined;
  }
};

export type LocationData = {
  index: number;
  event: Event;
  showOnMap?: boolean;
};

export const customMapStyle = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
];
