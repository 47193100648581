/* Copyright */
import * as React from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TestIds from "../../../../test-ids/test-ids";
import { translations } from "../../../../generated/translationHelper";
import { PeripheralInfo } from "@sade/data-access";
import { getPeripheralTypeTranslation } from "../../../../utils/utils";

type DevicePeripheralsRemoveConfirmationDialogProps = {
  open: boolean;
  peripheral: PeripheralInfo;
  handleClose: () => void;
  handleRemove: () => void;
};

const DevicePeripheralsRemoveConfirmationDialog: React.FC<DevicePeripheralsRemoveConfirmationDialogProps> = ({
  open,
  peripheral,
  handleClose,
  handleRemove,
}) => {
  const [isBusy, setIsBusy] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (open) {
      setIsBusy(false);
    }
  }, [open]);

  const handleRemoveClick = (): void => {
    setIsBusy(true);
    handleRemove();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.DevicePeripheralsRemoveConfirmationDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.devicePeripherals.texts.removeConfirmationHeader({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        })}
        <IconButton
          data-testid={TestIds.DevicePeripheralsRemoveConfirmationDialog.CloseButton}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography>
            {translations.devicePeripherals.texts.removeConfirmationContent({
              peripheralType: getPeripheralTypeTranslation(peripheral.type),
            })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => handleRemoveClick()}
          disabled={isBusy}
          color="error"
          data-testid={TestIds.DevicePeripheralsRemoveConfirmationDialog.ConfirmButton}
        >
          {translations.common.buttons.remove()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={isBusy}
          data-testid={TestIds.DevicePeripheralsRemoveConfirmationDialog.ActionCancelButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DevicePeripheralsRemoveConfirmationDialog;
