/* Copyright */
import * as React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Nullable, Team, UserInvitationStatus } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { NavLink } from "react-router-dom";
import TestIds from "../../../test-ids/test-ids";

type AccountDetailsTeamsInformationProps = {
  userId: string;
  teams: Team[];
};

const AccountDetailsTeamsInformation: React.FC<AccountDetailsTeamsInformationProps> = ({ userId, teams }) => {
  const [chosenTeam, setChosenTeam] = React.useState<Nullable<Team>>(null);
  const [chosenDeviceId, setChosenDeviceId] = React.useState<Nullable<string>>(null);

  React.useEffect(() => {
    if (teams.length > 0) {
      setChosenTeam(teams[0]);
      const devices = teams[0].getDevices();
      if (devices.length > 0) setChosenDeviceId(devices[0].deviceId ?? null);
    } else {
      setChosenTeam(null);
      setChosenDeviceId(null);
    }
  }, [teams]);

  const theme = useTheme();

  const chipColor = (status: UserInvitationStatus): string => {
    switch (status) {
      case UserInvitationStatus.Accepted:
        return theme.palette.success.main;
      case UserInvitationStatus.Pending:
        return "";
      case UserInvitationStatus.Declined:
        return theme.palette.error.main;
      case UserInvitationStatus.Expired:
        return theme.palette.error.main;
      default:
        return "";
    }
  };

  const chipText = (status: UserInvitationStatus): string => {
    switch (status) {
      case UserInvitationStatus.Accepted:
        return translations.common.texts.active();
      case UserInvitationStatus.Pending:
        return translations.common.texts.invitationStatus({ status: translations.common.texts.pending() });
      case UserInvitationStatus.Declined:
        return translations.common.texts.invitationStatus({ status: translations.common.texts.declined() });
      case UserInvitationStatus.Expired:
        return translations.common.texts.invitationStatus({ status: translations.common.texts.expired() });
      default:
        return translations.common.texts.unknown();
    }
  };

  const showTeamSelect = teams.length > 1 || (teams.length === 1 && teams[0].getDevices().length > 1);

  function handleDeviceSelect(deviceId: string): void {
    const team = teams.find((team) => team.getDevices().find((device) => device.deviceId === deviceId) !== undefined);
    setChosenTeam(team ?? null);
    setChosenDeviceId(team ? deviceId : null);
  }

  return (
    <Box>
      <Box display={"flex"}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">
            {translations.accountDetailsDialog.texts.team({
              team: chosenTeam?.getName() && chosenTeam?.getName().length > 0 ? `"${chosenTeam?.getName()}"` : "",
            })}
          </Typography>
          <Typography variant="body1">
            {translations.accountDetailsDialog.texts.aroundTheDevice()}{" "}
            <NavLink
              to={"/devices/" + chosenDeviceId}
              data-testid={TestIds.EverThereAccountDetailsDialog.DeviceDetailsLink}
            >
              {chosenDeviceId}
            </NavLink>
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, alignContent: "flex-end" }} display={showTeamSelect ? "flex" : "none"}>
          <FormControl>
            <InputLabel id="team-select-label" shrink disabled={teams.length === 0}>
              {translations.accountDetailsDialog.texts.deviceImei()}
            </InputLabel>
            <Select
              sx={{ width: 252 }}
              id="team-select"
              value={chosenDeviceId ?? ""}
              size="small"
              onChange={(event): void => {
                handleDeviceSelect(event.target.value as string);
              }}
              data-testid={TestIds.EverThereAccountDetailsDialog.DeviceSelector}
            >
              {teams.map((team) =>
                team.getDevices().map((device, index) => (
                  <MenuItem
                    key={device.deviceId}
                    value={device.deviceId}
                    data-testid={`${TestIds.EverThereAccountDetailsDialog.DeviceMenuItem}-${index}`}
                  >
                    {device.deviceId}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <TableContainer sx={{ overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.user()}</TableCell>
              <TableCell>{translations.common.texts.role()}</TableCell>
              <TableCell>{translations.common.texts.wearer()}</TableCell>
              <TableCell>{translations.common.texts.accountStatus()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chosenTeam?.getMembers().map((member, index) => (
              <TableRow key={member.userId} selected={member.userId === userId}>
                <TableCell data-testid={`${TestIds.EverThereAccountDetailsDialog.UserEmail}-${index}`}>
                  {member.email}
                </TableCell>
                <TableCell data-testid={`${TestIds.EverThereAccountDetailsDialog.UserRole}-${index}`}>
                  {member.isTeamManager ? (
                    <Chip
                      size="small"
                      label={translations.common.texts.teamManager()}
                      sx={{ backgroundColor: theme.palette.purple.main, color: "white" }}
                    />
                  ) : (
                    translations.common.texts.teamMember()
                  )}
                </TableCell>
                <TableCell>
                  {member.wearerOfDevice === chosenDeviceId ? (
                    <Chip
                      size="small"
                      label={translations.common.texts.wearer()}
                      sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  {
                    <Chip
                      size="small"
                      label={chipText(member.invitationStatus)}
                      sx={{
                        backgroundColor: chipColor(member.invitationStatus),
                        color: member.invitationStatus !== UserInvitationStatus.Pending ? "white" : "",
                      }}
                    />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AccountDetailsTeamsInformation;
