/* Copyright */
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { OtaManager, OtaPackageInfo, OtaVariant } from "@sade/data-access";
import React from "react";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import DeleteOtaPackageConfirmationDialog from "../../dialogs/ota-packages/delete-ota-package-confirmation-dialog";

type OtaPackagesTableRowProps = {
  otaPackage: OtaPackageInfo;
  tableRowIndex?: number;
  reFetchData: () => void;
};

const OtaPackagesTableRow: React.FC<OtaPackagesTableRowProps> = ({ otaPackage, reFetchData }) => {
  const { showSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState<boolean>(false);

  const toggleIsConfirmDeleteDialogOpen = (): void => {
    setIsConfirmDeleteDialogOpen(!isConfirmDeleteDialogOpen);
  };

  // It may be that this function needs to be moved up, perhaps to ota-packages-view
  const handleDeleteOtaPackage = async (): Promise<void> => {
    setIsDeleting(true);
    const packageName = otaPackage.otaPackageName;
    try {
      await OtaManager.getInstance().removeOtaPackage(otaPackage.otaId);
      showSnackbar(
        translations.deleteOtaPackageConfirmationDialog.texts.successText({ packageName }),
        SeverityType.Success
      );
      reFetchData();
      toggleIsConfirmDeleteDialogOpen();
    } catch (error) {
      showSnackbar(translations.deleteOtaPackageConfirmationDialog.texts.failText({ packageName }), SeverityType.Error);
    } finally {
      setIsDeleting(false);
    }
  };

  const solveVariant = (): string => {
    switch (otaPackage.variant) {
      case OtaVariant.French:
        return translations.common.texts.frenchAudio();
      case OtaVariant.Spanish:
        return translations.common.texts.spanishAudio();
      case OtaVariant.English:
        return translations.common.texts.englishAudio();
    }
  };

  return (
    <React.Fragment>
      <TableRow hover data-testid={TestIds.OtaPackages.TableRow}>
        <TableCell>{otaPackage.version}</TableCell>
        <TableCell>{solveVariant()}</TableCell>
        <TableCell>{otaPackage.deviceType}</TableCell>
        <TableCell>{otaPackage.otaPackageName}</TableCell>
        <TableCell>{otaPackage.size}</TableCell>
        <TableCell>{otaPackage.hash}</TableCell>
        <TableCell>{otaPackage.otaFilename}</TableCell>
        <TableCell>
          <IconButton aria-label="search" onClick={(): void => toggleIsConfirmDeleteDialogOpen()}>
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteOtaPackageConfirmationDialog
        open={isConfirmDeleteDialogOpen}
        handleClose={toggleIsConfirmDeleteDialogOpen}
        handleSubmit={handleDeleteOtaPackage}
        otaPackage={otaPackage}
        isDeleting={isDeleting}
      />
    </React.Fragment>
  );
};

export default OtaPackagesTableRow;
