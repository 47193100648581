/* Copyright */
import AddIcon from "@mui/icons-material/Add";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Box, Button } from "@mui/material";
import { OtaPackageInfo } from "@sade/data-access/src/generated/gqlDevice";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import CreateOtaPackageDialog from "../../dialogs/ota-packages/create-ota-package-dialog";
import SendBulkOtasDialog from "../../dialogs/ota-packages/send-bulk-otas-dialog";

type OtaPackagesViewHeaderProps = {
  otaPackages: OtaPackageInfo[];
  reFetchData: () => void;
};

const OtaPackagesViewHeader: React.FC<OtaPackagesViewHeaderProps> = ({ otaPackages, reFetchData }) => {
  const [isCreateOtaPackageDialogOpen, setIsCreateOtaPackageDialogOpen] = React.useState<boolean>(false);
  const [isSendBulkOtasDialogOpen, setIsSendBulkOtasDialogOpen] = React.useState<boolean>(false);

  const handleToggleOpenNewOtaPackageDialog = (): void => {
    setIsCreateOtaPackageDialogOpen(!isCreateOtaPackageDialogOpen);
  };

  const handleToggleOpenSendBulkOtasDialog = (): void => {
    setIsSendBulkOtasDialogOpen(!isSendBulkOtasDialogOpen);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleToggleOpenNewOtaPackageDialog}
          sx={{ mr: 2 }}
          data-testid={TestIds.AdminOtaPackages.AddNewOtaPackage}
        >
          {translations.otaPackages.buttons.addNewOtaPackage()}
        </Button>
        <Button
          variant="contained"
          startIcon={<PublishOutlinedIcon />}
          onClick={handleToggleOpenSendBulkOtasDialog}
          disabled={!otaPackages.length}
          data-testid={TestIds.AdminOtaPackages.SendBulkOtas}
        >
          {translations.otaPackages.buttons.sendBulkOtas()}
        </Button>
      </Box>
      <CreateOtaPackageDialog
        open={isCreateOtaPackageDialogOpen}
        onClose={handleToggleOpenNewOtaPackageDialog}
        onUploadComplete={reFetchData}
      />
      <SendBulkOtasDialog
        open={isSendBulkOtasDialogOpen}
        handleClose={handleToggleOpenSendBulkOtasDialog}
        otaPackages={otaPackages}
      />
    </React.Fragment>
  );
};

export default OtaPackagesViewHeader;
