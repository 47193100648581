/* Copyright */
import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { DeviceStatus } from "@sade/data-access";

type ReportsViewFiltersProps = {
  isLoading: boolean;
  handleStatusSelectChange: (event: SelectChangeEvent<string[]>) => void;
  selectedStatuses: string[];
};

const ReportsViewFilters: React.FC<ReportsViewFiltersProps> = ({
  isLoading,
  handleStatusSelectChange,
  selectedStatuses,
}) => {
  const handleSelectChange = (event: SelectChangeEvent<string[]>): void => {
    if (!selectedStatuses.includes("All") && event.target.value.includes("All")) {
      // All was selected
      event.target.value = Object.values(DeviceStatus);
      event.target.value.push("All");
    }

    if (selectedStatuses.includes("All") && !event.target.value.includes("All")) {
      // All was deselected
      event.target.value = [];
    }

    if (selectedStatuses.includes("All") && Object.values(DeviceStatus).length >= event.target.value.length) {
      // All selection is not valid as a selection was removed
      if (Array.isArray(event.target.value)) {
        event.target.value.splice(event.target.value.indexOf("All"), 1);
      }
    }

    handleStatusSelectChange(event);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { xs: "100%", lg: "50rem" },
      }}
    >
      <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
        <InputLabel id="filter-by-status-label" shrink disabled={isLoading}>
          {translations.reports.inputs.deviceStatuses()}
        </InputLabel>
        <Select
          id="status"
          size="small"
          multiple
          labelId="filter-by-status-label"
          data-testid={TestIds.Reports.OpenStatusSelection}
          inputProps={{ "data-testid": TestIds.Reports.StatusSelect }}
          value={selectedStatuses}
          onChange={handleSelectChange}
          renderValue={(selected): string => (selected.includes("All") ? "All" : selected.join(", "))}
          disabled={isLoading}
          defaultChecked={true}
        >
          <MenuItem
            key={0xff}
            value={"All"}
            style={{ borderBottom: "1px solid #cccccc" }}
            data-testid={`${TestIds.Reports.StatusFilterLabel}-${0xff}`}
          >
            <Checkbox
              checked={selectedStatuses.includes("All")}
              data-testid={`${TestIds.Reports.StatusFilter}-${0xff}`}
            />
            <ListItemText primary="All" />
          </MenuItem>
          {(Object.keys(DeviceStatus) as Array<keyof typeof DeviceStatus>).map((state, index) => (
            <MenuItem
              key={index}
              value={DeviceStatus[state]}
              data-testid={`${TestIds.Reports.StatusFilterLabel}-${index}`}
            >
              <Checkbox
                checked={selectedStatuses.indexOf(DeviceStatus[state]) > -1}
                data-testid={`${TestIds.Reports.StatusFilter}-${index}`}
              />
              <ListItemText primary={DeviceStatus[state]} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{String(" ")}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default ReportsViewFilters;
