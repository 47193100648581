/* Copyright */
import * as React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import CustomTextField from "../../common/textfield";
import { translations } from "../../../generated/translationHelper";
import { fallOccurOptions, wearingTheDeviceOptions } from "../../../utils/eventUtils";
import { EventReport } from "@sade/data-access";

type MissedFallReportFormProps = {
  reportData: EventReport;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MissedFallReportForm: React.FC<MissedFallReportFormProps> = ({
  reportData,
  handleSelectChange,
  handleInputChange,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" className="title">
        {translations.missedFallReport.texts.missedFallReportSubtitle()}
      </Typography>

      <FormControl sx={{ textAlign: "start", mt: 4, mb: 4 }}>
        <InputLabel id="fall-occurredAt" shrink>
          {translations.missedFallReport.fallOccur.title()}
        </InputLabel>
        <Select
          name="occurredAt"
          size="small"
          onChange={handleSelectChange}
          labelId="fall-occurredAt"
          value={reportData.occurredAt}
          data-testid={""}
        >
          {fallOccurOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ textAlign: "start", mb: 4 }}>
        <InputLabel id="deviceWornType" shrink>
          {translations.missedFallReport.wearingTheDevice.title()}
        </InputLabel>
        <Select
          name="deviceWornType"
          size="small"
          onChange={handleSelectChange}
          labelId="deviceWornType"
          value={reportData.deviceWornType}
          data-testid={""}
        >
          {wearingTheDeviceOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomTextField
        label={translations.missedFallReport.wayOfTheFall.title()}
        id="fallExplanation"
        value={reportData.fallExplanation}
        onChange={handleInputChange}
        errorMessage=""
        testId={""}
      />

      {/* <FormControl sx={{ textAlign: "start", mt: 4, mb: 4 }}>
        <InputLabel id="fall-position" shrink>
          {translations.missedFallReport.fallPosition.title()}
        </InputLabel>
        <Select
          name="fallPosition"
          size="small"
          onChange={handleSelectChange}
          labelId="fall-position"
          value={reportData.fallPosition}
          data-testid={""}
        >
          {fallingPositionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ textAlign: "start", mb: 4 }}>
        <InputLabel id="revocerType" shrink>
          {translations.missedFallReport.recovery.title()}
        </InputLabel>
        <Select
          name="revocerType"
          size="small"
          onChange={handleSelectChange}
          labelId="revocerType"
          value={reportData.revocerType}
          data-testid={""}
        >
          {recoveryTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomTextField
        label={translations.missedFallReport.causeOfTheFall.title()}
        id="fallCause"
        value={reportData.fallCause}
        onChange={handleInputChange}
        errorMessage=""
        testId={""}
      />

      <FormControl sx={{ textAlign: "start", mt: 4, mb: 4 }}>
        <InputLabel id="walkingAid" shrink>
          {translations.missedFallReport.walkingAidInvolvement.title()}
        </InputLabel>
        <Select
          name="walkingAid"
          size="small"
          onChange={handleSelectChange}
          labelId="walkingAid"
          value={reportData.walkingAid}
          data-testid={""}
        >
          {walkingAidInvolvementOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ textAlign: "start", mb: 4 }}>
        <InputLabel id="surface" shrink>
          {translations.missedFallReport.fallSurface.title()}
        </InputLabel>
        <Select
          name="surface"
          size="small"
          onChange={handleSelectChange}
          labelId="surface"
          value={reportData.surface}
          data-testid={""}
        >
          {surfaceOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.definition()}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <Box sx={{ mt: 4 }}>
        <CustomTextField
          label={translations.missedFallReport.otherRemarks.title()}
          id="remarks"
          value={reportData.remarks}
          onChange={handleInputChange}
          errorMessage=""
          testId={""}
        />
      </Box>
    </Box>
  );
};

export default MissedFallReportForm;
