/* Copyright */
import React from "react";
import ReactDOM from "react-dom/client";
import { withTranslation } from "react-i18next";
import { AuthWrapper, AwsConfiguration } from "@sade/data-access";
import { AwsConfigFactory } from "./utils/AwsConfigFactory";
import App from "./app";
import "./app/styles/css/main.css";
import { initLocalization } from "./locales/localisator";
import TokenApp from "./tokenApp";
import { initOidcTokenHolderFromUrl } from "./utils/oidcTokenUtil";
import { getSsoPath, parseIdpFromPath, setSsoPathHolder } from "./utils/ssoPathUtil";

const ssoPath = parseIdpFromPath();
setSsoPathHolder(ssoPath);
AwsConfiguration.configure(AwsConfigFactory.getConfig(getSsoPath()?.redirectUrl));
const tokenMode = initOidcTokenHolderFromUrl();
if (!tokenMode) {
  console.debug("starting app in normal mode");
  AuthWrapper.configureAmplify();
} else {
  console.debug("starting app in token mode");
}
initLocalization();
const TranslatedApp = withTranslation()(tokenMode ? TokenApp : App);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<TranslatedApp />);
