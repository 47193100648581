/* Copyright */
import {
  FormControl,
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BackendFactory,
  Libris2,
  Maybe,
  OrganizationParameterHierarchy,
  Panel4200x,
  ParameterValueType,
} from "@sade/data-access";
import * as React from "react";
import theme from "../../app/theme";
import { translations } from "../../generated/translationHelper";
import TestIds from "../../test-ids/test-ids";
import { getParameterFromHierarchy } from "../../utils/parameterUtils";
import { OrganizationInfo, ParameterNames, PartnerDetails } from "../../utils/utils";
import { validateUrl } from "../../utils/validation";
import RealmPicker from "../common/realm-picker";
import CustomTextField from "../common/textfield";

type PartnerDetailsViewBasicSettingsProps = {
  isAddNewMode: boolean;
  partnerDetails: PartnerDetails;
  childOrganizations?: OrganizationInfo[];
  parameterHierarchy?: OrganizationParameterHierarchy;
  handleSelectChange: (event: SelectChangeEvent) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DEFAULT_EMPTY_VALUE = "-" as const;

const urlError = (url: string): string => {
  return url.length !== 0 && !validateUrl(url) ? translations.common.texts.invalidUrl() : "";
};

const PartnerDetailsViewBasicSettings: React.FC<PartnerDetailsViewBasicSettingsProps> = ({
  isAddNewMode,
  partnerDetails,
  childOrganizations,
  parameterHierarchy,
  handleSelectChange,
  handleInputChange,
}) => {
  const getSoftwareVersionMin = (deviceType: string): Maybe<ParameterValueType> => {
    switch (deviceType) {
      case Libris2.type: {
        // TODO: If the parameter is overridden at the realm level while this component is being rendered,
        // the new value will not be reflected. Passing the parameter state between components is possible
        // but perhaps not worth the effort.
        const softwareVersionMin = parameterHierarchy
          ? getParameterFromHierarchy(ParameterNames.SOFTWARE_RELEASE, parameterHierarchy, "device")
          : DEFAULT_EMPTY_VALUE;
        return softwareVersionMin?.toString().length ? softwareVersionMin : DEFAULT_EMPTY_VALUE;
      }
      default:
        return DEFAULT_EMPTY_VALUE;
    }
  };

  const getVariant = (deviceType: string): Maybe<ParameterValueType> => {
    switch (deviceType) {
      case Libris2.type: {
        const variant = parameterHierarchy
          ? getParameterFromHierarchy(ParameterNames.VARIANT, parameterHierarchy, "device")
          : DEFAULT_EMPTY_VALUE;
        return variant?.toString().length ? variant : DEFAULT_EMPTY_VALUE;
      }
      default:
        return DEFAULT_EMPTY_VALUE;
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} xl={4} sx={{ pr: useMediaQuery(theme.breakpoints.down("md")) ? 0 : 2 }}>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              {translations.partners.texts.parentRealm()}
            </Typography>
            {isAddNewMode && (
              <Typography variant="body1" sx={{ mt: 1, mb: 4 }}>
                {translations.partners.texts.realmInfo()}
              </Typography>
            )}
            <FormControl fullWidth>
              {isAddNewMode ? (
                <RealmPicker
                  childOrganizations={childOrganizations}
                  testId={TestIds.PartnerDetails.RealmSelect}
                  onRealmPicked={(realmId: string): void =>
                    handleSelectChange({ target: { value: realmId, name: "parentId" } } as SelectChangeEvent)
                  }
                />
              ) : (
                <Typography variant="body1">{partnerDetails.parentName}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <Typography variant="h6" sx={{ mt: 2, mb: 3 }}>
            {translations.partners.texts.partnerName()}
          </Typography>

          <CustomTextField
            label={translations.partners.texts.partnerName()}
            id="partnerName"
            testId={TestIds.PartnerDetails.PartnerNameInput}
            required={true}
            value={partnerDetails.partnerName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4} sx={{ display: "flex", alignItems: "end" }}>
          <CustomTextField
            label={translations.partners.texts.realmName()}
            id="realmName"
            testId={TestIds.PartnerDetails.RealmNameInput}
            required={true}
            value={partnerDetails.realmName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4} sx={{ display: "flex", alignItems: "end" }}>
          <CustomTextField
            label={translations.partners.texts.networkId()}
            id="networkId"
            testId={TestIds.PartnerDetails.NetworkIdInput}
            value={partnerDetails.networkId}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 2, mb: 0 }}>
            {translations.common.texts.devices()}
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translations.common.texts.deviceType()}</TableCell>
                  <TableCell>{translations.partners.texts.softwareVersionMin()}</TableCell>
                  <TableCell>{translations.common.texts.variant()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BackendFactory.getBackend()
                  .getSupportedDeviceTypes()
                  .filter((deviceType) => deviceType !== Panel4200x.type)
                  .map((deviceType, index) => (
                    <TableRow key={index}>
                      <TableCell>{deviceType}</TableCell>
                      <TableCell>{getSoftwareVersionMin(deviceType)}</TableCell>
                      <TableCell>{getVariant(deviceType)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} md={6} xl={4} sx={{ pr: useMediaQuery(theme.breakpoints.down("md")) ? 0 : 2 }}>
            <Typography variant="h6" sx={{ mt: 2, mb: 3 }}>
              {translations.partners.texts.billing()}
            </Typography>
            <CustomTextField
              label={translations.partners.texts.netsuiteId()}
              id="netsuiteId"
              testId={TestIds.PartnerDetails.NetsuiteIdInput}
              value={partnerDetails.netsuiteId}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} md={6} xl={4} sx={{ pr: useMediaQuery(theme.breakpoints.down("md")) ? 0 : 2 }}>
            <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
              {translations.partners.texts.patientTextUrl()}
            </Typography>
            <CustomTextField
              label={translations.partners.texts.patientTextUrl()}
              id="patientTextUrl"
              error={!!urlError(partnerDetails.patientTextUrl ?? "")}
              errorMessage={urlError(partnerDetails.patientTextUrl ?? "")}
              testId={TestIds.PartnerDetails.PartnerNameInput}
              value={partnerDetails.patientTextUrl}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            {translations.partners.texts.federatedIdentity()}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            {translations.partners.texts.federatedIdentityInfo()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={translations.partners.texts.identityProvider()}
            id="ssoIdentityProvider"
            testId={TestIds.PartnerDetails.SsoIdentityProvider}
            value={partnerDetails.ssoIdentityProvider}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            disabled={partnerDetails.ssoIdentityProvider === ""}
            label={translations.partners.texts.defaultRole()}
            id="ssoDefaultRole"
            testId={TestIds.PartnerDetails.SsoDefaultRole}
            value={partnerDetails.ssoDefaultRole}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PartnerDetailsViewBasicSettings;
