/* Copyright */
import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import AuthenticationLayout from "../layout/authentication/authentication-layout";
import { LoginFormProps, Paths } from "../../utils/utils";
import { getPath } from "../../utils/ssoPathUtil";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import Loading from "../loading/loading";

const LoginRoute: React.FC<{
  normalLogin: React.ComponentType<LoginFormProps>;
  ssoLogin: React.ComponentType<LoginFormProps>;
  props: LoginFormProps;
}> = ({ normalLogin, ssoLogin, props }) => {
  const authUserContext = useAuthenticatedUser();
  const { sso } = useParams();

  if (authUserContext.isLoadingAuthenticatedUser || authUserContext.isChangingRealm) {
    return <Loading />;
  }

  return !authUserContext.authenticatedUser ? (
    <AuthenticationLayout>{React.createElement(sso ? ssoLogin : normalLogin, props)}</AuthenticationLayout>
  ) : (
    <Navigate replace to={getPath(Paths.ROOT)} />
  );
};

export default LoginRoute;
