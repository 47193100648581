/* Copyright */
import * as React from "react";
import { Box, Grid } from "@mui/material";

const CleanLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box className="layout-root">
      <Grid container className="clean-layout-content-container">
        {children}
      </Grid>
    </Box>
  );
};

export default CleanLayout;
