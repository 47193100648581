/* Copyright */

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { Device, PeripheralInfo, PeripheralType } from "@sade/data-access";
import React from "react";
import {
  SeverityType,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { Peripheral, getPeripheralTypeTranslation } from "../../../../utils/utils";
import DevicePeripheralsMHealthDeviceAddDialog from "./device-peripherals-mhealth-device-add-dialog";
import PeripheralsTable from "./peripherals-table/peripherals-table";

type DeviceMHealthDeviceProps = {
  peripherals: PeripheralInfo[];
  device?: Device;
  onRefresh: () => void;
};

const DevicePeripheralsMHealthDevice: React.FC<DeviceMHealthDeviceProps> = ({ peripherals, device, onRefresh }) => {
  const [isAddMHealthDeviceDialogOpen, setIsAddMHealthDeviceDialogOpen] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const tableEl = React.useRef<HTMLDivElement>(null);

  const toggleIsAddMHealthDeviceDialogOpen = (): void => {
    setIsAddMHealthDeviceDialogOpen(!isAddMHealthDeviceDialogOpen);
  };

  const includesMHealthDevices = (peripherals: PeripheralInfo[]): boolean => {
    return peripherals.filter((peripheral) => peripheral.type !== PeripheralType.BasicPendant).length > 0;
  };

  const handleRemovePeripheral = async (peripheral: Peripheral): Promise<void> => {
    if (!peripheral) return;

    try {
      await device?.removePeripheral(peripheral.type, peripheral.serialNumber);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralRemoved({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } catch (e) {
      console.error("error", e);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralRemoveFailed({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    }
    onRefresh();
  };

  const handleAttachPeripheral = async (peripheral?: Peripheral): Promise<void> => {
    if (!peripheral) {
      toggleIsAddMHealthDeviceDialogOpen();
      return;
    }

    try {
      await device?.addPeripheral(peripheral.type, peripheral.serialNumber);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralAddedSuccesfully({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } catch (e) {
      console.error("error", e);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralAddFailed({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } finally {
      onRefresh();
      toggleIsAddMHealthDeviceDialogOpen();
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {translations.devicePeripherals.texts.mHealthDevicesTitle()}
        </Typography>
        {includesMHealthDevices(peripherals) && (
          <PeripheralsTable
            peripherals={peripherals.filter((peripheral) => peripheral.type !== PeripheralType.BasicPendant)}
            tableRef={tableEl}
            isLoading={false}
            removePeripheral={handleRemovePeripheral}
          />
        )}
        <Button
          variant="outlined"
          onClick={toggleIsAddMHealthDeviceDialogOpen}
          startIcon={<AddIcon />}
          sx={{ textTransform: "none", mt: 2 }}
          data-testid={TestIds.DevicePeripheralsDialog.AddMHealthDeviceButton}
        >
          {translations.devicePeripherals.buttons.addDevice()}
        </Button>
      </Box>

      <DevicePeripheralsMHealthDeviceAddDialog
        open={isAddMHealthDeviceDialogOpen}
        handleClose={handleAttachPeripheral}
      />
    </React.Fragment>
  );
};

export default DevicePeripheralsMHealthDevice;
