/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { eventIdentitiesMatch } from "./Event";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { EventsFeedDocument } from "../../generated/gqlEvents";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { ReceiverManager } from "../receivers";
import { AWSEvent } from "./AWSEvent";
export class EventsRepository extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS), EventsFeedDocument, true);
        this.events = [];
        ReceiverManager.instance.addObserver(this);
    }
    static get instance() {
        if (!this.__instance) {
            this.__instance = new EventsRepository();
        }
        return EventsRepository.__instance;
    }
    static getIsSameEventComparator(event) {
        return (e) => eventIdentitiesMatch(e, event);
    }
    /**
     * Subscribes listener to all global receivers provided by {@link ReceiverManager}.
     *
     * @param listener
     */
    addListener(listener) {
        super.addListener(listener, ReceiverManager.instance.getFullReceiverList());
    }
    uninit() {
        this.close();
    }
    onReceiversChanged(newReceivers) {
        this.knownReceivers
            .filter((oldReceiver) => !newReceivers.includes(oldReceiver))
            .forEach((receiver) => this.unsubscribe(receiver));
        newReceivers.filter((receiver) => !this.isSubscribedTo(receiver)).forEach((receiver) => this.subscribe(receiver));
    }
    async handleSubscriptionResult(result, listeners) {
        if (!(result === null || result === void 0 ? void 0 : result.eventsFeed))
            return;
        const event = new AWSEvent(result.eventsFeed.item);
        const isNew = this.handleEvent(event);
        listeners.forEach((listener) => {
            if (isNew) {
                listener.onEvent(event);
            }
            else {
                listener.onEventStateChanged(event);
            }
        });
    }
    handleEvent(event) {
        const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
        const isNew = index === -1;
        if (isNew) {
            this.events.push(event);
        }
        else {
            this.events[index] = event;
        }
        return isNew;
    }
}
EventsRepository.EVENT_AGE_DAYS = 365;
