/* Copyright */
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { Maybe } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { DeviceDetails, PatientInfo, resolveDeviceStatusChipColor } from "../../../../utils/utils";

type DeviceDetailsDialogContentRightGridProps = {
  deviceDetails: DeviceDetails;
  patient: Maybe<PatientInfo>;
};

const DeviceDetailsDialogContentRightGrid: React.FC<DeviceDetailsDialogContentRightGridProps> = ({
  deviceDetails,
  patient,
}) => {
  return (
    <Grid item xs={12} md={6} sx={{ paddingLeft: 0 }}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.StatusField}>
                <InputLabel shrink>{translations.common.texts.deviceStatus()}</InputLabel>
                {deviceDetails.deviceStatus && (
                  <Chip
                    size="medium"
                    label={deviceDetails.deviceStatus}
                    sx={{
                      mt: 1.5,
                      width: 120,
                      backgroundColor: resolveDeviceStatusChipColor(deviceDetails.deviceStatus),
                      color: "white",
                    }}
                  />
                )}
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.HardwareVersionField}>
                <InputLabel shrink>{translations.common.texts.hardwareVersion()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.hardwareVersion}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth>
                <InputLabel id="softwareVersion-label" shrink>
                  {translations.deviceDetails.texts.softwareVersion()}
                </InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.softwareVersion}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth>
                <InputLabel id="variant-label" shrink>
                  {translations.common.texts.variant()}
                </InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.variant}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth>
                <Box>
                  <InputLabel id="callerId-label" shrink>
                    {translations.common.texts.callerIdSim()}
                  </InputLabel>
                  <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                    {deviceDetails.callerId}
                  </Typography>
                </Box>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.PatientNameField}>
                <InputLabel shrink>{translations.common.texts.patientName()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.patientName}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.CustomerIdField}>
                <InputLabel shrink>{translations.common.texts.customerId()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {patient?.customerId ?? ""}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default DeviceDetailsDialogContentRightGrid;
