/* Copyright */
import CallEndIcon from "@mui/icons-material/CallEnd";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type HangUpConfirmationDialogProps = {
  open: boolean;
  isHangingUp: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const HangUpConfirmationDialog: React.FC<HangUpConfirmationDialogProps> = ({
  open,
  isHangingUp,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.ActiveCallsHangUpConfirmationDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.activeCalls.texts.hangUpConfirmationHeader()}
        <IconButton
          data-testid={TestIds.ActiveCallsHangUpConfirmationDialog.CloseButton}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography>{translations.activeCalls.texts.hangUpConfirmationContent()}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => onSubmit()}
          color="error"
          data-testid={TestIds.ActiveCallsHangUpConfirmationDialog.ConfirmButton}
          startIcon={isHangingUp ? <CircularProgress size={16} color="primary" /> : <CallEndIcon />}
        >
          {translations.activeCalls.buttons.hangUp()}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          data-testid={TestIds.ActiveCallsHangUpConfirmationDialog.ActionCancelButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HangUpConfirmationDialog;
