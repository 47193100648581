/* Copyright */
import React from "react";
import { TableRow, TableCell, Chip, Typography } from "@mui/material";
import { DeviceReportItem, resolveDeviceStatusChipColor } from "../../../utils/utils";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type DeviceReportsViewTableRowProps = {
  device: DeviceReportItem;
  tableRowIndex?: number;
};

const DeviceReportsViewTableRow: React.FC<DeviceReportsViewTableRowProps> = ({ device, tableRowIndex }) => {
  const renderValidity = (): React.ReactNode => {
    return (
      <Typography sx={{ color: device.validity ? "" : "red" }}>
        {device.validity ? translations.common.texts.valid() : translations.common.texts.invalid()}
      </Typography>
    );
  };

  return (
    <TableRow data-testid={`${TestIds.DeviceReportsTable.TableRow}-${tableRowIndex}`} hover>
      <TableCell>
        {device.status && (
          <Chip
            size="small"
            label={device.status}
            sx={{
              backgroundColor: resolveDeviceStatusChipColor(device.status),
              color: "white",
            }}
          />
        )}
      </TableCell>
      <TableCell>{device.deviceName}</TableCell>
      <TableCell>{device.imei}</TableCell>
      <TableCell>{device.createdOn}</TableCell>
      <TableCell>{device.callerId}</TableCell>
      <TableCell>{device.iccid}</TableCell>
      <TableCell>{device.realmName}</TableCell>
      <TableCell>{device.sku}</TableCell>
      <TableCell>{device.network}</TableCell>
      <TableCell>{device.authKey}</TableCell>
      <TableCell>{renderValidity()}</TableCell>
    </TableRow>
  );
};

export default DeviceReportsViewTableRow;
