/* Copyright*/
import { BaseObservable } from "../observer";
export var UserInvitationStatus;
(function (UserInvitationStatus) {
    UserInvitationStatus["Pending"] = "PENDING";
    UserInvitationStatus["Accepted"] = "ACCEPTED";
    UserInvitationStatus["Declined"] = "DECLINED";
    UserInvitationStatus["Expired"] = "EXPIRED";
    UserInvitationStatus["Unknown"] = "UNKNOWN";
})(UserInvitationStatus || (UserInvitationStatus = {}));
export var WearerRelationship;
(function (WearerRelationship) {
    WearerRelationship["Spouse"] = "spouse";
    WearerRelationship["Child"] = "child";
    WearerRelationship["Grandchild"] = "grandchild";
    WearerRelationship["Other"] = "other";
})(WearerRelationship || (WearerRelationship = {}));
export class Team extends BaseObservable {
    constructor(params) {
        super();
        this.id = params.id;
        this.name = params.name;
        this.devices = params.devices;
        this.members = params.members;
    }
    /**
     * Get team id.
     * @returns team id
     */
    getId() {
        return this.id;
    }
    /**
     * Get team name.
     * @returns team name
     */
    getName() {
        return this.name;
    }
    /**
     * Get devices in the team.
     * @returns devices in the team
     */
    getDevices() {
        return this.devices;
    }
    /**
     * Get members in the team. Sort by:
     * 1. Team manager
     * 2. Wearers
     * 3. Team members
     * 4. Penging team members
     * @returns members in the team
     */
    getMembers() {
        return this.members.sort((a, b) => {
            if (a.isTeamManager && !b.isTeamManager) {
                return -1;
            }
            if (!a.isTeamManager && b.isTeamManager) {
                return 1;
            }
            if (a.wearerOfDevice && !b.wearerOfDevice) {
                return -1;
            }
            if (!a.wearerOfDevice && b.wearerOfDevice) {
                return 1;
            }
            if (a.invitationStatus === UserInvitationStatus.Accepted &&
                b.invitationStatus !== UserInvitationStatus.Accepted) {
                return -1;
            }
            if (a.invitationStatus !== UserInvitationStatus.Accepted &&
                b.invitationStatus === UserInvitationStatus.Accepted) {
                return 1;
            }
            return 0;
        });
    }
    /**
     * Get team manager id.
     * @returns User id of the team manager
     */
    getTeamManager() {
        const manager = this.members.find((member) => member.isTeamManager);
        if (!manager) {
            throw new Error("Team has no manager");
        }
        return manager.userId;
    }
}
