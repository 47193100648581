/* Copyright */
import { Box } from "@mui/material";
import {
  AWSLatestData,
  AWSLatestPatientData,
  BackendFactory,
  Device,
  DeviceAction,
  DeviceState,
  Event,
  EventReport,
  EventSet,
  EventType,
  FallDetectionDisposition,
  Maybe,
  OidcTokenHolder,
  Patient,
  invertedEventOrdering,
} from "@sade/data-access";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { LocationData } from "../../utils/mapUtils";
import { getPath } from "../../utils/ssoPathUtil";
import { PATIENT_PREFIX, Paths, dayInMilliseconds } from "../../utils/utils";
import HangUpConfirmationDialog from "../dialogs/active-calls/hang-up-confirmation-dialog";
import MissedFallConfirmationDialog from "../dialogs/active-calls/missed-fall-confirmation-dialog";
import MissedFallReportDialog from "../dialogs/missed-fall-report-dialog/missed-fall-report-dialog";
import ActiveCallViewContent from "./active-call-view-content/active-call-view-content";
import ActiveCallsViewHeader from "./active-call-view-header";

const ActiveCallsView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const { deviceId, eventId } = useParams();

  const backend = BackendFactory.getBackend();
  const organizationBackend = BackendFactory.getOrganizationBackend();
  const { currentOrganization } = useAuthenticatedUser();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isHangingUp, setIsHangingUp] = React.useState<boolean>(false);
  const [device, setDevice] = React.useState<Maybe<Device>>(undefined);
  const [deviceState, setDeviceState] = React.useState<Maybe<DeviceState>>(undefined);
  const [latestData, setLatestData] = React.useState<Maybe<AWSLatestData>>(undefined);
  const [latestPatientData, setLatestPatientData] = React.useState<Maybe<AWSLatestPatientData>>(undefined);
  const [patient, setPatient] = React.useState<Maybe<Patient>>(undefined);
  const [eventSet, setEventSet] = React.useState<Maybe<EventSet>>(undefined);
  const [callEvent, setCallEvent] = React.useState<Maybe<Event>>(undefined);
  const [isCallRequestSent, setIsCallRequestSent] = React.useState<boolean>(false);
  const [locations, setLocations] = React.useState<LocationData[]>([]);
  const [isSendingMissedFallReport, setIsSendingMissedFallReport] = React.useState<boolean>(false);
  const [isHandlingMissedFallResult, setIsHandlingMissedFallResult] = React.useState<Maybe<boolean>>(undefined);
  const [fallConfirmed, setFallConfirmed] = React.useState<Maybe<FallDetectionDisposition>>(undefined);
  const [isMissedFallReportDialogOpen, setIsMissedFallReportDialogOpen] = React.useState<boolean>(false);
  const [confirmHangUpDialogOpen, setConfirmhangUpDialogOpen] = React.useState<boolean>(false);
  const [missedFallConfirmDialogOpen, setMissedFallConfirmDialogOpen] = React.useState<boolean>(false);
  const [hangUpClicked, setHangUpClicked] = React.useState<boolean>(false);
  const [goBackClicked, setGoBackClicked] = React.useState<boolean>(false);

  const getPatient = async (): Promise<{ patient?: Patient; eventType: EventType }> => {
    const queryParams = new URLSearchParams(location.search);
    const eventType = queryParams.get("event_type") as EventType;
    const patientId = PATIENT_PREFIX + queryParams.get("patient_id");
    const patient = await organizationBackend.getPatient(patientId!);
    return { patient, eventType };
  };

  const answerCall = async (): Promise<void> => {
    try {
      let event: Maybe<Event>;

      if (OidcTokenHolder.hasToken() && eventId) {
        const { patient } = await getPatient();
        event = await patient?.getEvent(eventId);
      } else {
        event = currentOrganization
          ?.getCallEventSet()
          .getOngoingCallEvents()
          .find((e) => e.id === eventId);
      }

      setCallEvent(event);
      if (event?.callAnsweredAt == null) {
        await event?.answerCallEvent();
      }
    } catch (error) {
      showSnackbar(translations.activeCalls.texts.errorAnsweringCall(), SeverityType.Error);
    }
  };

  /** On mount */
  React.useEffect(() => {
    const eventObserver = {
      onEventSetUpdate: (_eventSet: EventSet): void => void 0,
      onEventListUpdate: (events: Event[]): void => {
        const event = events.find((e) => e.id === eventId);
        if (event) {
          setCallEvent(event);
        }
      },
    };

    const latestDataObserver = {
      onLatestDataUpdate: (data: AWSLatestData): void => {
        setLatestData(data);
      },
    };

    const fetchInitialData = async (): Promise<void> => {
      if (!deviceId || !eventId) return;

      setIsLoading(true);
      await answerCall();

      try {
        const queryParams = new URLSearchParams(location.search);
        const eventType = queryParams.get("event_type") as EventType;
        const patientId = PATIENT_PREFIX + queryParams.get("patient_id");
        const patient = await organizationBackend.getPatient(patientId!);

        const [eventSet, latestPatientData, device] = await Promise.all([
          patient?.getEventSet({
            limit: 25,
            startTimestamp: new Date(Date.now() - dayInMilliseconds),
            eventTypes: [EventType.LocationFix, eventType],
          }),
          patient?.getLatestData(),
          backend.getDevice(deviceId),
        ]);
        setPatient(patient);

        latestPatientData?.addObserver(latestDataObserver);
        setLatestPatientData(latestPatientData);
        setLatestData(latestPatientData?.getData());

        eventSet?.addObserver(eventObserver);
        setEventSet(eventSet);

        const locations: Maybe<LocationData[]> =
          eventSet
            ?.getEvents()
            .sort(invertedEventOrdering)
            .filter((event) => event.type === EventType.LocationFix)
            ?.map((event: Event, index: number) => {
              return {
                index: index + 2,
                event: event,
                showOnMap: index <= 6,
              };
            }) ?? [];
        setLocations([...locations]);

        setDevice(device);
        setDeviceState(device?.getState());
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();

    return (): void => {
      latestPatientData?.removeObserver(latestDataObserver);
      eventSet?.removeObserver(eventObserver);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If call event was ended elsewhere, show a snackbar.
   */
  React.useEffect(() => {
    // If the call has not ended, don't show the snackbar
    if (!callEvent?.callEndedAt) return;

    // If event was ended by this component, don't show the snackbar
    if (hangUpClicked) return;

    showSnackbar(translations.activeCalls.texts.callWasEndedElsewhereText(), SeverityType.Error);
  }, [callEvent, hangUpClicked, showSnackbar]);

  const handleHangUp = async (): Promise<void> => {
    setHangUpClicked(true);
    try {
      setIsHangingUp(true);
      await callEvent?.endOngoingCallEvent();
      showSnackbar(translations.activeCalls.texts.youEndedCallText(), SeverityType.Info);
    } catch (error) {
      console.error(error);
    } finally {
      setIsHangingUp(false);
    }
  };

  const handleSelectedLocationsToShowChange = React.useCallback((location: LocationData): void => {
    setLocations((prevLocationsToMap) => {
      const loc = prevLocationsToMap.find((item) => item.event.id === location.event.id);
      if (loc) loc.showOnMap = !location.showOnMap;
      return [...prevLocationsToMap];
    });
  }, []);

  const toggleIsMissedFallReportDialogOpen = (): void => {
    setIsMissedFallReportDialogOpen(!isMissedFallReportDialogOpen);
  };

  const isActiveCall = callEvent?.callAnsweredAt != null && callEvent?.callEndedAt == null;

  const toggleIsConfirmHangupDialogOpen = (): void => {
    setConfirmhangUpDialogOpen(!confirmHangUpDialogOpen);
  };

  const toggleMissedFallConfirmDialogOpen = (): void => {
    setMissedFallConfirmDialogOpen(!missedFallConfirmDialogOpen);
  };

  const handleGoBackClick = (): void => {
    setGoBackClicked(true);
    if (isActiveCall) {
      toggleIsConfirmHangupDialogOpen();
    } else {
      if (!callEvent?.fallDetectionDisposition) {
        toggleMissedFallConfirmDialogOpen();
      } else {
        navigate(-1);
      }
    }
  };

  const handleCallMeBackClick = (): void => {
    setIsCallRequestSent(true);
    try {
      device?.requestAction(DeviceAction.CallNow);
      showSnackbar(
        OidcTokenHolder.token
          ? translations.activeCalls.texts.callRequestSentTextToken()
          : translations.activeCalls.texts.callRequestSentText(),
        SeverityType.Success
      );
      if (!OidcTokenHolder.hasToken()) {
        navigate(getPath(Paths.MONITORING));
      }
    } catch (error) {
      setIsCallRequestSent(false);
      showSnackbar(translations.activeCalls.texts.callRequestSentText(), SeverityType.Error);
    }
  };

  const handleCancelHangUpClick = (): void => {
    setGoBackClicked(false);
    toggleIsConfirmHangupDialogOpen();
  };

  const handleConfirmHangUpClick = async (): Promise<void> => {
    setHangUpClicked(true);
    if (isActiveCall && !callEvent.fallDetectionDisposition) {
      toggleMissedFallConfirmDialogOpen();
    } else {
      await handleHangUp();
    }
    toggleIsConfirmHangupDialogOpen();
  };

  const handleMissedFallResult = async (result: boolean): Promise<void> => {
    let allowGoBack = true;
    try {
      setIsHandlingMissedFallResult(result);
      if (result === true) {
        if (callEvent?.type === EventType.Fall) {
          await callEvent?.addCallEventReport(FallDetectionDisposition.Correct);
          setFallConfirmed(FallDetectionDisposition.Correct);
          if (isActiveCall) await handleHangUp();
        } else {
          await callEvent?.addCallEventReport(FallDetectionDisposition.FalseNegative);
          setFallConfirmed(FallDetectionDisposition.FalseNegative);
          toggleIsMissedFallReportDialogOpen();
          // Prevent going back as we need to show the "missed fall" questionnaire
          allowGoBack = false;
        }
      } else {
        if (callEvent?.type === EventType.Fall) {
          await callEvent?.addCallEventReport(FallDetectionDisposition.FalsePositive);
          setFallConfirmed(FallDetectionDisposition.FalsePositive);
        } else {
          await callEvent?.addCallEventReport(FallDetectionDisposition.Correct);
          setFallConfirmed(FallDetectionDisposition.Correct);
        }
        if (isActiveCall) await handleHangUp();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsHandlingMissedFallResult(undefined);
      toggleMissedFallConfirmDialogOpen();
      if (goBackClicked && allowGoBack) navigate(-1);
    }
  };

  const handleSubmitFallReport = async (report?: EventReport): Promise<void> => {
    try {
      if (fallConfirmed && report) {
        setIsSendingMissedFallReport(true);
        await callEvent?.addCallEventReport(fallConfirmed, report);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsSendingMissedFallReport(false);
      toggleIsMissedFallReportDialogOpen();

      if (hangUpClicked) await handleHangUp();
      if (goBackClicked) navigate(-1);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ padding: "1rem" }}>
        <ActiveCallsViewHeader
          event={callEvent}
          isLoading={isLoading}
          isHangingUp={isHangingUp}
          isRedirecting={isCallRequestSent && !OidcTokenHolder.hasToken()}
          confirmHangUpDialogOpen={confirmHangUpDialogOpen}
          isActiveCall={isActiveCall}
          isCallRequestSent={isCallRequestSent}
          handleGoBackClick={handleGoBackClick}
          handleHangUpClick={toggleIsConfirmHangupDialogOpen}
          handleCallMeBackClick={handleCallMeBackClick}
        />
        <ActiveCallViewContent
          isLoading={isLoading}
          patient={patient}
          event={callEvent}
          device={device}
          deviceState={deviceState}
          latestData={latestData}
          locations={locations}
          handleSelectedLocationsToShowChange={handleSelectedLocationsToShowChange}
          toggleIsMissedFallReportDialogOpen={toggleIsMissedFallReportDialogOpen}
          fallConfirmed={fallConfirmed}
          setFallConfirmed={setFallConfirmed}
        />
      </Box>
      <MissedFallConfirmationDialog
        open={missedFallConfirmDialogOpen}
        isFall={callEvent?.type === EventType.Fall}
        handleResult={handleMissedFallResult}
        isHandlingMissedFallResult={isHandlingMissedFallResult}
      ></MissedFallConfirmationDialog>
      <MissedFallReportDialog
        open={isMissedFallReportDialogOpen}
        isLoading={isSendingMissedFallReport}
        onClose={handleSubmitFallReport}
        onSubmitForm={handleSubmitFallReport}
      />
      <HangUpConfirmationDialog
        open={confirmHangUpDialogOpen}
        isHangingUp={isHangingUp}
        onClose={handleCancelHangUpClick}
        onSubmit={handleConfirmHangUpClick}
      />
    </React.Fragment>
  );
};

export default ActiveCallsView;
