/* Copyright */
import * as React from "react";
import { Box, FormControl, IconButton, InputLabel, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { translations } from "../../generated/translationHelper";
import { Nullable } from "@sade/data-access";

type CustomDateRangePickerProps = {
  isDisabled: boolean;
  testId: string;
  minDate?: Nullable<Date>;
  selectedStartDate: Nullable<Date>;
  setSelectedStartDate: (startDate: Nullable<Date>) => void;
  selectedEndDate: Nullable<Date>;
  setSelectedEndDate: (startDate: Nullable<Date>) => void;
};

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  isDisabled,
  testId,
  minDate,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
}) => {
  const handleClearClick = (): void => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const handleStartDateChange = (selectedStartDate: Nullable<Date>): void => {
    setSelectedStartDate(selectedStartDate);
  };

  const handleEndDateChange = (selectedEndDate: Nullable<Date>): void => {
    setSelectedEndDate(selectedEndDate);
  };

  return (
    <>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }} data-testid={testId}>
        <FormControl>
          <InputLabel htmlFor="startDate" shrink disabled={isDisabled}>
            {translations.common.texts.start()}
          </InputLabel>
          <DatePicker
            value={selectedStartDate}
            onChange={(newValue): void => handleStartDateChange(newValue)}
            disableFuture
            maxDate={selectedEndDate}
            minDate={minDate}
            disabled={isDisabled}
          />
        </FormControl>
        <Box sx={{ alignSelf: "center", justifySelf: "center", pt: 1, mr: 1, ml: 1 }}>
          <Typography variant="body1">-</Typography>
        </Box>
        <FormControl>
          <InputLabel htmlFor="endDate" shrink disabled={isDisabled}>
            {translations.common.texts.end()}
          </InputLabel>
          <DatePicker
            onChange={(newValue): void => handleEndDateChange(newValue)}
            value={selectedEndDate}
            disableFuture
            minDate={selectedStartDate ?? minDate}
            disabled={isDisabled}
          />
        </FormControl>
        <IconButton
          aria-label="clear"
          onClick={handleClearClick}
          data-testid=""
          sx={{
            ml: 1,
            mt: 1,
          }}
          disabled={isDisabled || (!selectedStartDate && !selectedEndDate)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default CustomDateRangePicker;
