/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { BackendFactory, Role, SupportedLanguage, isError } from "@sade/data-access";
import * as React from "react";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import { DEFAULT_LANGUAGE, OrganizationInfo, getAssignableRoles, getChildOrganizations } from "../../../utils/utils";
import { isValidEmail, removeTrailingWhitespace } from "../../../utils/validation";
import RealmPicker from "../../common/realm-picker";
import CustomTextField from "../../common/textfield";
import UnsavedChangesDialog from "../../dialogs/unsaved-changes-dialog/unsaved-changes-dialog";
import Loading from "../../loading/loading";

type AddNewAccountDialog = {
  open: boolean;
  handleClose: () => void;
  reFetchUserData: () => void;
};

const AddNewAccountDialog: React.FC<AddNewAccountDialog> = ({ open, handleClose, reFetchUserData }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const organization = BackendFactory.getOrganizationBackend();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAssignableRoles, setIsLoadingAssignableRoles] = React.useState<boolean>(false);
  const [realms, setRealms] = React.useState<OrganizationInfo[]>([]);
  const [assignableRoles, setAssignableRoles] = React.useState<Role[]>([]);
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [selectedRealm, setSelectedRealm] = React.useState<string>("");
  const [selectedRole, setSelectedRole] = React.useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(DEFAULT_LANGUAGE);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState<boolean>(false);
  const [shouldFetchData, setShouldFetchData] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (shouldFetchData) {
      // This timeout here is supposed to be a temporary solution.
      // For some reason (maybe some caching somewhere), re-fetching account data immediately after
      // any changes are made does not return changes just made
      setTimeout(() => {
        reFetchUserData();
      }, 2000);

      setShouldFetchData(false);
    }
  }, [shouldFetchData, reFetchUserData]);

  React.useEffect(() => {
    const getChildOrganizationList = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
        setRealms(childOrganizationList);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getChildOrganizationList();
  }, [currentOrganization]);

  React.useEffect(() => {
    const fetchAssignableRoles = async (): Promise<void> => {
      if (selectedRealm) {
        setIsLoadingAssignableRoles(true);
        const roles = await getAssignableRoles(selectedRealm);
        setAssignableRoles(roles);
        setIsLoadingAssignableRoles(false);
      }
    };

    if (selectedRealm) fetchAssignableRoles();
  }, [selectedRealm]);

  React.useEffect(() => {
    if (email && selectedRealm && selectedRole && selectedLanguage) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [email, selectedRealm, selectedRole, selectedLanguage]);

  const handleClearForm = (): void => {
    setEmail("");
    setEmailError("");
    setSelectedRealm("");
    setSelectedRole("");
    setSelectedLanguage(DEFAULT_LANGUAGE);
    setIsDirty(false);
  };

  const handleCloseClick = (): void => {
    if (isDirty) {
      setUnsavedDataDialogOpen(true);
    } else {
      handleClearForm();
      handleClose();
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailError("");
    setEmail(event.target.value);
  };

  const handleRealmChange = (realmId: string): void => {
    setSelectedRealm(realmId);
  };

  const handleRoleChange = (event: SelectChangeEvent): void => {
    setSelectedRole(event.target.value);
  };

  const handleLanguageChange = (event: SelectChangeEvent): void => {
    setSelectedLanguage(event.target.value);
  };

  const handleCloseUnsavedDataDialog = (): void => {
    setUnsavedDataDialogOpen(false);
  };

  const handleDiscardChanges = (): void => {
    handleClearForm();
    handleClose();
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const emailStripped = removeTrailingWhitespace(email);
      if (!isValidEmail(emailStripped)) {
        setEmailError(translations.common.texts.emailFormatNotValid());
        return;
      }

      const indexOfLanguage = Object.keys(SupportedLanguage).indexOf(selectedLanguage);
      const language = Object.values(SupportedLanguage)[indexOfLanguage];
      const role = assignableRoles.find((role) => role.name === selectedRole)!;
      const activeOrganization = await organization.getOrganization(selectedRealm);
      if (activeOrganization) {
        await activeOrganization.createUser({
          email: emailStripped,
          resendInvitation: false,
          inviteLanguage: language,
          roles: [role],
        });
      }

      setShouldFetchData(true);
      showSnackbar(translations.addNewAccountDialog.texts.userRequestAdded(), SeverityType.Success);
      handleClearForm();
      handleClose();
    } catch (error) {
      if (isError(error)) {
        if (error.message.match(/user with email .* already exists/i)) {
          showSnackbar(translations.addNewAccountDialog.texts.userAlreadyExists(), SeverityType.Error);
          return;
        }
      }

      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = !email || !selectedRealm || !selectedRole || !selectedLanguage || isLoading;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.AddNewAccountDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.addNewAccountDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.AddNewAccountDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {translations.addNewAccountDialog.texts.subtitle()}
          <Box sx={{ mt: 4 }}>
            <CustomTextField
              label={translations.common.texts.email()}
              id="email"
              errorMessage={emailError}
              testId={TestIds.AddNewAccountDialog.EmailInput}
              onChange={handleEmailChange}
              placeholder={translations.addNewAccountDialog.inputs.emailPlaceholder()}
            />
            <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
              <FormControl className="input" sx={{ textAlign: "start" }}>
                <RealmPicker
                  childOrganizations={realms}
                  onRealmPicked={handleRealmChange}
                  testId={TestIds.AddNewAccountDialog.RealmSelect}
                />
              </FormControl>

              <FormControl className="input" sx={{ textAlign: "start", mt: 4 }}>
                <InputLabel id="role-label" shrink disabled={!selectedRealm}>
                  {translations.common.texts.role()}
                </InputLabel>
                <Select
                  id="role-select"
                  size="small"
                  onChange={handleRoleChange}
                  labelId="role-label"
                  value={selectedRole}
                  disabled={!selectedRealm}
                  data-testid={TestIds.AddNewAccountDialog.OpenRoleSelection}
                  inputProps={{ "data-testid": TestIds.AddNewAccountDialog.RoleSelect }}
                >
                  {isLoadingAssignableRoles && <Loading height={24} marginTop={2} />}
                  {assignableRoles?.map((role, index) => (
                    <MenuItem key={role.identifier} value={role.name} data-option-index={index}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ mt: 4 }}>
                <InputLabel shrink>{translations.common.texts.language()}</InputLabel>
                <RadioGroup
                  sx={{ mt: 1, ml: 2 }}
                  id="language"
                  value={selectedLanguage ?? ""}
                  onChange={handleLanguageChange}
                >
                  <FormControlLabel
                    value="English"
                    control={<Radio />}
                    label={`${translations.common.texts.english()} (${translations.common.texts.unitedStates()})`}
                  />
                  <FormControlLabel
                    value="French"
                    control={<Radio />}
                    label={`${translations.common.texts.french()} (${translations.common.texts.canada()}) `}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            data-testid={TestIds.AddNewAccountDialog.SubmitButton}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.sendInvite()}
          </Button>
          <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.AddNewAccountDialog.CancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleCloseUnsavedDataDialog}
        handleSave={handleSubmit}
        handleDiscard={handleDiscardChanges}
      />
    </React.Fragment>
  );
};

export default AddNewAccountDialog;
