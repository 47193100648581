/* Copyright */
import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useAuthenticatedUser } from "../../../../context/authenticated-user-context";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { getPath } from "../../../../utils/ssoPathUtil";
import { Paths } from "../../../../utils/utils";

const NavigationDrawerFooter: React.FC = () => {
  const theme = useTheme();
  const { currentOrganization } = useAuthenticatedUser();
  const [realmName, setRealmName] = React.useState<string>("");

  React.useEffect(() => {
    const getCurrentOrganization = async (): Promise<void> => {
      try {
        currentOrganization && setRealmName(currentOrganization?.getFullName());
      } catch (error) {
        console.error(error);
      }
    };

    getCurrentOrganization();
  }, [currentOrganization]);

  return (
    <Box data-testid={TestIds.Navigation.NavigationDrawerFooter}>
      <Typography variant="caption">{realmName}</Typography>
      <Box sx={{ pt: 2 }}>
        <Link
          href={getPath(Paths.TERMS_OF_USE)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.termsOfUse()}
        </Link>
        <Typography variant="caption" sx={{ margin: "0 0.5rem", color: theme.palette.secondary.main }}>
          |
        </Typography>
        <Link
          href={getPath(Paths.PRIVACY)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.privacy()}
        </Link>
      </Box>
      <Typography variant="caption" sx={{ color: theme.palette.secondary.light }}>
        © {new Date().getFullYear()} Nice North America
      </Typography>
    </Box>
  );
};

export default NavigationDrawerFooter;
