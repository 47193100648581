/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { OtaPackageInfo } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import OtaPackagesTableRow from "./ota-packages-table-row";

type OtaPackagesTableProps = {
  otaPackages: OtaPackageInfo[];
  tableRef?: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  onUploadComplete: () => void;
};

const OtaPackagesTable: React.FC<OtaPackagesTableProps> = ({ otaPackages, tableRef, isLoading, onUploadComplete }) => {
  return (
    <Box
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
      ref={tableRef}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.OtaPackages.Table}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.otaPackages.texts.release()}</TableCell>
              <TableCell>{translations.common.texts.variant()}</TableCell>
              <TableCell>{translations.common.texts.deviceType()}</TableCell>
              <TableCell>{translations.common.texts.name()}</TableCell>
              <TableCell>{translations.common.texts.size()}</TableCell>
              <TableCell>{translations.otaPackages.texts.hash()}</TableCell>
              <TableCell>{translations.otaPackages.texts.filename()}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {otaPackages.length > 0
              ? otaPackages.map((otaPackage, index) => (
                  <OtaPackagesTableRow
                    key={index}
                    otaPackage={otaPackage}
                    tableRowIndex={index}
                    reFetchData={onUploadComplete}
                  />
                ))
              : !isLoading && <TableEmptyRow colspan={8} infoText={translations.otaPackages.texts.emptyTableText()} />}
            {isLoading && <TableLoadingRow colspan={8} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OtaPackagesTable;
