/* Copyright */
import { __rest } from "tslib";
import { EventsFeedDocument } from "../../generated/gqlEvents";
import { AppSyncClientFactory, Service } from "../backend";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { AWSEvent } from "./AWSEvent";
export class CallEventsSubscriptionManager extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS), EventsFeedDocument);
    }
    static get instance() {
        if (!this.__instance) {
            this.__instance = new CallEventsSubscriptionManager();
        }
        return this.__instance;
    }
    async handleSubscriptionResult(result, listeners) {
        var _a;
        if ((_a = result.eventsFeed) === null || _a === void 0 ? void 0 : _a.item) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _b = result.eventsFeed.item, { __typename } = _b, event = __rest(_b, ["__typename"]);
            listeners.forEach(async (listener) => {
                const allOrganizationIds = await listener.getIds();
                if (allOrganizationIds.includes(event.organizationId)) {
                    listener.onEventUpdate(new AWSEvent(event));
                }
            });
        }
    }
}
