/* Copyright */
import { Chip } from "@mui/material";
import { Event, EventType } from "@sade/data-access";
import React from "react";
import { resolveCallEventName, resolveEventTypeChipColor } from "../../utils/eventUtils";

type EventChipProps = {
  event: Event;
};

const EventChip: React.FC<EventChipProps> = ({ event }) => {
  let label: string = event.type;

  if (event.type === EventType.Normal) {
    label = resolveCallEventName(event);
  }

  return (
    <Chip
      label={label}
      size={"small"}
      sx={{
        color: "white",
        backgroundColor: resolveEventTypeChipColor(event),
      }}
    />
  );
};

export default EventChip;
