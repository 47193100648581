/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CallEventObserver, CallEventSet, Event, EventType, Maybe } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import IncomingCallsTableRow from "./incoming-calls-table-row";

type IncomingCallsTableProps = {
  eventSet?: CallEventSet;
  userHasAccessToActiveCalls: boolean;
  onRowClick: (deviceId: string, eventId: string, eventType: EventType, patientId: string) => void;
};

const IncomingCallsTable: React.FC<IncomingCallsTableProps> = ({
  eventSet,
  userHasAccessToActiveCalls,
  onRowClick,
}) => {
  const tableElement = React.useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [callEvents, setCallEvents] = React.useState<Event[]>([]);
  const [nextToken, setNextToken] = React.useState<Maybe<string>>(undefined);

  const sortEventList = (events: Event[]): Event[] => {
    const sorted = events.sort((a, b): number => {
      if (a.callAnsweredBy && !b.callAnsweredBy) {
        return 1;
      } else if (!a.callAnsweredBy && b.callAnsweredBy) {
        return -1;
      } else if ((!a.callAnsweredBy && !b.callAnsweredBy) || (a.callAnsweredBy && b.callAnsweredBy)) {
        return b.timestamp < a.timestamp ? -1 : 1;
      }
      return 0;
    });

    return sorted;
  };

  /* Fetch events on mount */
  React.useEffect(() => {
    const fetchCallEvents = async (): Promise<void> => {
      try {
        setIsLoading(true);
        setCallEvents([]);
        await eventSet?.fetchOngoingCallEvents();
        const sortedEvents = sortEventList(eventSet?.getOngoingCallEvents() ?? []);
        setCallEvents(sortedEvents);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCallEvents();
  }, [eventSet]);

  /* Observe event changes */
  React.useEffect(() => {
    const callEventObserver: CallEventObserver = {
      onCallEventUpdate(callEventSet: CallEventSet): void {
        const sortedEvents = sortEventList(callEventSet?.getOngoingCallEvents() ?? []);
        setCallEvents(sortedEvents);
      },
    };

    eventSet?.addObserver(callEventObserver);

    return (): void => {
      eventSet?.removeObserver(callEventObserver);
    };
  }, [eventSet]);

  /* Follow scrolling to load more data */
  const loadMoreEvents = React.useCallback(() => {
    const getData = async (): Promise<void> => {
      const fetchedNextToken = await eventSet?.fetchEndedCallEvents(nextToken);
      const responseList = eventSet?.getEndedCallEvents();
      if (responseList) {
        setCallEvents((oldState) => [...oldState, ...responseList]);
      }
      setNextToken(fetchedNextToken);
    };

    if (nextToken) getData();
  }, [eventSet, nextToken]);

  const scrollListener = React.useCallback(() => {
    const element = tableElement.current;
    if (element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
        loadMoreEvents();
      }
    }
  }, [isLoading, loadMoreEvents]);

  React.useLayoutEffect(() => {
    const tableRef = tableElement.current;
    tableRef?.addEventListener("scroll", scrollListener);
    return (): void => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box
      ref={tableElement}
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.IncomingCallsTable.Table}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.monitoring.texts.eventTime()}</TableCell>
              <TableCell>{translations.common.texts.imei()}</TableCell>
              <TableCell>{translations.common.texts.callerId()}</TableCell>
              <TableCell>{translations.common.texts.deviceName()}</TableCell>
              <TableCell>{translations.monitoring.texts.eventType()}</TableCell>
              <TableCell>{translations.monitoring.texts.answeredBy()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableLoadingRow colspan={6} />}
            {callEvents.length > 0
              ? callEvents.map((event, index) => (
                  <IncomingCallsTableRow
                    key={index}
                    event={event}
                    userHasAccessToActiveCalls={userHasAccessToActiveCalls}
                    onRowClick={onRowClick}
                  />
                ))
              : !isLoading && <TableEmptyRow infoText={translations.monitoring.texts.noIncomingCalls()} colspan={6} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IncomingCallsTable;
