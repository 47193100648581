/* Copyright */
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button, Card, CardContent, CardHeader, Table, TableBody, tableCellClasses } from "@mui/material";
import { Device, Organization, OrganizationParameterHierarchy, RoleIdentifier } from "@sade/data-access";
import React from "react";
import { formatPhoneNumber } from "react-phone-number-input"; // there's also formatPhoneNumberIntl function available for formatting phone numbers in international format
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { enableEditDialog } from "../../../utils/deviceDetailsUtils";
import { isFeatureEnabled } from "../../../utils/parameterUtils";
import { ParameterNames, convertMinutesToHours } from "../../../utils/utils";
import TableDataRow from "../../common/table-data-row";
import DeviceSettingsDialog from "../../dialogs/device/settings/device-settings-dialog";
import CardContentSkeleton from "../../loading/card-content-skeleton";

type DeviceViewContentSettingsProps = {
  isLoading: boolean;
  device?: Device;
  deviceOrganization?: Organization;
  parameterHierarchy?: OrganizationParameterHierarchy;
};

const DeviceViewContentSettings: React.FC<DeviceViewContentSettingsProps> = ({
  isLoading,
  device,
  deviceOrganization,
  parameterHierarchy,
}) => {
  const [isDeviceSettingsDialogOpen, setIsDeviceSettingsDialogOpen] = React.useState<boolean>(false);

  const deviceState = device?.getState();

  const handleToggleIsDeviceSettingsDialogOpen = (): void => {
    setIsDeviceSettingsDialogOpen(!isDeviceSettingsDialogOpen);
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.SettingsCard}>
        <CardHeader
          title={translations.common.texts.settings()}
          action={
            useAccessControl(
              RoleIdentifier.SuperUser,
              RoleIdentifier.PowerUser,
              RoleIdentifier.DealerManager,
              RoleIdentifier.WarehouseInstaller,
              RoleIdentifier.DealerAgent
            ) &&
            enableEditDialog(device?.getDeviceStatus()) && (
              <Button
                variant="outlined"
                startIcon={<ModeEditIcon />}
                disabled={isLoading}
                sx={{ mr: 3 }}
                onClick={handleToggleIsDeviceSettingsDialogOpen}
                data-testid={TestIds.DeviceSettingsCard.DeviceSettingsEdit}
              >
                {translations.common.buttons.edit()}
              </Button>
            )
          }
        ></CardHeader>
        {isLoading && !deviceOrganization ? (
          <CardContentSkeleton />
        ) : (
          <CardContent>
            <Table
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableDataRow
                  label={translations.deviceSettings.texts.inactivityNotifications()}
                  value={
                    (deviceState?.getParameter(ParameterNames.INACTIVE_MONITOR_ENABLED) as boolean)
                      ? translations.common.texts.enabled()
                      : translations.common.texts.disabled()
                  }
                  testId={TestIds.DeviceSettingsCard.InactivityNotifications}
                />
                <TableDataRow
                  label={translations.deviceSettings.texts.inactiveInCharger()}
                  value={`${convertMinutesToHours(
                    (deviceState?.getParameter(ParameterNames.INACTIVE_CHARGER_MINUTES) as number) ?? 0
                  )} ${translations.common.texts.hours()}`}
                  testId={TestIds.DeviceSettingsCard.InactiveInCharger}
                />
                <TableDataRow
                  label={translations.deviceSettings.texts.inactiveOutOfCharger()}
                  value={`${convertMinutesToHours(
                    (deviceState?.getParameter(ParameterNames.INACTIVE_MOTION_MINUTES) as number) ?? 0
                  )} ${translations.common.texts.hours()}`}
                  testId={TestIds.DeviceSettingsCard.InactiveOutOfCharger}
                />
                {isFeatureEnabled(ParameterNames.SILENT_MODE_ENABLED, parameterHierarchy) && (
                  <TableDataRow
                    label={translations.deviceSettings.texts.fallDetection()}
                    value={
                      (deviceState?.getParameter(ParameterNames.FALL_DETECTION_ENABLED) as boolean)
                        ? translations.common.texts.enabled()
                        : translations.common.texts.disabled()
                    }
                    testId={TestIds.DeviceSettingsCard.FallDetection}
                  />
                )}
                <TableDataRow
                  label={translations.deviceSettings.texts.audioPromptVolume()}
                  value={`${(deviceState?.getParameter(ParameterNames.AUDIO_PROMPT_VOLUME) as number) ?? 0} %`}
                  testId={TestIds.DeviceSettingsCard.AudioPromptVolume}
                />
                <TableDataRow
                  label={translations.deviceSettings.texts.callVolume()}
                  value={`${(deviceState?.getParameter(ParameterNames.AUDIO_CALL_VOLUME) as number) ?? 0} %`}
                  testId={TestIds.DeviceSettingsCard.CallVolume}
                />
                <TableDataRow
                  label={translations.common.texts.callInService()}
                  value={formatPhoneNumber(
                    (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_1) as string) ?? ""
                  )}
                  testId={TestIds.DeviceSettingsCard.CallInService}
                />
                {(deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_2) as string) && (
                  <TableDataRow
                    label={translations.deviceSettings.texts.backupCallInNumber1()}
                    value={formatPhoneNumber(
                      (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_2) as string) ?? ""
                    )}
                    testId={""}
                  />
                )}
                {(deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_3) as string) && (
                  <TableDataRow
                    label={translations.deviceSettings.texts.backupCallInNumber2()}
                    value={formatPhoneNumber(
                      (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_3) as string) ?? ""
                    )}
                    testId={""}
                  />
                )}
                <TableDataRow
                  label={translations.deviceSettings.texts.periodicIndicatorFeedback()}
                  value={`
                    ${
                      (deviceState?.getParameter(ParameterNames.PERIODIC_INDICATOR_FEEDBACK) as number) ?? 0
                    } ${translations.common.texts.seconds()}`}
                  testId={TestIds.DeviceSettingsCard.PeriodicIndicatorFeedback}
                />
                <TableDataRow
                  label={translations.deviceSettings.texts.tapIndicator()}
                  value={
                    (deviceState?.getParameter(ParameterNames.TAP_INDICATOR_ENABLED) as boolean)
                      ? translations.common.texts.enabled()
                      : translations.common.texts.disabled()
                  }
                  testId={TestIds.DeviceSettingsCard.TapIndicator}
                />
                {isFeatureEnabled(ParameterNames.SILENT_MODE_ENABLED, parameterHierarchy) && (
                  <TableDataRow
                    label={translations.deviceSettings.texts.silentMode()}
                    value={
                      (deviceState?.getParameter(ParameterNames.SILENT_MODE_ENABLED) as boolean)
                        ? translations.common.texts.on()
                        : translations.common.texts.off()
                    }
                    testId={TestIds.DeviceSettingsCard.SilentMode}
                  />
                )}
                {isFeatureEnabled(ParameterNames.DEMO_MODE_ENABLED, parameterHierarchy) && (
                  <TableDataRow
                    label={translations.deviceSettings.texts.demoMode()}
                    value={
                      (deviceState?.getParameter(ParameterNames.DEMO_MODE_ENABLED) as boolean)
                        ? translations.common.texts.on()
                        : translations.common.texts.off()
                    }
                    testId={TestIds.DeviceSettingsCard.DemoMode}
                  />
                )}
                <TableDataRow
                  label={translations.common.texts.variant()}
                  value={(deviceState?.getParameter(ParameterNames.VARIANT) as string) ?? ""}
                  testId={TestIds.DeviceSettingsCard.Variant}
                />
              </TableBody>
            </Table>
          </CardContent>
        )}
      </Card>
      <DeviceSettingsDialog
        device={device}
        open={isDeviceSettingsDialogOpen}
        deviceState={deviceState}
        deviceOrganization={deviceOrganization}
        parameterHierarchy={parameterHierarchy}
        onClose={handleToggleIsDeviceSettingsDialogOpen}
      />
    </Box>
  );
};

export default DeviceViewContentSettings;
