/* Copyright */

import RemoveIcon from "@mui/icons-material/DeleteForever";
import ScaleIcon from "@mui/icons-material/Speed";
import { Avatar, Box, Chip, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { PeripheralState, PeripheralType } from "@sade/data-access";
import React from "react";
import CompanionIcon from "../../../../../assets/icons/devices-icon";
import TestIds from "../../../../../test-ids/test-ids";
import {
  Peripheral,
  PeripheralListItem,
  getPeripheralTypeTranslation,
  resolvePeripheralStateChipColor,
  resolvePeripheralStateCircleColor,
  resolvePeripheralStateIconColor,
} from "../../../../../utils/utils";
import DevicePeripheralsRemoveConfirmationDialog from "../device-peripherals-remove-confirmation-dialog";

type PeripheralsTableRowProps = {
  peripheral: PeripheralListItem;
  tableRowIndex?: number;
  removePeripheral: (peripheral: Peripheral) => void;
};

const PeripheralsTableRow: React.FC<PeripheralsTableRowProps> = ({ peripheral, tableRowIndex, removePeripheral }) => {
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState<boolean>(false);

  const toggleIsRemoveDialogOpen = (): void => {
    setIsRemoveDialogOpen(!isRemoveDialogOpen);
  };

  const handleRemovePeripheral = async (): Promise<void> => {
    removePeripheral({ serialNumber: peripheral.serialNumber, type: peripheral.type });
    toggleIsRemoveDialogOpen();
  };

  const renderPeripheralIcon = (peripheralType: PeripheralType, peripheralState: PeripheralState): JSX.Element => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center" }}>
        <Box sx={{ width: "55px" }}>
          <Avatar
            sx={{
              bgcolor: resolvePeripheralStateCircleColor(peripheral.state),
              color: peripheralState === PeripheralState.Whitelisted ? "black" : "white",
            }}
          >
            {peripheralType === PeripheralType.BasicPendant ? (
              <CompanionIcon fill={resolvePeripheralStateIconColor(peripheral.state)} />
            ) : (
              <ScaleIcon style={{ color: resolvePeripheralStateIconColor(peripheral.state) }} />
            )}
          </Avatar>
        </Box>
        <Box alignContent={"center"}>
          <Typography>{getPeripheralTypeTranslation(peripheral.type)}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <TableRow sx={{ cursor: "pointer" }} data-testid={TestIds.DevicesTable.TableRow} hover>
      <TableCell data-testid={`${TestIds.PeripheralsTable.PeripheralType}-${tableRowIndex}`}>
        {renderPeripheralIcon(peripheral.type, peripheral.state)}
      </TableCell>
      <TableCell data-testid={`${TestIds.PeripheralsTable.PeripheralSerial}-${tableRowIndex}`}>
        {peripheral.serialNumber}
      </TableCell>
      <TableCell data-testid={`${TestIds.PeripheralsTable.PeripheralState}-${tableRowIndex}`}>
        <Chip
          size="small"
          label={peripheral.state.charAt(0).toUpperCase() + peripheral.state.slice(1)}
          sx={{
            backgroundColor: resolvePeripheralStateChipColor(
              peripheral.state.charAt(0).toUpperCase() + peripheral.state.slice(1)
            ),
            color: peripheral.state === PeripheralState.Whitelisted ? "black" : "white",
          }}
        />
      </TableCell>
      <TableCell data-testid={`${TestIds.PeripheralsTable.PeripheralAction}-${tableRowIndex}`}>
        {peripheral.state !== PeripheralState.Unpairing && (
          <IconButton
            aria-label="remove"
            onClick={toggleIsRemoveDialogOpen}
            data-testid={TestIds.DevicePeripheralsDialog.CloseButton}
            sx={{
              color: "#C61924",
            }}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </TableCell>
      <DevicePeripheralsRemoveConfirmationDialog
        open={isRemoveDialogOpen}
        peripheral={peripheral}
        handleClose={toggleIsRemoveDialogOpen}
        handleRemove={handleRemovePeripheral}
      />
    </TableRow>
  );
};

export default PeripheralsTableRow;
