/* Copyright */
import { Chip, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Role, RoleIdentifier, UserInformation } from "@sade/data-access";
import React from "react";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { OrganizationInfo, UserStatusNames, UserStatuses } from "../../../utils/utils";

type AccountsTableRowProps = {
  user: UserInformation;
  allRoles: Role[];
  organizationInfo: OrganizationInfo[];
  everThere: boolean;
  tableRowIndex?: number;
  onRowClick: (userId: string) => void;
};

const AccountsTableRow: React.FC<AccountsTableRowProps> = ({
  user,
  allRoles,
  organizationInfo,
  everThere,
  tableRowIndex,
  onRowClick,
}) => {
  const theme = useTheme();

  const userStatus = UserStatuses.find((status) =>
    user.enabled ? status.identifiers.includes(user.status) : status.name === UserStatusNames.DISABLED
  );

  const realm = !everThere
    ? organizationInfo.find((organization) => organization.id === user.organizationId)
    : undefined;

  const role = !everThere ? allRoles.find((role) => role.identifier === user.roleIdentifier) : undefined;

  const chipColor = (): string => {
    switch (userStatus?.name) {
      case UserStatusNames.ACTIVE:
      case UserStatusNames.EXTERNAL_PROVIDER:
        return theme.palette.success.main;
      case UserStatusNames.PENDING:
        return "";
      case UserStatusNames.DISABLED:
        return theme.palette.error.main;
      default:
        return theme.palette.warning.main;
    }
  };

  return (
    <TableRow
      onClick={(): void => onRowClick(user.userId)}
      sx={{
        cursor: useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager, RoleIdentifier.Supervisor)
          ? "pointer"
          : "default",
      }}
      hover
      data-testid={TestIds.UsersTable.TableRow}
    >
      <TableCell data-testid={`${TestIds.UsersTable.UserStatus}-${tableRowIndex}`}>
        <Chip
          size="small"
          label={userStatus?.name}
          sx={{ backgroundColor: chipColor, color: userStatus?.name !== UserStatusNames.PENDING ? "white" : "" }}
        />
      </TableCell>
      <TableCell data-testid={`${TestIds.UsersTable.UserFirstName}-${tableRowIndex}`}>{user.firstName}</TableCell>
      <TableCell data-testid={`${TestIds.UsersTable.UserLastName}-${tableRowIndex}`}>{user.lastName}</TableCell>
      <TableCell data-testid={`${TestIds.UsersTable.UserEmail}-${tableRowIndex}`}>{user.email}</TableCell>
      {!everThere && (
        <TableCell data-testid={`${TestIds.UsersTable.UserRealm}-${tableRowIndex}`}>{realm?.fullName}</TableCell>
      )}
      {!everThere && (
        <TableCell data-testid={`${TestIds.UsersTable.UserRole}-${tableRowIndex}`}>{role?.name}</TableCell>
      )}
    </TableRow>
  );
};

export default AccountsTableRow;
