/* Copyright */
import React from "react";
import theme from "../../app/theme";

type DeactivateIconProps = {
  marginRight?: string;
  fill?: string;
};

const DeactivateIcon: React.FC<DeactivateIconProps> = ({ fill = theme.palette.secondary.main }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80965 0.81L0.389648 2.22L2.65965 4.49C1.60965 6.07 0.999648 7.96 0.999648 10C0.999648 15.52 5.47965 20 10.9996 20C13.0396 20 14.9296 19.39 16.5096 18.34L18.7796 20.61L20.1896 19.2L1.80965 0.81ZM10.9996 18C6.58965 18 2.99965 14.41 2.99965 10C2.99965 8.52 3.40965 7.14 4.11965 5.94L15.0596 16.88C13.8596 17.59 12.4796 18 10.9996 18ZM6.93965 3.12L5.48965 1.66C7.06965 0.61 8.95965 0 10.9996 0C16.5196 0 20.9996 4.48 20.9996 10C20.9996 12.04 20.3896 13.93 19.3396 15.51L17.8796 14.05C18.5896 12.86 18.9996 11.48 18.9996 10C18.9996 5.59 15.4096 2 10.9996 2C9.51965 2 8.13965 2.41 6.93965 3.12Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeactivateIcon;
