/* Copyright */
import React from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TestIds from "../../../test-ids/test-ids";
import { translations } from "../../../generated/translationHelper";
import CustomTextField from "../../common/textfield";
import SearchIcon from "@mui/icons-material/Search";
import { Nullable } from "@sade/data-access";
import { useAccessControl } from "../../../hooks/useAccessControl";

type PartnersViewFilterBarProps = {
  isLoading: boolean;
  handleSearchCriteriaChange: (searchCriteria: string) => void;
  handleVariantSelectChange: (event: SelectChangeEvent<string>) => void;
  handleAddNewPartnerClick: () => void;
  searchString: string;
  selectedVariant: string;
};

const PartnersViewFilterBar: React.FC<PartnersViewFilterBarProps> = ({
  isLoading,
  handleSearchCriteriaChange,
  handleVariantSelectChange,
  handleAddNewPartnerClick,
  searchString,
  selectedVariant,
}) => {
  const [searchCriteria, setSearchCriteria] = React.useState<string>(searchString);
  const [searchTimeout, setSearchTimeout] = React.useState<Nullable<NodeJS.Timeout>>(null);

  React.useEffect(() => {
    if (searchTimeout) clearTimeout(searchTimeout);

    if (searchCriteria != null && searchCriteria !== searchString) {
      setSearchTimeout(
        setTimeout(() => {
          handleSearchCriteriaChange(searchCriteria);
        }, 1500)
      );
    }

    return (): void => {
      if (searchTimeout) clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria, handleSearchCriteriaChange]);

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchCriteria(event.target.value);
  };

  const handleEnterClick = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (searchTimeout) clearTimeout(searchTimeout);
      handleSearchCriteriaChange(searchCriteria);
    }
  };

  const handleSearchClick = (): void => {
    if (searchTimeout) clearTimeout(searchTimeout);
    handleSearchCriteriaChange(searchCriteria);
  };

  const isSuperUser = useAccessControl();

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column-reverse", xl: "row" },
        alignItems: { xl: "center" },
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-around",
          maxWidth: { xs: "100%", xl: "60rem" },
        }}
      >
        <CustomTextField
          label={translations.common.inputs.search()}
          id="searchString"
          type="search"
          testId={TestIds.Partners.Search}
          onChange={handleSearchStringChange}
          onKeyDown={handleEnterClick}
          placeholder={translations.partners.texts.searchFieldPlaceholder()}
          disabled={isLoading}
          value={searchCriteria}
          inputRef={(input): void => input && input.focus()}
          InputProps={{
            sx: {
              "& input": {
                textOverflow: "ellipsis",
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="search" onClick={handleSearchClick} edge="start" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          className="input"
          sx={{ textAlign: "start", mt: { xs: 3, lg: 0 }, ml: { sx: 0, lg: 4 } }}
          fullWidth
        >
          <InputLabel id="search-by-label" shrink>
            {translations.common.texts.language()}
          </InputLabel>
          <Select
            id="searchBy"
            size="small"
            labelId="search-by-label"
            inputProps={{ "data-testid": TestIds.Partners.VariantSelect }}
            onChange={handleVariantSelectChange}
            disabled={isLoading}
            value={selectedVariant ?? "all"}
          >
            <MenuItem value={"all"}>{translations.common.texts.all()}</MenuItem>
            <MenuItem value={"EN"}>{translations.common.texts.englishUs()}</MenuItem>
            <MenuItem value={"FR"}>{translations.common.texts.french()}</MenuItem>
            <MenuItem value={"ES"}>{translations.common.texts.spanish()}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: { xs: "flex-end", xl: "flex-start" }, ml: 4, mb: { xs: 3, xl: 2 } }}>
        {isSuperUser && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddNewPartnerClick}
            data-testid={TestIds.Partners.NewPartnerButton}
          >
            {translations.partners.texts.addNewPartner()}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PartnersViewFilterBar;
