/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { OtaLog, OtaManager } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import Loading from "../../../loading/loading";
import OtaMonitorLogItem from "./ota-monitor-log-item";

type OtaMonitorTableProps = {
  deviceId: string;
  onError: (error: Error) => void;
};

const maxHeight = window.innerHeight - 400;

const OtaMonitorTable: React.FC<OtaMonitorTableProps> = ({ deviceId, onError }) => {
  const [logs, setLogs] = React.useState<OtaLog[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getOtaLogs = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const logs = await OtaManager.getInstance().getOtaLogs(deviceId);
        setLogs(logs);
      } catch (e) {
        if (e instanceof Error) onError(e);
      }
      setIsLoading(false);
    };
    getOtaLogs();
  }, [deviceId, onError]);

  return (
    <Box sx={{ mt: 2, overflowY: "auto", maxHeight: maxHeight }}>
      {!isLoading && (
        <TableContainer sx={{ overflowX: "initial" }}>
          {logs?.length > 0 && (
            <Table sx={{ maxHeight: maxHeight }}>
              <TableHead>
                <TableRow key="header">
                  <TableCell>
                    <Typography>{translations.otaMonitorDialog.texts.dateTime()}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{translations.common.texts.event()}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs
                  .sort((a, b) => {
                    return b.timestamp.getTime() - a.timestamp.getTime();
                  })
                  .map((log) => (
                    <OtaMonitorLogItem
                      key={log.timestamp.getTime()}
                      timestamp={log.timestamp}
                      state={log.state}
                      updateId={log.updateId}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
          {logs?.length === 0 && <Typography>{translations.otaMonitorDialog.texts.noLogs()}</Typography>}
        </TableContainer>
      )}
      {isLoading && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Loading height={16} marginTop={0.5} />
          <Typography style={{ marginLeft: 8 }}>{translations.otaMonitorDialog.texts.retrieving()}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default OtaMonitorTable;
