/* Copyright */
import * as React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { translations } from "../../generated/translationHelper";
import CustomTextField from "../common/textfield";
import { EventPushSettings } from "../../utils/utils";
import { EventPushType, EventPushTypeDisplay } from "@sade/data-access";
import { validateUrl } from "../../utils/validation";

type PartnerDetailsViewPushSettingsProps = {
  eventPushEnabled: boolean;
  handleToggleEventPushEnabled: () => void;
  eventPushSettings: EventPushSettings; // will be replaced with the correct object containing push settings
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent) => void;
};

const urlError = (url: string): string => {
  return url.length !== 0 && !validateUrl(url) ? translations.common.texts.invalidUrl() : "";
};

const PartnerDetailsViewPushSettings: React.FC<PartnerDetailsViewPushSettingsProps> = ({
  eventPushEnabled,
  handleToggleEventPushEnabled,
  eventPushSettings,
  handleInputChange,
  handleSelectChange,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            {translations.partners.texts.eventPushSettings()}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            {translations.partners.texts.eventPushSettingsInfo()}
          </Typography>
        </Grid>

        {eventPushEnabled ? (
          <React.Fragment>
            <Grid item xs={12} md={6} xl={4}>
              <CustomTextField
                label={translations.partners.texts.pushUrl1()}
                id="pushUrl1"
                testId={""}
                error={!!urlError(eventPushSettings.pushUrl1)}
                errorMessage={urlError(eventPushSettings.pushUrl1)}
                value={eventPushSettings.pushUrl1} // will be replaced with the correct value when it's known
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex", alignItems: "end" }}>
              <CustomTextField
                label={translations.partners.texts.pushUrl2()}
                id="pushUrl2"
                testId={""}
                error={!!urlError(eventPushSettings.pushUrl2)}
                errorMessage={urlError(eventPushSettings.pushUrl2)}
                value={eventPushSettings.pushUrl2} // will be replaced with the correct value when it's known
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex", alignItems: "end" }}>
              <CustomTextField
                label={translations.partners.texts.pushUrl3()}
                id="pushUrl3"
                testId={""}
                error={!!urlError(eventPushSettings.pushUrl3)}
                errorMessage={urlError(eventPushSettings.pushUrl3)}
                value={eventPushSettings.pushUrl3} // will be replaced with the correct value when it's known
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3} sx={{ display: "flex", alignItems: "end" }}>
              <FormControl fullWidth>
                <InputLabel id="event-push-type-label" shrink>
                  {translations.partners.texts.eventPushType()}
                </InputLabel>

                <Select
                  name="eventPushType"
                  size="small"
                  labelId="event-push-type-label"
                  onChange={handleSelectChange}
                  value={eventPushSettings.eventPushType}
                >
                  {Object.values(EventPushType).map((value) => (
                    <MenuItem value={value} key={value}>
                      <ListItemText primary={EventPushTypeDisplay[value]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} xl={9} sx={{ display: "flex", alignItems: "end" }}>
              <CustomTextField
                label={translations.partners.texts.pushRules()}
                id="pushRules"
                placeholder={translations.partners.texts.pushRulesPlaceholder()}
                testId={""}
                value={eventPushSettings.pushRules} // will be replaced with the correct value when it's known
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label={translations.partners.texts.pushConfiguration()}
                id="pushConfiguration"
                placeholder={translations.partners.texts.pushConfigurationPlaceholder()}
                testId={""}
                value={eventPushSettings.pushConfiguration} // will be replaced with the correct value when it's known
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                onClick={handleToggleEventPushEnabled}
                color="error"
              >
                {translations.partners.buttons.disableEventPush()}
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item xs={12}>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleToggleEventPushEnabled}>
              {translations.partners.buttons.enableEventPush()}
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PartnerDetailsViewPushSettings;
