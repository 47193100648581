/* Copyright */
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Device, PeripheralInfo, PeripheralState, PeripheralType, RoleIdentifier } from "@sade/data-access";
import React, { useCallback } from "react";
import { translations } from "../../../../generated/translationHelper";
import { useAccessControl } from "../../../../hooks/useAccessControl";
import TestIds from "../../../../test-ids/test-ids";
import { enableEditDialog } from "../../../../utils/deviceDetailsUtils";
import CardEmptyState from "../../../common/card-empty-state";
import DevicePeripheralsDialog from "../../../dialogs/device/peripherals/device-peripherals-dialog";
import CardContentSkeleton from "../../../loading/card-content-skeleton";
import DeviceViewContentPeripheral from "./device-view-content-peripheral";

type DeviceViewContentPeripheralsProps = {
  isLoading: boolean;
  device?: Device;
};

const DeviceViewContentPeripherals: React.FC<DeviceViewContentPeripheralsProps> = ({ isLoading, device }) => {
  const [isDevicePeripheralsDialogOpen, setIsDevicePeripheralsDialogOpen] = React.useState<boolean>(false);
  const [peripherals, setPeripherals] = React.useState<PeripheralInfo[]>([]);
  const isAuthorizedToView = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.WarehouseInstaller,
    RoleIdentifier.DealerAgent
  );
  const isAuthorizedToEdit = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.WarehouseInstaller
  );

  const handleToggleIsDevicePeripheralsDialogOpen = (): void => {
    setIsDevicePeripheralsDialogOpen(!isDevicePeripheralsDialogOpen);
  };

  const refresh = useCallback(
    async (): Promise<void> => setPeripherals((await device?.listPeripherals()) ?? []),
    [device]
  );

  React.useEffect(() => {
    isAuthorizedToView && refresh();
  }, [isAuthorizedToView, refresh]);

  const renderCompanionPendantSection = (peripherals: PeripheralInfo[]): React.ReactNode => {
    const companionPendant = peripherals.find(
      (peripheral) => peripheral.type === PeripheralType.BasicPendant && peripheral.state !== PeripheralState.Unpairing
    );
    return (
      companionPendant && (
        <React.Fragment>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {translations.devicePeripherals.texts.companionPendantTitle()}
          </Typography>
          <DeviceViewContentPeripheral
            disabled={!enableEditDialog(device?.getDeviceStatus())}
            key={companionPendant.serialNumber}
            peripheral={companionPendant}
          />
        </React.Fragment>
      )
    );
  };

  const renderMHealthDevices = (peripherals: PeripheralInfo[]): React.ReactNode => {
    return peripherals.map((peripheral) => {
      return (
        <DeviceViewContentPeripheral
          disabled={!enableEditDialog(device?.getDeviceStatus())}
          key={peripheral.serialNumber}
          peripheral={peripheral}
        />
      );
    });
  };

  const renderMHealthDevicesSection = (peripherals: PeripheralInfo[]): React.ReactNode => {
    const mHealthDevices = peripherals.filter((peripheral) => peripheral.type !== PeripheralType.BasicPendant);
    return mHealthDevices.length > 0 ? (
      <React.Fragment>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {translations.devicePeripherals.texts.mHealthDevicesTitle()}
        </Typography>
        {renderMHealthDevices(mHealthDevices)}
      </React.Fragment>
    ) : (
      peripherals.length === 0 && <CardEmptyState emptyStateText={translations.devicePeripherals.texts.emptyState()} />
    );
  };

  const renderPeripherals = (): React.ReactNode => {
    return (
      <CardContent>
        {renderCompanionPendantSection(peripherals)}
        {renderMHealthDevicesSection(peripherals)}
      </CardContent>
    );
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.PeripheralsCard}>
        <CardHeader
          title={translations.common.texts.peripherals()}
          action={
            isAuthorizedToEdit &&
            enableEditDialog(device?.getDeviceStatus()) && (
              <Button
                variant="outlined"
                startIcon={<ModeEditIcon />}
                disabled={isLoading}
                sx={{ mr: 3 }}
                onClick={handleToggleIsDevicePeripheralsDialogOpen}
                data-testid={TestIds.DevicePeripheralCard.EditButton}
              >
                {translations.common.buttons.edit()}
              </Button>
            )
          }
        ></CardHeader>
        {isLoading || !device ? <CardContentSkeleton /> : renderPeripherals()}
      </Card>
      <DevicePeripheralsDialog
        open={isDevicePeripheralsDialogOpen}
        peripherals={peripherals}
        device={device}
        onClose={handleToggleIsDevicePeripheralsDialogOpen}
        onRefresh={refresh}
      />
    </Box>
  );
};

export default DeviceViewContentPeripherals;
