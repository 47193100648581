/* Copyright */
import * as React from "react";
import { Box, CircularProgress } from "@mui/material";
import TestIds from "../../test-ids/test-ids";

type LoadingProps = {
  height?: number;
  marginTop?: number;
};

const Loading: React.FC<LoadingProps> = ({ height = 48, marginTop = 5 }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: marginTop }}>
      <CircularProgress size={height} data-testid={TestIds.Misc.LoadingIcon} />
    </Box>
  );
};

export default Loading;
