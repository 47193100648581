/* Copyright */
import React from "react";
import { Box, Skeleton } from "@mui/material";

const CardContentSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton height={400} variant="rounded" />
    </Box>
  );
};

export default CardContentSkeleton;
