/* Copyright */
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button, Card, CardContent, CardHeader, Table, TableBody, tableCellClasses } from "@mui/material";
import {
  Device,
  DeviceAttributeName,
  DeviceOperatorPlan,
  DeviceStatus,
  Maybe,
  Organization,
  OrganizationParameterHierarchy,
  RoleIdentifier,
} from "@sade/data-access";
import React, { Fragment } from "react";
import { translations } from "../../../../generated/translationHelper";
import { useAccessControl } from "../../../../hooks/useAccessControl";
import TestIds from "../../../../test-ids/test-ids";
import { enableEditDialog, getNameRowLabel } from "../../../../utils/deviceDetailsUtils";
import { ExternalDeviceIdentifierParameter, getParameterFromHierarchy } from "../../../../utils/parameterUtils";
import { OrganizationInfo, ParameterNames, PatientInfo } from "../../../../utils/utils";
import TableDataRow from "../../../common/table-data-row";
import DeviceDetailsDialog from "../../../dialogs/device/details/device-details-dialog";
import CardContentSkeleton from "../../../loading/card-content-skeleton";

type DeviceViewContentDetailsProps = {
  isLoading: boolean;
  device?: Device;
  deviceOrganization?: Organization;
  patient?: PatientInfo;
  parameterHierarchy?: OrganizationParameterHierarchy;
  operatorPlans: DeviceOperatorPlan[];
  setDetailsUpdated: (updated: boolean) => void;
};

const DeviceViewContentDetails: React.FC<DeviceViewContentDetailsProps> = ({
  isLoading,
  device,
  deviceOrganization,
  patient,
  parameterHierarchy,
  operatorPlans,
  setDetailsUpdated,
}) => {
  const [isDeviceDetailsDialogOpen, setIsDeviceDetailsDialogOpen] = React.useState<boolean>(false);
  const [realm, setRealm] = React.useState<Maybe<OrganizationInfo>>(undefined);
  const [externalParameter1, setExternalParameter1] = React.useState<Maybe<ExternalDeviceIdentifierParameter>>();
  const [externalParameter2, setExternalParameter2] = React.useState<Maybe<ExternalDeviceIdentifierParameter>>();
  const [externalParameter3, setExternalParameter3] = React.useState<Maybe<ExternalDeviceIdentifierParameter>>();

  React.useEffect(() => {
    const getRealmName = async (): Promise<void> => {
      const id = deviceOrganization?.getId() ?? "";
      const fullName = deviceOrganization?.getFullName() ?? "";
      setRealm({ id, fullName });
    };

    if (deviceOrganization) getRealmName();
  }, [deviceOrganization]);

  const handleToggleIsDeviceDetailsDialogOpen = (): void => {
    setIsDeviceDetailsDialogOpen(!isDeviceDetailsDialogOpen);
  };

  React.useEffect(() => {
    if (parameterHierarchy) {
      setExternalParameter1(
        getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_1,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter
      );
      setExternalParameter2(
        getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_2,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter
      );
      setExternalParameter3(
        getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_3,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter
      );
    }
  }, [parameterHierarchy]);

  const showForState = (status?: DeviceStatus): boolean => {
    if (!status) return false;
    switch (status) {
      case DeviceStatus.activating:
      case DeviceStatus.unmanaged:
      case DeviceStatus.unsuccesful:
      case DeviceStatus.retired:
      case DeviceStatus.deactivating:
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.DetailsCard}>
        <CardHeader
          title={translations.common.texts.details()}
          action={
            useAccessControl(
              RoleIdentifier.PowerUser,
              RoleIdentifier.DealerManager,
              RoleIdentifier.WarehouseInstaller,
              RoleIdentifier.DealerAgent
            ) &&
            device &&
            enableEditDialog(device.getDeviceStatus()) && (
              <Button
                variant="outlined"
                startIcon={<ModeEditIcon />}
                disabled={isLoading}
                sx={{ mr: 3 }}
                onClick={handleToggleIsDeviceDetailsDialogOpen}
                data-testid={TestIds.DeviceDetailsCard.DeviceDetailsEdit}
              >
                {translations.common.buttons.edit()}
              </Button>
            )
          }
        ></CardHeader>
        {isLoading && !device ? (
          <CardContentSkeleton />
        ) : (
          <CardContent>
            <Table
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableDataRow
                  label={getNameRowLabel(externalParameter1)}
                  value={
                    (externalParameter1 ? device?.getAttribute(DeviceAttributeName.externalId1) : device?.getName()) ??
                    ""
                  }
                  testId={TestIds.DeviceDetailsCard.DeviceName}
                />
                <TableDataRow
                  label={translations.common.texts.imei()}
                  value={device?.getId() ?? ""}
                  testId={TestIds.DeviceDetailsCard.DeviceImei}
                />
                <TableDataRow
                  label={translations.common.texts.iccid()}
                  value={device?.getAttribute(DeviceAttributeName.ICCID) ?? ""}
                />
                <TableDataRow
                  label={translations.common.texts.serialNumber()}
                  value={device?.getAttribute(DeviceAttributeName.serialNumber) ?? ""}
                  maxLength={25}
                />
                <TableDataRow
                  label={translations.deviceDetails.texts.networkOperatorAndPlan()}
                  value={`${
                    device?.getNetworkOperatorDisplayName(operatorPlans) ??
                    device?.getAttribute(DeviceAttributeName.networkOperator)
                  } - ${device?.getOperatorPlanDisplayName(operatorPlans) ?? ""}`}
                />
                <TableDataRow
                  label={translations.common.texts.realm()}
                  value={realm?.fullName ?? ""}
                  testId={TestIds.DeviceDetailsCard.DeviceRealm}
                />
                <TableDataRow
                  label={translations.common.texts.hardwareVersion()}
                  value={device?.getAttribute(DeviceAttributeName.hardwareVersion) ?? ""}
                />
                <TableDataRow
                  label={translations.deviceDetails.texts.softwareVersion()}
                  value={device?.getAttribute(DeviceAttributeName.softwareVersion) ?? ""}
                />
                {device && showForState(device?.getDeviceStatus()) && (
                  <Fragment>
                    <TableDataRow
                      label={translations.common.texts.variant()}
                      value={device?.getAttribute(DeviceAttributeName.variant) ?? ""}
                    />
                    <TableDataRow
                      label={translations.common.texts.callerIdSim()}
                      value={device?.getAttribute(DeviceAttributeName.callerIdNumber) ?? ""}
                    />
                    <TableDataRow label={translations.common.texts.customerId()} value={patient?.customerId ?? ""} />
                  </Fragment>
                )}
                {externalParameter2 && (
                  <TableDataRow
                    label={externalParameter2.label}
                    value={device?.getAttribute(DeviceAttributeName.externalId2) ?? ""}
                  />
                )}
                {externalParameter3 && (
                  <TableDataRow
                    label={externalParameter3.label}
                    value={device?.getAttribute(DeviceAttributeName.externalId3) ?? ""}
                  />
                )}
              </TableBody>
            </Table>
          </CardContent>
        )}
      </Card>
      <DeviceDetailsDialog
        open={isDeviceDetailsDialogOpen}
        onClose={handleToggleIsDeviceDetailsDialogOpen}
        realm={realm}
        device={device}
        patient={patient}
        onDetailsUpdated={setDetailsUpdated}
        externalParameter1={externalParameter1}
        externalParameter2={externalParameter2}
        externalParameter3={externalParameter3}
        operatorPlans={operatorPlans}
      />
    </>
  );
};

export default DeviceViewContentDetails;
