/* Copyright */
import React from "react";

const DashboardIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM2 16V2H8V16H2ZM16 16H10V9H16V16ZM16 7H10V2H16V7Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default DashboardIcon;
