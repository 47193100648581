/* Copyright */
import * as React from "react";
import { Typography, Button, CircularProgress } from "@mui/material";
import { translations } from "../../generated/translationHelper";
import CustomTextField from "../common/textfield";
import TestIds from "../../test-ids/test-ids";

type ForgotPasswordFormProps = {
  email: string;
  isLoading: boolean;
  emailError: string;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSendClick: () => void;
  handleBackClick: () => void;
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  email,
  isLoading,
  emailError,
  handleOnChange,
  handleSendClick,
  handleBackClick,
}) => {
  const isButtonDisabled = !email || isLoading;

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.forgotPassword.texts.title()}
      </Typography>
      <Typography variant="body1" className="title">
        {translations.forgotPassword.texts.subtitle()}
      </Typography>
      <CustomTextField
        label={translations.common.inputs.email()}
        id="email"
        autoComplete="off"
        value={email}
        onChange={handleOnChange}
        errorMessage={emailError}
        testId={TestIds.ForgotPasswordForm.ForgotPasswordEmailInput}
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleSendClick();
        }}
      />
      <Button
        data-testid={TestIds.ForgotPasswordForm.ForgotPasswordSubmit}
        size="large"
        variant="contained"
        className="button"
        onClick={handleSendClick}
        disabled={isButtonDisabled}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
      >
        {translations.forgotPassword.buttons.send()}
      </Button>
      <Button
        data-testid={TestIds.ForgotPasswordForm.ForgotPasswordBack}
        size="large"
        variant="text"
        className="dense-button"
        onClick={handleBackClick}
      >
        {translations.forgotPassword.buttons.back()}
      </Button>
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
