/* Copyright */
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  SelectChangeEvent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { OtaPackageInfo } from "@sade/data-access/src/generated/gqlDevice";
import CustomTextField from "../../common/textfield";
import FileUpload from "../../common/file-upload";
import { Maybe, OtaManager } from "@sade/data-access";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SeverityType } from "../../../context/snackbar-context";

type SendBulkOtasDialogProps = {
  open: boolean;
  handleClose: () => void;
  otaPackages: OtaPackageInfo[];
};

const SendBulkOtasDialog: React.FC<SendBulkOtasDialogProps> = ({ open, handleClose, otaPackages }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const [selectedDeviceType, setSelectedDeviceType] = React.useState<string>("");
  const [selectedOtaPackageId, setSelectedOtaPackageId] = React.useState<string>("");
  const [selectedImeiInputMethod, setSelectedImeiInputMethod] = React.useState<Maybe<number>>();
  const [selectedImeis, setSelectedImeis] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<Maybe<File>>(undefined);

  const handleOtaPackageChange = (event: SelectChangeEvent): void => {
    setSelectedOtaPackageId(event.target.value);
  };

  const handleImeiInputMethodChange = (event: SelectChangeEvent): void => {
    setSelectedImeiInputMethod(Number(event.target.value));
    setSelectedImeis([]);
  };

  const handleImeiInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const textValue = event.target.value;
    const imeiArray = textValue
      .split("\n")
      .map((imei) => imei.trim())
      .filter(Boolean);
    setSelectedImeis(imeiArray);
  };

  const parseCSV = (csvData: string): string[] => {
    const rows = csvData.trim().split(",");
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }
    return rows;
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event): void => {
        const csvData = event.target?.result as string;
        const parsedData = parseCSV(csvData);
        setSelectedImeis(parsedData);
      };
    }

    setSelectedFile(file);
  };

  const handleSendClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await OtaManager.getInstance().triggerLibrisDeviceOtaUpdate(selectedImeis, selectedOtaPackageId);
      showSnackbar(translations.sendBulkOtasDialog.texts.successText(), SeverityType.Success);
      handleClose();
    } catch (error) {
      console.error(error);
      showSnackbar(translations.sendBulkOtasDialog.texts.failText(), SeverityType.Error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSendDisabled =
    isLoading ||
    !selectedDeviceType ||
    !selectedOtaPackageId ||
    (selectedImeiInputMethod === 1 && !selectedImeis.length) ||
    (selectedImeiInputMethod === 2 && !selectedFile);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.SendBulkOtaPackageDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.sendBulkOtasDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
            data-testid={TestIds.SendBulkOtaPackageDialog.CloseButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {translations.sendBulkOtasDialog.texts.subtitle()}
          <Grid container spacing={2} mt={2} mb={3}>
            <Grid item xs={12} md={4}>
              <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                <InputLabel id="device-type-select-label" shrink>
                  {translations.common.texts.deviceType()}
                </InputLabel>
                <Select
                  id="device-type-select"
                  size="small"
                  onChange={(e): void => setSelectedDeviceType(e.target.value)}
                  labelId="device-type-select-label"
                  value={selectedDeviceType}
                  data-testid={TestIds.SendBulkOtaPackageDialog.OpenDeviceTypeSelection}
                >
                  <MenuItem value={"Libris 2"}>{"Libris 2"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                <InputLabel id="ota-package-select-label" shrink>
                  {translations.sendBulkOtasDialog.texts.otaPackage()}
                </InputLabel>
                <Select
                  id="ota-package-select"
                  size="small"
                  onChange={handleOtaPackageChange}
                  labelId="ota-package-select-label"
                  value={selectedOtaPackageId}
                  data-testid={TestIds.SendBulkOtaPackageDialog.OpenOtaPackageSelection}
                >
                  {otaPackages?.map((otaPackage) => (
                    <MenuItem key={otaPackage.otaId} value={otaPackage.otaId}>
                      {otaPackage.otaPackageName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl>
            <RadioGroup
              row
              id="imeiInputMethod"
              value={selectedImeiInputMethod ?? ""}
              onChange={handleImeiInputMethodChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio size="small" />}
                label={translations.common.texts.enterTheImeiCodes()}
              />
              <FormControlLabel
                value={2}
                control={<Radio size="small" />}
                label={translations.common.texts.uploadImeiCodes()}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            {selectedImeiInputMethod === 1 && (
              <CustomTextField
                id="imei-input"
                testId=""
                multiline
                type="number"
                minRows={3}
                maxRows={8}
                label={translations.common.texts.imeis()}
                placeholder={translations.common.texts.enterImeisPlaceholder()}
                onChange={handleImeiInputChange}
              />
            )}
            {selectedImeiInputMethod === 2 && (
              <FileUpload
                infoText={translations.common.texts.uploadImeisPlaceholder()}
                acceptedFileType="text/csv"
                handleFileChange={handleFileChange}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2, mt: 0 }}>
          <Button
            variant="contained"
            onClick={handleSendClick}
            disabled={isSendDisabled}
            startIcon={isLoading ? <CircularProgress size={16} color="secondary" /> : <PublishOutlinedIcon />}
            data-testid={TestIds.SendBulkOtaPackageDialog.SendButton}
          >
            {translations.sendBulkOtasDialog.buttons.sendOta()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            data-testid={TestIds.SendBulkOtaPackageDialog.ActionCancelButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SendBulkOtasDialog;
