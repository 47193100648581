/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { BackendFactory, Device, DeviceAttributeName, TeamMember } from "@sade/data-access";
import * as React from "react";
import { useEverThereContext } from "../../../../../context/everThere-context";
import { SeverityType } from "../../../../../context/snackbar-context";
import { translations } from "../../../../../generated/translationHelper";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import TestIds from "../../../../../test-ids/test-ids";

type RemoveDeviceFromMemberDialogProps = {
  open: boolean;
  member: TeamMember;
  device?: Device;
  onClose: () => void;
};

const RemoveDeviceFromMemberDialog: React.FC<RemoveDeviceFromMemberDialogProps> = ({
  open,
  member,
  device,
  onClose,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const memberFirstName = member.firstName ?? member.email ?? "";
  const backend = BackendFactory.getOrganizationBackend();
  const { getEverThereData } = useEverThereContext();

  const handleClickConfirm = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const patientId = device?.getAttribute(DeviceAttributeName.patientId) ?? "";
      const patient = await backend.getPatient(patientId);
      if (patient) {
        await patient.updateAttributes({ wearer: "" });
        showSnackbar(
          translations.deviceEverThereRemoveDeviceDialog.texts.successPopUp({ memberFirstName }),
          SeverityType.Success,
          undefined,
          undefined,
          translations.deviceEverThereRemoveDeviceDialog.texts.successDescription({ memberFirstName })
        );
      }
      getEverThereData(patientId);
    } catch (error) {
      let errorCode = "";

      if (error instanceof Error) {
        errorCode = error.message;
      }

      showSnackbar(
        translations.deviceEverThereRemoveDeviceDialog.texts.failurePopUp({ memberFirstName }),
        SeverityType.Error,
        undefined,
        undefined,
        translations.deviceEverThereRemoveDeviceDialog.texts.failureDescription({ errorCode })
      );
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DeviceEverThereRemoveDeviceFromMemberDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereRemoveDeviceDialog.texts.title({ memberFirstName })}
          <IconButton
            aria-label="close"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereRemoveDeviceFromMemberDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {translations.deviceEverThereRemoveDeviceDialog.texts.content({ memberFirstName })}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            color="error"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
            onClick={handleClickConfirm}
            data-testid={TestIds.DeviceEverThereRemoveDeviceFromMemberDialog.ConfirmButton}
          >
            {translations.common.buttons.confirm()}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereRemoveDeviceFromMemberDialog.ActionCloseButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RemoveDeviceFromMemberDialog;
