/* Copyright */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { AuthWrapper, ErrorWithCode, isErrorWithCode } from "@sade/data-access";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/Numera-Nice-brand-Logo-color.svg";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import TestIds from "../../test-ids/test-ids";
import { getPath } from "../../utils/ssoPathUtil";
import { LoginFormProps, Paths } from "../../utils/utils";
import { isValidEmail, removeTrailingWhitespace } from "../../utils/validation";
import CustomTextField from "../common/textfield";

export type SnackbarItemType = {
  message: string;
};

const LoginForm: React.FC<LoginFormProps> = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { setAuthenticatedUser } = useAuthenticatedUser();
  const [loginInfo, setLoginInfo] = React.useState({ email: "", password: "", rememberMe: false });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    generalError: "",
    emailError: "",
    passwordError: "",
  });

  React.useEffect(() => {
    const loginInfos = JSON.parse(localStorage.getItem("numeraLoginInfo")!);
    if (loginInfos) {
      setLoginInfo(loginInfos);
    }
  }, []);

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    }
  }, [errors, showSnackbar]);

  const clearErrors = async (): Promise<void> => {
    const updatedErrors = { generalError: "", emailError: "", passwordError: "" };
    setErrors(updatedErrors);
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors();
    const updatedLoginInfo = { ...loginInfo, [event.target.id]: event.target.value };
    setLoginInfo(updatedLoginInfo);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors();
    const updatedLoginInfo = { ...loginInfo, rememberMe: event.target.checked };
    setLoginInfo(updatedLoginInfo);
  };

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleErrors = (reason: string, message?: string): void => {
    switch (reason) {
      case "EmailFormatNotValidException":
        setErrors({
          generalError: "",
          emailError: translations.common.texts.emailFormatNotValid(),
          passwordError: "",
        });
        break;
      case "LimitExceededException":
        setErrors({
          generalError: translations.common.texts.tooManyAttempts(),
          emailError: "",
          passwordError: "",
        });
        break;
      case "NetworkError":
        setErrors({
          generalError: translations.common.texts.networkError(),
          emailError: "",
          passwordError: "",
        });
        break;
      case "ResetPasswordNotAuthorizedException":
        if (message?.includes("User password cannot be reset in the current state")) {
          setErrors({
            generalError: "",
            emailError: "",
            passwordError: translations.logIn.texts.passwordCannotBeResetCurrently(),
          });
        } else {
          setErrors({
            generalError: "",
            emailError: "",
            passwordError: translations.logIn.texts.incorrectCredentials(),
          });
        }
        break;
      case "NotAuthorizedException":
      case "UserNotFoundException":
        setErrors({
          generalError: "",
          emailError: translations.logIn.texts.incorrectCredentials(),
          passwordError: translations.logIn.texts.incorrectCredentials(),
        });
        break;
      case "InvalidParameterException":
        setErrors({
          generalError: "",
          emailError: translations.logIn.texts.emailNotVerified(),
          passwordError: "",
        });
        break;
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          emailError: "",
          passwordError: "",
        });
        break;
    }
  };

  const isEmailValid = (email?: string): void | never => {
    const testedEmail = email ?? loginInfo.email;

    if (!isValidEmail(testedEmail)) {
      throw new ErrorWithCode("EmailFormatNotValidException");
    }
  };

  const handleLoginClick = async (): Promise<void> => {
    await clearErrors();

    if (loginInfo.rememberMe) {
      localStorage.setItem(
        "numeraLoginInfo",
        JSON.stringify({
          email: loginInfo.email,
          rememberMe: loginInfo.rememberMe,
        })
      );
    } else {
      localStorage.removeItem("numeraLoginInfo");
    }

    try {
      loginInfo.email = removeTrailingWhitespace(loginInfo.email);
      isEmailValid();
      setIsLoading(true);
      const user = await AuthWrapper.logIn(loginInfo.email, loginInfo.password);
      if (user) {
        setAuthenticatedUser(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          navigate(getPath(Paths.REGISTRATION), { state: { attributes: user.challengeParam?.userAttributes } });
        } else {
          navigate(getPath(Paths.ROOT));
        }
      }
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = !loginInfo.email || !loginInfo.password || isLoading;

  return (
    <Box className="page-container">
      <img src={logo} alt="Numera Nice brand logo" className="image" />
      <Typography variant="h5" className="title">
        {translations.logIn.texts.title()}
      </Typography>
      <Typography variant="body1" className="title">
        {translations.logIn.texts.subtitle()}
      </Typography>
      <CustomTextField
        label={translations.common.inputs.email()}
        id="email"
        value={loginInfo.email}
        onChange={handleTextInputChange}
        errorMessage={errors.emailError}
        testId={TestIds.LoginView.Email}
        autoFocus={true}
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleLoginClick();
        }}
      />
      <CustomTextField
        label={translations.logIn.inputs.password()}
        id="password"
        type={showPassword ? "text" : "password"}
        onChange={handleTextInputChange}
        errorMessage={errors.passwordError}
        testId={TestIds.LoginView.Password}
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleLoginClick();
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Link href="/forgot-password" underline="hover" variant="body2" className="link">
        {translations.logIn.links.forgotPassword()}
      </Link>
      <FormControl className="input" sx={{ ml: "16px" }}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid={TestIds.LoginView.RememberMe}
              checked={loginInfo.rememberMe}
              onChange={handleCheckboxChange}
            />
          }
          label={translations.logIn.inputs.rememberMe()}
        />
      </FormControl>
      <Button
        data-testid={TestIds.LoginView.Submit}
        size="large"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={handleLoginClick}
        className={"dense-button"}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
      >
        {translations.logIn.buttons.logIn()}
      </Button>
    </Box>
  );
};

export default LoginForm;
