/* Copyright */
import { Box } from "@mui/material";
import { Timestamp } from "@sade/data-access";
import React from "react";

type TimerProps = {
  startTime?: Timestamp;
};

const Timer: React.FC<TimerProps> = ({ startTime = null }) => {
  const [duration, setDuration] = React.useState(0);

  React.useEffect(() => {
    const startTimestamp = startTime ?? Date.now();
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTimestamp;
      setDuration(elapsedTime);
    }, 1000);

    return (): void => {
      clearInterval(interval);
    };
  }, [startTime]);

  const formatDuration = (milliseconds: number): string => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = (minutes % 60).toString().padStart(2, "0");
    const formattedSeconds = (seconds % 60).toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return <Box sx={{ ml: 2 }}>{formatDuration(duration)}</Box>;
};

export default Timer;
