/* Copyright*/
import { NumeraDevice } from "../NumeraDeviceBase";
export class Panel4200x extends NumeraDevice {
    constructor(backend, params) {
        super(Panel4200x.type, backend, params);
    }
    static instanceOf(device) {
        return device instanceof Panel4200x;
    }
}
Panel4200x.type = "Panel4200x";
