/* Copyright */

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { translations } from "../../generated/translationHelper";
import { NAVIGATE_BACK } from "../../utils/utils";

type DeviceEventsLocationsViewHeaderProps = {
  deviceId: string;
};

const DeviceEventsLocationsViewHeader: React.FC<DeviceEventsLocationsViewHeaderProps> = ({ deviceId }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ mb: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Button
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={(): void => navigate(NAVIGATE_BACK)}
          sx={{ alignSelf: "flex-start" }}
        >
          {translations.common.buttons.back()}
        </Button>
      </Box>
      <Typography variant={"h5"} sx={{ mr: 2 }}>
        {translations.deviceEventsLocationsView.texts.title({ deviceId })}
      </Typography>
    </Box>
  );
};

export default DeviceEventsLocationsViewHeader;
