/* Copyright */
import React from "react";
import { useAccessControl } from "../../hooks/useAccessControl";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import AccessDenied from "../common/access-denied";
import Loading from "../loading/loading";

type AccessControlProps = {
  roles: string[];
  children: React.ReactNode;
};

const AccessControl: React.FC<AccessControlProps> = ({ roles, children }) => {
  const { isLoadingAuthenticatedUser } = useAuthenticatedUser();
  const isAuthorized = useAccessControl(...roles);

  return <>{isLoadingAuthenticatedUser ? <Loading /> : isAuthorized ? children : <AccessDenied />}</>;
};

export default AccessControl;
