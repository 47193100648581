/* Copyright */
import * as React from "react";
import { TextField, FormControl, InputLabel, TextFieldProps } from "@mui/material";

type CustomTextFieldProps = {
  label?: string;
  id: string;
  errorMessage?: string;
  testId: string;
  fullWidth?: boolean;
  textField?: boolean;
  required?: boolean;
} & TextFieldProps;

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  label = "",
  id,
  errorMessage = "",
  testId,
  fullWidth = true,
  textField = true,
  required = false,
  ...rest
}) => {
  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel htmlFor={id} shrink disabled={rest.disabled}>
          {label} {required ? "*" : ""}
        </InputLabel>
      )}
      <TextField
        id={id}
        className="input"
        error={errorMessage.length > 0}
        helperText={errorMessage.length > 0 && errorMessage}
        inputProps={{ "data-testid": testId }}
        type={textField ? "text" : "tel"}
        {...rest}
      ></TextField>
    </FormControl>
  );
};

export default CustomTextField;
