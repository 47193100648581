/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export var SimAction;
(function (SimAction) {
    SimAction["Activate"] = "Activate";
    SimAction["Deactivate"] = "Deactivate";
})(SimAction || (SimAction = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["Libris_2Att"] = "LIBRIS_2_ATT";
    DeviceType["Libris_2Verizon"] = "LIBRIS_2_VERIZON";
    DeviceType["Panel_4200X"] = "PANEL_4200X";
})(DeviceType || (DeviceType = {}));
export var SimManagementJobState;
(function (SimManagementJobState) {
    SimManagementJobState["Created"] = "Created";
    SimManagementJobState["Processing"] = "Processing";
    SimManagementJobState["Completed"] = "Completed";
    SimManagementJobState["Failed"] = "Failed";
})(SimManagementJobState || (SimManagementJobState = {}));
export var DeviceSearchField;
(function (DeviceSearchField) {
    DeviceSearchField["Imei"] = "Imei";
    DeviceSearchField["Iccid"] = "Iccid";
    DeviceSearchField["CallerId"] = "CallerId";
    DeviceSearchField["DeviceName"] = "DeviceName";
})(DeviceSearchField || (DeviceSearchField = {}));
