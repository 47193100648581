/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";

type ConfirmDeletePartnerDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

const ConfirmDeletePartnerDialog: React.FC<ConfirmDeletePartnerDialogProps> = ({
  open,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth data-testid={TestIds.ConfirmDeletePartnerDialog.Dialog}>
      <DialogTitle variant="h5">
        {translations.partners.texts.confirmDeletePartner()}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.ConfirmDeletePartnerDialog.CloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography variant="body1">{translations.partners.texts.confirmDeletePartnerInfo()}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleConfirm}
          color="error"
          data-testid={TestIds.ConfirmDeletePartnerDialog.ConfirmButton}
        >
          {translations.common.buttons.confirm()}
        </Button>
        <Button variant="outlined" onClick={handleClose} data-testid={TestIds.ConfirmDeletePartnerDialog.CancelButton}>
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletePartnerDialog;
