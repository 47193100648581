/* Copyright */
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, SelectChangeEvent } from "@mui/material";
import logo from "../../assets/images/Numera-Nice-brand-Logo-color.svg";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SeverityType } from "../../context/snackbar-context";
import RegistrationForm from "./registration-form";
import RegistrationSmsVerificationForm from "./registration-sms-verification-form";
import SuccessView from "./success-view";
import { AuthWrapper, isErrorWithCode } from "@sade/data-access";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { Paths, trimPhoneNumber } from "../../utils/utils";
import { getPath } from "../../utils/ssoPathUtil";

type Props = {
  initialProgress: string;
};

const RegistrationView: React.FC<Props> = ({ initialProgress }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showSnackbar } = useSnackbar();
  const { authenticatedUser, setAuthenticatedUser, isRndUser, getUserData } = useAuthenticatedUser();
  const [progress, setProgress] = React.useState(process.env.NODE_ENV === "test" ? initialProgress : "fillDetails");
  const [isLoading, setIsLoading] = React.useState(false);
  const [registrationInfo, setRegistrationInfo] = React.useState({
    firstName: state?.attributes?.given_name ?? "",
    lastName: state?.attributes?.family_name ?? "",
    userName: state?.attributes?.email ?? "",
    newPassword: "",
    confirmNewPassword: "",
    country: state?.attributes?.["custom:country"] ?? "United States",
    countryCode: !state?.attributes?.phone_number
      ? "+1"
      : state?.attributes?.phone_number?.slice(0, 4) === "+358"
      ? "+358"
      : "+1",

    phoneNumber: state?.attributes?.phone_number
      ? state?.attributes?.phone_number?.slice(0, 2) === "+1"
        ? state?.attributes?.phone_number.substring(2)
        : state?.attributes?.phone_number.substring(4)
      : "",
    acceptTermsAndConditions: false,
  });
  const [errors, setErrors] = React.useState({
    generalError: "",
    newPasswordError: "",
    phoneNumberError: "",
    verificationCodeError: "",
  });
  const [success, setSuccess] = React.useState("");
  const [trimmedPhoneNumber, setTrimmedPhoneNumber] = React.useState("");
  const [phoneNumberVerificationCode, setPhoneNumberVerificationCode] = React.useState("");

  React.useEffect(() => {
    if (authenticatedUser?.challengeName !== "NEW_PASSWORD_REQUIRED") {
      navigate(getPath(Paths.ROOT));
    }
  }, [authenticatedUser, navigate]);

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    } else if (success) {
      showSnackbar(success, SeverityType.Success);
    }
  }, [errors, success, showSnackbar]);

  const clearErrorsAndSuccess = (): void => {
    setErrors({
      generalError: "",
      newPasswordError: "",
      phoneNumberError: "",
      verificationCodeError: "",
    });
    setSuccess("");
  };

  const handleErrors = (code?: string): void => {
    switch (code) {
      case "TooManyRequestsException":
      case "LimitExceededException":
        setErrors({
          generalError: translations.common.texts.tooManyAttempts(),
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: "",
        });
        break;
      case "InternalErrorException":
      case "ServiceException":
      case "ServiceFailure":
      case "Network error":
        setErrors({
          generalError: translations.common.texts.networkError(),
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: "",
        });
        break;
      case "InvalidPasswordException":
        setErrors({
          generalError: "",
          newPasswordError: translations.common.texts.passwordDoesNotMeetRequirements(),
          phoneNumberError: "",
          verificationCodeError: "",
        });
        break;
      case "CodeMismatchException":
        setErrors({
          generalError: "",
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: translations.forgotPasswordResetPassword.texts.invalidCode(),
        });
        break;
      case "ExpiredCodeException":
        setErrors({
          generalError: "",
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: translations.forgotPasswordResetPassword.texts.codeExpired(),
        });
        break;
      case "AliasExistsException":
        setErrors({
          generalError: "",
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: translations.common.texts.phoneNumberTaken(),
        });
        break;
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          newPasswordError: "",
          phoneNumberError: "",
          verificationCodeError: "",
        });
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...registrationInfo, [event.target.id]: event.target.value };
    setRegistrationInfo(updatedRegistrationInfo);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...registrationInfo, [event.target.name]: event.target.value };
    setRegistrationInfo(updatedRegistrationInfo);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...registrationInfo, acceptTermsAndConditions: event.target.checked };
    setRegistrationInfo(updatedRegistrationInfo);
  };

  const handleSubmitClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    let finalPhoneNumber = undefined;

    if (registrationInfo.newPassword !== registrationInfo.confirmNewPassword) {
      setErrors({
        ...errors,
        newPasswordError: translations.common.texts.passwordsDoNotMatch(),
      });
      return;
    }

    if (registrationInfo.phoneNumber) {
      finalPhoneNumber = trimPhoneNumber(registrationInfo.phoneNumber, registrationInfo.countryCode);
      if (!finalPhoneNumber) {
        setErrors({
          ...errors,
          generalError: "",
          newPasswordError: "",
          phoneNumberError: translations.common.texts.invalidPhoneNumber(),
        });
        return;
      } else {
        setTrimmedPhoneNumber(finalPhoneNumber);
      }
    }

    try {
      setIsLoading(true);
      const user = await AuthWrapper.completeNewPassword(authenticatedUser!, registrationInfo.newPassword, {
        given_name: registrationInfo.firstName,
        family_name: registrationInfo.lastName,
        "custom:country": registrationInfo.country,
      });
      if (user) {
        setAuthenticatedUser(user);
        await getUserData();
      }

      if (finalPhoneNumber) {
        await AuthWrapper.setPhoneNumber(finalPhoneNumber);
        setProgress("verifyPhoneNumber");
      } else {
        setProgress("success");
      }
    } catch (error) {
      if (isErrorWithCode(error)) {
        handleErrors(error.code);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCodeChange = (code: string): void => {
    clearErrorsAndSuccess();
    setPhoneNumberVerificationCode(code);
  };

  const handleResendVerificationCodeClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    try {
      setIsLoading(true);
      await AuthWrapper.setPhoneNumber(trimmedPhoneNumber);
      setSuccess(translations.smsVerification.texts.verificationCodeReSent());
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitVerificationCodeClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    setIsLoading(true);
    try {
      await AuthWrapper.verifyPhoneNumber(phoneNumberVerificationCode);
      setProgress("success");
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePhoneNumber = async (countryCode: string, phoneNumber: string): Promise<void> => {
    const updatedRegistrationInfo = { ...registrationInfo, countryCode: countryCode, phoneNumber: phoneNumber };
    setRegistrationInfo(updatedRegistrationInfo);
  };

  const isButtonDisabled =
    !registrationInfo.firstName ||
    !/\S/.test(registrationInfo.firstName) ||
    !registrationInfo.lastName ||
    !/\S/.test(registrationInfo.lastName) ||
    !registrationInfo.newPassword ||
    !registrationInfo.confirmNewPassword ||
    !registrationInfo.country ||
    !registrationInfo.acceptTermsAndConditions ||
    !authenticatedUser ||
    isLoading;

  return (
    <Box className="page-container">
      <img src={logo} alt="Numera Nice brand logo" className="image" />
      {(progress === "fillDetails" || progress === "") && (
        <RegistrationForm
          isLoading={isLoading}
          registrationInfo={registrationInfo}
          isRndUser={isRndUser}
          passwordError={errors.newPasswordError}
          phoneNumberError={errors.phoneNumberError}
          acceptTermsAndConditions={registrationInfo.acceptTermsAndConditions}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleCheckboxChange={handleCheckboxChange}
          handleSubmitClick={handleSubmitClick}
          isButtonDisabled={isButtonDisabled}
        />
      )}
      {progress === "verifyPhoneNumber" && (
        <RegistrationSmsVerificationForm
          countryCode={registrationInfo.countryCode}
          phoneNumber={registrationInfo.phoneNumber}
          code={phoneNumberVerificationCode}
          handleCodeChange={handleVerificationCodeChange}
          handleSubmitClick={handleSubmitVerificationCodeClick}
          codeError={errors.verificationCodeError}
          handleResendClick={handleResendVerificationCodeClick}
          handleUpdatePhoneNumber={handleUpdatePhoneNumber}
          isLoading={isLoading}
        />
      )}
      {progress === "success" && <SuccessView />}
    </Box>
  );
};

export default RegistrationView;
