/* Copyright */
import ScaleIcon from "@mui/icons-material/Speed";
import { Avatar, Box, Chip, Table, TableBody, TableCell, TableRow, Typography, tableCellClasses } from "@mui/material";
import { PeripheralInfo, PeripheralState, PeripheralType } from "@sade/data-access";
import React from "react";
import CompanionIcon from "../../../../assets/icons/devices-icon";
import TestIds from "../../../../test-ids/test-ids";
import {
  getPeripheralTypeTranslation,
  resolvePeripheralStateChipColor,
  resolvePeripheralStateCircleColor,
  resolvePeripheralStateIconColor,
} from "../../../../utils/utils";

type DeviceViewContentPeripheralProps = {
  peripheral: PeripheralInfo;
  disabled?: boolean;
};

const DeviceViewContentPeripheral: React.FC<DeviceViewContentPeripheralProps> = ({ peripheral, disabled }) => {
  return (
    <Box>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: "80px" }}>
              <Avatar
                sx={{
                  bgcolor: resolvePeripheralStateCircleColor(peripheral.state),
                  color: peripheral.state === PeripheralState.Whitelisted ? "black" : "white",
                  opacity: disabled ? 0.5 : 1,
                }}
              >
                {peripheral.type === PeripheralType.BasicPendant ? (
                  <CompanionIcon fill={resolvePeripheralStateIconColor(peripheral.state)} />
                ) : (
                  <ScaleIcon style={{ color: resolvePeripheralStateIconColor(peripheral.state) }} />
                )}
              </Avatar>
            </TableCell>
            <TableCell data-testid={`${TestIds.DevicePeripheralCard.PeripheralSerial}`}>
              <Typography variant="body1">{getPeripheralTypeTranslation(peripheral.type)}</Typography>
              <Typography variant="caption">{"sn: " + peripheral.serialNumber}</Typography>
            </TableCell>

            <TableCell sx={{ textAlign: "end" }} data-testid={`${TestIds.DevicePeripheralCard.State}`}>
              <Chip
                size="small"
                label={peripheral.state.charAt(0).toUpperCase() + peripheral.state.slice(1)}
                sx={{
                  backgroundColor: resolvePeripheralStateChipColor(
                    peripheral.state.charAt(0).toUpperCase() + peripheral.state.slice(1)
                  ),
                  color: peripheral.state === PeripheralState.Whitelisted ? "black" : "white",
                  opacity: disabled ? 0.5 : 1,
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DeviceViewContentPeripheral;
