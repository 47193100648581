/* Copyright */
import React from "react";
import theme from "../../app/theme";
import { Box } from "@mui/material";

type DevicesIconProps = {
  marginRight?: string;
  fill?: string;
};

const DevicesIcon: React.FC<DevicesIconProps> = ({ marginRight = "0", fill = theme.palette.secondary.main }) => {
  return (
    <Box sx={{ mr: marginRight }}>
      <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.75 1C13.75 0.585786 13.4142 0.25 13 0.25C12.5858 0.25 12.25 0.585786 12.25 1C12.25 1.99392 12.7394 2.92507 13.4072 3.59283C14.0749 4.26059 15.0061 4.75 16 4.75C16.4142 4.75 16.75 4.41421 16.75 4C16.75 3.58579 16.4142 3.25 16 3.25C15.4939 3.25 14.9251 2.98941 14.4678 2.53217C14.0106 2.07493 13.75 1.50608 13.75 1ZM12 9C12 6.23858 9.76142 4 7 4C4.23858 4 2 6.23858 2 9V15C2 17.7614 4.23858 20 7 20C9.76142 20 12 17.7614 12 15V9ZM7 2C3.13401 2 0 5.13401 0 9V15C0 18.866 3.13401 22 7 22C10.866 22 14 18.866 14 15V9C14 5.13401 10.866 2 7 2ZM7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14ZM7 16C9.20914 16 11 14.2091 11 12C11 9.79086 9.20914 8 7 8C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16Z"
          fill={fill}
        />
      </svg>
    </Box>
  );
};

export default DevicesIcon;
