/* Copyright */
import * as React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import PastLocation2Icon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-PastLocation-02.svg";
import HomeAddressIcon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-HomeAddress.svg";
import SatelliteFixIcon from "../../../../assets/icons/MapPin-PastLocation-SatelliteFix.svg";
import NetworkFixIcon from "../../../../assets/icons/MapPin-PastLocation-NetworkFix.svg";
import WifiFixIcon from "../../../../assets/icons/MapPin-PastLocation-WifiFix.svg";
import { translations } from "../../../../generated/translationHelper";

const EventMapDefinitionOverlay: React.FC = () => {
  return (
    <Box sx={{ borderRadius: 1, bgcolor: "white", boxShadow: 1 }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <img src={SatelliteFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.satelliteFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={WifiFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.wifiFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={NetworkFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.networkFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={PastLocation2Icon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.common.texts.pastLocation()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={HomeAddressIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.activeCalls.texts.homeAddress()}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default EventMapDefinitionOverlay;
