/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { Device, Maybe, TeamMember } from "@sade/data-access";
import * as React from "react";
import { useEverThereContext } from "../../../../../context/everThere-context";
import { translations } from "../../../../../generated/translationHelper";
import TestIds from "../../../../../test-ids/test-ids";
import InviteNewEverThereUserDialog from "../invite-new-everthere-user-dialog/invite-new-everthere-user-dialog";
import MakeUserTeamManagerDialog from "../make-member-team-manager-dialog/make-member-team-manager-dialog";
import RemoveDeviceFromMemberDialog from "../remove-device-from-team-member-dialog/remove-device-from-team-member";
import RemoveMemberFromTeamDialog from "../remove-member-from-team-dialog/remove-member-from-team-dialog";
import EverThereAccountsDialogContent from "./everthere-accounts-dialog-content";
import MakeMemberAWearerDialog from "./make-member-a-wearer-dialog/make-member-a-wearer-dialog";

type EverThereAccountsDialogProps = {
  open: boolean;
  device?: Device;
  onClose: () => void;
};

const EverThereAccountsDialog: React.FC<EverThereAccountsDialogProps> = ({ open, device, onClose }) => {
  const [isInviteNewEverThereUserDialogOpen, setIsInviteNewEverThereUserDialogOpen] = React.useState<boolean>(false);

  const [isMakeMemberTeamManagerDialogOpen, setIsMakeMemberTeamManagerDialogOpen] = React.useState<boolean>(false);
  const [isRemoveTeamMemberDialogOpen, setIsRemoveTeamMemberDialogOpen] = React.useState<boolean>(false);
  const [isRemoveDeviceDialogOpen, setIsRemoveDeviceDialogOpen] = React.useState<boolean>(false);
  const [isMakeMemberAWearerDialogOpen, setIsMakeMemberAWearerDialogOpen] = React.useState<boolean>(false);
  const [teamMemberToBeModified, setTeamMemberToBeModified] = React.useState<Maybe<TeamMember>>(undefined);

  const { everThereData } = useEverThereContext();

  const members = everThereData?.getMembers() ?? [];

  const toggleIsInviteNewEverThereUserDialogOpen = (): void => {
    setIsInviteNewEverThereUserDialogOpen(!isInviteNewEverThereUserDialogOpen);
  };

  const toggleIsMakeMemberTeamManagerDialogOpen = (): void => {
    setIsMakeMemberTeamManagerDialogOpen(!isMakeMemberTeamManagerDialogOpen);
  };

  const handleOpenMakeMemberTeamManagerDialog = (member: TeamMember): void => {
    setTeamMemberToBeModified(member);
    toggleIsMakeMemberTeamManagerDialogOpen();
  };

  const toggleIsRemoveTeamMemberDialogOpen = (): void => {
    setIsRemoveTeamMemberDialogOpen(!isRemoveTeamMemberDialogOpen);
  };

  const handleOpenRemoveTeamMemberDialog = (member: TeamMember): void => {
    setTeamMemberToBeModified(member);
    toggleIsRemoveTeamMemberDialogOpen();
  };

  const toggleIsRemoveDeviceDialogOpen = (): void => {
    setIsRemoveDeviceDialogOpen(!isRemoveDeviceDialogOpen);
  };

  const handleOpenRemoveDeviceDialog = (member: TeamMember): void => {
    setTeamMemberToBeModified(member);
    toggleIsRemoveDeviceDialogOpen();
  };

  const toggleIsMakeMemberAWearerDialogOpen = (): void => {
    setIsMakeMemberAWearerDialogOpen(!isMakeMemberAWearerDialogOpen);
  };

  const handleOpenMakeMemberAWearerDialog = (member: TeamMember): void => {
    setTeamMemberToBeModified(member);
    toggleIsMakeMemberAWearerDialogOpen();
  };

  const renderRemoveMemberFromTeamDialog = (): JSX.Element | null => {
    if (teamMemberToBeModified) {
      return (
        <RemoveMemberFromTeamDialog
          open={isRemoveTeamMemberDialogOpen}
          everThereData={everThereData}
          member={teamMemberToBeModified}
          handleClose={toggleIsRemoveTeamMemberDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  const renderMakeMemberTeamManagerDialog = (): JSX.Element | null => {
    if (teamMemberToBeModified) {
      return (
        <MakeUserTeamManagerDialog
          open={isMakeMemberTeamManagerDialogOpen}
          member={teamMemberToBeModified}
          handleClose={toggleIsMakeMemberTeamManagerDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  const renderRemoveDeviceFromMemberDialog = (): JSX.Element | null => {
    if (teamMemberToBeModified) {
      return (
        <RemoveDeviceFromMemberDialog
          open={isRemoveDeviceDialogOpen}
          member={teamMemberToBeModified}
          device={device}
          onClose={toggleIsRemoveDeviceDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  const renderMakeMemberAWearerDialog = (): JSX.Element | null => {
    if (teamMemberToBeModified) {
      return (
        <MakeMemberAWearerDialog
          open={isMakeMemberAWearerDialogOpen}
          onClose={toggleIsMakeMemberAWearerDialogOpen}
          member={teamMemberToBeModified}
          device={device}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        data-testid={TestIds.DeviceEverThereAccountsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereAccountsDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereAccountsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <EverThereAccountsDialogContent
            deviceId={device?.getId() ?? ""}
            members={members}
            handleOpenTeamManagerDialog={handleOpenMakeMemberTeamManagerDialog}
            handleDeleteMember={handleOpenRemoveTeamMemberDialog}
            handleOpenRemoveDeviceDialog={handleOpenRemoveDeviceDialog}
            handleOpenMakeMemberAWearerDialog={handleOpenMakeMemberAWearerDialog}
          />
          <Button
            variant="outlined"
            onClick={toggleIsInviteNewEverThereUserDialogOpen}
            startIcon={<PersonAddAltIcon />}
            data-testid={TestIds.DeviceEverThereAccountsDialog.InviteButton}
          >
            {translations.deviceEverThereAccountsDialog.buttons.inviteNewEverThereUser()}
          </Button>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereAccountsDialog.ActionCloseButton}
          >
            {translations.common.texts.close()}
          </Button>
        </DialogActions>
      </Dialog>
      <InviteNewEverThereUserDialog
        open={isInviteNewEverThereUserDialogOpen}
        onClose={toggleIsInviteNewEverThereUserDialogOpen}
        device={device}
      />
      {renderMakeMemberTeamManagerDialog()}
      {renderRemoveMemberFromTeamDialog()}
      {renderRemoveDeviceFromMemberDialog()}
      {renderMakeMemberAWearerDialog()}
    </React.Fragment>
  );
};

export default EverThereAccountsDialog;
