/* Copyright */
import React from "react";
import { Checkbox, FormControlLabel, SxProps, Theme } from "@mui/material";
import { translations } from "../../../generated/translationHelper";
import { AccountFilters } from "../../../utils/utils";

type AccountsViewDisabledToggleProps = {
  isLoading: boolean;
  filters: AccountFilters;
  handleFilterChange: (data: Partial<AccountFilters>) => void;
  sx?: SxProps<Theme>;
  testId?: string;
};

const AccountsViewDisabledToggle: React.FC<AccountsViewDisabledToggleProps> = ({
  isLoading,
  filters,
  handleFilterChange,
  sx,
  testId,
}) => {
  return (
    <FormControlLabel
      className="input"
      sx={{ whiteSpace: "nowrap", mt: 1, ...sx }}
      control={
        <Checkbox
          id="includeDisabled"
          size="small"
          checked={filters.includeDisabled}
          onChange={(): void => handleFilterChange({ includeDisabled: !filters.includeDisabled })}
          disabled={isLoading}
          data-testid={testId}
        />
      }
      label={translations.accounts.inputs.includeDisabled()}
    />
  );
};

export default AccountsViewDisabledToggle;
