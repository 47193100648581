/* Copyright */

import LockIcon from "@mui/icons-material/Lock";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { ParameterValueType } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { ParameterOriginWithName } from "../../../utils/parameterUtils";

export type DeviceSettingsParameterOriginatorProps = {
  parameterOrigin: ParameterOriginWithName;
  isLocked: boolean;
  isOverridden: boolean;
  resetDisabled?: boolean;
  onReset: (parameterValue: ParameterValueType) => void;
};
const DeviceSettingsParameterOriginator: React.FC<DeviceSettingsParameterOriginatorProps> = ({
  parameterOrigin,
  isLocked,
  isOverridden,
  resetDisabled,
  onReset,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isLocked && <LockIcon sx={{ color: theme.palette.grey[600], height: 12, width: 12, mr: 0.5 }} />}
      {isOverridden && !resetDisabled ? (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          sx={{ fontWeight: "bold" }}
          onClick={(): void => onReset(parameterOrigin.parameterValue)}
        >
          {translations.common.buttons.reset()}
        </Link>
      ) : (
        // If the parameter is overriden on device level, return "Device-specific"
        <Typography variant="caption">
          {isOverridden ? translations.deviceSettingsDialog.texts.deviceSpecific() : parameterOrigin.organizationName}
        </Typography>
      )}
    </Box>
  );
};

export default DeviceSettingsParameterOriginator;
