/* Copyright */
import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { PartnerDetails } from "../../../utils/utils";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";

type PartnersTableRowProps = {
  partner: PartnerDetails;
  handleRowClick: (partnerId: string) => void;
  tableRowIndex?: number;
};

const PartnersTableRow: React.FC<PartnersTableRowProps> = ({ partner, handleRowClick, tableRowIndex }) => {
  const variantText = (): string => {
    switch (partner.variant) {
      case "EN":
        return translations.common.texts.englishUs();
      case "FR":
        return translations.common.texts.french();
      case "ES":
        return translations.common.texts.spanish();
      default:
        return "";
    }
  };

  const isSuperUser = useAccessControl();

  return (
    <TableRow
      sx={{ cursor: isSuperUser ? "pointer" : "" }}
      hover
      onClick={isSuperUser ? (): void => handleRowClick(partner.partnerId?.replace("ORG/", "") ?? "") : undefined}
      data-testid={`${TestIds.Partners.PartnersTableRow}-${tableRowIndex}`}
    >
      <TableCell data-testid={`${TestIds.Partners.PartnerName}-${tableRowIndex}`}>
        {partner.partnerName ?? "-"}
      </TableCell>
      <TableCell data-testid={`${TestIds.Partners.RealmName}-${tableRowIndex}`}>{partner.realmName ?? "-"}</TableCell>
      <TableCell>{partner.networkId ?? "-"}</TableCell>
      <TableCell>{variantText()}</TableCell>
    </TableRow>
  );
};

export default PartnersTableRow;
