/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Role, RoleIdentifier, UserInformation } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { OrganizationInfo } from "../../../utils/utils";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import AccountDetailsDialog from "../../dialogs/account-details-dialog/account-details-dialog";
import AccountsTableRow from "./accounts-table-row";

type AccountsTableProps = {
  users: UserInformation[];
  allRoles: Role[];
  organizationInfo: OrganizationInfo[];
  fetched: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  everThere: boolean;
  onReFetchUserData: () => void;
};

const AccountsTable: React.FC<AccountsTableProps> = ({
  users,
  allRoles,
  organizationInfo,
  fetched,
  tableRef,
  isLoading,
  everThere,
  onReFetchUserData,
}) => {
  const canSeeDealerAccountDetails = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.Supervisor
  );
  const canSeeEverThereAccountDetails = useAccessControl(RoleIdentifier.DealerAgent) || canSeeDealerAccountDetails;

  const [accountDetailsDialogOpen, setAccountDetailsDialogOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState("");

  const handleCloseDetailsDialog = (): void => {
    setAccountDetailsDialogOpen(false);
    setSelectedUserId("");
  };

  React.useEffect(() => {
    if (selectedUserId) {
      setAccountDetailsDialogOpen(true);
    }
  }, [selectedUserId]);

  React.useEffect(() => {
    if (accountDetailsDialogOpen && !(canSeeDealerAccountDetails || (canSeeEverThereAccountDetails && everThere))) {
      handleCloseDetailsDialog();
    }
  }, [accountDetailsDialogOpen, canSeeDealerAccountDetails, canSeeEverThereAccountDetails, everThere]);

  return (
    <Box
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
      ref={tableRef}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table
          stickyHeader
          data-testid={TestIds.Accounts.UsersTable}
          size="small"
          sx={{
            "& .MuiTableCell-body:first-of-type": {
              paddingLeft: { xs: "1rem", md: "1.5rem" },
            },
            "& .MuiTableCell-head:first-of-type": {
              paddingLeft: { xs: "1rem", md: "1.5rem" },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.status()}</TableCell>
              <TableCell>{translations.common.texts.firstName()}</TableCell>
              <TableCell>{translations.common.texts.lastName()}</TableCell>
              <TableCell>{translations.common.texts.email()}</TableCell>
              {!everThere && <TableCell>{translations.common.texts.realm()}</TableCell>}
              {!everThere && <TableCell>{translations.common.texts.role()}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0
              ? users.map((user, index) => (
                  <AccountsTableRow
                    key={index}
                    user={user}
                    allRoles={allRoles}
                    organizationInfo={organizationInfo}
                    everThere={everThere}
                    onRowClick={(userId): void => setSelectedUserId(userId)}
                    tableRowIndex={index}
                  />
                ))
              : fetched && <TableEmptyRow colspan={6} />}
            {isLoading && <TableLoadingRow colspan={6} />}
          </TableBody>
        </Table>
      </TableContainer>
      {(canSeeDealerAccountDetails || (everThere && canSeeEverThereAccountDetails)) && (
        <AccountDetailsDialog
          open={accountDetailsDialogOpen}
          handleClose={handleCloseDetailsDialog}
          userId={selectedUserId}
          reFetchUserData={onReFetchUserData}
          everThere={everThere}
        />
      )}
    </Box>
  );
};

export default AccountsTable;
