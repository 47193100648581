/* Copyright */
import {
  Device,
  DeviceSearchField,
  DeviceState,
  DeviceStateProperties,
  DeviceStatus,
  Maybe,
  Nullable,
} from "@sade/data-access";
import * as React from "react";
import { DeviceListItem, OrganizationInfo } from "../utils/utils";

type DeviceContextReturnType = {
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchField: DeviceSearchField;
  setSearchField: React.Dispatch<React.SetStateAction<DeviceSearchField>>;
  selectedRealms: string[];
  setSelectedRealms: React.Dispatch<React.SetStateAction<string[]>>;
  selectedStatuses: DeviceStatus[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<DeviceStatus[]>>;
  filteredDevicesList: DeviceListItem[];
  setFilteredDevicesList: React.Dispatch<React.SetStateAction<DeviceListItem[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  scrollPosition: Nullable<number>;
  setScrollPosition: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  devicesRef: React.MutableRefObject<Device<DeviceState<DeviceStateProperties>>[]>;
  allDevicesListRef: React.MutableRefObject<DeviceListItem[]>;
  childOrganizationListRef: React.MutableRefObject<Maybe<OrganizationInfo[]>>;
};

const DeviceContext = React.createContext<DeviceContextReturnType>({} as DeviceContextReturnType);
const { Provider } = DeviceContext;

export const DeviceContextProvider: React.FC = ({ children }) => {
  const [searchString, setSearchString] = React.useState<string>("");
  const [searchField, setSearchField] = React.useState<DeviceSearchField>(DeviceSearchField.Imei);
  const [selectedRealms, setSelectedRealms] = React.useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState<DeviceStatus[]>([]);
  const [filteredDevicesList, setFilteredDevicesList] = React.useState<DeviceListItem[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [scrollPosition, setScrollPosition] = React.useState<Nullable<number>>(null);

  const devicesRef = React.useRef<Device[]>([]);
  const allDevicesListRef = React.useRef<DeviceListItem[]>([]);
  const childOrganizationListRef = React.useRef<Maybe<OrganizationInfo[]>>();

  const value = React.useMemo(
    (): DeviceContextReturnType => ({
      searchString,
      setSearchString,
      searchField,
      setSearchField,
      selectedRealms,
      setSelectedRealms,
      selectedStatuses,
      setSelectedStatuses,
      filteredDevicesList,
      setFilteredDevicesList,
      page,
      setPage,
      scrollPosition,
      setScrollPosition,
      devicesRef,
      allDevicesListRef,
      childOrganizationListRef,
    }),
    [
      searchString,
      setSearchString,
      searchField,
      setSearchField,
      selectedRealms,
      setSelectedRealms,
      selectedStatuses,
      setSelectedStatuses,
      filteredDevicesList,
      setFilteredDevicesList,
      page,
      setPage,
      scrollPosition,
      setScrollPosition,
      devicesRef,
      allDevicesListRef,
      childOrganizationListRef,
    ]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useDeviceContext = (): DeviceContextReturnType => {
  const context = React.useContext(DeviceContext);
  if (context === undefined) {
    throw new Error("useDeviceContext must be used within a DeviceContextProvider");
  }

  return context;
};
