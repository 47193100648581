/* Copyright */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  CardHeader,
  ListItem,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import { AuthWrapper, BackendFactory } from "@sade/data-access";
import { translations } from "../../generated/translationHelper";
import { Paths, getUserFullName, getUserInitials } from "../../utils/utils";
import ProfileDialog from "../dialogs/profile-dialog/profile-dialog";
import RealmAccessSelectorDialog from "../dialogs/realm-access-selector-dialog/realm-access-selector-dialog";
import TestIds from "../../test-ids/test-ids";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import Loading from "../loading/loading";
import { getPath } from "../../utils/ssoPathUtil";

const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    currentOrganizationName,
    userInfo,
    authenticatedUserRole,
    userOrganizationRoles,
    isLoadingAuthenticatedUser,
    setAuthenticatedUser,
    authenticatedUser,
    isRndUser,
  } = useAuthenticatedUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [realmAccessDialogOpen, setRealmAccessDialogOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.SyntheticEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const userInitials = getUserInitials(
    authenticatedUser?.attributes.given_name ?? "",
    authenticatedUser?.attributes.family_name ?? ""
  );

  const userFullName = getUserFullName(
    authenticatedUser?.attributes.given_name ?? "",
    authenticatedUser?.attributes.family_name ?? ""
  );

  const handleRealmAccessDialogOpen = (): void => {
    setRealmAccessDialogOpen(!realmAccessDialogOpen);
    handleCloseMenu();
  };

  const handleProfileDialogOpen = (): void => {
    if (authenticatedUser) {
      setProfileDialogOpen(!profileDialogOpen);
      handleCloseMenu();
    }
  };

  const handleLogoutClick = async (): Promise<void> => {
    await AuthWrapper.logOut();
    setAuthenticatedUser(null);
    BackendFactory.resetAllBackendCaches();
    navigate(getPath(Paths.LOGOUT));
  };

  return (
    <Box sx={{ alignSelf: "center" }}>
      <IconButton onClick={handleOpenMenu} data-testid={TestIds.Profile.OpenProfileMenuButton}>
        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 32, height: 32, fontSize: 16 }}>
          {userInitials ? userInitials : undefined}
        </Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu} data-testid={TestIds.Profile.ProfileMenu}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{userInitials ? userInitials : undefined}</Avatar>
          }
          title={userFullName}
          subheader={authenticatedUserRole?.name}
          data-testid={TestIds.Profile.ProfileRole}
          sx={{ p: 2, cursor: "pointer" }}
          onClick={(): void => handleProfileDialogOpen()}
        />
        <Divider />
        {isLoadingAuthenticatedUser ? (
          <Box sx={{ padding: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loading height={32} marginTop={0} />
          </Box>
        ) : userOrganizationRoles.length < 2 ? (
          <ListItem>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: "overline", mb: -1 }}
              primary={translations.common.texts.realm()}
              secondaryTypographyProps={{ variant: "body2", color: "black" }}
              secondary={currentOrganizationName}
              data-testid={TestIds.Profile.ProfileRealm}
            ></ListItemText>
          </ListItem>
        ) : (
          <MenuItem
            onClick={(): void => handleRealmAccessDialogOpen()}
            data-testid={TestIds.RealmAccessSelectorDialog.OpenButton}
          >
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ ml: 2 }}
              primaryTypographyProps={{ variant: "overline", mb: -1 }}
              primary={translations.common.texts.realm()}
              secondaryTypographyProps={{ variant: "body2", color: "black" }}
              secondary={currentOrganizationName}
              data-testid={TestIds.Profile.ProfileRealm}
            ></ListItemText>
            <ListItemIcon sx={{ justifyContent: "flex-end", mt: 1 }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        <Divider />
        <MenuItem onClick={(): void => handleProfileDialogOpen()} sx={{ mt: userOrganizationRoles.length < 2 ? 1 : 0 }}>
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primary={translations.common.buttons.viewProfile()}
            data-testid={TestIds.Profile.ViewProfileButton}
          />
          <ListItemIcon sx={{ justifyContent: "flex-end" }}>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primary={translations.common.buttons.logout()}
            data-testid={TestIds.Profile.Logout}
          />
        </MenuItem>
      </Menu>
      <ProfileDialog
        open={profileDialogOpen}
        handleClose={handleProfileDialogOpen}
        currentUser={authenticatedUser ?? undefined}
        userData={userInfo}
        rdUserFlag={isRndUser}
      />
      <RealmAccessSelectorDialog open={realmAccessDialogOpen} handleClose={handleRealmAccessDialogOpen} />
    </Box>
  );
};

export default ProfileMenu;
