/* Copyright */
import { TableCell, TableRow, Typography } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";

type OtaMonitorLogItemProps = {
  timestamp: Date;
  updateId: string;
  state: string;
};

const getOtaStateTranslation = (state: string): string => {
  switch (state) {
    case "MessageSent":
      return translations.otaMonitorDialog.states.messageSent();
    case "MessageRead":
      return translations.otaMonitorDialog.states.messageRead();
    case "DeviceRebooted":
      return translations.otaMonitorDialog.states.deviceRebooted();
    default:
      return state;
  }
};

const OtaMonitorLogItem: React.FC<OtaMonitorLogItemProps> = ({ timestamp, state }) => {
  return (
    <TableRow key={timestamp.getTime()}>
      <TableCell>
        <Typography variant="body2">{timestamp.toLocaleString(i18next.language)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{getOtaStateTranslation(state)}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default OtaMonitorLogItem;
