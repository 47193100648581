/* Copyright */

import { Alert, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Device, EventType, Nullable } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { DeviceEvent } from "../../../utils/eventUtils";

import CustomDateRangePicker from "../../common/custom-date-range-picker";
import Loading from "../../loading/loading";
import { EventDetailsDialogType } from "../event-details/event-details-dialog";
import DeviceDetailsTable from "./device-details-table";
import EventTypeSelector from "./event-type-selector";
import EventsList from "./events-list";

const VIEW_STATIC_WIDTH = "420px";

type DeviceEventsLocationsViewLeftContentProps = {
  device?: Device;
  isLoading: boolean;
  allEventsCount?: number;
  groupsToShow?: Map<number, DeviceEvent[]>;
  selectedEventTypes?: EventType[];
  isEventsToShow?: boolean;
  minFilterDate: Date;
  selectedStartDate: Nullable<Date>;
  selectedEndDate: Nullable<Date>;
  listRef?: React.RefObject<HTMLDivElement>;
  onSelectEventTypesChange: (eventType: EventType) => void;
  onSelectStartDate: (startDate: Nullable<Date>) => void;
  onSelectEndDate: (startDate: Nullable<Date>) => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  onOpenEventDetailsDialog: (eventId: string, dialogType: EventDetailsDialogType) => void;
};

const DeviceEventsLocationsViewLeftContent: React.FC<DeviceEventsLocationsViewLeftContentProps> = ({
  device,
  isLoading,
  allEventsCount,
  groupsToShow,
  selectedEventTypes,
  isEventsToShow,
  minFilterDate,
  selectedStartDate,
  selectedEndDate,
  listRef,
  onSelectEventTypesChange,
  onSelectStartDate,
  onSelectEndDate,
  onScroll,
  onOpenEventDetailsDialog,
}) => {
  const noEventsFor60days =
    (!allEventsCount || allEventsCount < 0) && !selectedEventTypes?.length && !selectedStartDate && !selectedEndDate;

  const isSelectorsDisabled = isLoading || noEventsFor60days;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: VIEW_STATIC_WIDTH,
        maxWidth: VIEW_STATIC_WIDTH,
        overflow: "hidden",
      }}
    >
      <DeviceDetailsTable device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Typography variant="h6">{translations.deviceEventsLocationsView.texts.eventHistorySubtitle()}</Typography>
        <EventTypeSelector
          handleSelectedEventTypesChange={onSelectEventTypesChange}
          selectedEventTypes={selectedEventTypes}
          isDisabled={isSelectorsDisabled}
        />
      </Box>
      <CustomDateRangePicker
        isDisabled={isSelectorsDisabled}
        testId=""
        minDate={minFilterDate}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={onSelectStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={onSelectEndDate}
      />
      {isLoading ? (
        <Loading height={24} />
      ) : isEventsToShow ? (
        <EventsList
          events={groupsToShow}
          listRef={listRef}
          onScroll={onScroll}
          onOpenEventDetailsDialog={onOpenEventDetailsDialog}
        />
      ) : (
        <Alert severity="info" sx={{ mt: 2 }}>
          {noEventsFor60days
            ? translations.deviceEventsLocationsView.texts.noEventsIn60Days()
            : translations.deviceEventsLocationsView.texts.noEventsInSelectedPeriod()}
        </Alert>
      )}
    </Box>
  );
};

export default DeviceEventsLocationsViewLeftContent;
