/* Copyright */
import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { translations } from "../../generated/translationHelper";

const PasswordRequirements: React.FC = () => {
  return (
    <Grid container spacing={0} sx={{ ml: 1, textAlign: "left" }}>
      <Grid item xs={12} md={6}>
        <li>
          <Typography variant="caption">{translations.common.texts.characterMinimum()}</Typography>
        </li>
        <li>
          <Typography variant="caption">{translations.common.texts.lowercaseMinimum()}</Typography>
        </li>
        <li>
          <Typography variant="caption">{translations.common.texts.uppercaseMinimum()}</Typography>
        </li>
      </Grid>
      <Grid item xs={12} md={6}>
        <li>
          <Typography variant="caption">{translations.common.texts.numberMinimum()}</Typography>
        </li>
        <li>
          <Typography variant="caption">{translations.common.texts.specialCharacterMinimum()}</Typography>
        </li>
        <Typography variant="caption" sx={{ ml: 3 }}>
          {translations.common.texts.specialCharacters()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordRequirements;
