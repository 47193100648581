/* Copyright */
import { Box, SelectChangeEvent } from "@mui/material";
import { Organization, RoleIdentifier } from "@sade/data-access";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { getPath } from "../../utils/ssoPathUtil";
import { ParameterNames, PartnerDetails, Paths } from "../../utils/utils";
import AccessControl from "../access-control/access-control";
import PartnersTable from "./partners-table/partners-table";
import PartnersViewFilterBar from "./partners-view-filter-bar/partners-view-filter-bar";

const PartnersView: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const [searchString, setSearchString] = React.useState<string>("");
  const [selectedVariant, setSelectedVariant] = React.useState<string>("all");
  const [allPartnersList, setAllPartnersList] = React.useState<PartnerDetails[]>([]);
  const [filteredPartnersList, setFilteredPartnersList] = React.useState<PartnerDetails[]>([]);
  const [fetched, setFetched] = React.useState(false);

  const sortPartnersList = async (partnersList: Organization[]): Promise<PartnerDetails[]> => {
    const sortedList = await Promise.all(
      partnersList.map(async (partner) => {
        const [partnerId, realmName, networkId, parameters] = await Promise.all([
          partner.getId(),
          partner.getFullRealmName(currentOrganization),
          partner.getNetworkId(),
          partner.getParameters(),
        ]);

        const deviceParameters = parameters.deviceParameters;

        const variant = deviceParameters?.[ParameterNames.VARIANT]?.toLocaleString();

        return { partnerId, partnerName: partner.getFullName(), realmName, networkId, variant };
      })
    );

    return sortedList.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
  };

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      setFetched(false);
      try {
        const response = await currentOrganization?.getChildOrganizations(true);
        if (response) {
          const sortedPartnerList = await sortPartnersList([currentOrganization!, ...response]);

          setAllPartnersList(sortedPartnerList);
          setFilteredPartnersList(sortedPartnerList);
        }
      } catch (error) {
        console.error("error", error);
        showSnackbar(translations.common.texts.generalDataFetchError(), SeverityType.Error);
      } finally {
        setIsLoading(false);
        setFetched(true);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const filterPartners = React.useCallback(() => {
    if (allPartnersList.length) {
      setIsLoading(true);

      let filteredList: PartnerDetails[] = [];
      if (searchString.trim() !== "") {
        filteredList = allPartnersList.filter((partner) => {
          return (
            partner.partnerName.toUpperCase().includes(searchString.toUpperCase()) ||
            partner.realmName.toUpperCase().includes(searchString.toUpperCase())
          );
        });

        if (selectedVariant !== "" && selectedVariant !== "all") {
          filteredList = filteredList.filter((partner) => partner.variant === selectedVariant);
        }
      } else if (selectedVariant !== "" && selectedVariant !== "all") {
        filteredList = allPartnersList.filter((partner) => partner.variant === selectedVariant);
      } else {
        filteredList = allPartnersList;
      }

      setFilteredPartnersList(filteredList);

      setIsLoading(false);
    }
  }, [allPartnersList, searchString, selectedVariant]);

  React.useEffect(() => {
    filterPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, selectedVariant]);

  const handleSearchCriteriaChange = (searchString: string): void => {
    setSearchString(searchString);
  };

  const handleVariantSelectChange = (event: SelectChangeEvent<string>): void => {
    setSelectedVariant(event.target.value);
  };

  const handleAddNewPartnerClick = (): void => {
    navigate(getPath(Paths.PARTNER_ADD));
  };

  const handleRowClick = (partnerId: string): void => {
    const path = getPath(Paths.PARTNER).replace(":partnerId", partnerId);
    navigate(path);
  };

  return (
    <Box sx={{ padding: "3rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.PowerUser]}>
        <PartnersViewFilterBar
          isLoading={isLoading}
          handleSearchCriteriaChange={handleSearchCriteriaChange}
          handleVariantSelectChange={handleVariantSelectChange}
          handleAddNewPartnerClick={handleAddNewPartnerClick}
          searchString={searchString}
          selectedVariant={selectedVariant}
        />
        <PartnersTable
          isLoading={isLoading}
          fetched={fetched}
          partners={filteredPartnersList}
          handleRowClick={handleRowClick}
        />
      </AccessControl>
    </Box>
  );
};

export default PartnersView;
