/* Copyright */
import { Chip, InputLabel, Table, TableBody, TableCell, TableRow, tableCellClasses } from "@mui/material";
import { Device, DeviceAttributeName } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { resolveDeviceStatusChipColor } from "../../../utils/utils";
import TableDataRow from "../../common/table-data-row";

type DeviceDetailsTableProps = {
  device?: Device;
};

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = ({ device }) => {
  const deviceStatus = device?.getDeviceStatus();

  return (
    <Table
      size="small"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          maxWidth: "370px",
        },
      }}
    >
      <TableBody>
        <TableDataRow label={translations.common.texts.imei()} value={device?.getId() ?? ""} maxLength={20} />
        <TableDataRow
          label={translations.common.texts.iccid()}
          value={device?.getAttribute(DeviceAttributeName.ICCID) ?? ""}
          maxLength={20}
        />
        <TableDataRow
          label={translations.common.texts.callerIdSim()}
          value={device?.getAttribute(DeviceAttributeName.callerIdNumber) ?? ""}
          maxLength={20}
        />
        <TableRow>
          <TableCell>
            <InputLabel sx={{ fontSize: "12px" }}>{translations.common.texts.deviceStatus()}</InputLabel>
          </TableCell>
          <TableCell sx={{ textAlign: "end" }}>
            {deviceStatus && (
              <Chip
                size="small"
                label={deviceStatus}
                sx={{
                  backgroundColor: resolveDeviceStatusChipColor(deviceStatus),
                  color: "white",
                }}
              />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DeviceDetailsTable;
