/* Copyright */

import { CloudSyncOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Device, MailboxMessage } from "@sade/data-access";
import React from "react";
import { SeverityType } from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { getErrorMessage } from "../../../../utils/utils";
import MailboxTable from "./mailbox-table";
import PurgeMailboxConfirmationDialog from "./purge-mailbox-confirmation-dialog";

type MailboxDialogProps = {
  open: boolean;
  device?: Device;
  onClose: () => void;
};

const MailboxDialog: React.FC<MailboxDialogProps> = ({ open, device, onClose }) => {
  const [confirmPurgeDialogOpen, setConfirmPurgeDialogOpen] = React.useState(false);
  const [messages, setMessages] = React.useState<MailboxMessage[]>([]);
  const [loadingMessages, setLoadingMessages] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const { showSnackbar } = useSnackbar();

  const fetchMessages = React.useCallback(async (): Promise<MailboxMessage[]> => {
    if (device) {
      try {
        return await device.listMailboxMessages();
      } catch (error) {
        showSnackbar(
          translations.mailboxDialog.texts.listingMessagesFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.mailboxDialog.texts.listingMessagesFailedError({
            deviceId: device.getId(),
            error: getErrorMessage(error),
          })
        );
      }
    }

    return [];
  }, [device, showSnackbar]);

  /**
   * Fetch mailbox messages when dialog is opened.
   */
  React.useEffect(() => {
    (async (): Promise<void> => {
      if (open) {
        setLoadingMessages(true);
        setMessages(await fetchMessages());
        setLoadingMessages(false);
      }
    })();
  }, [open, fetchMessages]);

  /**
   * Fetch mailbox messages when refreshing.
   */
  React.useEffect(() => {
    (async (): Promise<void> => {
      if (refreshing) {
        setLoadingMessages(true);
        setMessages(await fetchMessages());
        setRefreshing(false);
        setLoadingMessages(false);
      }
    })();
  }, [refreshing, fetchMessages]);

  const handlePurge = async (): Promise<void> => {
    setConfirmPurgeDialogOpen(false);
    setLoadingMessages(true);
    try {
      await device?.purgeMailboxMessages();
      setMessages([]);
      showSnackbar(translations.mailboxDialog.texts.purgeSuccess(), SeverityType.Success);
    } catch (error) {
      showSnackbar(
        translations.mailboxDialog.texts.purgeFailed(),
        SeverityType.Error,
        undefined,
        undefined,
        translations.mailboxDialog.texts.purgeFailedError({
          deviceId: device?.getId() ?? "",
          error: getErrorMessage(error),
        })
      );
    } finally {
      setLoadingMessages(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle variant="h5">
          {translations.mailboxDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
            data-testid={TestIds.DeviceMailboxDialog.CloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 4 }}>
          <MailboxTable messages={messages} isLoading={loadingMessages} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", ml: 2, mr: 2, mb: 2, mt: 4 }}>
          <Box>
            <Button
              variant="contained"
              onClick={(): void => setConfirmPurgeDialogOpen(true)}
              color="error"
              data-testid={TestIds.DeviceMailboxDialog.PurgeButton}
              sx={{ mr: 2 }}
              disabled={loadingMessages || messages.length === 0}
            >
              {translations.mailboxDialog.texts.purgeMailbox()}
            </Button>

            <Button variant="outlined" onClick={onClose} data-testid={TestIds.DeviceMailboxDialog.CloseButton}>
              {translations.common.buttons.close()}
            </Button>
          </Box>
          <Button
            variant="outlined"
            onClick={(): void => setRefreshing(true)}
            data-testid={TestIds.DeviceMailboxDialog.RefreshButton}
            startIcon={refreshing ? <CircularProgress size={16} color={"secondary"} /> : <CloudSyncOutlined />}
            disabled={loadingMessages}
          >
            {translations.mailboxDialog.texts.refresh()}
          </Button>
        </DialogActions>
      </Dialog>

      <PurgeMailboxConfirmationDialog
        open={confirmPurgeDialogOpen}
        deviceId={device?.getId() ?? ""}
        onClose={(): void => setConfirmPurgeDialogOpen(false)}
        onPurge={handlePurge}
      />
    </React.Fragment>
  );
};

export default MailboxDialog;
