/* Copyright */
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import Loading from "../loading/loading";
import { Paths } from "../../utils/utils";
import { RoleIdentifier } from "@sade/data-access";
import { getPath } from "../../utils/ssoPathUtil";

const TemporaryLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isChangingRealm, authenticatedUserRole } = useAuthenticatedUser();

  const redirectUser = React.useCallback(() => {
    switch (authenticatedUserRole?.identifier) {
      case RoleIdentifier.SuperUser:
      case RoleIdentifier.PowerUser:
      case RoleIdentifier.DealerManager:
      case RoleIdentifier.DealerAgent:
      case RoleIdentifier.WarehouseInstaller:
        navigate(getPath(Paths.DEVICES));
        break;
      case RoleIdentifier.Operator:
      case RoleIdentifier.Supervisor:
        navigate(getPath(Paths.MONITORING));
        break;
      case RoleIdentifier.Viewer:
        navigate(getPath(Paths.DEVICE_REPORTS));
    }
  }, [authenticatedUserRole, navigate]);

  React.useEffect(() => {
    if (authenticatedUserRole) {
      redirectUser();
    }
  }, [authenticatedUserRole, redirectUser, state]);

  return <Box sx={{ alignSelf: "center", justifySelf: "center" }}>{isChangingRealm && <Loading />}</Box>;
};

export default TemporaryLandingPage;
