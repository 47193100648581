/* Copyright */
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import { Icon } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import CellularSignal1Bars from "../../assets/icons/cellular-signal-1-bars";
import CellularSignal2Bars from "../../assets/icons/cellular-signal-2-bars";
import CellularSignal3Bars from "../../assets/icons/cellular-signal-3-bars";
import CellularSignal4Bars from "../../assets/icons/cellular-signal-4-bars";

type networkSignalProps = {
  signalStrength: number;
  networkType: string;
  disabled?: boolean;
};

const NetworkSignal: React.FC<networkSignalProps> = ({ signalStrength, networkType, disabled }) => {
  const theme = useTheme();

  const solveNetworkSignalIcon = (): React.ReactNode | undefined => {
    switch (signalStrength) {
      case 1:
        return <CellularSignal1Bars />;
      case 2:
        return <CellularSignal2Bars />;
      case 3:
        return <CellularSignal3Bars />;
      case 4 || 5:
        return <CellularSignal4Bars />;
      default:
        return <SignalCellularConnectedNoInternet0BarIcon sx={{ color: theme.palette.error.main }} />;
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      {networkType}
      <Icon
        sx={{
          ml: 1,
          color: signalStrength?.toString() === "0" ? theme.palette.error.main : "",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {solveNetworkSignalIcon()}
      </Icon>
    </Box>
  );
};

export default NetworkSignal;
