/* Copyright */
import { AppSyncClientFactory, Service } from "../backend";
import { throwGQLError } from "../private-utils/throwGQLError";
import { ParameterBackend } from "./ParameterBackend";
import { ParameterType } from "./ParameterName";
import { ParametersListDocument } from "../../generated/gqlParameters";
export class AWSParameterBackend extends ParameterBackend {
    constructor() {
        super(...arguments);
        this.deviceParameters = [];
        this.systemParameters = [];
    }
    async listDeviceParameters() {
        var _a;
        if (this.deviceParameters.length > 0) {
            return this.deviceParameters;
        }
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.PARAMETERS);
        const response = await client.query(ParametersListDocument, {
            type: ParameterType.device,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.parametersList)) {
            throwGQLError(response, "Failed to list device parameters");
        }
        this.deviceParameters = response.data.parametersList.parameters.map((param) => (Object.assign(Object.assign({}, param), { type: ParameterType[param.type], valueType: param.valueType })));
        return this.deviceParameters;
    }
    async listSystemParameters() {
        var _a;
        if (this.systemParameters.length > 0) {
            return this.systemParameters;
        }
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.PARAMETERS);
        const response = await client.query(ParametersListDocument, {
            type: ParameterType.system,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.parametersList)) {
            throwGQLError(response, "Failed to list system parameters");
        }
        this.systemParameters = response.data.parametersList.parameters.map((param) => (Object.assign(Object.assign({}, param), { valueType: param.valueType, type: ParameterType[param.type] })));
        return this.systemParameters;
    }
}
