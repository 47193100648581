/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { TypedAppSyncClient } from "./TypedAppSyncClient";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { AuthListener } from "../auth/AuthListener";
import { AwsConfiguration } from "../auth/AwsConfiguration";
import { Auth } from "@aws-amplify/auth";
import { AWSAppSyncClient } from "aws-appsync";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import usersFragments from "../../generated/fragments/users.json";
import deviceFragments from "../../generated/fragments/device.json";
import eventsFragments from "../../generated/fragments/events.json";
import parametersFragments from "../../generated/fragments/parameters.json";
import { OidcTokenHolder } from "../auth/OidcTokenHolder";
// using non-zero positive values in order for client caching to work
export var Service;
(function (Service) {
    Service[Service["USERS"] = 1] = "USERS";
    Service[Service["DEVICE"] = 2] = "DEVICE";
    Service[Service["EVENTS"] = 3] = "EVENTS";
    Service[Service["PARAMETERS"] = 4] = "PARAMETERS";
})(Service || (Service = {}));
// using prime numbers to avoid client cache collisions
export var ClientType;
(function (ClientType) {
    ClientType[ClientType["TYPE_COGNITO"] = 3] = "TYPE_COGNITO";
    ClientType[ClientType["TYPE_IAM"] = 5] = "TYPE_IAM";
    ClientType[ClientType["TYPE_OIDC"] = 11] = "TYPE_OIDC";
})(ClientType || (ClientType = {}));
// TODO: merge this with AppSyncClientFactory
export class AppSyncClientProvider {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.clientCache = []; // sparse array
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.clientCache = [];
            }
        };
    }
    getTypedClient(service, type) {
        const innerClient = this.getAppSyncClient(service, type);
        return new TypedAppSyncClient(innerClient);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAppSyncClient(service, type) {
        // use oidc token if token available unless client type explicitly provided
        const clientType = !type && OidcTokenHolder.token ? ClientType.TYPE_OIDC : ClientType.TYPE_COGNITO;
        const cacheSlot = service * clientType;
        if (!this.clientCache[cacheSlot]) {
            this.clientCache[cacheSlot] = this.createClient(service, clientType);
        }
        return this.clientCache[cacheSlot];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createClient(service, type) {
        let authOptions;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cache;
        if (type === ClientType.TYPE_IAM) {
            if (!this.authListener) {
                this.authListener = new AuthListener(this.authEventHandler);
            }
            authOptions = {
                credentials: () => Auth.currentCredentials(),
                type: AUTH_TYPE.AWS_IAM,
            };
        }
        else if (type === ClientType.TYPE_COGNITO) {
            if (!this.authListener) {
                this.authListener = new AuthListener(this.authEventHandler);
            }
            authOptions = {
                // using Identity Token for authentication, otherwise our custom claims cannot be added to the auth token.
                // this has the distinct downside that the token cannot be invalidated from "server" side, only timeout will
                // clean identity tokens. maybe one day AWS will allow custom claims on access tokens :fingers-crossed:
                jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            };
        }
        else if (type === ClientType.TYPE_OIDC && OidcTokenHolder.token) {
            authOptions = {
                jwtToken: OidcTokenHolder.token,
                type: AUTH_TYPE.OPENID_CONNECT,
            };
        }
        else {
            throw new Error("Unknown AppSync authentication requested!");
        }
        const introspectionQueryResultData = this.getFragmentDefinitions(service);
        if (introspectionQueryResultData) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
            cache = new InMemoryCache({ fragmentMatcher });
        }
        const awsConfig = AwsConfiguration.getConfiguration();
        return new AWSAppSyncClient({
            auth: authOptions,
            region: awsConfig.AppSync.Region,
            url: this.getEndpoint(service, awsConfig),
            disableOffline: true,
        }, {
            defaultOptions: {
                query: {
                    fetchPolicy: "network-only",
                },
            },
            cache,
        });
    }
    getEndpoint(service, awsConfig) {
        switch (service) {
            case Service.USERS:
                return awsConfig.AppSync.GqlEndpointUsers;
            case Service.DEVICE:
                return awsConfig.AppSync.GqlEndpointDevice;
            case Service.EVENTS:
                return awsConfig.AppSync.GqlEndpointEvents;
            case Service.PARAMETERS:
                return awsConfig.AppSync.GqlEndpointParameters;
            default:
                throw new Error("Unknown AppSync service requested");
        }
    }
    getFragmentDefinitions(service) {
        switch (service) {
            case Service.USERS:
                return usersFragments;
            case Service.DEVICE:
                return deviceFragments;
            case Service.EVENTS:
                return eventsFragments;
            case Service.PARAMETERS:
                return parametersFragments;
            default:
                return;
        }
    }
}
