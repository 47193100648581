/* Copyright */
import * as React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Maybe, Role } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { UserRealmRole } from "../../../utils/utils";
import AccountDetailsRealmInformationTableRow from "./account-details-realm-information-table-row";
import TestIds from "../../../test-ids/test-ids";

type AccountDetailsRealmInformation = {
  userRealmRoles: Maybe<UserRealmRole[]>;
  allRoles: Maybe<Role[]>;
  isAccountEnabled: boolean;
  isExternalUser: boolean;
  handleOpenAddRealmAccessDialog: () => void;
  handleOpenRevokeRealmAccessDialog: (realmRole: UserRealmRole) => void;
  handleRealmRoleSelectChange: (realmId: string, role: string) => void;
};

const AccountDetailsRealmInformation: React.FC<AccountDetailsRealmInformation> = ({
  userRealmRoles,
  allRoles,
  isAccountEnabled,
  isExternalUser,
  handleOpenAddRealmAccessDialog,
  handleOpenRevokeRealmAccessDialog,
  handleRealmRoleSelectChange,
}) => {
  return (
    <Box>
      <Typography variant="h6">{translations.accountDetailsDialog.texts.realmAccess()}</Typography>
      <TableContainer sx={{ overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.realm()}</TableCell>
              <TableCell>{translations.common.texts.role()}</TableCell>
              <TableCell>{translations.common.texts.actions()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRealmRoles?.map((realmRole, index) => {
              return (
                <AccountDetailsRealmInformationTableRow
                  key={index}
                  userRealmAccessCount={userRealmRoles.length}
                  userRealmRole={realmRole}
                  allRoles={allRoles}
                  isAccountEnabled={isAccountEnabled}
                  handleOpenRevokeRealmAccessDialog={handleOpenRevokeRealmAccessDialog}
                  handleRealmRoleSelectChange={handleRealmRoleSelectChange}
                  realmIndex={index}
                  isExternalUser={isExternalUser}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        sx={{ mt: 2 }}
        disabled={!isAccountEnabled || isExternalUser}
        startIcon={<AddIcon />}
        onClick={handleOpenAddRealmAccessDialog}
        data-testid={TestIds.AccountDetailsDialog.AddRealmAccess}
      >
        {translations.common.texts.addRealmAccess()}
      </Button>
    </Box>
  );
};

export default AccountDetailsRealmInformation;
