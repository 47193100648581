/* Copyright */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  BackendFactory,
  DeviceAttributeName,
  IMEIPrefix,
  Maybe,
  Organization,
  OrganizationParameterHierarchy,
  ParameterValueType,
  SimAction,
  isError,
} from "@sade/data-access";
import * as React from "react";
import theme from "../../../../app/theme";
import { useAuthenticatedUser } from "../../../../context/authenticated-user-context";
import { SeverityType } from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { ExternalDeviceIdentifierParameter, getParameterFromHierarchy } from "../../../../utils/parameterUtils";
import {
  DeviceSettings,
  OrganizationInfo,
  ParameterNames,
  getChildOrganizations,
  getErrorMessage,
} from "../../../../utils/utils";
import Loading from "../../../loading/loading";
import ContentDeviceSettings from "./content-device-settings";
import ContentImeiAndRealm from "./content-imei-and-realm";

type SelectedImei = {
  imei: string;
  fromFile: boolean;
};

type ActivateNewDevicesDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ActivateNewDevicesDialog: React.FC<ActivateNewDevicesDialogProps> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const backend = BackendFactory.getBackend();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [imeiPrefixes, setImeiPrefixes] = React.useState<IMEIPrefix[]>([]);
  const [selectedImeiPrefix, setSelectedImeiPrefix] = React.useState<string>("");
  const [imeiSuffix, setImeiSuffix] = React.useState<string>("");
  const [realms, setRealms] = React.useState<Organization[]>([]);
  const [activationRealms, setActivationRealms] = React.useState<OrganizationInfo[]>([]);
  const [selectedRealm, setSelectedRealm] = React.useState<Organization>();
  const [organizationNameMap, setOrganizationNameMap] = React.useState<OrganizationInfo[]>([]);
  const [parameterHierarchy, setParameterHierarchy] = React.useState<Maybe<OrganizationParameterHierarchy>>();
  const [selectedImeis, setSelectedImeis] = React.useState<SelectedImei[]>([]);
  const [usingCustomDeviceSettings, setUsingCustomDeviceSettings] = React.useState<boolean>(false);
  const [deviceSettings, setDeviceSettings] = React.useState<DeviceSettings>({
    fallDetectionEnabled: false,
    silentModeEnabled: false,
    demoModeEnabled: false,
    tapIndicatorEnabled: false,
    inactiveMonitorEnabled: false,
    inactiveChargerTimeMinutes: 0,
    inactiveMotionTimeMinutes: 0,
    powerHeartBeatInterval: 0,
    playbackVolume: 0,
    audioMasterVolume: 0,
    callInService1: "",
    callInService2: "",
    callInService3: "",
    libris2SoftwareVariant: "",
  });
  const [settingsToUpdate, setSettingsToUpdate] = React.useState<string[]>([]);
  const [isExternalIdError, setIsExternalIdError] = React.useState<boolean>(false);
  // External identifier parameters
  const [externalDeviceIdentifier1, setExternalDeviceIdentifier1] = React.useState<ExternalDeviceIdentifierParameter>();
  const [externalDeviceIdentifier2, setExternalDeviceIdentifier2] = React.useState<ExternalDeviceIdentifierParameter>();
  const [externalDeviceIdentifier3, setExternalDeviceIdentifier3] = React.useState<ExternalDeviceIdentifierParameter>();
  // External identifier values
  const [externalId1, setExternalId1] = React.useState<string>("");
  const [externalId2, setExternalId2] = React.useState<string>("");
  const [externalId3, setExternalId3] = React.useState<string>("");

  const steps = [translations.activateNewDevicesDialog.texts.imeiAndRealm(), translations.common.texts.settings()];

  React.useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const [prefixesResponse, organizations, orgNames] = await Promise.all([
          backend.getDeviceIMEIPrefixes(),
          currentOrganization?.getChildOrganizations(true),
          getChildOrganizations(currentOrganization?.getId() ?? "", true),
        ]);
        setImeiPrefixes(prefixesResponse ?? []);
        setOrganizationNameMap(orgNames);
        setRealms(organizations ?? []);

        const inventoryRealm = organizations?.find((org) => org.getName().toLocaleLowerCase() === "inventory");
        if (!inventoryRealm) {
          setActivationRealms(orgNames);
          return;
        }

        const params = await inventoryRealm.getParameterHierarchy();
        const activationRealms = getParameterFromHierarchy(
          ParameterNames.DEVICE_ACTIVATION_REALMS,
          params,
          "system"
        ) as string[];
        if (!activationRealms) {
          return;
        }

        setActivationRealms(orgNames?.filter((org) => activationRealms.includes(org.fullName)));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (backend) initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backend]);

  React.useEffect(() => {
    if (selectedImeiPrefix && imeiSuffix) {
      const imei = selectedImeiPrefix.concat(imeiSuffix);
      if (imei.length === 15) {
        setSelectedImeis([...selectedImeis, { imei, fromFile: false }]);
      } else {
        const filteredImeis = selectedImeis.filter(
          (selectedImei) => !selectedImei.imei.startsWith(imei) && selectedImei.fromFile
        );
        setSelectedImeis(filteredImeis);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImeiPrefix, imeiSuffix]);

  React.useEffect(() => {
    const getDefaultParameters = async (): Promise<void> => {
      const [parameters, parameterHierarchy] = await Promise.all([
        selectedRealm?.getParameters(),
        selectedRealm?.getParameterHierarchy(),
      ]);

      const deviceParams = parameters?.deviceParameters ?? {};

      const updatedDeviceSettings: DeviceSettings = {
        fallDetectionEnabled: !!deviceParams[ParameterNames.FALL_DETECTION_ENABLED],
        silentModeEnabled: !!deviceParams[ParameterNames.SILENT_MODE_ENABLED],
        demoModeEnabled: !!deviceParams[ParameterNames.DEMO_MODE_ENABLED],
        tapIndicatorEnabled: !!deviceParams[ParameterNames.TAP_INDICATOR_ENABLED],
        inactiveMonitorEnabled: !!deviceParams[ParameterNames.INACTIVE_MONITOR_ENABLED],
        inactiveChargerTimeMinutes: deviceParams[ParameterNames.INACTIVE_CHARGER_MINUTES]
          ? Number(deviceParams[ParameterNames.INACTIVE_CHARGER_MINUTES])
          : 0,
        inactiveMotionTimeMinutes: deviceParams[ParameterNames.INACTIVE_MOTION_MINUTES]
          ? Number(deviceParams[ParameterNames.INACTIVE_MOTION_MINUTES])
          : 0,
        powerHeartBeatInterval: deviceParams[ParameterNames.PERIODIC_INDICATOR_FEEDBACK]
          ? Number(deviceParams[ParameterNames.PERIODIC_INDICATOR_FEEDBACK])
          : 0,
        playbackVolume: deviceParams[ParameterNames.AUDIO_PROMPT_VOLUME]
          ? Number(deviceParams[ParameterNames.AUDIO_PROMPT_VOLUME])
          : 0,
        audioMasterVolume: deviceParams[ParameterNames.AUDIO_CALL_VOLUME]
          ? Number(deviceParams[ParameterNames.AUDIO_CALL_VOLUME])
          : 0,
        callInService1: deviceParams[ParameterNames.CALL_IN_SERVICE_1]
          ? String(deviceParams[ParameterNames.CALL_IN_SERVICE_1])
          : "",
        callInService2: deviceParams[ParameterNames.CALL_IN_SERVICE_2]
          ? String(deviceParams[ParameterNames.CALL_IN_SERVICE_2])
          : "",
        callInService3: deviceParams[ParameterNames.CALL_IN_SERVICE_3]
          ? String(deviceParams[ParameterNames.CALL_IN_SERVICE_3])
          : "",
        libris2SoftwareVariant: deviceParams[ParameterNames.VARIANT]
          ? String(deviceParams[ParameterNames.VARIANT])
          : "",
      };

      setDeviceSettings(updatedDeviceSettings);
      setParameterHierarchy(parameterHierarchy);

      // Set external device identifiers
      if (parameterHierarchy) {
        const extId1 = getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_1,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter;
        const extId2 = getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_2,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter;
        const extId3 = getParameterFromHierarchy(
          ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_3,
          parameterHierarchy,
          "system"
        ) as ExternalDeviceIdentifierParameter;

        setExternalDeviceIdentifier1(extId1);
        setExternalDeviceIdentifier2(extId2);
        setExternalDeviceIdentifier3(extId3);
      }
    };

    if (selectedRealm) {
      getDefaultParameters();
    }
  }, [selectedRealm, usingCustomDeviceSettings]);

  React.useEffect(() => {
    if (!externalDeviceIdentifier1) setExternalId1("");
    if (!externalDeviceIdentifier2) setExternalId2("");
    if (!externalDeviceIdentifier3) setExternalId3("");
  }, [externalDeviceIdentifier1, externalDeviceIdentifier2, externalDeviceIdentifier3]);

  /**
   * Clear out settings to update when not using custom device settings.
   */
  React.useEffect(() => {
    if (!usingCustomDeviceSettings) {
      setSettingsToUpdate([]);
    }
  }, [usingCustomDeviceSettings]);

  const handleNextStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImeiPrefixSelectChange = (event: SelectChangeEvent): void => {
    setSelectedImeiPrefix(event.target.value);
  };

  const handleImeiSuffixChange = (suffix: string): void => {
    setImeiSuffix(suffix);
  };

  const handleRealmSelectChange = (realmId: string): void => {
    const selectedRealm = realms?.find((realm) => realm.getId() === realmId);
    setSelectedRealm(selectedRealm);
  };

  const parseCSV = (csvData: string): string[] => {
    const rows = csvData.trim().split(",");
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }
    return rows;
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event): void => {
        const csvData = event.target?.result as string;
        const parsedData = parseCSV(csvData);

        const updatedImeis = [...selectedImeis];

        parsedData.forEach((imei) => {
          updatedImeis.push({ imei, fromFile: true });
        });

        setSelectedImeis(updatedImeis);
      };
    }
  };

  const handleRemoveFile = (): void => {
    const filteredImeis = selectedImeis.filter((selectedImei) => !selectedImei.fromFile);
    setSelectedImeis(filteredImeis);
  };

  const getUpdatedSettings = (): Record<string, unknown> => {
    return settingsToUpdate.reduce((acc, key) => {
      const typedKey = key as keyof DeviceSettings;

      if (typedKey in deviceSettings) {
        acc[typedKey] = deviceSettings[typedKey];
      }
      return acc;
    }, {} as Record<string, unknown>);
  };

  const handleActivateClick = async (): Promise<void> => {
    setIsLoading(true);
    // First update external identifier values if those are set and we are activating just a single device
    try {
      if (selectedImeis.length === 1 && (externalId1 || externalId2 || externalId3)) {
        const device = await backend.getDevice(selectedImeis[0].imei);
        const attributes = [];
        if (externalId1) {
          attributes.push({ key: DeviceAttributeName.externalId1, value: externalId1 });
        }
        if (externalId2) {
          attributes.push({ key: DeviceAttributeName.externalId2, value: externalId2 });
        }
        if (externalId3) {
          attributes.push({ key: DeviceAttributeName.externalId3, value: externalId3 });
        }
        await device?.updateAttributes(attributes);
      }
    } catch (error) {
      setIsLoading(false);
      if (isError(error) && error.message.match(/failed to get thing/i)) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.common.texts.deviceNotFound({ deviceId: selectedImeis[0].imei })
        );
        return;
      }

      if (isError(error) && error.message.match(/device external identifier is not unique/i)) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.common.texts.identifierNotUniqueInfo()
        );
        return;
      }

      showSnackbar(
        translations.activateNewDevicesDialog.texts.deviceActivationFailed(),
        SeverityType.Error,
        undefined,
        undefined,
        getErrorMessage(error)
      );

      // Stop here
      return;
    }

    // Then activate devices
    try {
      const imeis: string[] = selectedImeis.map((selectedImei) => selectedImei.imei);

      await backend.requestSimManagementAction({
        deviceIds: imeis,
        simAction: SimAction.Activate,
        targetOrganizationInfo: selectedRealm
          ? {
              organizationId: selectedRealm?.getId(),
              realmFullName: selectedRealm?.getFullName(),
            }
          : undefined,
        deviceSpecificConfig: getUpdatedSettings(),
      });

      showSnackbar(
        translations.activateNewDevicesDialog.texts.activatingDevices(),
        SeverityType.Info,
        undefined,
        undefined,
        translations.activateNewDevicesDialog.texts.activatingDevicesInfo()
      );
      onClose();
    } catch (error) {
      showSnackbar(
        translations.activateNewDevicesDialog.texts.deviceActivationFailed(),
        SeverityType.Error,
        undefined,
        undefined,
        getErrorMessage(error)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSettingChanged = (parameterName: ParameterNames, value: ParameterValueType): void => {
    if (!settingsToUpdate?.includes(parameterName)) {
      setSettingsToUpdate((prevArray) => [...prevArray, parameterName]);
    }

    setDeviceSettings({ ...deviceSettings, [parameterName]: value });
  };

  const handleSetExternalIdentifier = (key: "externalId1" | "externalId2" | "externalId3", value: string): void => {
    switch (key) {
      case "externalId1":
        setExternalId1(value);
        break;
      case "externalId2":
        setExternalId2(value);
        break;
      case "externalId3":
        setExternalId3(value);
        break;
    }
  };

  const isContinueButtonDisabled = isLoading || !selectedImeis.length || !selectedRealm || isExternalIdError;

  const isActivateButtonDisabled = isLoading;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        data-testid={TestIds.ActivateNewDevicesDialog.Dialog}
        keepMounted={false}
      >
        <DialogTitle variant="h5">
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {translations.activateNewDevicesDialog.texts.title()}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Stepper activeStep={activeStep} sx={{ mr: 6, width: 300 }}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};

                  if (index < activeStep) {
                    stepProps.completed = true;
                  }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel
                        {...labelProps}
                        StepIconComponent={(props: { icon?: React.ReactNode }): JSX.Element => {
                          return (
                            <StepIcon
                              {...props}
                              icon={
                                index < activeStep ? (
                                  <CheckCircleIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                  props.icon
                                )
                              }
                            />
                          );
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: index < activeStep ? theme.palette.success.main : "inherit" }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <IconButton
                aria-label="close"
                onClick={onClose}
                data-testid={TestIds.ActivateNewDevicesDialog.CloseButton}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 10,
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loading height={24} />
          ) : activeStep === 0 ? (
            <ContentImeiAndRealm
              imeiPrefixes={imeiPrefixes}
              selectedImeiPrefix={selectedImeiPrefix}
              imeiSuffix={imeiSuffix}
              activationRealms={activationRealms}
              externalDeviceIdentifier1={externalDeviceIdentifier1}
              externalDeviceIdentifier2={externalDeviceIdentifier2}
              externalDeviceIdentifier3={externalDeviceIdentifier3}
              externalId1={externalId1}
              externalId2={externalId2}
              externalId3={externalId3}
              selectedImeiCount={selectedImeis.length}
              selectedRealm={selectedRealm}
              onImeiPrefixSelectChange={handleImeiPrefixSelectChange}
              onImeiSuffixChange={handleImeiSuffixChange}
              onRealmSelectChange={handleRealmSelectChange}
              onFileChange={handleFileChange}
              onRemoveFile={handleRemoveFile}
              onSetExternalIdentifier={handleSetExternalIdentifier}
              onExternalIdError={setIsExternalIdError}
            />
          ) : (
            <ContentDeviceSettings
              deviceSettings={deviceSettings}
              deviceOrganization={selectedRealm}
              parameterHierarchy={parameterHierarchy}
              organizationNameMap={organizationNameMap}
              usingCustomDeviceSettings={usingCustomDeviceSettings}
              onSettingChanged={handleSettingChanged}
              setUsingCustomDeviceSettings={setUsingCustomDeviceSettings}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", ml: 2, mb: 2 }}>
          {activeStep === 0 ? (
            <>
              <Button
                variant="contained"
                onClick={handleNextStep}
                data-testid={TestIds.ActivateNewDevicesDialog.ContinueButton}
                disabled={isContinueButtonDisabled}
              >
                {translations.common.texts.continue()}
              </Button>
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
                data-testid={TestIds.ActivateNewDevicesDialog.CancelButton}
              >
                {translations.common.buttons.cancel()}
              </Button>
            </>
          ) : (
            <React.Fragment>
              <Button variant="outlined" onClick={handlePreviousStep} data-testid="">
                {translations.common.buttons.back()}
              </Button>
              <Button
                variant="contained"
                onClick={handleActivateClick}
                data-testid=""
                startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
                disabled={isActivateButtonDisabled}
              >
                {translations.activateNewDevicesDialog.buttons.activate()}
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ActivateNewDevicesDialog;
