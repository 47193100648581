/*
*  Copyright (C) 2021 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { TOptionsBase } from "i18next";
import { t } from "../locales/localisator";

type ValueRecord = Record<string, string | number | boolean>;
export type OptionParams = Omit<TOptionsBase, "defaultValue"> & ValueRecord;

export const translations = {
  "logIn": {
    "texts": {
      "title": (options?: OptionParams): string => t("logIn.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("logIn.texts.subtitle", options),
      "subtitleSso": (options?: OptionParams): string => t("logIn.texts.subtitleSso", options),
      "passwordCannotBeResetCurrently": (options?: OptionParams): string => t("logIn.texts.passwordCannotBeResetCurrently", options),
      "incorrectCredentials": (options?: OptionParams): string => t("logIn.texts.incorrectCredentials", options),
      "emailNotVerified": (options?: OptionParams): string => t("logIn.texts.emailNotVerified", options),
      "userNotFound": (options?: OptionParams): string => t("logIn.texts.userNotFound", options)
    },
    "inputs": {
      "password": (options?: OptionParams): string => t("logIn.inputs.password", options),
      "rememberMe": (options?: OptionParams): string => t("logIn.inputs.rememberMe", options)
    },
    "buttons": {
      "logIn": (options?: OptionParams): string => t("logIn.buttons.logIn", options),
      "logInSso": (options?: OptionParams): string => t("logIn.buttons.logInSso", options)
    },
    "links": {
      "forgotPassword": (options?: OptionParams): string => t("logIn.links.forgotPassword", options)
    },
  },
  "logOut": {
    "texts": {
      "title": (options?: OptionParams): string => t("logOut.texts.title", options),
      "subtitleSso": (options?: OptionParams): string => t("logOut.texts.subtitleSso", options)
    },
  },
  "forgotPassword": {
    "texts": {
      "title": (options?: OptionParams): string => t("forgotPassword.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("forgotPassword.texts.subtitle", options)
    },
    "buttons": {
      "send": (options?: OptionParams): string => t("forgotPassword.buttons.send", options),
      "back": (options?: OptionParams): string => t("forgotPassword.buttons.back", options)
    },
  },
  "forgotPasswordResetPassword": {
    "texts": {
      "title": (options?: OptionParams): string => t("forgotPasswordResetPassword.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("forgotPasswordResetPassword.texts.subtitle", options),
      "codeResent": (options?: OptionParams): string => t("forgotPasswordResetPassword.texts.codeResent", options),
      "invalidCode": (options?: OptionParams): string => t("forgotPasswordResetPassword.texts.invalidCode", options),
      "codeExpired": (options?: OptionParams): string => t("forgotPasswordResetPassword.texts.codeExpired", options)
    },
    "links": {
      "resend": (options?: OptionParams): string => t("forgotPasswordResetPassword.links.resend", options)
    },
    "inputs": {
      "newPassword": (options?: OptionParams): string => t("forgotPasswordResetPassword.inputs.newPassword", options),
      "confirmNewPassword": (options?: OptionParams): string => t("forgotPasswordResetPassword.inputs.confirmNewPassword", options)
    },
  },
  "forgotPasswordSuccess": {
    "texts": {
      "title": (options?: OptionParams): string => t("forgotPasswordSuccess.texts.title", options)
    },
    "buttons": {
      "openNumeraPortal": (options?: OptionParams): string => t("forgotPasswordSuccess.buttons.openNumeraPortal", options)
    },
  },
  "registration": {
    "texts": {
      "title": (options?: OptionParams): string => t("registration.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("registration.texts.subtitle", options)
    },
    "inputs": {
      "confirmNewPassword": (options?: OptionParams): string => t("registration.inputs.confirmNewPassword", options),
      "termsAndConditions1": (options?: OptionParams): string => t("registration.inputs.termsAndConditions1", options),
      "termsAndConditions2": (options?: OptionParams): string => t("registration.inputs.termsAndConditions2", options),
      "termsAndConditions3": (options?: OptionParams): string => t("registration.inputs.termsAndConditions3", options)
    },
  },
  "registrationSuccess": {
    "texts": {
      "title": (options?: OptionParams): string => t("registrationSuccess.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("registrationSuccess.texts.subtitle", options)
    },
  },
  "appbar": {
    "links": {
      "monitoring": (options?: OptionParams): string => t("appbar.links.monitoring", options),
      "accounts": (options?: OptionParams): string => t("appbar.links.accounts", options),
      "reports": (options?: OptionParams): string => t("appbar.links.reports", options),
      "partners": (options?: OptionParams): string => t("appbar.links.partners", options),
      "admin": (options?: OptionParams): string => t("appbar.links.admin", options),
      "support": (options?: OptionParams): string => t("appbar.links.support", options)
    },
  },
  "adminMenu": {
    "texts": {
      "sendDeviceCommands": (options?: OptionParams): string => t("adminMenu.texts.sendDeviceCommands", options),
      "otaUpdates": (options?: OptionParams): string => t("adminMenu.texts.otaUpdates", options),
      "partnerManagement": (options?: OptionParams): string => t("adminMenu.texts.partnerManagement", options),
      "partnerManagementInfo": (options?: OptionParams): string => t("adminMenu.texts.partnerManagementInfo", options),
      "endUserIdentityManagement": (options?: OptionParams): string => t("adminMenu.texts.endUserIdentityManagement", options),
      "endUserIdentityManagementInfo": (options?: OptionParams): string => t("adminMenu.texts.endUserIdentityManagementInfo", options),
      "deviceMigration": (options?: OptionParams): string => t("adminMenu.texts.deviceMigration", options),
      "deviceMigrationInfo": (options?: OptionParams): string => t("adminMenu.texts.deviceMigrationInfo", options),
      "deviceManagement": (options?: OptionParams): string => t("adminMenu.texts.deviceManagement", options),
      "deviceManagementInfo": (options?: OptionParams): string => t("adminMenu.texts.deviceManagementInfo", options),
      "deviceReports": (options?: OptionParams): string => t("adminMenu.texts.deviceReports", options),
      "deviceReportsInfo": (options?: OptionParams): string => t("adminMenu.texts.deviceReportsInfo", options),
      "deviceAsnImporter": (options?: OptionParams): string => t("adminMenu.texts.deviceAsnImporter", options),
      "deviceAsnImporterInfo": (options?: OptionParams): string => t("adminMenu.texts.deviceAsnImporterInfo", options),
      "systemConfigurationTable": (options?: OptionParams): string => t("adminMenu.texts.systemConfigurationTable", options),
      "systemConfigurationTableInfo": (options?: OptionParams): string => t("adminMenu.texts.systemConfigurationTableInfo", options),
      "sendMailboxMessages": (options?: OptionParams): string => t("adminMenu.texts.sendMailboxMessages", options),
      "sendMailboxMessagesInfo": (options?: OptionParams): string => t("adminMenu.texts.sendMailboxMessagesInfo", options),
      "otaUpdatesInfo": (options?: OptionParams): string => t("adminMenu.texts.otaUpdatesInfo", options),
      "otaManagement": (options?: OptionParams): string => t("adminMenu.texts.otaManagement", options),
      "otaManagementInfo": (options?: OptionParams): string => t("adminMenu.texts.otaManagementInfo", options)
    },
  },
  "deviceBulkActions": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceBulkActions.texts.title", options),
      "info": (options?: OptionParams): string => t("deviceBulkActions.texts.info", options),
      "newRealm": (options?: OptionParams): string => t("deviceBulkActions.texts.newRealm", options),
      "select": (options?: OptionParams): string => t("deviceBulkActions.texts.select", options),
      "file": (options?: OptionParams): string => t("deviceBulkActions.texts.file", options),
      "operationSuccessful": (options?: OptionParams): string => t("deviceBulkActions.texts.operationSuccessful", options),
      "operationFailed": (options?: OptionParams): string => t("deviceBulkActions.texts.operationFailed", options),
      "cannotRetireDeviceError": (options?: OptionParams): string => t("deviceBulkActions.texts.cannotRetireDeviceError", options),
      "activateDeactivateFailedHeader": (options?: OptionParams): string => t("deviceBulkActions.texts.activateDeactivateFailedHeader", options),
      "sendWakeUpSMS": (options?: OptionParams): string => t("deviceBulkActions.texts.sendWakeUpSMS", options)
    },
    "actions": {
      "changeRealm": (options?: OptionParams): string => t("deviceBulkActions.actions.changeRealm", options),
      "activate": (options?: OptionParams): string => t("deviceBulkActions.actions.activate", options),
      "deactivate": (options?: OptionParams): string => t("deviceBulkActions.actions.deactivate", options),
      "retire": (options?: OptionParams): string => t("deviceBulkActions.actions.retire", options),
      "resetDeviceKey": (options?: OptionParams): string => t("deviceBulkActions.actions.resetDeviceKey", options),
      "resetSettings": (options?: OptionParams): string => t("deviceBulkActions.actions.resetSettings", options)
    },
  },
  "myProfile": {
    "texts": {
      "myProfile": (options?: OptionParams): string => t("myProfile.texts.myProfile", options),
      "changePassword": (options?: OptionParams): string => t("myProfile.texts.changePassword", options),
      "passwordRequirements": (options?: OptionParams): string => t("myProfile.texts.passwordRequirements", options)
    },
    "inputs": {
      "currentPassword": (options?: OptionParams): string => t("myProfile.inputs.currentPassword", options)
    },
  },
  "unsavedChangesDialog": {
    "texts": {
      "unsavedChanges": (options?: OptionParams): string => t("unsavedChangesDialog.texts.unsavedChanges", options),
      "unsavedChangesInfo": (options?: OptionParams): string => t("unsavedChangesDialog.texts.unsavedChangesInfo", options)
    },
  },
  "accounts": {
    "texts": {
      "legacyUsername": (options?: OptionParams): string => t("accounts.texts.legacyUsername", options),
      "disabled": (options?: OptionParams): string => t("accounts.texts.disabled", options)
    },
    "inputs": {
      "searchBy": (options?: OptionParams): string => t("accounts.inputs.searchBy", options),
      "searchUsers": (options?: OptionParams): string => t("accounts.inputs.searchUsers", options),
      "includeDisabled": (options?: OptionParams): string => t("accounts.inputs.includeDisabled", options),
      "dealerAccounts": (options?: OptionParams): string => t("accounts.inputs.dealerAccounts", options),
      "userAccounts": (options?: OptionParams): string => t("accounts.inputs.userAccounts", options)
    },
    "buttons": {
      "addNewAccount": (options?: OptionParams): string => t("accounts.buttons.addNewAccount", options)
    },
  },
  "addNewAccountDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("addNewAccountDialog.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("addNewAccountDialog.texts.subtitle", options),
      "userRequestAdded": (options?: OptionParams): string => t("addNewAccountDialog.texts.userRequestAdded", options),
      "userAlreadyExists": (options?: OptionParams): string => t("addNewAccountDialog.texts.userAlreadyExists", options)
    },
    "inputs": {
      "emailPlaceholder": (options?: OptionParams): string => t("addNewAccountDialog.inputs.emailPlaceholder", options)
    },
  },
  "accountDetailsDialog": {
    "texts": {
      "dealerAccountDetails": (options?: OptionParams): string => t("accountDetailsDialog.texts.dealerAccountDetails", options),
      "everThereAccountDetails": (options?: OptionParams): string => t("accountDetailsDialog.texts.everThereAccountDetails", options),
      "realmAccess": (options?: OptionParams): string => t("accountDetailsDialog.texts.realmAccess", options),
      "realmAccessAdded": (options?: OptionParams): string => t("accountDetailsDialog.texts.realmAccessAdded", options),
      "realmAccessRevoked": (options?: OptionParams): string => t("accountDetailsDialog.texts.realmAccessRevoked", options),
      "roleChanged": (options?: OptionParams): string => t("accountDetailsDialog.texts.roleChanged", options),
      "team": (options?: OptionParams): string => t("accountDetailsDialog.texts.team", options),
      "deviceImei": (options?: OptionParams): string => t("accountDetailsDialog.texts.deviceImei", options),
      "aroundTheDevice": (options?: OptionParams): string => t("accountDetailsDialog.texts.aroundTheDevice", options),
      "invitationResent": (options?: OptionParams): string => t("accountDetailsDialog.texts.invitationResent", options),
      "accountEnabled": (options?: OptionParams): string => t("accountDetailsDialog.texts.accountEnabled", options),
      "accountDisabled": (options?: OptionParams): string => t("accountDetailsDialog.texts.accountDisabled", options),
      "accountDeleted": (options?: OptionParams): string => t("accountDetailsDialog.texts.accountDeleted", options)
    },
    "buttons": {
      "resendInvitation": (options?: OptionParams): string => t("accountDetailsDialog.buttons.resendInvitation", options)
    },
  },
  "addRealmAccessDialog": {
    "texts": {
      "subtitle": (options?: OptionParams): string => t("addRealmAccessDialog.texts.subtitle", options),
      "noRealmsInfo": (options?: OptionParams): string => t("addRealmAccessDialog.texts.noRealmsInfo", options)
    },
  },
  "revokeRealmAccessDialog": {
    "texts": {
      "subtitle": (options?: OptionParams): string => t("revokeRealmAccessDialog.texts.subtitle", options)
    },
  },
  "enableAccountDialog": {
    "texts": {
      "subtitle": (options?: OptionParams): string => t("enableAccountDialog.texts.subtitle", options),
      "subtitleNoName": (options?: OptionParams): string => t("enableAccountDialog.texts.subtitleNoName", options)
    },
  },
  "disableAccountDialog": {
    "texts": {
      "subtitle": (options?: OptionParams): string => t("disableAccountDialog.texts.subtitle", options)
    },
  },
  "deleteAccountDialog": {
    "texts": {
      "subtitle": (options?: OptionParams): string => t("deleteAccountDialog.texts.subtitle", options),
      "subtitleNoName": (options?: OptionParams): string => t("deleteAccountDialog.texts.subtitleNoName", options)
    },
  },
  "smsVerification": {
    "texts": {
      "title": (options?: OptionParams): string => t("smsVerification.texts.title", options),
      "subtitle1": (options?: OptionParams): string => t("smsVerification.texts.subtitle1", options),
      "subtitle2": (options?: OptionParams): string => t("smsVerification.texts.subtitle2", options),
      "verificationCodeReSent": (options?: OptionParams): string => t("smsVerification.texts.verificationCodeReSent", options),
      "editInfo": (options?: OptionParams): string => t("smsVerification.texts.editInfo", options)
    },
    "buttons": {
      "resendCode": (options?: OptionParams): string => t("smsVerification.buttons.resendCode", options),
      "submit": (options?: OptionParams): string => t("smsVerification.buttons.submit", options)
    },
  },
  "accountRealmSelector": {
    "texts": {
      "title": (options?: OptionParams): string => t("accountRealmSelector.texts.title", options),
      "searchPlaceholder": (options?: OptionParams): string => t("accountRealmSelector.texts.searchPlaceholder", options),
      "realmChanged": (options?: OptionParams): string => t("accountRealmSelector.texts.realmChanged", options)
    },
  },
  "devices": {
    "buttons": {
      "activateNew": (options?: OptionParams): string => t("devices.buttons.activateNew", options),
      "playTune": (options?: OptionParams): string => t("devices.buttons.playTune", options),
      "playTuneTooltip": (options?: OptionParams): string => t("devices.buttons.playTuneTooltip", options),
      "locate": (options?: OptionParams): string => t("devices.buttons.locate", options),
      "locateTooltip": (options?: OptionParams): string => t("devices.buttons.locateTooltip", options),
      "call": (options?: OptionParams): string => t("devices.buttons.call", options),
      "callTooltip": (options?: OptionParams): string => t("devices.buttons.callTooltip", options)
    },
    "inputs": {
      "unmanaged": (options?: OptionParams): string => t("devices.inputs.unmanaged", options),
      "retired": (options?: OptionParams): string => t("devices.inputs.retired", options),
      "activating": (options?: OptionParams): string => t("devices.inputs.activating", options),
      "active": (options?: OptionParams): string => t("devices.inputs.active", options),
      "deactivating": (options?: OptionParams): string => t("devices.inputs.deactivating", options),
      "unsuccesful": (options?: OptionParams): string => t("devices.inputs.unsuccesful", options),
      "imei": (options?: OptionParams): string => t("devices.inputs.imei", options),
      "iccid": (options?: OptionParams): string => t("devices.inputs.iccid", options),
      "callerid": (options?: OptionParams): string => t("devices.inputs.callerid", options),
      "devicename": (options?: OptionParams): string => t("devices.inputs.devicename", options)
    },
  },
  "device": {
    "texts": {
      "deviceActivating": (options?: OptionParams): string => t("device.texts.deviceActivating", options),
      "deviceActivatingMessage": (options?: OptionParams): string => t("device.texts.deviceActivatingMessage", options),
      "deviceDeactivating": (options?: OptionParams): string => t("device.texts.deviceDeactivating", options),
      "deviceDeactivatingMessage": (options?: OptionParams): string => t("device.texts.deviceDeactivatingMessage", options)
    },
  },
  "deviceDetails": {
    "texts": {
      "networkOperatorAndPlan": (options?: OptionParams): string => t("deviceDetails.texts.networkOperatorAndPlan", options),
      "invalidOperatorPlan": (options?: OptionParams): string => t("deviceDetails.texts.invalidOperatorPlan", options),
      "softwareVersion": (options?: OptionParams): string => t("deviceDetails.texts.softwareVersion", options),
      "patientId": (options?: OptionParams): string => t("deviceDetails.texts.patientId", options),
      "deviceDetails": (options?: OptionParams): string => t("deviceDetails.texts.deviceDetails", options),
      "playTuneRequestSent": (options?: OptionParams): string => t("deviceDetails.texts.playTuneRequestSent", options),
      "playTuneRequestFailed": (options?: OptionParams): string => t("deviceDetails.texts.playTuneRequestFailed", options),
      "errorMessage": (options?: OptionParams): string => t("deviceDetails.texts.errorMessage", options),
      "callMeRequestSent": (options?: OptionParams): string => t("deviceDetails.texts.callMeRequestSent", options),
      "callMeRequestFailed": (options?: OptionParams): string => t("deviceDetails.texts.callMeRequestFailed", options),
      "locateMeRequestSent": (options?: OptionParams): string => t("deviceDetails.texts.locateMeRequestSent", options),
      "locateMeRequestFailed": (options?: OptionParams): string => t("deviceDetails.texts.locateMeRequestFailed", options)
    },
  },
  "deviceDetailsDialog": {
    "texts": {
      "changesSaveSuccessHeader": (options?: OptionParams): string => t("deviceDetailsDialog.texts.changesSaveSuccessHeader", options),
      "changesSaveSuccessInfo": (options?: OptionParams): string => t("deviceDetailsDialog.texts.changesSaveSuccessInfo", options),
      "changesSaveFailedHeader": (options?: OptionParams): string => t("deviceDetailsDialog.texts.changesSaveFailedHeader", options),
      "changesSaveFailedInfo": (options?: OptionParams): string => t("deviceDetailsDialog.texts.changesSaveFailedInfo", options)
    },
  },
  "otaMonitorDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("otaMonitorDialog.texts.title", options),
      "dateTime": (options?: OptionParams): string => t("otaMonitorDialog.texts.dateTime", options),
      "retrieving": (options?: OptionParams): string => t("otaMonitorDialog.texts.retrieving", options),
      "noLogs": (options?: OptionParams): string => t("otaMonitorDialog.texts.noLogs", options),
      "failurePopup": (options?: OptionParams): string => t("otaMonitorDialog.texts.failurePopup", options),
      "failureDescription": (options?: OptionParams): string => t("otaMonitorDialog.texts.failureDescription", options)
    },
    "states": {
      "messageSent": (options?: OptionParams): string => t("otaMonitorDialog.states.messageSent", options),
      "messageRead": (options?: OptionParams): string => t("otaMonitorDialog.states.messageRead", options),
      "deviceRebooted": (options?: OptionParams): string => t("otaMonitorDialog.states.deviceRebooted", options)
    },
  },
  "otaUpdateDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("otaUpdateDialog.texts.title", options),
      "info": (options?: OptionParams): string => t("otaUpdateDialog.texts.info", options),
      "successText": (options?: OptionParams): string => t("otaUpdateDialog.texts.successText", options),
      "packageFail": (options?: OptionParams): string => t("otaUpdateDialog.texts.packageFail", options),
      "updateFail": (options?: OptionParams): string => t("otaUpdateDialog.texts.updateFail", options),
      "failureDescription": (options?: OptionParams): string => t("otaUpdateDialog.texts.failureDescription", options)
    },
  },
  "advancedSettingsDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("advancedSettingsDialog.texts.title", options),
      "info": (options?: OptionParams): string => t("advancedSettingsDialog.texts.info", options),
      "parameterLabel": (options?: OptionParams): string => t("advancedSettingsDialog.texts.parameterLabel", options),
      "changesSavedSuccess": (options?: OptionParams): string => t("advancedSettingsDialog.texts.changesSavedSuccess", options),
      "changesSavedFailed": (options?: OptionParams): string => t("advancedSettingsDialog.texts.changesSavedFailed", options),
      "removeConfirmTitle": (options?: OptionParams): string => t("advancedSettingsDialog.texts.removeConfirmTitle", options),
      "removeConfirmInfo": (options?: OptionParams): string => t("advancedSettingsDialog.texts.removeConfirmInfo", options),
      "saveChangesTitle": (options?: OptionParams): string => t("advancedSettingsDialog.texts.saveChangesTitle", options),
      "saveChangesInfo": (options?: OptionParams): string => t("advancedSettingsDialog.texts.saveChangesInfo", options)
    },
  },
  "mailboxDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("mailboxDialog.texts.title", options),
      "topic": (options?: OptionParams): string => t("mailboxDialog.texts.topic", options),
      "status": (options?: OptionParams): string => t("mailboxDialog.texts.status", options),
      "body": (options?: OptionParams): string => t("mailboxDialog.texts.body", options),
      "purgeMailbox": (options?: OptionParams): string => t("mailboxDialog.texts.purgeMailbox", options),
      "refresh": (options?: OptionParams): string => t("mailboxDialog.texts.refresh", options),
      "created": (options?: OptionParams): string => t("mailboxDialog.texts.created", options),
      "read": (options?: OptionParams): string => t("mailboxDialog.texts.read", options),
      "confirmed": (options?: OptionParams): string => t("mailboxDialog.texts.confirmed", options),
      "purgeConfirmTitle": (options?: OptionParams): string => t("mailboxDialog.texts.purgeConfirmTitle", options),
      "purgeConfirmInfo": (options?: OptionParams): string => t("mailboxDialog.texts.purgeConfirmInfo", options),
      "purgeSuccess": (options?: OptionParams): string => t("mailboxDialog.texts.purgeSuccess", options),
      "listingMessagesFailed": (options?: OptionParams): string => t("mailboxDialog.texts.listingMessagesFailed", options),
      "listingMessagesFailedError": (options?: OptionParams): string => t("mailboxDialog.texts.listingMessagesFailedError", options),
      "purgeFailed": (options?: OptionParams): string => t("mailboxDialog.texts.purgeFailed", options),
      "purgeFailedError": (options?: OptionParams): string => t("mailboxDialog.texts.purgeFailedError", options),
      "noMessages": (options?: OptionParams): string => t("mailboxDialog.texts.noMessages", options)
    },
  },
  "deviceActions": {
    "texts": {
      "activateDeviceTitle": (options?: OptionParams): string => t("deviceActions.texts.activateDeviceTitle", options),
      "activateDeviceContent": (options?: OptionParams): string => t("deviceActions.texts.activateDeviceContent", options),
      "keepExistingPatient": (options?: OptionParams): string => t("deviceActions.texts.keepExistingPatient", options),
      "deactivateDeviceTitle": (options?: OptionParams): string => t("deviceActions.texts.deactivateDeviceTitle", options),
      "deactivateDeviceContent": (options?: OptionParams): string => t("deviceActions.texts.deactivateDeviceContent", options),
      "disableDeviceTitle": (options?: OptionParams): string => t("deviceActions.texts.disableDeviceTitle", options),
      "disableDeviceContent": (options?: OptionParams): string => t("deviceActions.texts.disableDeviceContent", options),
      "flushQueueTitle": (options?: OptionParams): string => t("deviceActions.texts.flushQueueTitle", options),
      "flushQueueContent": (options?: OptionParams): string => t("deviceActions.texts.flushQueueContent", options),
      "rebootTitle": (options?: OptionParams): string => t("deviceActions.texts.rebootTitle", options),
      "rebootContent": (options?: OptionParams): string => t("deviceActions.texts.rebootContent", options),
      "sendLogsTitle": (options?: OptionParams): string => t("deviceActions.texts.sendLogsTitle", options),
      "sendLogsContent": (options?: OptionParams): string => t("deviceActions.texts.sendLogsContent", options),
      "resetAudioTitle": (options?: OptionParams): string => t("deviceActions.texts.resetAudioTitle", options),
      "resetAudioContent": (options?: OptionParams): string => t("deviceActions.texts.resetAudioContent", options),
      "resetKeyTitle": (options?: OptionParams): string => t("deviceActions.texts.resetKeyTitle", options),
      "resetKeyContent": (options?: OptionParams): string => t("deviceActions.texts.resetKeyContent", options),
      "resetDeviceTitle": (options?: OptionParams): string => t("deviceActions.texts.resetDeviceTitle", options),
      "resetDeviceContent": (options?: OptionParams): string => t("deviceActions.texts.resetDeviceContent", options)
    },
    "buttons": {
      "activateDeviceButton": (options?: OptionParams): string => t("deviceActions.buttons.activateDeviceButton", options),
      "deactivateDeviceButton": (options?: OptionParams): string => t("deviceActions.buttons.deactivateDeviceButton", options),
      "disableDeviceButton": (options?: OptionParams): string => t("deviceActions.buttons.disableDeviceButton", options),
      "flushQueueButton": (options?: OptionParams): string => t("deviceActions.buttons.flushQueueButton", options),
      "rebootButton": (options?: OptionParams): string => t("deviceActions.buttons.rebootButton", options),
      "sendLogsButton": (options?: OptionParams): string => t("deviceActions.buttons.sendLogsButton", options),
      "resetAudioButton": (options?: OptionParams): string => t("deviceActions.buttons.resetAudioButton", options),
      "resetKeyButton": (options?: OptionParams): string => t("deviceActions.buttons.resetKeyButton", options),
      "resetDeviceButton": (options?: OptionParams): string => t("deviceActions.buttons.resetDeviceButton", options)
    },
  },
  "deviceDetailsMaintenanceMenu": {
    "texts": {
      "tooltip": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.tooltip", options),
      "activateDevice": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.activateDevice", options),
      "reactivateDevice": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.reactivateDevice", options),
      "activateDeviceRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.activateDeviceRequestSent", options),
      "activateDeviceRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed", options),
      "activateDeviceRequestFailedError": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailedError", options),
      "deactivateDevice": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.deactivateDevice", options),
      "deactivateDeviceRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestSent", options),
      "deactivateDeviceRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestFailed", options),
      "deactivateDeviceRequestFailedError": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestFailedError", options),
      "disablePermanently": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.disablePermanently", options),
      "disablePermanentlyRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.disablePermanentlyRequestSent", options),
      "disablePermanentlyRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.disablePermanentlyRequestFailed", options),
      "flushQueue": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.flushQueue", options),
      "flushQueueRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.flushQueueRequestSent", options),
      "flushQueueRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.flushQueueRequestFailed", options),
      "flushQueueRequestFailedError": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.flushQueueRequestFailedError", options),
      "mailbox": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.mailbox", options),
      "reboot": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.reboot", options),
      "rebootRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.rebootRequestSent", options),
      "rebootRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.rebootRequestFailed", options),
      "sendLogs": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.sendLogs", options),
      "sendLogsRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.sendLogsRequestSent", options),
      "sendLogsRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.sendLogsRequestFailed", options),
      "sendLogsRequestFailedError": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.sendLogsRequestFailedError", options),
      "resetAudio": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetAudio", options),
      "resetAudioRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetAudioRequestSent", options),
      "resetAudioRequestSentMessage": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetAudioRequestSentMessage", options),
      "resetAudioRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetAudioRequestFailed", options),
      "resetKey": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetKey", options),
      "resetKeyRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetKeyRequestSent", options),
      "resetKeyRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetKeyRequestFailed", options),
      "advancedDeviceSettings": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.advancedDeviceSettings", options),
      "updateFirmware": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.updateFirmware", options),
      "otaMonitor": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.otaMonitor", options),
      "resetDevice": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetDevice", options),
      "resetDeviceRequestSent": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetDeviceRequestSent", options),
      "resetDeviceRequestSentMessage": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetDeviceRequestSentMessage", options),
      "resetDeviceRequestFailed": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.resetDeviceRequestFailed", options),
      "requestFailedError": (options?: OptionParams): string => t("deviceDetailsMaintenanceMenu.texts.requestFailedError", options)
    },
  },
  "deviceSettings": {
    "texts": {
      "inactivityNotifications": (options?: OptionParams): string => t("deviceSettings.texts.inactivityNotifications", options),
      "inactiveInCharger": (options?: OptionParams): string => t("deviceSettings.texts.inactiveInCharger", options),
      "inactiveOutOfCharger": (options?: OptionParams): string => t("deviceSettings.texts.inactiveOutOfCharger", options),
      "fallDetection": (options?: OptionParams): string => t("deviceSettings.texts.fallDetection", options),
      "audioPromptVolume": (options?: OptionParams): string => t("deviceSettings.texts.audioPromptVolume", options),
      "callVolume": (options?: OptionParams): string => t("deviceSettings.texts.callVolume", options),
      "periodicIndicatorFeedback": (options?: OptionParams): string => t("deviceSettings.texts.periodicIndicatorFeedback", options),
      "tapIndicator": (options?: OptionParams): string => t("deviceSettings.texts.tapIndicator", options),
      "silentMode": (options?: OptionParams): string => t("deviceSettings.texts.silentMode", options),
      "demoMode": (options?: OptionParams): string => t("deviceSettings.texts.demoMode", options),
      "backupCallInNumber1": (options?: OptionParams): string => t("deviceSettings.texts.backupCallInNumber1", options),
      "backupCallInNumber2": (options?: OptionParams): string => t("deviceSettings.texts.backupCallInNumber2", options)
    },
  },
  "deviceSettingsDialog": {
    "texts": {
      "inactivityNotificationsTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.inactivityNotificationsTooltip", options),
      "inactiveInChargerTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.inactiveInChargerTooltip", options),
      "inactiveOutOfChargerTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.inactiveOutOfChargerTooltip", options),
      "fallDetectionTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.fallDetectionTooltip", options),
      "audioPromptVolumeTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.audioPromptVolumeTooltip", options),
      "callVolumeTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.callVolumeTooltip", options),
      "periodicIndicatorFeedbackTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.periodicIndicatorFeedbackTooltip", options),
      "tapIndicatorTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.tapIndicatorTooltip", options),
      "silentModeTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.silentModeTooltip", options),
      "demoModeTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.demoModeTooltip", options),
      "callInServiceTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.callInServiceTooltip", options),
      "backupCallInNumber1Tooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.backupCallInNumber1Tooltip", options),
      "backupCallInNumber2Tooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.backupCallInNumber2Tooltip", options),
      "changesSaveSuccessHeader": (options?: OptionParams): string => t("deviceSettingsDialog.texts.changesSaveSuccessHeader", options),
      "changesSaveSuccessInfo": (options?: OptionParams): string => t("deviceSettingsDialog.texts.changesSaveSuccessInfo", options),
      "stateUpdateFailedHeader": (options?: OptionParams): string => t("deviceSettingsDialog.texts.stateUpdateFailedHeader", options),
      "stateUpdateFailedInfo": (options?: OptionParams): string => t("deviceSettingsDialog.texts.stateUpdateFailedInfo", options),
      "deviceSpecific": (options?: OptionParams): string => t("deviceSettingsDialog.texts.deviceSpecific", options),
      "variantTooltip": (options?: OptionParams): string => t("deviceSettingsDialog.texts.variantTooltip", options),
      "otaTriggerFailedHeader": (options?: OptionParams): string => t("deviceSettingsDialog.texts.otaTriggerFailedHeader", options),
      "otaTriggerFailedInfo": (options?: OptionParams): string => t("deviceSettingsDialog.texts.otaTriggerFailedInfo", options)
    },
  },
  "devicePeripherals": {
    "texts": {
      "devicePeripherals": (options?: OptionParams): string => t("devicePeripherals.texts.devicePeripherals", options),
      "mHealthDevicesTitle": (options?: OptionParams): string => t("devicePeripherals.texts.mHealthDevicesTitle", options),
      "companionPendantTitle": (options?: OptionParams): string => t("devicePeripherals.texts.companionPendantTitle", options),
      "emptyState": (options?: OptionParams): string => t("devicePeripherals.texts.emptyState", options),
      "attachCompanionPendant": (options?: OptionParams): string => t("devicePeripherals.texts.attachCompanionPendant", options),
      "addMHealthDevice": (options?: OptionParams): string => t("devicePeripherals.texts.addMHealthDevice", options),
      "peripheralAddedSuccesfully": (options?: OptionParams): string => t("devicePeripherals.texts.peripheralAddedSuccesfully", options),
      "peripheralAddFailed": (options?: OptionParams): string => t("devicePeripherals.texts.peripheralAddFailed", options),
      "peripheralRemoved": (options?: OptionParams): string => t("devicePeripherals.texts.peripheralRemoved", options),
      "peripheralRemoveFailed": (options?: OptionParams): string => t("devicePeripherals.texts.peripheralRemoveFailed", options),
      "removeConfirmationHeader": (options?: OptionParams): string => t("devicePeripherals.texts.removeConfirmationHeader", options),
      "removeConfirmationContent": (options?: OptionParams): string => t("devicePeripherals.texts.removeConfirmationContent", options)
    },
    "buttons": {
      "attachCompanionPendant": (options?: OptionParams): string => t("devicePeripherals.buttons.attachCompanionPendant", options),
      "addmHealthDevice": (options?: OptionParams): string => t("devicePeripherals.buttons.addmHealthDevice", options),
      "attach": (options?: OptionParams): string => t("devicePeripherals.buttons.attach", options),
      "addDevice": (options?: OptionParams): string => t("devicePeripherals.buttons.addDevice", options)
    },
    "peripheralTypes": {
      "unknown": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.unknown", options),
      "basicPendant": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.basicPendant", options),
      "thermometer": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.thermometer", options),
      "oxymeter": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.oxymeter", options),
      "weightScale": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.weightScale", options),
      "bloodPressureMonitor": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.bloodPressureMonitor", options),
      "glucoseMonitor": (options?: OptionParams): string => t("devicePeripherals.peripheralTypes.glucoseMonitor", options)
    },
  },
  "deviceStatus": {
    "texts": {
      "emptyStateText": (options?: OptionParams): string => t("deviceStatus.texts.emptyStateText", options)
    },
  },
  "deviceEverThere": {
    "texts": {
      "emptyStateText": (options?: OptionParams): string => t("deviceEverThere.texts.emptyStateText", options)
    },
  },
  "deviceEventsLocations": {
    "texts": {
      "emptyStateText": (options?: OptionParams): string => t("deviceEventsLocations.texts.emptyStateText", options)
    },
  },
  "deviceEverThereAccountsDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.title", options),
      "memberMenuItemMakeWearer": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemMakeWearer", options),
      "memberMenuItemRemoveFromDevice": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemRemoveFromDevice", options),
      "memberMenuItemWearerOf": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemWearerOf", options),
      "memberMenuItemMakeManager": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemMakeManager", options),
      "memberMenuItemResend": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemResend", options),
      "memberMenuItemRemoveFromTeam": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.texts.memberMenuItemRemoveFromTeam", options)
    },
    "buttons": {
      "inviteNewEverThereUser": (options?: OptionParams): string => t("deviceEverThereAccountsDialog.buttons.inviteNewEverThereUser", options)
    },
  },
  "deviceEverThereInviteNewUserDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereInviteNewUserDialog.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("deviceEverThereInviteNewUserDialog.texts.subtitle", options)
    },
  },
  "deviceEverThereMakeMemberAWearerDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.title", options),
      "info": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.info", options),
      "successHeader": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.successHeader", options),
      "successInfo": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.successInfo", options),
      "errorHeader": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.errorHeader", options),
      "errorInfo": (options?: OptionParams): string => t("deviceEverThereMakeMemberAWearerDialog.texts.errorInfo", options)
    },
  },
  "deviceEventsLocationsView": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEventsLocationsView.texts.title", options),
      "eventHistorySubtitle": (options?: OptionParams): string => t("deviceEventsLocationsView.texts.eventHistorySubtitle", options),
      "noEventsIn60Days": (options?: OptionParams): string => t("deviceEventsLocationsView.texts.noEventsIn60Days", options),
      "noEventsInSelectedPeriod": (options?: OptionParams): string => t("deviceEventsLocationsView.texts.noEventsInSelectedPeriod", options)
    },
  },
  "events": {
    "texts": {
      "batteryLow": (options?: OptionParams): string => t("events.texts.batteryLow", options),
      "batteryUnknown": (options?: OptionParams): string => t("events.texts.batteryUnknown", options),
      "callButtonPressed": (options?: OptionParams): string => t("events.texts.callButtonPressed", options),
      "callCancelled": (options?: OptionParams): string => t("events.texts.callCancelled", options),
      "chargeChange": (options?: OptionParams): string => t("events.texts.chargeChange", options),
      "chargerOff": (options?: OptionParams): string => t("events.texts.chargerOff", options),
      "chargerOn": (options?: OptionParams): string => t("events.texts.chargerOn", options),
      "classify": (options?: OptionParams): string => t("events.texts.classify", options),
      "classifyFallDetected": (options?: OptionParams): string => t("events.texts.classifyFallDetected", options),
      "debug": (options?: OptionParams): string => t("events.texts.debug", options),
      "demoCall": (options?: OptionParams): string => t("events.texts.demoCall", options),
      "demoFallCall": (options?: OptionParams): string => t("events.texts.demoFallCall", options),
      "demoModeDisabled": (options?: OptionParams): string => t("events.texts.demoModeDisabled", options),
      "demoModeEnabled": (options?: OptionParams): string => t("events.texts.demoModeEnabled", options),
      "demoPendantCall": (options?: OptionParams): string => t("events.texts.demoPendantCall", options),
      "demoSilentCall": (options?: OptionParams): string => t("events.texts.demoSilentCall", options),
      "demoSilentPendantCall": (options?: OptionParams): string => t("events.texts.demoSilentPendantCall", options),
      "deviceActivation": (options?: OptionParams): string => t("events.texts.deviceActivation", options),
      "deviceDeactivated": (options?: OptionParams): string => t("events.texts.deviceDeactivated", options),
      "deviceFailActivation": (options?: OptionParams): string => t("events.texts.deviceFailActivation", options),
      "fallAssistanceCall": (options?: OptionParams): string => t("events.texts.fallAssistanceCall", options),
      "inProgressCall": (options?: OptionParams): string => t("events.texts.inProgressCall", options),
      "latestFixOfAnyType": (options?: OptionParams): string => t("events.texts.latestFixOfAnyType", options),
      "logFile": (options?: OptionParams): string => t("events.texts.logFile", options),
      "longCharge": (options?: OptionParams): string => t("events.texts.longCharge", options),
      "lowActivity": (options?: OptionParams): string => t("events.texts.lowActivity", options),
      "MagCalibration": (options?: OptionParams): string => t("events.texts.MagCalibration", options),
      "medicalDevicePaired": (options?: OptionParams): string => t("events.texts.medicalDevicePaired", options),
      "medicalDevicePairFailure": (options?: OptionParams): string => t("events.texts.medicalDevicePairFailure", options),
      "medicalDeviceReading": (options?: OptionParams): string => t("events.texts.medicalDeviceReading", options),
      "medicalDeviceUnpaired": (options?: OptionParams): string => t("events.texts.medicalDeviceUnpaired", options),
      "mostRecentEventsListed": (options?: OptionParams): string => t("events.texts.mostRecentEventsListed", options),
      "networkChange": (options?: OptionParams): string => t("events.texts.networkChange", options),
      "networkFix": (options?: OptionParams): string => t("events.texts.networkFix", options),
      "noEventParameters": (options?: OptionParams): string => t("events.texts.noEventParameters", options),
      "noLocationDataAvailable": (options?: OptionParams): string => t("events.texts.noLocationDataAvailable", options),
      "notesUpdated": (options?: OptionParams): string => t("events.texts.notesUpdated", options),
      "pendantBatteryLow": (options?: OptionParams): string => t("events.texts.pendantBatteryLow", options),
      "pendantCall": (options?: OptionParams): string => t("events.texts.pendantCall", options),
      "pendantOutOfRange": (options?: OptionParams): string => t("events.texts.pendantOutOfRange", options),
      "pendantPaired": (options?: OptionParams): string => t("events.texts.pendantPaired", options),
      "pendantPairingFailure": (options?: OptionParams): string => t("events.texts.pendantPairingFailure", options),
      "pendantUnPaired": (options?: OptionParams): string => t("events.texts.pendantUnPaired", options),
      "poweredOn": (options?: OptionParams): string => t("events.texts.poweredOn", options),
      "powerOffOnLowBattery": (options?: OptionParams): string => t("events.texts.powerOffOnLowBattery", options),
      "satelliteFix": (options?: OptionParams): string => t("events.texts.satelliteFix", options),
      "silentCall": (options?: OptionParams): string => t("events.texts.silentCall", options),
      "silentModeDisabled": (options?: OptionParams): string => t("events.texts.silentModeDisabled", options),
      "silentModeEnabled": (options?: OptionParams): string => t("events.texts.silentModeEnabled", options),
      "silentPendantCall": (options?: OptionParams): string => t("events.texts.silentPendantCall", options),
      "statusUpdate": (options?: OptionParams): string => t("events.texts.statusUpdate", options),
      "wifiFix": (options?: OptionParams): string => t("events.texts.wifiFix", options)
    },
  },
  "deviceEverThereMakeMemberTeamManagerDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereMakeMemberTeamManagerDialog.texts.title", options),
      "content": (options?: OptionParams): string => t("deviceEverThereMakeMemberTeamManagerDialog.texts.content", options),
      "successHeader": (options?: OptionParams): string => t("deviceEverThereMakeMemberTeamManagerDialog.texts.successHeader", options),
      "successInfo": (options?: OptionParams): string => t("deviceEverThereMakeMemberTeamManagerDialog.texts.successInfo", options),
      "errorHeader": (options?: OptionParams): string => t("deviceEverThereMakeMemberTeamManagerDialog.texts.errorHeader", options)
    },
  },
  "deviceEverThereRemoveTeamMemberDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereRemoveTeamMemberDialog.texts.title", options),
      "content": (options?: OptionParams): string => t("deviceEverThereRemoveTeamMemberDialog.texts.content", options),
      "successPopUp": (options?: OptionParams): string => t("deviceEverThereRemoveTeamMemberDialog.texts.successPopUp", options),
      "failurePopUp": (options?: OptionParams): string => t("deviceEverThereRemoveTeamMemberDialog.texts.failurePopUp", options),
      "failureDescription": (options?: OptionParams): string => t("deviceEverThereRemoveTeamMemberDialog.texts.failureDescription", options)
    },
  },
  "deviceEverThereRemoveDeviceDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.title", options),
      "content": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.content", options),
      "successPopUp": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.successPopUp", options),
      "successDescription": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.successDescription", options),
      "failurePopUp": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.failurePopUp", options),
      "failureDescription": (options?: OptionParams): string => t("deviceEverThereRemoveDeviceDialog.texts.failureDescription", options)
    },
  },
  "activateNewDevicesDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.title", options),
      "info": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.info", options),
      "imeiAndRealm": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.imeiAndRealm", options),
      "imeiPrefix": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.imeiPrefix", options),
      "imeiSuffix": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.imeiSuffix", options),
      "useDefaultDeviceSettingsText": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.useDefaultDeviceSettingsText", options),
      "useCustomizedSettingsText": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.useCustomizedSettingsText", options),
      "activatingDevices": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.activatingDevices", options),
      "activatingDevicesInfo": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.activatingDevicesInfo", options),
      "devicesActivated": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.devicesActivated", options),
      "devicesActivatedInfo": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.devicesActivatedInfo", options),
      "deviceActivationFailed": (options?: OptionParams): string => t("activateNewDevicesDialog.texts.deviceActivationFailed", options)
    },
    "buttons": {
      "activate": (options?: OptionParams): string => t("activateNewDevicesDialog.buttons.activate", options)
    },
  },
  "monitoring": {
    "texts": {
      "eventTime": (options?: OptionParams): string => t("monitoring.texts.eventTime", options),
      "eventType": (options?: OptionParams): string => t("monitoring.texts.eventType", options),
      "answeredBy": (options?: OptionParams): string => t("monitoring.texts.answeredBy", options),
      "duration": (options?: OptionParams): string => t("monitoring.texts.duration", options),
      "incomingCallsTab": (options?: OptionParams): string => t("monitoring.texts.incomingCallsTab", options),
      "callHistoryTab": (options?: OptionParams): string => t("monitoring.texts.callHistoryTab", options),
      "noIncomingCalls": (options?: OptionParams): string => t("monitoring.texts.noIncomingCalls", options)
    },
  },
  "activeCalls": {
    "texts": {
      "activeCall": (options?: OptionParams): string => t("activeCalls.texts.activeCall", options),
      "callEnded": (options?: OptionParams): string => t("activeCalls.texts.callEnded", options),
      "homeAddress": (options?: OptionParams): string => t("activeCalls.texts.homeAddress", options),
      "wasThisActualFall": (options?: OptionParams): string => t("activeCalls.texts.wasThisActualFall", options),
      "wasThisMissedFall": (options?: OptionParams): string => t("activeCalls.texts.wasThisMissedFall", options),
      "wasThisActualFallText": (options?: OptionParams): string => t("activeCalls.texts.wasThisActualFallText", options),
      "wasThisMissedFallText": (options?: OptionParams): string => t("activeCalls.texts.wasThisMissedFallText", options),
      "orientationAndActivity": (options?: OptionParams): string => t("activeCalls.texts.orientationAndActivity", options),
      "locationHistory": (options?: OptionParams): string => t("activeCalls.texts.locationHistory", options),
      "hangUpConfirmationHeader": (options?: OptionParams): string => t("activeCalls.texts.hangUpConfirmationHeader", options),
      "hangUpConfirmationContent": (options?: OptionParams): string => t("activeCalls.texts.hangUpConfirmationContent", options),
      "confirmingText": (options?: OptionParams): string => t("activeCalls.texts.confirmingText", options),
      "denyingText": (options?: OptionParams): string => t("activeCalls.texts.denyingText", options),
      "orientationAndActivityInfoHeader": (options?: OptionParams): string => t("activeCalls.texts.orientationAndActivityInfoHeader", options),
      "orientationAndActivityInfoContent": (options?: OptionParams): string => t("activeCalls.texts.orientationAndActivityInfoContent", options),
      "fallConfirmedText": (options?: OptionParams): string => t("activeCalls.texts.fallConfirmedText", options),
      "fallUnConfirmedText": (options?: OptionParams): string => t("activeCalls.texts.fallUnConfirmedText", options),
      "callConfirmedAsFallText": (options?: OptionParams): string => t("activeCalls.texts.callConfirmedAsFallText", options),
      "callRequestSentText": (options?: OptionParams): string => t("activeCalls.texts.callRequestSentText", options),
      "callRequestSentTextToken": (options?: OptionParams): string => t("activeCalls.texts.callRequestSentTextToken", options),
      "callRequestErrorText": (options?: OptionParams): string => t("activeCalls.texts.callRequestErrorText", options),
      "youEndedCallText": (options?: OptionParams): string => t("activeCalls.texts.youEndedCallText", options),
      "callWasEndedElsewhereText": (options?: OptionParams): string => t("activeCalls.texts.callWasEndedElsewhereText", options),
      "errorAnsweringCall": (options?: OptionParams): string => t("activeCalls.texts.errorAnsweringCall", options)
    },
    "buttons": {
      "hangUp": (options?: OptionParams): string => t("activeCalls.buttons.hangUp", options),
      "callMeBack": (options?: OptionParams): string => t("activeCalls.buttons.callMeBack", options)
    },
  },
  "missedFallReport": {
    "texts": {
      "missedFallReportTitle": (options?: OptionParams): string => t("missedFallReport.texts.missedFallReportTitle", options),
      "missedFallReportSubtitle": (options?: OptionParams): string => t("missedFallReport.texts.missedFallReportSubtitle", options)
    },
    "fallOccur": {
      "title": (options?: OptionParams): string => t("missedFallReport.fallOccur.title", options),
      "now": (options?: OptionParams): string => t("missedFallReport.fallOccur.now", options),
      "tenMinutes": (options?: OptionParams): string => t("missedFallReport.fallOccur.tenMinutes", options),
      "thirtyMinutes": (options?: OptionParams): string => t("missedFallReport.fallOccur.thirtyMinutes", options),
      "oneHour": (options?: OptionParams): string => t("missedFallReport.fallOccur.oneHour", options),
      "twoHour": (options?: OptionParams): string => t("missedFallReport.fallOccur.twoHour", options)
    },
    "wearingTheDevice": {
      "title": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.title", options),
      "onTheNeck": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.onTheNeck", options),
      "onTheBelt": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.onTheBelt", options),
      "inThePocket": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.inThePocket", options),
      "inTheBag": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.inTheBag", options),
      "inHand": (options?: OptionParams): string => t("missedFallReport.wearingTheDevice.inHand", options)
    },
    "wayOfTheFall": {
      "title": (options?: OptionParams): string => t("missedFallReport.wayOfTheFall.title", options)
    },
    "fallPosition": {
      "title": (options?: OptionParams): string => t("missedFallReport.fallPosition.title", options),
      "lyingOnTheirBack": (options?: OptionParams): string => t("missedFallReport.fallPosition.lyingOnTheirBack", options),
      "lyingOnTheirStomach": (options?: OptionParams): string => t("missedFallReport.fallPosition.lyingOnTheirStomach", options),
      "lyingOnTheirSide": (options?: OptionParams): string => t("missedFallReport.fallPosition.lyingOnTheirSide", options),
      "sitting": (options?: OptionParams): string => t("missedFallReport.fallPosition.sitting", options),
      "kneeling": (options?: OptionParams): string => t("missedFallReport.fallPosition.kneeling", options)
    },
    "recovery": {
      "title": (options?: OptionParams): string => t("missedFallReport.recovery.title", options),
      "seatedPosition": (options?: OptionParams): string => t("missedFallReport.recovery.seatedPosition", options),
      "standingPosition": (options?: OptionParams): string => t("missedFallReport.recovery.standingPosition", options),
      "walkedAway": (options?: OptionParams): string => t("missedFallReport.recovery.walkedAway", options),
      "didNotRecover": (options?: OptionParams): string => t("missedFallReport.recovery.didNotRecover", options)
    },
    "causeOfTheFall": {
      "title": (options?: OptionParams): string => t("missedFallReport.causeOfTheFall.title", options)
    },
    "walkingAidInvolvement": {
      "title": (options?: OptionParams): string => t("missedFallReport.walkingAidInvolvement.title", options),
      "unsure": (options?: OptionParams): string => t("missedFallReport.walkingAidInvolvement.unsure", options)
    },
    "fallSurface": {
      "title": (options?: OptionParams): string => t("missedFallReport.fallSurface.title", options),
      "whatTypeOfSurfaceDidTheUserFallOn": (options?: OptionParams): string => t("missedFallReport.fallSurface.whatTypeOfSurfaceDidTheUserFallOn", options),
      "asphaltConcrete": (options?: OptionParams): string => t("missedFallReport.fallSurface.asphaltConcrete", options),
      "hardFloor": (options?: OptionParams): string => t("missedFallReport.fallSurface.hardFloor", options),
      "carpet": (options?: OptionParams): string => t("missedFallReport.fallSurface.carpet", options),
      "stairs": (options?: OptionParams): string => t("missedFallReport.fallSurface.stairs", options),
      "wetSlipperySurface": (options?: OptionParams): string => t("missedFallReport.fallSurface.wetSlipperySurface", options)
    },
    "otherRemarks": {
      "title": (options?: OptionParams): string => t("missedFallReport.otherRemarks.title", options)
    },
  },
  "googleMaps": {
    "texts": {
      "cannotDisplayMap": (options?: OptionParams): string => t("googleMaps.texts.cannotDisplayMap", options)
    },
  },
  "reports": {
    "inputs": {
      "reportType": (options?: OptionParams): string => t("reports.inputs.reportType", options),
      "deviceStatuses": (options?: OptionParams): string => t("reports.inputs.deviceStatuses", options)
    },
    "texts": {
      "reportTypeDeviceStatus": (options?: OptionParams): string => t("reports.texts.reportTypeDeviceStatus", options),
      "downloadSuccessHeader": (options?: OptionParams): string => t("reports.texts.downloadSuccessHeader", options),
      "downloadFailed": (options?: OptionParams): string => t("reports.texts.downloadFailed", options),
      "noDevicesFound": (options?: OptionParams): string => t("reports.texts.noDevicesFound", options),
      "generationFailed": (options?: OptionParams): string => t("reports.texts.generationFailed", options)
    },
    "buttons": {
      "download": (options?: OptionParams): string => t("reports.buttons.download", options)
    },
  },
  "partners": {
    "texts": {
      "searchFieldPlaceholder": (options?: OptionParams): string => t("partners.texts.searchFieldPlaceholder", options),
      "partnerName": (options?: OptionParams): string => t("partners.texts.partnerName", options),
      "realmName": (options?: OptionParams): string => t("partners.texts.realmName", options),
      "networkId": (options?: OptionParams): string => t("partners.texts.networkId", options),
      "deviceTypes": (options?: OptionParams): string => t("partners.texts.deviceTypes", options),
      "libris2Software": (options?: OptionParams): string => t("partners.texts.libris2Software", options),
      "libris3Software": (options?: OptionParams): string => t("partners.texts.libris3Software", options),
      "partnerDetailsAddModeTitle": (options?: OptionParams): string => t("partners.texts.partnerDetailsAddModeTitle", options),
      "softwareVersionMin": (options?: OptionParams): string => t("partners.texts.softwareVersionMin", options),
      "billing": (options?: OptionParams): string => t("partners.texts.billing", options),
      "netsuiteId": (options?: OptionParams): string => t("partners.texts.netsuiteId", options),
      "patientTextUrl": (options?: OptionParams): string => t("partners.texts.patientTextUrl", options),
      "eventPushSettings": (options?: OptionParams): string => t("partners.texts.eventPushSettings", options),
      "eventPushSettingsInfo": (options?: OptionParams): string => t("partners.texts.eventPushSettingsInfo", options),
      "pushUrl1": (options?: OptionParams): string => t("partners.texts.pushUrl1", options),
      "pushUrl2": (options?: OptionParams): string => t("partners.texts.pushUrl2", options),
      "pushUrl3": (options?: OptionParams): string => t("partners.texts.pushUrl3", options),
      "eventPushType": (options?: OptionParams): string => t("partners.texts.eventPushType", options),
      "pushRules": (options?: OptionParams): string => t("partners.texts.pushRules", options),
      "pushRulesPlaceholder": (options?: OptionParams): string => t("partners.texts.pushRulesPlaceholder", options),
      "pushConfiguration": (options?: OptionParams): string => t("partners.texts.pushConfiguration", options),
      "pushConfigurationPlaceholder": (options?: OptionParams): string => t("partners.texts.pushConfigurationPlaceholder", options),
      "federatedIdentity": (options?: OptionParams): string => t("partners.texts.federatedIdentity", options),
      "federatedIdentityInfo": (options?: OptionParams): string => t("partners.texts.federatedIdentityInfo", options),
      "deviceParameters": (options?: OptionParams): string => t("partners.texts.deviceParameters", options),
      "deviceParametersInfo": (options?: OptionParams): string => t("partners.texts.deviceParametersInfo", options),
      "deviceParameter": (options?: OptionParams): string => t("partners.texts.deviceParameter", options),
      "systemParameters": (options?: OptionParams): string => t("partners.texts.systemParameters", options),
      "systemParametersInfo": (options?: OptionParams): string => t("partners.texts.systemParametersInfo", options),
      "systemParameter": (options?: OptionParams): string => t("partners.texts.systemParameter", options),
      "parameterValuePlaceholder": (options?: OptionParams): string => t("partners.texts.parameterValuePlaceholder", options),
      "rtsParameters": (options?: OptionParams): string => t("partners.texts.rtsParameters", options),
      "rtsParameter": (options?: OptionParams): string => t("partners.texts.rtsParameter", options),
      "rtsParametersInfo": (options?: OptionParams): string => t("partners.texts.rtsParametersInfo", options),
      "apiKey": (options?: OptionParams): string => t("partners.texts.apiKey", options),
      "secretKey": (options?: OptionParams): string => t("partners.texts.secretKey", options),
      "appId": (options?: OptionParams): string => t("partners.texts.appId", options),
      "addNewPartner": (options?: OptionParams): string => t("partners.texts.addNewPartner", options),
      "realmInfo": (options?: OptionParams): string => t("partners.texts.realmInfo", options),
      "confirmDeletePartner": (options?: OptionParams): string => t("partners.texts.confirmDeletePartner", options),
      "confirmDeletePartnerInfo": (options?: OptionParams): string => t("partners.texts.confirmDeletePartnerInfo", options),
      "partnerDeletionFailed": (options?: OptionParams): string => t("partners.texts.partnerDeletionFailed", options),
      "partnerDeletionSuccessful": (options?: OptionParams): string => t("partners.texts.partnerDeletionSuccessful", options),
      "parentRealm": (options?: OptionParams): string => t("partners.texts.parentRealm", options),
      "libris2Variant": (options?: OptionParams): string => t("partners.texts.libris2Variant", options),
      "identityProvider": (options?: OptionParams): string => t("partners.texts.identityProvider", options),
      "defaultRole": (options?: OptionParams): string => t("partners.texts.defaultRole", options)
    },
    "buttons": {
      "deletePartner": (options?: OptionParams): string => t("partners.buttons.deletePartner", options),
      "createPartner": (options?: OptionParams): string => t("partners.buttons.createPartner", options),
      "addDeviceType": (options?: OptionParams): string => t("partners.buttons.addDeviceType", options),
      "addPushUrl": (options?: OptionParams): string => t("partners.buttons.addPushUrl", options),
      "addDeviceParameter": (options?: OptionParams): string => t("partners.buttons.addDeviceParameter", options),
      "addSystemParameter": (options?: OptionParams): string => t("partners.buttons.addSystemParameter", options),
      "addRtsParameter": (options?: OptionParams): string => t("partners.buttons.addRtsParameter", options),
      "createApiKey": (options?: OptionParams): string => t("partners.buttons.createApiKey", options),
      "removeApiKey": (options?: OptionParams): string => t("partners.buttons.removeApiKey", options),
      "enableEventPush": (options?: OptionParams): string => t("partners.buttons.enableEventPush", options),
      "disableEventPush": (options?: OptionParams): string => t("partners.buttons.disableEventPush", options)
    },
  },
  "otaPackages": {
    "texts": {
      "release": (options?: OptionParams): string => t("otaPackages.texts.release", options),
      "hash": (options?: OptionParams): string => t("otaPackages.texts.hash", options),
      "filename": (options?: OptionParams): string => t("otaPackages.texts.filename", options),
      "emptyTableText": (options?: OptionParams): string => t("otaPackages.texts.emptyTableText", options)
    },
    "buttons": {
      "addNewOtaPackage": (options?: OptionParams): string => t("otaPackages.buttons.addNewOtaPackage", options),
      "sendBulkOtas": (options?: OptionParams): string => t("otaPackages.buttons.sendBulkOtas", options)
    },
  },
  "createNewOtaPackageDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.subtitle", options),
      "otaCategory": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.otaCategory", options),
      "release": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.release", options),
      "test": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.test", options),
      "uploadThePackage": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.uploadThePackage", options),
      "successText": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.successText", options),
      "failText": (options?: OptionParams): string => t("createNewOtaPackageDialog.texts.failText", options)
    },
    "buttons": {
      "addOtaPackage": (options?: OptionParams): string => t("createNewOtaPackageDialog.buttons.addOtaPackage", options)
    },
  },
  "deleteOtaPackageConfirmationDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("deleteOtaPackageConfirmationDialog.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("deleteOtaPackageConfirmationDialog.texts.subtitle", options),
      "successText": (options?: OptionParams): string => t("deleteOtaPackageConfirmationDialog.texts.successText", options),
      "failText": (options?: OptionParams): string => t("deleteOtaPackageConfirmationDialog.texts.failText", options)
    },
    "buttons": {
      "delete": (options?: OptionParams): string => t("deleteOtaPackageConfirmationDialog.buttons.delete", options)
    },
  },
  "sendBulkOtasDialog": {
    "texts": {
      "title": (options?: OptionParams): string => t("sendBulkOtasDialog.texts.title", options),
      "subtitle": (options?: OptionParams): string => t("sendBulkOtasDialog.texts.subtitle", options),
      "otaPackage": (options?: OptionParams): string => t("sendBulkOtasDialog.texts.otaPackage", options),
      "successText": (options?: OptionParams): string => t("sendBulkOtasDialog.texts.successText", options),
      "failText": (options?: OptionParams): string => t("sendBulkOtasDialog.texts.failText", options)
    },
    "buttons": {
      "sendOta": (options?: OptionParams): string => t("sendBulkOtasDialog.buttons.sendOta", options)
    },
  },
  "common": {
    "texts": {
      "tooManyAttempts": (options?: OptionParams): string => t("common.texts.tooManyAttempts", options),
      "networkError": (options?: OptionParams): string => t("common.texts.networkError", options),
      "unableToPerformAction": (options?: OptionParams): string => t("common.texts.unableToPerformAction", options),
      "identifierNotUniqueInfo": (options?: OptionParams): string => t("common.texts.identifierNotUniqueInfo", options),
      "characterMinimum": (options?: OptionParams): string => t("common.texts.characterMinimum", options),
      "lowercaseMinimum": (options?: OptionParams): string => t("common.texts.lowercaseMinimum", options),
      "uppercaseMinimum": (options?: OptionParams): string => t("common.texts.uppercaseMinimum", options),
      "numberMinimum": (options?: OptionParams): string => t("common.texts.numberMinimum", options),
      "specialCharacterMinimum": (options?: OptionParams): string => t("common.texts.specialCharacterMinimum", options),
      "specialCharacters": (options?: OptionParams): string => t("common.texts.specialCharacters", options),
      "unitedStates": (options?: OptionParams): string => t("common.texts.unitedStates", options),
      "canada": (options?: OptionParams): string => t("common.texts.canada", options),
      "userSessionExpired": (options?: OptionParams): string => t("common.texts.userSessionExpired", options),
      "invalidPhoneNumber": (options?: OptionParams): string => t("common.texts.invalidPhoneNumber", options),
      "realm": (options?: OptionParams): string => t("common.texts.realm", options),
      "english": (options?: OptionParams): string => t("common.texts.english", options),
      "french": (options?: OptionParams): string => t("common.texts.french", options),
      "default": (options?: OptionParams): string => t("common.texts.default", options),
      "currentPasswordIncorrent": (options?: OptionParams): string => t("common.texts.currentPasswordIncorrent", options),
      "passwordDoesNotMeetRequirements": (options?: OptionParams): string => t("common.texts.passwordDoesNotMeetRequirements", options),
      "passwordsDoNotMatch": (options?: OptionParams): string => t("common.texts.passwordsDoNotMatch", options),
      "passwordChanged": (options?: OptionParams): string => t("common.texts.passwordChanged", options),
      "profileInfoUpdated": (options?: OptionParams): string => t("common.texts.profileInfoUpdated", options),
      "firstName": (options?: OptionParams): string => t("common.texts.firstName", options),
      "lastName": (options?: OptionParams): string => t("common.texts.lastName", options),
      "status": (options?: OptionParams): string => t("common.texts.status", options),
      "email": (options?: OptionParams): string => t("common.texts.email", options),
      "role": (options?: OptionParams): string => t("common.texts.role", options),
      "country": (options?: OptionParams): string => t("common.texts.country", options),
      "phone": (options?: OptionParams): string => t("common.texts.phone", options),
      "accessDenied": (options?: OptionParams): string => t("common.texts.accessDenied", options),
      "noDataFound": (options?: OptionParams): string => t("common.texts.noDataFound", options),
      "addRealmAccess": (options?: OptionParams): string => t("common.texts.addRealmAccess", options),
      "actions": (options?: OptionParams): string => t("common.texts.actions", options),
      "revokeAccess": (options?: OptionParams): string => t("common.texts.revokeAccess", options),
      "enableAccount": (options?: OptionParams): string => t("common.texts.enableAccount", options),
      "disableAccount": (options?: OptionParams): string => t("common.texts.disableAccount", options),
      "deleteAccount": (options?: OptionParams): string => t("common.texts.deleteAccount", options),
      "verified": (options?: OptionParams): string => t("common.texts.verified", options),
      "editPhoneNumber": (options?: OptionParams): string => t("common.texts.editPhoneNumber", options),
      "phoneNumber": (options?: OptionParams): string => t("common.texts.phoneNumber", options),
      "phoneNumberUpdated": (options?: OptionParams): string => t("common.texts.phoneNumberUpdated", options),
      "organization": (options?: OptionParams): string => t("common.texts.organization", options),
      "device": (options?: OptionParams): string => t("common.texts.device", options),
      "details": (options?: OptionParams): string => t("common.texts.details", options),
      "settings": (options?: OptionParams): string => t("common.texts.settings", options),
      "everThere": (options?: OptionParams): string => t("common.texts.everThere", options),
      "peripherals": (options?: OptionParams): string => t("common.texts.peripherals", options),
      "eventsLocations": (options?: OptionParams): string => t("common.texts.eventsLocations", options),
      "phoneNumberTaken": (options?: OptionParams): string => t("common.texts.phoneNumberTaken", options),
      "deviceName": (options?: OptionParams): string => t("common.texts.deviceName", options),
      "imei": (options?: OptionParams): string => t("common.texts.imei", options),
      "callerId": (options?: OptionParams): string => t("common.texts.callerId", options),
      "iccid": (options?: OptionParams): string => t("common.texts.iccid", options),
      "callInService": (options?: OptionParams): string => t("common.texts.callInService", options),
      "minutes": (options?: OptionParams): string => t("common.texts.minutes", options),
      "hours": (options?: OptionParams): string => t("common.texts.hours", options),
      "enabled": (options?: OptionParams): string => t("common.texts.enabled", options),
      "disabled": (options?: OptionParams): string => t("common.texts.disabled", options),
      "on": (options?: OptionParams): string => t("common.texts.on", options),
      "off": (options?: OptionParams): string => t("common.texts.off", options),
      "manager": (options?: OptionParams): string => t("common.texts.manager", options),
      "teamManager": (options?: OptionParams): string => t("common.texts.teamManager", options),
      "wearer": (options?: OptionParams): string => t("common.texts.wearer", options),
      "wearerOf": (options?: OptionParams): string => t("common.texts.wearerOf", options),
      "pending": (options?: OptionParams): string => t("common.texts.pending", options),
      "invitationExpired": (options?: OptionParams): string => t("common.texts.invitationExpired", options),
      "accountStatus": (options?: OptionParams): string => t("common.texts.accountStatus", options),
      "user": (options?: OptionParams): string => t("common.texts.user", options),
      "teamMember": (options?: OptionParams): string => t("common.texts.teamMember", options),
      "close": (options?: OptionParams): string => t("common.texts.close", options),
      "update": (options?: OptionParams): string => t("common.texts.update", options),
      "active": (options?: OptionParams): string => t("common.texts.active", options),
      "expired": (options?: OptionParams): string => t("common.texts.expired", options),
      "declined": (options?: OptionParams): string => t("common.texts.declined", options),
      "invitationStatus": (options?: OptionParams): string => t("common.texts.invitationStatus", options),
      "enable": (options?: OptionParams): string => t("common.texts.enable", options),
      "seconds": (options?: OptionParams): string => t("common.texts.seconds", options),
      "second": (options?: OptionParams): string => t("common.texts.second", options),
      "minute": (options?: OptionParams): string => t("common.texts.minute", options),
      "hour": (options?: OptionParams): string => t("common.texts.hour", options),
      "emailFormatNotValid": (options?: OptionParams): string => t("common.texts.emailFormatNotValid", options),
      "patientNotFound": (options?: OptionParams): string => t("common.texts.patientNotFound", options),
      "deviceNotFound": (options?: OptionParams): string => t("common.texts.deviceNotFound", options),
      "invitationSuccessHeader": (options?: OptionParams): string => t("common.texts.invitationSuccessHeader", options),
      "invitationSuccessInfo": (options?: OptionParams): string => t("common.texts.invitationSuccessInfo", options),
      "invitationFailedHeader": (options?: OptionParams): string => t("common.texts.invitationFailedHeader", options),
      "invitationFailedInfo": (options?: OptionParams): string => t("common.texts.invitationFailedInfo", options),
      "start": (options?: OptionParams): string => t("common.texts.start", options),
      "end": (options?: OptionParams): string => t("common.texts.end", options),
      "serialNumber": (options?: OptionParams): string => t("common.texts.serialNumber", options),
      "hardwareVersion": (options?: OptionParams): string => t("common.texts.hardwareVersion", options),
      "callerIdSim": (options?: OptionParams): string => t("common.texts.callerIdSim", options),
      "deviceStatus": (options?: OptionParams): string => t("common.texts.deviceStatus", options),
      "no": (options?: OptionParams): string => t("common.texts.no", options),
      "yes": (options?: OptionParams): string => t("common.texts.yes", options),
      "deviceWearer": (options?: OptionParams): string => t("common.texts.deviceWearer", options),
      "event": (options?: OptionParams): string => t("common.texts.event", options),
      "unknown": (options?: OptionParams): string => t("common.texts.unknown", options),
      "call": (options?: OptionParams): string => t("common.texts.call", options),
      "fall": (options?: OptionParams): string => t("common.texts.fall", options),
      "other": (options?: OptionParams): string => t("common.texts.other", options),
      "otherOrUnsure": (options?: OptionParams): string => t("common.texts.otherOrUnsure", options),
      "deviceType": (options?: OptionParams): string => t("common.texts.deviceType", options),
      "networkSignal": (options?: OptionParams): string => t("common.texts.networkSignal", options),
      "batteryLevel": (options?: OptionParams): string => t("common.texts.batteryLevel", options),
      "pastLocation": (options?: OptionParams): string => t("common.texts.pastLocation", options),
      "minutesAgo": (options?: OptionParams): string => t("common.texts.minutesAgo", options),
      "hoursAgo": (options?: OptionParams): string => t("common.texts.hoursAgo", options),
      "hourAgo": (options?: OptionParams): string => t("common.texts.hourAgo", options),
      "noDataAvailable": (options?: OptionParams): string => t("common.texts.noDataAvailable", options),
      "patientName": (options?: OptionParams): string => t("common.texts.patientName", options),
      "customerId": (options?: OptionParams): string => t("common.texts.customerId", options),
      "locationUpdate": (options?: OptionParams): string => t("common.texts.locationUpdate", options),
      "showLocationUpdates": (options?: OptionParams): string => t("common.texts.showLocationUpdates", options),
      "generalDataFetchError": (options?: OptionParams): string => t("common.texts.generalDataFetchError", options),
      "otaSoftwareVersion": (options?: OptionParams): string => t("common.texts.otaSoftwareVersion", options),
      "continue": (options?: OptionParams): string => t("common.texts.continue", options),
      "dragDropFileInfo": (options?: OptionParams): string => t("common.texts.dragDropFileInfo", options),
      "value": (options?: OptionParams): string => t("common.texts.value", options),
      "devices": (options?: OptionParams): string => t("common.texts.devices", options),
      "variant": (options?: OptionParams): string => t("common.texts.variant", options),
      "generalSaveError": (options?: OptionParams): string => t("common.texts.generalSaveError", options),
      "generalSaveSucceed": (options?: OptionParams): string => t("common.texts.generalSaveSucceed", options),
      "valueMissingError": (options?: OptionParams): string => t("common.texts.valueMissingError", options),
      "existingParameterAlert": (options?: OptionParams): string => t("common.texts.existingParameterAlert", options),
      "invalidUrl": (options?: OptionParams): string => t("common.texts.invalidUrl", options),
      "invalidJsonError": (options?: OptionParams): string => t("common.texts.invalidJsonError", options),
      "englishUs": (options?: OptionParams): string => t("common.texts.englishUs", options),
      "spanish": (options?: OptionParams): string => t("common.texts.spanish", options),
      "all": (options?: OptionParams): string => t("common.texts.all", options),
      "invalidNumberError": (options?: OptionParams): string => t("common.texts.invalidNumberError", options),
      "invalidBooleanError": (options?: OptionParams): string => t("common.texts.invalidBooleanError", options),
      "language": (options?: OptionParams): string => t("common.texts.language", options),
      "eventParameters": (options?: OptionParams): string => t("common.texts.eventParameters", options),
      "parameterName": (options?: OptionParams): string => t("common.texts.parameterName", options),
      "attachments": (options?: OptionParams): string => t("common.texts.attachments", options),
      "name": (options?: OptionParams): string => t("common.texts.name", options),
      "type": (options?: OptionParams): string => t("common.texts.type", options),
      "size": (options?: OptionParams): string => t("common.texts.size", options),
      "date": (options?: OptionParams): string => t("common.texts.date", options),
      "incorrectInputFormatError": (options?: OptionParams): string => t("common.texts.incorrectInputFormatError", options),
      "deviceBulkActions": (options?: OptionParams): string => t("common.texts.deviceBulkActions", options),
      "fileNotCorrectTypeError": (options?: OptionParams): string => t("common.texts.fileNotCorrectTypeError", options),
      "deviceReports": (options?: OptionParams): string => t("common.texts.deviceReports", options),
      "uploadImeisPlaceholder": (options?: OptionParams): string => t("common.texts.uploadImeisPlaceholder", options),
      "deviceSettings": (options?: OptionParams): string => t("common.texts.deviceSettings", options),
      "englishAudio": (options?: OptionParams): string => t("common.texts.englishAudio", options),
      "frenchAudio": (options?: OptionParams): string => t("common.texts.frenchAudio", options),
      "spanishAudio": (options?: OptionParams): string => t("common.texts.spanishAudio", options),
      "chooseFile": (options?: OptionParams): string => t("common.texts.chooseFile", options),
      "nofileChosen": (options?: OptionParams): string => t("common.texts.nofileChosen", options),
      "failedToReadFileError": (options?: OptionParams): string => t("common.texts.failedToReadFileError", options),
      "noFileSelectedError": (options?: OptionParams): string => t("common.texts.noFileSelectedError", options),
      "enterTheImeiCodes": (options?: OptionParams): string => t("common.texts.enterTheImeiCodes", options),
      "uploadImeiCodes": (options?: OptionParams): string => t("common.texts.uploadImeiCodes", options),
      "imeis": (options?: OptionParams): string => t("common.texts.imeis", options),
      "enterImeisPlaceholder": (options?: OptionParams): string => t("common.texts.enterImeisPlaceholder", options),
      "searchPlaceholder": (options?: OptionParams): string => t("common.texts.searchPlaceholder", options),
      "enterImeiCodes": (options?: OptionParams): string => t("common.texts.enterImeiCodes", options),
      "enterImeiCodesInfo": (options?: OptionParams): string => t("common.texts.enterImeiCodesInfo", options),
      "createdOn": (options?: OptionParams): string => t("common.texts.createdOn", options),
      "sku": (options?: OptionParams): string => t("common.texts.sku", options),
      "network": (options?: OptionParams): string => t("common.texts.network", options),
      "authKey": (options?: OptionParams): string => t("common.texts.authKey", options),
      "validity": (options?: OptionParams): string => t("common.texts.validity", options),
      "valid": (options?: OptionParams): string => t("common.texts.valid", options),
      "invalid": (options?: OptionParams): string => t("common.texts.invalid", options),
      "dataCopiedToClipboard": (options?: OptionParams): string => t("common.texts.dataCopiedToClipboard", options)
    },
    "inputs": {
      "firstName": (options?: OptionParams): string => t("common.inputs.firstName", options),
      "lastName": (options?: OptionParams): string => t("common.inputs.lastName", options),
      "newPassword": (options?: OptionParams): string => t("common.inputs.newPassword", options),
      "confirmNewPassword": (options?: OptionParams): string => t("common.inputs.confirmNewPassword", options),
      "country": (options?: OptionParams): string => t("common.inputs.country", options),
      "phone": (options?: OptionParams): string => t("common.inputs.phone", options),
      "email": (options?: OptionParams): string => t("common.inputs.email", options),
      "status": (options?: OptionParams): string => t("common.inputs.status", options),
      "realm": (options?: OptionParams): string => t("common.inputs.realm", options),
      "role": (options?: OptionParams): string => t("common.inputs.role", options),
      "searchBy": (options?: OptionParams): string => t("common.inputs.searchBy", options),
      "search": (options?: OptionParams): string => t("common.inputs.search", options)
    },
    "buttons": {
      "openNumeraPortal": (options?: OptionParams): string => t("common.buttons.openNumeraPortal", options),
      "viewProfile": (options?: OptionParams): string => t("common.buttons.viewProfile", options),
      "logout": (options?: OptionParams): string => t("common.buttons.logout", options),
      "changePassword": (options?: OptionParams): string => t("common.buttons.changePassword", options),
      "saveChanges": (options?: OptionParams): string => t("common.buttons.saveChanges", options),
      "discardChanges": (options?: OptionParams): string => t("common.buttons.discardChanges", options),
      "cancel": (options?: OptionParams): string => t("common.buttons.cancel", options),
      "close": (options?: OptionParams): string => t("common.buttons.close", options),
      "confirm": (options?: OptionParams): string => t("common.buttons.confirm", options),
      "backToList": (options?: OptionParams): string => t("common.buttons.backToList", options),
      "maintenance": (options?: OptionParams): string => t("common.buttons.maintenance", options),
      "edit": (options?: OptionParams): string => t("common.buttons.edit", options),
      "view": (options?: OptionParams): string => t("common.buttons.view", options),
      "notes": (options?: OptionParams): string => t("common.buttons.notes", options),
      "sendInvite": (options?: OptionParams): string => t("common.buttons.sendInvite", options),
      "back": (options?: OptionParams): string => t("common.buttons.back", options),
      "submit": (options?: OptionParams): string => t("common.buttons.submit", options),
      "remove": (options?: OptionParams): string => t("common.buttons.remove", options),
      "ok": (options?: OptionParams): string => t("common.buttons.ok", options),
      "copy": (options?: OptionParams): string => t("common.buttons.copy", options),
      "downloadAsCsv": (options?: OptionParams): string => t("common.buttons.downloadAsCsv", options),
      "enterImeis": (options?: OptionParams): string => t("common.buttons.enterImeis", options),
      "backToCallHistory": (options?: OptionParams): string => t("common.buttons.backToCallHistory", options),
      "reset": (options?: OptionParams): string => t("common.buttons.reset", options)
    },
    "links": {
      "support": (options?: OptionParams): string => t("common.links.support", options),
      "privacy": (options?: OptionParams): string => t("common.links.privacy", options),
      "termsOfUse": (options?: OptionParams): string => t("common.links.termsOfUse", options),
      "whereToBuy": (options?: OptionParams): string => t("common.links.whereToBuy", options)
    },
    "footer": {
      "rightsReserved": (options?: OptionParams): string => t("common.footer.rightsReserved", options)
    },
  },
};
