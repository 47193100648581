/* Copyright */
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import { Device, RoleIdentifier } from "@sade/data-access";
import React from "react";
import { useEverThereContext } from "../../../../context/everThere-context";
import { translations } from "../../../../generated/translationHelper";
import { useAccessControl } from "../../../../hooks/useAccessControl";
import TestIds from "../../../../test-ids/test-ids";
import { enableEditDialog } from "../../../../utils/deviceDetailsUtils";
import CardEmptyState from "../../../common/card-empty-state";
import EverThereAccountsDialog from "../../../dialogs/device/everthere/everthere-accounts-dialog/everthere-accounts-dialog";
import CardContentSkeleton from "../../../loading/card-content-skeleton";
import DeviceViewContentEverThereUser from "./device-view-content-ever-there-user";

type DeviceViewContentEverThereProps = {
  device?: Device;
  isLoadingDetails: boolean;
};

const DeviceViewContentEverThere: React.FC<DeviceViewContentEverThereProps> = ({ device, isLoadingDetails }) => {
  const [isEverThereAccountsDialogOpen, setIsEverThereAccountsDialogOpen] = React.useState<boolean>(false);
  const { isLoadingEverThere, everThereData } = useEverThereContext();

  const members = everThereData?.getMembers() ?? [];

  const handleToggleIsEverThereAccountsDialogOpen = (): void => {
    setIsEverThereAccountsDialogOpen(!isEverThereAccountsDialogOpen);
  };

  const isLoading = isLoadingDetails || isLoadingEverThere;

  return (
    <Box>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.EverThereCard}>
        <CardHeader
          title={translations.common.texts.everThere()}
          action={
            useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager) &&
            enableEditDialog(device?.getDeviceStatus()) && (
              <Button
                variant="outlined"
                startIcon={<ModeEditIcon />}
                disabled={isLoading}
                sx={{ mr: 3 }}
                onClick={handleToggleIsEverThereAccountsDialogOpen}
                data-testid={TestIds.DeviceEverThereCard.DeviceEverThereEdit}
              >
                {translations.common.buttons.edit()}
              </Button>
            )
          }
        ></CardHeader>
        {isLoading || !device ? (
          <CardContentSkeleton />
        ) : (
          <CardContent>
            {members.length ? (
              members.map((member, memberIndex) => (
                <DeviceViewContentEverThereUser
                  disabled={!enableEditDialog(device?.getDeviceStatus())}
                  key={member.userId}
                  deviceId={device?.getId()}
                  member={member}
                  memberIndex={memberIndex}
                />
              ))
            ) : (
              <CardEmptyState emptyStateText={translations.deviceEverThere.texts.emptyStateText()} />
            )}
          </CardContent>
        )}
      </Card>
      <EverThereAccountsDialog
        open={isEverThereAccountsDialogOpen}
        onClose={handleToggleIsEverThereAccountsDialogOpen}
        device={device}
      />
    </Box>
  );
};

export default DeviceViewContentEverThere;
