/* Copyright */

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { Device, PeripheralInfo, PeripheralState, PeripheralType } from "@sade/data-access";
import React from "react";
import {
  SeverityType,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { Peripheral, getPeripheralTypeTranslation } from "../../../../utils/utils";
import DevicePeripheralsCompanionPendantAttachDialog from "./device-peripherals-companion-pendant-attach-dialog";
import PeripheralsTable from "./peripherals-table/peripherals-table";

type DevicePeripheralsCompanionPendantProps = {
  peripherals: PeripheralInfo[];
  device?: Device;
  onRefresh: () => void;
};

const DevicePeripheralsCompanionPendant: React.FC<DevicePeripheralsCompanionPendantProps> = ({
  peripherals,
  device,
  onRefresh,
}) => {
  const [isAttachCompanionPendantDialogOpen, setIsAttachCompanionPendantDialogOpen] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const tableEl = React.useRef<HTMLDivElement>(null);

  const toggleIsAttachCompanionPendantDialogOpen = (): void => {
    setIsAttachCompanionPendantDialogOpen(!isAttachCompanionPendantDialogOpen);
  };

  const includesCompanionPendant = (peripherals: PeripheralInfo[]): boolean => {
    return peripherals.some(
      (peripheral) => peripheral.type === PeripheralType.BasicPendant && peripheral.state !== PeripheralState.Unpairing
    );
  };

  const handleRemovePeripheral = async (peripheral: Peripheral): Promise<void> => {
    if (!peripheral) return;

    try {
      await device?.removePeripheral(PeripheralType.BasicPendant, peripheral.serialNumber);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralRemoved({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } catch (e) {
      console.error("error", e);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralRemoveFailed({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    }
    onRefresh();
  };

  const handleAttachPeripheral = async (peripheral?: Peripheral): Promise<void> => {
    if (!peripheral) {
      toggleIsAttachCompanionPendantDialogOpen();
      return;
    }

    try {
      await device?.addPeripheral(PeripheralType.BasicPendant, peripheral.serialNumber);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralAddedSuccesfully({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } catch (e) {
      console.error("error", e);
      showSnackbar(
        translations.devicePeripherals.texts.peripheralAddFailed({
          peripheralType: getPeripheralTypeTranslation(peripheral.type),
        }),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } finally {
      onRefresh();
      toggleIsAttachCompanionPendantDialogOpen();
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {translations.devicePeripherals.texts.companionPendantTitle()}
        </Typography>
        {includesCompanionPendant(peripherals) && (
          <PeripheralsTable
            peripherals={peripherals.filter((peripheral) => peripheral.type === PeripheralType.BasicPendant)}
            tableRef={tableEl}
            isLoading={false}
            removePeripheral={handleRemovePeripheral}
          />
        )}
        {!includesCompanionPendant(peripherals) && (
          <Button
            variant="outlined"
            onClick={toggleIsAttachCompanionPendantDialogOpen}
            startIcon={<AddIcon />}
            data-testid={TestIds.DevicePeripheralsDialog.AttachNewCompanionPendantButton}
            sx={{ mt: 2 }}
          >
            {translations.devicePeripherals.buttons.attachCompanionPendant()}
          </Button>
        )}
      </Box>

      <DevicePeripheralsCompanionPendantAttachDialog
        open={isAttachCompanionPendantDialogOpen}
        handleClose={handleAttachPeripheral}
      />
    </React.Fragment>
  );
};

export default DevicePeripheralsCompanionPendant;
