/* Copyright */
import { callEventTypeList, eventIdentitiesMatch, invertedEventOrdering } from "./Event";
import { EventsEndedCallsListDocument, EventsOngoingCallsListDocument } from "../../generated/gqlEvents";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { AWSEvent } from "./AWSEvent";
import { CallEventSet } from "./CallEventSet";
import { throwGQLError } from "../private-utils/throwGQLError";
import { CallEventsSubscriptionManager } from "./CallEventSubscriptionManager";
export class AWSCallEventSet extends CallEventSet {
    constructor(organization) {
        super();
        this.eventListener = {
            getIds: async () => [...(await this.getIds())],
            onEventUpdate: (event) => this.handleEventUpdate(event),
        };
        this.organization = organization;
        this.endedEvents = [];
        this.ongoingEvents = [];
    }
    async getIds() {
        return [this.organization.getId(), ...(await this.organization.getChildOrganizations(true)).map((o) => o.getId())];
    }
    getEndedCallEvents() {
        return [...this.endedEvents];
    }
    getOngoingCallEvents() {
        return [...this.ongoingEvents];
    }
    async fetchEndedCallEvents(nextToken) {
        var _a, _b, _c, _d, _e;
        if (!nextToken) {
            // Force reset the ended events list and get new events from the beginning.
            this.endedEvents = [];
        }
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.query(EventsEndedCallsListDocument, {
            nextToken,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsEndedCallsList)) {
            throwGQLError(response, "Failed to fetch ended call events");
        }
        const newEvents = (_d = (_c = (_b = response.data.eventsEndedCallsList) === null || _b === void 0 ? void 0 : _b.events) === null || _c === void 0 ? void 0 : _c.map((i) => new AWSEvent(i))) !== null && _d !== void 0 ? _d : [];
        this.endedEvents = this.endedEvents.concat(newEvents).sort(invertedEventOrdering);
        return (_e = response.data.eventsEndedCallsList) === null || _e === void 0 ? void 0 : _e.nextToken;
    }
    async fetchOngoingCallEvents() {
        var _a, _b, _c, _d;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.query(EventsOngoingCallsListDocument, {});
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsOngoingCallsList)) {
            throwGQLError(response, "Failed to fetch ongoing call events");
        }
        this.ongoingEvents = (_d = (_c = (_b = response.data.eventsOngoingCallsList) === null || _b === void 0 ? void 0 : _b.events) === null || _c === void 0 ? void 0 : _c.map((i) => new AWSEvent(i))) !== null && _d !== void 0 ? _d : [];
    }
    /**
     * Add observer for call event GraphQL notifications
     *
     * @param observer Observer to add
     */
    async addObserver(observer) {
        super.addObserver(observer);
        const ids = await this.getIds();
        CallEventsSubscriptionManager.instance.addListener(this.eventListener, ids);
    }
    /**
     * Remove observer for call event GraphQL notifications
     * @param observer Observer to remove
     */
    removeObserver(observer) {
        super.removeObserver(observer);
        CallEventsSubscriptionManager.instance.removeListener(this.eventListener);
    }
    /**
     * Handle event update from GraphQL subscription.
     * @param event Updated event
     */
    handleEventUpdate(event) {
        if (!callEventTypeList().includes(event.type)) {
            // Ignore non-call events.
            return;
        }
        const matchOngoingEventsIndex = this.ongoingEvents.findIndex((e) => eventIdentitiesMatch(event, e));
        if (event.callEndedAt) {
            this.handleEndedCallEvent(event, matchOngoingEventsIndex);
        }
        else {
            if (matchOngoingEventsIndex >= 0) {
                this.ongoingEvents[matchOngoingEventsIndex] = event;
            }
            else {
                this.ongoingEvents.push(event);
            }
        }
        this.notifyAction((observer) => observer.onCallEventUpdate(this));
    }
    handleEndedCallEvent(event, matchOngoingEventsIndex) {
        const matchEndedEventsIndex = this.endedEvents.findIndex((e) => eventIdentitiesMatch(event, e));
        if (matchEndedEventsIndex >= 0) {
            this.endedEvents[matchEndedEventsIndex] = event;
        }
        else {
            this.endedEvents.push(event);
            this.endedEvents.sort(invertedEventOrdering);
        }
        if (matchOngoingEventsIndex >= 0) {
            // Remove event from the ongoing events list.
            this.ongoingEvents.splice(matchOngoingEventsIndex, 1);
        }
    }
}
