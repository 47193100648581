/* Copyright */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import CustomTextField from "../../../common/textfield";
import { Peripheral, getPeripheralTypeTranslation } from "../../../../utils/utils";
import { PeripheralType } from "@sade/data-access";

type DevicePeripheralsMHealthDeviceAddDialogProps = {
  open: boolean;
  handleClose: (peripheral?: Peripheral) => void;
};

const DevicePeripheralsMHealthDeviceAddDialog: React.FC<DevicePeripheralsMHealthDeviceAddDialogProps> = ({
  open,
  handleClose,
}) => {
  const [serialError, setSerialError] = React.useState<string>("");
  const [serialnumber, setSerialnumber] = React.useState<string>("");
  const [isBusy, setIsBusy] = React.useState<boolean>(false);
  const [peripheralType, setPeripheralType] = React.useState<PeripheralType>(PeripheralType.Thermometer);

  const resetSettings = (): void => {
    setSerialError("");
    setSerialnumber("");
    setIsBusy(false);
    setPeripheralType(PeripheralType.Thermometer);
  };

  React.useEffect(() => {
    if (open) {
      resetSettings();
    }
  }, [open]);

  const handleCloseClick = (): void => {
    handleClose();
  };

  const handleSerialChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSerialError("");
    setSerialnumber(event.target.value);
  };

  const handleAttachClick = async (): Promise<void> => {
    setIsBusy(true);
    handleClose({ serialNumber: serialnumber, type: peripheralType });
  };

  const handleSelectChange = (event: SelectChangeEvent): void => {
    setPeripheralType(event.target.value as PeripheralType);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DevicePeripheralsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.devicePeripherals.texts.addMHealthDevice()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
            <FormControl className="input" sx={{ textAlign: "start", mt: 3, mr: 1 }} fullWidth>
              <CustomTextField
                label={translations.common.texts.serialNumber()}
                id="mhealth-device-serialnumber"
                errorMessage={serialError}
                testId={TestIds.AddNewAccountDialog.EmailInput}
                onChange={handleSerialChange}
                placeholder={translations.common.texts.serialNumber()}
                value={serialnumber}
              />
            </FormControl>

            <FormControl className="input" sx={{ textAlign: "start", mt: 3 }} fullWidth>
              <InputLabel id="peripheralTypeSelect-label" shrink>
                {translations.common.texts.deviceType()}
              </InputLabel>
              <Select
                name="peripheralTypeSelect"
                size="small"
                labelId="peripheralTypeSelect-label"
                value={peripheralType}
                onChange={handleSelectChange}
                data-testid={TestIds.DevicePeripheralsDialog.PeripheralTypeSelect}
              >
                {Object.values(PeripheralType).map((value) => {
                  if (value === PeripheralType.BasicPendant) return;
                  return (
                    <MenuItem key={value} value={value}>
                      {getPeripheralTypeTranslation(value)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="outlined"
            disabled={isBusy}
            onClick={handleAttachClick}
            data-testid={TestIds.DevicePeripheralsDialog.AttachButton}
          >
            {translations.devicePeripherals.buttons.attach()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CancelButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DevicePeripheralsMHealthDeviceAddDialog;
