/* Copyright */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { Box, Button, CircularProgress, Skeleton, Typography } from "@mui/material";
import { Event, Maybe, OidcTokenHolder } from "@sade/data-access";
import React from "react";
import { translations } from "../../generated/translationHelper";
import TestIds from "../../test-ids/test-ids";
import { calculateDuration } from "../../utils/utils";
import Timer from "../common/timer";

type ActiveCallsViewHeaderProps = {
  event: Maybe<Event>;
  isLoading: boolean;
  isHangingUp: boolean;
  isRedirecting: boolean;
  confirmHangUpDialogOpen: boolean;
  isActiveCall: boolean;
  isCallRequestSent: boolean;
  handleGoBackClick: () => void;
  handleHangUpClick: () => void;
  handleCallMeBackClick: () => void;
};

const ActiveCallsViewHeader: React.FC<ActiveCallsViewHeaderProps> = ({
  event,
  isLoading,
  isHangingUp,
  isRedirecting,
  isActiveCall,
  isCallRequestSent,
  handleGoBackClick,
  handleHangUpClick,
  handleCallMeBackClick,
}) => {
  const callDuration =
    !isActiveCall && event?.callAnsweredAt && event?.callEndedAt
      ? calculateDuration(event?.callAnsweredAt, event?.callEndedAt)
      : "00:00:00";

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box sx={{ flexDirection: "column" }}>
          {!OidcTokenHolder.hasToken() && (
            <Button
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={handleGoBackClick}
              sx={{ alignSelf: "flex-start" }}
              data-testid={TestIds.ActiveCalls.BackButton}
            >
              {translations.common.buttons.back()}
            </Button>
          )}
          {isLoading ? (
            <Skeleton width={300} height={60} />
          ) : (
            <Typography variant="h5" sx={{ mr: 2, display: "flex", flexDirection: "row" }}>
              {isActiveCall ? (
                <>
                  {translations.activeCalls.texts.activeCall()}
                  <Timer startTime={event?.callAnsweredAt?.getTime()} />
                </>
              ) : (
                translations.activeCalls.texts.callEnded() + " " + callDuration
              )}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          {isLoading ? (
            <Skeleton width={170} height={60} />
          ) : isActiveCall ? (
            <Button
              variant="contained"
              startIcon={isHangingUp ? <CircularProgress size={16} color="secondary" /> : <CallEndIcon />}
              disabled={isHangingUp}
              onClick={handleHangUpClick}
              color="error"
              size="large"
              data-testid={TestIds.ActiveCalls.HangUpButton}
            >
              {translations.activeCalls.buttons.hangUp()}
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={isRedirecting ? <CircularProgress size={16} color="secondary" /> : <PhoneCallbackIcon />}
              onClick={handleCallMeBackClick}
              disabled={isRedirecting || isCallRequestSent}
              color="success"
              size="large"
              data-testid={TestIds.ActiveCalls.CallMeBackButton}
            >
              {translations.activeCalls.buttons.callMeBack()}
            </Button>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ActiveCallsViewHeader;
