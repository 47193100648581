/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  BackendFactory,
  Device,
  DeviceAction,
  DeviceAttributeName,
  DeviceStatus,
  Maybe,
  SimAction,
} from "@sade/data-access";
import * as React from "react";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { DeviceBulkActions, OrganizationInfo, getChildOrganizations, getErrorMessage } from "../../../utils/utils";
import FileUpload from "../../common/file-upload";
import CustomTextField from "../../common/textfield";

type DeviceBulkActionsDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const DeviceBulkActionsDialog: React.FC<DeviceBulkActionsDialogProps> = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const backend = BackendFactory.getBackend();
  const [selectedAction, setSelectedAction] = React.useState<Maybe<DeviceBulkActions>>(undefined);
  const [childOrganizations, setChildOrganizations] = React.useState<OrganizationInfo[]>([]);
  const [selectedNewOrganization, setSelectedNewOrganization] = React.useState<string>("");
  const [selectedImeiInputMethod, setSelectedImeiInputMethod] = React.useState<Maybe<number>>();
  const [selectedImeis, setSelectedImeis] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<Maybe<File>>(undefined);

  React.useEffect(() => {
    const getOrganizations = async (): Promise<void> => {
      const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
      setChildOrganizations(childOrganizationList);
    };
    getOrganizations();
  }, [currentOrganization]);

  const handleActionSelectChange = (event: SelectChangeEvent<typeof selectedAction>): void => {
    setSelectedAction(event.target.value as DeviceBulkActions);
    setSelectedNewOrganization("");
  };

  const handleNewOrganizationSelect = (event: SelectChangeEvent<typeof selectedNewOrganization>): void => {
    setSelectedNewOrganization(event.target.value);
  };

  const handleImeiInputMethodChange = (event: SelectChangeEvent): void => {
    setSelectedImeiInputMethod(Number(event.target.value));
    setSelectedImeis([]);
  };

  const handleImeiInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const textValue = event.target.value;
    const imeiArray = textValue
      .split("\n")
      .map((imei) => imei.trim())
      .filter(Boolean);
    setSelectedImeis(imeiArray);
  };

  const parseCSV = (csvData: string): string[] => {
    const rows = csvData.trim().split(",");
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }
    return rows;
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event): void => {
        const csvData = event.target?.result as string;
        const parsedData = parseCSV(csvData);
        setSelectedImeis(parsedData);
      };
    }

    setSelectedFile(file);
  };

  const performAction = async (device: Device): Promise<void> => {
    switch (selectedAction) {
      case DeviceBulkActions.CHANGE_REALM:
        {
          await device.setOrganization(selectedNewOrganization);
        }
        break;
      case DeviceBulkActions.RETIRE:
        {
          const statusAttribute = [{ key: DeviceAttributeName.status, value: DeviceStatus.retired }];
          if (device.getDeviceStatus() === DeviceStatus.unmanaged) {
            await device.updateAttributes(statusAttribute);
          } else {
            const imei = device.getId();
            throw new Error(translations.deviceBulkActions.texts.cannotRetireDeviceError({ imei }));
          }
        }
        break;
      case DeviceBulkActions.RESET_DEVICE_KEY:
        {
          await device.requestAction(DeviceAction.ResetKey);
        }
        break;
      case DeviceBulkActions.RESET_SETTINGS: {
        throw new Error("Backend method for resetting settings not implemented yet.");
      }
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (selectedAction === DeviceBulkActions.ACTIVATE) {
        await backend.requestSimManagementAction({
          deviceIds: selectedImeis,
          simAction: SimAction.Activate,
        });
      } else if (selectedAction === DeviceBulkActions.DEACTIVATE) {
        await backend.requestSimManagementAction({
          deviceIds: selectedImeis,
          simAction: SimAction.Deactivate,
        });
      } else {
        await Promise.all(
          selectedImeis.map((imei) => backend.getDevice(imei).then((device) => performAction(device!)))
        );
      }

      handleClose();
      showSnackbar(
        translations.deviceBulkActions.texts.operationSuccessful({
          operationName: selectedAction ? selectedAction : "",
        }),
        SeverityType.Success
      );
    } catch (error) {
      showSnackbar(
        "",
        SeverityType.Error,
        undefined,
        undefined,
        translations.deviceBulkActions.texts.operationFailed({
          operationName: selectedAction ? selectedAction : "",
          error: getErrorMessage(error),
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled =
    isLoading ||
    !selectedAction ||
    (selectedAction === DeviceBulkActions.CHANGE_REALM && !selectedNewOrganization) ||
    (selectedImeiInputMethod === 1 && !selectedImeis.length) ||
    (selectedImeiInputMethod === 2 && !selectedFile);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth keepMounted={false}>
        <DialogTitle variant="h5">
          {translations.common.texts.deviceBulkActions()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1, mb: 4 }}>
            <Typography>{translations.deviceBulkActions.texts.info()}</Typography>
          </Box>
          <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
            <InputLabel id="actions-select-label" shrink disabled={isLoading}>
              {translations.common.texts.actions()}
            </InputLabel>
            <Select
              id="status"
              size="small"
              labelId="actions-select-label"
              value={selectedAction}
              onChange={handleActionSelectChange}
            >
              {Object.values(DeviceBulkActions).map((action) => (
                <MenuItem key={action} value={action}>
                  <ListItemText primary={action} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedAction === DeviceBulkActions.CHANGE_REALM && (
            <FormControl className="input" sx={{ textAlign: "start", mt: 4, mr: 1 }} fullWidth>
              <InputLabel id="organization-select-label" shrink disabled={isLoading}>
                {translations.deviceBulkActions.texts.newRealm()}
              </InputLabel>
              <Select
                id="status"
                size="small"
                labelId="organization-select-label"
                value={selectedNewOrganization}
                onChange={handleNewOrganizationSelect}
              >
                {childOrganizations.map((organization, key) => (
                  <MenuItem key={key} value={organization.id}>
                    <ListItemText primary={organization.fullName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl sx={{ mt: 2 }}>
            <RadioGroup
              row
              id="imeiInputMethod"
              value={selectedImeiInputMethod ?? ""}
              onChange={handleImeiInputMethodChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio size="small" />}
                label={translations.common.texts.enterTheImeiCodes()}
              />
              <FormControlLabel
                value={2}
                control={<Radio size="small" />}
                label={translations.common.texts.uploadImeiCodes()}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            {selectedImeiInputMethod === 1 && (
              <CustomTextField
                id="imei-input"
                testId=""
                multiline
                type="number"
                minRows={3}
                maxRows={8}
                label={translations.common.texts.imeis()}
                placeholder={translations.common.texts.enterImeisPlaceholder()}
                onChange={handleImeiInputChange}
              />
            )}
            {selectedImeiInputMethod === 2 && (
              <FileUpload
                infoText={translations.common.texts.uploadImeisPlaceholder()}
                acceptedFileType="text/csv"
                handleFileChange={handleFileChange}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            data-testid=""
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.submit()}
          </Button>
          <Button variant="outlined" onClick={handleClose} data-testid="">
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeviceBulkActionsDialog;
