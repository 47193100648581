/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { BackendFactory, Device, DeviceAttributeName, TeamMember } from "@sade/data-access";
import * as React from "react";
import { useEverThereContext } from "../../../../../../context/everThere-context";
import { SeverityType } from "../../../../../../context/snackbar-context";
import { translations } from "../../../../../../generated/translationHelper";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";
import TestIds from "../../../../../../test-ids/test-ids";

type MakeMemberAWearerDialogProps = {
  open: boolean;
  member: TeamMember;
  device?: Device;
  onClose: () => void;
};

const MakeMemberAWearerDialog: React.FC<MakeMemberAWearerDialogProps> = ({ open, member, device, onClose }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const backend = BackendFactory.getOrganizationBackend();
  const { showSnackbar } = useSnackbar();
  const { getEverThereData } = useEverThereContext();

  const deviceId = device?.getId() ?? "";

  const memberFirstName = member?.firstName ?? "";
  const memberUserId = member?.userId ?? "";

  const handleSubmitClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const patientId = device?.getAttribute(DeviceAttributeName.patientId) ?? "";
      const patient = await backend.getPatient(patientId);
      if (patient) {
        await patient.updateAttributes({ wearer: memberUserId });
        showSnackbar(
          translations.deviceEverThereMakeMemberAWearerDialog.texts.successHeader(),
          SeverityType.Success,
          undefined,
          undefined,
          translations.deviceEverThereMakeMemberAWearerDialog.texts.successInfo({ memberFirstName, deviceId })
        );
        getEverThereData(patientId);
        onClose();
      } else {
        showSnackbar(translations.common.texts.patientNotFound({ patientId }), SeverityType.Error);
      }
    } catch (error) {
      showSnackbar(
        translations.deviceEverThereMakeMemberAWearerDialog.texts.errorHeader(),
        SeverityType.Error,
        undefined,
        undefined,
        translations.deviceEverThereMakeMemberAWearerDialog.texts.errorInfo({ deviceId })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DeviceEverThereMakeMemberAWearerDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereMakeMemberAWearerDialog.texts.title({ memberFirstName })}
          <IconButton
            aria-label="close"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereMakeMemberAWearerDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {translations.deviceEverThereMakeMemberAWearerDialog.texts.info({ memberFirstName, deviceId })}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmitClick}
            data-testid={TestIds.DeviceEverThereMakeMemberAWearerDialog.ConfirmButton}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.confirm()}
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            data-testid={TestIds.DeviceEverThereMakeMemberAWearerDialog.ActionCloseButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MakeMemberAWearerDialog;
