/* Copyright */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
} from "@mui/material";
import {
  BackendFactory,
  DeviceState,
  OrganizationParameterHierarchy,
  ParameterName,
  ParameterType,
  ParameterValueType,
  Parameters,
} from "@sade/data-access";
import * as React from "react";
import theme from "../../../../app/theme";
import {
  SeverityType,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { filterBasedOnSystemParameters } from "../../../../utils/parameterUtils";
import { PartnerParameter } from "../../../../utils/utils";
import ParameterManagement from "../../../parameter-management/parameter-management";
import UnsavedChangesDialog from "../../unsaved-changes-dialog/unsaved-changes-dialog";
import RemoveParameterConfirmationDialog from "./remove-parameter-confirmation-dialog";
import SaveChangesDialog from "./save-changes-dialog";

type AdvancedSettingsDialogProps = {
  open: boolean;
  deviceId: string;
  deviceState?: DeviceState;
  parameterHierarchy?: OrganizationParameterHierarchy;
  onClose: () => void;
  onDeviceStateUpdate: () => void;
};

const DEFAULT_PARAMETER: PartnerParameter = { parameterName: "", parameterValue: "", parameterType: "string" };

const AdvancedSettingsDialog: React.FC<AdvancedSettingsDialogProps> = ({
  open,
  deviceId,
  deviceState,
  parameterHierarchy,
  onClose,
  onDeviceStateUpdate,
}) => {
  const { showSnackbar } = useSnackbar();
  const [supportedParameters, setSupportedParameters] = React.useState<ParameterName[]>([]);
  const [deviceParameters, setDeviceParameters] = React.useState<PartnerParameter[]>([DEFAULT_PARAMETER]);
  // Currently selected parameter to remove
  const [parameterToRemove, setParameterToRemove] = React.useState<string>();
  // List of parameters to remove
  const [parametersToRemove, setParametersToRemove] = React.useState<string[]>([]);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState<boolean>(false);
  const [removeParameterDialogOpen, setRemoveParameterDialogOpen] = React.useState<boolean>(false);
  const [saveChangesDialogOpen, setSaveChangesDialogOpen] = React.useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = React.useState<boolean>(false);
  // Manage validation state for each parameter. Key is the parameter name and value is the validation result
  // (true = validation good, false = validation failed).
  const [parameterValidationMap, setParameterValidationMap] = React.useState<{ [key: string]: boolean }>({});

  /**
   * Get list of supported device parameters.
   */
  React.useEffect(() => {
    const getSupportedParameters = async (): Promise<void> => {
      const params: ParameterName[] = await BackendFactory.getParameterBackend().listDeviceParameters();
      setSupportedParameters(filterBasedOnSystemParameters(params, parameterHierarchy ?? {}));
    };

    getSupportedParameters();
  }, [parameterHierarchy]);

  /**
   * Get list of parameters that have been overridden on the device.
   */
  React.useEffect(() => {
    if (supportedParameters.length === 0) return;

    const params: PartnerParameter[] = Object.entries(deviceState?.getOverriddenParameters() ?? {}).map(
      ([key, value]) => ({
        parameterName: key,
        parameterValue: value,
        parameterType: supportedParameters.find((s) => s.name === key)?.valueType ?? "string",
      })
    );

    if (params.length) setDeviceParameters(filterBasedOnSystemParameters(params, parameterHierarchy ?? {}));
  }, [supportedParameters, deviceState, open, parameterHierarchy]);

  /**
   * Clean up "parameterToRemove" once "Remove Parameter" confirmation dialog is closed.
   */
  React.useEffect(() => {
    if (!removeParameterDialogOpen) setParameterToRemove(undefined);
  }, [removeParameterDialogOpen]);

  const handleRemoveParameter = (_parameterType: ParameterType, parameterName: string): void => {
    setParameterToRemove(parameterName);
    setRemoveParameterDialogOpen(true);
  };

  const removeParameterConfirm = (): void => {
    if (parameterToRemove) {
      let newParameters = deviceParameters.filter((param) => param.parameterName !== parameterToRemove);

      if (newParameters.length === 0) {
        newParameters = [DEFAULT_PARAMETER];
      }
      setDeviceParameters(newParameters);
      setParametersToRemove([...parametersToRemove, parameterToRemove]);
      setIsDirty(true);
    }

    setRemoveParameterDialogOpen(false);
  };

  const handleParameterNameChange = (_parameterType: ParameterType, parameterName: string): void => {
    const parameterType = supportedParameters?.find((option) => option.name === parameterName)?.valueType ?? "string";

    if (deviceParameters.some((param) => param.parameterName === parameterName)) {
      alert(translations.common.texts.existingParameterAlert());
    } else {
      setDeviceParameters((prevParameters) =>
        prevParameters.map((parameter) => {
          if (parameter.parameterName === "") {
            return { ...parameter, parameterName, parameterType };
          } else {
            return parameter;
          }
        })
      );
    }
  };

  const handleParameterValueChange = (
    _parameterType: ParameterType,
    parameterName: string,
    parameterValue: ParameterValueType
  ): void => {
    setDeviceParameters((prevParameters) =>
      prevParameters.map((parameter) => {
        if (parameter.parameterName === parameterName) {
          return { ...parameter, parameterValue };
        } else {
          return parameter;
        }
      })
    );
    setIsDirty(true);
  };

  const handleParameterValueValidation = (parameter: string, validationResult: boolean): void => {
    setParameterValidationMap((prevMap) => ({ ...prevMap, [parameter]: validationResult }));
  };

  const saveChangesConfirm = async (): Promise<void> => {
    setSaveChangesDialogOpen(false);
    setIsSavingChanges(true);
    try {
      if (!deviceState || !parameterHierarchy?.deviceParameters) return;
      // First, reset those parameters which are to be removed back to the realm level value.
      const realmParameters: Parameters = Object.values(parameterHierarchy.deviceParameters).reduce(
        (acc, hierarchy) => ({
          ...acc,
          ...hierarchy,
        })
      );
      parametersToRemove.forEach((param) => deviceState.resetParameter(param, realmParameters));

      // Then, update the rest of the parameters.
      deviceParameters.forEach((param) =>
        // Parameters in shadow are stored as strings, at least for Libris2.
        // TODO: Check the need for casting to string with Libris3.
        deviceState?.updateParameter(param.parameterName, String(param.parameterValue))
      );
      await deviceState.store();
      onDeviceStateUpdate();

      setIsDirty(false);
      showSnackbar(
        translations.advancedSettingsDialog.texts.changesSavedSuccess(),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } catch (error) {
      // Restore pre-change settings
      deviceState?.revert();

      showSnackbar(
        translations.advancedSettingsDialog.texts.changesSavedFailed(),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } finally {
      setIsSavingChanges(false);
      onClose();
    }
  };

  const handleClose = (): void => {
    if (isDirty) {
      setUnsavedDataDialogOpen(true);
    } else {
      onClose();
    }
  };

  const isSavingDisabled =
    !isDirty ||
    isSavingChanges ||
    Object.values(parameterValidationMap).some((validationResult) => validationResult === false);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle variant="h5">
          {translations.advancedSettingsDialog.texts.title({ deviceId })}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
            data-testid={TestIds.DeviceAdvancedSettingsDialog.CloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>{translations.advancedSettingsDialog.texts.info()}</div>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", ml: 2, mb: -2 }}>
                <Box sx={{ width: "50%" }}>
                  <InputLabel id="device-parameter-label" shrink>
                    {translations.advancedSettingsDialog.texts.parameterLabel()}
                  </InputLabel>
                </Box>
                <Box sx={{ width: "50%", ml: -1 }}>
                  <InputLabel id="parameter-value-label" shrink>
                    {translations.common.texts.value()}
                  </InputLabel>
                </Box>
              </Box>
              {deviceParameters.map((param, index) => (
                <ParameterManagement
                  key={index}
                  parameterOptions={supportedParameters}
                  parameterType={ParameterType.device}
                  parameter={param}
                  handleRemoveParameter={handleRemoveParameter}
                  handleParameterNameChange={handleParameterNameChange}
                  handleParameterValueChange={handleParameterValueChange}
                  handleParameterValueValidation={handleParameterValueValidation}
                />
              ))}
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{ mt: 1 }}
                onClick={(): void => setDeviceParameters([...deviceParameters, DEFAULT_PARAMETER])}
                disabled={
                  deviceParameters.length > 0 ? !deviceParameters[deviceParameters.length - 1].parameterName : true
                }
              >
                {translations.partners.buttons.addDeviceParameter()}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={(): void => setSaveChangesDialogOpen(true)}
            sx={{ color: theme.palette.common.white }}
            data-testid={TestIds.DeviceAdvancedSettingsDialog.SaveChanges}
            disabled={isSavingDisabled}
            startIcon={isSavingChanges && <CircularProgress size={16} color={"secondary"} />}
          >
            {translations.common.buttons.saveChanges()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
            data-testid={TestIds.DeviceAdvancedSettingsDialog.CloseButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>

      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={(): void => setUnsavedDataDialogOpen(false)}
        handleSave={saveChangesConfirm}
        handleDiscard={(): void => {
          setIsDirty(false);
          onClose();
        }}
      />

      <RemoveParameterConfirmationDialog
        open={removeParameterDialogOpen}
        parameterName={parameterToRemove}
        onClose={(): void => setRemoveParameterDialogOpen(false)}
        onRemove={removeParameterConfirm}
      />

      <SaveChangesDialog
        open={saveChangesDialogOpen}
        onClose={(): void => setSaveChangesDialogOpen(false)}
        onSave={saveChangesConfirm}
      />
    </React.Fragment>
  );
};

export default AdvancedSettingsDialog;
