/* Copyright */
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Chip, Table, TableBody, TableCell, TableRow, Typography, tableCellClasses } from "@mui/material";
import { TeamMember } from "@sade/data-access";
import React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { getUserInitials, solveEverThereMemberProps } from "../../../../utils/utils";

type DeviceViewContentEverThereUserProps = {
  deviceId?: string;
  disabled?: boolean;
  member: TeamMember;
  memberIndex?: number;
};

const DeviceViewContentEverThereUser: React.FC<DeviceViewContentEverThereUserProps> = ({
  deviceId,
  disabled,
  member,
  memberIndex,
}) => {
  const tableRef = React.useRef<HTMLTableElement | null>(null);
  const [maxWidth, setMaxWidth] = React.useState<number | null>(null);

  React.useEffect(() => {
    const updateMaxWidth = (): void => {
      if (tableRef.current) {
        const width = tableRef.current.clientWidth;
        const maxWidth = width - 260;
        setMaxWidth(maxWidth);
      }
    };

    updateMaxWidth();

    window.addEventListener("resize", updateMaxWidth);

    return (): void => {
      window.removeEventListener("resize", updateMaxWidth);
    };
  }, [tableRef]);

  const memberProps = solveEverThereMemberProps(member, deviceId);

  const initials = getUserInitials(member.firstName ?? "", member.lastName ?? "");

  const chipLabel = memberProps.isManager
    ? translations.common.texts.manager()
    : memberProps.isPending
    ? translations.common.texts.pending()
    : memberProps.isWearer
    ? translations.common.texts.wearer()
    : memberProps.isExpiredInvitation
    ? translations.common.texts.invitationExpired()
    : memberProps.isDeclined
    ? translations.common.texts.declined()
    : "";

  return (
    <Box>
      <Table
        ref={tableRef}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell sx={{ minWidth: "80px", maxWidth: "80px" }}>
              <Avatar sx={{ bgcolor: memberProps.colorScheme, opacity: disabled ? 0.5 : 1.0 }}>
                {initials && !memberProps.isPending ? initials : <PersonIcon />}
              </Avatar>
            </TableCell>
            <TableCell data-testid={`${TestIds.DeviceEverThereCard.UserEmail}-${memberIndex}`} sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{ maxWidth: maxWidth, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {member.email}
              </Typography>
              {memberProps.isManager && (
                <Typography variant="caption">{translations.common.texts.teamManager()}</Typography>
              )}
              {memberProps.isManager && member.wearerOfDevice && <br />}
              {member.wearerOfDevice && (
                <Typography variant="caption">
                  {translations.common.texts.wearerOf()} {member.wearerOfDevice}
                </Typography>
              )}
            </TableCell>

            <TableCell
              sx={{ minWidth: "110px", maxWidth: "110px", textAlign: "end" }}
              data-testid={`${TestIds.DeviceEverThereCard.Manager}-${memberIndex}`}
            >
              {(memberProps.isManager || memberProps.isWearer || memberProps.isPending || memberProps.isDeclined) && (
                <Chip
                  size="small"
                  label={chipLabel}
                  sx={{
                    bgcolor: memberProps.colorScheme,
                    color: memberProps.colorScheme.length > 0 ? "white" : "",
                    opacity: disabled ? 0.5 : 1.0,
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DeviceViewContentEverThereUser;
