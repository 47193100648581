/* Copyright */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import logo from "../../assets/images/Numera-Nice-brand-Logo-color.svg";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SeverityType } from "../../context/snackbar-context";
import { isValidEmail } from "../../utils/validation";
import { AuthWrapper, isErrorWithCode, ErrorWithCode } from "@sade/data-access";
import ForgotPasswordForm from "./forgot-password-form";
import ResetPasswordForm from "./reset-password-form";
import SuccessView from "./success-view";
import { Paths } from "../../utils/utils";
import { getPath } from "../../utils/ssoPathUtil";

type Props = {
  initialProgress: string;
};

const ForgotPasswordView: React.FC<Props> = ({ initialProgress }) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = React.useState(process.env.NODE_ENV === "test" ? initialProgress : "requestCode");
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [newPasswords, setNewPasswords] = React.useState({ newPassword: "", confirmNewPassword: "" });
  const [errors, setErrors] = React.useState({
    generalError: "",
    emailError: "",
    codeError: "",
    newPasswordError: "",
  });
  const [success, setSuccess] = React.useState("");

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    } else if (success) {
      showSnackbar(success, SeverityType.Success);
    }
  }, [errors, success, showSnackbar]);

  const clearErrorsAndSuccess = (): void => {
    setErrors({
      generalError: "",
      emailError: "",
      codeError: "",
      newPasswordError: "",
    });
    setSuccess("");
  };

  const handleErrors = (code?: string): void => {
    switch (code) {
      case "LimitExceededException":
        setErrors({
          generalError: translations.common.texts.tooManyAttempts(),
          emailError: "",
          codeError: "",
          newPasswordError: "",
        });
        break;
      case "Network error":
        setErrors({
          generalError: translations.common.texts.networkError(),
          emailError: "",
          codeError: "",
          newPasswordError: "",
        });
        break;
      case "EmailFormatNotValidException":
        setErrors({
          generalError: "",
          emailError: translations.common.texts.emailFormatNotValid(),
          codeError: "",
          newPasswordError: "",
        });
        break;
      case "CodeMismatchException":
        setErrors({
          generalError: "",
          emailError: "",
          codeError: translations.forgotPasswordResetPassword.texts.invalidCode(),
          newPasswordError: "",
        });
        break;
      case "ExpiredCodeException":
        setErrors({
          generalError: "",
          emailError: "",
          codeError: translations.forgotPasswordResetPassword.texts.codeExpired(),
          newPasswordError: "",
        });
        break;
      case "InvalidPasswordException":
        setErrors({
          generalError: "",
          emailError: "",
          codeError: "",
          newPasswordError: translations.common.texts.passwordDoesNotMeetRequirements(),
        });
        break;
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          emailError: "",
          codeError: "",
          newPasswordError: "",
        });
        break;
    }
  };

  //#region request code

  const isEmailValid = (): void | never => {
    const testedEmail = email;

    if (!isValidEmail(testedEmail)) {
      throw new ErrorWithCode("EmailFormatNotValidException");
    }
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    setEmail(event.target.value);
  };

  const handleSendLinkClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    try {
      isEmailValid();
      setIsLoading(true);
      await AuthWrapper.forgotPassword(email);
      setProgress("resetPassword");
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToLoginClick = (): void => {
    navigate(getPath(Paths.LOGIN));
  };

  //#endregion

  //#region new password

  const handleCodeChange = (code: string): void => {
    clearErrorsAndSuccess();
    setCode(code);
  };

  const handleResendCodeClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    try {
      setIsLoading(true);
      await AuthWrapper.forgotPassword(email);
      setSuccess(translations.forgotPasswordResetPassword.texts.codeResent());
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedNewPasswords = { ...newPasswords, [event.target.id]: event.target.value };
    setNewPasswords(updatedNewPasswords);
  };

  const handleChangePasswordClick = async (): Promise<void> => {
    clearErrorsAndSuccess();

    if (newPasswords.newPassword !== newPasswords.confirmNewPassword) {
      setErrors({
        ...errors,
        newPasswordError: translations.common.texts.passwordsDoNotMatch(),
      });
      return;
    }

    try {
      setIsLoading(true);
      await AuthWrapper.checkCodeAndSubmitNewPassword(email, code, newPasswords.newPassword);
      setProgress("success");
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  //#endregion

  const isChangePasswordButtonDisabled =
    !code || code.length !== 6 || !newPasswords.newPassword || !newPasswords.confirmNewPassword || isLoading;

  return (
    <Box className="page-container">
      <img src={logo} alt="Numera Nice brand logo" className="image" />
      {(progress === "requestCode" || progress === "") && (
        <ForgotPasswordForm
          email={email}
          isLoading={isLoading}
          emailError={errors.emailError}
          handleOnChange={handleEmailInputChange}
          handleSendClick={handleSendLinkClick}
          handleBackClick={handleBackToLoginClick}
        />
      )}
      {progress === "resetPassword" && (
        <ResetPasswordForm
          email={email}
          isLoading={isLoading}
          codeError={errors.codeError}
          newPasswordError={errors.newPasswordError}
          isButtonDisabled={isChangePasswordButtonDisabled}
          handleCodeOnChange={handleCodeChange}
          handleResend={handleResendCodeClick}
          handleInputChange={handleNewPasswordChange}
          handleChangePasswordClick={handleChangePasswordClick}
        />
      )}
      {progress === "success" && <SuccessView />}
    </Box>
  );
};

export default ForgotPasswordView;
