/* Copyright */
const LTE_RSRP_BAR_0 = -44; // 0 bar indicates illegal rsrp value and must use rssi instead
const LTE_RSRP_BAR_2 = -115;
const LTE_RSRP_BAR_3 = -105;
const LTE_RSRP_BAR_4 = -95;
const LTE_RSRP_BAR_5 = -85;
const LTE_ASU_BAR_1 = 63;
const LTE_ASU_BAR_2 = 0;
const LTE_ASU_BAR_3 = 5;
const LTE_ASU_BAR_4 = 8;
const LTE_ASU_BAR_5 = 12;
const WCDMA_ASU_BAR_1 = 2;
const WCDMA_ASU_BAR_1_OTHER = 99;
const WCDMA_ASU_BAR_2 = 0;
const WCDMA_ASU_BAR_3 = 5;
const WCDMA_ASU_BAR_4 = 8;
const WCDMA_ASU_BAR_5 = 12;
/**
 * Retrieves the signal strength in bars.
 *
 * @return {Promise<Maybe<number>>} The signal strength, or undefined if there's no data to calculate.
 */
export const getSignalStrengthLevel = (rssi, rsrp, networkType) => {
    // asu level calculated from rssi per Quectel documentation
    const asu_level = (rssi + 113) / 2;
    if (networkType === "LTE") {
        if (rsrp >= LTE_RSRP_BAR_0) {
            // Illegal rsrp value, need to use asu to calculate
        }
        else if (rsrp >= LTE_RSRP_BAR_5) {
            return 5;
        }
        else if (rsrp >= LTE_RSRP_BAR_4) {
            return 4;
        }
        else if (rsrp >= LTE_RSRP_BAR_3) {
            return 3;
        }
        else if (rsrp >= LTE_RSRP_BAR_2) {
            return 2;
        }
        else {
            return 1; // 1 bar is any other value
        }
        if (asu_level > LTE_ASU_BAR_1) {
            return 1;
        }
        else if (asu_level >= LTE_ASU_BAR_5) {
            return 5;
        }
        else if (asu_level >= LTE_ASU_BAR_4) {
            return 4;
        }
        else if (asu_level >= LTE_ASU_BAR_3) {
            return 3;
        }
        else if (asu_level >= LTE_ASU_BAR_2) {
            return 2;
        }
    }
    else if (networkType === "WCDMA") {
        // L20-1164
        // RSSI = 0 check needed for edge case not handled by documentation's recommendation
        // RSSI is reported as 0 in poor network conditions which incorrectly gives 5 bars
        if (rssi === 0) {
            return 0;
        }
        else if (asu_level <= WCDMA_ASU_BAR_1 || asu_level === WCDMA_ASU_BAR_1_OTHER) {
            return 1;
        }
        else if (asu_level >= WCDMA_ASU_BAR_5) {
            return 5;
        }
        else if (asu_level >= WCDMA_ASU_BAR_4) {
            return 4;
        }
        else if (asu_level >= WCDMA_ASU_BAR_3) {
            return 3;
        }
        else if (asu_level >= WCDMA_ASU_BAR_2) {
            return 2;
        }
    }
    else if (networkType === "NOSERVICE") {
        return 0;
    }
    return 0;
};
