/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import { Maybe, User } from "@sade/data-access";
import TestIds from "../../../test-ids/test-ids";

type ReEnableAccountDialogProps = {
  open: boolean;
  handleClose: () => void;
  user: Maybe<User>;
  handleSubmit: () => void;
  isLoading: boolean;
};

const ReEnableAccountDialog: React.FC<ReEnableAccountDialogProps> = ({
  open,
  handleClose,
  user,
  handleSubmit,
  isLoading,
}) => {
  const firstName = user?.getDetails().firstName ?? "";
  const lastName = user?.getDetails().lastName ?? "";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.EnableAccountDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.common.texts.enableAccount()}
        <IconButton
          data-testid={TestIds.EnableAccountDialog.CloseButton}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              !firstName && !lastName
                ? translations.enableAccountDialog.texts.subtitleNoName()
                : translations.enableAccountDialog.texts.subtitle({ firstName, lastName }),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="success"
          disabled={isLoading}
          data-testid={TestIds.EnableAccountDialog.SubmitButton}
          startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
        >
          {translations.common.texts.enableAccount()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={isLoading}
          data-testid={TestIds.EnableAccountDialog.CancelButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReEnableAccountDialog;
