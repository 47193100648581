/* Copyright */
import React from "react";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CallIcon from "@mui/icons-material/Call";
import LocateDeviceIcon from "../../../assets/icons/locate-device-icon";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { Device, DeviceAction, RoleIdentifier, isErrorWithCode } from "@sade/data-access";
import { SeverityType } from "../../../context/snackbar-context";
import { useAccessControl } from "../../../hooks/useAccessControl";

type DeviceViewHeaderActionButtonsProps = {
  isLoading: boolean;
  deviceData?: Device;
};

const DeviceViewHeaderActionButtons: React.FC<DeviceViewHeaderActionButtonsProps> = ({ isLoading, deviceData }) => {
  const { showSnackbar } = useSnackbar();
  const [isSendingTuneRequest, setIsSendingTuneRequest] = React.useState<boolean>(false);
  const [isSendingCallRequest, setIsSendingCallRequest] = React.useState<boolean>(false);
  const [isSendingLocateRequest, setIsSendingLocateRequest] = React.useState<boolean>(false);

  const handlePlayTuneClick = async (): Promise<void> => {
    if (deviceData) {
      try {
        setIsSendingTuneRequest(true);
        await deviceData?.requestAction(DeviceAction.PlayTune);
        showSnackbar(translations.deviceDetails.texts.playTuneRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetails.texts.playTuneRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetails.texts.errorMessage({ error: isErrorWithCode(error) ? error.code : "" })
        );
      }
      setIsSendingTuneRequest(false);
    }
  };

  const handleCallMeClick = async (): Promise<void> => {
    if (deviceData) {
      try {
        setIsSendingCallRequest(true);
        await deviceData?.requestAction(DeviceAction.CallNow);
        showSnackbar(translations.deviceDetails.texts.callMeRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetails.texts.callMeRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetails.texts.errorMessage({ error: isErrorWithCode(error) ? error.code : "" })
        );
      }
      setIsSendingCallRequest(false);
    }
  };

  const handleLocateMeClick = async (): Promise<void> => {
    if (deviceData) {
      try {
        setIsSendingLocateRequest(true);
        await deviceData?.requestAction(DeviceAction.LocateNow);
        showSnackbar(translations.deviceDetails.texts.locateMeRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetails.texts.locateMeRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetails.texts.errorMessage({ error: isErrorWithCode(error) ? error.code : "" })
        );
      }
      setIsSendingLocateRequest(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, mb: { xs: 2 } }}>
      <Box sx={{ mb: { xs: 2, xl: 0 } }}>
        <Tooltip title={translations.devices.buttons.playTuneTooltip()} arrow>
          <Button
            variant="outlined"
            startIcon={isSendingTuneRequest ? <CircularProgress size={16} color="secondary" /> : <AudiotrackIcon />}
            disabled={isLoading || isSendingTuneRequest}
            sx={{ mr: 1 }}
            data-testid={TestIds.DeviceDetails.PlayTuneButton}
            onClick={handlePlayTuneClick}
          >
            {translations.devices.buttons.playTune()}
          </Button>
        </Tooltip>
        {useAccessControl(
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.WarehouseInstaller
        ) && (
          <Tooltip title={translations.devices.buttons.locateTooltip()} arrow>
            <Button
              variant="outlined"
              startIcon={
                isSendingLocateRequest ? (
                  <CircularProgress size={16} color="secondary" />
                ) : (
                  <LocateDeviceIcon disabled={isLoading || isSendingLocateRequest} />
                )
              }
              disabled={isLoading || isSendingLocateRequest}
              sx={{ mr: 1 }}
              data-testid={TestIds.DeviceDetails.LocateButton}
              onClick={handleLocateMeClick}
            >
              {translations.devices.buttons.locate()}
            </Button>
          </Tooltip>
        )}
        <Tooltip title={translations.devices.buttons.callTooltip()} arrow>
          <Button
            variant="outlined"
            startIcon={isSendingCallRequest ? <CircularProgress size={16} color="secondary" /> : <CallIcon />}
            disabled={isLoading || isSendingCallRequest}
            sx={{ mr: 1 }}
            data-testid={TestIds.DeviceDetails.CallButton}
            onClick={handleCallMeClick}
          >
            {translations.common.texts.call()}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default DeviceViewHeaderActionButtons;
