/* Copyright */
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, Checkbox, FormControlLabel, Menu, MenuItem } from "@mui/material";
import { EventType, Maybe, RoleIdentifier } from "@sade/data-access";
import * as React from "react";
import theme from "../../../app/theme";
import { useAccessControl } from "../../../hooks/useAccessControl";
import { getEventFilterOptions } from "../../../utils/eventUtils";
import CustomBadgedAvatar from "../../common/custom-badged-avatar";

type EventTypeSelectorProps = {
  handleSelectedEventTypesChange: (eventType: EventType) => void;
  selectedEventTypes: Maybe<EventType[]>;
  isDisabled: boolean;
};

const EventTypeSelector: React.FC<EventTypeSelectorProps> = ({
  handleSelectedEventTypesChange,
  selectedEventTypes,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.SyntheticEvent<HTMLElement>): void => {
    !isDisabled && setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const allRolesAccess = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent,
    RoleIdentifier.Supervisor,
    RoleIdentifier.Operator,
    RoleIdentifier.WarehouseInstaller
  );

  const powerUserAccess = useAccessControl(RoleIdentifier.PowerUser);

  const checkAccess = (eventType: EventType): boolean => {
    if (
      eventType === EventType.NetworkChange ||
      eventType === EventType.Classify ||
      eventType === EventType.Status ||
      eventType === EventType.Debug ||
      eventType === EventType.Logfile
    ) {
      return powerUserAccess;
    }
    return allRolesAccess;
  };

  return (
    <Box>
      <CustomBadgedAvatar
        icon={<FilterListOutlinedIcon sx={{ color: isDisabled ? theme.palette.secondary.light : "" }} />}
        badgeColor="warning"
        isBadgeActive={selectedEventTypes ? selectedEventTypes?.length > 0 : false}
        handleOnClick={handleOpenMenu}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        {getEventFilterOptions().map((event) => {
          if (!checkAccess(event.type)) return null;

          return (
            <MenuItem dense key={event.type}>
              <FormControlLabel
                sx={{ flex: 1 }}
                control={<Checkbox checked={!!selectedEventTypes?.includes(event.type)} />}
                onChange={(): void => handleSelectedEventTypesChange(event.type)}
                label={event.label}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default EventTypeSelector;
