/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export var OtaState;
(function (OtaState) {
    OtaState["Idle"] = "IDLE";
    OtaState["InProgress"] = "IN_PROGRESS";
    OtaState["Done"] = "DONE";
    OtaState["Cancelled"] = "CANCELLED";
})(OtaState || (OtaState = {}));
export var OtaVariant;
(function (OtaVariant) {
    OtaVariant["English"] = "EN";
    OtaVariant["French"] = "FR";
    OtaVariant["Spanish"] = "ES";
})(OtaVariant || (OtaVariant = {}));
