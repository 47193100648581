/* Copyright */
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import * as React from "react";
import AnyFixIcon from "../../../../assets/icons/Legend-LatestFix-AnyType.svg";

import InProgressCall from "../../../../assets/icons/MapPin-InProgressCall.svg";
import NetworkFixIcon from "../../../../assets/icons/MapPin-PastLocation-NetworkFix.svg";
import SatelliteFixIcon from "../../../../assets/icons/MapPin-PastLocation-SatelliteFix.svg";
import WifiFixIcon from "../../../../assets/icons/MapPin-PastLocation-WifiFix.svg";
import { translations } from "../../../../generated/translationHelper";

const EventsMapDefinitionOverlay: React.FC = () => {
  return (
    <Box sx={{ borderRadius: 1, bgcolor: "white", boxShadow: 1 }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <img src={AnyFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.latestFixOfAnyType()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={SatelliteFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.satelliteFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={WifiFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.wifiFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={NetworkFixIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.networkFix()}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <img src={InProgressCall} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", ml: -2 }}
            primary={translations.events.texts.inProgressCall()}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default EventsMapDefinitionOverlay;
