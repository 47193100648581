/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { Libris2 } from "../../devices/Libris2/Libris2";
import { Libris3 } from "../../devices/Libris3/Libris3";
import { Panel4200x } from "../../devices/Panel4200x/Panel4200x";
const DEVICE_CONSTRUCTORS = {
    [Libris2.type]: Libris2,
    [Libris3.type]: Libris3,
    [Panel4200x.type]: Panel4200x,
};
export class AWSDeviceFactory {
    createDevice(backend, type, params) {
        const ctor = DEVICE_CONSTRUCTORS[type];
        if (ctor) {
            return new ctor(backend, params);
        }
        else {
            console.error(`Could not locate device implementation for type ${type}`);
        }
    }
    listDeviceTypes() {
        return Object.keys(DEVICE_CONSTRUCTORS);
    }
}
