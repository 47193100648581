/* Copyright */
const TestIds = {
  ForgotPasswordForm: {
    ForgotPasswordBack: "forgot-password-back",
    ForgotPasswordEmailInput: "forgot-password-email",
    ForgotPasswordResendCode: "enter-code-resend",
    ForgotPasswordSubmit: "forgot-password-submit",
    ForgotPasswordSuccess: "forgot-password-open-portal",
  },
  LoginView: {
    Email: "login-email",
    Password: "login-password",
    RememberMe: "login-remember-me",
    Submit: "login-submit",
  },
  Navigation: {
    NavigationDrawerCloseButton: "navigation-drawer-close-icon-button",
    NavigationDrawer: "navigation-drawer",
    NavigationDrawerFooter: "navigation-drawer-footer",
    NavigationDrawerItemList: "navigation-drawer-item-list",
  },
  NewPasswordForm: {
    ConfirmNewPassword: "create-new-confirm-password",
    CreateNewPassword: "create-new-password",
    SubmitNewPassword: "new-password-submit",
  },
  Profile: {
    OpenProfileMenuButton: "profile-menu-open-button",
    ViewProfileButton: "view-profile-button",
    ProfileRole: "profile-role",
    ProfileMenu: "profile-menu",
    ProfileRealm: "profile-realm",
    Logout: "logout",
  },
  LayoutFooter: {
    LayoutFooter: "layout-footer",
    LayoutFooterItemContainer: "layout-footer-item-container",
    CurrentRealm: "layout-footer-current-realm",
  },
  ProfileDialog: {
    FirstName: "profile-dialog-firstname",
    LastName: "profile-dialog-lastname",
    Email: "profile-dialog-email",
    Country: "profile-dialog-country",
    CountryCode: "profile-dialog-country-code",
    Phone: "profile-dialog-phone",
    Language: "profile-dialog-language",
    CurrentPassword: "profile-dialog-current-password",
    NewPassword: "create-new-password",
    ConfirmPassword: "create-new-confirm-password",
    ChangePasswordButton: "new-password-submit",
    SaveChangesButton: "profile-dialog-save-changes-button",
    CloseProfileDialog: "profile-dialog-close-button",
    CancelButton: "profile-dialog-cancel-button",
  },
  Registration: {
    Country: "registration-country",
    OpenCountrySelection: "registration-country-menu",
    CountryOptionUSA: "country-usa",
    CountryOptionCanada: "country-canada",
    FirstName: "registration-first-name",
    LastName: "registration-last-name",
    Email: "registration-user-email",
    Number: "registration-phone-number",
    AcceptTermsAndConditions: "registration-accept-terms-and-conditions",
    Submit: "register-submit",
    OpenPortal: "registration-open-portal",
  },
  RegistrationSmsVerification: {
    EditPhoneNumberLink: "registration-sms-verification-edit-phone-number-link",
    ResendCodeLink: "registration-sms-verification-resend-code-link",
    SubmitButton: "registration-sms-verification-submit-button",
  },
  Toolbar: {
    LargeHomeLink: "toolbar-large-home-link",
    LargeMonitoringLink: "toolbar-large-monitoring-link",
    LargeDevicesLink: "toolbar-large-devices-link",
    LargeAccountsLink: "toolbar-large-accounts-link",
    LargeReportsLink: "toolbar-large-reports-link",
    LargePartnersLink: "toolbar-large-partners-link",
    LargeAdminButton: "toolbar-large-admin-button",
    SmallMenuButton: "toolbar-small-menu-iconbutton",
    SmallHomeLink: "toolbar-small-home-link",
  },
  UnsavedChangesDialog: {
    CloseDialog: "close-button",
    SaveChanges: "save-button",
    DiscardChanges: "discard-button",
  },
  Accounts: {
    SearchBySelect: "accounts-search-by-select",
    SearchDropdown: "search-dropdown-menu",
    SearchByFirstName: "accounts-search-by-first-name",
    SearchByLastName: "accounts-search-by-last-name",
    SearchByEmail: "accounts-search-by-email",
    Search: "accounts-search",
    IncludeDisabled: "accounts-include-disabled",
    EverThereIncludeDisabled: "everthere-accounts-include-disabled",
    UsersTable: "accounts-users-table",
    NewAccountButton: "accounts-new-account-button",
    EverThereAccountsTab: "everthere-accounts-tab",
    DealerAccountsTab: "dealer-accounts-tab",
  },
  AccountsFilterBar: {
    StatusSelect: "accounts-status-select",
    OpenStatusSelection: "status-selection-menu",
    RealmSelect: "accounts-realm-select",
    OpenRealmSelection: "realm-selection-menu",
    RoleSelect: "accounts-role-select",
    OpenRoleSelection: "role-selection-menu",
  },
  EverThereAccounts: {
    UsersTable: "everthere-accounts-users-table",
    EverThereAccountsTab: "everthere-accounts-tab",
    StatusSelect: "everthere-accounts-status-select",
    OpenStatusSelection: "everthere-status-selection-menu",
  },
  Reports: {
    ReportTypeDropdown: "reports-report-type-dropdown",
    OpenStatusSelection: "reports-open-status-selection",
    StatusSelect: "reports-status-select",
    DownloadButton: "reports-download-button",
    StatusFilterLabel: "reports-status-filter-label",
    StatusFilter: "reports-status-filter",
  },
  Partners: {
    Search: "partners-search",
    VariantSelect: "partners-variant-select",
    NewPartnerButton: "partners-new-partner-button",
    PartnersTable: "partners-table",
    PartnersTableRow: "table-row-element",
    PartnerName: "partner-name-cell",
    RealmName: "realm-name-cell",
  },
  PartnerDetails: {
    Dialog: "partner-details-dialog",
    BackButton: "partner-details-back-button",
    CreateButton: "partner-details-create-button",
    SaveButton: "partner-details-save-button",
    DeleteButton: "partner-details-delete-button",
    RealmSelect: "partner-details-realm-select",
    PartnerNameInput: "partner-details-name-input",
    RealmNameInput: "partner-details-realm-name-input",
    NetworkIdInput: "partner-details-network-id-input",
    NetsuiteIdInput: "partner-details-netsuite-id-input",
    SsoIdentityProvider: "partner-details-sso-identity-provider-input",
    SsoDefaultRole: "partner-details-sso-default-role-input",
  },
  ConfirmDeletePartnerDialog: {
    Dialog: "confirm-delete-partner-dialog",
    CloseButton: "confirm-delete-partner-dialog-close-button",
    ConfirmButton: "confirm-delete-partner-dialog-confirm-button",
    CancelButton: "confirm-delete-partner-dialog-cancel-button",
  },
  UsersTable: {
    TableRow: "table-row-element",
    UserStatus: "status-cell",
    UserFirstName: "first-name-cell",
    UserLastName: "last-name-cell",
    UserEmail: "email-cell",
    UserLegacyUsername: "legacy-username-cell",
    UserRealm: "realm-cell",
    UserRole: "role-cell",
  },
  Misc: {
    LoadingIcon: "loader",
  },
  AccountDetailsDialog: {
    Dialog: "account-details-dialog",
    CloseButton: "account-details-dialog-close-button",
    FirstName: "account-details-dialog-first-name",
    LastName: "account-details-dialog-last-name",
    Email: "account-details-dialog-email",
    Status: "account-details-dialog-status",
    Country: "account-details-dialog-country",
    CountryCode: "account-details-dialog-country-code",
    PhoneNumber: "account-details-dialog-phone-number",
    Realm: "account-details-realm",
    RealmRow: "account-details-realm-row",
    Role: "account-details-role",
    RoleSelect: "account-details-select-role",
    AddRealmAccess: "account-details-dialog-add-realm-access-button",
    RevokeRealmAccess: "account-details-dialog-revoke-realm-access-button",
    EnableButton: "account-details-dialog-enable-button",
    DisableButton: "account-details-dialog-disable-button",
    DeleteButton: "account-details-dialog-delete-button",
    ResendInvitationButton: "account-details-dialog-resend-invitation-button",
    SaveChanges: "account-details-save-changes",
    CancelButton: "account-details-dialog-cancel-button",
  },
  EverThereAccountDetailsDialog: {
    DeviceSelector: "everthere-account-details-device-selector",
    DeviceMenuItem: "everthere-account-details-device-menu-item",
    DeviceDetailsLink: "everthere-account-details-device-details-link",
    UserEmail: "everthere-account-details-email",
    UserRole: "everthere-account-details-user-role",
  },
  AddNewAccountDialog: {
    Dialog: "add-new-account-dialog",
    CloseButton: "add-new-account-dialog-close-button",
    EmailInput: "add-new-account-dialog-email-input",
    RealmSelect: "add-new-account-dialog-realm-select",
    RoleSelect: "add-new-account-dialog-role-select",
    OpenRoleSelection: "add-new-account-role-selection-menu",
    SubmitButton: "add-new-account-dialog-submit-button",
    CancelButton: "add-new-account-dialog-cancel-button",
  },
  AddRealmAccessDialog: {
    Dialog: "add-realm-access-dialog",
    CloseButton: "add-realm-access-dialog-close-button",
    RealmSelect: "add-realm-access-dialog-realm-select",
    RoleSelect: "add-realm-access-dialog-role-select",
    SubmitButton: "add-realm-access-dialog-submit-button",
    CancelButton: "add-realm-access-dialog-cancel-button",
  },
  RevokeRealmAccessDialog: {
    Dialog: "revoke-realm-access-dialog",
    CloseButton: "revoke-realm-access-dialog-close-button",
    SubmitButton: "revoke-realm-access-dialog-submit-button",
    CancelButton: "revoke-realm-access-dialog-cancel-button",
  },
  DisableAccountDialog: {
    Dialog: "disable-account-dialog",
    CloseButton: "disable-account-dialog-close-button",
    SubmitButton: "disable-account-dialog-submit-button",
    CancelButton: "disable-account-dialog-cancel-button",
  },
  DeleteAccountDialog: {
    Dialog: "delete-account-dialog",
    CloseButton: "delete-account-dialog-close-button",
    SubmitButton: "delete-account-dialog-submit-button",
    CancelButton: "delete-account-dialog-cancel-button",
  },
  EnableAccountDialog: {
    Dialog: "enable-account-dialog",
    CloseButton: "enable-account-dialog-close-button",
    SubmitButton: "enable-account-dialog-submit-button",
    CancelButton: "enable-account-dialog-cancel-button",
  },
  EditPhoneNumberDialog: {
    Dialog: "edit-phone-number-dialog",
    CloseButton: "edit-phone-number-dialog-close-button",
    CountryCodeSelect: "edit-phone-number-dialog-country-code-select",
    PhoneNumberInput: "edit-phone-number-dialog-input",
    SubmitButton: "edit-phone-number-dialog-submit-button",
    CancelButton: "edit-phone-number-dialog-cancel-button",
  },
  SmsVerificationDialog: {
    Dialog: "sms-verification-dialog",
    CloseButton: "sms-verification-close-button",
    ResendCodeLink: "sms-verification-resend-code-link",
    SubmitButton: "sms-verification-submit-button",
  },
  RealmAccessSelectorDialog: {
    OpenButton: "realm-access-selector-open-button",
    Dialog: "realm-access-selector-dialog",
    CloseButton: "realm-access-selector-close-button",
    Header: "realm-access-selector-header",
    HeaderAvatar: "realm-access-selector-header-avatar",
    UserRealm: "realm-access-selector-user-realm",
    RealmInput: "realm-access-selector-realm-input",
  },
  Devices: {
    Search: "devices-search",
    SearchDropdown: "search-dropdown-menu",
    OpenRealmSelection: "devices-open-realm-selection",
    RealmSelect: "devices-realm-select",
    OpenStatusSelection: "devices-open-status-selection",
    StatusSelect: "devices-status-select",
    ActivateNewButton: "devices-activate-new-button",
    DevicesTable: "devices-table",
  },
  DevicesTable: {
    TableRow: "table-row-element",
    DeviceStatus: "status-cell",
    DeviceName: "device-name-cell",
    DeviceImei: "imei-cell",
    DeviceCallerId: "caller-id-cell",
    DeviceIccId: "iccid-cell",
    DeviceRealmName: "realm-cell",
  },
  DeviceDetails: {
    BackToListButton: "device-back-to-list-button",
    PlayTuneButton: "device-play-tune-button",
    LocateButton: "device-locate-button",
    CallButton: "device-call-button",
    NotesButton: "device-notes-button",
    MaintenanceButton: "device-maintenance-button",
    DetailsCard: "device-details-card",
    SettingsCard: "device-settings-card",
    EverThereCard: "device-ever-there-card",
    StatusCard: "device-status-card",
    EventsLocationsCard: "device-events-locations-card",
    PeripheralsCard: "device-peripherals-card",
    DeviceName: "device-name",
  },
  DeviceDetailsMaintenanceMenu: {
    MaintenanceMenu: "device-maintenance-menu",
    ActivateDevice: "device-maintenance-menu-activate-device",
    ActivateDeviceItem: "device-maintenance-menu-activate-device-item",
    DeactivateDevice: "device-maintenance-menu-deactivate-device",
    DeactivateDeviceItem: "device-maintenance-menu-deactivate-device-item",
    DisablePermanently: "device-maintenance-menu-disable-permanently",
    DisablePermanentlyItem: "device-maintenance-menu-disable-permanently-item",
    FlushQueue: "device-maintenance-menu-flush-queue",
    FlushQueueItem: "device-maintenance-menu-flush-queue-item",
    Reboot: "device-maintenance-menu-reboot",
    RebootItem: "device-maintenance-menu-reboot-item",
    SendLogs: "device-maintenance-menu-send-logs",
    SendLogsItem: "device-maintenance-menu-send-logs-item",
    ResetAudio: "device-maintenance-menu-reset-audio",
    ResetAudioItem: "device-maintenance-menu-reset-audio-item",
    ResetKey: "device-maintenance-menu-reset-key",
    ResetKeyItem: "device-maintenance-menu-reset-key-item",
    AdvancedDeviceSettings: "device-maintenance-menu-advanced-device-settings",
    AdvancedDeviceSettingsItem: "device-maintenance-menu-advanced-device-settings-item",
    UpdateFirmware: "device-maintenance-menu-update-firmware",
    UpdateFirmwareItem: "device-maintenance-menu-update-firmware-item",
    OtaMonitor: "device-maintenance-menu-ota-monitor",
    OtaMonitorItem: "device-maintenance-menu-ota-monitor-item",
    ResetDevice: "device-maintenance-menu-reset-device",
    ResetDeviceItem: "device-maintenance-menu-reset-device-item",
    Mailbox: "device-maintenance-menu-mailbox",
    MailboxItem: "device-maintenance-menu-mailbox-item",
  },
  DeviceActionsDialog: {
    SaveChanges: "device-actions-dialog-save-changes",
    DiscardChanges: "device-actions-dialog-discard-changes",
    CloseDialog: "device-actions-dialog-close-dialog",
  },
  DeviceActivateDialog: {
    CloseButton: "device-activate-dialog-close-button",
    ContinueButton: "device-activate-dialog-continue-button",
    DiscardButton: "device-activate-dialog-discard-button",
    KeepExistingPatientCheckbox: "device-activate-dialog-keep-existing-patient",
  },
  DeviceOtaMonitorDialog: {
    CloseButton: "device-ota-monitor-dialog-close-button",
    CloseDialog: "device-ota-monitor-dialog-close-dialog",
  },
  DeviceOtaUpdateDialog: {
    CloseButton: "device-ota-update-dialog-close-button",
    UpdateButton: "device-ota-update-dialog-update-button",
    CloseDialog: "device-ota-update-dialog-close-dialog",
    OpenOtaPackageSelection: "device-ota-update-dialog-open-ota-package-selection",
  },
  DeviceAdvancedSettingsDialog: {
    CloseButton: "device-advanced-settings-dialog-close-button",
    SaveChanges: "device-advanced-settings-dialog-save-changes",
    CloseDialog: "device-advanced-settings-dialog-close-dialog",
    ConfirmRemoveDialogClose: "device-advanced-settings-dialog-confirm-remove-dialog-close",
    ConfirmRemoveDialogCancel: "device-advanced-settings-dialog-confirm-remove-dialog-cancel",
    ConfirmRemoveDialogConfirm: "device-advanced-settings-dialog-confirm-remove-dialog-confirm",
    SaveChangesDialogClose: "device-advanced-settings-dialog-save-changes-dialog-close",
    SaveChangesDialogCancel: "device-advanced-settings-dialog-save-changes-dialog-cancel",
    SaveChangesDialogSave: "device-advanced-settings-dialog-save-changes-dialog-save",
  },
  DeviceMailboxDialog: {
    CloseButton: "device-mailbox-dialog-close-button",
    CloseDialog: "device-mailbox-dialog-close-dialog",
    PurgeButton: "device-mailbox-dialog-purge-button",
    RefreshButton: "device-mailbox-dialog-refresh-button",
    PurgeConfirm: "device-mailbox-dialog-purge-confirm",
    PurgeCancel: "device-mailbox-dialog-purge-cancel",
    TableHeader: "device-mailbox-dialog-table-header",
    TableRow: "device-mailbox-dialog-table-row",
    TableColumnCreated: "device-mailbox-dialog-table-column-created",
    TableColumnTopic: "device-mailbox-dialog-table-column-topic",
    TableColumnRead: "device-mailbox-dialog-table-column-read",
    TableColumnStatus: "device-mailbox-dialog-table-column-status",
    TableColumnBody: "device-mailbox-dialog-table-column-body",
  },
  DeviceEverThereAccountsDialog: {
    Dialog: "device-everthere-accounts-dialog",
    CloseButton: "device-everthere-accounts-dialog-close-button",
    MembersTable: "device-everthere-accounts-dialog-members-table",
    EmptyRow: "device-everthere-accounts-dialog-members-table-empty-row",
    DataRow: "device-everthere-accounts-dialog-members-table-data-row",
    UserEmailCell: "device-everthere-accounts-dialog-members-table-user-email-cell",
    ManagerCell: "device-everthere-accounts-dialog-members-table-manager-cell",
    WearerCell: "device-everthere-accounts-dialog-members-table-wearer-cell",
    InvitationStatusCell: "device-everthere-accounts-dialog-members-table-invitation-status-cell",
    InviteButton: "device-everthere-accounts-dialog-invite-button",
    ActionCloseButton: "device-everthere-accounts-dialog-action-close-button",
  },
  DeviceEverThereNewMemberDialog: {
    Dialog: "device-everthere-new-member-dialog",
    CloseButton: "device-everthere-new-member-dialog-close-button",
    EmailInput: "device-everthere-new-member-dialog-email-input",
    SendInviteButton: "device-everthere-new-member-dialog-send-invite-button",
    ActionCloseButton: "device-everthere-new-member-dialog-action-close-button",
  },
  DeviceEverThereUserMenu: {
    OpenButton: "device-everthere-user-menu-open",
    MakeUserWearer: "device-everthere-user-menu-make-user-wearer",
    RemoveDeviceFromUser: "device-everthere-user-menu-remove-device-from-user",
    MakeUserTeamManager: "device-everthere-user-menu-make-user-team-manager",
    ResendInvitation: "device-everthere-user-menu-resend-invitation",
    RemoveUser: "device-everthere-user-menu-remove-user",
  },
  DeviceEverThereMakeMemberAWearerDialog: {
    Dialog: "device-everthere-make-member-a-wearer-dialog",
    CloseButton: "device-everthere-make-member-a-wearer-dialog-close-button",
    ConfirmButton: "device-everthere-make-member-a-wearer-dialog-confirm-button",
    ActionCloseButton: "device-everthere-make-member-a-wearer-dialog-action-close-button",
  },
  DeviceEverThereRemoveDeviceFromMemberDialog: {
    Dialog: "device-everthere-remove-device-from-user-dialog",
    CloseButton: "device-everthere-remove-device-from-user-dialog-close-button",
    ConfirmButton: "device-everthere-remove-device-from-user-dialog-confirm-button",
    ActionCloseButton: "device-everthere-remove-device-from-user-dialog-action-close-button",
  },
  DeviceEverThereRemoveMemberFromTeamDialog: {
    Dialog: "device-everthere-remove-member-from-team-dialog",
    CloseButton: "device-everthere-remove-member-from-team-dialog-close-button",
    ConfirmButton: "device-everthere-remove-member-from-team-dialog-confirm-button",
    ActionCloseButton: "device-everthere-remove-member-from-team-dialog-action-close-button",
  },
  DeviceEverThereMakeMemberATeamManagerDialog: {
    Dialog: "device-everthere-make-member-a-team-manager-dialog",
    CloseButton: "device-everthere-make-member-a-team-manager-dialog-close-button",
    ConfirmButton: "device-everthere-make-member-a-team-manager-dialog-confirm-button",
    ActionCloseButton: "device-everthere-make-member-a-team-manager-dialog-action-close-button",
  },
  DeviceDetailsCard: {
    DeviceName: "device-details-device-name",
    DeviceImei: "device-details-device-imei",
    DeviceRealm: "device-details-device-realm",
    DeviceDetailsEdit: "device-details-edit",
  },
  DeviceDetailsDialog: {
    Dialog: "device-details-dialog",
    CloseButton: "device-details-dialog-close-button",
    SubmitButton: "device-details-dialog-submit-button",
    CancelButton: "device-details-dialog-cancel-button",
    DeviceNameInput: "device-details-dialog-device-name-input",
    ImeiField: "device-details-dialog-imei-field",
    IccidIdField: "device-details-dialog-iccid-field",
    SerialNumberField: "device-details-dialog-serial-number-field",
    NetworkOperatorSelect: "device-details-dialog-network-operator-select",
    RealmSelect: "device-details-dialog-realm-select",
    StatusField: "device-details-dialog-status-field",
    HardwareVersionField: "device-details-dialog-firmware-version-field",
    CallInServiceInput: "device-details-dialog-call-in-service-input",
    PatientNameField: "device-details-dialog-patient-name-field",
    CustomerIdField: "device-details-dialog-customer-id-field",
  },
  DeviceEverThereCard: {
    DeviceEverThereEdit: "device-ever-there-edit",
    UserEmail: "device-ever-there-user-email",
    Manager: "device-ever-there-manager",
  },
  DeviceSettingsCard: {
    DeviceSettingsEdit: "device-settings-edit",
    InactivityNotifications: "device-settings-inactivity-notifications",
    InactiveInCharger: "device-settings-inactive-in-charger",
    InactiveOutOfCharger: "device-settings-inactive-out-of-charger",
    FallDetection: "device-settings-fall-detection",
    AudioPromptVolume: "device-settings-audio-prompt-volume",
    CallVolume: "device-settings-call-volume",
    CallInService: "device-settings-call-in-service",
    PeriodicIndicatorFeedback: "device-settings-periodic-indicator-feedback",
    TapIndicator: "device-settings-tap-indicator",
    SilentMode: "device-settings-silent-mode",
    DemoMode: "device-settings-demo-mode",
    Variant: "device-settings-variant",
  },
  DevicePeripheralCard: {
    PeripheralSerial: "device-peripheral-serial",
    State: "device-peripheral-state",
    EditButton: "device-peripheral-edit",
  },
  DeviceSettingsDialog: {
    Dialog: "device-settings-dialog",
    CloseButton: "device-settings-dialog-close-button",
    SubmitButton: "device-settings-dialog-submit-button",
    CancelButton: "device-settings-dialog-cancel-button",
    FallDetection: "device-settings-dialog-fall-detection",
    SilentMode: "device-settings-dialog-silent-mode",
    DemoMode: "device-settings-dialog-demo-mode",
    TapIndicator: "device-settings-dialog-tap-indicator",
    InactivityNotifications: "device-settings-dialog-inactivity-notifications",
    InactiveInCharger: "device-settings-dialog-inactive-in-charger",
    InactiveOutOfCharger: "device-settings-dialog-inactive-out-of-charger",
    PeriodicIndicatorFeedback: "device-settings-dialog-periodic-indicator-feedback",
    AudioPromptVolume: "device-settings-dialog-audio-prompt-volume",
    AudioPromptVolumeInput: "device-settings-dialog-audio-prompt-volume-input",
    CallVolume: "device-settings-dialog-call-volume",
    CallVolumeInput: "device-settings-dialog-call-volume-input",
    CallInService1: "device-settings-dialog-call-in-service-1",
    CallInService2: "device-settings-dialog-call-in-service-2",
    CallInService3: "device-settings-dialog-call-in-service-3",
    Variant: "device-settings-dialog-variant",
  },
  DevicePeripheralsDialog: {
    Dialog: "device-peripherals-dialog",
    CloseButton: "device-peripherals-dialog-close-button",
    CancelButton: "device-peripherals-dialog-cancel-button",
    AttachButton: "device-peripherals-dialog-attach-button",
    AttachNewCompanionPendantButton: "device-peripherals-dialog-attach-new-companion-pendant-button",
    AddMHealthDeviceButton: "device-peripherals-dialog-add-mhealth-device-button",
    PeripheralTypeSelect: "device-peripherals-dialog-peripheral-type-select",
  },
  DevicePeripheralsRemoveConfirmationDialog: {
    Dialog: "device-peripherals-remove-confirmation-dialog",
    CloseButton: "device-peripherals-remove-confirmation-dialog-close-button",
    ConfirmButton: "device-peripherals-remove-confirmation-dialog-confirm-button",
    ActionCancelButton: "device-peripherals-remove-confirmation-dialog-action-cancel-button",
  },
  PeripheralsTable: {
    TableRow: "table-row-element",
    PeripheralState: "state-cell",
    PeripheralSerial: "serial-cell",
    PeripheralType: "type-cell",
    PeripheralAction: "action-cell",
  },
  InactiveInChargerMenu: {
    Off: "inactive-in-charger-off",
    TwelveHours: "inactive-in-charger-12-hours",
    TwentyFourHours: "inactive-in-charger-24-hours",
    FortyEightHours: "inactive-in-charger-48-hours",
    SeventyTwoHours: "inactive-in-charger-72-hours",
  },
  CallHistoryTable: {
    Table: "call-history-table",
    TableRow: "call-history-table-row",
    Timestamp: "call-history-timestamp",
    Imei: "call-history-imei",
    CallerIdNumber: "call-history-caller-id-number",
    DeviceName: "call-history-device-name",
    Type: "call-history-type",
    AnsweredBy: "call-history-answered-by",
    Duration: "call-history-duration",
  },
  ActiveCalls: {
    BackButton: "active-calls-back-button",
    HangUpButton: "active-calls-hang-up-button",
    CallMeBackButton: "active-calls-call-me-back-button",
    WearerCard: "active-calls-details-card",
    DeviceCard: "active-calls-device-card",
    EventCard: "active-calls-event-card",
    OrientationActivityCard: "active-calls-orientation-activity-card",
    LocationHistoryCard: "active-calls-location-history-card",
    MapCard: "active-calls-map-card",
  },
  ActiveCallsHangUpConfirmationDialog: {
    Dialog: "active-calls-hang-up-confirmation-dialog",
    CloseButton: "active-calls-hang-up-confirmation-close-button",
    ConfirmButton: "active-calls-hang-up-confirmation-confirm-button",
    ActionCancelButton: "active-calls-hang-up-confirmation-action-cancel-button",
  },
  ActiveCallsMissedCallConfirmationDialog: {
    YesButton: "active-calls-missed-call-confirmation-yes-button",
    NoButton: "active-calls-missed-call-confirmation-no-button",
    Dialog: "active-calls-missed-call-confirmation-dialog",
    CloseButton: "active-calls-missed-call-confirmation-close-button",
  },
  ActiveCallsOrientationActivityInfoDialog: {
    Dialog: "active-calls-orientation-activity-info-dialog",
    CloseButton: "active-calls-orientation-activity-info-close-button",
    OkButton: "active-calls-orientation-activity-info-ok-button",
  },
  MissedFallReportDialog: {
    Dialog: "missed-fall-report-dialog",
    CloseButton: "missed-fall-report-dialog-close-button",
    SubmitButton: "missed-fall-report-dialog-submit-button",
    ActionCancelButton: "missed-fall-report-dialog-action-cancel-button",
  },
  IncomingCallsTable: {
    Table: "incoming-calls-table",
  },
  MonitoringPage: {
    IncomingCallsTab: "incoming-calls-tab",
    CallHistoryTab: "call-history-tab",
  },
  ActivateNewDevicesDialog: {
    Dialog: "activate-new-devices-dialog",
    CloseButton: "activate-new-devices-dialog-close-button",
    CancelButton: "activate-new-devices-dialog-cancel-button",
    ContinueButton: "activate-new-devices-dialog-continue-button",
    ImeiPrefixSelect: "activate-new-devices-dialog-imei-prefix-select",
    RealmSelect: "activate-new-devices-dialog-realm-select",
  },
  EventDetailsDialog: {
    Dialog: "",
    CloseButton: "",
  },
  OtaPackages: {
    Table: "ota-packages-table",
    TableRow: "ota-packages-table-row",
  },
  CreateOtaPackageDialog: {
    Dialog: "create-ota-package-dialog",
    CloseButton: "create-ota-package-dialog-close-button",
    ReleaseInput: "create-ota-package-release-input",
    OpenDeviceTypeSelection: "create-ota-package-device-type-selection",
    OpenVariantSelection: "create-ota-package-variant-selection",
    NameInput: "create-ota-package-name-input",
    OpenCategorySelection: "create-ota-package-category-selection",
    SubmitButton: "create-ota-package-submit-button",
    ActionCancelButton: "create-ota-package-action-cancel-button",
  },
  ConfirmDeleteOtaPackageDialog: {
    Dialog: "confirm-delete-ota-package-dialog",
    CloseButton: "confirm-delete-ota-package-dialog-close-button",
    ConfirmButton: "confirm-delete-ota-package-dialog-confirm-button",
    CancelButton: "confirm-delete-ota-package-dialog-cancel-button",
  },
  SendBulkOtaPackageDialog: {
    Dialog: "send-bulk-ota-package-dialog",
    CloseButton: "send-bulk-ota-package-dialog-close-button",
    OpenDeviceTypeSelection: "send-bulk-ota-package-device-type-selection",
    OpenOtaPackageSelection: "send-bulk-ota-package-variant-selection",
    SendButton: "send-bulk-ota-package-send-button",
    ActionCancelButton: "send-bulk-ota-package-action-cancel-button",
  },
  DeviceReports: {
    Search: "device-reports-search",
    CopyButton: "device-reports-copy-button",
    DownloadButton: "device-reports-download-button",
    EnterImeisButton: "device-reports-enter-imeis-button",
  },
  DeviceReportsTable: {
    Table: "device-reports-table",
    TableRow: "table-row-element",
  },
  EnterImeisDialog: {
    Dialog: "enter-imeis-dialog",
    EnterImeis: "imei-input-enter-imeis",
    UploadImeis: "imei-input-upload-imeis",
    ImeiInput: "imei-input",
    CloseButton: "enter-imeis-dialog-close-button",
    SubmitButton: "enter-imeis-dialog-confirm-button",
    CancelButton: "enter-imeis-dialog-cancel-button",
  },
  AdminMenu: {
    DeviceReports: "admin-device-reports",
    DeviceBulkActions: "admin-device-bulk-actions",
    OtaPackages: "admin-ota-packages",
  },
  AdminOtaPackages: {
    AddNewOtaPackage: "admin-add-new-ota-package-button",
    SendBulkOtas: "admin-send-bulk-otas-button",
  },
  RealmPicker: {
    RealmInput: "realm-picker-realm-input",
  },
};

export default TestIds;
