/* Copyright */
import { Paths } from "./utils";
import { Maybe } from "@sade/data-access";

export interface SsoPath {
  provider: string;
  redirectUrl: string;
}

let ssoHolder: Maybe<SsoPath> = undefined;

export const getRedirectUrl = (location: Location, providerName: string): string => {
  return `${location.origin}/sso/${providerName}`;
};

export const setSsoPathHolder = (sso: Maybe<SsoPath>): void => {
  ssoHolder = sso;
};

export const getSsoPath = (): Maybe<SsoPath> => {
  return ssoHolder;
};

export function getPath(path: Paths): string {
  if ([Paths.SUPPORT, Paths.WHERE_TO_BUY, Paths.PRIVACY, Paths.TERMS_OF_USE].includes(path)) {
    return path;
  }
  return ssoHolder ? `/sso/${ssoHolder.provider}/${path.toString()}` : `/${path}`;
}

export function parseIdpFromPath(): Maybe<SsoPath> {
  const location = window.location.pathname.split("/");
  if (location.length >= 3 && location[1] === "sso") {
    const provider = location[2];
    return { provider, redirectUrl: getRedirectUrl(window.location, provider) };
  }
  return undefined;
}
