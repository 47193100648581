/* Copyright */
import React from "react";
import { Tabs, Tab } from "@mui/material";
import { UserType } from "@sade/data-access";
import { AccountFilters } from "../../../utils/utils";
import { AccountCircleOutlined, PeopleOutlined } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type AccountsViewTabsProps = {
  isLoading: boolean;
  filters: AccountFilters;
  handleFilterChange: (data: Partial<AccountFilters>) => void;
};

const AccountsViewTabs: React.FC<AccountsViewTabsProps> = ({ isLoading, filters, handleFilterChange }) => {
  return (
    <Tabs
      value={filters.userType}
      onChange={(e, v): void => {
        handleFilterChange({ userType: v });
      }}
      sx={{
        minHeight: "24px",
      }}
    >
      <Tab
        icon={<AccountCircleOutlined />}
        label={translations.accounts.inputs.dealerAccounts()}
        value={UserType.WebApp}
        iconPosition="start"
        disabled={isLoading}
        data-testid={TestIds.Accounts.DealerAccountsTab}
        sx={{
          minHeight: "24px",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          fontStyle: "normal",
          lineHeight: "24px" /* 171.429% */,
          letterSpacing: "0.4px",
        }}
      />
      <Tab
        icon={<PeopleOutlined />}
        label={translations.accounts.inputs.userAccounts()}
        value={UserType.EverThere}
        iconPosition="start"
        disabled={isLoading}
        data-testid={TestIds.Accounts.EverThereAccountsTab}
        sx={{
          minHeight: "24px",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          fontStyle: "normal",
          lineHeight: "24px" /* 171.429% */,
          letterSpacing: "0.4px",
        }}
      />
    </Tabs>
  );
};

export default AccountsViewTabs;
