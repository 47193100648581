/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export var EventType;
(function (EventType) {
    EventType["Activation"] = "Activation";
    EventType["BatteryLow"] = "BatteryLow";
    EventType["CallCancelled"] = "CallCancelled";
    EventType["ChargeChange"] = "ChargeChange";
    EventType["Classify"] = "Classify";
    EventType["Deactivation"] = "Deactivation";
    EventType["Debug"] = "Debug";
    EventType["DemoModeFDDisabled"] = "DemoModeFDDisabled";
    EventType["DemoModeFDEnabled"] = "DemoModeFDEnabled";
    EventType["Disconnected"] = "Disconnected";
    EventType["FailActivation"] = "FailActivation";
    EventType["Fall"] = "Fall";
    EventType["LocationFail"] = "LocationFail";
    EventType["LocationFix"] = "LocationFix";
    EventType["Logfile"] = "Logfile";
    EventType["LongChargeAlarm"] = "LongChargeAlarm";
    EventType["LowActivityAlarm"] = "LowActivityAlarm";
    EventType["MagCalibration"] = "MagCalibration";
    EventType["MedicalDevice"] = "MedicalDevice";
    EventType["MedicalDevicePaired"] = "MedicalDevicePaired";
    EventType["MedicalDevicePairFailure"] = "MedicalDevicePairFailure";
    EventType["MedicalDeviceReading"] = "MedicalDeviceReading";
    EventType["MedicalDeviceUnpaired"] = "MedicalDeviceUnpaired";
    EventType["NetworkChange"] = "NetworkChange";
    EventType["Normal"] = "Normal";
    EventType["Note"] = "Note";
    EventType["On"] = "On";
    EventType["PendantBatteryLow"] = "PendantBatteryLow";
    EventType["PendantLinked"] = "PendantLinked";
    EventType["PendantOutOfRange"] = "PendantOutOfRange";
    EventType["PendantPairFail"] = "PendantPairFail";
    EventType["PendantPairSuccess"] = "PendantPairSuccess";
    EventType["PendantReconnection"] = "PendantReconnection";
    EventType["PendantUnpaired"] = "PendantUnpaired";
    EventType["PowerOffOnLowBattery"] = "PowerOffOnLowBattery";
    EventType["SilentModeDisabled"] = "SilentModeDisabled";
    EventType["SilentModeEnabled"] = "SilentModeEnabled";
    EventType["Status"] = "Status";
    EventType["StatusUpdate"] = "StatusUpdate";
})(EventType || (EventType = {}));
export var NotificationState;
(function (NotificationState) {
    NotificationState["MissingEndpoint"] = "MISSING_ENDPOINT";
    NotificationState["Ok"] = "OK";
})(NotificationState || (NotificationState = {}));
export var EventState;
(function (EventState) {
    EventState["CallDropped"] = "CallDropped";
    EventState["Calling"] = "Calling";
    EventState["Cancelled"] = "Cancelled";
    EventState["ClassADL0"] = "ClassADL0";
    EventState["ClassADL1"] = "ClassADL1";
    EventState["ClassADL2"] = "ClassADL2";
    EventState["ClassADL3"] = "ClassADL3";
    EventState["ClassADL4"] = "ClassADL4";
    EventState["ClassADL5"] = "ClassADL5";
    EventState["ClassADL6"] = "ClassADL6";
    EventState["ClassADL7"] = "ClassADL7";
    EventState["ClassADL8"] = "ClassADL8";
    EventState["ClassADL9"] = "ClassADL9";
    EventState["ClassERR"] = "ClassERR";
    EventState["ClassFall"] = "ClassFall";
    EventState["ClassINCO"] = "ClassINCO";
    EventState["ClassUNK"] = "ClassUNK";
    EventState["Complete"] = "Complete";
})(EventState || (EventState = {}));
export var LocationMethod;
(function (LocationMethod) {
    LocationMethod["Satellite"] = "Satellite";
    LocationMethod["Network"] = "Network";
    LocationMethod["WiFi"] = "WiFi";
})(LocationMethod || (LocationMethod = {}));
export var FallDetectionDisposition;
(function (FallDetectionDisposition) {
    FallDetectionDisposition["FalseNegative"] = "FalseNegative";
    FallDetectionDisposition["FalsePositive"] = "FalsePositive";
    FallDetectionDisposition["Correct"] = "Correct";
})(FallDetectionDisposition || (FallDetectionDisposition = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["Email"] = "EMAIL";
    NotificationType["Sms"] = "SMS";
    NotificationType["Push"] = "PUSH";
})(NotificationType || (NotificationType = {}));
export var MeasurementPosition;
(function (MeasurementPosition) {
    MeasurementPosition["Ear"] = "Ear";
    MeasurementPosition["Head"] = "Head";
    MeasurementPosition["Object"] = "Object";
})(MeasurementPosition || (MeasurementPosition = {}));
export var PulseStatus;
(function (PulseStatus) {
    PulseStatus[PulseStatus["InRange"] = 0] = "InRange";
    PulseStatus[PulseStatus["TooHight"] = 1] = "TooHight";
    PulseStatus[PulseStatus["TooLow"] = 2] = "TooLow";
})(PulseStatus || (PulseStatus = {}));
export class Event {
    constructor(params) {
        this.id = params.id;
        this.patientId = params.patientId;
        this.deviceId = params.deviceId;
        this.deviceName = params.deviceName;
        this.organizationId = params.organizationId;
        this.type = params.type;
        this.timestamp = new Date(params.timestamp);
        this.updatedTimestamp = new Date(params.updatedTimestamp);
        this.state = params.state;
        this.metadata = params.metadata;
        this.latitude = params.latitude;
        this.longitude = params.longitude;
        this.altitude = params.altitude;
        this.accuracy = params.accuracy;
        this.fixTime = params.fixTime ? new Date(params.fixTime) : undefined;
        this.method = params.method;
        this.address = params.address;
        this.callerIdNumber = params.callerIdNumber;
        this.callAnsweredBy = params.callAnsweredBy;
        this.callAnsweredAt = params.callAnsweredAt ? new Date(params.callAnsweredAt) : undefined;
        this.fallDetectionDisposition = params.fallDetectionDisposition;
        this.callEndedAt = params.callEndedAt ? new Date(params.callEndedAt) : undefined;
    }
}
export function eventIdentitiesMatch(e1, e2) {
    return e1.patientId === e2.patientId && e1.id === e2.id;
}
export function eventOrdering(e1, e2) {
    const at = e1.timestamp.getTime();
    const bt = e2.timestamp.getTime();
    if (at === undefined && bt === undefined)
        return 0;
    if (at === undefined)
        return -1;
    if (bt === undefined)
        return 1;
    return at - bt;
}
export function invertedEventOrdering(e1, e2) {
    const at = e1.timestamp.getTime();
    const bt = e2.timestamp.getTime();
    if (at === undefined && bt === undefined)
        return 0;
    if (at === undefined)
        return 1;
    if (bt === undefined)
        return -1;
    return bt - at;
}
export function callEventTypeList() {
    return [EventType.Normal, EventType.Fall];
}
