/*Copyright*/
import { TableCell, TableRow } from "@mui/material";
import { Event, EventType } from "@sade/data-access";
import React from "react";
import { formatTimestamp } from "../../../utils/utils";
import EventChip from "../event-chip";

type IncomingCallsTableRowProps = {
  event: Event;
  userHasAccessToActiveCalls: boolean;
  onRowClick: (deviceId: string, eventId: string, eventType: EventType, patientId: string) => void;
};

const IncomingCallsTableRow: React.FC<IncomingCallsTableRowProps> = ({
  event,
  userHasAccessToActiveCalls,
  onRowClick,
}) => {
  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor: event.callAnsweredBy ? "inherit" : "#EBF1F6",
          cursor: userHasAccessToActiveCalls ? "pointer" : "default",
        }}
        onClick={(): void => onRowClick(event.deviceId, event.id, event.type, event.patientId)}
      >
        <TableCell>{formatTimestamp(event.timestamp)}</TableCell>
        <TableCell>{event.deviceId}</TableCell>
        <TableCell>{event.callerIdNumber ?? "-"}</TableCell>
        <TableCell>{event.deviceName ?? "-"}</TableCell>
        <TableCell>
          <EventChip event={event} />
        </TableCell>
        <TableCell>{event.callAnsweredBy ?? "-"}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default IncomingCallsTableRow;
