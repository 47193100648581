/* Copyright */

import React from "react";

React;

/**
 * This method can be used to make word wrap from specific character.
 * @param word word to wrap
 * @param character character to wrap from
 * @returns array of JSX elements and strings that can be used to render the wrapped word
 */
export const wrapWordFromCharacter = (word: string, character: string): (JSX.Element | string)[] => {
  return word.split(character).reduce((acc: (JSX.Element | string)[], part, index, array) => {
    acc.push(part + (index < array.length - 1 ? character : ""));
    if (index < array.length - 1) {
      acc.push(<wbr key={index} />);
    }
    return acc;
  }, []);
};
