/* Copyright */
import * as React from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { AuthWrapper, isErrorWithCode } from "@sade/data-access";
import logo from "../../assets/images/Numera-Nice-brand-Logo-color.svg";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SeverityType } from "../../context/snackbar-context";
import TestIds from "../../test-ids/test-ids";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { LoginFormProps } from "../../utils/utils";
import { useParams } from "react-router-dom";

export type SnackbarItemType = {
  message: string;
};

const FederatedLoginForm: React.FC<LoginFormProps> = ({ logout }) => {
  const { showSnackbar } = useSnackbar();
  const { setAuthenticatedUser } = useAuthenticatedUser();
  const { sso } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    generalError: "",
    emailError: "",
    passwordError: "",
  });

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    }
  }, [errors, showSnackbar]);

  const clearErrors = async (): Promise<void> => {
    const updatedErrors = { generalError: "", emailError: "", passwordError: "" };
    setErrors(updatedErrors);
  };

  const handleErrors = (reason: string, _message?: string): void => {
    switch (reason) {
      // TODO: debug error messages, for example account disabled
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          emailError: "",
          passwordError: "",
        });
        break;
    }
  };

  const handleLoginClick = async (): Promise<void> => {
    await clearErrors();

    try {
      setIsLoading(true);
      const user = await AuthWrapper.federatedLogIn(sso);
      if (user) {
        setAuthenticatedUser(user);
      }
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = isLoading || !sso;

  return (
    <Box className="page-container">
      <img src={logo} alt="Numera Nice brand logo" className="image" />
      <Typography variant="h5" className="title">
        {logout ? translations.logOut.texts.title() : translations.logIn.texts.title()}
      </Typography>
      <Typography variant="body1" className="title">
        {logout ? translations.logOut.texts.subtitleSso() : translations.logIn.texts.subtitleSso()}
      </Typography>
      <Button
        data-testid={TestIds.LoginView.Submit}
        size="large"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={handleLoginClick}
        className={"dense-button"}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
      >
        {translations.logIn.buttons.logInSso()}
      </Button>
    </Box>
  );
};

export default FederatedLoginForm;
