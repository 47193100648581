/* Copyright */
import { Box } from "@mui/material";
import {
  BackendFactory,
  Device,
  DeviceAttributeName,
  Event,
  EventObserver,
  EventSet,
  EventType,
  LocationMethod,
  Maybe,
  Nullable,
  Patient,
  invertedEventOrdering,
} from "@sade/data-access";
import { sub } from "date-fns";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { DeviceEvent, formatEvent } from "../../utils/eventUtils";
import DeviceEventsLocationsViewHeader from "./device-events-locations-header";
import DeviceEventsLocationsViewLeftContent from "./device-events-locations-view-left-content/device-events-locations-view-left-content";
import DeviceEventsLocationsViewRightContent from "./device-events-locations-view-right-content/device-events-locations-view-right-content";
import EventDetailsDialog, { EventDetailsDialogType } from "./event-details/event-details-dialog";

const MINIMUM_VIEW_WIDTH = "840px";
const EVENT_LIMIT = 30;

const DeviceEventsLocationsView: React.FC = () => {
  const { deviceId } = useParams();

  const { showSnackbar } = useSnackbar();
  const { authenticatedUserRole } = useAuthenticatedUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFetchingMore, setIsFetchingMore] = React.useState<boolean>(false);
  const [events, setEvents] = React.useState<Maybe<Event[]>>();
  const [allEvents, setAllEvents] = React.useState<DeviceEvent[]>();
  const [groupsToShow, setGroupsToShow] = React.useState<Map<number, DeviceEvent[]>>();
  const [selectedEventTypes, setSelectedEventTypes] = React.useState<Maybe<EventType[]>>();
  const [selectedStartDate, setSelectedStartDate] = React.useState<Nullable<Date>>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Nullable<Date>>(null);
  const [selectedFixes, setSelectedFixes] = React.useState({ satellite: true, wifi: true, network: true });
  const [showLocationUpdates, setShowLocationUpdates] = React.useState<boolean>(true);
  const [isEventDetailsDialogOpen, setIsEventDetailsDialogOpen] = React.useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = React.useState<Maybe<Event>>(undefined);
  const [dialogType, setDialogType] = React.useState<EventDetailsDialogType>("detailed");

  const [device, setDevice] = React.useState<Device>();

  const minFilterDate = sub(new Date(), { days: 60 });

  const listEl = React.useRef<HTMLDivElement>(null);
  const eventSet = React.useRef<Maybe<EventSet>>();
  const eventObserverRef = React.useRef<EventObserver>({
    onEventSetUpdate: (_eventSet: EventSet): void => void 0,
    onEventListUpdate: (events: Event[]): void => setEvents([...events]),
  });

  const fetchEventSet = React.useCallback(
    async (patient: Patient) => {
      setIsLoading(true);
      try {
        const parameters = {
          limit: EVENT_LIMIT,
        };

        if (eventSet.current?.isObservedBy(eventObserverRef.current)) {
          eventSet.current?.removeObserver(eventObserverRef.current);
        }

        const result = await patient?.getEventSet(parameters);
        eventSet.current = result;
        eventSet.current?.addObserver(eventObserverRef.current);
        setEvents(result?.getEvents());
      } catch (error) {
        showSnackbar(translations.common.texts.generalDataFetchError(), SeverityType.Error);
      } finally {
        setIsLoading(false);
      }
    },

    [showSnackbar]
  );

  // Grouping data by date
  const groupEvents = (events: DeviceEvent[]): Map<number, DeviceEvent[]> => {
    const groupedEvents =
      events?.reduce((group: Map<number, Array<DeviceEvent>>, event: DeviceEvent) => {
        // Convert timestamp to date-only-timestamp, strip time information
        const timeStamp = new Date(event.eventTimestamp.toDateString()).getTime();
        group.has(timeStamp) || group.set(timeStamp, []);
        group.get(timeStamp)?.push(event);
        return group;
      }, new Map<number, Array<DeviceEvent>>()) ?? new Map<number, Array<DeviceEvent>>();

    return groupedEvents;
  };

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (!deviceId) return;

      const backend = BackendFactory.getBackend();
      const organizationBackend = BackendFactory.getOrganizationBackend();

      const device = await backend.getDevice(deviceId);
      if (!device) return;

      const patientId = device.getAttribute(DeviceAttributeName.patientId);
      if (!patientId) return;

      const patient = await organizationBackend.getPatient(patientId);
      if (!patient) return;

      await fetchEventSet(patient);
      setDevice(device);
    })();
    // Perform actions upon navigation to this page
  }, [deviceId, fetchEventSet]); // Only re-run the effect if the URL changes

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (listEl.current) {
        listEl.current.scrollTop = 0;
      }

      const parameters = {
        limit: EVENT_LIMIT,
        startTimestamp: selectedStartDate ?? undefined,
        endTimestamp: selectedEndDate
          ? new Date(selectedEndDate.setHours(23, 59, 59, 999))
          : selectedStartDate
          ? new Date()
          : undefined,
        eventTypes: selectedEventTypes?.length ? [...selectedEventTypes, EventType.LocationFix] : undefined,
      };

      eventSet.current?.changeQueryParams(parameters);
      await eventSet.current?.fetch();
      setEvents(eventSet.current?.getEvents());
    })();
  }, [selectedStartDate, selectedEndDate, selectedEventTypes]);

  React.useEffect(() => {
    // Correct format
    const formattedEvents = events
      ?.sort(invertedEventOrdering)
      .map((event) => formatEvent(event))
      .filter(
        (event) =>
          event?.permissionRoles.includes(authenticatedUserRole?.identifier ?? "") &&
          event.eventName !== "UnsupportedEvent"
      );

    // TODO: We should really do this on events-map component, but it requires that we move grouping to proper child component
    const firstEventWithLocation = formattedEvents?.find((e) => e.lat != null && e.lng != null);
    if (firstEventWithLocation) firstEventWithLocation.isLatest = true;
    setAllEvents(formattedEvents);

    // Filtering
    const filteredEvents = formattedEvents?.filter((event) => {
      const LocationFixFilter = !showLocationUpdates ? event.eventType !== EventType.LocationFix : event;
      const SatelliteFixFilter = selectedFixes.satellite || !event.method?.includes(LocationMethod.Satellite);
      const WifiFixFilter = selectedFixes.wifi || event.method !== LocationMethod.WiFi;
      const NetworkFixFilter = selectedFixes.network || event.method !== LocationMethod.Network;
      return LocationFixFilter && SatelliteFixFilter && WifiFixFilter && NetworkFixFilter;
    });

    // TODO: We should do grouping in event-list component, since its the only place where it is needed, and now we need to do ungrouping in events-map component.
    setGroupsToShow(groupEvents(filteredEvents ?? []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, showLocationUpdates, selectedFixes]);

  const handleShowLocationUpdatesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowLocationUpdates(event.target.checked);
  };

  const handleSelectedEventTypesChange = (eventType: EventType): void => {
    setSelectedEventTypes((prevSelectedEventTypes) =>
      prevSelectedEventTypes
        ? prevSelectedEventTypes.includes(eventType)
          ? prevSelectedEventTypes.filter((type) => type !== eventType)
          : [...prevSelectedEventTypes, eventType]
        : [eventType]
    );
  };

  const handleChangeSelectedFix = (fix: string): void => {
    setSelectedFixes({
      satellite: fix === LocationMethod.Satellite ? !selectedFixes.satellite : selectedFixes.satellite,
      wifi: fix === LocationMethod.WiFi ? !selectedFixes.wifi : selectedFixes.wifi,
      network: fix === LocationMethod.Network ? !selectedFixes.network : selectedFixes.network,
    });
  };

  const loadMore = React.useCallback(
    () =>
      (async (): Promise<void> => {
        if (!eventSet || !eventSet.current?.getNextToken()) {
          return;
        }
        setIsFetchingMore(true);
        await eventSet.current?.fetch(eventSet.current.getNextToken());
        const events = eventSet.current?.getEvents();
        setEvents(events);
        setIsFetchingMore(false);
      })(),
    []
  );

  const scrollListener = React.useCallback(() => {
    const element = listEl.current;
    if (element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading && !isFetchingMore) {
        loadMore();
      }
    }
  }, [isLoading, isFetchingMore, loadMore]);

  const hasValues = (map: Map<number, DeviceEvent[]> | undefined): boolean => {
    return map instanceof Map && map.size > 0;
  };

  const handleOpenEventDetailsDialog = (eventId: string, dialogType: EventDetailsDialogType): void => {
    setSelectedEvent(events?.find((event) => event.id === eventId));
    setDialogType(dialogType);
    setIsEventDetailsDialogOpen(true);
  };

  const handleCloseEventDetailsDialog = (): void => {
    setSelectedEvent(undefined);
    setIsEventDetailsDialogOpen(false);
  };

  const isEventsToShow = !isLoading && allEvents && hasValues(groupsToShow);

  return (
    <Box sx={{ padding: "2rem" }}>
      <DeviceEventsLocationsViewHeader deviceId={deviceId ?? ""} />
      <Box sx={{ display: "flex", flex: 1, minWidth: MINIMUM_VIEW_WIDTH }}>
        <DeviceEventsLocationsViewLeftContent
          device={device}
          isLoading={isLoading}
          allEventsCount={allEvents?.length}
          groupsToShow={groupsToShow}
          selectedEventTypes={selectedEventTypes}
          onSelectEventTypesChange={handleSelectedEventTypesChange}
          isEventsToShow={isEventsToShow}
          minFilterDate={minFilterDate}
          selectedStartDate={selectedStartDate}
          onSelectStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          onSelectEndDate={setSelectedEndDate}
          listRef={listEl}
          onScroll={scrollListener}
          onOpenEventDetailsDialog={handleOpenEventDetailsDialog}
        />
        <DeviceEventsLocationsViewRightContent
          isLoading={isLoading}
          groupsToShow={groupsToShow}
          selectedFixes={selectedFixes}
          onChangeSelectedFix={handleChangeSelectedFix}
          showLocationUpdates={showLocationUpdates}
          onShowLocationUpdatesChange={handleShowLocationUpdatesChange}
          onOpenEventDetailsDialog={handleOpenEventDetailsDialog}
        />
        {selectedEvent && (
          <EventDetailsDialog
            open={isEventDetailsDialogOpen}
            handleClose={handleCloseEventDetailsDialog}
            event={selectedEvent}
            dialogType={dialogType}
          />
        )}
      </Box>
    </Box>
  );
};

export default DeviceEventsLocationsView;
