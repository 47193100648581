/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { DeviceListItem } from "../../../utils/utils";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import DevicesTableRow from "./devices-table-row";

type DevicesTableProps = {
  devices: DeviceListItem[];
  tableRef: React.RefObject<HTMLDivElement> | undefined;
  isLoading: boolean;
  handleRowClick: (deviceId: string) => void;
};

const DevicesTable: React.FC<DevicesTableProps> = ({ devices, tableRef, isLoading, handleRowClick }) => {
  return (
    <Box
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
      ref={tableRef}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.Devices.DevicesTable} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.status()}</TableCell>
              <TableCell>{translations.common.texts.deviceName()}</TableCell>
              <TableCell>{translations.common.texts.imei()}</TableCell>
              <TableCell>{translations.common.texts.callerId()}</TableCell>
              <TableCell>{translations.common.texts.iccid()}</TableCell>
              <TableCell>{translations.common.texts.realm()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.length > 0
              ? devices.map((device, index) => (
                  <DevicesTableRow key={index} device={device} tableRowIndex={index} handleRowClick={handleRowClick} />
                ))
              : !isLoading && <TableEmptyRow colspan={6} />}
            {isLoading && <TableLoadingRow colspan={6} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DevicesTable;
