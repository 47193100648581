/* Copyright */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DeviceType, IMEIPrefix, Organization } from "@sade/data-access";
import * as React from "react";
import AuthCode from "react-auth-code-input";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { ExternalDeviceIdentifierParameter } from "../../../../utils/parameterUtils";
import { OrganizationInfo } from "../../../../utils/utils";
import CustomTextFieldWithValidation from "../../../common/custom-textfield-with-validation";
import FileUpload from "../../../common/file-upload";
import RealmPicker from "../../../common/realm-picker";

type ContentImeiAndRealmProps = {
  imeiPrefixes: IMEIPrefix[];
  selectedImeiPrefix?: string;
  imeiSuffix: string;
  activationRealms: OrganizationInfo[];
  externalDeviceIdentifier1?: ExternalDeviceIdentifierParameter;
  externalDeviceIdentifier2?: ExternalDeviceIdentifierParameter;
  externalDeviceIdentifier3?: ExternalDeviceIdentifierParameter;
  externalId1: string;
  externalId2: string;
  externalId3: string;
  selectedImeiCount: number;
  selectedRealm?: Organization;
  onImeiPrefixSelectChange: (event: SelectChangeEvent) => void;
  onImeiSuffixChange: (suffix: string) => void;
  onRealmSelectChange: (realmId: string) => void;
  onFileChange: (file?: File) => void;
  onRemoveFile: () => void;
  onSetExternalIdentifier: (key: "externalId1" | "externalId2" | "externalId3", value: string) => void;
  onExternalIdError: (error: boolean) => void;
};

const ContentImeiAndRealm: React.FC<ContentImeiAndRealmProps> = ({
  imeiPrefixes,
  selectedImeiPrefix,
  imeiSuffix,
  activationRealms,
  externalDeviceIdentifier1,
  externalDeviceIdentifier2,
  externalDeviceIdentifier3,
  externalId1,
  externalId2,
  externalId3,
  selectedImeiCount,
  selectedRealm,
  onImeiPrefixSelectChange,
  onImeiSuffixChange,
  onRealmSelectChange,
  onFileChange,
  onRemoveFile,
  onSetExternalIdentifier,
  onExternalIdError,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("md"));

  // External ID validation error
  const [externalId1Error, setExternalId1Error] = React.useState<boolean>(false);
  const [externalId2Error, setExternalId2Error] = React.useState<boolean>(false);
  const [externalId3Error, setExternalId3Error] = React.useState<boolean>(false);

  const suffixError = imeiSuffix.length > 0 && imeiSuffix.length < 7;

  React.useEffect(() => {
    if (
      selectedImeiCount === 1 &&
      (externalDeviceIdentifier1 || externalDeviceIdentifier2 || externalDeviceIdentifier3)
    ) {
      if (externalId1Error || externalId2Error || externalId3Error) {
        onExternalIdError(true);
      } else {
        onExternalIdError(false);
      }
    } else {
      onExternalIdError(false);
    }
  }, [
    externalId1Error,
    externalId2Error,
    externalId3Error,
    externalDeviceIdentifier1,
    externalDeviceIdentifier2,
    externalDeviceIdentifier3,
    selectedImeiCount,
    onExternalIdError,
  ]);

  const imeiPrefixName = (imeiPrefix: IMEIPrefix): string => {
    let name = `${imeiPrefix.imei} - `;
    switch (imeiPrefix.type) {
      case DeviceType.Libris_2Att:
        name += "Libris2 ATT/Telus";
        break;
      case DeviceType.Libris_2Verizon:
        name += "Libris2 Verizon";
        break;
      case DeviceType.Panel_4200X:
        name += "4200x";
        break;
    }

    return name;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ mt: 1, mb: 4, maxWidth: 540 }}>
        <Typography>{translations.activateNewDevicesDialog.texts.info()}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: isSmallViewPort ? "column" : "row", maxWidth: 700, mb: 4 }}>
        <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
          <InputLabel id="imei-prefix-label" shrink>
            {translations.activateNewDevicesDialog.texts.imeiPrefix()}
          </InputLabel>
          <Select
            id="imei-prefix-select"
            size="small"
            onChange={onImeiPrefixSelectChange}
            labelId="imei-prefix-label"
            value={selectedImeiPrefix}
            data-testid={TestIds.ActivateNewDevicesDialog.ImeiPrefixSelect}
          >
            {imeiPrefixes?.map((imeiPrefix, index) => (
              <MenuItem key={index} value={imeiPrefix.imei}>
                {imeiPrefixName(imeiPrefix)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ ml: isSmallViewPort ? 0 : 2, mt: isSmallViewPort ? 2 : 0, maxWidth: 300 }}>
          <InputLabel id="imei-suffix-label" shrink>
            {translations.activateNewDevicesDialog.texts.imeiSuffix()}
          </InputLabel>

          {/* AuthCode is an uncontrolled component meaning we can't set it's state. When user moves
          back in the activation flow, we loose the input although it's stored in the parent component. */}
          <AuthCode
            autoFocus={false}
            allowedCharacters="numeric"
            onChange={onImeiSuffixChange}
            containerClassName="enter-code-container"
            inputClassName={suffixError ? "enter-code-input-error" : "enter-code-input"}
            length={7}
          />
        </FormControl>
      </Box>

      <Box sx={{ mb: 4 }}>
        <FileUpload
          infoText={translations.common.texts.uploadImeisPlaceholder()}
          handleFileChange={onFileChange}
          handleRemoveFile={onRemoveFile}
        />
      </Box>

      <Box sx={{ maxWidth: 500 }}>
        <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
          <RealmPicker
            childOrganizations={activationRealms ?? []}
            onRealmPicked={onRealmSelectChange}
            testId={TestIds.ActivateNewDevicesDialog.RealmSelect}
            selectedRealmId={selectedRealm?.getId()}
          />
        </FormControl>
      </Box>

      {selectedImeiCount === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {externalDeviceIdentifier1 && (
              <CustomTextFieldWithValidation
                label={externalDeviceIdentifier1?.label}
                id="externaldDeviceIdentifier1"
                value={externalId1}
                required
                validationPattern={externalDeviceIdentifier1?.pattern}
                placeholder={externalDeviceIdentifier1?.placeholder}
                onValueChange={(_id, value, isError): void => {
                  onSetExternalIdentifier("externalId1", value);
                  setExternalId1Error(isError);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {externalDeviceIdentifier2 && (
              <CustomTextFieldWithValidation
                label={externalDeviceIdentifier2?.label}
                id="externaldDeviceIdentifier2"
                value={externalId2}
                required
                validationPattern={externalDeviceIdentifier2?.pattern}
                placeholder={externalDeviceIdentifier2?.placeholder}
                onValueChange={(_id, value, isError): void => {
                  onSetExternalIdentifier("externalId2", value);
                  setExternalId2Error(isError);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {externalDeviceIdentifier3 && (
              <CustomTextFieldWithValidation
                label={externalDeviceIdentifier3?.label}
                id="externaldDeviceIdentifier3"
                value={externalId3}
                required
                validationPattern={externalDeviceIdentifier3?.pattern}
                placeholder={externalDeviceIdentifier3?.placeholder}
                onValueChange={(_id, value, isError): void => {
                  onSetExternalIdentifier("externalId3", value);
                  setExternalId3Error(isError);
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ContentImeiAndRealm;
