/* Copyright */
import { createTheme, alpha } from "@mui/material/styles";
import { blue, purple, orange } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    purple: Palette["primary"];
    blue: Palette["primary"];
    orange: Palette["primary"];
  }
  interface PaletteOptions {
    purple: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    orange: PaletteOptions["primary"];
  }
}

const basicTheme = createTheme({
  palette: {
    primary: {
      main: "#004A93",
      light: "#0288D1",
      dark: "#002E5A",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.6)",
      light: "rgba(0, 0, 0, 0.38)",
      dark: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#2E7D32",
      light: "#4CAF50",
    },
    error: {
      main: "#D32F2F",
      light: "#FBEAEA",
    },
    warning: {
      main: "#ed6c02",
      dark: "#e65100",
      light: "#ff9800",
    },
    background: {
      default: "#FFFFFF",
    },
    purple: {
      main: purple[500],
      dark: "#E200FD",
    },
    blue: {
      main: blue[500],
      light: "#E6F3FA",
    },
    orange: {
      main: orange[500],
      light: "#FDF0E6",
      dark: "#FF6D00",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

const theme = createTheme(basicTheme, {
  typography: {
    caption: {
      color: basicTheme.palette.secondary.main,
    },
  },
  palette: {
    custom: {
      purple: purple[500],
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "capitalize",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: basicTheme.palette.getContrastText(basicTheme.palette.grey[300]),
            fontWeight: 600,
            textTransform: "capitalize",
          },
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: basicTheme.palette.text.primary,
            borderColor: basicTheme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${basicTheme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor: basicTheme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(basicTheme.palette.text.primary, basicTheme.palette.action.hoverOpacity),
            },
          },
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: basicTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(basicTheme.palette.text.primary, basicTheme.palette.action.hoverOpacity),
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "uppercase",
          left: -10,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          marginTop: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.Mui-disabled + fieldset": {
            borderStyle: "dotted",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          marginTop: 8,
          "& .Mui-disabled + fieldset": {
            borderStyle: "dotted",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 16,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
  },
});

export default theme;
