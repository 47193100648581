/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __rest } from "tslib";
import { isDefined } from "../../common/isDefined";
import { DevicesActionRequestDocument, DevicesEncryptDocument, DevicesGetDocument, DevicesGetEverThereDocument, DevicesImeiPrefixesListDocument, DevicesListDocument, DevicesMailboxMessagesPurgeDocument, DevicesOperatorPlansGetDocument, DevicesOrganizationSetDocument, DevicesResetDocument, DevicesRetireDocument, DevicesSearchDocument, DevicesSimActionStatusGetDocument, DevicesSimManageDocument, } from "../../generated/gqlDevice";
import { DevicesEventsLatestGetDocument } from "../../generated/gqlEvents";
import { AuthListener } from "../auth/AuthListener";
import { AWSEvent } from "../events/AWSEvent";
import { AsyncCache } from "../private-utils/AsyncCache";
import { throwGQLError } from "../private-utils/throwGQLError";
import { AppSyncClientFactory } from "./AppSyncClientFactory";
import { Service } from "./AppSyncClientProvider";
export function narrowDownAttributeTypes(attrs) {
    return attrs.map(({ key, value }) => ({ key, value }));
}
export class AWSBackend {
    constructor(deviceFactory) {
        this.deviceFactory = deviceFactory;
        // TODO: it might be smarter to cache devices to DeviceFactory
        this.deviceCache = new AsyncCache();
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.deviceCache.clear();
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
        this.deviceFragmentIntoDevice = async (fragment, initState = true) => {
            const device = this.deviceFactory.createDevice(this, fragment.type, {
                deviceId: fragment.id,
                attributes: narrowDownAttributeTypes(fragment.attr),
            });
            if (device) {
                if (fragment.shadow) {
                    const { state, timestamp, version, connectionState } = JSON.parse(fragment.shadow);
                    device.setState(timestamp, version, state.reported, state.desired, connectionState);
                }
                else if (initState) {
                    await device.init();
                }
                return device;
            }
        };
        this.eventDeviceFragmentIntoDevice = async (fragment, initState = true) => {
            const device = await this.deviceFragmentIntoDevice(fragment, initState);
            if (device) {
                if (fragment.latestEvent) {
                    const event = new AWSEvent(fragment.latestEvent);
                    device.setLatestEvent(event);
                }
            }
            return device;
        };
    }
    async getDevice(id, refreshCache) {
        const fetchDevice = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.query(DevicesGetDocument, {
                deviceId: id,
            });
            if (response.data.devicesGet) {
                return this.deviceFragmentIntoDevice(response.data.devicesGet);
            }
        };
        if (refreshCache)
            await this.deviceCache.delete(id);
        return this.deviceCache.get(id, fetchDevice);
    }
    async getDeviceEverThere(IMEI, ICCID, refreshCache) {
        const fetchDevice = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.query(DevicesGetEverThereDocument, {
                deviceId: IMEI,
                ICCID,
            });
            if (response.data.devicesGetEverThere) {
                return this.deviceFragmentIntoDevice(response.data.devicesGetEverThere);
            }
        };
        if (refreshCache)
            await this.deviceCache.delete(IMEI);
        return this.deviceCache.get(IMEI, fetchDevice);
    }
    async getLatestDeviceEvents(status) {
        var _a, _b, _c;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const deviceFragments = [];
        const response = await client.query(DevicesEventsLatestGetDocument, { status });
        deviceFragments.push(...((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesEventsLatestGet) === null || _b === void 0 ? void 0 : _b.devices) !== null && _c !== void 0 ? _c : []));
        return (await Promise.all(deviceFragments.map((fragment) => this.eventDeviceFragmentIntoDevice(fragment, false)))).filter(isDefined);
    }
    async getOrganizationDevices(organizationId) {
        const query = `attributes.organization: "${organizationId}"`;
        return await this.searchDevices(query);
    }
    async searchDevices(query, initDevices = true) {
        var _a, _b, _c, _d, _e;
        // TODO: should we have query-specific cache?
        console.info(`Searching for devices with query ${query}`);
        const deviceFragments = [];
        let nextToken;
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        do {
            const searchDevicesResponse = await appSyncClient.query(DevicesSearchDocument, {
                query,
                nextToken,
            });
            nextToken = (_b = (_a = searchDevicesResponse.data) === null || _a === void 0 ? void 0 : _a.devicesSearch) === null || _b === void 0 ? void 0 : _b.nextToken;
            deviceFragments.push(...((_e = (_d = (_c = searchDevicesResponse.data) === null || _c === void 0 ? void 0 : _c.devicesSearch) === null || _d === void 0 ? void 0 : _d.devices) !== null && _e !== void 0 ? _e : []));
        } while (nextToken);
        return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice, initDevices);
    }
    async encryptWithDeviceCertificate(message, certificateArn) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesEncryptDocument, { certificateArn, message });
        if (!response.data.devicesEncrypt) {
            throwGQLError(response, "Encryption failed");
        }
        return response.data.devicesEncrypt;
    }
    async getDeviceIMEIPrefixes() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesImeiPrefixesListDocument, {});
        if (!response.data.devicesIMEIPrefixesList) {
            throwGQLError(response, "Failed to fetch IMEI prefixes");
        }
        return response.data.devicesIMEIPrefixesList.prefixes;
    }
    async listAllAccessibleDevices(parameters, nextToken) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesListDocument, { parameters, nextToken });
        if (!response.data.devicesList) {
            throwGQLError(response, "Failed to list devices");
        }
        return response.data.devicesList;
    }
    async requestSimManagementAction(params) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.mutate(DevicesSimManageDocument, {
            params: Object.assign(Object.assign({}, params), { deviceSpecificConfig: params.deviceSpecificConfig ? JSON.stringify(params.deviceSpecificConfig) : undefined }),
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesSimManage)) {
            throwGQLError(response, "SIM action request failed");
        }
        return response.data.devicesSimManage;
    }
    async getSimManagementActionBatchInfo(batchIds) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesSimActionStatusGetDocument, {
            batchIds,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesSimActionStatusGet)) {
            throwGQLError(response, "Getting SIM action batch statuses failed");
        }
        return response.data.devicesSimActionStatusGet;
    }
    async requestActionForDevices(deviceIds, action, sendWakeUpSMS) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesActionRequestDocument, { deviceIds, action, sendWakeUpSMS });
    }
    async retireDevices(deviceIds) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesRetireDocument, { deviceIds });
    }
    async resetDevices(deviceIds) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesResetDocument, { deviceIds });
    }
    async changeOrganizationForDevices(deviceIds, newOrganizationId) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesOrganizationSetDocument, {
            deviceIds,
            newOrganizationId,
        });
    }
    async purgeMailboxForDevices(deviceIds) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesMailboxMessagesPurgeDocument, { deviceIds });
    }
    async getOperatorPlans() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const document = await client.query(DevicesOperatorPlansGetDocument, {});
        if (document.data.devicesOperatorPlansGet) {
            return document.data.devicesOperatorPlansGet.map((p) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { __typename } = p, plan = __rest(p, ["__typename"]);
                return plan;
            });
        }
        return [];
    }
    /////
    /// AWSBackend specific public methods
    /////
    async removeLocal(thing) {
        await this.deviceCache.delete(thing.getId());
    }
    getSupportedDeviceTypes() {
        return this.deviceFactory.listDeviceTypes();
    }
    /**
     * Takes a collection of fragments, which are either
     * - if id matches something in cache, replaced with the cached entity
     * - converted into the desired entity, and then cached
     *
     * @param cache
     *    an AsyncCache into which to store the converted fragments
     * @param fragments
     *    list of fragments to go through
     * @param fragmentConverter
     *    method for converting fragment into the desired entity type
     * @private
     */
    async cacheFragments(cache, fragments, fragmentConverter, initDevices = true) {
        const results = await Promise.all(fragments.map((fragment) => cache.get(fragment.id, () => fragmentConverter(fragment, initDevices))));
        return results.filter(isDefined);
    }
}
