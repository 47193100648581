/* Copyright */
import { Box, Divider, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import {
  DeviceState,
  Maybe,
  Organization,
  OrganizationParameterHierarchy,
  ParameterValueType,
} from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import { DeviceSettings, OrganizationInfo, ParameterNames } from "../../../../utils/utils";
import DeviceSettingsContent from "../../../common/device-settings-content/device-settings-content";

type ContentDeviceSettingsProps = {
  deviceSettings: DeviceSettings;
  deviceOrganization?: Organization;
  parameterHierarchy?: OrganizationParameterHierarchy;
  organizationNameMap: OrganizationInfo[];
  usingCustomDeviceSettings: boolean;
  onSettingChanged: (param: ParameterNames, value: ParameterValueType) => void;
  setUsingCustomDeviceSettings: (usingCustomSettings: boolean) => void;
};

const ContentDeviceSettings: React.FC<ContentDeviceSettingsProps> = ({
  deviceSettings,
  deviceOrganization,
  parameterHierarchy,
  organizationNameMap,
  usingCustomDeviceSettings,
  onSettingChanged,
  setUsingCustomDeviceSettings,
}) => {
  const deviceState: Maybe<DeviceState> = undefined;

  return (
    <Box sx={{ mt: 2 }}>
      <FormControl>
        <InputLabel id="using-custom-device-settings-select-label" shrink>
          {translations.common.texts.deviceSettings()}
        </InputLabel>
        <RadioGroup
          aria-labelledby="using-custom-device-settings-select-label"
          name="using-custom-device-settings-select"
          sx={{ mt: 2 }}
          value={String(usingCustomDeviceSettings)}
          onChange={(e): void => setUsingCustomDeviceSettings(e.target.value === "true")}
        >
          <FormControlLabel
            value={"false"}
            control={<Radio />}
            label={translations.activateNewDevicesDialog.texts.useDefaultDeviceSettingsText()}
          />
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={translations.activateNewDevicesDialog.texts.useCustomizedSettingsText()}
          />
        </RadioGroup>
      </FormControl>
      {usingCustomDeviceSettings && (
        <React.Fragment>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <DeviceSettingsContent
            deviceSettings={deviceSettings}
            deviceState={deviceState}
            parameterHierarchy={parameterHierarchy}
            organizationNameMap={organizationNameMap}
            deviceOrganization={deviceOrganization}
            onSettingChanged={onSettingChanged}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ContentDeviceSettings;
