/* Copyright */
import React from "react";
import { Link } from "react-router-dom";
import { Toolbar, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../assets/images/Numera-Nice-brand-Logo-color.svg";
import NavigationDrawer from "./navigation-drawer/navigation-drawer";
import ProfileMenu from "../profile-menu";
import TestIds from "../../../test-ids/test-ids";

const ToolbarSmall: React.FC = () => {
  const [navigationDrawerOpen, setNavigationDrawerOpen] = React.useState(false);

  const handleToggleNavigationDrawerOpen = (): void => {
    setNavigationDrawerOpen(!navigationDrawerOpen);
  };

  return (
    <React.Fragment>
      <Toolbar sx={{ width: "100%", justifyContent: { xs: "space-between" } }}>
        <Box>
          <IconButton
            sx={{ flexGrow: 1 }}
            color="primary"
            onClick={handleToggleNavigationDrawerOpen}
            data-testid={TestIds.Toolbar.SmallMenuButton}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Link to="/" data-testid={TestIds.Toolbar.SmallHomeLink}>
          <img src={logo} alt="Numera Nice brand logo" style={{ width: "120px" }} />
        </Link>
        <ProfileMenu />
      </Toolbar>
      <NavigationDrawer isOpen={navigationDrawerOpen} handleCloseDrawer={handleToggleNavigationDrawerOpen} />
    </React.Fragment>
  );
};

export default ToolbarSmall;
