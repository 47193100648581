/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class AwsConfiguration {
    static configure(awsConfig) {
        AwsConfiguration.awsConfig = awsConfig;
    }
    static getConfiguration() {
        if (!AwsConfiguration.awsConfig) {
            throw new Error("AWS not configured. Have you called AwsConfiguration.configure from data access module?");
        }
        return AwsConfiguration.awsConfig;
    }
}
