/* Copyright */
import { getSignalStrengthLevel } from "../../common/getSignalStrengthLevel";
export class AWSLatestData {
    constructor(params) {
        this.deviceId = params.deviceId;
        this.patientId = params.patientId;
        this.updatedTime = new Date(params.updatedTime);
        this.temperature = params.temperature;
        this.networkType = params.networkType;
        this.batteryLevel = params.batteryLevel;
        this.rssi = params.rssi;
        this.rsrp = params.rsrp;
        this.latitude = params.latitude;
        this.longitude = params.longitude;
        this.altitude = params.altitude;
        this.accuracy = params.accuracy;
        this.method = params.method;
        this.address = params.address;
        this.fixTime = params.fixTime ? new Date(params.fixTime) : undefined;
        this.additionalData = params.additionalData;
    }
    /**
     * Retrieves the signal strength in bars.
     *
     * @return {Promise<Maybe<number>>} The signal strength, or undefined if there's no data to calculate.
     */
    getSignalStrength() {
        if (this.rssi === undefined || this.rsrp === undefined || !this.networkType) {
            return undefined;
        }
        return getSignalStrengthLevel(this.rssi, this.rsrp, this.networkType);
    }
}
