/* Copyright */
import * as React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { translations } from "../../../generated/translationHelper";
import { Paths } from "../../../utils/utils";
import { getPath } from "../../../utils/ssoPathUtil";

const AuthenticationLayoutFooter: React.FC = () => {
  const theme = useTheme();
  return (
    <Box className="footer-container">
      <Box className="link-container">
        <Link href={getPath(Paths.SUPPORT)} underline="hover" variant="body2" className="link">
          {translations.common.links.support()}
        </Link>
        <Typography variant="caption" sx={{ color: theme.palette.secondary.light }}>
          |
        </Typography>
        <Link href={getPath(Paths.PRIVACY)} underline="hover" variant="body2" className="link">
          {translations.common.links.privacy()}
        </Link>
        <Typography variant="caption" sx={{ color: theme.palette.secondary.light }}>
          |
        </Typography>
        <Link href={getPath(Paths.TERMS_OF_USE)} underline="hover" variant="body2" className="link">
          {translations.common.links.termsOfUse()}
        </Link>
      </Box>
      <Typography variant="caption" className="copyright">
        © {new Date().getFullYear()} {translations.common.footer.rightsReserved()}
      </Typography>
    </Box>
  );
};

export default AuthenticationLayoutFooter;
