/* Copyright */
import * as React from "react";
import AccessControl from "../access-control/access-control";
import { DeviceStatus, RoleIdentifier } from "@sade/data-access";
import ReportsViewFilterBar, { ReportType } from "./reports-view-filter-bar/reports-view-filter-bar";
import { Box, SelectChangeEvent } from "@mui/material";

const ReportsView: React.FC = () => {
  const [selectedStatuses, setSelectedStatuses] = React.useState<string[]>([
    ...(Object.values(DeviceStatus) as string[]),
    "All",
  ]);
  const [selectedReportType, setSelectedReportType] = React.useState<ReportType>(ReportType.DeviceStatus);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatuses]);

  const handleStatusSelectChange = (event: SelectChangeEvent<typeof selectedStatuses>): void => {
    const {
      target: { value },
    } = event;
    setSelectedStatuses(typeof value === "string" ? value.split(",") : value);
    setSelectedReportType(selectedReportType);
  };

  return (
    <Box sx={{ padding: "3rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.PowerUser, RoleIdentifier.DealerManager]}>
        <ReportsViewFilterBar
          handleReportTypeSelectChange={(): void => {
            // not in use
          }}
          selectedReportType={selectedReportType}
          handleStatusSelectChange={handleStatusSelectChange}
          selectedStatuses={selectedStatuses}
        />
      </AccessControl>
    </Box>
  );
};

export default ReportsView;
