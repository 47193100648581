/* Copyright */
import { Card, CardContent } from "@mui/material";
import { Event, Maybe } from "@sade/data-access";
import React from "react";
import TestIds from "../../../../test-ids/test-ids";
import { LocationData } from "../../../../utils/mapUtils";
import GoogleMapsWrapper from "../../../common/google-maps-wrapper";
import EventMap from "./event-map";

type EventMapCardProps = {
  callEvent: Event;
  locationsOnMap: Maybe<LocationData[]>;
  homeAddress?: string;
};

const EventMapCard: React.FC<EventMapCardProps> = ({ callEvent, locationsOnMap, homeAddress }) => {
  return (
    <React.Fragment>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.ActiveCalls.MapCard}>
        <CardContent
          sx={{
            p: 0,
            "&:last-child": {
              pb: 0,
            },
          }}
        >
          <GoogleMapsWrapper>
            <EventMap locationsOnMap={locationsOnMap} callEvent={callEvent} homeAddress={homeAddress} />
          </GoogleMapsWrapper>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventMapCard;
