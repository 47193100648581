/* Copyright */
import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ActiveCallView from "./components/active-call-view/active-call-view";
import { Paths } from "./utils/utils";
import { getPath } from "./utils/ssoPathUtil";
import TokenAppProviders from "./app/TokenAppProviders";

function TokenApp(): JSX.Element {
  return (
    <TokenAppProviders>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path={getPath(Paths.ACTIVE_CALL)} element={<ActiveCallView />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </TokenAppProviders>
  );
}

export default TokenApp;
