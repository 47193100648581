/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { minimizeSetOfPermissions, permissionListCoversPermission } from "./Permissions";
export var RankingOrder;
(function (RankingOrder) {
    RankingOrder["Superior"] = "Superior";
    RankingOrder["Inferior"] = "Inferior";
    RankingOrder["Equal"] = "Equal";
    RankingOrder["Sibling"] = "Sibling";
})(RankingOrder || (RankingOrder = {}));
export var RoleIdentifier;
(function (RoleIdentifier) {
    // TODO:  these are copied from backend and are not 100% trust-worthy
    //        but since the Role concept does not include a way to cluster
    //        roles by the semantics of "is admin-like", this will have to
    //        for now.
    RoleIdentifier["SuperUser"] = "superUser";
    RoleIdentifier["PowerUser"] = "powerUser";
    RoleIdentifier["DealerManager"] = "dealerManager";
    RoleIdentifier["WarehouseInstaller"] = "warehouseInstaller";
    RoleIdentifier["DealerAgent"] = "dealerAgent";
    RoleIdentifier["Supervisor"] = "supervisor";
    RoleIdentifier["Operator"] = "operator";
    RoleIdentifier["Viewer"] = "viewer";
    RoleIdentifier["EndUser"] = "endUser";
})(RoleIdentifier || (RoleIdentifier = {}));
export class Role {
    constructor(params) {
        this.identifier = params.identifier;
        this.name = params.name;
        this.ranking = params.ranking;
        this.permissionArray = [...params.permissions];
    }
    get permissions() {
        return [...this.permissionArray];
    }
    getRankingOrder(another) {
        return Role.getRankingOrder(this.ranking, another.ranking);
    }
    grantsPermission(permission) {
        return permissionListCoversPermission(this.permissionArray, permission);
    }
    equals(other) {
        return Role.equal(this, other);
    }
    static fromGraphQL(role) {
        return new Role(Object.assign(Object.assign({}, role), { identifier: role.id }));
    }
    /**
     * Returns the relative ordering of two {@link Ranking} values.
     * This method can be used with sort functions: [].sort(rankingOrder).
     *
     * @param ranking1
     * @param ranking2
     * @returns number representing the relative order of the ranks
     * @see getRankingOrder for a more nuanced method
     */
    static rankingOrder(ranking1, ranking2) {
        if (ranking1 === ranking2)
            return 0;
        if (ranking2.startsWith(ranking1))
            return Number.NEGATIVE_INFINITY;
        if (ranking1.startsWith(ranking2))
            return Number.POSITIVE_INFINITY;
        // If the rankings are siblings, treat them as equals
        const [r1, r2] = [ranking1.slice(0, -1), ranking2.slice(0, -1)];
        if (r1 === r2)
            return 0;
        // Rankings are from different trees
        return Number.NEGATIVE_INFINITY;
    }
    /**
     * Returns a {@link RankingOrder} between the two given rankings answering the question
     * "What is the relations of ranking1 to ranking2?"
     *
     * @param ranking1
     * @param ranking2
     */
    static getRankingOrder(ranking1, ranking2) {
        const order = Role.rankingOrder(ranking1, ranking2);
        if (ranking1 === ranking2)
            return RankingOrder.Equal;
        if (order < 0)
            return RankingOrder.Superior;
        if (order > 0)
            return RankingOrder.Inferior;
        return RankingOrder.Sibling;
    }
    /**
     * Collects and minimized permissions granted by the given roles.
     * @param roles
     */
    static getMinimizedPermissionsForRoles(roles) {
        const allPermissions = roles.flatMap((role) => role.permissions);
        const minimalSet = minimizeSetOfPermissions(new Set(allPermissions));
        return [...minimalSet];
    }
    /**
     * Checks that all {@link Permission permissions} are covered by any {@link Role} in the roles list.
     * @param roles
     * @param permissions
     */
    static rolesHavePermissions(roles, permissions) {
        const rolePermissions = this.getMinimizedPermissionsForRoles(roles);
        return permissions.every((permission) => permissionListCoversPermission(rolePermissions, permission));
    }
    static equal(role1, role2) {
        return role1.identifier === role2.identifier;
    }
}
