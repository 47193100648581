/* Copyright */

const MAX_TERM_COUNT = 12 as const;

/**
 * Split a query string into multiple queries based on a split operator and a maximum term count.
 *
 * @param query - The query string to split.
 * @param splitOperator - The operator used to split the query string.
 * @param maxTermCount - The maximum number of terms per query.
 * @returns An array of queries.
 */
export const splitQuery = (query: string, splitOperator: string, maxTermCount?: number): string[] => {
  const queryTerms = query.split(splitOperator);
  const queryTermsCount = queryTerms.length;

  // Build new queries so that each query has a maximum of maxTermCount terms
  const queries: string[] = [];
  let currentQuery = "";
  let startNewQuery = true;
  for (let i = 0; i < queryTermsCount; i++) {
    const term = queryTerms[i];
    if (!startNewQuery) {
      currentQuery += splitOperator;
    }
    startNewQuery = false;

    currentQuery += term;
    if ((i + 1) % (maxTermCount ?? MAX_TERM_COUNT) === 0) {
      queries.push(currentQuery);
      currentQuery = "";
      startNewQuery = true;
    }
  }

  // Add the last query if it is not empty
  if (currentQuery !== "") {
    queries.push(currentQuery);
  }

  return queries;
};
