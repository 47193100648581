/* Copyright */
import * as React from "react";
import { Box, Grid, IconButton, Typography, Button, InputAdornment, CircularProgress } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { translations } from "../../../generated/translationHelper";
import CustomTextField from "../../common/textfield";
import TestIds from "../../../test-ids/test-ids";

type ChangePasswordFormProps = {
  isLoading: boolean;
  showPassword: boolean;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  currentPasswordError: string;
  newPasswordError: string;
  isChangePasswordButtonDisabled: boolean;
  handleClickShowPassword: () => void;
  handlePasswordInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePasswordClick: () => void;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  isLoading,
  showPassword,
  currentPassword,
  newPassword,
  confirmPassword,
  currentPasswordError,
  newPasswordError,
  isChangePasswordButtonDisabled,
  handleClickShowPassword,
  handlePasswordInputChange,
  handleChangePasswordClick,
}) => {
  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: "0.5rem" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{translations.myProfile.texts.changePassword()}</Typography>
          <Box sx={{ mt: 5 }}>
            <CustomTextField
              label={translations.myProfile.inputs.currentPassword()}
              id="current"
              type={showPassword ? "text" : "password"}
              errorMessage={currentPasswordError}
              testId={TestIds.ProfileDialog.CurrentPassword}
              onChange={handlePasswordInputChange}
              onKeyDown={(e): void => {
                if (e.key === "Enter" && !isChangePasswordButtonDisabled) handleChangePasswordClick();
              }}
              value={currentPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: 5 }}>
            <CustomTextField
              label={translations.common.inputs.newPassword()}
              id="new"
              type={showPassword ? "text" : "password"}
              errorMessage={newPasswordError}
              testId={TestIds.ProfileDialog.NewPassword}
              onChange={handlePasswordInputChange}
              onKeyDown={(e): void => {
                if (e.key === "Enter" && !isChangePasswordButtonDisabled) handleChangePasswordClick();
              }}
              value={newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: 5 }}>
            <CustomTextField
              label={translations.common.inputs.confirmNewPassword()}
              id="confirm"
              type={showPassword ? "text" : "password"}
              errorMessage={newPasswordError}
              testId={TestIds.ProfileDialog.ConfirmPassword}
              onChange={handlePasswordInputChange}
              onKeyDown={(e): void => {
                if (e.key === "Enter" && !isChangePasswordButtonDisabled) handleChangePasswordClick();
              }}
              value={confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "end" }}>
          <Box sx={{ ml: 2 }}>
            <Typography variant="caption">
              <b>{translations.myProfile.texts.passwordRequirements()}</b>
            </Typography>
            <li>
              <Typography variant="caption">{translations.common.texts.characterMinimum()}</Typography>
            </li>
            <li>
              <Typography variant="caption">{translations.common.texts.lowercaseMinimum()}</Typography>
            </li>
            <li>
              <Typography variant="caption">{translations.common.texts.uppercaseMinimum()}</Typography>
            </li>
            <li>
              <Typography variant="caption">{translations.common.texts.numberMinimum()}</Typography>
            </li>
            <li>
              <Typography variant="caption">
                {translations.common.texts.specialCharacterMinimum()} {translations.common.texts.specialCharacters()}
              </Typography>
            </li>
          </Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ mt: 3 }}
        onClick={handleChangePasswordClick}
        disabled={isChangePasswordButtonDisabled}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
        data-testid={TestIds.ProfileDialog.ChangePasswordButton}
      >
        {translations.common.buttons.changePassword()}
      </Button>
    </Box>
  );
};

export default ChangePasswordForm;
