/* Copyright */
export function isValidEmail(email: string): boolean {
  return /.[a-z0-9.]+@+[a-z0-9.]+\.[a-z0-9]+/.test(email);
}

export function removeTrailingWhitespace(email: string): string {
  return email.replace(/[ \t]+$/, "");
}

export function validateUrl(address: string): boolean {
  try {
    new URL(address);
  } catch (_) {
    return false;
  }
  return true;
}
