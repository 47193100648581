/* Copyright */
import CallIcon from "@mui/icons-material/Call";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import { Event, EventType, Maybe } from "@sade/data-access";
import React from "react";
import { translations } from "../../../../generated/translationHelper";
import { formatTimestamp } from "../../../../utils/utils";

type EventTypeItemProps = {
  event: Maybe<Event>;
};

const EventTypeItem: React.FC<EventTypeItemProps> = ({ event }) => {
  const theme = useTheme();

  const isFall = event?.type === EventType.Fall;

  return (
    <Box>
      <ListItem sx={{ bgcolor: isFall ? theme.palette.error.light : theme.palette.orange.light, borderRadius: "8px" }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: isFall ? theme.palette.error.main : theme.palette.orange.dark }}>
            {isFall ? <PriorityHighIcon /> : <CallIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={isFall ? translations.common.texts.fall() : translations.common.texts.call()}
          secondary={
            <>
              <Typography variant="caption">{event?.address ?? ""}</Typography>
              <br />
              <Typography variant="caption">{event?.timestamp ? formatTimestamp(event.timestamp) : ""}</Typography>
            </>
          }
        />
      </ListItem>
    </Box>
  );
};

export default EventTypeItem;
