/* Copyright*/
import { DeviceState } from "../../data/device/DeviceState";
import { isDeviceLevelDefinition } from "../../data/device/DeviceStateProperties";
export class NumeraDeviceState extends DeviceState {
    getParameter(name) {
        var _a, _b, _c;
        const parameter = (_b = (_a = this.changedValues.parameters) === null || _a === void 0 ? void 0 : _a[name]) !== null && _b !== void 0 ? _b : (_c = this.reported.parameters) === null || _c === void 0 ? void 0 : _c[name];
        if (parameter) {
            // Parameter can have two keys. But in this case the other one has value "null".
            return Object.values(parameter).filter((v) => v != null)[0];
        }
    }
    updateParameter(name, value) {
        var _a, _b;
        this.changedValues.parameters = (_b = (_a = this.changedValues.parameters) !== null && _a !== void 0 ? _a : Object.assign({}, this.reported.parameters)) !== null && _b !== void 0 ? _b : {};
        this.changedValues.parameters[name] = {
            // Make sure there's no value for key 0 (organization level), accomplished by setting the value to "null"
            // so that UpdateThingShadow command removes the field, see
            // https://docs.aws.amazon.com/iot/latest/developerguide/device-shadow-rest-api.html#API_UpdateThingShadow
            0: null,
            1: value,
        };
    }
    resetParameter(name, realmParameters) {
        var _a, _b;
        this.changedValues.parameters = (_b = (_a = this.changedValues.parameters) !== null && _a !== void 0 ? _a : Object.assign({}, this.reported.parameters)) !== null && _b !== void 0 ? _b : {};
        this.changedValues.parameters[name] = {
            0: realmParameters[name],
            1: null,
        };
    }
    isParameterOverridden(name) {
        var _a, _b, _c;
        const parameter = (_b = (_a = this.changedValues.parameters) === null || _a === void 0 ? void 0 : _a[name]) !== null && _b !== void 0 ? _b : (_c = this.reported.parameters) === null || _c === void 0 ? void 0 : _c[name];
        if (!parameter) {
            return false;
        }
        return isDeviceLevelDefinition(parameter);
    }
    getOverriddenParameters() {
        var _a;
        const allParameters = (_a = this.reported.parameters) !== null && _a !== void 0 ? _a : {};
        return (Object.keys(allParameters)
            // First filter out all parameters that are not overridden at device level
            .filter((key) => isDeviceLevelDefinition(allParameters[key]))
            // Then map the results to Parameters object
            .reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: Object.values(allParameters[key]).filter((v) => v != null)[0] })), {}));
    }
    deltaExists(key) {
        return this.changedValues[key] != null && this.changedValues[key] !== this.reported[key];
    }
    static instanceOf(state) {
        return state instanceof NumeraDeviceState;
    }
}
