/* Copyright */
import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { OtaPackageInfo } from "@sade/data-access/src/generated/gqlDevice";

type DeleteOtaPackageConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  otaPackage: OtaPackageInfo;
  isDeleting: boolean;
};

const DeleteOtaPackageConfirmationDialog: React.FC<DeleteOtaPackageConfirmationDialogProps> = ({
  open,
  handleClose,
  handleSubmit,
  otaPackage,
  isDeleting,
}) => {
  const packageName = otaPackage.otaPackageName;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.ConfirmDeleteOtaPackageDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.deleteOtaPackageConfirmationDialog.texts.title()}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.ConfirmDeleteOtaPackageDialog.CloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.deleteOtaPackageConfirmationDialog.texts.subtitle({ packageName }),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => handleSubmit()}
          color="error"
          disabled={isDeleting}
          startIcon={isDeleting ? <CircularProgress size={16} color="secondary" /> : <DeleteForeverOutlinedIcon />}
          data-testid={TestIds.ConfirmDeleteOtaPackageDialog.ConfirmButton}
        >
          {translations.deleteOtaPackageConfirmationDialog.buttons.delete()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          data-testid={TestIds.ConfirmDeleteOtaPackageDialog.CancelButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOtaPackageConfirmationDialog;
