/* Copyright */
import { Box, List, ListSubheader, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import * as React from "react";
import { DeviceEvent, eventLevel } from "../../../utils/eventUtils";
import { fortmatTimestampToShortDate } from "../../../utils/utils";
import { EventDetailsDialogType } from "../event-details/event-details-dialog";
import EventsListItem from "./events-list-item";

type EventsListProps = {
  events?: Map<number, DeviceEvent[]>;
  listRef?: React.RefObject<HTMLDivElement>;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  onOpenEventDetailsDialog: (eventId: string, dialogType: EventDetailsDialogType) => void;
};

const HEIGHT_OFFSET = 430;

const EventsList: React.FC<EventsListProps> = ({ events, listRef, onScroll, onOpenEventDetailsDialog }) => {
  const [viewHeight, setViewHeight] = React.useState<number>(window.innerHeight - HEIGHT_OFFSET);

  React.useEffect(() => {
    const handleResize = (): void => {
      setViewHeight(window.innerHeight - HEIGHT_OFFSET);
    };
    window.addEventListener("resize", () => handleResize());

    return (): void => {
      window.removeEventListener("resize", () => handleResize());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ mt: 2, overflowY: "auto", maxHeight: viewHeight }} ref={listRef} onScroll={onScroll}>
      <List>
        {events?.size &&
          Array.from(events?.entries()).map(([key, value]) => (
            <div key={key}>
              <ListSubheader sx={{ bgcolor: grey[50] }}>
                <Typography variant="overline">{fortmatTimestampToShortDate(key)}</Typography>
              </ListSubheader>
              {value.map((event, index) => (
                <EventsListItem
                  key={index}
                  icon={event.icon}
                  id={event.id}
                  eventName={event.eventName ?? ""}
                  eventTime={event.eventTimestamp}
                  level={event.eventLevel ?? eventLevel.UNSPECIFIED}
                  index={index}
                  onOpenEventDetailsDialog={onOpenEventDetailsDialog}
                />
              ))}
            </div>
          ))}
      </List>
    </Box>
  );
};

export default EventsList;
