/* Copyright */
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import { Device, DeviceAttributeName, DeviceOperatorPlan, Maybe, isError } from "@sade/data-access";
import { useAuthenticatedUser } from "../../../../context/authenticated-user-context";
import {
  SeverityType,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { ExternalDeviceIdentifierParameter } from "../../../../utils/parameterUtils";
import { DeviceDetails, OrganizationInfo, PatientInfo, getChildOrganizations } from "../../../../utils/utils";
import UnsavedChangesDialog from "../../unsaved-changes-dialog/unsaved-changes-dialog";
import DeviceDetailsDialogContentLeftGrid from "./device-details-dialog-content-left-grid";
import DeviceDetailsDialogContentRightGrid from "./device-details-dialog-content-right-grid";

type DeviceDetailsDialogProps = {
  open: boolean;
  realm?: OrganizationInfo;
  device?: Device;
  patient?: PatientInfo;
  externalParameter1?: ExternalDeviceIdentifierParameter;
  externalParameter2?: ExternalDeviceIdentifierParameter;
  externalParameter3?: ExternalDeviceIdentifierParameter;
  operatorPlans: DeviceOperatorPlan[];
  onClose: () => void;
  onDetailsUpdated: (updated: boolean) => void;
};

const DeviceDetailsDialog: React.FC<DeviceDetailsDialogProps> = ({
  open,
  realm,
  device,
  patient,
  externalParameter1,
  externalParameter2,
  externalParameter3,
  operatorPlans,
  onClose,
  onDetailsUpdated,
}) => {
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const [deviceDetails, setDeviceDetails] = React.useState<DeviceDetails>({
    deviceName: "-",
    imei: "-",
    iccid: "-",
    serialNumber: "-",
    networkOperator: "-",
    networkOperatorDisplayName: "-",
    operatorPlan: undefined,
    operatorPlanDisplayName: "-",
    operatorPlans: [],
    realmId: "-",
    hardwareVersion: "-",
    softwareVersion: "-",
    variant: "-",
    callerId: "-",
    patientName: "-",
    externalId1: "-",
    externalId2: "-",
    externalId3: "-",
  });
  const [childOrganizations, setChildOrganizations] = React.useState<Maybe<OrganizationInfo[]>>();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);

  const initialize = React.useCallback(async () => {
    const operatorPlanDisplayName = (device?: Device): string => {
      return (
        device?.getOperatorPlanDisplayName(operatorPlans) ??
        `${translations.deviceDetails.texts.invalidOperatorPlan()} (${
          device?.getAttribute(DeviceAttributeName.operatorPlan) ?? ""
        })`
      );
    };
    const initializeData = async (): Promise<void> => {
      setIsDirty(false);
      const networkOperator = device?.getAttribute(DeviceAttributeName.networkOperator) ?? "";
      const operatorDisplayName = device?.getNetworkOperatorDisplayName(operatorPlans) ?? networkOperator ?? "";
      const availablePlans = device?.getAvailablePlans(operatorPlans) ?? [];
      if (device && !device.getCurrentOperatorPlan(availablePlans)) {
        // if current plan not found from the config, it needs to be added to show up on the details
        availablePlans.push({
          planId: "invalid",
          displayName: operatorPlanDisplayName(device),
          operator: networkOperator,
          operatorDisplayName,
          deviceTypes: [device?.getType()],
        });
      }
      const updatedDeviceDetails = {
        deviceName: device?.getName() ?? "",
        deviceStatus: device?.getDeviceStatus(),
        imei: device?.getId() ?? "",
        iccid: device?.getAttribute(DeviceAttributeName.ICCID) ?? "",
        serialNumber: device?.getAttribute(DeviceAttributeName.serialNumber) ?? "",
        networkOperator: device?.getAttribute(DeviceAttributeName.networkOperator) ?? "",
        networkOperatorDisplayName: operatorDisplayName,
        operatorPlan: device?.getCurrentOperatorPlan(operatorPlans)?.planId,
        operatorPlanDisplayName: operatorPlanDisplayName(device),
        operatorPlans: availablePlans,
        realmId: realm?.id ?? "",
        hardwareVersion: device?.getAttribute(DeviceAttributeName.hardwareVersion) ?? "",
        softwareVersion: device?.getAttribute(DeviceAttributeName.softwareVersion) ?? "",
        variant: device?.getAttribute(DeviceAttributeName.variant) ?? "",
        callerId: device?.getAttribute(DeviceAttributeName.callerIdNumber) ?? "",
        patientName: patient?.name ?? "",
        externalId1: device?.getAttribute(DeviceAttributeName.externalId1) ?? "",
        externalId2: device?.getAttribute(DeviceAttributeName.externalId2) ?? "",
        externalId3: device?.getAttribute(DeviceAttributeName.externalId3) ?? "",
      };

      setDeviceDetails(updatedDeviceDetails);

      const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
      setChildOrganizations(childOrganizationList);
    };

    initializeData();
  }, [device, realm, patient, currentOrganization, operatorPlans]);

  React.useEffect(() => {
    if (device && realm && operatorPlans.length > 1) initialize();
  }, [device, realm, operatorPlans, initialize]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedDeviceDetails = { ...deviceDetails, [event.target.id]: event.target.value };
    setDeviceDetails(updatedDeviceDetails);
    setIsDirty(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const updatedDeviceDetails = { ...deviceDetails, [event.target.name]: event.target.value };
    setDeviceDetails(updatedDeviceDetails);
    setIsDirty(true);
  };

  const handleCustomInputChange = (id: string, value: string, isError: boolean): void => {
    const updatedDeviceDetails = { ...deviceDetails, [id]: value };
    // Let's keep the device "name" and "externalId1" attributes in sync. Bit clumsy but this is the easiest way to
    // get all the pieces working. In the device listing view we want to user the "name" attribute instead of "externalId1"
    // as using "externalId" would require checking a system parameter for each device. Storing the value only
    // in the "name" attribute complicates the uniqueness check as external identifiers need to be unique.
    if (id === DeviceAttributeName.externalId1) updatedDeviceDetails.deviceName = value;
    setDeviceDetails(updatedDeviceDetails);
    setIsDirty(true);
    setHasError(isError);
  };

  const handleToggleUnsavedDataDialog = (): void => {
    setUnsavedDataDialogOpen(!unsavedDataDialogOpen);
  };

  const handleCloseClick = (): void => {
    if (isDirty) {
      handleToggleUnsavedDataDialog();
    } else {
      onClose();
    }
  };

  const handleSaveChangesClick = async (): Promise<void> => {
    setIsSavingChanges(true);
    try {
      if (!device) return;

      const attributes = [
        { key: DeviceAttributeName.name, value: deviceDetails.deviceName },
        { key: DeviceAttributeName.callerIdNumber, value: deviceDetails.callerId },
        { key: DeviceAttributeName.externalId2, value: deviceDetails.externalId2 },
        { key: DeviceAttributeName.externalId3, value: deviceDetails.externalId3 },
      ];

      if (deviceDetails.operatorPlan && deviceDetails.operatorPlan !== "invalid") {
        attributes.push({ key: DeviceAttributeName.operatorPlan, value: deviceDetails.operatorPlan });
      }

      // If the "externalDeviceIdentifier1" parameter is set, then set "externalId1" attribute
      if (externalParameter1) {
        attributes.push({ key: DeviceAttributeName.externalId1, value: deviceDetails.externalId1 });
      }

      // Attributes need to be updated first. This is because here we check that the device "externalId" values are unique.
      await device.updateAttributes(attributes);
      await device.setOrganization(deviceDetails.realmId);

      setIsDirty(false);
      showSnackbar(
        translations.deviceDetailsDialog.texts.changesSaveSuccessHeader(),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center,
        translations.deviceDetailsDialog.texts.changesSaveSuccessInfo()
      );
      onDetailsUpdated(true);
      onClose();
    } catch (error) {
      if (isError(error) && error.message.match(/device external identifier is not unique/i)) {
        showSnackbar(
          translations.deviceDetailsDialog.texts.changesSaveFailedHeader(),
          SeverityType.Error,
          SnackbarVerticalPosition.Bottom,
          SnackbarHorizontalPosition.Center,
          translations.common.texts.identifierNotUniqueInfo()
        );
      } else {
        showSnackbar(
          translations.deviceDetailsDialog.texts.changesSaveFailedHeader(),
          SeverityType.Error,
          SnackbarVerticalPosition.Bottom,
          SnackbarHorizontalPosition.Center,
          translations.deviceDetailsDialog.texts.changesSaveFailedInfo()
        );
      }
    } finally {
      setIsSavingChanges(false);
    }
  };

  const isSaveChangesButtonDisabled = !isDirty || isSavingChanges || hasError;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        data-testid={TestIds.DeviceDetailsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceDetails.texts.deviceDetails()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceDetailsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <DeviceDetailsDialogContentLeftGrid
              deviceDetails={deviceDetails}
              organizationList={childOrganizations}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              externalParameter1={externalParameter1}
              externalParameter2={externalParameter2}
              externalParameter3={externalParameter3}
              handleCustomInputChange={handleCustomInputChange}
            />
            <DeviceDetailsDialogContentRightGrid deviceDetails={deviceDetails} patient={patient} />
          </Grid>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSaveChangesClick}
            data-testid={TestIds.DeviceDetailsDialog.SubmitButton}
            disabled={isSaveChangesButtonDisabled}
            startIcon={isSavingChanges && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.saveChanges()}
          </Button>
          <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.DeviceDetailsDialog.CancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleToggleUnsavedDataDialog}
        handleSave={handleSaveChangesClick}
        handleDiscard={onClose}
      />
    </React.Fragment>
  );
};

export default DeviceDetailsDialog;
