/* Copyright */
import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { translations } from "../../generated/translationHelper";

type TableEmptyRowProps = {
  colspan: number;
  infoText?: string;
};

const TableEmptyRow: React.FC<TableEmptyRowProps> = ({ colspan, infoText }) => {
  return (
    <TableRow>
      <TableCell colSpan={colspan} align="center">
        <Typography variant="h6" sx={{ alignSelf: "center" }}>
          {infoText ?? translations.common.texts.noDataFound()}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TableEmptyRow;
