/* Copyright */

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MailboxMessage } from "@sade/data-access";
import React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import TableEmptyRow from "../../../common/table-empty-row";
import TableLoadingRow from "../../../common/table-loading-row";
import MailboxTableRow from "./mailbox-table-row";

type MailboxTableProps = {
  messages: MailboxMessage[];
  isLoading: boolean;
};

const MailboxTable: React.FC<MailboxTableProps> = ({ messages, isLoading }) => {
  return (
    <Box sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.DeviceMailboxDialog.TableHeader}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.mailboxDialog.texts.created()}</TableCell>
              <TableCell>{translations.mailboxDialog.texts.topic()}</TableCell>
              <TableCell>{translations.mailboxDialog.texts.read()}</TableCell>
              <TableCell>{translations.mailboxDialog.texts.status()}</TableCell>
              <TableCell>{translations.mailboxDialog.texts.body()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.length > 0 && !isLoading
              ? messages.map((message, index) => (
                  <MailboxTableRow key={index} message={message} tableRowIndex={index} />
                ))
              : !isLoading && <TableEmptyRow colspan={5} infoText={translations.mailboxDialog.texts.noMessages()} />}
            {isLoading && <TableLoadingRow colspan={5} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MailboxTable;
