/* Copyright */
import * as React from "react";
import {
  FormControl,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  SelectChangeEvent,
  Button,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccessControl from "../../access-control/access-control";
import { BackendFactory, DeviceAction, Maybe, RoleIdentifier, SimAction } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { DeviceBulkActions, OrganizationInfo, getChildOrganizations, getErrorMessage } from "../../../utils/utils";
import FileUpload from "../../common/file-upload";
import { SeverityType } from "../../../context/snackbar-context";
import CustomTextField from "../../common/textfield";
import RealmPicker from "../../common/realm-picker";

const DeviceBulkActionsView: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const backend = BackendFactory.getBackend();
  const [childOrganizations, setChildOrganizations] = React.useState<OrganizationInfo[]>([]);
  const [selectedNewOrganization, setSelectedNewOrganization] = React.useState<string>("");
  const [selectedImeiInputMethod, setSelectedImeiInputMethod] = React.useState<"imei" | "csv">("imei");
  const [selectedImeis, setSelectedImeis] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<Maybe<File>>(undefined);
  const [sendWakeUpSMS, setSendWakeUpSMS] = React.useState<boolean>(false);
  const [selectedActions, setSelectedActions] = React.useState<DeviceBulkActions[]>([]);

  React.useEffect(() => {
    const getOrganizations = async (): Promise<void> => {
      const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
      setChildOrganizations(childOrganizationList);
    };
    getOrganizations();
  }, [currentOrganization]);

  const handleActionSelectChange = (selectedAction: DeviceBulkActions): void => {
    const actions = [...selectedActions];
    actions.some((action) => action === selectedAction)
      ? actions.splice(actions.indexOf(selectedAction), 1)
      : actions.push(selectedAction);
    setSelectedActions(actions);
    setSelectedNewOrganization("");
  };

  const handleImeiInputMethodChange = (event: SelectChangeEvent): void => {
    setSelectedImeiInputMethod(event.target.value as "imei" | "csv");
    setSelectedImeis([]);
  };

  const handleImeiInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const textValue = event.target.value;
    const imeiArray = textValue
      .split("\n")
      .map((imei) => imei.trim())
      .filter(Boolean);
    setSelectedImeis(imeiArray);
  };

  const parseCSV = (csvData: string): string[] => {
    const rows = csvData.trim().split(",");
    if (rows[rows.length - 1] === "") {
      rows.pop();
    }
    return rows;
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event): void => {
        const csvData = event.target?.result as string;
        const parsedData = parseCSV(csvData);
        setSelectedImeis(parsedData);
      };
    }

    setSelectedFile(file);
  };

  const performAction = async (selectedImeis: string[], selectedAction: DeviceBulkActions): Promise<void> => {
    switch (selectedAction) {
      case DeviceBulkActions.ACTIVATE: {
        await backend.requestSimManagementAction({
          deviceIds: selectedImeis,
          simAction: SimAction.Activate,
          sendWakeUpSMS: sendWakeUpSMS,
        });
        break;
      }
      case DeviceBulkActions.DEACTIVATE: {
        await backend.requestSimManagementAction({
          deviceIds: selectedImeis,
          simAction: SimAction.Deactivate,
        });
        break;
      }
      case DeviceBulkActions.CHANGE_REALM:
        {
          await backend.changeOrganizationForDevices(selectedImeis, selectedNewOrganization);
        }
        break;
      case DeviceBulkActions.RETIRE:
        {
          await backend.retireDevices(selectedImeis);
        }
        break;
      case DeviceBulkActions.RESET_DEVICE_KEY:
        {
          await backend.requestActionForDevices(selectedImeis, DeviceAction.ResetKey, false);
        }
        break;
      case DeviceBulkActions.RESET_SETTINGS:
        {
          await backend.resetDevices(selectedImeis);
          await backend.requestActionForDevices(selectedImeis, DeviceAction.UpdateConfiguration, sendWakeUpSMS);
        }
        break;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);

    let selectedAction: Maybe<DeviceBulkActions>;
    try {
      for (selectedAction of selectedActions) {
        await performAction(selectedImeis, selectedAction);
        showSnackbar(
          translations.deviceBulkActions.texts.operationSuccessful({
            operationName: selectedAction ? selectedAction : "",
          }),
          SeverityType.Success
        );
      }
    } catch (error) {
      showSnackbar(
        "",
        SeverityType.Error,
        undefined,
        undefined,
        translations.deviceBulkActions.texts.operationFailed({
          operationName: selectedAction ? selectedAction : "",
          error: getErrorMessage(error),
        })
      );
    }
    setIsLoading(false);
  };

  const isSubmitDisabled =
    isLoading ||
    !selectedActions?.length ||
    (selectedActions.some((action) => action === DeviceBulkActions.CHANGE_REALM) && !selectedNewOrganization) ||
    (selectedImeiInputMethod === "imei" && !selectedImeis.length) ||
    (selectedImeiInputMethod === "csv" && !selectedFile);

  return (
    <Box sx={{ padding: "2rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.PowerUser, RoleIdentifier.Viewer]}>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            {translations.deviceBulkActions.texts.title()}
          </Typography>
          <Typography>{translations.deviceBulkActions.texts.info()}</Typography>
        </Box>
        <Box maxWidth={748}>
          <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
            {Object.values(DeviceBulkActions).map((action, index) => {
              const selected = selectedActions.some((a) => a === action);
              return (
                <Box key={index}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={selected}
                    onChange={(): void => handleActionSelectChange(action)}
                  />
                  {translations.deviceBulkActions.actions[
                    action as keyof typeof translations.deviceBulkActions.actions
                  ]?.() ?? action}
                </Box>
              );
            })}
          </FormControl>
          {selectedActions.some((action) => action === DeviceBulkActions.CHANGE_REALM) && (
            <FormControl className="input" sx={{ textAlign: "start", mt: 4, mr: 1 }} fullWidth>
              <RealmPicker
                childOrganizations={childOrganizations}
                testId="realm-picker"
                onRealmPicked={(realmId: string): void => setSelectedNewOrganization(realmId)}
                isLoading={isLoading}
              />
            </FormControl>
          )}
          {selectedActions.some((action) =>
            [DeviceBulkActions.ACTIVATE, DeviceBulkActions.RESET_SETTINGS].includes(action)
          ) && (
            <FormControl sx={{ mt: 2 }}>
              <Box>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={sendWakeUpSMS}
                  onChange={(): void => setSendWakeUpSMS(!sendWakeUpSMS)}
                />
                {translations.deviceBulkActions.texts.sendWakeUpSMS()}
              </Box>
            </FormControl>
          )}
          <FormControl sx={{ mt: 2 }}>
            <RadioGroup row id="imeiInputMethod" value={selectedImeiInputMethod} onChange={handleImeiInputMethodChange}>
              <FormControlLabel
                value={"imei"}
                control={<Radio size="small" />}
                label={translations.common.texts.enterTheImeiCodes()}
              />
              <FormControlLabel
                value={"csv"}
                control={<Radio size="small" />}
                label={translations.common.texts.uploadImeiCodes()}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            {selectedImeiInputMethod === "imei" && (
              <CustomTextField
                id="imei-input"
                testId=""
                multiline
                type="number"
                minRows={3}
                maxRows={8}
                label={translations.common.texts.imeis()}
                placeholder={translations.common.texts.enterImeisPlaceholder()}
                onChange={handleImeiInputChange}
              />
            )}
            {selectedImeiInputMethod === "csv" && (
              <FileUpload
                infoText={translations.common.texts.uploadImeisPlaceholder()}
                acceptedFileType="text/csv"
                handleFileChange={handleFileChange}
              />
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              data-testid=""
              startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
            >
              {translations.common.buttons.submit()}
            </Button>
          </Box>
        </Box>
      </AccessControl>
    </Box>
  );
};

export default DeviceBulkActionsView;
