/* Copyright */
import * as React from "react";
import {
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import { Maybe, Role } from "@sade/data-access";
import DeleteIcon from "@mui/icons-material/Delete";
import { translations } from "../../../generated/translationHelper";
import { UserRealmRole } from "../../../utils/utils";
//import { RoleIdentifier } from "@sade/data-access";
import TestIds from "../../../test-ids/test-ids";

type AccountDetailsRealmInformationTableRowProps = {
  userRealmAccessCount: number;
  userRealmRole: UserRealmRole;
  allRoles: Maybe<Role[]>;
  isAccountEnabled: boolean;
  isExternalUser: boolean;
  handleOpenRevokeRealmAccessDialog: (realm: UserRealmRole) => void;
  handleRealmRoleSelectChange: (realmId: string, role: string) => void;
  realmIndex: number;
};

const AccountDetailsRealmInformationTableRow: React.FC<AccountDetailsRealmInformationTableRowProps> = ({
  userRealmAccessCount,
  userRealmRole,
  allRoles,
  isAccountEnabled,
  isExternalUser,
  handleOpenRevokeRealmAccessDialog,
  handleRealmRoleSelectChange,
  realmIndex,
}) => {
  const [selectedRole, setSelectedRole] = React.useState<string>(userRealmRole.role);

  const handleRoleChange = (event: SelectChangeEvent): void => {
    if (userRealmRole) {
      setSelectedRole(event.target.value);
      handleRealmRoleSelectChange(userRealmRole.realmId, event.target.value);
    }
  };

  const isRoleAssignable = userRealmRole.assignableRoles.map((role) => role.identifier).includes(selectedRole);
  //const indexOfRoleIdentifier = (Object.values(RoleIdentifier) as string[]).indexOf(userRealmRole.role);
  const indexOfRole = allRoles?.findIndex((role) => {
    return role.identifier === userRealmRole.role;
  });
  const selectedRoleName = allRoles && indexOfRole ? allRoles[indexOfRole].name : "";

  return (
    <TableRow data-testid={TestIds.AccountDetailsDialog.RealmRow} hover>
      <TableCell data-testid={`${TestIds.AccountDetailsDialog.Realm}-${realmIndex}`}>
        {userRealmRole.realmName}
      </TableCell>
      {!isRoleAssignable ? (
        <TableCell data-testid={`${TestIds.AccountDetailsDialog.Role}-${realmIndex}`}>{selectedRoleName}</TableCell>
      ) : (
        <TableCell>
          <FormControl>
            <Select
              id="role"
              size="small"
              variant="standard"
              disabled={isExternalUser}
              disableUnderline
              value={selectedRole}
              onChange={handleRoleChange}
              data-testid={`${TestIds.AccountDetailsDialog.RoleSelect}-${realmIndex}`}
            >
              {!userRealmRole.assignableRoles.map((role) => role.identifier === userRealmRole.role) && (
                <MenuItem value={userRealmRole.role}>
                  <ListItemText primary={selectedRoleName} />
                </MenuItem>
              )}
              {userRealmRole.assignableRoles.map((role, index) => (
                <MenuItem key={index} value={role.identifier} data-option-index={index}>
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
      )}
      <TableCell>
        <Button
          size="small"
          variant="outlined"
          startIcon={<DeleteIcon />}
          color="error"
          disabled={
            userRealmAccessCount < 2 ||
            !isAccountEnabled ||
            userRealmRole.isHomeOrg ||
            !isRoleAssignable ||
            isExternalUser
          }
          onClick={(): void => handleOpenRevokeRealmAccessDialog(userRealmRole)}
          data-testid={`${TestIds.AccountDetailsDialog.RevokeRealmAccess}-${realmIndex}`}
        >
          {translations.common.texts.revokeAccess()}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AccountDetailsRealmInformationTableRow;
