/* Copyright */
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Chip, TableCell, TableRow } from "@mui/material";
import { TeamMember } from "@sade/data-access";
import * as React from "react";
import theme from "../../../../../app/theme";
import { translations } from "../../../../../generated/translationHelper";
import TestIds from "../../../../../test-ids/test-ids";
import { getUserInitials, solveEverThereMemberProps } from "../../../../../utils/utils";
import EverThereAccountsDialogContentDataRowMenu from "./everthere-accounts-dialog-content-data-row-menu";

type EverThereAccountsDialogContentDataRowProps = {
  deviceId: string;
  member: TeamMember;
  handleDeleteMember: (member: TeamMember) => void;
  handleOpenTeamManagerDialog: (member: TeamMember) => void;
  handleOpenRemoveDeviceDialog: (member: TeamMember) => void;
  handleOpenMakeMemberAWearerDialog: (member: TeamMember) => void;
  userIndex?: number;
};

const EverThereAccountsDialogContentDataRow: React.FC<EverThereAccountsDialogContentDataRowProps> = ({
  deviceId,
  member,
  handleDeleteMember,
  handleOpenTeamManagerDialog,
  handleOpenRemoveDeviceDialog,
  handleOpenMakeMemberAWearerDialog,
  userIndex,
}) => {
  const memberProps = solveEverThereMemberProps(member, deviceId);

  const initials = getUserInitials(member.firstName ?? "", member.lastName ?? "");

  const roleText = memberProps.isManager
    ? translations.common.texts.teamManager()
    : translations.common.texts.teamMember();

  return (
    <TableRow data-testid={`${TestIds.DeviceEverThereAccountsDialog.DataRow}-${userIndex}`}>
      <TableCell
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        data-testid={`${TestIds.DeviceEverThereAccountsDialog.UserEmailCell}-${userIndex}`}
      >
        <Avatar sx={{ bgcolor: memberProps.colorScheme, mr: 2 }}>
          {initials && !memberProps.isPending ? initials : <PersonIcon />}
        </Avatar>
        {member.email}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceEverThereAccountsDialog.ManagerCell}-${userIndex}`}>
        {memberProps.isManager ? (
          <Chip size="small" label={roleText} sx={{ bgcolor: theme.palette.purple.main, color: "white" }}></Chip>
        ) : (
          roleText
        )}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceEverThereAccountsDialog.WearerCell}-${userIndex}`}>
        {memberProps.isWearer ? (
          <Chip
            size="small"
            label={translations.common.texts.wearer()}
            sx={{ bgcolor: theme.palette.primary.light, color: memberProps.isPending ? "" : "white" }}
          ></Chip>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceEverThereAccountsDialog.InvitationStatusCell}-${userIndex}`}>
        {memberProps.invitationAccepted ? (
          <Chip
            size="small"
            label={translations.common.texts.active()}
            sx={{ bgcolor: theme.palette.success.main, color: "white" }}
          />
        ) : (
          <Chip
            size="small"
            label={
              memberProps.isExpiredInvitation
                ? translations.common.texts.expired()
                : memberProps.isDeclined
                ? translations.common.texts.declined()
                : translations.common.texts.pending()
            }
          />
        )}
      </TableCell>
      <TableCell>
        <EverThereAccountsDialogContentDataRowMenu
          deviceId={deviceId}
          member={member}
          memberProps={memberProps}
          handleDeleteMember={handleDeleteMember}
          handleOpenTeamManagerDialog={handleOpenTeamManagerDialog}
          handleOpenRemoveDeviceDialog={handleOpenRemoveDeviceDialog}
          handleOpenMakeMemberAWearerDialog={handleOpenMakeMemberAWearerDialog}
          userIndex={userIndex}
        />
      </TableCell>
    </TableRow>
  );
};

export default EverThereAccountsDialogContentDataRow;
