/* Copyright */
export var ParameterType;
(function (ParameterType) {
    ParameterType["device"] = "device";
    ParameterType["system"] = "system";
})(ParameterType || (ParameterType = {}));
export class ParameterName {
    constructor(params) {
        this.type = params.type;
        this.name = params.name;
        this.legacyName = params.legacyName;
        this.valueType = params.valueType;
    }
}
