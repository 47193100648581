/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TeamMember, Maybe, Team } from "@sade/data-access";
import { translations } from "../../../../../generated/translationHelper";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { SeverityType } from "../../../../../context/snackbar-context";
import TestIds from "../../../../../test-ids/test-ids";

type RemoveMemberFromTeamDialogProps = {
  open: boolean;
  everThereData: Maybe<Team>;
  member: TeamMember;
  handleClose: () => void;
};

const RemoveMemberFromTeamDialog: React.FC<RemoveMemberFromTeamDialogProps> = ({
  open,
  everThereData,
  member,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const memberFirstName = member.firstName ?? member.email ?? "";
  const handleSubmitClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await everThereData?.removeTeamMember(member.userId);

      showSnackbar(
        translations.deviceEverThereRemoveTeamMemberDialog.texts.successPopUp({ memberFirstName }),
        SeverityType.Success,
        undefined,
        undefined
      );
    } catch (error) {
      let errorCode = "unknown";

      if (error instanceof Error) {
        errorCode = error.message;
      }

      console.error("error", errorCode);

      showSnackbar(
        translations.deviceEverThereRemoveTeamMemberDialog.texts.failurePopUp({ memberFirstName }),
        SeverityType.Error,
        undefined,
        undefined,
        translations.deviceEverThereRemoveTeamMemberDialog.texts.failureDescription({ errorCode })
      );
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DeviceEverThereRemoveMemberFromTeamDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereRemoveTeamMemberDialog.texts.title({ memberFirstName })}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            data-testid={TestIds.DeviceEverThereRemoveMemberFromTeamDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {translations.deviceEverThereRemoveTeamMemberDialog.texts.content({ memberFirstName })}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleSubmitClick}
            data-testid={TestIds.DeviceEverThereRemoveMemberFromTeamDialog.ConfirmButton}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.confirm()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            data-testid={TestIds.DeviceEverThereRemoveMemberFromTeamDialog.ActionCloseButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RemoveMemberFromTeamDialog;
