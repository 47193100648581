/* Copyright */
import React from "react";

const CellularSignal2Bars: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 12H8V21H11V12ZM6 16H3V21H6V16Z" fill="#0288D1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H20V20H19V4ZM18 3H19H20H21V4V20V21H20H19H18V20V4V3ZM14 8H15V20H14V8ZM13 7H14H15H16V8V20V21H15H14H13V20V8V7Z"
        fill="#0288D1"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default CellularSignal2Bars;
