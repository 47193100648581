/* Copyright */
import {
  BackendFactory,
  CognitoAccountStatus,
  DeviceInformation,
  DeviceStatus,
  EventPushType,
  Maybe,
  Nullable,
  ParameterValueType,
  ParameterValueTypeString,
  PeripheralState,
  PeripheralType,
  Role,
  RoleIdentifier,
  TeamMember,
  UserInvitationStatus,
  UserType,
  isError,
  isObject,
} from "@sade/data-access";
import { createHmac } from "crypto";
import { differenceInHours, differenceInMinutes } from "date-fns";
import i18next from "i18next";
import { DeviceOperatorPlan } from "../../data-access-module/src/data/device/DeviceOperatorPlan";
import theme from "../app/theme";
import { translations } from "../generated/translationHelper";

export type UserStatus = {
  name: UserStatusNames;
  identifiers: string[];
};

export type OrganizationInfo = {
  id: string;
  fullName: string;
};

export type PatientInfo = {
  id: string;
  name: string;
  customerId?: string;
};

export const NAVIGATE_BACK = -1;

export const PATIENT_PREFIX = "PATIENT/";
export enum Paths {
  ROOT = "",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTRATION = "registration",
  FORGOTPASSWORD = "forgot-password",
  MONITORING = "monitoring",
  ACTIVE_CALL = "active-call/:deviceId/:eventId",
  DEVICES = "devices",
  DEVICE = "devices/:deviceId",
  EVENTS_AND_LOCATIONS = ":parent/:deviceId/events-and-locations",
  ACCOUNTS = "accounts",
  REPORTS = "reports",
  SUPPORT = "https://support.numera.com/", //"/support",
  PROFILE = "profile",
  WHERE_TO_BUY = "https://www.numera.com/where-to-buy/",
  PRIVACY = "https://na.niceforyou.com/legal/privacy-policy/", //"/privacy",
  TERMS_OF_USE = "https://na.niceforyou.com/legal/terms-and-conditions-of-purchase/", //"/terms-of-use",
  PARTNERS = "partners",
  PARTNER = "partners/:partnerId",
  PARTNER_ADD = "partners/add",
  DEVICE_BULK_ACTIONS = "admin/device-bulk-actions",
  SEND_DEVICE_COMMANDS = "admin/send-device-commands",
  OTA_PACKAGES = "admin/ota-packages",
  DEVICE_REPORTS = "admin/device-reports",
}

export enum NavTabs {
  MONITORING = "monitoring",
  DEVICES = "devices",
  ACCOUNTS = "accounts",
  REPORTS = "reports",
  PARTNERS = "partners",
  ADMIN = "admin",
}

export enum DeviceBulkActions {
  CHANGE_REALM = "changeRealm",
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate",
  RETIRE = "retire",
  RESET_DEVICE_KEY = "resetDeviceKey",
  RESET_SETTINGS = "resetSettings",
}

export function trimPhoneNumber(phoneNumber: string, countryCode: string): Maybe<string> {
  const trimmedPhoneNumber = phoneNumber.replace(/([^\w]+|\s+)/g, "");
  if (/^[0-9]+$/.test(trimmedPhoneNumber)) {
    return countryCode + trimmedPhoneNumber;
  }
}

export function validatePassword(password: string): boolean {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+-.=?@_])(?=.{10,})/.test(password);
}

export enum UserStatusNames {
  PENDING = "Pending",
  ACTIVE = "Active",
  DISABLED = "Disabled",
  UNKNOWN = "Unknown",
  EXTERNAL_PROVIDER = "External Provider",
}

export const UserStatuses: UserStatus[] = [
  {
    name: UserStatusNames.PENDING,
    identifiers: [
      CognitoAccountStatus.Unconfirmed,
      CognitoAccountStatus.ResetRequired,
      CognitoAccountStatus.ForceChangePassword,
    ],
  },
  { name: UserStatusNames.ACTIVE, identifiers: [CognitoAccountStatus.Confirmed] },
  { name: UserStatusNames.DISABLED, identifiers: [CognitoAccountStatus.Archived] },
  { name: UserStatusNames.UNKNOWN, identifiers: [CognitoAccountStatus.Unknown] },
  { name: UserStatusNames.EXTERNAL_PROVIDER, identifiers: [CognitoAccountStatus.ExternalProvider] },
];

export const getChildOrganizations = async (
  currentOrganizationId: string,
  recursive = true
): Promise<OrganizationInfo[]> => {
  const organizationList: OrganizationInfo[] = [];
  try {
    if (currentOrganizationId) {
      const backend = BackendFactory.getOrganizationBackend();
      const currentOrganization = await backend.getOrganization(currentOrganizationId);
      if (currentOrganization) {
        const childOrganizations = await currentOrganization.getChildOrganizations(recursive);

        const result = childOrganizations.map((organization) => ({
          id: organization.getId(),
          fullName: organization.getFullName(),
        }));

        result.sort((a, b) => a.fullName.localeCompare(b.fullName));
        result.forEach((organizationInfo) => {
          organizationList.push(organizationInfo);
        });
        organizationList.unshift({ id: currentOrganization.getId(), fullName: currentOrganization.getFullName() });
      }
    }
  } catch (error) {
    console.error("error", error);
    throw new Error("Fetching organizations failed");
  }

  return organizationList;
};

export const getAllRoles = async (): Promise<Role[]> => {
  let roles: Role[] = [];

  try {
    const organization = BackendFactory.getOrganizationBackend();
    roles = await organization.listRoles();
  } catch {
    throw new Error("Fetching roles failed");
  }

  return roles;
};

export const getAssignableRoles = async (realmId: string): Promise<Role[]> => {
  let roles: Role[] = [];

  try {
    const organization = BackendFactory.getOrganizationBackend();
    const currentUser = await organization.getCurrentUser();
    if (!currentUser) throw new Error("No logged in user!");
    const availableRoles = await currentUser.getRolesUserCanAssign(realmId);
    roles = availableRoles;
  } catch {
    throw new Error("Fetching assignable roles failed");
  }

  return roles;
};

export type AccountFilters = {
  statuses: CognitoAccountStatus[];
  realms: string[];
  roles: RoleIdentifier[];
  includeDisabled: boolean;
  userType: UserType;
};

export type UserRealmRole = {
  realmId: string;
  realmName: string;
  isHomeOrg: boolean;
  role: string;
  assignableRoles: Role[];
};

export enum LanguageMap {
  ENGLISH = "en",
  FRENCH = "fr",
}

export const getPeripheralTypeTranslation = (type: PeripheralType): string => {
  switch (type) {
    case PeripheralType.BasicPendant:
      return translations.devicePeripherals.peripheralTypes.basicPendant();
    case PeripheralType.Thermometer:
      return translations.devicePeripherals.peripheralTypes.thermometer();
    case PeripheralType.Oxymeter:
      return translations.devicePeripherals.peripheralTypes.oxymeter();
    case PeripheralType.WeightScale:
      return translations.devicePeripherals.peripheralTypes.weightScale();
    case PeripheralType.BloodPressureMonitor:
      return translations.devicePeripherals.peripheralTypes.bloodPressureMonitor();
    case PeripheralType.GlucoseMeter:
      return translations.devicePeripherals.peripheralTypes.glucoseMonitor();
    default:
      return translations.devicePeripherals.peripheralTypes.unknown();
  }
};

export type Peripheral = {
  serialNumber: string;
  type: PeripheralType;
};

export const DEFAULT_LANGUAGE = "English";

export const getUserInitials = (firstName: string, lastName: string): string => {
  const fChar = firstName.substring(0, 1).toLocaleUpperCase();
  const lChar = lastName.substring(0, 1).toLocaleUpperCase();
  return fChar + lChar;
};

export const getUserFullName = (firstName: string, lastName: string): string => {
  return [firstName, lastName].join(" ");
};

export type UserOrganizationWithRole = {
  id: string;
  fullName: string;
  role: string;
};

export type DeviceListItem = DeviceInformation & {
  realmName: string;
};

export type PeripheralListItem = {
  serialNumber: string;
  type: PeripheralType;
  state: PeripheralState;
};

export type DeviceStatusListItem = {
  deviceId: string;
  status: string;
  imei: string;
  callerId: string;
  softwareVersion: string;
  eventType: string;
  eventDate: string;
};

export type DeviceReportItem = {
  status?: DeviceStatus;
  deviceName?: string;
  imei: string;
  createdOn?: string;
  callerId?: string;
  iccid?: string;
  realmName?: string;
  sku?: string;
  network?: string;
  authKey?: string;
  validity: boolean;
};

export enum ParameterNames {
  SOFTWARE_RELEASE = "libris2Release",
  VARIANT = "libris2SoftwareVariant",
  CALL_IN_SERVICE_1 = "callInService1",
  CALL_IN_SERVICE_2 = "callInService2",
  CALL_IN_SERVICE_3 = "callInService3",
  FALL_DETECTION_ENABLED = "fallDetectionEnabled",
  INACTIVE_MONITOR_ENABLED = "inactiveMonitorEnabled",
  INACTIVE_CHARGER_MINUTES = "inactiveChargerTimeMinutes",
  INACTIVE_MOTION_MINUTES = "inactiveMotionTimeMinutes",
  AUDIO_CALL_VOLUME = "audioMasterVolume",
  AUDIO_PROMPT_VOLUME = "playbackVolume",
  PERIODIC_INDICATOR_FEEDBACK = "powerHeartBeatInterval",
  TAP_INDICATOR_ENABLED = "tapIndicatorEnabled",
  SILENT_MODE_ENABLED = "silentModeEnabled",
  EXTERNAL_DEVICE_IDENTIFIER_1 = "externalDeviceIdentifier1",
  EXTERNAL_DEVICE_IDENTIFIER_2 = "externalDeviceIdentifier2",
  EXTERNAL_DEVICE_IDENTIFIER_3 = "externalDeviceIdentifier3",
  DEVICE_ACTIVATION_REALMS = "deviceActivationRealms",
  FIND_MY_DEVICE_ENABLED = "findMyDeviceEnabled",
  EVERTHERE_ENABLED = "everThereEnabled",
  DEMO_MODE_ENABLED = "demoModeEnabled",
  FIRST_TIME_AUDIO_PLAYED = "firstTimeAudioPlayed",
}

export const resolveDeviceStatusChipColor = (deviceStatus: DeviceStatus): string => {
  switch (deviceStatus) {
    case DeviceStatus.active:
      return theme.palette.success.main;
    case DeviceStatus.activating:
      return theme.palette.primary.main;
    case DeviceStatus.retired:
      return theme.palette.error.main;
    case DeviceStatus.unmanaged:
      return theme.palette.secondary.main;
    default:
      return theme.palette.warning.dark;
  }
};

export const resolvePeripheralStateChipColor = (peripheralState: string): string => {
  switch (peripheralState) {
    case PeripheralState.Paired:
      return theme.palette.success.main;
    case PeripheralState.Unpairing:
      return theme.palette.orange.main;
    case PeripheralState.Whitelisted:
      return theme.palette.grey[300];
    case "error":
      return theme.palette.error.main;
    default:
      return theme.palette.warning.dark;
  }
};

export const resolvePeripheralStateCircleColor = (peripheralState: string): string => {
  switch (peripheralState) {
    case PeripheralState.Paired:
      return "rgba(200,247,197,0.2)";
    case PeripheralState.Unpairing:
      return "rgba(249,105,14,0.2)";
    case PeripheralState.Whitelisted:
      return theme.palette.grey[200];
    case "error":
      return "rgba(255,76,48,0.2)";
    default:
      return "rgba(249,105,14,0.2)";
  }
};

export const resolvePeripheralStateIconColor = (peripheralState: string): string => {
  switch (peripheralState) {
    case PeripheralState.Paired:
      return theme.palette.success.main;
    case PeripheralState.Unpairing:
      return theme.palette.orange.main;
    case PeripheralState.Whitelisted:
      return theme.palette.grey[600];
    case "error":
      return theme.palette.error.main;
    default:
      return theme.palette.warning.dark;
  }
};

export const convertMillisecondsToSeconds = (milliseconds: number): number => {
  return Math.floor(milliseconds / 1000);
};

export const convertSecondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60);
};

export const convertMinutesToHours = (minutes: number): number => {
  return Math.floor(minutes / 60);
};

export const formatTimestamp = (timestamp: number | Date): string => {
  const dateTime = new Date(timestamp);
  const dateString = `${dateTime.toLocaleTimeString()} ${dateTime.toLocaleDateString("default", {
    month: "short",
    year: "numeric",
    day: "numeric",
  })}`;
  return dateString;
};

export const fortmatTimestampToShortDate = (timestamp: number | Date): string => {
  const date = new Date(timestamp);

  return new Intl.DateTimeFormat(i18next.language, {
    month: "short",
    day: "numeric",
  })
    .format(date)
    .toUpperCase();
};

export const formatTimeFromTimestamp = (timestamp: number | Date, showSeconds?: boolean): string => {
  const date = new Date(timestamp);

  return new Intl.DateTimeFormat(i18next.language, {
    hour: "numeric",
    minute: "numeric",
    second: showSeconds ? "numeric" : undefined,
    hour12: true,
  }).format(date);
};

export const twoMonthsInMilliseconds = 1000 * 60 * 60 * 24 * 60;

export const dayInMilliseconds = 1000 * 60 * 60 * 24;

export const calculateDuration = (timeStampStart: Date, timestampEnd: Date): string => {
  const timeDifference = Math.abs(timestampEnd.getTime() - timeStampStart.getTime());

  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = 60 * millisecondsPerSecond;
  const millisecondsPerHour = 60 * millisecondsPerMinute;

  const hours = Math.floor(timeDifference / millisecondsPerHour);
  const minutes = Math.floor((timeDifference % millisecondsPerHour) / millisecondsPerMinute);
  const seconds = Math.floor((timeDifference % millisecondsPerMinute) / millisecondsPerSecond);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const calculateFromNow = (timestamp: number | Date): string => {
  const now = new Date();
  const differenceInMins = differenceInMinutes(now, new Date(timestamp));
  const differenceInHrs = differenceInHours(now, new Date(timestamp));

  switch (true) {
    case differenceInMins < 60:
      return translations.common.texts.minutesAgo({ minutes: differenceInMins });
    case differenceInMins < 120:
      return translations.common.texts.hourAgo();
    default:
      return translations.common.texts.hoursAgo({ hours: differenceInHrs });
  }
};

/**
 * Format a time duration in milliseconds to a string with leading zeros (e.g. 01:01:01)
 * @param duration Time duration in milliseconds
 * @returns Formatted time duration string
 */
export const formatDuration = (duration: number): string => {
  const hours = convertMinutesToHours(convertSecondsToMinutes(convertMillisecondsToSeconds(duration)));
  const minutes = convertSecondsToMinutes(convertMillisecondsToSeconds(duration)) % 60;
  const seconds = convertMillisecondsToSeconds(duration) % 60;
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const durationString = `${hoursString}:${minutesString}:${secondsString}`;
  return durationString;
};

export type DateRange = {
  startDate?: Nullable<Date>;
  endDate?: Nullable<Date>;
};

export type everThereMemberProps = {
  isManager: boolean;
  isWearer: boolean;
  isTeamMember: boolean;
  invitationAccepted: boolean;
  isPending: boolean;
  isExpiredInvitation: boolean;
  isDeclined: boolean;
  colorScheme: string;
};

export const solveEverThereMemberProps = (member: TeamMember, deviceId?: string): everThereMemberProps => {
  const solveColorScheme = (): string => {
    const colorScheme = member.isTeamManager
      ? member.invitationStatus === UserInvitationStatus.Pending ||
        member.invitationStatus === UserInvitationStatus.Expired ||
        member.invitationStatus === UserInvitationStatus.Declined
        ? ""
        : theme.palette.purple.main
      : member.invitationStatus === UserInvitationStatus.Pending ||
        member.invitationStatus === UserInvitationStatus.Expired ||
        member.invitationStatus === UserInvitationStatus.Declined
      ? ""
      : (deviceId ? member.wearerOfDevice === deviceId : member.wearerOfDevice !== null) &&
        member.invitationStatus === UserInvitationStatus.Accepted
      ? theme.palette.primary.light
      : theme.palette.primary.main;

    return colorScheme;
  };

  const state: everThereMemberProps = {
    isManager: member.isTeamManager,
    isWearer: deviceId ? member.wearerOfDevice === deviceId : member.wearerOfDevice !== null,
    isTeamMember: member.wearerOfDevice === null && member.invitationStatus === UserInvitationStatus.Accepted,
    invitationAccepted: member.invitationStatus === UserInvitationStatus.Accepted,
    isPending: member.invitationStatus === UserInvitationStatus.Pending,
    isExpiredInvitation: member.invitationStatus === UserInvitationStatus.Expired,
    isDeclined: member.invitationStatus === UserInvitationStatus.Declined,
    colorScheme: solveColorScheme(),
  };

  return state;
};

export const generateRandomKey = (): string => {
  const keyBuffer = Buffer.from(`${Date.now()}${Math.random()}`);
  const hash = createHmac("sha256", keyBuffer).update(keyBuffer).digest("hex");
  return hash;
};

export const getErrorMessage = (error: unknown): string => {
  return isObject(error) && isError(error) && typeof error.message === "string" ? error.message : "";
};

export type DeviceDetails = {
  deviceName: string;
  deviceStatus?: DeviceStatus;
  imei: string;
  iccid: string;
  serialNumber: string;
  networkOperator: string;
  networkOperatorDisplayName: string;
  operatorPlan?: string;
  operatorPlanDisplayName: string;
  operatorPlans: DeviceOperatorPlan[];
  realmId: string;
  hardwareVersion: string;
  softwareVersion: string;
  variant: string;
  callerId: string;
  patientName: string;
  patientId?: string;
  externalId1: string;
  externalId2: string;
  externalId3: string;
};

export type DeviceSettings = {
  [ParameterNames.FALL_DETECTION_ENABLED]: boolean;
  [ParameterNames.SILENT_MODE_ENABLED]: boolean;
  [ParameterNames.DEMO_MODE_ENABLED]: boolean;
  [ParameterNames.TAP_INDICATOR_ENABLED]: boolean;
  [ParameterNames.INACTIVE_MONITOR_ENABLED]: boolean;
  [ParameterNames.INACTIVE_CHARGER_MINUTES]: number;
  [ParameterNames.INACTIVE_MOTION_MINUTES]: number;
  [ParameterNames.PERIODIC_INDICATOR_FEEDBACK]: number;
  [ParameterNames.AUDIO_PROMPT_VOLUME]: number;
  [ParameterNames.AUDIO_CALL_VOLUME]: number;
  [ParameterNames.CALL_IN_SERVICE_1]: string;
  [ParameterNames.CALL_IN_SERVICE_2]: string;
  [ParameterNames.CALL_IN_SERVICE_3]: string;
  [ParameterNames.VARIANT]: string;
};

export type PartnerDetails = {
  partnerId?: string;
  parentId?: string;
  parentName?: string;
  partnerName: string;
  realmName: string;
  networkId?: string;
  netsuiteId?: string;
  patientTextUrl?: string;
  variant?: string;
  ssoIdentityProvider?: string;
  ssoDefaultRole?: string;
};

export type EventPushSettings = {
  enabled: boolean;
  pushUrl1: string;
  pushUrl2: string;
  pushUrl3: string;
  eventPushType: EventPushType;
  pushRules: string;
  pushConfiguration: string;
};

export type PartnerParameter = {
  parameterName: string;
  parameterValue: ParameterValueType;
  parameterType: ParameterValueTypeString;
};

export type LoginFormProps = {
  logout?: boolean;
};
