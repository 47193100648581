/* Copyright */
import { Card, CardContent, CardHeader } from "@mui/material";
import { Event, FallDetectionDisposition, Maybe } from "@sade/data-access";
import React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import EventConfirmation from "./event-confirmation";
import EventTypeItem from "./event-type-item";

type EventCardProps = {
  event: Maybe<Event>;
  toggleIsMissedFallReportDialogOpen: () => void;
  fallConfirmed: Maybe<FallDetectionDisposition>;
  setFallConfirmed: (fallDetectionDisposition?: FallDetectionDisposition) => void;
};

const EventCard: React.FC<EventCardProps> = ({
  event,
  toggleIsMissedFallReportDialogOpen,
  fallConfirmed,
  setFallConfirmed,
}) => {
  return (
    <React.Fragment>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.ActiveCalls.EventCard}>
        <CardHeader title={translations.common.texts.event()} titleTypographyProps={{ variant: "h6" }} sx={{ pb: 0 }} />
        <CardContent>
          <EventTypeItem event={event} />
          <EventConfirmation
            event={event}
            toggleIsMissedFallReportDialogOpen={toggleIsMissedFallReportDialogOpen}
            fallConfirmed={fallConfirmed}
            setFallConfirmed={setFallConfirmed}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventCard;
