/* Copyright */
import React from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import { translations } from "../../../generated/translationHelper";
import { DeviceReportItem } from "../../../utils/utils";
import DeviceReportsViewTableRow from "./device-reports-view-table-row";
import TestIds from "../../../test-ids/test-ids";

type DeviceReportsViewTableProps = {
  isLoading: boolean;
  reportData: DeviceReportItem[];
};

const DeviceReportsViewTable: React.FC<DeviceReportsViewTableProps> = ({ isLoading, reportData }) => {
  return (
    <Box sx={{ mt: 2, overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.DeviceReportsTable.Table}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.status()}</TableCell>
              <TableCell>{translations.common.texts.deviceName()}</TableCell>
              <TableCell>{translations.common.texts.imei()}</TableCell>
              <TableCell>{translations.common.texts.createdOn()}</TableCell>
              <TableCell>{translations.common.texts.callerId()}</TableCell>
              <TableCell>{translations.common.texts.iccid()}</TableCell>
              <TableCell>{translations.common.texts.realm()}</TableCell>
              <TableCell>{translations.common.texts.sku()}</TableCell>
              <TableCell>{translations.common.texts.network()}</TableCell>
              <TableCell>{translations.common.texts.authKey()}</TableCell>
              <TableCell>{translations.common.texts.validity()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.length > 0
              ? reportData.map((device, index) => (
                  <DeviceReportsViewTableRow key={index} device={device} tableRowIndex={index} />
                ))
              : !isLoading && <TableEmptyRow colspan={11} />}
            {isLoading && <TableLoadingRow colspan={11} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeviceReportsViewTable;
