/* Copyright */
import React from "react";

const CellularSignal3Bars: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7H16V21H13V7ZM8 12H11V21H8V12ZM6 16H3V21H6V16Z"
        fill="#0288D1"
      />
      <path d="M18.5 3.5H20.5V20.5H18.5V3.5Z" stroke="#0288D1" strokeOpacity="0.3" />
    </svg>
  );
};

export default CellularSignal3Bars;
