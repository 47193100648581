/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import {
  Device,
  DeviceAttributeName,
  DeviceState,
  Organization,
  OrganizationParameterHierarchy,
  OtaManager,
  ParameterValueType,
  Parameters,
  isError,
} from "@sade/data-access";
import * as React from "react";
import { useAuthenticatedUser } from "../../../../context/authenticated-user-context";
import {
  SeverityType,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition,
} from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";
import { DeviceSettings, OrganizationInfo, ParameterNames, getChildOrganizations } from "../../../../utils/utils";
import DeviceSettingsContentLeftGrid from "../../../common/device-settings-content/device-settings-content-left-grid";
import DeviceSettingsContentRightGrid from "../../../common/device-settings-content/device-settings-content-right-grid";
import UnsavedChangesDialog from "../../unsaved-changes-dialog/unsaved-changes-dialog";

const DEVICE_STATE_UPDATE_FAILED = "Failed to update device state" as const;
const OTA_TRIGGER_FAILED = "Failed to trigger OTA update" as const;

type DeviceSettingsDialogProps = {
  device?: Device;
  open: boolean;
  onClose: () => void;
  deviceState?: DeviceState;
  deviceOrganization?: Organization;
  parameterHierarchy?: OrganizationParameterHierarchy;
};

const DeviceSettingsDialog: React.FC<DeviceSettingsDialogProps> = ({
  device,
  open,
  onClose,
  deviceState,
  deviceOrganization,
  parameterHierarchy,
}) => {
  const { showSnackbar } = useSnackbar();
  const { currentOrganization } = useAuthenticatedUser();
  const [deviceSettings, setDeviceSettings] = React.useState<DeviceSettings>({
    fallDetectionEnabled: false,
    silentModeEnabled: false,
    demoModeEnabled: false,
    tapIndicatorEnabled: false,
    inactiveMonitorEnabled: false,
    inactiveChargerTimeMinutes: 0,
    inactiveMotionTimeMinutes: 0,
    powerHeartBeatInterval: 0,
    playbackVolume: 0,
    audioMasterVolume: 0,
    callInService1: "-",
    callInService2: "-",
    callInService3: "-",
    libris2SoftwareVariant: "",
  });
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = React.useState<boolean>(false);
  const [organizationNameMap, setOrganizationNameMap] = React.useState<OrganizationInfo[]>([]);
  // Save the initial state of the variant parameter. This will be used if the OTA trigger fails.
  const [initialVariant, setInitialVariant] = React.useState<{ value: string; overridden: boolean }>();

  const initialize = React.useCallback(() => {
    setIsDirty(false);
    const updatedDeviceSettings: DeviceSettings = {
      fallDetectionEnabled: (deviceState?.getParameter(ParameterNames.FALL_DETECTION_ENABLED) as boolean) ?? false,
      silentModeEnabled: (deviceState?.getParameter(ParameterNames.SILENT_MODE_ENABLED) as boolean) ?? false,
      demoModeEnabled: (deviceState?.getParameter(ParameterNames.DEMO_MODE_ENABLED) as boolean) ?? false,
      tapIndicatorEnabled: (deviceState?.getParameter(ParameterNames.TAP_INDICATOR_ENABLED) as boolean) ?? false,
      inactiveMonitorEnabled: (deviceState?.getParameter(ParameterNames.INACTIVE_MONITOR_ENABLED) as boolean) ?? false,
      inactiveChargerTimeMinutes: (deviceState?.getParameter(ParameterNames.INACTIVE_CHARGER_MINUTES) as number) ?? 0,
      inactiveMotionTimeMinutes: (deviceState?.getParameter(ParameterNames.INACTIVE_MOTION_MINUTES) as number) ?? 0,
      powerHeartBeatInterval: (deviceState?.getParameter(ParameterNames.PERIODIC_INDICATOR_FEEDBACK) as number) ?? 0,
      playbackVolume: (deviceState?.getParameter(ParameterNames.AUDIO_PROMPT_VOLUME) as number) ?? 0,
      audioMasterVolume: (deviceState?.getParameter(ParameterNames.AUDIO_CALL_VOLUME) as number) ?? 0,
      callInService1: (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_1) as string) ?? "",
      callInService2: (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_2) as string) ?? "",
      callInService3: (deviceState?.getParameter(ParameterNames.CALL_IN_SERVICE_3) as string) ?? "",
      libris2SoftwareVariant: (deviceState?.getParameter(ParameterNames.VARIANT) as string) ?? "",
    };

    setInitialVariant({
      value: updatedDeviceSettings.libris2SoftwareVariant,
      overridden: deviceState?.isParameterOverridden(ParameterNames.VARIANT) ?? false,
    });
    setDeviceSettings(updatedDeviceSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceState]);

  const handleClose = (): void => {
    onClose();
  };

  React.useEffect(() => {
    const getOrganizationNames = async (): Promise<void> => {
      const orgNames = await getChildOrganizations(currentOrganization?.getId() ?? "");
      setOrganizationNameMap(orgNames);
    };

    if (deviceState && !open) initialize();

    // Only fetch these details when dialog is opened
    if (open) {
      getOrganizationNames();
    }
  }, [deviceState, open, initialize, deviceOrganization, currentOrganization]);

  const handleCloseClick = (): void => {
    if (isDirty) {
      setUnsavedDataDialogOpen(true);
    } else {
      handleClose();
    }
  };

  const handleCloseUnsavedDataDialog = (): void => {
    deviceState?.revert();
    setUnsavedDataDialogOpen(false);
  };

  /**
   * Update device state with the latest changes.
   */
  const updateState = async (): Promise<void> => {
    if (!deviceState) return;

    try {
      await deviceState.store();
    } catch (error) {
      throw new Error(DEVICE_STATE_UPDATE_FAILED);
    }
  };

  /**
   * Triggers an OTA update for the device if the `variant` parameter value differs from the device `variant` attribute value.
   * Device also need to have a `softwareVersion` attribute value.
   */
  const triggerOta = async (): Promise<void> => {
    if (
      device &&
      deviceSettings.libris2SoftwareVariant &&
      deviceSettings.libris2SoftwareVariant !== device.getAttribute(DeviceAttributeName.variant) &&
      device.getAttribute(DeviceAttributeName.softwareVersion)
    ) {
      try {
        await OtaManager.getInstance().triggerLibrisDeviceOtaUpdateByVersion(
          [device.getId()],
          device.getAttribute(DeviceAttributeName.softwareVersion)!,
          deviceSettings.libris2SoftwareVariant
        );
      } catch (error) {
        throw new Error(OTA_TRIGGER_FAILED);
      }
    }
  };

  const handleSaveChangesClick = async (): Promise<void> => {
    setIsSavingChanges(true);
    try {
      await updateState();
      await triggerOta();

      showSnackbar(
        translations.deviceSettingsDialog.texts.changesSaveSuccessHeader(),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center,
        translations.deviceSettingsDialog.texts.changesSaveSuccessInfo()
      );
      handleClose();
    } catch (error) {
      if (isError(error) && error.message === DEVICE_STATE_UPDATE_FAILED) {
        // Restore the device state to the previous state
        deviceState?.revert();
        initialize();

        showSnackbar(
          translations.deviceSettingsDialog.texts.stateUpdateFailedHeader(),
          SeverityType.Error,
          SnackbarVerticalPosition.Bottom,
          SnackbarHorizontalPosition.Center,
          translations.deviceSettingsDialog.texts.stateUpdateFailedInfo()
        );
      }

      if (isError(error) && error.message === OTA_TRIGGER_FAILED) {
        // Changes to device state got already saved, but OTA trigger failed.
        // We need to revert the variant parameter value to the previous state.
        if (initialVariant?.overridden) {
          // Parameter was initially overridden, go back to the previous device level value.
          deviceState?.updateParameter(ParameterNames.VARIANT, initialVariant.value);
          await updateState();
        } else {
          // Parameter was initially not overridden, go back to the previous realm level value.
          const realmParameters: Parameters = Object.values(parameterHierarchy?.deviceParameters ?? {}).reduce(
            (acc, hierarchy) => ({
              ...acc,
              ...hierarchy,
            })
          );
          deviceState?.resetParameter(ParameterNames.VARIANT, realmParameters);
          await updateState();
        }
        deviceSettings.libris2SoftwareVariant = initialVariant?.value ?? "";

        showSnackbar(
          translations.deviceSettingsDialog.texts.otaTriggerFailedHeader(),
          SeverityType.Error,
          SnackbarVerticalPosition.Bottom,
          SnackbarHorizontalPosition.Center,
          translations.deviceSettingsDialog.texts.otaTriggerFailedInfo()
        );
      }
    } finally {
      setIsDirty(false);
      setIsSavingChanges(false);
    }
  };

  const handleSettingChanged = (parameterName: ParameterNames, value: ParameterValueType): void => {
    deviceState?.updateParameter(parameterName, value);
    setDeviceSettings({ ...deviceSettings, [parameterName]: value });
    setIsDirty(true);
  };

  const handleResetSetting = (parameterName: ParameterNames, value: ParameterValueType): void => {
    if (parameterHierarchy?.deviceParameters) {
      const realmParameters: Parameters = Object.values(parameterHierarchy.deviceParameters).reduce(
        (acc, hierarchy) => ({
          ...acc,
          ...hierarchy,
        })
      );

      deviceState?.resetParameter(parameterName, realmParameters);
      setDeviceSettings({ ...deviceSettings, [parameterName]: value });
      setIsDirty(true);
    }
  };

  const isSaveChangesButtonDisabled = !isDirty || isSavingChanges;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        data-testid={TestIds.DeviceSettingsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.common.texts.deviceSettings()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceSettingsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <DeviceSettingsContentLeftGrid
              settings={deviceSettings}
              deviceState={deviceState}
              parameterHierarchy={parameterHierarchy}
              organizationNameMap={organizationNameMap}
              deviceOrganization={deviceOrganization}
              onSettingChanged={handleSettingChanged}
              onResetSetting={handleResetSetting}
            />
            <DeviceSettingsContentRightGrid
              settings={deviceSettings}
              deviceState={deviceState}
              parameterHierarchy={parameterHierarchy}
              organizationNameMap={organizationNameMap}
              deviceOrganization={deviceOrganization}
              onSettingChanged={handleSettingChanged}
              onResetSetting={handleResetSetting}
            />
          </Grid>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSaveChangesClick}
            data-testid={TestIds.DeviceSettingsDialog.SubmitButton}
            disabled={isSaveChangesButtonDisabled}
            startIcon={isSavingChanges && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.saveChanges()}
          </Button>
          <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.DeviceSettingsDialog.CancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleCloseUnsavedDataDialog}
        handleSave={handleSaveChangesClick}
        handleDiscard={handleClose}
      />
    </React.Fragment>
  );
};

export default DeviceSettingsDialog;
