/* Copyright */
import React from "react";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomTextField from "../../common/textfield";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import EnterImeisDialog from "../../dialogs/enter-imeis-dialog/enter-imeis-dialog";

type DeviceReportsViewFilterBarProps = {
  isLoading: boolean;
  selectedImeis: string[];
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  handleCopy: () => void;
  handleDownloadCSV: () => void;
  handleSubmitImeis: (imeis: string[]) => void;
};

const DeviceReportsViewFilterBar: React.FC<DeviceReportsViewFilterBarProps> = ({
  isLoading,
  selectedImeis,
  searchString,
  setSearchString,
  handleCopy,
  handleDownloadCSV,
  handleSubmitImeis,
}) => {
  const [isEnterImeisDialogOpen, setIsEnterImeisDialogOpen] = React.useState<boolean>(false);
  const handleSearchStringChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchString(event.target.value);
  };

  const handleCopyClick = (): void => {
    handleCopy();
  };

  const handleDownloadClick = (): void => {
    handleDownloadCSV();
  };

  const handleToggleOpenEnterImeisDialog = (): void => {
    setIsEnterImeisDialogOpen(!isEnterImeisDialogOpen);
  };

  const initState = selectedImeis.length < 1;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <CustomTextField
          label={translations.common.inputs.search()}
          id="searchString"
          type="search"
          testId={TestIds.DeviceReports.Search}
          onChange={handleSearchStringChange}
          placeholder={translations.common.texts.searchPlaceholder()}
          value={searchString}
          inputRef={(input): void => input && input.focus()}
          InputProps={{
            sx: {
              "& input": {
                textOverflow: "ellipsis",
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="search" edge="start">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth={false}
          disabled={isLoading || initState}
          sx={{
            minWidth: 350,
            mb: { xs: 2, md: 0 },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 1, mb: 1 }}>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyClick}
            data-testid={TestIds.DeviceReports.CopyButton}
            disabled={initState}
            sx={{ mr: 2 }}
          >
            {translations.common.buttons.copy()}
          </Button>
          <Button
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleDownloadClick}
            data-testid={TestIds.DeviceReports.DownloadButton}
            disabled={initState}
            sx={{ mr: 2 }}
          >
            {translations.common.buttons.downloadAsCsv()}
          </Button>
          <Button
            variant="contained"
            onClick={handleToggleOpenEnterImeisDialog}
            data-testid={TestIds.DeviceReports.EnterImeisButton}
          >
            {translations.common.buttons.enterImeis()}
          </Button>
        </Box>
      </Box>
      <EnterImeisDialog
        open={isEnterImeisDialogOpen}
        handleClose={handleToggleOpenEnterImeisDialog}
        handleSubmit={handleSubmitImeis}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default DeviceReportsViewFilterBar;
