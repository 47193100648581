/* Copyright */
import React from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { translations } from "../../../../../generated/translationHelper";
import TableEmptyRow from "../../../../common/table-empty-row";
import TableLoadingRow from "../../../../common/table-loading-row";
import { Peripheral, PeripheralListItem } from "../../../../../utils/utils";
import TestIds from "../../../../../test-ids/test-ids";
import PeripheralsTableRow from "./peripherals-table-row";

type PeripheralsTableProps = {
  peripherals: PeripheralListItem[];
  tableRef: React.RefObject<HTMLDivElement> | undefined;
  isLoading: boolean;
  removePeripheral: (peripheral: Peripheral) => void;
};

const PeripheralsTable: React.FC<PeripheralsTableProps> = ({ peripherals, tableRef, isLoading, removePeripheral }) => {
  return (
    <Box
      sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}
      ref={tableRef}
    >
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.Devices.DevicesTable}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.deviceType()}</TableCell>
              <TableCell>{translations.common.texts.serialNumber()}</TableCell>
              <TableCell>{translations.common.texts.status()}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {peripherals.length > 0 && !isLoading
              ? peripherals.map((peripheral, index) => (
                  <PeripheralsTableRow
                    key={index}
                    peripheral={peripheral}
                    tableRowIndex={index}
                    removePeripheral={removePeripheral}
                  />
                ))
              : !isLoading && <TableEmptyRow colspan={6} />}
            {isLoading && <TableLoadingRow colspan={6} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PeripheralsTable;
