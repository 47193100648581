/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type DisableAccountDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
};

const DisableAccountDialog: React.FC<DisableAccountDialogProps> = ({ open, handleClose, handleSubmit, isLoading }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.DisableAccountDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.common.texts.disableAccount()}
        <IconButton
          data-testid={TestIds.DisableAccountDialog.CloseButton}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.disableAccountDialog.texts.subtitle(),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="error"
          disabled={isLoading}
          data-testid={TestIds.DisableAccountDialog.SubmitButton}
          startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
        >
          {translations.common.texts.disableAccount()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={isLoading}
          data-testid={TestIds.DisableAccountDialog.CancelButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableAccountDialog;
