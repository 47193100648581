/* Copyright */
import * as React from "react";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import { translations } from "../../generated/translationHelper";
import EditPhoneNumberDialog from "../dialogs/edit-phone-number-dialog/edit-phone-number-dialog";
import TestIds from "../../test-ids/test-ids";

type RegistrationSmsVerificationFormProps = {
  countryCode: string;
  phoneNumber: string;
  code: string;
  handleCodeChange: (code: string) => void;
  handleSubmitClick: () => void;
  codeError: string;
  handleResendClick: () => void;
  handleUpdatePhoneNumber: (countryCode: string, phoneNumber: string) => void;
  isLoading: boolean;
};

const RegistrationSmsVerificationForm: React.FC<RegistrationSmsVerificationFormProps> = ({
  countryCode,
  phoneNumber,
  code,
  handleCodeChange,
  handleSubmitClick,
  codeError,
  handleResendClick,
  handleUpdatePhoneNumber,
  isLoading,
}) => {
  const AuthInputRef = React.useRef<AuthCodeRef>(null);
  const [editPhoneNumberDialogOpen, setEditPhoneNumberDialogOpen] = React.useState<boolean>(false);

  const handleEditPhoneNumberDialogOpen = (): void => {
    setEditPhoneNumberDialogOpen(true);
  };

  const handleEditPhoneNumberDialogClose = (): void => {
    setEditPhoneNumberDialogOpen(false);
  };

  const isVerifyButtonDisabled = code.length !== 6 || isLoading;

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.smsVerification.texts.title()}
      </Typography>
      <div
        className="title"
        dangerouslySetInnerHTML={{
          __html: translations.smsVerification.texts.subtitle2({ countryCode, phoneNumber }),
        }}
      />{" "}
      <AuthCode
        allowedCharacters="numeric"
        onChange={handleCodeChange}
        containerClassName="enter-code-container"
        inputClassName={codeError.length > 0 ? "enter-code-input-error" : "enter-code-input"}
        ref={AuthInputRef}
      />
      {codeError.length > 0 && <Typography className="enter-code-error">{codeError}</Typography>}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: codeError.length > 0 ? 0 : 2 }}>
        <Link
          href="#"
          underline="hover"
          variant="body1"
          onClick={handleEditPhoneNumberDialogOpen}
          data-testid={TestIds.RegistrationSmsVerification.EditPhoneNumberLink}
        >
          {translations.common.texts.editPhoneNumber()}
        </Link>
        <Link
          href="#"
          underline="hover"
          variant="body1"
          onClick={handleResendClick}
          data-testid={TestIds.RegistrationSmsVerification.ResendCodeLink}
        >
          {translations.smsVerification.buttons.resendCode()}
        </Link>
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmitClick}
        disabled={isVerifyButtonDisabled}
        className="sparse-button"
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
        data-testid={TestIds.RegistrationSmsVerification.SubmitButton}
      >
        {translations.smsVerification.buttons.submit()}
      </Button>
      <EditPhoneNumberDialog
        open={editPhoneNumberDialogOpen}
        handleClose={handleEditPhoneNumberDialogClose}
        originalCountryCode={countryCode}
        originalPhoneNumber={phoneNumber}
        handleUpdatePhoneNumber={handleUpdatePhoneNumber}
      />
    </React.Fragment>
  );
};

export default RegistrationSmsVerificationForm;
