/* Copyright */
import * as React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { translations } from "../../generated/translationHelper";
import { Paths } from "../../utils/utils";
import TestIds from "../../test-ids/test-ids";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { getPath } from "../../utils/ssoPathUtil";

const Footer: React.FC = () => {
  const theme = useTheme();
  const { currentOrganizationName } = useAuthenticatedUser();

  return (
    <Box className="layout-footer" data-testid={TestIds.LayoutFooter.LayoutFooter}>
      <Box data-testid={TestIds.LayoutFooter.CurrentRealm}>
        <Typography variant="caption">{currentOrganizationName}</Typography>
      </Box>
      <Box className="layout-footer-item-container" data-testid={TestIds.LayoutFooter.LayoutFooterItemContainer}>
        <Typography variant="caption" sx={{ color: theme.palette.secondary.light }}>
          © {new Date().getFullYear()} {translations.common.footer.rightsReserved()}
        </Typography>
        <Link
          href={getPath(Paths.SUPPORT)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.support()}
        </Link>
        <Link
          href={getPath(Paths.PRIVACY)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.privacy()}
        </Link>
        <Link
          href={getPath(Paths.TERMS_OF_USE)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.termsOfUse()}
        </Link>
        <Link
          href={getPath(Paths.WHERE_TO_BUY)}
          underline="hover"
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {translations.common.links.whereToBuy()}
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
