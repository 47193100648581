/* Copyright */
import { Box } from "@mui/material";
import { OtaManager, OtaPackageInfo, RoleIdentifier } from "@sade/data-access";
import * as React from "react";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import AccessControl from "../../access-control/access-control";
import OtaPackagesTable from "./ota-packages-table";
import OtaPackagesViewHeader from "./ota-packages-view-header";

const OtaPackagesView: React.FC = () => {
  const tableEl = React.useRef<HTMLDivElement>(null);
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [otaPackages, setOtaPackages] = React.useState<OtaPackageInfo[]>([]);

  const fetchOtaPackagesData = React.useCallback(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const result = await OtaManager.getInstance().getOtaPackages();
        setOtaPackages(result);
      } catch (error) {
        showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
      } finally {
        setIsLoading(false);
      }
    };

    return fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchOtaPackagesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadComplete = (): void => {
    fetchOtaPackagesData();
  };

  return (
    <Box sx={{ padding: "2rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.SuperUser]}>
        <OtaPackagesViewHeader otaPackages={otaPackages} reFetchData={handleUploadComplete} />
        <OtaPackagesTable
          otaPackages={otaPackages}
          tableRef={tableEl}
          isLoading={isLoading}
          onUploadComplete={handleUploadComplete}
        />
      </AccessControl>
    </Box>
  );
};

export default OtaPackagesView;
