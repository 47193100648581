/* Copyright */
import React from "react";

type LocateDeviceIconProps = {
  disabled?: boolean;
};

const LocateDeviceIcon: React.FC<LocateDeviceIconProps> = ({ disabled = false }) => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00091 3.33333C8.60924 3.33333 10.0676 3.98333 11.1259 5.04167L9.95091 6.21667C9.19258 5.46667 8.15091 5 7.00091 5C5.85091 5 4.80925 5.46667 4.05091 6.21667L2.87591 5.04167C3.93425 3.98333 5.39258 3.33333 7.00091 3.33333ZM13.4842 2.69167L12.3092 3.86667C10.9509 2.50833 9.07591 1.66667 7.00924 1.66667C4.94258 1.66667 3.05924 2.50833 1.70091 3.85833L0.517578 2.68333C2.18424 1.025 4.47591 0 7.00924 0C9.54258 0 11.8259 1.025 13.4842 2.69167ZM7.00091 9.16667C8.61758 9.16667 10.3342 10.375 10.3342 12.625C10.3342 13.4083 9.87591 15.0667 7.00091 17.7667C4.12591 15.0667 3.66758 13.4083 3.66758 12.625C3.66758 10.375 5.38424 9.16667 7.00091 9.16667ZM7.00091 7.5C4.37591 7.5 2.00091 9.50833 2.00091 12.625C2.00091 14.7 3.66758 17.1583 7.00091 20C10.3342 17.1583 12.0009 14.7 12.0009 12.625C12.0009 9.50833 9.62591 7.5 7.00091 7.5ZM8.25091 12.5C8.25091 11.8083 7.69258 11.25 7.00091 11.25C6.30924 11.25 5.75091 11.8083 5.75091 12.5C5.75091 13.1917 6.30924 13.75 7.00091 13.75C7.69258 13.75 8.25091 13.1917 8.25091 12.5Z"
        fill={disabled ? "#BDBDBD" : "#004A93"}
      />
    </svg>
  );
};

export default LocateDeviceIcon;
