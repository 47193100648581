/* Copyright */
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Battery0BarOutlinedIcon from "@mui/icons-material/Battery0BarOutlined";
import BatteryAlertOutlinedIcon from "@mui/icons-material/BatteryAlertOutlined";
import BatteryChargingFullOutlinedIcon from "@mui/icons-material/BatteryChargingFullOutlined";
import BatteryUnknownOutlinedIcon from "@mui/icons-material/BatteryUnknownOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import CompassCalibrationOutlinedIcon from "@mui/icons-material/CompassCalibrationOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PhoneMissedOutlinedIcon from "@mui/icons-material/PhoneMissedOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PlayDisabledOutlinedIcon from "@mui/icons-material/PlayDisabledOutlined";
import PowerOffOutlinedIcon from "@mui/icons-material/PowerOffOutlined";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SyncDisabledOutlinedIcon from "@mui/icons-material/SyncDisabledOutlined";
import SyncProblemOutlinedIcon from "@mui/icons-material/SyncProblemOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import { SvgIconProps } from "@mui/material";
import { Event, EventState, EventType, RoleIdentifier } from "@sade/data-access";
import theme from "../app/theme";
import DevicesIcon from "../assets/icons/devices-icon";
import { OptionParams, translations } from "../generated/translationHelper";

export enum eventLevel {
  NORMAL,
  WARNING,
  ALERT,
  UNSPECIFIED,
}

export type SelectedEventFixType = { satellite: boolean; wifi: boolean; network: boolean };

export type DeviceEvent = {
  id: string;
  isLatest: boolean;
  eventType?: EventType;
  eventName: string;
  eventTimestamp: Date;
  eventLevel: eventLevel;
  icon?: React.ElementType<SvgIconProps>;
  permissionRoles: string[];
  lat?: number;
  lng?: number;
  method?: string;
  address?: string;
  isOnGoingCall?: boolean;
};

type EventMetadata = {
  demoMode: boolean;
  silentMode: boolean;
  pendantMode: boolean;
};

/**
 * Parse the call mode from the given event metadata.
 * @param event The event object.
 * @returns The parsed call mode as an {@link EventMetadata} object.
 */
export const parseCallMode = (event: Event): EventMetadata => {
  const json = event.metadata ? JSON.parse(event.metadata) : undefined;
  const demoModeValue = json?.demomode_fd;
  const silentModeValue = json?.SilentModeEvent;
  const pendantModeValue = json?.PendantEvent;

  const isDemo = typeof demoModeValue === "boolean" ? demoModeValue : String(demoModeValue).toLowerCase() === "true";
  const isSilent =
    typeof silentModeValue === "boolean" ? silentModeValue : String(silentModeValue).toLowerCase() === "true";
  const isPendant =
    typeof pendantModeValue === "boolean" ? pendantModeValue : String(pendantModeValue).toLowerCase() === "true";

  return {
    demoMode: isDemo,
    silentMode: isSilent,
    pendantMode: isPendant,
  };
};

export const resolveCallEventName = (event: Event): string => {
  switch (event.type) {
    case EventType.Fall:
      return translations.common.texts.fall();
    case EventType.Normal: {
      const { demoMode, silentMode, pendantMode } = parseCallMode(event);

      if (demoMode && silentMode && pendantMode) {
        return translations.events.texts.demoSilentPendantCall();
      } else if (demoMode && silentMode) {
        return translations.events.texts.demoSilentCall();
      } else if (demoMode && pendantMode) {
        return translations.events.texts.demoPendantCall();
      } else if (demoMode) {
        return translations.events.texts.demoCall();
      } else if (silentMode && pendantMode) {
        return translations.events.texts.silentPendantCall();
      } else if (silentMode) {
        return translations.events.texts.silentCall();
      } else if (pendantMode) {
        return translations.events.texts.pendantCall();
      } else {
        return translations.events.texts.callButtonPressed();
      }
    }
    default:
      return "";
  }
};

const resolveClassifyEventName = (event: Event): string => {
  switch (event.state) {
    case EventState.ClassFall:
      return translations.events.texts.classifyFallDetected();
    default:
      return translations.events.texts.classify();
  }
};

export const resolveEventTypeChipColor = (event: Event): string => {
  switch (event.type) {
    case EventType.Normal: {
      const { demoMode, silentMode, pendantMode } = parseCallMode(event);

      return silentMode
        ? theme.palette.primary.light
        : pendantMode
        ? theme.palette.warning.main
        : demoMode
        ? theme.palette.warning.light
        : theme.palette.primary.main;
    }
    case EventType.Fall:
      return theme.palette.error.main;
    default:
      return theme.palette.warning.dark;
  }
};

// #region formatEvent

export const formatEvent = (event: Event, isLatestEvent = false): DeviceEvent => {
  const baseEventType: Pick<
    DeviceEvent,
    "id" | "isLatest" | "eventType" | "eventTimestamp" | "lat" | "lng" | "method" | "address"
  > = {
    id: event.id,
    isLatest: isLatestEvent,
    eventType: event.type,
    eventTimestamp: event.timestamp,
    lat: event.latitude,
    lng: event.longitude,
    method: event.method,
    address: event.address,
  };

  switch (event.type) {
    case EventType.Fall:
      return {
        ...baseEventType,
        eventName: translations.events.texts.fallAssistanceCall(),
        eventLevel: eventLevel.ALERT,
        icon: PriorityHighOutlinedIcon,
        isOnGoingCall: event.callAnsweredAt && !event.callEndedAt,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.Normal: {
      return {
        ...baseEventType,
        eventName: resolveCallEventName(event),
        eventLevel: eventLevel.WARNING,
        icon: PhoneOutlinedIcon,
        isOnGoingCall: event.callAnsweredAt && !event.callEndedAt,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    }
    case EventType.LowActivityAlarm:
      return {
        ...baseEventType,
        eventName: translations.events.texts.lowActivity(),
        eventLevel: eventLevel.WARNING,
        icon: HotelOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.LongChargeAlarm:
      return {
        ...baseEventType,
        eventName: translations.events.texts.longCharge(),
        eventLevel: eventLevel.WARNING,
        icon: BatteryChargingFullOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.BatteryLow:
      return {
        ...baseEventType,
        eventName: translations.events.texts.batteryLow(),
        eventLevel: eventLevel.WARNING,
        icon: BatteryAlertOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PowerOffOnLowBattery:
      return {
        ...baseEventType,
        eventName: translations.events.texts.powerOffOnLowBattery(),
        eventLevel: eventLevel.WARNING,
        icon: Battery0BarOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.NetworkChange:
      return {
        ...baseEventType,
        eventName: translations.events.texts.networkChange(),
        eventLevel: eventLevel.NORMAL,
        icon: WifiOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.ChargeChange: {
      const json = event.metadata ? JSON.parse(event.metadata) : undefined;
      const state = json?.chrg_state ?? 0;
      return {
        ...baseEventType,
        eventName:
          state === 1
            ? translations.events.texts.chargerOff()
            : state === 2 || state === 3
            ? translations.events.texts.chargerOn()
            : translations.events.texts.batteryUnknown(),
        eventLevel: eventLevel.NORMAL,
        icon:
          state === 1
            ? PowerOffOutlinedIcon
            : state === 2 || state === 3
            ? PowerOutlinedIcon
            : BatteryUnknownOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    }
    case EventType.On:
      return {
        ...baseEventType,
        eventName: translations.events.texts.poweredOn(),
        eventLevel: eventLevel.NORMAL,
        icon: PowerSettingsNewOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.Classify:
      return {
        ...baseEventType,
        eventName: resolveClassifyEventName(event),
        eventLevel: eventLevel.NORMAL,
        icon: QueryStatsOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.Note:
      return {
        ...baseEventType,
        eventName: translations.events.texts.notesUpdated(),
        eventLevel: eventLevel.NORMAL,
        icon: NotesOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.Status:
      return {
        ...baseEventType,
        eventName: translations.common.texts.status(),
        eventLevel: eventLevel.NORMAL,
        icon: AssignmentOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.Debug:
      return {
        ...baseEventType,
        eventName: translations.events.texts.debug(),
        eventLevel: eventLevel.NORMAL,
        icon: BugReportOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.Logfile:
      return {
        ...baseEventType,
        eventName: translations.events.texts.logFile(),
        eventLevel: eventLevel.NORMAL,
        icon: FileDownloadOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.Activation:
      return {
        ...baseEventType,
        eventName: translations.events.texts.deviceActivation(),
        eventLevel: eventLevel.NORMAL,
        icon: DevicesIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.FailActivation:
      return {
        ...baseEventType,
        eventName: translations.events.texts.deviceFailActivation(),
        eventLevel: eventLevel.NORMAL,
        icon: ErrorOutlineOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.Deactivation:
      return {
        ...baseEventType,
        eventName: translations.events.texts.deviceDeactivated(),
        eventLevel: eventLevel.NORMAL,
        icon: AdjustOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.MagCalibration:
      return {
        ...baseEventType,
        eventName: translations.events.texts.MagCalibration(),
        eventLevel: eventLevel.NORMAL,
        icon: CompassCalibrationOutlinedIcon,
        permissionRoles: [RoleIdentifier.SuperUser, RoleIdentifier.PowerUser],
      };
    case EventType.StatusUpdate:
      return {
        ...baseEventType,
        eventName: translations.events.texts.statusUpdate(),
        eventLevel: eventLevel.NORMAL,
        icon: UpdateOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.CallCancelled:
      return {
        ...baseEventType,
        eventName: translations.events.texts.callCancelled(),
        eventLevel: eventLevel.NORMAL,
        icon: PhoneMissedOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PendantLinked:
      return {
        ...baseEventType,
        eventName: translations.events.texts.pendantPaired(),
        eventLevel: eventLevel.NORMAL,
        icon: LoopOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PendantUnpaired:
      return {
        ...baseEventType,
        eventName: translations.events.texts.pendantUnPaired(),
        eventLevel: eventLevel.NORMAL,
        icon: SyncDisabledOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PendantBatteryLow:
      return {
        ...baseEventType,
        eventName: translations.events.texts.pendantBatteryLow(),
        eventLevel: eventLevel.WARNING,
        icon: BatteryAlertOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PendantOutOfRange:
      return {
        ...baseEventType,
        eventName: translations.events.texts.pendantOutOfRange(),
        eventLevel: eventLevel.ALERT,
        icon: ErrorOutlineOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.PendantPairFail:
      return {
        ...baseEventType,
        eventName: translations.events.texts.pendantPairingFailure(),
        eventLevel: eventLevel.ALERT,
        icon: SyncProblemOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.MedicalDevicePaired:
      return {
        ...baseEventType,
        eventName: translations.events.texts.medicalDevicePaired(),
        eventLevel: eventLevel.NORMAL,
        icon: LoopOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.MedicalDeviceUnpaired:
      return {
        ...baseEventType,
        eventName: translations.events.texts.medicalDeviceUnpaired(),
        eventLevel: eventLevel.NORMAL,
        icon: SyncDisabledOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.MedicalDeviceReading:
      return {
        ...baseEventType,
        eventName: translations.events.texts.medicalDeviceReading(),
        eventLevel: eventLevel.NORMAL,
        icon: SyncProblemOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.MedicalDevicePairFailure:
      return {
        ...baseEventType,
        eventName: translations.events.texts.medicalDevicePairFailure(),
        eventLevel: eventLevel.ALERT,
        icon: SyncProblemOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.DemoModeFDEnabled:
      return {
        ...baseEventType,
        eventName: translations.events.texts.demoModeEnabled(),
        eventLevel: eventLevel.NORMAL,
        icon: PlayArrowOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.DemoModeFDDisabled:
      return {
        ...baseEventType,
        eventName: translations.events.texts.demoModeDisabled(),
        eventLevel: eventLevel.NORMAL,
        icon: PlayDisabledOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.LocationFix:
      return {
        ...baseEventType,
        eventName: translations.common.texts.locationUpdate(),
        eventLevel: eventLevel.NORMAL,
        icon: LocationOnOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    case EventType.SilentModeDisabled: {
      return {
        ...baseEventType,
        eventName: translations.events.texts.silentModeDisabled(),
        eventLevel: eventLevel.NORMAL,
        icon: PlayDisabledOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    }
    case EventType.SilentModeEnabled: {
      return {
        ...baseEventType,
        eventName: translations.events.texts.silentModeEnabled(),
        eventLevel: eventLevel.NORMAL,
        icon: PlayArrowOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
    }
    default:
      return {
        ...baseEventType,
        eventName: "UnsupportedEvent",
        eventLevel: eventLevel.UNSPECIFIED,
        icon: PlayDisabledOutlinedIcon,
        permissionRoles: [
          RoleIdentifier.SuperUser,
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.WarehouseInstaller,
        ],
      };
  }
};

//#endregion

// #region Missed Fall Report

export enum Occur {
  NOW = "Now",
  TEN_MINUTES = "10 Minutes",
  THIRTY_MINUTES = "30 Minutes",
  ONE_HOUR = "1 Hour",
  TWO_HOUR = "2 Hour",
  OTHER = "Other",
}

export type FallOccur = {
  value: Occur;
  definition: (options?: OptionParams | undefined) => string;
};

export const fallOccurOptions: FallOccur[] = [
  { value: Occur.NOW, definition: translations.missedFallReport.fallOccur.now },
  { value: Occur.TEN_MINUTES, definition: translations.missedFallReport.fallOccur.tenMinutes },
  { value: Occur.THIRTY_MINUTES, definition: translations.missedFallReport.fallOccur.thirtyMinutes },
  { value: Occur.ONE_HOUR, definition: translations.missedFallReport.fallOccur.oneHour },
  { value: Occur.TWO_HOUR, definition: translations.missedFallReport.fallOccur.twoHour },
  { value: Occur.OTHER, definition: translations.common.texts.other },
];

export enum DeviceLocation {
  NECK = "Neck",
  BELT = "Belt",
  POCKET = "Pocket",
  BAG = "Bag",
  HAND = "Hand",
  OTHER = "Other",
}

export type WearingTheDevice = {
  value: DeviceLocation;
  definition: (options?: OptionParams | undefined) => string;
};

export const wearingTheDeviceOptions: WearingTheDevice[] = [
  { value: DeviceLocation.NECK, definition: translations.missedFallReport.wearingTheDevice.onTheNeck },
  { value: DeviceLocation.BELT, definition: translations.missedFallReport.wearingTheDevice.onTheBelt },
  { value: DeviceLocation.POCKET, definition: translations.missedFallReport.wearingTheDevice.inThePocket },
  { value: DeviceLocation.BAG, definition: translations.missedFallReport.wearingTheDevice.inTheBag },
  { value: DeviceLocation.HAND, definition: translations.missedFallReport.wearingTheDevice.inHand },
  { value: DeviceLocation.OTHER, definition: translations.common.texts.other },
];

export enum FallPosition {
  BACK = "Back",
  STOMACH = "Stomach",
  SIDE = "Side",
  SITTING = "Sitting",
  KNEELING = "Kneeling",
  OTHER = "Other",
}

export type FallingPosition = {
  value: FallPosition;
  definition: (options?: OptionParams | undefined) => string;
};

export const fallingPositionOptions: FallingPosition[] = [
  { value: FallPosition.BACK, definition: translations.missedFallReport.fallPosition.lyingOnTheirBack },
  { value: FallPosition.STOMACH, definition: translations.missedFallReport.fallPosition.lyingOnTheirStomach },
  { value: FallPosition.SIDE, definition: translations.missedFallReport.fallPosition.lyingOnTheirSide },
  { value: FallPosition.SITTING, definition: translations.missedFallReport.fallPosition.sitting },
  { value: FallPosition.KNEELING, definition: translations.missedFallReport.fallPosition.kneeling },
  { value: FallPosition.OTHER, definition: translations.common.texts.other },
];

export enum Recovery {
  SEATED = "Seated Position",
  STANDING = "Standing Position",
  WALKED = "Walked Away",
  DID_NOT_RECOVER = "Did Not Recover",
  OTHER = "Other",
}

export type RecoveryType = {
  value: Recovery;
  definition: (options?: OptionParams | undefined) => string;
};

export const recoveryTypeOptions: RecoveryType[] = [
  { value: Recovery.SEATED, definition: translations.missedFallReport.recovery.seatedPosition },
  { value: Recovery.STANDING, definition: translations.missedFallReport.recovery.standingPosition },
  { value: Recovery.WALKED, definition: translations.missedFallReport.recovery.walkedAway },
  { value: Recovery.DID_NOT_RECOVER, definition: translations.missedFallReport.recovery.didNotRecover },
  { value: Recovery.OTHER, definition: translations.common.texts.other },
];

export enum WalkingAidInvolvement {
  YES = "Yes",
  NO = "No",
  UNSURE = "Unsure",
}

export type WalkingAidInvolvementType = {
  value: WalkingAidInvolvement;
  definition: (options?: OptionParams | undefined) => string;
};

export const walkingAidInvolvementOptions: WalkingAidInvolvementType[] = [
  { value: WalkingAidInvolvement.YES, definition: translations.common.texts.yes },
  { value: WalkingAidInvolvement.NO, definition: translations.common.texts.no },
  { value: WalkingAidInvolvement.UNSURE, definition: translations.missedFallReport.walkingAidInvolvement.unsure },
];

export enum Surface {
  ASPHALT_CONCRETE = "Asphalt or Concrete",
  HARD_FLOOR = "Hard Floor",
  CARPET = "Carpet",
  STAIRS = "Stairs",
  WET_SLIPPERY_SURFACE = "Wet or Slippery Surface",
  OTHER = "Other",
}

export type SurfaceType = {
  value: Surface;
  definition: (options?: OptionParams | undefined) => string;
};

export const surfaceOptions: SurfaceType[] = [
  {
    value: Surface.ASPHALT_CONCRETE,
    definition: translations.missedFallReport.fallSurface.asphaltConcrete,
  },
  {
    value: Surface.HARD_FLOOR,
    definition: translations.missedFallReport.fallSurface.hardFloor,
  },
  { value: Surface.CARPET, definition: translations.missedFallReport.fallSurface.carpet },
  { value: Surface.STAIRS, definition: translations.missedFallReport.fallSurface.stairs },
  { value: Surface.WET_SLIPPERY_SURFACE, definition: translations.missedFallReport.fallSurface.wetSlipperySurface },
  { value: Surface.OTHER, definition: translations.common.texts.other },
];

// #endregion

export interface EventFilterOption {
  /**
   * The event type.
   */
  type: EventType;
  /*
   * The localized label for the event type.
   */
  label: string;
}

/**
 *  Get the supported event filter options.
 * @returns An array of EventFilterOption.
 */
export const getEventFilterOptions = (): EventFilterOption[] =>
  [
    {
      type: EventType.Activation,
      label: translations.events.texts.deviceActivation(),
    },
    {
      type: EventType.BatteryLow,
      label: translations.events.texts.pendantBatteryLow(),
    },
    {
      type: EventType.CallCancelled,
      label: translations.events.texts.callCancelled(),
    },
    {
      type: EventType.ChargeChange,
      label: translations.events.texts.chargeChange(),
    },
    {
      type: EventType.Classify,
      label: translations.events.texts.classify(),
    },
    {
      type: EventType.Debug,
      label: translations.events.texts.debug(),
    },
    {
      type: EventType.DemoModeFDDisabled,
      label: translations.events.texts.demoModeDisabled(),
    },
    {
      type: EventType.DemoModeFDEnabled,
      label: translations.events.texts.demoModeEnabled(),
    },
    {
      type: EventType.FailActivation,
      label: translations.events.texts.deviceFailActivation(),
    },
    {
      type: EventType.Fall,
      label: translations.events.texts.fallAssistanceCall(),
    },
    {
      type: EventType.LowActivityAlarm,
      label: translations.events.texts.lowActivity(),
    },
    {
      type: EventType.LongChargeAlarm,
      label: translations.events.texts.longCharge(),
    },
    {
      type: EventType.Logfile,
      label: translations.events.texts.logFile(),
    },
    {
      type: EventType.MedicalDevicePairFailure,
      label: translations.events.texts.medicalDevicePairFailure(),
    },
    {
      type: EventType.MedicalDevicePaired,
      label: translations.events.texts.medicalDevicePaired(),
    },
    {
      type: EventType.MedicalDeviceReading,
      label: translations.events.texts.medicalDeviceReading(),
    },
    {
      type: EventType.MedicalDeviceUnpaired,
      label: translations.events.texts.medicalDeviceUnpaired(),
    },
    {
      type: EventType.NetworkChange,
      label: translations.events.texts.networkChange(),
    },
    {
      type: EventType.Note,
      label: translations.events.texts.notesUpdated(),
    },
    {
      type: EventType.Normal,
      label: translations.events.texts.callButtonPressed(),
    },
    {
      type: EventType.On,
      label: translations.events.texts.poweredOn(),
    },
    {
      type: EventType.PendantBatteryLow,
      label: translations.events.texts.pendantBatteryLow(),
    },
    {
      type: EventType.PendantLinked,
      label: translations.events.texts.pendantPaired(),
    },
    {
      type: EventType.PendantOutOfRange,
      label: translations.events.texts.pendantOutOfRange(),
    },
    {
      type: EventType.PendantPairFail,
      label: translations.events.texts.pendantPairingFailure(),
    },
    {
      type: EventType.PendantUnpaired,
      label: translations.events.texts.pendantUnPaired(),
    },
    {
      type: EventType.PowerOffOnLowBattery,
      label: translations.events.texts.powerOffOnLowBattery(),
    },
    {
      type: EventType.SilentModeDisabled,
      label: translations.events.texts.silentModeDisabled(),
    },
    {
      type: EventType.SilentModeEnabled,
      label: translations.events.texts.silentModeEnabled(),
    },
    {
      type: EventType.Status,
      label: translations.common.texts.status(),
    },
    {
      type: EventType.StatusUpdate,
      label: translations.events.texts.statusUpdate(),
    },
  ].sort((a, b) => a.label.localeCompare(b.label));
