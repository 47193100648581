/* Copyright */

import { MailboxMessage } from "@sade/data-access";
import theme from "../app/theme";
import { translations } from "../generated/translationHelper";

export const resolveMailboxMessageStateIconColor = (message: MailboxMessage): string => {
  if (message.ackedTimestamp) return theme.palette.success.main;
  if (message.readTimestamp) return theme.palette.primary.main;

  return theme.palette.grey[300];
};

export const resolveMailboxMessageLabel = (message: MailboxMessage): string => {
  if (message.ackedTimestamp) return translations.mailboxDialog.texts.confirmed();
  if (message.readTimestamp) return translations.mailboxDialog.texts.read();

  return translations.common.texts.pending();
};
