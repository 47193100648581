/* Copyright */
import React from "react";

const CellularSignal4Bars: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 3H18V21H21V3ZM16 7H13V21H16V7ZM8 12H11V21H8V12ZM6 16H3V21H6V16Z" fill="#0288D1" />
    </svg>
  );
};

export default CellularSignal4Bars;
