/* Copyright */
import { Box, Tab, Tabs } from "@mui/material";
import { EventType, RoleIdentifier } from "@sade/data-access";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../app/theme";
import DevicesIcon from "../../assets/icons/devices-icon";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { translations } from "../../generated/translationHelper";
import { useAccessControl } from "../../hooks/useAccessControl";
import TestIds from "../../test-ids/test-ids";
import { getPath } from "../../utils/ssoPathUtil";
import { PATIENT_PREFIX, Paths } from "../../utils/utils";
import AccessControl from "../access-control/access-control";
import CallHistoryTable from "./call-history-tab/call-history-table";
import IncomingCallsTable from "./incoming-calls-tab/incoming-calls-table";

const MonitoringView: React.FC = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const { currentOrganization } = useAuthenticatedUser();

  React.useEffect(() => {
    if (state && state.from) setCurrentTabIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userHasAccessToActiveCalls = useAccessControl(RoleIdentifier.Supervisor, RoleIdentifier.Operator);

  const handleRowClick = (deviceId: string, eventId: string, eventType: EventType, patientId: string): void => {
    if (userHasAccessToActiveCalls) {
      const path = getPath(Paths.ACTIVE_CALL).replace(":deviceId", deviceId).replace(":eventId", eventId);
      navigate(path + `?patient_id=${patientId.replace(PATIENT_PREFIX, "")}&event_type=${eventType}`);
    }
  };

  const handleHistoryRowClick = (deviceId: string): void => {
    const path = getPath(Paths.EVENTS_AND_LOCATIONS)
      .replace(":parent", Paths.MONITORING)
      .replace(":deviceId", deviceId);
    navigate(path, { state: { from: pathname } });
  };

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, index: number): void => {
    setCurrentTabIndex(index);
  };

  const renderTabs = (): JSX.Element => {
    return (
      <React.Fragment>
        {currentTabIndex === 0 && (
          <IncomingCallsTable
            eventSet={currentOrganization?.getCallEventSet()}
            userHasAccessToActiveCalls={userHasAccessToActiveCalls}
            onRowClick={handleRowClick}
          />
        )}

        {currentTabIndex === 1 && (
          <CallHistoryTable
            eventSet={currentOrganization?.getCallEventSet()}
            userHasAccessToActiveCalls={userHasAccessToActiveCalls}
            handleRowClick={handleHistoryRowClick}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ padding: "3rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.Supervisor, RoleIdentifier.Operator]}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ marginBottom: "2rem" }}>
          <Tab
            icon={
              <DevicesIcon
                marginRight="1rem"
                fill={currentTabIndex === 0 ? theme.palette.primary.main : theme.palette.secondary.main}
              />
            }
            iconPosition="start"
            data-testid={TestIds.MonitoringPage.IncomingCallsTab}
            label={translations.monitoring.texts.incomingCallsTab()}
          />
          <Tab
            icon={
              <DevicesIcon
                marginRight="1rem"
                fill={currentTabIndex === 1 ? theme.palette.primary.main : theme.palette.secondary.main}
              />
            }
            iconPosition="start"
            data-testid={TestIds.MonitoringPage.CallHistoryTab}
            label={translations.monitoring.texts.callHistoryTab()}
          />
        </Tabs>
        {renderTabs()}
      </AccessControl>
    </Box>
  );
};

export default MonitoringView;
