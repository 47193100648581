/* Copyright */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  DeviceState,
  Organization,
  OrganizationParameterHierarchy,
  ParameterValueType,
  RoleIdentifier,
} from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { getParameterOriginator } from "../../../utils/parameterUtils";
import { DeviceSettings, OrganizationInfo, ParameterNames } from "../../../utils/utils";
import PhoneNumberTextfield from "../../common/phone-number-textfield";
import DeviceSettingsParameterOriginator from "./device-settings-parameter-originator";

type DeviceSettingsContentRightGridProps = {
  settings: DeviceSettings;
  deviceState?: DeviceState;
  parameterHierarchy?: OrganizationParameterHierarchy;
  organizationNameMap: OrganizationInfo[];
  deviceOrganization?: Organization;
  onSettingChanged: (param: ParameterNames, value: ParameterValueType) => void;
  onResetSetting?: (param: ParameterNames, value: ParameterValueType) => void;
};

const DeviceSettingsContentRightGrid: React.FC<DeviceSettingsContentRightGridProps> = ({
  settings,
  deviceState,
  parameterHierarchy,
  organizationNameMap,
  deviceOrganization,
  onSettingChanged,
  onResetSetting,
}) => {
  const renderParameterOriginator = (parameterName: ParameterNames): React.ReactNode => {
    const origin = getParameterOriginator(parameterName, parameterHierarchy, organizationNameMap);
    return (
      <DeviceSettingsParameterOriginator
        parameterOrigin={origin}
        isLocked={deviceOrganization?.isParameterLocked(origin.parameterName, parameterHierarchy ?? {}) ?? false}
        isOverridden={deviceState?.isParameterOverridden(origin.parameterName) ?? false}
        onReset={(paramValue: ParameterValueType): void => onResetSetting?.(parameterName, paramValue)}
      />
    );
  };

  return (
    <Grid item xs={12} md={6}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.audioPromptVolumeTooltip()}>
                <Box>
                  <InputLabel shrink>{translations.deviceSettings.texts.audioPromptVolume()}</InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Slider
                        value={settings.playbackVolume}
                        onChange={(_event: Event, newValue: number | number[]): void =>
                          onSettingChanged(ParameterNames.AUDIO_PROMPT_VOLUME, newValue as number)
                        }
                        aria-labelledby="playback-volume-input-slider"
                        data-testid={TestIds.DeviceSettingsDialog.AudioPromptVolume}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.AUDIO_PROMPT_VOLUME,
                          parameterHierarchy ?? {}
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="playbackVolume"
                        variant="outlined"
                        value={settings.playbackVolume}
                        size="small"
                        inputMode="numeric"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          !isNaN(Number(event.target.value)) && Number(event.target.value) < 101
                            ? onSettingChanged(ParameterNames.AUDIO_PROMPT_VOLUME, event.target.value)
                            : void 0
                        }
                        sx={{ width: 60, mt: -1 }}
                        inputProps={{ "data-testid": TestIds.DeviceSettingsDialog.AudioPromptVolumeInput }}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.AUDIO_PROMPT_VOLUME,
                          parameterHierarchy ?? {}
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.AUDIO_PROMPT_VOLUME)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.callVolumeTooltip()}>
                <Box>
                  <InputLabel shrink>{translations.deviceSettings.texts.callVolume()}</InputLabel>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Slider
                        value={settings.audioMasterVolume}
                        onChange={(_event: Event, newValue: number | number[]): void =>
                          onSettingChanged(ParameterNames.AUDIO_CALL_VOLUME, newValue as number)
                        }
                        aria-labelledby="master-volume-input-slider"
                        data-testid={TestIds.DeviceSettingsDialog.CallVolume}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.AUDIO_CALL_VOLUME,
                          parameterHierarchy ?? {}
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="audioMasterVolume"
                        variant="outlined"
                        value={settings.audioMasterVolume}
                        size="small"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          !isNaN(Number(event.target.value)) && Number(event.target.value) < 101
                            ? onSettingChanged(ParameterNames.AUDIO_CALL_VOLUME, event.target.value)
                            : void 0
                        }
                        sx={{ width: 60, mt: -1 }}
                        inputProps={{ "data-testid": TestIds.DeviceSettingsDialog.CallVolumeInput }}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.AUDIO_CALL_VOLUME,
                          parameterHierarchy ?? {}
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.AUDIO_CALL_VOLUME)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.callInServiceTooltip()}>
                {useAccessControl(
                  RoleIdentifier.PowerUser,
                  RoleIdentifier.WarehouseInstaller,
                  RoleIdentifier.DealerManager
                ) ? (
                  <PhoneNumberTextfield
                    label={translations.common.texts.callInService()}
                    id="callInService1"
                    value={settings.callInService1?.length ? settings.callInService1 : ""}
                    handlePhoneNumberChange={(number: string): void =>
                      onSettingChanged(ParameterNames.CALL_IN_SERVICE_1, number ?? "")
                    }
                    testId={TestIds.DeviceSettingsDialog.CallInService1}
                    disabled={deviceOrganization?.isParameterLocked(
                      ParameterNames.CALL_IN_SERVICE_1,
                      parameterHierarchy ?? {}
                    )}
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.common.texts.callInService()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.callInService1 ?? ""}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.CALL_IN_SERVICE_1)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.backupCallInNumber1Tooltip()}>
                {useAccessControl(
                  RoleIdentifier.PowerUser,
                  RoleIdentifier.WarehouseInstaller,
                  RoleIdentifier.DealerManager
                ) ? (
                  <Box>
                    <PhoneNumberTextfield
                      label={translations.deviceSettings.texts.backupCallInNumber1()}
                      id="callInService2"
                      value={settings.callInService2}
                      handlePhoneNumberChange={(number: string): void =>
                        onSettingChanged(ParameterNames.CALL_IN_SERVICE_2, number ?? "")
                      }
                      testId={TestIds.DeviceSettingsDialog.CallInService2}
                      disabled={deviceOrganization?.isParameterLocked(
                        ParameterNames.CALL_IN_SERVICE_2,
                        parameterHierarchy ?? {}
                      )}
                    />
                  </Box>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.backupCallInNumber1()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.callInService2 ?? ""}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.CALL_IN_SERVICE_2)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.backupCallInNumber2Tooltip()}>
                {useAccessControl(
                  RoleIdentifier.PowerUser,
                  RoleIdentifier.WarehouseInstaller,
                  RoleIdentifier.DealerManager
                ) ? (
                  <PhoneNumberTextfield
                    label={translations.deviceSettings.texts.backupCallInNumber2()}
                    id="callInService3"
                    value={settings.callInService3}
                    handlePhoneNumberChange={(number: string): void =>
                      onSettingChanged(ParameterNames.CALL_IN_SERVICE_3, number ?? "")
                    }
                    testId={TestIds.DeviceSettingsDialog.CallInService3}
                    disabled={deviceOrganization?.isParameterLocked(
                      ParameterNames.CALL_IN_SERVICE_3,
                      parameterHierarchy ?? {}
                    )}
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.backupCallInNumber2()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.callInService3 ?? ""}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.CALL_IN_SERVICE_3)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.variantTooltip()} placement="right">
                {useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager) ? (
                  <Box>
                    <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                      <InputLabel id="variant-label" shrink>
                        {translations.common.texts.variant()}
                      </InputLabel>
                      <Select
                        name={ParameterNames.VARIANT}
                        size="small"
                        labelId="variant-label"
                        value={settings.libris2SoftwareVariant ?? ""}
                        onChange={(event): void => onSettingChanged(ParameterNames.VARIANT, event.target.value)}
                        data-testid={TestIds.DeviceSettingsDialog.Variant}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.VARIANT,
                          parameterHierarchy ?? {}
                        )}
                      >
                        <MenuItem value="EN">{`${translations.common.texts.english()} (${translations.common.texts.default()})`}</MenuItem>
                        <MenuItem value="FR">{translations.common.texts.french()}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.common.texts.variant()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.libris2SoftwareVariant ?? ""}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.VARIANT)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default DeviceSettingsContentRightGrid;
