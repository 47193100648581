/* Copyright */
import React from "react";
import { Drawer, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigationDrawerItemList from "./navigation-drawer-item-list";
import NavigationDrawerFooter from "./navigation-drawer-footer";
import TestIds from "../../../../test-ids/test-ids";

type NavigationDrawerProps = {
  isOpen: boolean;
  handleCloseDrawer: () => void;
};

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ isOpen, handleCloseDrawer }) => {
  return (
    <Drawer
      anchor="left"
      transitionDuration={150}
      open={isOpen}
      onClose={handleCloseDrawer}
      data-testid={TestIds.Navigation.NavigationDrawer}
    >
      <Box className="navigation-drawer-container">
        <Box className="navigation-drawer-header">
          <IconButton
            onClick={handleCloseDrawer}
            color="primary"
            data-testid={TestIds.Navigation.NavigationDrawerCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="navigation-drawer-content">
          <NavigationDrawerItemList />
        </Box>
        <Box className="navigation-drawer-footer">
          <NavigationDrawerFooter />
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
