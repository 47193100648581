/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer/BaseObservable";
import { prefixlessId } from "./Utils";
export var UserSearchField;
(function (UserSearchField) {
    UserSearchField["Email"] = "Email";
    UserSearchField["FirstName"] = "FirstName";
    UserSearchField["LastName"] = "LastName";
})(UserSearchField || (UserSearchField = {}));
export var UserType;
(function (UserType) {
    UserType["WebApp"] = "WebApp";
    UserType["EverThere"] = "EverThere";
})(UserType || (UserType = {}));
export var CognitoAccountStatus;
(function (CognitoAccountStatus) {
    CognitoAccountStatus["Unconfirmed"] = "UNCONFIRMED";
    CognitoAccountStatus["Confirmed"] = "CONFIRMED";
    CognitoAccountStatus["Archived"] = "ARCHIVED";
    CognitoAccountStatus["Compromised"] = "COMPROMISED";
    CognitoAccountStatus["Unknown"] = "UNKNOWN";
    CognitoAccountStatus["ResetRequired"] = "RESET_REQUIRED";
    CognitoAccountStatus["ForceChangePassword"] = "FORCE_CHANGE_PASSWORD";
    CognitoAccountStatus["ExternalProvider"] = "EXTERNAL_PROVIDER";
})(CognitoAccountStatus || (CognitoAccountStatus = {}));
export var EventPushType;
(function (EventPushType) {
    EventPushType["AgsoapEnhanced"] = "AgsoapEnhanced";
    EventPushType["StagesWithCancel"] = "StagesWithCancel";
    EventPushType["DiceXml"] = "DiceXml";
    EventPushType["Put"] = "Put";
    EventPushType["Affiliated"] = "Affiliated";
    EventPushType["ApiMonitoring"] = "ApiMonitoring";
})(EventPushType || (EventPushType = {}));
export const EventPushTypeDisplay = {
    [EventPushType.AgsoapEnhanced]: "AGSOAP-Enhanced",
    [EventPushType.StagesWithCancel]: "StagesWithCancel",
    [EventPushType.DiceXml]: "DICE-XML",
    [EventPushType.Put]: "PUT",
    [EventPushType.Affiliated]: "Affiliated",
    [EventPushType.ApiMonitoring]: "APIMonitoring",
};
export class Organization extends BaseObservable {
    constructor(params) {
        var _a;
        super();
        this.id = params.id;
        this.name = params.name;
        this.parentId = params.organizationId;
        this.realmName = params.realmName;
        this.fullName = params.fullName;
        this.netsuiteId = params.netsuiteId;
        this.networkId = params.networkId;
        this.patientTextUrl = params.patientTextUrl;
        this.ssoConfiguration = params.ssoConfiguration;
        this.apiKeyIds = (_a = params.apiKeyIds) !== null && _a !== void 0 ? _a : [];
    }
    static isParentOrEqualOrganizationId(organizationId, maybeParentOrganizationId) {
        const a = this.toComparableFormat(organizationId);
        const b = this.toComparableFormat(maybeParentOrganizationId);
        return a.startsWith(b);
    }
    static toComparableFormat(id) {
        return prefixlessId(id).endsWith(":") ? id : `${id}:`;
    }
    getId() {
        return this.id;
    }
    /**
     * Display name for the organization.
     */
    getName() {
        return this.name;
    }
    getParentOrganizationId() {
        return this.parentId;
    }
    /**
     * Internal name for the organization.
     */
    getRealmName() {
        return this.realmName;
    }
    getNetsuiteId() {
        return this.netsuiteId;
    }
    getNetworkId() {
        return this.networkId;
    }
    getPatientTextUrl() {
        return this.patientTextUrl;
    }
    getSsoConfiguration() {
        return this.ssoConfiguration;
    }
    getApiKeyIds() {
        return this.apiKeyIds;
    }
    getFullName() {
        return this.fullName;
    }
}
