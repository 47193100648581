/* Copyright */
import { Button, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { translations } from "../../generated/translationHelper";
import TestIds from "../../test-ids/test-ids";
import { getPath } from "../../utils/ssoPathUtil";
import { Paths } from "../../utils/utils";

const SuccessView: React.FC = () => {
  const navigate = useNavigate();
  const { refreshAuthenticatedUser } = useAuthenticatedUser();

  const handleButtonClick = async (): Promise<void> => {
    await refreshAuthenticatedUser();
    navigate(getPath(Paths.ROOT));
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.registrationSuccess.texts.title()}
      </Typography>
      <Typography variant="h5" className="title">
        {translations.registrationSuccess.texts.subtitle()}
      </Typography>
      <Button
        data-testid={TestIds.Registration.OpenPortal}
        size="large"
        variant="contained"
        className="button"
        onClick={handleButtonClick}
      >
        {translations.common.buttons.openNumeraPortal()}
      </Button>
    </React.Fragment>
  );
};

export default SuccessView;
