/* Copyright */
import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { translations } from "../../generated/translationHelper";
import { Maybe } from "@sade/data-access";

type GoogleMapsWrapperProps = {
  children: Maybe<React.ReactNode>;
};

const GoogleMapsWrapper: React.FC<GoogleMapsWrapperProps> = ({ children }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  if (!apiKey) {
    return <div>{translations.googleMaps.texts.cannotDisplayMap()}</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

export default GoogleMapsWrapper;
