/* Copyright */
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import { Maybe, User } from "@sade/data-access";
import { UserRealmRole } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";

type RevokeRealmAccessDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  userData: Maybe<User>;
  realmRole: Maybe<UserRealmRole>;
  isRevokingRealmAccess: boolean;
};

const RevokeRealmAccessDialog: React.FC<RevokeRealmAccessDialogProps> = ({
  open,
  handleClose,
  handleSubmit,
  userData,
  realmRole,
  isRevokingRealmAccess,
}) => {
  const firstName = userData?.getDetails().firstName ?? "";
  const lastName = userData?.getDetails().lastName ?? "";
  const realmName = realmRole?.realmName ?? "";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      data-testid={TestIds.RevokeRealmAccessDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.common.texts.revokeAccess()}
        <IconButton
          data-testid={TestIds.RevokeRealmAccessDialog.CloseButton}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.revokeRealmAccessDialog.texts.subtitle({ firstName, lastName, realmName }),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => handleSubmit()}
          color="error"
          disabled={isRevokingRealmAccess}
          data-testid={TestIds.RevokeRealmAccessDialog.SubmitButton}
          startIcon={isRevokingRealmAccess && <CircularProgress size={16} color="secondary" />}
        >
          {translations.common.texts.revokeAccess()}
        </Button>
        <Button variant="outlined" onClick={handleClose} data-testid={TestIds.RevokeRealmAccessDialog.CancelButton}>
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevokeRealmAccessDialog;
