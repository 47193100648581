/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button, CircularProgress } from "@mui/material";
import { TeamMember } from "@sade/data-access";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../../../generated/translationHelper";
import { useEverThereContext } from "../../../../../context/everThere-context";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { SeverityType } from "../../../../../context/snackbar-context";
import TestIds from "../../../../../test-ids/test-ids";

type MakeUserTeamManagerDialogProps = {
  open: boolean;
  member: TeamMember;
  handleClose: () => void;
};

const MakeUserTeamManagerDialog: React.FC<MakeUserTeamManagerDialogProps> = ({ open, member, handleClose }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { everThereData } = useEverThereContext();
  const { showSnackbar } = useSnackbar();

  const memberFirstName = member.firstName ?? member.email ?? "";

  const handleCloseClick = (): void => {
    handleClose();
  };

  const setMemberAsManager = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await everThereData?.setTeamManager(member.userId);
      showSnackbar(
        translations.deviceEverThereMakeMemberTeamManagerDialog.texts.successHeader(),
        SeverityType.Success,
        undefined,
        undefined,
        translations.deviceEverThereMakeMemberTeamManagerDialog.texts.successInfo({ memberFirstName })
      );
    } catch (error) {
      showSnackbar(
        translations.deviceEverThereMakeMemberTeamManagerDialog.texts.successHeader(),
        SeverityType.Error,
        undefined,
        undefined
      );
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleConfirmClick = (): void => {
    setMemberAsManager();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DeviceEverThereMakeMemberATeamManagerDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereMakeMemberTeamManagerDialog.texts.title({ memberFirstName })}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceEverThereMakeMemberAWearerDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {translations.deviceEverThereMakeMemberTeamManagerDialog.texts.content({ memberFirstName })}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleConfirmClick}
            data-testid={TestIds.DeviceEverThereMakeMemberATeamManagerDialog.ConfirmButton}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} />}
          >
            {translations.common.buttons.confirm()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceEverThereMakeMemberATeamManagerDialog.ActionCloseButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MakeUserTeamManagerDialog;
