/* Copyright*/
import { BaseObservable } from "../observer";
export class Patient extends BaseObservable {
    constructor(id, params) {
        super();
        this.id = id;
        this.firstName = params.firstName;
        this.lastName = params.lastName;
        this.birthDate = params.birthDate;
        this.gender = params.gender;
        this.address1 = params.address1;
        this.address2 = params.address2;
        this.city = params.city;
        this.state = params.state;
        this.zipCode = params.zipCode;
        this.phoneNumber = params.phoneNumber;
        this.title = params.title;
        this.wearer = params.wearer;
        this.teamId = params.teamId;
        this.height = params.height;
        this.weight = params.weight;
        this.customerId = params.customerId;
    }
    getId() {
        return this.id;
    }
    getFirstName() {
        return this.firstName;
    }
    getLastName() {
        return this.lastName;
    }
    getBirthDate() {
        return this.birthDate;
    }
    getGender() {
        return this.gender;
    }
    getAddress1() {
        return this.address1;
    }
    getAddress2() {
        return this.address2;
    }
    getCity() {
        return this.city;
    }
    getState() {
        return this.state;
    }
    getZipCode() {
        return this.zipCode;
    }
    getPhoneNumber() {
        return this.phoneNumber;
    }
    getTitle() {
        return this.title;
    }
    getWearer() {
        return this.wearer;
    }
    getTeamId() {
        return this.teamId;
    }
    getHeight() {
        return this.height;
    }
    getWeight() {
        return this.weight;
    }
    getCustomerId() {
        return this.customerId;
    }
}
