/* Copyright */
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { trimPhoneNumber } from "../../../utils/utils";
import { AuthWrapper } from "@sade/data-access";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SeverityType } from "../../../context/snackbar-context";
import TestIds from "../../../test-ids/test-ids";

type EditPhoneNumberDialogProps = {
  open: boolean;
  handleClose: () => void;
  originalCountryCode: string;
  originalPhoneNumber: string;
  handleUpdatePhoneNumber: (countryCode: string, phoneNumber: string) => void;
};

const EditPhoneNumberDialog: React.FC<EditPhoneNumberDialogProps> = ({
  open,
  handleClose,
  originalCountryCode,
  originalPhoneNumber,
  handleUpdatePhoneNumber,
}) => {
  const { showSnackbar } = useSnackbar();
  const { isRndUser } = useAuthenticatedUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState(originalCountryCode);
  const [phoneNumber, setPhoneNumber] = React.useState(originalPhoneNumber);
  const [error, setError] = React.useState("");

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    setError("");
    setCountryCode(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setError("");
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    setError("");
    try {
      setIsLoading(true);
      let finalPhoneNumber = undefined;
      finalPhoneNumber = trimPhoneNumber(phoneNumber, countryCode);
      if (!finalPhoneNumber) {
        setError(translations.common.texts.invalidPhoneNumber());
        return;
      } else {
        await AuthWrapper.setPhoneNumber(finalPhoneNumber);
        handleUpdatePhoneNumber(countryCode, phoneNumber);
        handleClose();
        showSnackbar(translations.common.texts.phoneNumberUpdated(), SeverityType.Success);
      }
    } catch {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = !phoneNumber || isLoading;

  return (
    <Dialog open={open} fullWidth data-testid={TestIds.EditPhoneNumberDialog.Dialog}>
      <DialogTitle variant="h5">
        {translations.common.texts.editPhoneNumber()}
        <IconButton
          aria-label="close"
          data-testid={TestIds.EditPhoneNumberDialog.CloseButton}
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
          <FormControl sx={{ textAlign: "start", width: "30%" }}>
            <InputLabel id="country-code-label" shrink></InputLabel>
            <Select
              name="countryCode"
              size="small"
              labelId="country-code-label"
              value={countryCode}
              onChange={handleSelectChange}
              data-testid={TestIds.EditPhoneNumberDialog.CountryCodeSelect}
            >
              <MenuItem value="+1">+1</MenuItem>
              {isRndUser && <MenuItem value="+358">+358</MenuItem>}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%", ml: 2 }}>
            <InputLabel id="phone-number-label" shrink>
              {translations.common.texts.phoneNumber()}
            </InputLabel>
            <TextField
              id="phoneNumber"
              className="input"
              inputProps={{ "data-testid": TestIds.EditPhoneNumberDialog.PhoneNumberInput }}
              value={phoneNumber}
              error={error.length > 0}
              helperText={error.length > 0 && error}
              onChange={handleInputChange}
              onKeyDown={(e): void => {
                if (e.key === "Enter" && !isSubmitDisabled) handleSubmit();
              }}
            ></TextField>
          </FormControl>
        </Box>
        <Typography variant="body2" sx={{ mt: 3 }}>
          {translations.smsVerification.texts.editInfo()}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          data-testid={TestIds.EditPhoneNumberDialog.SubmitButton}
          startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
        >
          {translations.smsVerification.buttons.resendCode()}
        </Button>
        <Button variant="outlined" onClick={handleClose} data-testid={TestIds.EditPhoneNumberDialog.CancelButton}>
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPhoneNumberDialog;
