/* Copyright */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import { Maybe, RoleIdentifier } from "@sade/data-access";
import React, { ChangeEvent } from "react";
import { translations } from "../../../../generated/translationHelper";
import { useAccessControl } from "../../../../hooks/useAccessControl";
import TestIds from "../../../../test-ids/test-ids";
import { enableEdit, enableEditOperatorPlan, getNameRowLabel } from "../../../../utils/deviceDetailsUtils";
import { ExternalDeviceIdentifierParameter } from "../../../../utils/parameterUtils";
import { DeviceDetails, OrganizationInfo } from "../../../../utils/utils";
import CustomTextFieldWithValidation from "../../../common/custom-textfield-with-validation";
import RealmPicker from "../../../common/realm-picker";
import CustomTextField from "../../../common/textfield";

type DeviceDetailsDialogContentLeftGridProps = {
  deviceDetails: DeviceDetails;
  organizationList: Maybe<OrganizationInfo[]>;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  externalParameter1: Maybe<ExternalDeviceIdentifierParameter>;
  externalParameter2: Maybe<ExternalDeviceIdentifierParameter>;
  externalParameter3: Maybe<ExternalDeviceIdentifierParameter>;
  handleCustomInputChange: (id: string, value: string, isError: boolean) => void;
};

const DeviceDetailsDialogContentLeftGrid: React.FC<DeviceDetailsDialogContentLeftGridProps> = ({
  deviceDetails,
  organizationList,
  handleInputChange,
  handleSelectChange,
  externalParameter1,
  externalParameter2,
  externalParameter3,
  handleCustomInputChange,
}) => {
  return (
    <Grid item xs={12} md={6} sx={{ paddingLeft: 0 }}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              {externalParameter1 ? (
                <CustomTextFieldWithValidation
                  label={getNameRowLabel(externalParameter1)}
                  id="externalId1"
                  validationPattern={externalParameter1.pattern}
                  value={deviceDetails.externalId1}
                  onValueChange={handleCustomInputChange}
                  placeholder={externalParameter1.placeholder}
                />
              ) : (
                <CustomTextField
                  label={translations.common.texts.deviceName()}
                  id="deviceName"
                  testId={TestIds.DeviceDetailsDialog.DeviceNameInput}
                  value={deviceDetails.deviceName}
                  onChange={handleInputChange}
                  disabled={!enableEdit(deviceDetails.deviceStatus)}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.ImeiField}>
                <InputLabel shrink>{translations.common.texts.imei()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.imei}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.IccidIdField}>
                <InputLabel shrink>{translations.common.texts.iccid()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.iccid}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl fullWidth data-testid={TestIds.DeviceDetailsDialog.SerialNumberField}>
                <InputLabel shrink>{translations.common.texts.serialNumber()}</InputLabel>
                <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                  {deviceDetails.serialNumber}
                </Typography>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                <InputLabel id="operatorPlan-label" shrink>
                  {translations.deviceDetails.texts.networkOperatorAndPlan()}
                </InputLabel>
                <Select
                  name="operatorPlan"
                  size="small"
                  labelId="networkOperator-label"
                  value={deviceDetails?.operatorPlan ?? "invalid"}
                  onChange={handleSelectChange}
                  data-testid={TestIds.DeviceDetailsDialog.NetworkOperatorSelect}
                  disabled={
                    !useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.SuperUser) ||
                    !enableEditOperatorPlan(deviceDetails.deviceStatus)
                  }
                >
                  {deviceDetails.operatorPlans.map((plan) => (
                    <MenuItem key={plan.planId} value={plan.planId}>
                      {plan.operatorDisplayName} - {plan.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 80 }}>
            <TableCell>
              <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                <RealmPicker
                  childOrganizations={organizationList}
                  selectedRealmId={deviceDetails.realmId}
                  onRealmPicked={(realmId: string): void =>
                    handleSelectChange({ target: { name: "realmId", value: realmId } } as ChangeEvent<HTMLInputElement>)
                  }
                  testId={TestIds.DeviceDetailsDialog.RealmSelect}
                  disabled={!enableEdit(deviceDetails.deviceStatus)}
                />
              </FormControl>
            </TableCell>
          </TableRow>
          {externalParameter2 && (
            <TableRow sx={{ height: 80 }}>
              <TableCell>
                <CustomTextFieldWithValidation
                  label={externalParameter2.label}
                  id="externalId2"
                  validationPattern={externalParameter2.pattern}
                  value={deviceDetails.externalId2}
                  onValueChange={handleCustomInputChange}
                  placeholder={externalParameter2.placeholder}
                  disabled={!enableEdit(deviceDetails.deviceStatus)}
                />
              </TableCell>
            </TableRow>
          )}
          {externalParameter3 && (
            <TableRow sx={{ height: 80 }}>
              <TableCell>
                <CustomTextFieldWithValidation
                  label={externalParameter3.label}
                  id="externalId2"
                  validationPattern={externalParameter3.pattern}
                  value={deviceDetails.externalId2}
                  onValueChange={handleCustomInputChange}
                  placeholder={externalParameter3.placeholder}
                  disabled={!enableEdit(deviceDetails.deviceStatus)}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default DeviceDetailsDialogContentLeftGrid;
