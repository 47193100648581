/* Copyright*/
import { ResultType, TeamsDevicesAddDocument, TeamsDevicesRemoveDocument, TeamsInvitationsResendDocument, TeamsMembersAddDocument, TeamsMembersRemoveDocument, TeamsSetDeviceNicknameDocument, TeamsSetWearerRelationshipDocument, TeamsUpdateDocument, } from "../../generated/gqlUsers";
import { AppSyncClientFactory, Service } from "../backend";
import { throwGQLError } from "../private-utils/throwGQLError";
import { Team, UserInvitationStatus } from "./Team";
export class AWSTeam extends Team {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSTeam;
    }
    delete() {
        return this.backend.deleteTeam(this.getId());
    }
    async setName(name) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsUpdateDocument, {
            payload: {
                id: this.getId(),
                name,
            },
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsUpdate) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsUpdate) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to set team name");
        }
        this.name = name;
    }
    async setTeamManager(userId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsUpdateDocument, {
            payload: {
                id: this.getId(),
                teamManager: userId,
            },
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsUpdate) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsUpdate) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to set team manager");
        }
        this.members.forEach((member) => {
            member.isTeamManager = member.userId === userId;
        });
    }
    async setDeviceNickname(deviceId, nickname) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsSetDeviceNicknameDocument, {
            payload: {
                teamId: this.getId(),
                deviceId,
                nickname,
            },
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsSetDeviceNickname) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsSetDeviceNickname) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to set device nickname");
        }
        const device = this.devices.find((device) => device.deviceId === deviceId);
        if (device) {
            device.nickname = nickname;
        }
    }
    async setWearerRelationship(deviceId, relationship) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsSetWearerRelationshipDocument, {
            payload: {
                teamId: this.getId(),
                deviceId,
                relationship,
            },
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsSetWearerRelationship) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsSetWearerRelationship) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to set wearer relationship");
        }
        // TODO: Update local team instance if/when relationship is added to TeamDevice
    }
    async addTeamMember(email, inviteLanguage) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsMembersAddDocument, {
            payload: {
                teamId: this.getId(),
                userEmail: email,
                inviteLanguage,
            },
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsMembersAdd)) {
            throwGQLError(response, "Failed to add team member");
        }
        const member = response.data.teamsMembersAdd;
        this.members.push(member);
        return member;
    }
    async removeTeamMember(userId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsMembersRemoveDocument, { teamId: this.getId(), userId });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsMembersRemove)) {
            throwGQLError(response, "Failed to remove user from team");
        }
        const success = response.data.teamsMembersRemove.result === ResultType.Ok;
        if (!success) {
            const reason = response.data.teamsMembersRemove.failureReason;
            throw new Error(`Failed to remove user from team ${reason ? ": " + reason : ""}`);
        }
        this.members = this.members.filter((member) => member.userId !== userId);
    }
    async addDevice(patientId, deviceId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsDevicesAddDocument, {
            teamId: this.getId(),
            patientId,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsDevicesAdd)) {
            throwGQLError(response, "Failed to add device");
        }
        const device = response.data.teamsDevicesAdd;
        this.devices.push(Object.assign(Object.assign({}, device), { deviceId }));
        return device;
    }
    async removeDevice(patientId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsDevicesRemoveDocument, {
            teamId: this.getId(),
            patientId,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsDevicesRemove)) {
            throwGQLError(response, "Failed to remove device");
        }
        const success = response.data.teamsDevicesRemove.result === ResultType.Ok;
        if (!success) {
            const reason = response.data.teamsDevicesRemove.failureReason;
            throw new Error(`Failed to remove device ${reason ? ": " + reason : ""}`);
        }
        this.devices = this.devices.filter((device) => device.patientId !== patientId);
    }
    async resendInvitation(userId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsInvitationsResendDocument, { teamId: this.getId(), userId });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsInvitationsResend)) {
            throwGQLError(response, "Failed to resend invitation");
        }
        const success = response.data.teamsInvitationsResend.result === ResultType.Ok;
        if (!success) {
            const reason = response.data.teamsInvitationsResend.failureReason;
            throw new Error(`Failed to resend invitation ${reason ? ": " + reason : ""}`);
        }
        const member = this.members.find((member) => member.userId === userId);
        if (member) {
            member.invitationStatus = UserInvitationStatus.Pending;
        }
    }
    async onRelationChange(change) {
        console.log("Team relation change", change);
    }
}
