/* Copyright */
import React, { ReactNode } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  Popper,
  PopperProps,
  TextField,
} from "@mui/material";
import { Maybe } from "@sade/data-access";
import { translations } from "../../generated/translationHelper";
import { OrganizationInfo } from "../../utils/utils";

type RealmFilterPickerProps = {
  isLoading: boolean;
  childOrganizations: Maybe<OrganizationInfo[]>;
  filters: string[];
  handleFilterChange: (filters: string[]) => void;
  testId?: string;
};

const CustomPopper = function (props: PopperProps): JSX.Element {
  return <Popper {...props} style={{ ...props.style, width: "500px" }} placement="bottom-start" />;
};

const RealmFilterPicker: React.FC<RealmFilterPickerProps> = ({
  isLoading,
  childOrganizations,
  handleFilterChange,
  filters,
  testId,
}) => {
  const [value, setValue] = React.useState<string>("");
  const [filterValue, setFilterValue] = React.useState<string>();
  const [hideTags, setHideTags] = React.useState<boolean>(true);
  const [selectedRealms, setSelectedRealms] = React.useState<string[]>(filters);

  React.useEffect(() => {
    setSelectedRealms(filters);
    setValue(
      childOrganizations
        ?.filter((realm: OrganizationInfo) => filters.includes(realm.id))
        .map((realm: OrganizationInfo) => realm.fullName)
        .join(", ") ?? ""
    );
  }, [childOrganizations, filters]);

  React.useEffect(() => {
    setHideTags(!!filterValue?.length);
  }, [filterValue]);

  const handleRealmSelectChange = (realms: string[]): void => {
    setSelectedRealms(realms);
    handleFilterChange(realms);
  };

  return (
    <FormControl id="realms-form-control" className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
      <InputLabel shrink id="filter-by-realm-label">
        {translations.common.inputs.realm()}
      </InputLabel>
      <Autocomplete
        key={1}
        multiple
        disableCloseOnSelect
        loading={isLoading || !childOrganizations}
        onBlur={(): void => setFilterValue(undefined)}
        options={childOrganizations ?? []}
        filterOptions={(options): OrganizationInfo[] =>
          options.filter(
            (option) => !filterValue?.length || option.fullName.toLowerCase().includes(filterValue.toLowerCase())
          )
        }
        renderTags={(): ReactNode => []}
        data-testid={testId}
        onChange={(_event, value): void => handleRealmSelectChange(value.map((realm) => realm.id))}
        getOptionLabel={(option: OrganizationInfo): string => option.fullName}
        renderInput={(params): React.ReactNode => (
          <TextField
            {...params}
            placeholder={hideTags ? undefined : value}
            value={filterValue}
            onChange={(event): void => setFilterValue(event.target.value)}
            style={{ flexWrap: "nowrap", WebkitFlexWrap: "nowrap" }}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& input::placeholder": {
                  opacity: 1,
                },
              },
            }}
          />
        )}
        loadingText={
          <Box sx={{ display: "flex", pt: "16px", pb: "16px", justifyContent: "center" }}>
            <CircularProgress size={16} />
          </Box>
        }
        renderOption={(props, option): React.ReactNode => (
          <ListItem {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selectedRealms.includes(option.id)}
              sx={{ mr: 4 }}
            />
            {option.fullName}
          </ListItem>
        )}
        PopperComponent={(props): JSX.Element => <CustomPopper {...props} />}
      />
      <FormHelperText id="form-helper">{String(" ")}</FormHelperText>
    </FormControl>
  );
};

export default RealmFilterPicker;
