/* Copyright */
import {
  AuthWrapper,
  AuthenticatedUser,
  AwsConfiguration,
  BackendFactory,
  Maybe,
  Nullable,
  Organization,
  Role,
  RoleIdentifier,
  User,
  UserObserver,
} from "@sade/data-access";
import * as React from "react";
import { AwsConfigFactory } from "../utils/AwsConfigFactory";
import { getRedirectUrl, getSsoPath, setSsoPathHolder } from "../utils/ssoPathUtil";
import { UserOrganizationWithRole, getAllRoles } from "../utils/utils";

type AuthenticatedUserReturnType = {
  isLoadingAuthenticatedUser: boolean;
  authenticatedUser: Nullable<AuthenticatedUser>;
  setAuthenticatedUser: React.Dispatch<React.SetStateAction<Nullable<AuthenticatedUser>>>;
  authenticatedUserRole: Maybe<Role>;
  isRndUser: boolean;
  userInfo: Maybe<User>;
  currentOrganization: Maybe<Organization>;
  currentOrganizationName: string;
  getUserData: () => void;
  userOrganizationRoles: UserOrganizationWithRole[];
  isChangingRealm: boolean;
  changeRealmAccess: (selectedOrganizationId: string) => Promise<void>;
  refreshAuthenticatedUser: () => Promise<void>;
};

const AuthenticatedUserContext = React.createContext<AuthenticatedUserReturnType>({} as AuthenticatedUserReturnType);
const { Provider } = AuthenticatedUserContext;

export const AuthenticatedUserProvider: React.FC = ({ children }) => {
  const backend = BackendFactory.getOrganizationBackend();
  const [isLoadingAuthenticatedUser, setIsLoadingAuthenticatedUser] = React.useState<boolean>(false);
  const [authenticatedUser, setAuthenticatedUser] = React.useState<Nullable<AuthenticatedUser>>(null);
  const [authenticatedUserRole, setAuthenticatedUserRole] = React.useState<Maybe<Role>>(undefined);
  const [isRndUser, setIsRndUser] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = React.useState<Maybe<User>>(undefined);
  const [currentOrganization, setCurrentOrganization] = React.useState<Maybe<Organization>>(undefined);
  const [currentOrganizationName, setCurrentOrganizationName] = React.useState<string>("");
  const [userOrganizationRoles, setUserOrganizationRoles] = React.useState<UserOrganizationWithRole[]>([]);
  const [isChangingRealm, setIsChangingRealm] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        setIsLoadingAuthenticatedUser(true);
        const currentUser = await AuthWrapper.getCurrentAuthenticatedUser();
        if (!currentUser) return;
        setAuthenticatedUser(currentUser);

        if (!currentUser.attributes?.identities) {
          setSsoPathHolder(undefined);
          return;
        }

        try {
          const identities = JSON.parse(currentUser.attributes.identities);
          const providerName = identities[0].providerName;
          setSsoPathHolder({ provider: providerName, redirectUrl: getRedirectUrl(window.location, providerName) });
          AwsConfiguration.configure(AwsConfigFactory.getConfig(getSsoPath()?.redirectUrl));
          AuthWrapper.configureAmplify();
        } catch (err) {
          console.error("unable to parse identities", err);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAuthenticatedUser(false);
      }
    })();
  }, []);

  const getOrganizationRoleData = React.useCallback(async (): Promise<void> => {
    const currentOrganizationId =
      (await AuthWrapper.getActiveOrganizationId()) ??
      (await BackendFactory.getOrganizationBackend().getCurrentHomeOrganization()).getId();
    if (currentOrganizationId) {
      const [currentOrganization, allRoles, userRoleIdentifier, rndUser] = await Promise.all([
        backend.getOrganization(currentOrganizationId),
        getAllRoles(),
        AuthWrapper.getOrganizationRole(currentOrganizationId!),
        AuthWrapper.getRdUserFlag(),
      ]);

      if (currentOrganization) {
        setCurrentOrganization(currentOrganization);
        setCurrentOrganizationName(currentOrganization?.getFullName());
      }

      setAuthenticatedUserRole(allRoles.find((role) => role.identifier === userRoleIdentifier));
      setIsRndUser(rndUser);
    }
    const userOrganizations: UserOrganizationWithRole[] = [];
    const orgs = await userInfo?.getOrganizations();
    orgs?.map(async (org) => {
      const roles = await userInfo?.getOrganizationRoles(org.getId());
      if (roles?.length) {
        const indexOfRoleIdentifier = (Object.values(RoleIdentifier) as string[]).indexOf(roles[0].identifier);
        const role = Object.keys(RoleIdentifier)[indexOfRoleIdentifier] as keyof typeof RoleIdentifier;
        userOrganizations.push({
          id: org.getId(),
          fullName: org.getFullName(),
          role: role,
        } as UserOrganizationWithRole);
      }
    });
    setUserOrganizationRoles(userOrganizations.sort((a, b) => a.fullName.localeCompare(b.fullName)));
  }, [backend, userInfo]);

  const getUserData = React.useCallback(async (): Promise<void> => {
    const userObserver: UserObserver = {
      async onActiveOrganizationChange(_user): Promise<void> {
        getOrganizationRoleData();
      },
    };

    try {
      setIsLoadingAuthenticatedUser(true);
      const [currentUser] = await Promise.all([
        BackendFactory.getOrganizationBackend().getCurrentUser(),
        getOrganizationRoleData(),
      ]);
      if (currentUser) {
        currentUser?.addObserver(userObserver);

        setUserInfo(currentUser);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAuthenticatedUser(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser]);

  const changeRealmAccess = React.useCallback(
    async (selectedOrganizationId: string) => {
      setIsChangingRealm(true);
      setAuthenticatedUserRole(undefined);
      try {
        await userInfo?.changeActiveOrganization(selectedOrganizationId);
        await AuthWrapper.refreshAuthentication();
      } catch (error) {
        console.error(error);
        throw new Error("Realm access change failed");
      } finally {
        setIsChangingRealm(false);
      }
    },
    [userInfo]
  );

  const refreshAuthenticatedUser = React.useCallback(async (): Promise<void> => {
    const currentUser = await AuthWrapper.getCurrentAuthenticatedUser(true);
    if (currentUser) {
      setAuthenticatedUser(currentUser);
    }
  }, []);

  const value = React.useMemo(
    (): AuthenticatedUserReturnType => ({
      isLoadingAuthenticatedUser,
      authenticatedUser,
      setAuthenticatedUser,
      authenticatedUserRole,
      isRndUser: isRndUser,
      userInfo,
      currentOrganization,
      currentOrganizationName,
      getUserData,
      userOrganizationRoles,
      isChangingRealm,
      changeRealmAccess,
      refreshAuthenticatedUser,
    }),
    [
      isLoadingAuthenticatedUser,
      authenticatedUser,
      authenticatedUserRole,
      isRndUser,
      userInfo,
      currentOrganization,
      currentOrganizationName,
      getUserData,
      userOrganizationRoles,
      isChangingRealm,
      changeRealmAccess,
      refreshAuthenticatedUser,
    ]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useAuthenticatedUser = (): AuthenticatedUserReturnType => {
  const context = React.useContext(AuthenticatedUserContext);
  if (context === undefined) {
    throw new Error("useAuthenticatedUser must be used within a AuthenticatedUserProvider");
  }

  return context;
};
