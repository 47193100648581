/* Copyright */
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { BackendFactory, Device, DeviceAttributeName, RoleIdentifier } from "@sade/data-access";
import xlsx from "node-xlsx";
import React from "react";
import { SeverityType, SnackbarHorizontalPosition, SnackbarVerticalPosition } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import Loading from "../../loading/loading";
import ReportsViewFilters from "./reports-view-filters";

type ReportsViewFilterBarProps = {
  handleReportTypeSelectChange: (reportType: string) => void;
  selectedReportType: ReportType;
  handleStatusSelectChange: (event: SelectChangeEvent<string[]>) => void;
  selectedStatuses: string[];
};

export enum ReportType {
  DeviceStatus = "DeviceStatus",
}

const ReportsViewFilterBar: React.FC<ReportsViewFilterBarProps> = ({
  selectedReportType,
  handleReportTypeSelectChange,
  handleStatusSelectChange,
  selectedStatuses,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const organizationBackend = BackendFactory.getOrganizationBackend();
  const backend = BackendFactory.getBackend();

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportTypeSelectedChange = (event: SelectChangeEvent<string>): void => {
    const {
      target: { value },
    } = event;

    handleReportTypeSelectChange(value);
  };

  const download = (content: Buffer, fileName: string, contentType: string): void => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
    setIsLoading(false);
    showSnackbar(
      translations.reports.texts.downloadSuccessHeader(),
      SeverityType.Success,
      SnackbarVerticalPosition.Bottom,
      SnackbarHorizontalPosition.Center
    );
  };

  const getLatestDeviceEvents = async (status: string[]): Promise<Device[]> => {
    const latestEvents: Device[] = [];
    const latestEventsResponse = await backend.getLatestDeviceEvents(status);

    if (latestEventsResponse) {
      latestEvents.push(...latestEventsResponse);
    }
    return latestEvents;
  };

  const insertExcelHeaderLine = (deviceStatusList: string[][]): void => {
    const titles = ["Realm", "IMEI", "Name", "Status", "Software Version", "Caller ID", "Event Type", "Event Date"];
    deviceStatusList.unshift(titles);
  };

  const getDeviceStatusRows = async (devices: Device[]): Promise<string[][]> => {
    const deviceStatusList: string[][] = [];
    await Promise.all(
      devices.map(async (device, i) => {
        const organization = await organizationBackend.getOrganization(
          device.getAttribute(DeviceAttributeName.organization) ?? ""
        );

        const latestEvent = device.getLatestEvent();

        const deviceStatusListItem = [
          organization?.getFullName() ?? "",
          devices[i].getId(),
          devices[i].getName() ?? "",
          devices[i].getDeviceStatus() ?? "",
          devices[i].getAttribute(DeviceAttributeName.softwareVersion) ?? "",
          devices[i].getAttribute(DeviceAttributeName.callerIdNumber) ?? "",
          latestEvent?.type ?? "",
          latestEvent?.updatedTimestamp ? latestEvent.updatedTimestamp.toLocaleString() : "",
        ];
        deviceStatusList.push(deviceStatusListItem);
      })
    );

    return deviceStatusList;
  };

  const getExcelDataBuffer = async (devices: Device[]): Promise<Buffer> => {
    const deviceStatusList = await getDeviceStatusRows(devices);
    insertExcelHeaderLine(deviceStatusList);
    return xlsx.build([{ name: "Status", data: deviceStatusList, options: {} }]);
  };

  const downloadClicked = async (): Promise<void> => {
    setIsLoading(true);
    if (selectedStatuses.length > 0) {
      setIsLoading(true);
      const latestEvents = await getLatestDeviceEvents(selectedStatuses);
      setIsLoading(false);
      try {
        const buffer = await getExcelDataBuffer(latestEvents);
        download(
          buffer,
          new Date().toLocaleDateString() + ".xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } catch (error) {
        setIsLoading(false);
        showSnackbar(
          translations.reports.texts.downloadFailed(),
          SeverityType.Error,
          SnackbarVerticalPosition.Bottom,
          SnackbarHorizontalPosition.Center,
          translations.reports.texts.generationFailed()
        );
      }

      return;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column-reverse", lg: "row" },
        alignItems: { xl: "center" },
      }}
    >
      <Box sx={{ display: "flex", flex: 1, flexDirection: { xs: "row", lg: "row" } }}>
        <FormControl className="input" sx={{ textAlign: "start", mr: 1, maxWidth: "180px" }} fullWidth>
          <InputLabel id="report-type-label" shrink>
            {translations.reports.inputs.reportType()}
          </InputLabel>
          <Select
            id="reportType"
            size="small"
            labelId="report-type-label"
            data-testid={TestIds.Reports.ReportTypeDropdown}
            inputProps={{ "data-testid": TestIds.Reports.ReportTypeDropdown }}
            onChange={reportTypeSelectedChange}
            disabled={true}
            value={selectedReportType ?? ReportType.DeviceStatus}
          >
            <MenuItem value={ReportType.DeviceStatus} data-testid={TestIds.Reports.ReportTypeDropdown}>
              {translations.reports.texts.reportTypeDeviceStatus()}
            </MenuItem>
          </Select>
          <FormHelperText>{String(" ")}</FormHelperText>
        </FormControl>
        <ReportsViewFilters
          isLoading={isLoading}
          handleStatusSelectChange={handleStatusSelectChange}
          selectedStatuses={selectedStatuses}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: { xs: "flex-end", xl: "flex-start" }, ml: 4, mb: { xs: 3, xl: 2 } }}>
        {useAccessControl(
          RoleIdentifier.PowerUser,
          RoleIdentifier.SuperUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor
        ) && (
          <Button
            variant="contained"
            startIcon={isLoading ? <Loading height={16} marginTop={0} /> : <DownloadIcon />}
            disabled={isLoading || selectedStatuses.length === 0}
            onClick={async (): Promise<void> => downloadClicked()}
            data-testid={TestIds.Reports.DownloadButton}
          >
            {translations.reports.buttons.download()}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReportsViewFilterBar;
