/* Copyright */
import * as React from "react";
import { Box, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { UserOrganizationWithRole } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";

type RealmAccessSelectorDialogListProps = {
  isLoading: boolean;
  currentOrganizationId: string;
  organizationRoles: UserOrganizationWithRole[];
  handleSelect: (orgId: string) => void;
};

const RealmAccessSelectorDialogList: React.FC<RealmAccessSelectorDialogListProps> = ({
  isLoading,
  currentOrganizationId,
  organizationRoles,
  handleSelect,
}) => {
  return (
    <List sx={{ padding: 0 }}>
      {Array.isArray(organizationRoles) &&
        organizationRoles.map((organizationRole, index) => (
          <Box key={organizationRole.id}>
            <ListItemButton
              onClick={(): void => handleSelect(organizationRole.id)}
              disabled={isLoading || organizationRole.id === currentOrganizationId}
              data-testid={`${TestIds.RealmAccessSelectorDialog.UserRealm}-${index}`}
            >
              <ListItemText primary={organizationRole.fullName} secondary={organizationRole.role} />
              <KeyboardArrowRightIcon color="secondary" />
            </ListItemButton>
            <Divider />
          </Box>
        ))}
    </List>
  );
};

export default RealmAccessSelectorDialogList;
