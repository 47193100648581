/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { eventIdentitiesMatch, eventOrdering, invertedEventOrdering } from "./Event";
import { EventSet } from "./EventSet";
import { EventsSubscriptionManager } from "./EventsSubscriptionManager";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { PatientsEventsListDocument } from "../../generated/gqlEvents";
import { AWSEvent } from "./AWSEvent";
export class AWSEventSet extends EventSet {
    constructor(patient, params) {
        super();
        this.changeQueryParams = (params) => {
            this.limit = params.limit;
            if (params.startTimestamp != null && params.endTimestamp != null) {
                this.period = { startTimestamp: params.startTimestamp, endTimestamp: params.endTimestamp };
            }
            else {
                // Backend requires both start and end timestamps
                this.period = undefined;
            }
            this.eventTypes = params.eventTypes;
            this.nextToken = undefined;
            this.events = [];
        };
        this.eventListener = {
            getId: () => this.getId(),
            onEvent: (event) => this.addOrUpdateEvent(event),
        };
        this.patient = patient;
        this.limit = params.limit;
        if (params.startTimestamp != null && params.endTimestamp != null) {
            this.period = { startTimestamp: params.startTimestamp, endTimestamp: params.endTimestamp };
        }
        this.eventTypes = params.eventTypes;
        this.events = [];
    }
    getQueryParams() {
        var _a, _b;
        return {
            limit: this.limit,
            startTimestamp: (_a = this.period) === null || _a === void 0 ? void 0 : _a.startTimestamp,
            endTimestamp: (_b = this.period) === null || _b === void 0 ? void 0 : _b.endTimestamp,
            eventTypes: this.eventTypes,
        };
    }
    async fetch(nextToken) {
        var _a, _b, _c, _d, _e, _f;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const patientsEventsResponse = await client.query(PatientsEventsListDocument, {
            patientId: this.patient.getId(),
            limit: this.limit,
            period: ((_a = this.period) === null || _a === void 0 ? void 0 : _a.startTimestamp) && ((_b = this.period) === null || _b === void 0 ? void 0 : _b.endTimestamp)
                ? {
                    endTimestamp: this.period.endTimestamp.toISOString(),
                    startTimestamp: this.period.startTimestamp.toISOString(),
                }
                : undefined,
            types: this.eventTypes,
            nextToken,
        });
        const newEvents = (_e = (_d = (_c = patientsEventsResponse.data.patientsEventsList) === null || _c === void 0 ? void 0 : _c.events) === null || _d === void 0 ? void 0 : _d.map((i) => new AWSEvent(i))) !== null && _e !== void 0 ? _e : [];
        this.events = this.events.concat(newEvents).sort(invertedEventOrdering);
        this.nextToken = (_f = patientsEventsResponse.data.patientsEventsList) === null || _f === void 0 ? void 0 : _f.nextToken;
    }
    getId() {
        return this.patient.getId();
    }
    getNextToken() {
        return this.nextToken;
    }
    getEvents() {
        return [...this.events];
    }
    addOrUpdateEvent(event) {
        // If the new event is not valid type or not in the given time period, ignore it
        if ((this.eventTypes && !this.eventTypes.includes(event.type)) ||
            (this.period && (event.timestamp < this.period.startTimestamp || event.timestamp > this.period.endTimestamp))) {
            return;
        }
        const matchIndex = this.events.findIndex((e) => eventIdentitiesMatch(event, e));
        if (matchIndex >= 0) {
            this.events[matchIndex] = event;
        }
        else {
            this.addEvent(event);
        }
        this.notifyAction((observer) => observer.onEventSetUpdate(this));
        this.notifyAction((observer) => observer.onEventListUpdate(this.events));
    }
    /**
     * Add observer for patient event updates
     *
     * @param observer
     */
    async addObserver(observer) {
        if (!this.events) {
            await this.fetch();
        }
        super.addObserver(observer);
        EventsSubscriptionManager.instance.addListener(this.eventListener, [this.patient.getId()]);
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        EventsSubscriptionManager.instance.removeListener(this.eventListener);
    }
    addEvent(event) {
        this.events.push(event);
        this.events.sort(eventOrdering);
    }
}
