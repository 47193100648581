/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";

type RemoveParameterConfirmationDialogProps = {
  open: boolean;
  parameterName?: string;
  onClose: () => void;
  onRemove: () => void;
};

const RemoveParameterConfirmationDialog: React.FC<RemoveParameterConfirmationDialogProps> = ({
  open,
  parameterName,
  onClose,
  onRemove,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.advancedSettingsDialog.texts.removeConfirmTitle()}
        <IconButton
          data-testid={TestIds.DeviceAdvancedSettingsDialog.ConfirmRemoveDialogClose}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.advancedSettingsDialog.texts.removeConfirmInfo({ parameterName: parameterName ?? "" }),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => onRemove()}
          color="error"
          data-testid={TestIds.DeviceAdvancedSettingsDialog.ConfirmRemoveDialogConfirm}
        >
          {translations.common.buttons.remove()}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          data-testid={TestIds.DeviceAdvancedSettingsDialog.ConfirmRemoveDialogCancel}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveParameterConfirmationDialog;
