/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Maybe, OtaManager, OtaPackageInfoParams, OtaVariant } from "@sade/data-access";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import FileUpload from "../../common/file-upload";
import CustomTextField from "../../common/textfield";

type CreateOtaPackageDialogProps = {
  open: boolean;
  onClose: () => void;
  onUploadComplete: () => void;
};

const CreateOtaPackageDialog: React.FC<CreateOtaPackageDialogProps> = ({ open, onClose, onUploadComplete }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const [release, setRelease] = React.useState<string>("");
  const [selectedDeviceType, setSelectedDeviceType] = React.useState<string>("");
  const [selectedVariant, setSelectedVariant] = React.useState<OtaVariant | "">("");
  const [otaName, setOtaName] = React.useState<string>("");
  const [selectedCategory, setSelectedCategory] = React.useState<string>("");
  const [selectedFile, setSelectedFile] = React.useState<Maybe<File>>(undefined);

  const handleReleaseInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRelease(event.target.value);
  };

  const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setOtaName(event.target.value);
  };

  const handleFileChange = (file?: Maybe<File>): void => {
    setSelectedFile(file);
  };

  const readAsBuffer = (file: File): Promise<Buffer> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (): void => {
        const fileBuffer = fileReader.result;

        if (fileBuffer instanceof ArrayBuffer) {
          resolve(Buffer.from(fileBuffer));
        } else {
          reject(new Error(translations.common.texts.failedToReadFileError()));
        }
      };

      fileReader.onerror = (): void => {
        reject(new Error(translations.common.texts.failedToReadFileError()));
      };

      fileReader.readAsArrayBuffer(file);
    });
  };

  const handleSubmitClick = async (): Promise<void> => {
    try {
      setIsLoading(true);

      if (!selectedFile) {
        throw new Error(translations.common.texts.noFileSelectedError());
      }

      const fileBuffer = await readAsBuffer(selectedFile);

      const otaPackageInfo: OtaPackageInfoParams = {
        category: selectedCategory,
        deviceType: selectedDeviceType,
        otaFilename: selectedFile.name,
        otaId: uuidv4(),
        otaPackageName: otaName,
        variant: selectedVariant as OtaVariant,
        version: release,
      };

      await OtaManager.getInstance().uploadOtaPackage(fileBuffer, otaPackageInfo);

      showSnackbar(translations.createNewOtaPackageDialog.texts.successText(), SeverityType.Success);
      onUploadComplete();
      onClose();
    } catch (error) {
      console.error(error);
      showSnackbar(translations.createNewOtaPackageDialog.texts.failText(), SeverityType.Error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled =
    isLoading || !release || !selectedDeviceType || !selectedVariant || !otaName || !selectedCategory || !selectedFile;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.CreateOtaPackageDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.createNewOtaPackageDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
            data-testid={TestIds.CreateOtaPackageDialog.CloseButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {translations.createNewOtaPackageDialog.texts.subtitle()}
          <Box sx={{ mt: 4 }}>
            <CustomTextField
              label={translations.otaPackages.texts.release()}
              id="release"
              value={release}
              testId={TestIds.CreateOtaPackageDialog.ReleaseInput}
              onChange={handleReleaseInputChange}
            />
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item xs={12} md={6}>
                <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                  <InputLabel id="device-type-select-label" shrink>
                    {translations.common.texts.deviceType()}
                  </InputLabel>
                  <Select
                    id="device-type-select"
                    size="small"
                    onChange={(e): void => setSelectedDeviceType(e.target.value)}
                    labelId="device-type-select-label"
                    value={selectedDeviceType}
                    data-testid={TestIds.CreateOtaPackageDialog.OpenDeviceTypeSelection}
                  >
                    <MenuItem value={"Libris 2"}>{"Libris 2"}</MenuItem>
                    <MenuItem value={"Libris 3"}>{"Libris 3"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                  <InputLabel id="variant-select-label" shrink>
                    {translations.common.texts.variant()}
                  </InputLabel>
                  <Select
                    id="variant-select"
                    size="small"
                    onChange={(e): void => setSelectedVariant(e.target.value as OtaVariant)}
                    labelId="variant-select-label"
                    value={selectedVariant}
                    data-testid={TestIds.CreateOtaPackageDialog.OpenVariantSelection}
                  >
                    <MenuItem value={OtaVariant.English}>{translations.common.texts.englishAudio()}</MenuItem>
                    <MenuItem value={OtaVariant.French}>{translations.common.texts.frenchAudio()}</MenuItem>
                    <MenuItem value={OtaVariant.Spanish}>{translations.common.texts.spanishAudio()}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <CustomTextField
              label={translations.common.texts.name()}
              id="otaName"
              value={otaName}
              testId={TestIds.CreateOtaPackageDialog.NameInput}
              onChange={handleNameInputChange}
            />
            <FormControl className="input" sx={{ textAlign: "start", mt: 3, mb: 3 }} fullWidth>
              <InputLabel id="category-select-label" shrink>
                {translations.createNewOtaPackageDialog.texts.otaCategory()}
              </InputLabel>
              <Select
                id="category-select"
                size="small"
                onChange={(e): void => setSelectedCategory(e.target.value)}
                labelId="category-select-label"
                value={selectedCategory}
                data-testid={TestIds.CreateOtaPackageDialog.OpenCategorySelection}
              >
                <MenuItem value={"Release"}>{translations.createNewOtaPackageDialog.texts.release()}</MenuItem>
                <MenuItem value={"Test"}>{translations.createNewOtaPackageDialog.texts.test()}</MenuItem>
              </Select>
            </FormControl>
            {translations.createNewOtaPackageDialog.texts.uploadThePackage()}
            <Box sx={{ mt: 2 }}>
              <FileUpload handleFileChange={handleFileChange} hasDropZone={false} showNoFileInfo={true} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2, mt: 0 }}>
          <Button
            variant="contained"
            onClick={handleSubmitClick}
            disabled={isSubmitDisabled}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
            data-testid={TestIds.CreateOtaPackageDialog.SubmitButton}
          >
            {translations.createNewOtaPackageDialog.buttons.addOtaPackage()}
          </Button>
          <Button variant="outlined" onClick={onClose} data-testid={TestIds.CreateOtaPackageDialog.ActionCancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateOtaPackageDialog;
