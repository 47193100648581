/* Copyright */
import * as React from "react";
import { Box, Grid, FormControl, Typography, InputLabel, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CognitoAccountStatus, Maybe, User } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { UserStatus, UserStatusNames } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";

type AccountDetailsBasicInformationProps = {
  accountDetails: Maybe<User>;
  userStatus: Maybe<UserStatus>;
};

const AccountDetailsBasicInformation: React.FC<AccountDetailsBasicInformationProps> = ({
  accountDetails,
  userStatus,
}) => {
  const theme = useTheme();

  const chipColor = (): string => {
    switch (userStatus?.name) {
      case UserStatusNames.ACTIVE:
      case UserStatusNames.EXTERNAL_PROVIDER:
        return theme.palette.success.main;
      case UserStatusNames.PENDING:
        return theme.palette.primary.main;
      case UserStatusNames.DISABLED:
        return theme.palette.error.main;
      default:
        return theme.palette.warning.main;
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth data-testid={TestIds.AccountDetailsDialog.FirstName}>
            <InputLabel shrink>{translations.common.texts.firstName()}</InputLabel>
            <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
              {accountDetails?.getDetails().firstName}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth data-testid={TestIds.AccountDetailsDialog.LastName}>
            <InputLabel shrink>{translations.common.texts.lastName()}</InputLabel>
            <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
              {accountDetails?.getDetails().lastName}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl fullWidth data-testid={TestIds.AccountDetailsDialog.Email}>
            <InputLabel shrink>{translations.common.texts.email()}</InputLabel>
            <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
              {accountDetails?.getEmail()}
            </Typography>
            {accountDetails?.getDetails().emailVerified &&
              accountDetails.getDetails().status !== CognitoAccountStatus.ForceChangePassword && (
                <Typography variant="caption" sx={{ ml: 0.5, color: theme.palette.success.main }}>
                  {translations.common.texts.verified()}
                </Typography>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl fullWidth data-testid={TestIds.AccountDetailsDialog.Status}>
            <InputLabel shrink>{translations.common.texts.accountStatus()}</InputLabel>
            <Chip
              label={userStatus?.name}
              sx={{ backgroundColor: chipColor, color: "white", maxWidth: "150px", mt: 1.5 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl fullWidth data-testid={TestIds.AccountDetailsDialog.Country}>
            <InputLabel shrink>{translations.common.texts.country()}</InputLabel>
            <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
              {accountDetails?.getDetails().country}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ flexGrow: 1 }} data-testid={TestIds.AccountDetailsDialog.PhoneNumber}>
            <InputLabel shrink>{translations.common.texts.phone()}</InputLabel>
            <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
              {accountDetails?.getDetails().phoneNumber}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountDetailsBasicInformation;
