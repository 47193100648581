/* Copyright */
import { Card, CardContent, CardHeader } from "@mui/material";
import { Maybe } from "@sade/data-access";
import React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { LocationData } from "../../../../utils/mapUtils";
import LocationHistoryTable from "./location-history-table";

type LocationHistoryCardProps = {
  locationsOnHistoryList: Maybe<LocationData[]>;
  handleSelectedLocationsToShowChange: (location: LocationData) => void;
};

const LocationHistoryCard: React.FC<LocationHistoryCardProps> = ({
  locationsOnHistoryList,
  handleSelectedLocationsToShowChange,
}) => {
  return (
    <React.Fragment>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.ActiveCalls.LocationHistoryCard}>
        <CardHeader
          title={translations.activeCalls.texts.locationHistory()}
          titleTypographyProps={{ variant: "h6" }}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <LocationHistoryTable
            locationsOnHistoryList={locationsOnHistoryList}
            handleSelectedLocationsToShowChange={handleSelectedLocationsToShowChange}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default LocationHistoryCard;
