/* Copyright */
import { FormControl, InputLabel, TextField, TextFieldProps } from "@mui/material";
import { Maybe } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../generated/translationHelper";

type CustomTextFieldWithValidationProps = {
  label?: string;
  id: string;
  value?: string;
  validationPattern: string;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
  onValueChange: (id: string, value: string, isError: boolean) => void;
} & TextFieldProps;

const CustomTextFieldWithValidation: React.FC<CustomTextFieldWithValidationProps> = ({
  label = "",
  id,
  value,
  validationPattern,
  fullWidth = true,
  required = false,
  placeholder,
  onValueChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState<string>(value ?? "");
  const [errorText, setErrorText] = React.useState<Maybe<string>>(undefined);

  React.useEffect(() => {
    if (required && !inputValue) {
      setErrorText("");
    }
  }, [required, inputValue]);

  React.useEffect(() => {
    onValueChange(id, inputValue, errorText !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, errorText]);

  const validateInput = (value: string): boolean => {
    const regex = new RegExp(validationPattern);
    return regex.test(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setInputValue(value);

    if (!validateInput(value)) {
      setErrorText(translations.common.texts.incorrectInputFormatError());
    } else {
      setErrorText(undefined);
    }
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel htmlFor={id} shrink disabled={rest.disabled}>
          {label} {required ? "*" : ""}
        </InputLabel>
      )}
      <TextField
        id={id}
        className="input"
        value={inputValue}
        onChange={handleInputChange}
        error={!!errorText}
        helperText={errorText}
        placeholder={placeholder}
        {...rest}
      ></TextField>
    </FormControl>
  );
};

export default CustomTextFieldWithValidation;
