/* Copyright */
import { RoleIdentifier } from "@sade/data-access";
import { useAuthenticatedUser } from "../context/authenticated-user-context";

/**
 * Check if the current authenticated user has the required roles.
 *
 * Can be used instead of the `useAccessControl` hook in places where the hook cannot be used (like inside conditionals).
 * @param requiredRoles - An array of required roles.
 * @param currentAuthenticatedUserRole - The role of the current authenticated user.
 * @returns A boolean indicating whether the current authenticated user has the required roles.
 */
export const isAuthorized = (requiredRoles: string[], currentAuthenticatedUserRole?: string): boolean => {
  requiredRoles.push(RoleIdentifier.SuperUser);
  return requiredRoles.includes(currentAuthenticatedUserRole ?? "");
};

export const useAccessControl = (...requiredRoles: string[]): boolean => {
  const { authenticatedUserRole } = useAuthenticatedUser();
  return isAuthorized(requiredRoles, authenticatedUserRole?.identifier);
};
