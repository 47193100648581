/* Copyright */
import { BackendFactory, Maybe, Team } from "@sade/data-access";
import * as React from "react";
import { translations } from "../generated/translationHelper";

type EverThereContextReturnType = {
  isLoadingEverThere: boolean;
  everThereData: Maybe<Team>;
  getEverThereData: (patientId: string) => void;
};

const EverThereContext = React.createContext<EverThereContextReturnType>({} as EverThereContextReturnType);
const { Provider } = EverThereContext;

export const EverThereContextProvider: React.FC = ({ children }) => {
  const [isLoadingEverThere, setIsLoadingEverThere] = React.useState<boolean>(false);
  const [everThereData, setEverThereData] = React.useState<Maybe<Team>>(undefined);

  const getEverThereData = React.useCallback((patientId: string) => {
    const getData = async (): Promise<void> => {
      setIsLoadingEverThere(true);
      try {
        // If for what ever reason patientId is not provided, we should set EverThereData to be undefined
        // to make sure that we don't show wrong EverThereData, for devices that do not have a patientId
        // for some reason.
        // NOTE: Above situation could happen if we fail to get device state, and we try to get EverThereData
        //       However this should not happen, as we should not be able to get to this point if we fail to
        //       get device state. Requires further investigation.
        setEverThereData(
          patientId?.length ? await BackendFactory.getOrganizationBackend().getPatientTeam(patientId) : undefined
        );
      } catch (error) {
        console.error(error);
        throw new Error(translations.common.texts.unableToPerformAction());
      } finally {
        setIsLoadingEverThere(false);
      }
    };

    getData();
  }, []);

  const value = React.useMemo(
    (): EverThereContextReturnType => ({
      isLoadingEverThere,
      everThereData,
      getEverThereData,
    }),
    [isLoadingEverThere, everThereData, getEverThereData]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useEverThereContext = (): EverThereContextReturnType => {
  const context = React.useContext(EverThereContext);
  if (context === undefined) {
    throw new Error("useEverThereContext must be used within a EverThereContextProvider");
  }

  return context;
};
