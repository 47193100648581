/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { isDefined } from "../../common";
import { UsersOrganizationsListDocument, UsersTeamsListDocument } from "../../generated/gqlUsers";
import { AuthWrapper } from "../auth";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
import { throwGQLError } from "../private-utils/throwGQLError";
import { AWSOrganization } from "./AWSOrganization";
import { AWSTeam } from "./AWSTeam";
import { verifyOrganizationType } from "./AWSTypeUtils";
import { Organization } from "./Organization";
import { RankingOrder, Role, RoleIdentifier } from "./Role";
import { User } from "./User";
const PERMISSION_ASSIGN_ROLE = "organizationsRolesAssign";
export class AWSUser extends User {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSUser;
        this.organizationsSemaphore = new PromiseSemaphore(() => this.backendFetchOrganizations(this));
        this.teamsSemaphore = new PromiseSemaphore(() => this.backendFetchTeams(this));
        /**
         * Saves {@link backendFetchUserRoles} results.
         * @private
         */
        this.roleCache = new Map();
        /**
         * Roles user has active in {@link cacheRootId} via role inheritance
         * @private
         */
        this.inheritedRoles = [];
    }
    static instanceOf(value) {
        return value instanceof AWSUser;
    }
    async getHomeOrganization() {
        if (!this.homeOrganization) {
            this.homeOrganization = await this.backendGetHomeOrganization();
        }
        return this.homeOrganization;
    }
    async getOrganizations() {
        await this.organizationsSemaphore.guard();
        return this.backend.entityRelationCache.listFor(this, AWSOrganization);
    }
    async hasPermissions(organizationId, ...permissions) {
        const roles = await this.getOrganizationRoles(organizationId);
        return Role.rolesHavePermissions(roles, permissions);
    }
    async hasRole(roleId, organizationId) {
        const roles = await this.getOrganizationRoles(organizationId !== null && organizationId !== void 0 ? organizationId : (await this.getHomeOrganization()).getId());
        return Boolean(roles.find((role) => role.identifier === roleId));
    }
    async assignOrganizationRoles(organizationId, roles) {
        await this.backend.assignUserOrganizationRoles(this.id, organizationId, roles.map((role) => role.identifier));
        this.roleCache.set(organizationId, roles);
        this.notifyRoleChange();
    }
    async addToOrganization(organization, roles) {
        await this.backend.addUserToOrganization(organization, this, roles.map((role) => role.identifier));
        this.roleCache.set(organization.getId(), roles);
        this.notifyRoleChange();
    }
    async removeFromOrganization(organization) {
        await this.backend.removeUserFromOrganization(organization, this);
        this.roleCache.delete(organization.getId());
        this.notifyRoleChange();
    }
    async getOrganizationRoles(organizationId) {
        var _a;
        if (!this.roleCache.has(organizationId)) {
            await this.backendFetchUserRoles(organizationId);
        }
        return (_a = this.roleCache.get(organizationId)) !== null && _a !== void 0 ? _a : [];
    }
    async delete() {
        await this.backend.deleteUser(this.id);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        this.clearObservers();
    }
    async setAccountStatus(enabled) {
        await this.backend.setUserAccountStatus(this.id, enabled);
        this.details.enabled = enabled;
    }
    async onRelationChange(change) {
        if (change.ofType(AWSOrganization) && this.organizationsSemaphore.invoked()) {
            const organizations = this.backend.entityRelationCache.listFor(this, AWSOrganization);
            this.notifyAction((observer) => { var _a; return (_a = observer.onOrganizationsChange) === null || _a === void 0 ? void 0 : _a.call(observer, organizations, this); });
        }
    }
    async hasSuperiorRolesToAnother(organizationId, other) {
        const [myRoles, theirRoles] = await Promise.all([
            this.getOrganizationRoles(organizationId),
            other.getOrganizationRoles(organizationId),
        ]);
        return myRoles.some((myRole) => theirRoles.every((theirRole) => myRole.getRankingOrder(theirRole) === RankingOrder.Superior));
    }
    async canAssignRole(organizationId, role, organizationRoles) {
        // End user roles cannot be assigned, they can only be invited to ET teams.
        if (role.identifier === RoleIdentifier.EndUser)
            return false;
        const roles = organizationRoles !== null && organizationRoles !== void 0 ? organizationRoles : (await this.getOrganizationRoles(organizationId));
        if (!Role.rolesHavePermissions(roles, [PERMISSION_ASSIGN_ROLE]))
            return false;
        return roles.some((myRole) => [RankingOrder.Superior, RankingOrder.Equal, RankingOrder.Sibling].includes(myRole.getRankingOrder(role)));
    }
    async getRolesUserCanSee(organizationId) {
        const userRoles = await this.getUserRoles(organizationId);
        const allRoles = await this.backend.listRoles();
        return allRoles
            .map((role) => {
            // If the role is superior to all the user's roles, then the user cannot see it.
            if (userRoles.every((userRole) => role.getRankingOrder(userRole) === RankingOrder.Superior))
                return null;
            return role;
        })
            .filter(isDefined);
    }
    async getRolesUserCanAssign(organizationId) {
        const userRoles = await this.getUserRoles(organizationId);
        if (!Role.rolesHavePermissions(userRoles, [PERMISSION_ASSIGN_ROLE]))
            return [];
        const rolesUserCanSee = await this.getRolesUserCanSee(organizationId);
        const filteredRoles = [];
        for (const roleToAssign of rolesUserCanSee) {
            if (await this.canAssignRole(organizationId, roleToAssign, userRoles))
                filteredRoles.push(roleToAssign);
        }
        return filteredRoles;
    }
    async changeActiveOrganization(organizationId) {
        // Change active organization
        await AuthWrapper.setActiveOrganization(organizationId);
        await AuthWrapper.refreshAuthentication();
        // Get the new organization
        // TODO: Web UI nowadays clears all caches when changing active organization, so this is not needed.
        // TODO: Instead we could pass the organization id to the client.
        const organization = await this.backend.getOrganization(organizationId);
        if (!organization)
            throw new Error("Failed to retrieve organization");
        // Notify observers
        this.notifyAction((observer) => { var _a; return (_a = observer.onActiveOrganizationChange) === null || _a === void 0 ? void 0 : _a.call(observer, organization, this); });
    }
    async getTeams() {
        await this.teamsSemaphore.guard();
        return this.backend.entityRelationCache.listFor(this, AWSTeam);
    }
    async backendFetchOrganizations(user) {
        const organizations = await this.getUsersOrganizations(user.getId());
        organizations.forEach((org) => this.backend.entityRelationCache.link(user, org));
    }
    async getUsersOrganizations(userId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(UsersOrganizationsListDocument, { userId });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersOrganizationsList)) {
            throwGQLError(response, "Failed to retrieve user's organizations");
        }
        return response.data.usersOrganizationsList.organizations.map((org) => new AWSOrganization(this.backend, org));
    }
    async backendGetHomeOrganization() {
        const organization = await this.backend.getOrganization(this.homeOrganizationId);
        if (!organization) {
            throw new Error(`Home organization of user '${this.id}' does not exist`);
        }
        verifyOrganizationType(organization);
        return organization;
    }
    async backendFetchUserRoles(organizationId) {
        const currentUser = await this.backend.getCurrentUser();
        if (!currentUser)
            console.error("No current user");
        const result = await this.backend.getUserRoles(this.id, organizationId);
        result.roleTree.forEach((value, key) => this.roleCache.set(key, value));
        this.inheritedRoles = result.inheritedRoles;
        this.notifyRoleChange();
    }
    async backendFetchTeams(user) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(UsersTeamsListDocument, { userId: user.getId() });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersTeamsList)) {
            throwGQLError(response, "Failed to retrieve user's teams");
        }
        const teams = response.data.usersTeamsList.teams.map((team) => new AWSTeam(this.backend, {
            id: team.id,
            name: team.name,
            devices: team.devices,
            members: team.teamMembers,
        }));
        teams.forEach((team) => this.backend.entityRelationCache.link(user, team));
    }
    /**
     * Retrieves user's roles that are effective within the context of the given organization.
     * if no organization is given, user's home organization is used.
     *
     * "Effective" means that a given role is in effect in the organization; either it has been granted for the specific
     * organization or the user inherits it from a parent organization.
     *
     * @param organizationId
     * @returns list of effective, unique {@link Role roles}
     */
    async getEffectiveRoles(organizationId) {
        const organization = organizationId !== null && organizationId !== void 0 ? organizationId : this.homeOrganizationId;
        if (!this.roleCache.has(organization)) {
            await this.backendFetchUserRoles(organization);
        }
        const effectiveRoles = [...this.roleCache.entries()]
            .filter(([id]) => Organization.isParentOrEqualOrganizationId(organization, id))
            .flatMap(([_, roles]) => roles);
        this.inheritedRoles.forEach((role) => effectiveRoles.push(role));
        const roleMap = new Map(effectiveRoles.map((role) => [role.identifier, role]));
        return [...roleMap.values()];
    }
    /**
     * Retrieve the roles assigned to a user for a specific organization.
     * If the organization is the user's active organization, it returns the roles assigned directly to the user.
     * If the organization is not the user's active organization, it returns the roles inherited from parent organizations.
     *
     * @param organizationId - The ID of the organization.
     * @returns A promise that resolves to an array of Role objects representing the user's roles for the organization.
     */
    async getUserRoles(organizationId) {
        let userRoles = [];
        const activeOrg = await AuthWrapper.getActiveOrganizationId();
        if (activeOrg === organizationId) {
            // This is user's active organization, use the roles that the user has on this organization.
            userRoles = await this.getOrganizationRoles(organizationId);
        }
        else {
            // This is not user's active organization, use the roles that the user inherits from parent organizations.
            userRoles = await this.getEffectiveRoles(organizationId);
        }
        return userRoles;
    }
    notifyRoleChange() {
        const newRolesRecord = [...this.roleCache.entries()].reduce((record, entry) => {
            record[entry[0]] = entry[1];
            return record;
        }, {});
        this.notifyAction((observer) => { var _a; return (_a = observer.onRolesChange) === null || _a === void 0 ? void 0 : _a.call(observer, newRolesRecord, this); });
    }
}
