/* Copyright */
import React from "react";
import { Box, Typography } from "@mui/material";
import { translations } from "../../generated/translationHelper";

const AccessDenied: React.FC = () => {
  return (
    <Box sx={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
      <Typography variant="h4" color="primary">
        {translations.common.texts.accessDenied()}
      </Typography>
    </Box>
  );
};

export default AccessDenied;
