/* Copyright */
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import {
  DeviceState,
  Organization,
  OrganizationParameterHierarchy,
  ParameterValueType,
  RoleIdentifier,
} from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { getParameterOriginator, isFeatureEnabled } from "../../../utils/parameterUtils";
import { DeviceSettings, OrganizationInfo, ParameterNames, convertMinutesToHours } from "../../../utils/utils";
import DeviceSettingsParameterOriginator from "./device-settings-parameter-originator";

type DeviceSettingsContentPropsLeftGridProps = {
  settings: DeviceSettings;
  deviceState?: DeviceState;
  parameterHierarchy?: OrganizationParameterHierarchy;
  organizationNameMap: OrganizationInfo[];
  deviceOrganization?: Organization;
  onSettingChanged: (param: ParameterNames, value: ParameterValueType) => void;
  onResetSetting?: (param: ParameterNames, value: ParameterValueType) => void;
};

const DeviceSettingsContentLeftGrid: React.FC<DeviceSettingsContentPropsLeftGridProps> = ({
  settings,
  deviceState,
  parameterHierarchy,
  organizationNameMap,
  deviceOrganization,
  onSettingChanged,
  onResetSetting,
}) => {
  const isAuthorized = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.WarehouseInstaller,
    RoleIdentifier.DealerManager
  );

  const renderParameterOriginator = (parameterName: ParameterNames): React.ReactNode => {
    const origin = getParameterOriginator(parameterName, parameterHierarchy, organizationNameMap);
    return (
      <DeviceSettingsParameterOriginator
        parameterOrigin={origin}
        isLocked={deviceOrganization?.isParameterLocked(origin.parameterName, parameterHierarchy ?? {}) ?? false}
        isOverridden={deviceState?.isParameterOverridden(origin.parameterName) ?? false}
        resetDisabled={!isAuthorized}
        onReset={(paramValue: ParameterValueType): void => onResetSetting?.(parameterName, paramValue)}
      />
    );
  };

  return (
    <Grid item xs={12} md={6} sx={{ paddingLeft: 0 }}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          {isFeatureEnabled(ParameterNames.SILENT_MODE_ENABLED, parameterHierarchy) && (
            <TableRow sx={{ height: 90 }}>
              <TableCell sx={{ width: "70%" }}>
                <Tooltip title={translations.deviceSettingsDialog.texts.fallDetectionTooltip()}>
                  {isAuthorized ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={ParameterNames.FALL_DETECTION_ENABLED}
                          checked={settings.fallDetectionEnabled}
                          onChange={(_event, checked): void =>
                            onSettingChanged(ParameterNames.FALL_DETECTION_ENABLED, checked)
                          }
                          data-testid={TestIds.DeviceSettingsDialog.FallDetection}
                          disabled={deviceOrganization?.isParameterLocked(
                            ParameterNames.FALL_DETECTION_ENABLED,
                            parameterHierarchy ?? {}
                          )}
                        />
                      }
                      label={`${translations.common.texts.enable()} ${translations.deviceSettings.texts.fallDetection()}`}
                      labelPlacement="end"
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel shrink>{translations.deviceSettings.texts.fallDetection()}</InputLabel>
                      <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                        {settings.fallDetectionEnabled
                          ? translations.common.texts.enabled()
                          : translations.common.texts.disabled()}
                      </Typography>
                    </FormControl>
                  )}
                </Tooltip>
              </TableCell>
              <TableCell>{renderParameterOriginator(ParameterNames.FALL_DETECTION_ENABLED)}</TableCell>
            </TableRow>
          )}
          {isFeatureEnabled(ParameterNames.SILENT_MODE_ENABLED, parameterHierarchy) && (
            <TableRow sx={{ height: 90 }}>
              <TableCell sx={{ width: "70%" }}>
                <Tooltip title={translations.deviceSettingsDialog.texts.silentModeTooltip()}>
                  {isAuthorized ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={ParameterNames.SILENT_MODE_ENABLED}
                          checked={settings.silentModeEnabled}
                          onChange={(_event, checked): void =>
                            onSettingChanged(ParameterNames.SILENT_MODE_ENABLED, checked)
                          }
                          data-testid={TestIds.DeviceSettingsDialog.SilentMode}
                          disabled={deviceOrganization?.isParameterLocked(
                            ParameterNames.SILENT_MODE_ENABLED,
                            parameterHierarchy ?? {}
                          )}
                        />
                      }
                      label={`${translations.common.texts.enable()} ${translations.deviceSettings.texts.silentMode()}`}
                      labelPlacement="end"
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel shrink>{translations.deviceSettings.texts.silentMode()}</InputLabel>
                      <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                        {settings.silentModeEnabled ? translations.common.texts.on() : translations.common.texts.off()}
                      </Typography>
                    </FormControl>
                  )}
                </Tooltip>
              </TableCell>
              <TableCell>{renderParameterOriginator(ParameterNames.SILENT_MODE_ENABLED)}</TableCell>
            </TableRow>
          )}
          {isFeatureEnabled(ParameterNames.DEMO_MODE_ENABLED, parameterHierarchy) && (
            <TableRow sx={{ height: 90 }}>
              <TableCell sx={{ width: "70%" }}>
                <Tooltip title={translations.deviceSettingsDialog.texts.demoModeTooltip()}>
                  {isAuthorized ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={ParameterNames.DEMO_MODE_ENABLED}
                          checked={settings.demoModeEnabled}
                          onChange={(_event, checked): void =>
                            onSettingChanged(ParameterNames.DEMO_MODE_ENABLED, checked)
                          }
                          data-testid={TestIds.DeviceSettingsDialog.DemoMode}
                          disabled={deviceOrganization?.isParameterLocked(
                            ParameterNames.DEMO_MODE_ENABLED,
                            parameterHierarchy ?? {}
                          )}
                        />
                      }
                      label={`${translations.common.texts.enable()} ${translations.deviceSettings.texts.demoMode()}`}
                      labelPlacement="end"
                    />
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel shrink>{translations.deviceSettings.texts.demoMode()}</InputLabel>
                      <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                        {settings.demoModeEnabled ? translations.common.texts.on() : translations.common.texts.off()}
                      </Typography>
                    </FormControl>
                  )}
                </Tooltip>
              </TableCell>
              <TableCell>{renderParameterOriginator(ParameterNames.DEMO_MODE_ENABLED)}</TableCell>
            </TableRow>
          )}
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.tapIndicatorTooltip()}>
                {isAuthorized ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ParameterNames.TAP_INDICATOR_ENABLED}
                        checked={settings.tapIndicatorEnabled}
                        onChange={(_event, checked): void =>
                          onSettingChanged(ParameterNames.TAP_INDICATOR_ENABLED, checked)
                        }
                        data-testid={TestIds.DeviceSettingsDialog.TapIndicator}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.TAP_INDICATOR_ENABLED,
                          parameterHierarchy ?? {}
                        )}
                      />
                    }
                    label={`${translations.common.texts.enable()} ${translations.deviceSettings.texts.tapIndicator()}`}
                    labelPlacement="end"
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.tapIndicator()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.tapIndicatorEnabled ? translations.common.texts.on() : translations.common.texts.off()}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.TAP_INDICATOR_ENABLED)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.inactivityNotificationsTooltip()}>
                {isAuthorized ? (
                  <FormControlLabel
                    value={settings.inactiveMonitorEnabled}
                    control={
                      <Checkbox
                        name={ParameterNames.INACTIVE_MONITOR_ENABLED}
                        checked={settings.inactiveMonitorEnabled}
                        onChange={(_event, checked): void =>
                          onSettingChanged(ParameterNames.INACTIVE_MONITOR_ENABLED, checked)
                        }
                        data-testid={TestIds.DeviceSettingsDialog.InactivityNotifications}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.INACTIVE_MONITOR_ENABLED,
                          parameterHierarchy ?? {}
                        )}
                      />
                    }
                    label={`${translations.common.texts.enable()} ${translations.deviceSettings.texts.inactivityNotifications()}`}
                    labelPlacement="end"
                  />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.inactivityNotifications()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {settings.inactiveMonitorEnabled
                        ? translations.common.texts.enabled()
                        : translations.common.texts.disabled()}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.INACTIVE_MONITOR_ENABLED)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.inactiveInChargerTooltip()} placement="right">
                {isAuthorized ? (
                  <Box>
                    <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                      <InputLabel id="inactiveChargerTimeMinutes-label" shrink>
                        {translations.deviceSettings.texts.inactiveInCharger()}
                      </InputLabel>
                      <Select
                        name={ParameterNames.INACTIVE_CHARGER_MINUTES}
                        size="small"
                        labelId="inactiveChargerTimeMinutes-label"
                        value={settings.inactiveChargerTimeMinutes}
                        onChange={(event): void =>
                          onSettingChanged(ParameterNames.INACTIVE_CHARGER_MINUTES, event.target.value)
                        }
                        data-testid={TestIds.DeviceSettingsDialog.InactiveInCharger}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.INACTIVE_CHARGER_MINUTES,
                          parameterHierarchy ?? {}
                        )}
                      >
                        <MenuItem value="0" data-testid={TestIds.InactiveInChargerMenu.Off}>
                          {translations.common.texts.off()}
                        </MenuItem>
                        <MenuItem
                          value="720"
                          data-testid={TestIds.InactiveInChargerMenu.TwelveHours}
                        >{`${12} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem
                          value="1440"
                          data-testid={TestIds.InactiveInChargerMenu.TwentyFourHours}
                        >{`${24} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem
                          value="2880"
                          data-testid={TestIds.InactiveInChargerMenu.FortyEightHours}
                        >{`${48} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem
                          value="4320"
                          data-testid={TestIds.InactiveInChargerMenu.SeventyTwoHours}
                        >{`${72} ${translations.common.texts.hours()}`}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.inactiveInCharger()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {`${convertMinutesToHours(
                        settings.inactiveChargerTimeMinutes ?? 0
                      )} ${translations.common.texts.hours()}`}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.INACTIVE_CHARGER_MINUTES)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip title={translations.deviceSettingsDialog.texts.inactiveOutOfChargerTooltip()} placement="right">
                <Box>
                  <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                    <InputLabel id="inactiveMotionTimeMinutes-label" shrink>
                      {translations.deviceSettings.texts.inactiveOutOfCharger()}
                    </InputLabel>
                    {isAuthorized ? (
                      <Select
                        name={ParameterNames.INACTIVE_MOTION_MINUTES}
                        size="small"
                        labelId="inactiveMotionTimeMinutes-label"
                        value={settings.inactiveMotionTimeMinutes}
                        onChange={(event): void =>
                          onSettingChanged(ParameterNames.INACTIVE_MOTION_MINUTES, event.target.value)
                        }
                        data-testid={TestIds.DeviceSettingsDialog.InactiveOutOfCharger}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.INACTIVE_MOTION_MINUTES,
                          parameterHierarchy ?? {}
                        )}
                      >
                        <MenuItem value="0">{translations.common.texts.off()}</MenuItem>
                        <MenuItem value="720">{`${12} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem value="1440">{`${24} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem value="2880">{`${48} ${translations.common.texts.hours()}`}</MenuItem>
                        <MenuItem value="4320">{`${72} ${translations.common.texts.hours()}`}</MenuItem>
                      </Select>
                    ) : (
                      <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                        {`${convertMinutesToHours(
                          settings.inactiveMotionTimeMinutes ?? 0
                        )} ${translations.common.texts.hours()}`}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.INACTIVE_MOTION_MINUTES)}</TableCell>
          </TableRow>
          <TableRow sx={{ height: 90 }}>
            <TableCell sx={{ width: "70%" }}>
              <Tooltip
                title={translations.deviceSettingsDialog.texts.periodicIndicatorFeedbackTooltip()}
                placement="right"
              >
                {isAuthorized ? (
                  <Box>
                    <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
                      <InputLabel id="powerHeartBeatInterval-label" shrink>
                        {translations.deviceSettings.texts.periodicIndicatorFeedback()}
                      </InputLabel>
                      <Select
                        name={ParameterNames.PERIODIC_INDICATOR_FEEDBACK}
                        size="small"
                        labelId="powerHeartBeatInterval-label"
                        value={settings.powerHeartBeatInterval}
                        onChange={(event): void =>
                          onSettingChanged(ParameterNames.PERIODIC_INDICATOR_FEEDBACK, event.target.value)
                        }
                        data-testid={TestIds.DeviceSettingsDialog.PeriodicIndicatorFeedback}
                        disabled={deviceOrganization?.isParameterLocked(
                          ParameterNames.PERIODIC_INDICATOR_FEEDBACK,
                          parameterHierarchy ?? {}
                        )}
                      >
                        <MenuItem value="0">{translations.common.texts.off()}</MenuItem>
                        <MenuItem value="30">{`${30} ${translations.common.texts.seconds()}`}</MenuItem>
                        <MenuItem value="60">{`${1} ${translations.common.texts.minute()}`}</MenuItem>
                        <MenuItem value="300">{`${5} ${translations.common.texts.minutes()}`}</MenuItem>
                        <MenuItem value="600">{`${10} ${translations.common.texts.minutes()}`}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel shrink>{translations.deviceSettings.texts.periodicIndicatorFeedback()}</InputLabel>
                    <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                      {""}
                    </Typography>
                  </FormControl>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{renderParameterOriginator(ParameterNames.PERIODIC_INDICATOR_FEEDBACK)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default DeviceSettingsContentLeftGrid;
