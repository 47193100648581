/* Copyright */
import { Box } from "@mui/material";
import {
  BackendFactory,
  Maybe,
  Role,
  RoleIdentifier,
  UserInformation,
  UserSearchField,
  UserSearchParameters,
  UserSearchResult,
  UserType,
} from "@sade/data-access";
import React, { useCallback } from "react";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { AccountFilters, OrganizationInfo, getChildOrganizations } from "../../utils/utils";
import AccessControl from "../access-control/access-control";
import AccountsTable from "./accounts-table/accounts-table";
import AccountsViewFilterBar from "./accounts-view-filter-bar/accounts-view-filter-bar";
import { SearchCriteria } from "./accounts-view-filter-bar/accounts-view-search";

const pageSize = 20;

const initialSearchParams: UserSearchParameters = {
  searchField: undefined,
  searchQuery: undefined,
  statusFilter: [],
  realmFilter: [],
  roleFilter: [],
  targetResultCount: pageSize,
  includeDisabledUsers: true,
  includeUserTypes: [UserType.WebApp],
};

const initialFilters: AccountFilters = {
  statuses: [],
  realms: [],
  roles: [],
  includeDisabled: true,
  userType: UserType.WebApp,
};

const AccountsView: React.FC = () => {
  const tableEl = React.useRef<HTMLDivElement>(null);
  const backend = BackendFactory.getOrganizationBackend();
  const { currentOrganization, userInfo } = useAuthenticatedUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [childOrganizations, setChildOrganizations] = React.useState<Maybe<OrganizationInfo[]>>();
  const [allRoles, setAllRoles] = React.useState<Role[]>([]);
  const [userList, setUserList] = React.useState<UserInformation[]>([]);
  const [organizationInfo, setOrganizationInfo] = React.useState<OrganizationInfo[]>([]);
  const [fetched, setFetched] = React.useState(false);
  const nextToken = React.useRef<string | null>(null);
  const { showSnackbar } = useSnackbar();
  const [searchCriteria, setSearchCriteria] = React.useState<SearchCriteria>({
    searchBy: UserSearchField.Email,
    searchString: "",
  });
  const [filters, setFilters] = React.useState<AccountFilters>(initialFilters);
  const [parameters, setParameters] = React.useState<UserSearchParameters>(initialSearchParams);
  const [error, setError] = React.useState(false);
  const [everThere, setEverThere] = React.useState(false);

  React.useEffect(() => {
    const initializeFilters = async (): Promise<void> => {
      const organizationId = currentOrganization?.getId();
      if (!organizationId) return;

      const childOrganizationList = await getChildOrganizations(organizationId);
      setChildOrganizations(childOrganizationList);

      if (!userInfo) return;

      const roles = await userInfo.getRolesUserCanSee(organizationId);
      setAllRoles(roles?.filter((role) => role.identifier !== RoleIdentifier.EndUser));
    };
    initializeFilters();
  }, [currentOrganization, userInfo]);

  React.useEffect(() => {
    const indexOfSearchBy = searchCriteria.searchBy ? Object.keys(UserSearchField).indexOf(searchCriteria.searchBy) : 0;
    const params: UserSearchParameters = {
      searchField:
        searchCriteria.searchBy && searchCriteria.searchString
          ? Object.values(UserSearchField)[indexOfSearchBy]
          : undefined,
      searchQuery: searchCriteria.searchBy && searchCriteria.searchString ? searchCriteria.searchString : undefined,
      statusFilter: filters.statuses,
      realmFilter: filters.realms,
      roleFilter: filters.roles,
      targetResultCount: pageSize,
      includeUserTypes: [filters.userType],
      includeDisabledUsers: filters.includeDisabled,
    };
    setParameters(params);
    setEverThere(params.includeUserTypes.includes(UserType.EverThere));
    nextToken.current = null;
  }, [filters, searchCriteria]);

  const getUserData = React.useCallback(() => {
    const fetchData = async (): Promise<UserSearchResult | undefined> => {
      setIsLoading(true);
      setFetched(false);
      setError(false);
      try {
        if (currentOrganization) {
          const result = await currentOrganization.searchUsersFromThisAndChildOrganizations(
            parameters,
            nextToken.current ? nextToken.current : undefined
          );

          return result;
        }
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
        setFetched(true);
      }
    };

    return fetchData();
  }, [currentOrganization, parameters]);

  React.useEffect(() => {
    const getData = async (): Promise<void> => {
      const response = await getUserData();
      if (response) {
        setUserList(response.users);
        nextToken.current = response.nextToken ?? null;
      }
    };

    if (!nextToken.current) getData();
  }, [getUserData]);

  React.useEffect(() => {
    if (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    }
  }, [error, showSnackbar]);

  React.useEffect(() => {
    const fetchOrganizationData = async (organizationId: string): Promise<Maybe<OrganizationInfo>> => {
      const organizationData = await backend.getOrganization(organizationId);
      if (organizationData) {
        const data = { id: organizationData.getId(), fullName: organizationData.getFullName() };
        return data;
      }
    };

    if (userList) {
      const uniqueOrganizationIds = [
        ...new Set<string>(
          // userList is filtered because ORG/UNASSIGNED will cause an error and we don't need to do the following steps for EverThere users.
          userList.filter((user) => user.roleIdentifier !== RoleIdentifier.EndUser).map((user) => user.organizationId)
        ),
      ];
      const updatedOrganizations = [...organizationInfo];
      uniqueOrganizationIds.forEach((id) => {
        if (!organizationInfo.some((org) => org.id === id)) {
          fetchOrganizationData(id).then((data) => {
            if (data) {
              updatedOrganizations.push({ id: data?.id, fullName: data?.fullName });
              setOrganizationInfo(updatedOrganizations);
            }
          });
        }
      });
    }
  }, [userList, organizationInfo, backend]);

  const loadMore = React.useCallback(() => {
    const getData = async (): Promise<void> => {
      const response = await getUserData();
      if (response) {
        setUserList((oldState) => [...oldState, ...response.users]);
        nextToken.current = response.nextToken ?? null;
      }
    };

    if (nextToken.current) getData();
  }, [getUserData]);

  const scrollListener = React.useCallback(() => {
    const element = tableEl.current;
    if (element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
        loadMore();
      }
    }
  }, [isLoading, loadMore]);

  React.useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef?.addEventListener("scroll", scrollListener);
    return (): void => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  const handleSearchCriteriaChange = (updatedSearchCriteria: SearchCriteria): void => {
    if (updatedSearchCriteria.searchString) {
      setSearchCriteria(updatedSearchCriteria);
    } else {
      const prevCriterias = { ...searchCriteria };
      if (prevCriterias.searchString) {
        setSearchCriteria(updatedSearchCriteria);
      }
    }
  };

  const handleSearchClick = async (): Promise<void> => {
    const response = await getUserData();
    if (response) {
      setUserList(response.users);
      nextToken.current = response.nextToken ?? null;
    }
  };

  const handleFilterChange = useCallback(
    (data: Partial<AccountFilters>): void => {
      if (
        Object.keys(data).some((key) => {
          return (
            data[key as keyof AccountFilters] !== undefined &&
            data[key as keyof AccountFilters] !== filters[key as keyof AccountFilters]
          );
        })
      ) {
        //reset filters and users if userType changes
        if (data.userType !== filters.userType && data.userType !== undefined) {
          setFetched(false);
          setFilters({ ...initialFilters, userType: data.userType });
          setSearchCriteria({
            searchBy: UserSearchField.Email,
            searchString: "",
          });
          setUserList([]);
        } else {
          setFilters({ ...filters, ...data });
        }
      }
    },
    [filters]
  );

  const handleReFetchData = async (): Promise<void> => {
    nextToken.current = null;
    handleSearchClick();
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <AccessControl
        roles={[
          RoleIdentifier.PowerUser,
          RoleIdentifier.Supervisor,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
        ]}
      >
        <AccountsViewFilterBar
          isLoading={isLoading}
          childOrganizations={childOrganizations}
          allRoles={allRoles}
          everThere={everThere}
          searchCriteria={searchCriteria}
          filters={filters}
          handleSearchCriteriaChange={handleSearchCriteriaChange}
          handleSearchClick={handleSearchClick}
          handleFilterChange={handleFilterChange}
        />
        <AccountsTable
          users={userList}
          allRoles={allRoles}
          organizationInfo={organizationInfo}
          fetched={fetched}
          tableRef={tableEl}
          isLoading={isLoading}
          everThere={everThere}
          onReFetchUserData={handleReFetchData}
        />
      </AccessControl>
    </Box>
  );
};

export default AccountsView;
