/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TestIds from "../../../../test-ids/test-ids";
import theme from "../../../../app/theme";
import { translations } from "../../../../generated/translationHelper";
import OtaMonitorTable from "./ota-monitor-table";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SeverityType } from "../../../../context/snackbar-context";

type DeviceActionDialogProps = {
  open: boolean;
  deviceId: string;
  handleClose: () => void;
};

const DeviceOtaMonitorDialog: React.FC<DeviceActionDialogProps> = ({ open, deviceId, handleClose }) => {
  const { showSnackbar } = useSnackbar();

  const handleCloseClick = (): void => {
    handleClose();
  };

  const handleError = (error: Error): void => {
    let errorCode = "";
    if (error instanceof Error) {
      errorCode = error.message;
    }
    showSnackbar(
      translations.otaMonitorDialog.texts.failurePopup(),
      SeverityType.Error,
      undefined,
      undefined,
      translations.otaMonitorDialog.texts.failureDescription({ errorCode })
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.otaMonitorDialog.texts.title({ deviceId })}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.DeviceOtaMonitorDialog.CloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <OtaMonitorTable deviceId={deviceId} onError={handleError} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="outlined"
          onClick={handleCloseClick}
          sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
          data-testid={TestIds.DeviceOtaMonitorDialog.CloseButton}
        >
          {translations.common.buttons.close()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceOtaMonitorDialog;
