/* Copyright */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TeamMember } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../../../generated/translationHelper";
import TestIds from "../../../../../test-ids/test-ids";
import EverThereAccountsDialogContentDataRow from "./everthere-accounts-dialog-content-data-row";

type EverThereAccountsDialogContentProps = {
  deviceId: string;
  members: TeamMember[];
  handleDeleteMember: (member: TeamMember) => void;
  handleOpenTeamManagerDialog: (member: TeamMember) => void;
  handleOpenRemoveDeviceDialog: (member: TeamMember) => void;
  handleOpenMakeMemberAWearerDialog: (member: TeamMember) => void;
};

const EverThereAccountsDialogContent: React.FC<EverThereAccountsDialogContentProps> = ({
  deviceId,
  members,
  handleOpenTeamManagerDialog,
  handleDeleteMember,
  handleOpenRemoveDeviceDialog,
  handleOpenMakeMemberAWearerDialog,
}) => {
  const getEmptyRow = (): React.ReactNode => {
    return (
      <TableRow data-testid={TestIds.DeviceEverThereAccountsDialog.EmptyRow}>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.DeviceEverThereAccountsDialog.MembersTable}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.common.texts.user()}</TableCell>
              <TableCell>{translations.common.texts.role()}</TableCell>
              <TableCell>{translations.common.texts.wearer()}</TableCell>
              <TableCell>{translations.common.texts.accountStatus()}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.length
              ? members.map((member, index) => (
                  <EverThereAccountsDialogContentDataRow
                    key={index}
                    deviceId={deviceId}
                    member={member}
                    handleOpenTeamManagerDialog={handleOpenTeamManagerDialog}
                    handleDeleteMember={handleDeleteMember}
                    handleOpenRemoveDeviceDialog={handleOpenRemoveDeviceDialog}
                    handleOpenMakeMemberAWearerDialog={handleOpenMakeMemberAWearerDialog}
                    userIndex={index}
                  />
                ))
              : getEmptyRow()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EverThereAccountsDialogContent;
