/* Copyright */
import * as React from "react";
import { Snackbar, Alert, Typography } from "@mui/material";

export enum SeverityType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export enum SnackbarVerticalPosition {
  Bottom = "bottom",
  Top = "top",
}

export enum SnackbarHorizontalPosition {
  Left = "left",
  Right = "right",
  Center = "center",
}

export type SnackbarItemType = {
  id: number;
  message: string;
  severity: SeverityType;
  vertical: SnackbarVerticalPosition;
  horizontal: SnackbarHorizontalPosition;
  info: string;
};

type SnackbarContextReturnType = {
  show: (
    message: string,
    severity: SeverityType,
    vertical: SnackbarVerticalPosition,
    horizontal: SnackbarHorizontalPosition,
    info: string
  ) => void;
};

const SnackbarContext = React.createContext<SnackbarContextReturnType>({} as SnackbarContextReturnType);
const { Provider } = SnackbarContext;

const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarItems, setSnackbarItems] = React.useState<SnackbarItemType[]>([]);

  const show = React.useCallback(
    (
      message: string,
      severity: SeverityType,
      vertical: SnackbarVerticalPosition,
      horizontal: SnackbarHorizontalPosition,
      info: string
    ): void => {
      const newSnackbar = { id: Date.now(), message, severity, vertical, horizontal, info };
      setSnackbarItems((prevItems) => [...prevItems, newSnackbar]);
    },
    []
  );

  const closeSnackbar = (id: number): void => {
    setSnackbarItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const calculateSpacing = (index: number): number => {
    const verticalSpacing = 60;
    const offsetFromCenter = 20;
    return -(index * verticalSpacing + offsetFromCenter);
  };

  const value = React.useMemo(
    (): SnackbarContextReturnType => ({
      show,
    }),
    [show]
  );

  return (
    <Provider value={value}>
      {children}
      {snackbarItems.map((snackbarItem, index) => (
        <Snackbar
          key={snackbarItem.id}
          anchorOrigin={{
            vertical: snackbarItem.vertical,
            horizontal: snackbarItem.horizontal,
          }}
          open={true}
          autoHideDuration={6000}
          onClose={(): void => closeSnackbar(snackbarItem.id)}
          style={{
            left: "50%",
            transform: `translateX(-50%) translateY(${calculateSpacing(index)}px)`,
          }}
        >
          <Alert variant="filled" onClose={(): void => closeSnackbar(snackbarItem.id)} severity={snackbarItem.severity}>
            {snackbarItem.message}
            {snackbarItem.info && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {snackbarItem.info}
              </Typography>
            )}
          </Alert>
        </Snackbar>
      ))}
    </Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
