/* Copyright */
import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "../context/snackbar-context";
import { DeviceContextProvider } from "../context/device-context";
import { EverThereContextProvider } from "../context/everThere-context";

export const TokenAppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <DeviceContextProvider>
            <EverThereContextProvider>{children}</EverThereContextProvider>
          </DeviceContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default TokenAppProviders;
