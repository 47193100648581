/* Copyright */
import {
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { AWSLatestData, Device, DeviceAttributeName, DeviceState } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { ParameterNames } from "../../../utils/utils";
import BatteryLevel from "../../common/battery-level";
import NetworkSignal from "../../common/network-signal";
import TableDataRow from "../../common/table-data-row";

type DeviceCardProps = {
  device?: Device;
  deviceState?: DeviceState;
  latestData?: AWSLatestData;
};

const DeviceCard: React.FC<DeviceCardProps> = ({ device, deviceState, latestData }) => {
  const [signalStrength, setSignalStrength] = React.useState<number>(0);

  React.useEffect(() => {
    if (latestData) {
      setSignalStrength(latestData.getSignalStrength() ?? 0);
    }
  }, [latestData]);

  return (
    <React.Fragment>
      <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.ActiveCalls.DeviceCard}>
        <CardHeader
          title={translations.common.texts.device()}
          titleTypographyProps={{ variant: "h6" }}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Table
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              <TableDataRow label={translations.common.texts.imei()} value={device?.getId() ?? ""} />
              <TableDataRow label={translations.common.texts.deviceType()} value={device?.getType() ?? ""} />
              <TableDataRow
                label={translations.common.texts.hardwareVersion()}
                value={device?.getAttribute(DeviceAttributeName.hardwareVersion) ?? ""}
              />
              <TableDataRow
                label={translations.deviceSettings.texts.fallDetection()}
                value={
                  (deviceState?.getParameter(ParameterNames.FALL_DETECTION_ENABLED) as boolean)
                    ? translations.common.texts.enabled()
                    : translations.common.texts.disabled()
                }
              />
              <TableDataRow
                label={translations.common.texts.callerIdSim()}
                value={device?.getAttribute(DeviceAttributeName.callerIdNumber) ?? ""}
              />
              <TableRow>
                <TableCell>
                  <InputLabel sx={{ fontSize: "12px", mb: 1 }}>{translations.common.texts.batteryLevel()}</InputLabel>
                </TableCell>
                <TableCell>
                  {latestData?.batteryLevel && <BatteryLevel batteryLevel={latestData.batteryLevel} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputLabel sx={{ fontSize: "12px", mb: 1 }}>{translations.common.texts.networkSignal()}</InputLabel>
                </TableCell>
                <TableCell>
                  {latestData?.batteryLevel && (
                    <NetworkSignal signalStrength={signalStrength} networkType={latestData?.networkType ?? ""} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default DeviceCard;
