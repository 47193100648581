/* Copyright */
import { Checkbox, FormControlLabel, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import { DeviceEvent, SelectedEventFixType } from "../../../utils/eventUtils";
import GoogleMapsWrapper from "../../common/google-maps-wrapper";
import Loading from "../../loading/loading";
import { EventDetailsDialogType } from "../event-details/event-details-dialog";
import EventsMap from "./events-map/events-map";
import SelectingFixButtons from "./events-map/selecting-fix-buttons";

type DeviceEventsLocationsViewRightContentProps = {
  isLoading: boolean;
  groupsToShow?: Map<number, DeviceEvent[]>;
  selectedFixes: SelectedEventFixType;
  showLocationUpdates: boolean;
  onChangeSelectedFix: (fix: string) => void;
  onShowLocationUpdatesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenEventDetailsDialog: (eventId: string, dialogType: EventDetailsDialogType) => void;
};

const DeviceEventsLocationsViewRightContent: React.FC<DeviceEventsLocationsViewRightContentProps> = ({
  isLoading,
  groupsToShow,
  selectedFixes,
  showLocationUpdates,
  onChangeSelectedFix,
  onShowLocationUpdatesChange,
  onOpenEventDetailsDialog,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ width: "90%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
      {isLoading ? (
        <Box>
          <Loading height={64} />
        </Box>
      ) : (
        <Box
          sx={{
            ml: isSmallViewPort ? 0 : 2,
            mt: isSmallViewPort ? 2 : 0,
          }}
        >
          <GoogleMapsWrapper>
            <EventsMap groupsToShow={groupsToShow} onOpenEventDetailsDialog={onOpenEventDetailsDialog} />
          </GoogleMapsWrapper>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControlLabel
              control={<Checkbox size="small" checked={showLocationUpdates} onChange={onShowLocationUpdatesChange} />}
              label={translations.common.texts.showLocationUpdates()}
            />

            <SelectingFixButtons selectedFixes={selectedFixes} handleChangeSelectedFix={onChangeSelectedFix} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DeviceEventsLocationsViewRightContent;
