/* Copyright */
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import { Icon } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";

type batteryLevelProps = {
  batteryLevel: number;
  disabled?: boolean;
};

const BatteryLevel: React.FC<batteryLevelProps> = ({ batteryLevel, disabled }) => {
  const theme = useTheme();

  const solveBatteryIcon = (): React.ReactNode | undefined => {
    const x = batteryLevel;
    switch (true) {
      case x > 95:
        return <BatteryFullIcon />;
      case x > 80:
        return <Battery6BarIcon />;
      case x > 65:
        return <Battery5BarIcon />;
      case x > 50:
        return <Battery4BarIcon />;
      case x > 35:
        return <Battery3BarIcon />;
      case x > 20:
        return <Battery2BarIcon />;
      case x > 5:
        return <Battery1BarIcon />;
      default:
        return <Battery0BarIcon />;
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      {batteryLevel + " %"}
      <Icon
        sx={{
          ml: 1,
          rotate: "90deg",
          color:
            batteryLevel < 6
              ? theme.palette.error.main
              : batteryLevel < 21
              ? theme.palette.warning.dark
              : theme.palette.success.main,
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {solveBatteryIcon()}
      </Icon>
    </Box>
  );
};

export default BatteryLevel;
