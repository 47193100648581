/* Copyright */

import { OidcTokenHolder } from "@sade/data-access";

export function initOidcTokenHolderFromUrl(): boolean {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("oidc_token") ?? undefined;
  if (token) {
    OidcTokenHolder.token = token;
  }
  return OidcTokenHolder.token !== undefined;
}
