/* Copyright */
import { Box } from "@mui/material";
import { BackendFactory, DeviceAttributeName, RoleIdentifier } from "@sade/data-access";
import copy from "clipboard-copy";
import * as React from "react";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { splitQuery } from "../../../utils/queryUtils";
import { DeviceReportItem, formatTimestamp } from "../../../utils/utils";
import AccessControl from "../../access-control/access-control";
import DeviceReportsViewFilterBar from "./device-reports-view-filter-bar";
import DeviceReportsViewTable from "./device-reports-view-table";

const DeviceReports: React.FC = () => {
  const backend = BackendFactory.getBackend();
  const organizationBackend = BackendFactory.getOrganizationBackend();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>("");
  const [selectedImeis, setSelectedImeis] = React.useState<string[]>([]);
  const [deviceData, setDeviceData] = React.useState<DeviceReportItem[]>([]);
  const [reportData, setReportData] = React.useState<DeviceReportItem[]>([]);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const devices: DeviceReportItem[] = [];

        const luceneQueryString = selectedImeis.map((imei) => `thingName:${imei}`).join(" OR ");
        const queryStrings = splitQuery(luceneQueryString, " OR ");

        const searchResult = await Promise.all(
          queryStrings.map((queryString) => backend.searchDevices(queryString, false))
        );

        await Promise.all(
          searchResult.flat().map(async (device) => {
            const organization = await organizationBackend.getOrganization(
              device.getAttribute(DeviceAttributeName.organization) ?? ""
            );

            const createdAt = device.getAttribute(DeviceAttributeName.createdAt);

            const reportItem: DeviceReportItem = {
              imei: device.getId(),
              validity: true,
              status: device.getDeviceStatus(),
              deviceName: device.getName(),
              createdOn: createdAt ? formatTimestamp(new Date(createdAt).getTime()) : "",
              callerId: device.getAttribute(DeviceAttributeName.callerIdNumber),
              iccid: device.getAttribute(DeviceAttributeName.ICCID),
              realmName: organization?.getFullName() ?? "",
              sku: device.getAttribute(DeviceAttributeName.hardwareVersion),
              network: device.getAttribute(DeviceAttributeName.networkOperator),
              authKey: device.getAttribute("hmacKey"), // DeviceAttributeName enum does not contain hmacKey
            };
            devices.push(reportItem);
          })
        );

        const resultImeis = searchResult.flat().map((device) => device.getId());
        const notFoundImeis = selectedImeis.filter((imei) => !resultImeis.includes(imei));

        notFoundImeis.map((imei) => {
          const notFoundImei: DeviceReportItem = {
            imei: imei,
            validity: false,
          };

          devices.push(notFoundImei);
        });

        setDeviceData(devices);
        setReportData(devices);
      } catch (error) {
        console.error(error);
        showSnackbar(translations.common.texts.generalDataFetchError(), SeverityType.Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedImeis.length) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImeis]);

  React.useEffect(() => {
    const filteredData = deviceData.filter((item) =>
      Object.values(item).some(
        (value) => typeof value === "string" && value.toLowerCase().includes(searchString.toLowerCase())
      )
    );

    setReportData(filteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const getReportData = (): string => {
    const headers = [
      translations.common.texts.status(),
      translations.common.texts.deviceName(),
      translations.common.texts.imei(),
      translations.common.texts.createdOn(),
      translations.common.texts.callerId(),
      translations.common.texts.iccid(),
      translations.common.texts.realm(),
      translations.common.texts.sku(),
      translations.common.texts.network(),
      translations.common.texts.authKey(),
      translations.common.texts.validity(),
    ];

    const content = reportData
      .map((item) => {
        const { status, deviceName, imei, createdOn, callerId, iccid, realmName, sku, network, authKey, validity } =
          item;

        return `${status || ""},${deviceName || ""},${imei},${createdOn || ""},${callerId || ""},${iccid || ""},${
          realmName || ""
        },${sku || ""},${network || ""},${authKey || ""},${validity}`;
      })
      .join("\n");

    return `${headers.join(",")}\n${content}`;
  };

  const handleCopy = (): void => {
    const reportContent = getReportData();

    copy(reportContent);
    showSnackbar(translations.common.texts.dataCopiedToClipboard(), SeverityType.Success);
  };

  const handleDownloadCSV = (): void => {
    const reportContent = getReportData();

    const blob = new Blob([reportContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "deviceReport.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmitImeis = (imeis: string[]): void => {
    // Remove duplicates from the list
    setSelectedImeis([...new Set(imeis)]);
  };

  return (
    <Box sx={{ padding: "2rem", overflow: "hidden" }}>
      <AccessControl roles={[RoleIdentifier.PowerUser, RoleIdentifier.Viewer]}>
        <DeviceReportsViewFilterBar
          isLoading={isLoading}
          selectedImeis={selectedImeis}
          searchString={searchString}
          setSearchString={setSearchString}
          handleCopy={handleCopy}
          handleDownloadCSV={handleDownloadCSV}
          handleSubmitImeis={handleSubmitImeis}
        />
        {selectedImeis.length > 0 && <DeviceReportsViewTable isLoading={isLoading} reportData={reportData} />}
      </AccessControl>
    </Box>
  );
};

export default DeviceReports;
