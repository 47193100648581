/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { NotificationsSubscriptionsCreateDocument, NotificationsSubscriptionsListDocument, NotificationsSubscriptionsRemoveDocument, } from "../../generated/gqlEvents";
import { prefixlessId } from "../organization/Utils";
import { throwGQLError } from "../private-utils/throwGQLError";
export class AWSNotificationBackend {
    async getUserNotificationSubscriptions(query) {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.query(NotificationsSubscriptionsListDocument, {
            userId: prefixlessId(query.userId),
            organizationId: query.owningOrganizationId,
            patientId: query.patientId,
        });
        return (_b = (_a = response.data.notificationsSubscriptionsList) === null || _a === void 0 ? void 0 : _a.subscriptions) !== null && _b !== void 0 ? _b : [];
    }
    async createNotificationSubscription(params) {
        var _a;
        const subscriptionDetails = {
            userId: prefixlessId(params.userId),
            type: params.type,
            eventType: params.eventType,
            patientId: params.patientId,
            owningOrganization: params.owningOrganizationId,
        };
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.mutate(NotificationsSubscriptionsCreateDocument, {
            subscriptionDetails,
        });
        if (response.errors) {
            throwGQLError(response);
        }
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.notificationsSubscriptionsCreate;
    }
    async removeNotificationSubscription(params) {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.mutate(NotificationsSubscriptionsRemoveDocument, {
            userId: prefixlessId(params.userId),
            subscriptionId: params.subscriptionId,
        });
        return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.notificationsSubscriptionsRemove) === null || _b === void 0 ? void 0 : _b.subscription;
    }
}
