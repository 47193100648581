/* Copyright */
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid } from "@mui/material";
import { Maybe, Role, RoleIdentifier } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import { AccountFilters, OrganizationInfo } from "../../../utils/utils";
import RealmFilterPicker from "../../common/realm-filter-picker";
import AddNewAccountDialog from "../../dialogs/add-new-account-dialog/add-new-account-dialog";
import AccountsViewDisabledToggle from "./accounts-view-disabled-toggle";
import AccountsViewRolePicker from "./accounts-view-role-picker";
import AccountsViewSearch, { SearchCriteria } from "./accounts-view-search";
import AccountsViewStatusPicker from "./accounts-view-status-picker";
import AccountsViewTabs from "./accounts-view-tabs";

type AccountsViewFilterBarProps = {
  isLoading: boolean;
  childOrganizations: Maybe<OrganizationInfo[]>;
  allRoles: Role[];
  everThere: boolean;
  searchCriteria: SearchCriteria;
  filters: AccountFilters;
  handleSearchCriteriaChange: (searchCriteria: SearchCriteria) => void;
  handleSearchClick: () => void;
  handleFilterChange: (data: Partial<AccountFilters>) => void;
};

const AccountsViewFilterBar: React.FC<AccountsViewFilterBarProps> = ({
  isLoading,
  childOrganizations,
  allRoles,
  everThere,
  searchCriteria,
  filters,
  handleSearchCriteriaChange,
  handleSearchClick,
  handleFilterChange,
}) => {
  const [addNewAccountDialogOpen, setAddNewAccountDialogOpen] = React.useState(false);

  const handleOpenAddNewDialog = (): void => {
    setAddNewAccountDialogOpen(true);
  };

  const handleCloseAddNewDialog = (): void => {
    setAddNewAccountDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "left",
        padding: { xs: "1rem 1rem 0rem 1rem", md: "1rem 1.5rem 0rem 1.5rem" },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <AccountsViewTabs isLoading={isLoading} handleFilterChange={handleFilterChange} filters={filters} />
        <Box sx={{ flex: 1 }} display={{ xs: "none", md: "flex", lg: "none" }}>
          <AccountsViewDisabledToggle
            isLoading={isLoading}
            handleFilterChange={handleFilterChange}
            filters={filters}
            sx={{ margin: "0 0 0 auto" }}
            testId={everThere ? TestIds.Accounts.IncludeDisabled : TestIds.Accounts.EverThereIncludeDisabled}
          />
        </Box>
      </Box>
      <Box
        sx={{ padding: "2rem 0rem 1rem 1rem", alignItems: "top", margin: "0 0 0 auto" }}
        display={{ xs: !everThere ? "flex" : "none", md: "none" }}
      >
        {useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager, RoleIdentifier.Supervisor) && (
          <Button
            sx={{
              visibility: everThere ? "hidden" : "visible",
              marginBottom: "24px",
              marginTop: "8px",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenAddNewDialog}
            data-testid={everThere ? TestIds.Accounts.NewAccountButton : ""}
          >
            {translations.accounts.buttons.addNewAccount()}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "left",
        }}
      >
        <Box
          sx={{ display: "flex", flex: 2, flexDirection: { xs: "column", lg: "row" }, padding: "2rem 0rem 1rem 0rem" }}
        >
          <AccountsViewSearch
            isLoading={isLoading}
            handleSearchCriteriaChange={handleSearchCriteriaChange}
            searchCriteria={searchCriteria}
          />
        </Box>
        <Grid
          container
          columnSpacing={"16px"}
          sx={{ flex: 3, padding: "2rem 0rem 1rem 0rem" }}
          display={{ xs: "none", xl: "flex" }}
        >
          <Grid item xl={everThere ? 12 : 4}>
            <AccountsViewStatusPicker
              isLoading={isLoading}
              filters={filters}
              handleFilterChange={handleFilterChange}
              everThere={everThere}
              testId={
                everThere
                  ? TestIds.AccountsFilterBar.OpenStatusSelection
                  : TestIds.EverThereAccounts.OpenStatusSelection
              }
            />
          </Grid>
          <Grid item xl={4} display={everThere ? "none" : "auto"}>
            <RealmFilterPicker
              isLoading={isLoading}
              filters={filters.realms}
              handleFilterChange={(filters): void => handleFilterChange({ realms: filters })}
              childOrganizations={childOrganizations}
              testId={everThere ? TestIds.AccountsFilterBar.OpenStatusSelection : ""}
            />
          </Grid>
          <Grid item xl={4} display={everThere ? "none" : "auto"}>
            <AccountsViewRolePicker
              isLoading={isLoading}
              filters={filters}
              handleFilterChange={handleFilterChange}
              allRoles={allRoles}
              testId={everThere ? TestIds.AccountsFilterBar.OpenStatusSelection : ""}
            />
          </Grid>
        </Grid>
        <Box sx={{ padding: "2rem 0rem 1rem 1rem", alignItems: "top" }} display={{ xs: "none", lg: "flex" }}>
          <AccountsViewDisabledToggle
            isLoading={isLoading}
            handleFilterChange={handleFilterChange}
            filters={filters}
            testId={everThere ? TestIds.Accounts.EverThereIncludeDisabled : TestIds.Accounts.IncludeDisabled}
          />
        </Box>
        <Box
          sx={{ padding: "2rem 0rem 1rem 1rem", alignItems: "top" }}
          display={{ xs: "none", md: !everThere ? "flex" : "none" }}
        >
          {useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager, RoleIdentifier.Supervisor) && (
            <Button
              sx={{
                visibility: everThere ? "hidden" : "visible",
                marginBottom: "24px",
                marginTop: "8px",
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenAddNewDialog}
              data-testid={everThere ? "" : TestIds.Accounts.NewAccountButton}
            >
              {translations.accounts.buttons.addNewAccount()}
            </Button>
          )}
        </Box>
      </Box>
      <Grid
        container
        columnSpacing={"16px"}
        sx={{ flex: 3, padding: "2rem 0rem 1rem 0rem" }}
        display={{ xs: "flex", xl: "none" }}
      >
        <Grid item xs={12} sm={6} md={everThere ? 12 : 4}>
          <AccountsViewStatusPicker
            isLoading={isLoading}
            filters={filters}
            handleFilterChange={handleFilterChange}
            everThere={everThere}
            testId={
              everThere ? TestIds.EverThereAccounts.OpenStatusSelection : TestIds.AccountsFilterBar.OpenStatusSelection
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display={everThere ? "none" : "auto"}>
          <RealmFilterPicker
            isLoading={isLoading}
            filters={filters.realms}
            handleFilterChange={(filters): void => handleFilterChange({ realms: filters })}
            childOrganizations={childOrganizations}
            testId={everThere ? "" : TestIds.AccountsFilterBar.OpenRealmSelection}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display={everThere ? "none" : "auto"}>
          <AccountsViewRolePicker
            isLoading={isLoading}
            filters={filters}
            handleFilterChange={handleFilterChange}
            allRoles={allRoles}
            testId={everThere ? "" : TestIds.AccountsFilterBar.OpenRoleSelection}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display={{ xs: "flex", md: "none" }}>
          <AccountsViewDisabledToggle isLoading={isLoading} handleFilterChange={handleFilterChange} filters={filters} />
        </Grid>
      </Grid>
      <AddNewAccountDialog
        open={addNewAccountDialogOpen}
        handleClose={handleCloseAddNewDialog}
        reFetchUserData={handleSearchClick}
      />
    </Box>
  );
};

export default AccountsViewFilterBar;
