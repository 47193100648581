/* Copyright */
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { translations } from "../../generated/translationHelper";

const EventsMapNoLocationDataOverlay: React.FC = () => {
  return (
    <Box
      sx={{
        left: "40%",
        top: "50%",
        position: "absolute",
        zIndex: 1000,
        borderRadius: 1,
        bgcolor: "white",
        p: 2,
        boxShadow: 1,
      }}
    >
      <Typography variant="h5">{translations.events.texts.noLocationDataAvailable()}</Typography>
    </Box>
  );
};

export default EventsMapNoLocationDataOverlay;
