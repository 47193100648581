/* Copyright */
import { Box } from "@mui/material";
import {
  BackendFactory,
  DeviceListResult,
  DeviceSearchField,
  DeviceStatus,
  Maybe,
  RoleIdentifier,
} from "@sade/data-access";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { useDeviceContext } from "../../context/device-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { DeviceListItem, getChildOrganizations } from "../../utils/utils";
import AccessControl from "../access-control/access-control";
import DevicesTable from "./devices-table/devices-table";
import DevicesViewFilterBar from "./devices-view-filter-bar/devices-view-filter-bar";

const pageSize = 50;

const DevicesView: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showSnackbar } = useSnackbar();
  const backend = BackendFactory.getBackend();
  const { currentOrganization } = useAuthenticatedUser();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const {
    searchString,
    setSearchString,
    searchField,
    setSearchField,
    selectedRealms,
    setSelectedRealms,
    selectedStatuses,
    setSelectedStatuses,
    filteredDevicesList,
    setFilteredDevicesList,
    scrollPosition,
    setScrollPosition,
    devicesRef,
    childOrganizationListRef,
  } = useDeviceContext();

  const [error, setError] = React.useState(false);

  // Refs for data that should not trigger re-render
  const tableEl = React.useRef<HTMLDivElement>(null);
  const latestFetchDataPromise = React.useRef<Promise<DeviceListResult> | null>(null);
  const nextTokenRef = React.useRef<Maybe<string>>();

  const fetchDeviceData = React.useCallback(
    async (
      searchField?: DeviceSearchField,
      searchQuery?: string,
      realmFilter?: string[],
      statusFilter?: DeviceStatus[]
    ): Promise<DeviceListItem[]> => {
      try {
        if (!currentOrganization) {
          return [];
        }

        setIsLoading(true);

        // We need to store the latest fetch promise so we can check if the promise is still valid when it resolves
        // TODO: We should investigate if we can use TanStack Query for this
        const fetchDevicesPromise = (latestFetchDataPromise.current = backend.listAllAccessibleDevices(
          {
            targetResultCount: pageSize,
            realmFilter: realmFilter ?? [],
            statusFilter: statusFilter ?? [],
            searchField,
            searchQuery,
          },
          nextTokenRef.current
        ));

        const [devicesFetchResult, childOrganizationList] = await Promise.all([
          fetchDevicesPromise,
          !childOrganizationListRef.current
            ? getChildOrganizations(currentOrganization.getId())
            : childOrganizationListRef.current,
        ]);

        // If the promise is not the latest one, we should not update the state. It is totally possible that there are few
        // requests in flight, and we should only update the state with the latest one.
        if (latestFetchDataPromise.current !== fetchDevicesPromise) {
          return [];
        }

        childOrganizationListRef.current = !childOrganizationListRef.current
          ? childOrganizationList ?? []
          : childOrganizationListRef.current;

        nextTokenRef.current = devicesFetchResult.nextToken;
        return (
          devicesFetchResult.devices.map((device) => ({
            ...device,
            realmName:
              childOrganizationListRef.current?.find((org) => org.id === device.organizationId)?.fullName ?? "",
          })) ?? []
        );
      } catch (error) {
        setError(true);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [backend, childOrganizationListRef, currentOrganization]
  );

  React.useEffect(() => {
    (async (): Promise<void> => {
      /**
       * NOTE:
       * TODO: We have a little temporary trick here. We need to fetch the device data when the user navigates to the
       * devices view, but we don't want to do it when the user navigates back from the device view. However we need
       * to reset location to the devices view when the user navigates back from the device view. So if user presses
       * refresh on this page we trigger the data fetch. This is not ideal, but it works for now.
       */
      if (currentOrganization && !state) {
        setSelectedRealms([]);
        setSelectedStatuses([]);
        setSearchString("");
        setSearchField(DeviceSearchField.Imei);
        setFilteredDevicesList(await fetchDeviceData());
      } else if (state) {
        navigate(location.pathname, { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  React.useEffect(() => {
    if (error) {
      showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
    }
  }, [error, showSnackbar]);

  const resetTable = (): void => {
    tableEl.current?.scrollTo(0, 0);
  };

  const handleSearchFieldChange = async (searchField: DeviceSearchField): Promise<void> => {
    setSearchField(searchField);
    nextTokenRef.current = undefined;
    setFilteredDevicesList([]);
    setFilteredDevicesList(await fetchDeviceData(searchField, searchString, selectedRealms, selectedStatuses));
    resetTable();
  };

  const handleSearchStringChange = async (searchString: string): Promise<void> => {
    setSearchString(searchString);
    nextTokenRef.current = undefined;
    setFilteredDevicesList([]);
    setFilteredDevicesList(await fetchDeviceData(searchField, searchString, selectedRealms, selectedStatuses));
    resetTable();
  };

  const handleRealmSelectChange = async (realms: string[]): Promise<void> => {
    setSelectedRealms(realms);
    nextTokenRef.current = undefined;
    setFilteredDevicesList([]);
    setFilteredDevicesList(await fetchDeviceData(searchField, searchString, realms, selectedStatuses));
    resetTable();
  };

  const handleStatusSelectChange = async (selectedStatuses: DeviceStatus[]): Promise<void> => {
    setSelectedStatuses(selectedStatuses);
    nextTokenRef.current = undefined;
    setFilteredDevicesList([]);
    setFilteredDevicesList(await fetchDeviceData(searchField, searchString, selectedRealms, selectedStatuses));
    resetTable();
  };

  const scrollListener = React.useCallback(() => {
    (async (): Promise<void> => {
      if (!tableEl.current || isLoading) return;
      const { scrollHeight, scrollTop, clientHeight } = tableEl.current;

      if (scrollHeight - scrollTop - clientHeight < 100 && filteredDevicesList.length && nextTokenRef.current) {
        const moreDevices = await fetchDeviceData(searchField, searchString, selectedRealms, selectedStatuses);
        setFilteredDevicesList((prev) => [...prev, ...moreDevices]);
      }
    })();
  }, [
    fetchDeviceData,
    filteredDevicesList.length,
    isLoading,
    searchField,
    searchString,
    selectedRealms,
    selectedStatuses,
    setFilteredDevicesList,
  ]);

  React.useEffect(() => {
    const tableRef = tableEl.current;
    if (scrollPosition) {
      setTimeout(() => {
        tableEl.current?.scrollTo(0, scrollPosition);
        setScrollPosition(null);
      }, 50);
    }

    tableRef?.addEventListener("scroll", scrollListener);
    return (): void => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener, scrollPosition, setScrollPosition]);

  const handleRowClick = (deviceId: string): void => {
    setScrollPosition(tableEl.current?.scrollTop ?? null);
    navigate(deviceId, {
      state: { status: devicesRef.current.find((device) => device.getId() === deviceId)?.getDeviceStatus() },
    });
  };

  return (
    <Box sx={{ padding: "3rem", overflow: "hidden" }}>
      <AccessControl
        roles={[
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.WarehouseInstaller,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
        ]}
      >
        <DevicesViewFilterBar
          isLoading={isLoading}
          searchString={searchString}
          selectedSearchField={searchField}
          selectedStatuses={selectedStatuses}
          selectedRealms={selectedRealms}
          handleSearchFieldChange={handleSearchFieldChange}
          handleSearchStringChange={handleSearchStringChange}
          handleRealmSelectChange={handleRealmSelectChange}
          handleStatusSelectChange={handleStatusSelectChange}
        />
        <DevicesTable
          devices={filteredDevicesList}
          tableRef={tableEl}
          isLoading={isLoading}
          handleRowClick={handleRowClick}
        />
      </AccessControl>
    </Box>
  );
};

export default DevicesView;
