/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer/BaseObservable";
export var DeviceAttributeName;
(function (DeviceAttributeName) {
    DeviceAttributeName["callerIdNumber"] = "callerIdNumber";
    DeviceAttributeName["createdAt"] = "createdAt";
    DeviceAttributeName["externalId1"] = "externalId1";
    DeviceAttributeName["externalId2"] = "externalId2";
    DeviceAttributeName["externalId3"] = "externalId3";
    DeviceAttributeName["hardwareVersion"] = "hardwareVersion";
    DeviceAttributeName["ICCID"] = "ICCID";
    DeviceAttributeName["mirroredIMEI"] = "mirroredIMEI";
    DeviceAttributeName["name"] = "name";
    DeviceAttributeName["networkOperator"] = "networkOperator";
    DeviceAttributeName["operatorPlan"] = "operatorPlan";
    DeviceAttributeName["organization"] = "organization";
    DeviceAttributeName["patientId"] = "patientId";
    DeviceAttributeName["serialNumber"] = "serialNumber";
    DeviceAttributeName["softwareVersion"] = "softwareVersion";
    DeviceAttributeName["status"] = "status";
    DeviceAttributeName["variant"] = "variant";
})(DeviceAttributeName || (DeviceAttributeName = {}));
export var DeviceAction;
(function (DeviceAction) {
    DeviceAction["SendLogs"] = "SendLogs";
    DeviceAction["UpdateConfiguration"] = "UpdateConfiguration";
    DeviceAction["Reboot"] = "Reboot";
    DeviceAction["FlushQueues"] = "FlushQueues";
    DeviceAction["CallNow"] = "CallNow";
    DeviceAction["LocateNow"] = "LocateNow";
    DeviceAction["PlayTune"] = "PlayTune";
    DeviceAction["ResetKey"] = "ResetKey";
    DeviceAction["SoftwareUpdate"] = "SoftwareUpdate";
})(DeviceAction || (DeviceAction = {}));
export var PeripheralState;
(function (PeripheralState) {
    PeripheralState["Whitelisted"] = "Whitelisted";
    PeripheralState["Paired"] = "Paired";
    PeripheralState["Unpairing"] = "Unpairing";
})(PeripheralState || (PeripheralState = {}));
export var PeripheralType;
(function (PeripheralType) {
    PeripheralType["BasicPendant"] = "BasicPendant";
    PeripheralType["Thermometer"] = "Thermometer";
    PeripheralType["Oxymeter"] = "Oxymeter";
    PeripheralType["WeightScale"] = "WeightScale";
    PeripheralType["BloodPressureMonitor"] = "BloodPressureMonitor";
    PeripheralType["GlucoseMeter"] = "GlucoseMeter";
})(PeripheralType || (PeripheralType = {}));
export var DeviceStatus;
(function (DeviceStatus) {
    DeviceStatus["unmanaged"] = "unmanaged";
    DeviceStatus["retired"] = "retired";
    DeviceStatus["activating"] = "activating";
    DeviceStatus["active"] = "active";
    DeviceStatus["deactivating"] = "deactivating";
    DeviceStatus["unsuccesful"] = "unsuccesful";
})(DeviceStatus || (DeviceStatus = {}));
export class Device extends BaseObservable {
    getNetworkOperatorDisplayName(plans) {
        var _a;
        return (_a = this.getCurrentOperatorPlan(plans)) === null || _a === void 0 ? void 0 : _a.operatorDisplayName;
    }
    getOperatorPlanDisplayName(plans) {
        var _a;
        return (_a = this.getCurrentOperatorPlan(plans)) === null || _a === void 0 ? void 0 : _a.displayName;
    }
    getCurrentOperatorPlan(plans) {
        return plans.find((plan) => plan.planId === this.getAttribute(DeviceAttributeName.operatorPlan));
    }
    getAvailablePlans(plans) {
        return plans.filter((plan) => plan.operator === this.getAttribute(DeviceAttributeName.networkOperator) &&
            plan.deviceTypes.includes(this.getType()));
    }
    static instanceOf(value) {
        return value instanceof Device;
    }
}
