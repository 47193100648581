/** Copyright */
import { Paper, PaperProps } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

export const DRAGGABLE_DIALOG_HANDLE = "draggable-dialog-handle";

const DraggableComponent = (props: PaperProps): React.ReactElement => {
  return (
    <Draggable handle={`#${DRAGGABLE_DIALOG_HANDLE}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export default DraggableComponent;
