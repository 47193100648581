/* Copyright */
import { PatientsLatestDataGetDocument } from "../../generated/gqlEvents";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { BaseObservable } from "../observer";
import { AWSLatestData } from "./AWSLatestData";
import { LatestDataSubscriptionManager } from "./LatestDataSubscriptionManager";
export class AWSLatestPatientData extends BaseObservable {
    constructor(patient) {
        super();
        this.patient = patient;
        this.latestDataSubscription = {
            getId: () => {
                return this.getId();
            },
            onLatestData: (latestData) => {
                this.setData(latestData);
            },
        };
    }
    async fetch() {
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const latestDataResponse = await appSyncClient.query(PatientsLatestDataGetDocument, {
            patientId: this.getId(),
        });
        if (latestDataResponse.data.patientsLatestDataGet) {
            this.setData(new AWSLatestData(latestDataResponse.data.patientsLatestDataGet));
        }
    }
    /**
     * Add observer for latest data updates
     *
     * @param observer
     */
    async addObserver(observer) {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observerCount === 1) {
            LatestDataSubscriptionManager.instance.addListener(this.latestDataSubscription, [this.getId()]);
        }
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        if (this.observerCount === 0) {
            LatestDataSubscriptionManager.instance.removeListener(this.latestDataSubscription);
        }
    }
    clearObservers() {
        super.clearObservers();
        LatestDataSubscriptionManager.instance.removeListener(this.latestDataSubscription);
    }
    getId() {
        return this.patient.getId();
    }
    getData() {
        return this.data;
    }
    setData(data) {
        this.data = data;
        this.notifyAction((observer) => observer.onLatestDataUpdate(data));
    }
}
