/* Copyright */
import { __rest } from "tslib";
import { Service } from "../backend/AppSyncClientProvider";
import { LatestDataFeedDocument } from "../../generated/gqlEvents";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { AWSLatestData } from "./AWSLatestData";
export class LatestDataSubscriptionManager extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS), LatestDataFeedDocument);
    }
    static get instance() {
        if (!this.__instance) {
            this.__instance = new LatestDataSubscriptionManager();
        }
        return this.__instance;
    }
    async handleSubscriptionResult(result, listeners) {
        var _a;
        if ((_a = result.latestDataFeed) === null || _a === void 0 ? void 0 : _a.item) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _b = result.latestDataFeed.item, { __typename } = _b, latestData = __rest(_b, ["__typename"]);
            listeners.forEach((listener) => {
                if (listener.getId() === latestData.patientId) {
                    listener.onLatestData(new AWSLatestData(latestData));
                }
            });
        }
    }
}
