/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ParametersGetDocument, ParametersHierarchyGetDocument, ParametersUpdateDocument, } from "../../generated/gqlParameters";
import { OrganizationsDeleteDocument, OrganizationsEventPushConfigurationGetDocument, OrganizationsEventPushConfigurationUpdateDocument, OrganizationsOrganizationsListDocument, OrganizationsUpdateDocument, OrganizationsUsersListDocument, OrganizationsUsersSearchDocument, ResultType, UserSearchField, } from "../../generated/gqlUsers";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { AWSCallEventSet } from "../events";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
import { throwGQLError } from "../private-utils/throwGQLError";
import { verifyUserType } from "./AWSTypeUtils";
import { AWSUser } from "./AWSUser";
import { Organization, } from "./Organization";
import { User } from "./User";
import { isBaseOrganizationId } from "./Utils";
const META_PARAMETER_PREFIX = "META_";
export class AWSOrganization extends Organization {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSOrganization;
        this.usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());
        this.childrenSemaphore = new PromiseSemaphore(() => this.backendFetchChildOrganizations());
    }
    static instanceOf(value) {
        return value instanceof AWSOrganization;
    }
    async updateDetails(parameters) {
        await this.backendUpdateOrganization(parameters);
        this.name = parameters.name;
        this.realmName = parameters.realmName;
        this.netsuiteId = parameters.netsuiteId;
        this.networkId = parameters.networkId;
        this.patientTextUrl = parameters.patientTextUrl;
        this.ssoConfiguration = parameters.ssoConfiguration;
        if (parameters.apiKeyIds) {
            this.apiKeyIds = parameters.apiKeyIds;
        }
        this.notifyAction((observer) => { var _a; return (_a = observer.onDetailsChange) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
    }
    async getFullRealmName(organization) {
        return this.recursiveOrganizationRealmName(organization);
    }
    async getUsers() {
        await this.usersSemaphore.guard();
        const result = this.backend.entityRelationCache.listFor(this, AWSUser);
        result.sort(User.alphabeticUserOrdering);
        return result;
    }
    async searchUsersFromThisAndChildOrganizations(searchParameters, nextToken) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsUsersSearchDocument, {
            organizationId: this.id,
            searchParameters: {
                includeUserTypes: searchParameters.includeUserTypes,
                realmFilter: searchParameters.realmFilter,
                roleFilter: searchParameters.roleFilter,
                statusFilter: searchParameters.statusFilter,
                targetResultCount: searchParameters.targetResultCount,
                searchField: searchParameters.searchField ? UserSearchField[searchParameters.searchField] : undefined,
                searchQuery: searchParameters.searchQuery,
                includeDisabledUsers: searchParameters.includeDisabledUsers,
            },
            nextToken,
        });
        if (!((_a = response.data.organizationsUsersSearch) === null || _a === void 0 ? void 0 : _a.users)) {
            throwGQLError(response, "Failed to search for organization's users");
        }
        const userInformation = response.data.organizationsUsersSearch.users.map((u) => ({
            userId: u.userId,
            email: u.email,
            firstName: u.firstName,
            lastName: u.lastName,
            organizationId: u.organizationId,
            roleIdentifier: u.roleIdentifier,
            status: u.status,
            enabled: u.enabled,
        }));
        return {
            users: userInformation,
            nextToken: response.data.organizationsUsersSearch.nextToken,
        };
    }
    async createOrganization(parameters) {
        const newOrganization = await this.backend.createOrganization(this, parameters);
        await this.getChildOrganizations();
        this.backend.entityRelationCache.link(this, newOrganization);
        return newOrganization;
    }
    async getChildOrganizations(recursive = false) {
        // If we previously fetched the children non-recursively and now we want to fetch them recursively,
        // we need to reset the semaphore so that the children are fetched again.
        if (!this.recursiveChilds && recursive)
            this.childrenSemaphore.reset();
        this.recursiveChilds = recursive;
        await this.childrenSemaphore.guard();
        const allChildren = this.backend.entityRelationCache
            .listFor(this, AWSOrganization)
            .filter((potentialChild) => potentialChild.id.startsWith(this.id));
        if (recursive) {
            return allChildren;
        }
        else {
            return allChildren.filter((child) => child.parentId === this.id);
        }
    }
    async getChildOrganizationsAssignableToUser(user) {
        const childOrganizations = await this.getChildOrganizations();
        return childOrganizations.filter((organization) => organization.isAssignableToUser(user));
    }
    async isAssignableToUser(user) {
        const userOrganizations = await user.getOrganizations();
        if (userOrganizations.length === 0)
            return true;
        return !userOrganizations.some((userOrganization) => Organization.isParentOrEqualOrganizationId(this.getId(), userOrganization.getId()));
    }
    async getParentOrganization(organization) {
        if (this.parentId) {
            let parentOrganization;
            // If the optional 'organization' parameter was provided, then try to look up the parent organization
            // from the cache of that organization. This is a performance optimization.
            if (organization && AWSOrganization.instanceOf(organization)) {
                parentOrganization = this.backend.entityRelationCache
                    .listFor(organization, AWSOrganization)
                    .find((record) => record.getId() === this.parentId);
            }
            return parentOrganization !== null && parentOrganization !== void 0 ? parentOrganization : this.backend.getOrganization(this.parentId);
        }
    }
    async createUser(parameters) {
        try {
            const newUser = await this.backend.createUser(this, parameters);
            this.backend.entityRelationCache.link(this, newUser);
            return newUser;
        }
        catch (error) {
            console.error("Failed to create new user: " + error);
            throw error;
        }
    }
    async addUser(user, roles) {
        verifyUserType(user);
        try {
            await user.addToOrganization(this, roles);
        }
        catch (error) {
            console.error("Failed to add user to organization: " + error);
            throw error;
        }
    }
    async removeUser(user) {
        verifyUserType(user);
        try {
            await user.removeFromOrganization(this);
        }
        catch (error) {
            console.error("Failed to remove user from organization: " + error);
            throw error;
        }
    }
    async delete() {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsDeleteDocument, {
            organizationId: this.id,
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throw new Error((_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.organizationsDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete organization");
        }
        await this.backend.cleanEntityFromCaches(this.id);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDeleted) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        this.clearObservers();
    }
    getCallEventSet() {
        if (this.eventSet) {
            return this.eventSet;
        }
        // Fetch new event set
        this.eventSet = new AWSCallEventSet(this);
        return this.eventSet;
    }
    async getParameters() {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.PARAMETERS);
        const response = await client.query(ParametersGetDocument, {
            organizationId: this.id,
        });
        return {
            deviceParameters: ((_a = response.data.parametersGet) === null || _a === void 0 ? void 0 : _a.deviceParameters)
                ? JSON.parse(response.data.parametersGet.deviceParameters)
                : undefined,
            systemParameters: ((_b = response.data.parametersGet) === null || _b === void 0 ? void 0 : _b.systemParameters)
                ? JSON.parse(response.data.parametersGet.systemParameters)
                : undefined,
        };
    }
    async updateParameters(input) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.PARAMETERS);
        const response = await client.mutate(ParametersUpdateDocument, {
            organizationId: this.id,
            parameters: {
                deviceParameters: input.deviceParameters ? JSON.stringify(input.deviceParameters) : undefined,
                systemParameters: input.systemParameters ? JSON.stringify(input.systemParameters) : undefined,
            },
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.parametersUpdate)) {
            throwGQLError(response, "Failed to update organization parameters");
        }
        // Clear cached parameter hierarchy
        this.parameterHierarchy = undefined;
    }
    async getParameterHierarchy() {
        var _a;
        if (this.parameterHierarchy) {
            return this.parameterHierarchy;
        }
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.PARAMETERS);
        const response = await client.mutate(ParametersHierarchyGetDocument, {
            organizationId: this.id,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.parametersHierarchyGet)) {
            throwGQLError(response, "Failed to get organization parameter hierarchy");
        }
        this.parameterHierarchy = {
            deviceParameters: response.data.parametersHierarchyGet.deviceParameters
                ? JSON.parse(response.data.parametersHierarchyGet.deviceParameters)
                : undefined,
            systemParameters: response.data.parametersHierarchyGet.systemParameters
                ? JSON.parse(response.data.parametersHierarchyGet.systemParameters)
                : undefined,
        };
        return this.parameterHierarchy;
    }
    isParameterLocked(parameterName, parameterHierarchy) {
        if (!parameterHierarchy.systemParameters)
            return false;
        // Flatten to Parameters object
        const parameters = Object.values(parameterHierarchy.systemParameters)
            .flatMap((obj) => Object.entries(obj))
            .reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: value })), {});
        // Search for a parameter named "META_<parameterName>"
        const parameter = parameters[META_PARAMETER_PREFIX + parameterName];
        if (!parameter)
            return false;
        try {
            // If the parameter has a "locked" field, return its value
            if (typeof parameter === "object" && "locked" in parameter)
                return parameter.locked;
            if (typeof parameter === "string")
                return JSON.parse(parameter).locked;
            return false;
        }
        catch (e) {
            // Unable to parse JSON, assume not locked
            return false;
        }
    }
    async getEventPushConfiguration() {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsEventPushConfigurationGetDocument, {
            organizationId: this.id,
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsEventPushConfigurationGet;
    }
    async updateEventPushConfiguration(configuration) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsEventPushConfigurationUpdateDocument, {
            organizationId: this.id,
            payload: {
                enabled: configuration.enabled,
                url: configuration.url,
                rule: configuration.rule,
                type: configuration.type,
                configuration: configuration.configuration,
            },
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsEventPushConfigurationUpdate)) {
            throwGQLError(response, "Failed to update event push configuration");
        }
    }
    async onRelationChange(change) {
        // using semaphore.invoked() to check if some external entity is interested in the particular updates
        // otherwise we'd end up greedily loading resources (such as the whole organization tree)
        if (change.ofType(AWSOrganization) && this.childrenSemaphore.invoked()) {
            const children = await this.getChildOrganizations();
            this.notifyAction((observer) => { var _a; return (_a = observer.onChildrenChange) === null || _a === void 0 ? void 0 : _a.call(observer, children, this); });
        }
        else if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
            const users = await this.getUsers();
            this.notifyAction((observer) => { var _a; return (_a = observer.onUsersChange) === null || _a === void 0 ? void 0 : _a.call(observer, users, this); });
        }
    }
    async backendUpdateOrganization(params) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        await client.mutate(OrganizationsUpdateDocument, {
            payload: {
                id: this.id,
                name: params.name,
                realmName: params.realmName,
                netsuiteId: params.netsuiteId,
                networkId: params.networkId,
                patientTextUrl: params.patientTextUrl,
                ssoConfiguration: params.ssoConfiguration,
                apiKeyIds: params.apiKeyIds,
            },
        });
    }
    async backendFetchUsers() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsUsersListDocument, {
            organizationId: this.id,
            // TODO: token
        });
        if (!response.data.organizationsUsersList) {
            throwGQLError(response, "Failed to get organization's users");
        }
        // TODO:  in order to improve performance here, we need to decide what data should be duplicated
        //        from user to the organization-user link row, and use that to construct a simplified view to the user
        //        ... or we can just resolve the users on the service side, but that is as expensive as OrganizationUtils.
        //            maybe a little faster
        //        ... or we could add some smart indexes here and there for specific use-cases, such as this (although,
        //            custom indexing org-users relationship is pretty hard)
        //
        //        Currently, the backend does a lot of caching, so that helps with repeated requests
        const maybeUsers = await Promise.all(response.data.organizationsUsersList.users.map((id) => this.backend.getUser(id)));
        const users = maybeUsers.filter(AWSUser.instanceOf);
        this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users);
    }
    async backendFetchChildOrganizations() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsOrganizationsListDocument, {
            organizationId: this.id,
            recursive: this.recursiveChilds,
            // TODO: next token
        });
        if (!response.data.organizationsOrganizationsList) {
            throwGQLError(response, "Failed to get organization's child organizations");
        }
        const organizations = response.data.organizationsOrganizationsList.organizations.map((org) => new AWSOrganization(this.backend, org));
        const children = organizations.filter(AWSOrganization.instanceOf);
        // keeps the potential parent in the cache, otherwise we are in trouble
        this.backend.entityRelationCache.replaceTypedLinks(this, AWSOrganization, children, (record) => record.entity.id === this.parentId);
    }
    async recursiveOrganizationRealmName(organization) {
        const parent = await this.getParentOrganization(organization);
        if (parent) {
            // Don't include the base organization in the name
            if (!isBaseOrganizationId(parent.id))
                return `${await parent.recursiveOrganizationRealmName()}.${this.realmName}`;
        }
        return this.name;
    }
}
