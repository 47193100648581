/* Copyright */
import React from "react";
import { Box, Avatar, ListItem, ListItemText, SvgIconProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { eventLevel } from "../../../../utils/eventUtils";

type LatestEventProps = {
  icon?: React.ElementType<SvgIconProps>;
  eventName?: string;
  eventTime?: string;
  level?: eventLevel;
};

const LatestEvent: React.FC<LatestEventProps> = ({
  icon: Icon,
  eventName,
  eventTime,
  level = eventLevel.UNSPECIFIED,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <ListItem sx={{ pl: 0 }}>
        {Icon && (
          <Avatar
            sx={{
              bgcolor:
                level === eventLevel.ALERT
                  ? theme.palette.error.light
                  : level === eventLevel.WARNING
                  ? theme.palette.orange.light
                  : level === eventLevel.NORMAL
                  ? theme.palette.blue.light
                  : "",
            }}
          >
            <Icon
              sx={{
                color:
                  level === eventLevel.ALERT
                    ? theme.palette.error.main
                    : level === eventLevel.WARNING
                    ? theme.palette.warning.dark
                    : level === eventLevel.NORMAL
                    ? theme.palette.primary.light
                    : "",
              }}
            />
          </Avatar>
        )}
        <ListItemText primary={eventName} secondary={eventTime} sx={{ ml: 2 }} />
      </ListItem>
    </Box>
  );
};

export default LatestEvent;
