/* Copyright */
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { CognitoAccountStatus } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { UserStatuses, AccountFilters, UserStatusNames, UserStatus } from "../../../utils/utils";

type AccountsViewStatusPickerProps = {
  isLoading: boolean;
  filters: AccountFilters;
  handleFilterChange: (data: Partial<AccountFilters>) => void;
  everThere: boolean;
  testId?: string;
};

function getUserStatuses(everThere: boolean): UserStatus[] {
  if (everThere) {
    return UserStatuses.filter((status) => status.name !== UserStatusNames.EXTERNAL_PROVIDER);
  }
  return UserStatuses;
}

const AccountsViewStatusPicker: React.FC<AccountsViewStatusPickerProps> = ({
  isLoading,
  filters,
  handleFilterChange,
  everThere,
  testId,
}) => {
  const [selectedStatuses, setSelectedStatuses] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSelectedStatuses([
      ...new Set(
        filters.statuses
          .map((status) => {
            return getUserStatuses(everThere).find((userStatus) => userStatus.identifiers.includes(status))?.name ?? "";
          })
          .filter((status) => status !== "")
      ),
    ]);
  }, [filters, everThere]);

  const handleStatusSelectChange = (event: SelectChangeEvent<typeof selectedStatuses>): void => {
    const {
      target: { value },
    } = event;
    const selectedStatuses = typeof value === "string" ? value.split(",") : value;

    const accountStatuses: CognitoAccountStatus[] = [];
    selectedStatuses.forEach((selectedStatus) => {
      const accountStatus = UserStatuses.find((userStatus) => userStatus.name === selectedStatus);
      accountStatus?.identifiers.forEach((status) => {
        accountStatuses.push(status as CognitoAccountStatus);
      });
    });

    handleFilterChange({
      statuses: accountStatuses,
    });
  };

  return (
    <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
      <InputLabel id="filter-by-status-label" shrink disabled={isLoading}>
        {translations.common.inputs.status()}
      </InputLabel>
      <Select
        id="status"
        size="small"
        multiple
        labelId="filter-by-status-label"
        data-testid={testId}
        inputProps={{ "data-testid": TestIds.AccountsFilterBar.StatusSelect }}
        value={selectedStatuses}
        onChange={handleStatusSelectChange}
        renderValue={(selected): string => selected.join(", ")}
        disabled={isLoading}
      >
        {getUserStatuses(everThere).map(
          (status, index) =>
            status.name !== UserStatusNames.DISABLED && (
              <MenuItem key={index} value={status.name} data-option-index={index}>
                <Checkbox checked={selectedStatuses.indexOf(status.name) > -1} />
                <ListItemText primary={status.name} data-option-index={index} />
              </MenuItem>
            )
        )}
      </Select>
      <FormHelperText>{String(" ")}</FormHelperText>
    </FormControl>
  );
};

export default AccountsViewStatusPicker;
