/* Copyright */
import { Device, DeviceStatus, Panel4200x } from "@sade/data-access";
import { translations } from "../generated/translationHelper";
import { ExternalDeviceIdentifierParameter } from "./parameterUtils";

export const enableEditDialog = (status?: DeviceStatus): boolean => {
  if (!status) return false;
  switch (status) {
    case DeviceStatus.active:
    case DeviceStatus.deactivating:
      return true;
    default:
      return false;
  }
};

export const enableEdit = (status?: DeviceStatus): boolean => {
  if (!status) return false;
  switch (status) {
    case DeviceStatus.active:
      return true;
    default:
      return false;
  }
};

export const enableEditOperatorPlan = (status?: DeviceStatus): boolean => {
  if (!status) return false;
  switch (status) {
    case DeviceStatus.unmanaged:
      return true;
    default:
      return false;
  }
};

/**
 * This method is used to check if device supports settings on device view and maintenance menu actions
 * @param device - The device object.
 * @returns True if the device supports settings and actions, otherwise false.
 */
export const deviceSupportsSettingsAndActions = (device: Device): boolean => {
  // Currently only Panel4200x is the only device that DOES NOT support settings and actions
  return !Panel4200x.instanceOf(device);
};

/**
 * Return the label for the name row based on the provided {@link ExternalDeviceIdentifierParameter}.
 * If the parameter is not provided or the label is empty, it returns the default device name.
 * @param param - The {@link ExternalDeviceIdentifierParameter} object.
 * @returns The label for the row.
 */
export const getNameRowLabel = (param?: ExternalDeviceIdentifierParameter): string => {
  if (param) {
    return param.label !== "" ? param.label : translations.common.texts.deviceName();
  } else {
    return translations.common.texts.deviceName();
  }
};
