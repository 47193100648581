/* Copyright */
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, IconButton, Divider, CardHeader, Avatar, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { translations } from "../../../generated/translationHelper";
import { UserOrganizationWithRole, getUserFullName, getUserInitials, Paths } from "../../../utils/utils";
import RealmAccessSelectorDialogList from "./realm-access-selector-dialog-list";
import RealmAccessSelectorDialogSearch from "./realm-access-selector-dialog-search";
import { SeverityType } from "../../../context/snackbar-context";
import TestIds from "../../../test-ids/test-ids";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import Loading from "../../loading/loading";
import { BackendFactory } from "@sade/data-access";
import { getPath } from "../../../utils/ssoPathUtil";

type RealmAccessSelectorDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const RealmAccessSelectorDialog: React.FC<RealmAccessSelectorDialogProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userInfo, currentOrganization, userOrganizationRoles, isChangingRealm, changeRealmAccess } =
    useAuthenticatedUser();
  const { showSnackbar } = useSnackbar();
  const [filteredUserOrganizationRoles, setFilteredUserOrganizationRoles] = React.useState<UserOrganizationWithRole[]>(
    []
  );

  const userInitials = getUserInitials(userInfo?.getDetails().firstName ?? "", userInfo?.getDetails().lastName ?? "");

  const userFullName = getUserFullName(userInfo?.getDetails().firstName ?? "", userInfo?.getDetails().lastName ?? "");

  const handleOrganizationRoleSelect = async (selectedOrganizationId: string): Promise<void> => {
    if (selectedOrganizationId) {
      try {
        navigate(getPath(Paths.ROOT), { state: { from: pathname } });
        await changeRealmAccess(selectedOrganizationId);
        BackendFactory.resetAllBackendCaches();
        handleClose();
        showSnackbar(translations.accountRealmSelector.texts.realmChanged(), SeverityType.Success);
      } catch {
        showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
      }
    }
  };

  const handleOrganizationRoleSearch = (searchString: string): void => {
    let filteredOrganizationRoles: UserOrganizationWithRole[] = [];
    if (searchString) {
      filteredOrganizationRoles = userOrganizationRoles.filter((userOrganizationRole) =>
        userOrganizationRole.fullName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    }

    setFilteredUserOrganizationRoles(filteredOrganizationRoles.sort((a, b) => a.fullName.localeCompare(b.fullName)));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      data-testid={TestIds.RealmAccessSelectorDialog.Dialog}
    >
      <DialogTitle variant="h5">
        {translations.accountRealmSelector.texts.title()}
        <IconButton
          data-testid={TestIds.RealmAccessSelectorDialog.CloseButton}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: theme.palette.primary.main }}
            data-testid={TestIds.RealmAccessSelectorDialog.HeaderAvatar}
          >
            {userInitials ? userInitials : undefined}
          </Avatar>
        }
        title={userFullName}
        subheader={userInfo?.getEmail()}
        sx={{ pl: 2 }}
        data-testid={TestIds.RealmAccessSelectorDialog.Header}
      />
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {isChangingRealm ? (
          <Box sx={{ mb: 3 }}>
            <Loading height={32} />
          </Box>
        ) : userOrganizationRoles.length < 6 ? (
          <RealmAccessSelectorDialogList
            isLoading={isChangingRealm}
            currentOrganizationId={currentOrganization?.getId() ?? ""}
            organizationRoles={userOrganizationRoles}
            handleSelect={handleOrganizationRoleSelect}
          />
        ) : (
          <RealmAccessSelectorDialogSearch
            isLoading={isChangingRealm}
            currentOrganizationId={currentOrganization?.getId() ?? ""}
            filteredOrganizationRoles={filteredUserOrganizationRoles}
            handleSearch={handleOrganizationRoleSearch}
            handleSelect={handleOrganizationRoleSelect}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RealmAccessSelectorDialog;
