/* Copyright */
import * as React from "react";
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Button,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { translations } from "../../generated/translationHelper";
import CustomTextField from "../common/textfield";
import PasswordRequirements from "../common/password-requirements";
import TestIds from "../../test-ids/test-ids";

type RegistrationProps = {
  isLoading: boolean;
  registrationInfo: {
    firstName: string;
    lastName: string;
    userName: string;
    country: string;
    countryCode: string;
    phoneNumber: string;
  };
  isRndUser: boolean;
  passwordError: string;
  phoneNumberError: string;
  acceptTermsAndConditions: boolean;
  isButtonDisabled: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitClick: () => void;
};

const RegistrationForm: React.FC<RegistrationProps> = ({
  isLoading,
  registrationInfo,
  isRndUser,
  passwordError,
  phoneNumberError,
  acceptTermsAndConditions,
  isButtonDisabled,
  handleInputChange,
  handleSelectChange,
  handleCheckboxChange,
  handleSubmitClick,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.registration.texts.title()}
      </Typography>
      <Typography variant="body1" className="title">
        {translations.registration.texts.subtitle()}
      </Typography>
      <CustomTextField
        label={translations.common.inputs.firstName()}
        id="firstName"
        value={registrationInfo.firstName}
        onChange={handleInputChange}
        errorMessage=""
        testId={TestIds.Registration.FirstName}
        className="input"
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleSubmitClick();
        }}
        autoComplete="given-name"
      />
      <CustomTextField
        label={translations.common.inputs.lastName()}
        id="lastName"
        value={registrationInfo.lastName}
        onChange={handleInputChange}
        errorMessage=""
        testId={TestIds.Registration.LastName}
        className="input"
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleSubmitClick();
        }}
        autoComplete="family-name"
      />
      <CustomTextField
        label={translations.common.inputs.email()}
        id="userName"
        value={registrationInfo.userName}
        errorMessage=""
        testId={TestIds.Registration.Email}
        className="input"
        autoComplete="username"
        disabled
      />
      <CustomTextField
        label={translations.common.inputs.newPassword()}
        id="newPassword"
        type={showPassword ? "text" : "password"}
        onChange={handleInputChange}
        errorMessage={passwordError}
        testId={TestIds.NewPasswordForm.CreateNewPassword}
        className="input"
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleSubmitClick();
        }}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomTextField
        label={translations.registration.inputs.confirmNewPassword()}
        id="confirmNewPassword"
        type={showPassword ? "text" : "password"}
        onChange={handleInputChange}
        errorMessage={passwordError}
        testId={TestIds.NewPasswordForm.ConfirmNewPassword}
        className="input"
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleSubmitClick();
        }}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <PasswordRequirements />
      <FormControl className="input" sx={{ textAlign: "start", mt: 3 }}>
        <InputLabel id="country-label" shrink>
          {translations.common.inputs.country()}
        </InputLabel>
        <Select
          name="country"
          size="small"
          onChange={handleSelectChange}
          labelId="country-label"
          value={registrationInfo.country}
          data-testid={TestIds.Registration.OpenCountrySelection}
          inputProps={{ "data-testid": TestIds.Registration.Country }}
        >
          <MenuItem value="United States" data-testid={TestIds.Registration.CountryOptionUSA}>
            {translations.common.texts.unitedStates()}
          </MenuItem>
          <MenuItem value="Canada" data-testid={TestIds.Registration.CountryOptionCanada}>
            {translations.common.texts.canada()}
          </MenuItem>
        </Select>
      </FormControl>
      <Box className="phone-number-container">
        <FormControl className="country-code" sx={{ textAlign: "start" }}>
          <InputLabel id="country-code-label" shrink></InputLabel>
          <Select
            name="countryCode"
            size="small"
            labelId="country-code-label"
            value={registrationInfo.countryCode}
            onChange={handleSelectChange}
          >
            <MenuItem value="+1">+1</MenuItem>
            {isRndUser && <MenuItem value="+358">+358</MenuItem>}
          </Select>
        </FormControl>
        <FormControl className="phone-number">
          <InputLabel id="phone-number-label" shrink>
            {translations.common.inputs.phone()}
          </InputLabel>
          <TextField
            id="phoneNumber"
            className="input"
            inputProps={{ "data-testid": TestIds.Registration.Number }}
            value={registrationInfo.phoneNumber}
            error={phoneNumberError.length > 0}
            helperText={phoneNumberError.length > 0 && phoneNumberError}
            onChange={handleInputChange}
            onKeyDown={(e): void => {
              if (e.key === "Enter" && !isButtonDisabled) handleSubmitClick();
            }}
          ></TextField>
        </FormControl>
      </Box>
      <FormControl sx={{ ml: "16px" }}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid={TestIds.Registration.AcceptTermsAndConditions}
              checked={acceptTermsAndConditions}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <Box sx={{ display: "flex", textAlign: "start" }}>
              <Typography variant="body2">
                {translations.registration.inputs.termsAndConditions1()}{" "}
                <Link href="#" underline="hover" variant="body2" className="link" sx={{ ml: "2px", mr: "2px" }}>
                  {translations.registration.inputs.termsAndConditions2()}
                </Link>
                {translations.registration.inputs.termsAndConditions3()}
              </Typography>
            </Box>
          }
        />
      </FormControl>
      <Button
        data-testid={TestIds.Registration.Submit}
        size="large"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={handleSubmitClick}
        className={"sparse-button"}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
      >
        {translations.common.buttons.submit()}
      </Button>
    </React.Fragment>
  );
};

export default RegistrationForm;
