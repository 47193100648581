/* Copyright */
import { Box, useTheme } from "@mui/material";
import { LocationMethod, Maybe, Nullable } from "@sade/data-access";
import * as React from "react";
import InProgressCallIcon from "../../../../assets/icons/MapPin-InProgressCall.svg";
import LatestNetworkFixIcon from "../../../../assets/icons/MapPin-LatestFix-Network.svg";
import LatestSatelliteFixIcon from "../../../../assets/icons/MapPin-LatestFix-Satellite.svg";
import LatestWifiFixIcon from "../../../../assets/icons/MapPin-LatestFix-WiFi.svg";
import NetworkFixIcon from "../../../../assets/icons/MapPin-PastLocation-NetworkFix.svg";
import SatelliteFixIcon from "../../../../assets/icons/MapPin-PastLocation-SatelliteFix.svg";
import WifiFixIcon from "../../../../assets/icons/MapPin-PastLocation-WifiFix.svg";
import { DeviceEvent } from "../../../../utils/eventUtils";
import { customMapStyle } from "../../../../utils/mapUtils";
import EventsMapNoLocationDataOverlay from "../../../common/events-map-no-location-data-overlay";
import { EventDetailsDialogType } from "../../event-details/event-details-dialog";
import EventsMapDefinitionOverlay from "./events-map-definition-overlay";

type EventsMapProps = {
  groupsToShow?: Map<number, DeviceEvent[]>;
  onOpenEventDetailsDialog: (eventId: string, dialogType: EventDetailsDialogType) => void;
};

const HEIGHT_OFFSET = 220;
const WIDTH_OFFSET = 500;
const MINIMUM_MAP_WIDTH = "520px";

const DEFAULT_CENTER = { lat: 40.840211, lng: -97.497453 };
const DEFAULT_ZOOM = 4;

const EventsMap: React.FC<EventsMapProps> = ({ groupsToShow, onOpenEventDetailsDialog }) => {
  const theme = useTheme();

  const mapRef = React.useRef<Nullable<google.maps.Map>>(null);
  const markersRef = React.useRef<Nullable<google.maps.Marker[]>>([]);
  const polyLine = React.useRef<Nullable<google.maps.Polyline>>(null);
  const ref = React.useRef<Nullable<HTMLDivElement>>(null);
  const zoomRef = React.useRef<number>();
  const mapZoomListener = React.useRef<Nullable<google.maps.MapsEventListener>>(null);

  const [viewWidth, setViewWidth] = React.useState<number>(window.innerWidth - WIDTH_OFFSET);
  const [viewHeight, setViewHeight] = React.useState<number>(window.innerHeight - HEIGHT_OFFSET);

  const resolveMapIcon = (event: DeviceEvent): string | undefined => {
    switch (true) {
      case event.isOnGoingCall:
        return InProgressCallIcon;
      case event.method === LocationMethod.Satellite:
        return event.isLatest ? LatestSatelliteFixIcon : SatelliteFixIcon;
      case event.method === LocationMethod.WiFi:
        return event.isLatest ? LatestWifiFixIcon : WifiFixIcon;
      case event.method === LocationMethod.Network:
        return event.isLatest ? LatestNetworkFixIcon : NetworkFixIcon;
      default: {
        return undefined;
      }
    }
  };

  const addEventMarkers = React.useCallback(
    (eventGroups: Maybe<Map<number, DeviceEvent[]>>) => {
      const newMarkerLocations: google.maps.LatLng[] = [];
      if (!eventGroups?.size) return;

      const allEvents = Array.from(eventGroups?.values()).flat();
      if (allEvents.length < (markersRef.current?.length ?? 0)) {
        markersRef.current?.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
      }

      allEvents.forEach((event, index) => {
        const { lat, lng } = event;
        if (lat == null || lng == null) {
          return;
        }

        const position = new google.maps.LatLng({ lat, lng });
        let marker = index < (markersRef.current?.length ?? 0) ? markersRef.current?.[index] : undefined;
        if (marker) {
          marker.set("position", position);
          marker.set("icon", resolveMapIcon(event));
        } else {
          marker = new google.maps.Marker({
            position,
            icon: resolveMapIcon(event),
            map: mapRef.current!,
          });
          markersRef.current?.push(marker);
        }

        marker.addListener("click", () => {
          onOpenEventDetailsDialog(event.id, "simple");
        });

        newMarkerLocations.push(position);
      });

      const center = markersRef.current?.length ? markersRef.current[0].getPosition() : undefined;
      mapRef.current!.setCenter(center ? center : DEFAULT_CENTER);
      mapRef.current!.setZoom(center ? zoomRef.current ?? 16 : DEFAULT_ZOOM);

      if (newMarkerLocations.length > 1) {
        polyLine.current
          ? polyLine.current.setPath(newMarkerLocations)
          : (polyLine.current = new google.maps.Polyline({
              path: newMarkerLocations,
              geodesic: true,
              strokeColor: theme.palette.primary.main,
              strokeOpacity: 1.0,
              strokeWeight: 2,
              map: mapRef.current!,
            }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme.palette.primary.main]
  );

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    mapRef.current = new google.maps.Map(ref.current, {
      center: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
      streetViewControl: false,
      fullscreenControl: false,
      styles: customMapStyle,
    });

    mapZoomListener.current = mapRef.current.addListener("zoom_changed", () => {
      if (markersRef.current?.length) {
        zoomRef.current = mapRef.current?.getZoom();
      }
    });

    const handleResize = (): void => {
      setViewWidth(window.innerWidth - WIDTH_OFFSET);
      setViewHeight(window.innerHeight - HEIGHT_OFFSET);
    };

    window.addEventListener("resize", () => handleResize());

    return (): void => {
      mapZoomListener.current?.remove();
      window.removeEventListener("resize", () => handleResize());
    };
  }, []);

  React.useEffect(() => {
    addEventMarkers(groupsToShow);
  }, [groupsToShow, addEventMarkers]);

  const locationInfoAvailable =
    groupsToShow?.size &&
    Array.from(groupsToShow?.values()).some((events) => events.some((event: DeviceEvent) => event.lat && event.lng));

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        ref={ref}
        sx={{
          width: viewWidth,
          minWidth: MINIMUM_MAP_WIDTH,
          height: viewHeight,
          borderRadius: 1,
        }}
      />
      <Box sx={{ left: 0, bottom: 0, position: "absolute", zIndex: 1000, mb: 2, ml: 2 }}>
        <EventsMapDefinitionOverlay />
      </Box>

      {groupsToShow?.size === 0 && !locationInfoAvailable && <EventsMapNoLocationDataOverlay />}
    </Box>
  );
};

export default EventsMap;
