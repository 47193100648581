/* Copyright */
import { InputLabel, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { wrapWordFromCharacter } from "../../utils/wrapWordFromCharacter";

type TableDataRowProps = {
  label: string;
  value: string;
  testId?: string;
  maxLength?: number;
};

const TableDataRow: React.FC<TableDataRowProps> = ({ label, value, testId, maxLength }) => {
  const formatValue = (value: string): string[] => {
    const result = [];
    if (maxLength) {
      for (let i = 0; i < value.length; i += maxLength) {
        result.push(value.substr(i, maxLength));
      }
    } else result.push(value);

    return result;
  };

  const values = formatValue(value);

  return (
    <TableRow data-testid={testId}>
      <TableCell>
        <InputLabel sx={{ fontSize: "12px" }}>{label}</InputLabel>
      </TableCell>
      <TableCell sx={{ textAlign: "end" }}>
        {values.map((value) => (
          <Typography key={value} variant="body2">
            {wrapWordFromCharacter(value, ".")}
          </Typography>
        ))}
      </TableCell>
    </TableRow>
  );
};

export default TableDataRow;
