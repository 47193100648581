/* Copyright */
import * as React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Maybe } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { UserStatus, UserStatusNames } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";
import { DeleteForever, PersonAddOutlined, PersonOffOutlined, PersonOutlined } from "@mui/icons-material";

type AccountDetailsDialogFooterProps = {
  isSaveChangesDisabled: boolean;
  isLoading: boolean;
  isSaving: boolean;
  userStatus: Maybe<UserStatus>;
  everThere: boolean;
  handleSaveChanges: () => void;
  handleResendInvitation: () => void;
  handleOpenReEnableAccountDialog: () => void;
  handleOpenDisableAccountDialog: () => void;
  handleOpenDeleteAccountDialog: () => void;
  handleCloseClick: () => void;
};

const AccountDetailsDialogFooter: React.FC<AccountDetailsDialogFooterProps> = ({
  isSaveChangesDisabled,
  isLoading,
  isSaving,
  userStatus,
  everThere,
  handleSaveChanges,
  handleResendInvitation,
  handleOpenReEnableAccountDialog,
  handleOpenDisableAccountDialog,
  handleOpenDeleteAccountDialog,
  handleCloseClick,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        {!everThere && (
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            disabled={isSaveChangesDisabled}
            onClick={handleSaveChanges}
            startIcon={isSaving && <CircularProgress size={16} color="secondary" />}
            data-testid={TestIds.AccountDetailsDialog.SaveChanges}
          >
            {translations.common.buttons.saveChanges()}
          </Button>
        )}
        {!everThere && (userStatus?.name === UserStatusNames.PENDING || userStatus?.name === UserStatusNames.UNKNOWN) && (
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={handleResendInvitation}
            disabled={isLoading}
            data-testid={TestIds.AccountDetailsDialog.ResendInvitationButton}
            startIcon={<PersonAddOutlined />}
          >
            {translations.accountDetailsDialog.buttons.resendInvitation()}
          </Button>
        )}
        <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.AccountDetailsDialog.CancelButton}>
          {everThere ? translations.common.buttons.close() : translations.common.buttons.cancel()}
        </Button>
      </Box>
      <Box>
        {userStatus?.name === UserStatusNames.DISABLED ? (
          <Button
            variant="contained"
            color="success"
            onClick={handleOpenReEnableAccountDialog}
            disabled={isLoading}
            sx={{ mr: 2 }}
            data-testid={TestIds.AccountDetailsDialog.EnableButton}
            startIcon={<PersonOutlined />}
          >
            {translations.common.texts.enableAccount()}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            onClick={handleOpenDisableAccountDialog}
            disabled={isLoading}
            sx={{ mr: 2 }}
            data-testid={TestIds.AccountDetailsDialog.DisableButton}
            startIcon={<PersonOffOutlined />}
          >
            {translations.common.texts.disableAccount()}
          </Button>
        )}
        {!everThere && (
          <Button
            variant="outlined"
            color="error"
            onClick={handleOpenDeleteAccountDialog}
            data-testid={TestIds.AccountDetailsDialog.DeleteButton}
            startIcon={<DeleteForever />}
          >
            {translations.common.texts.deleteAccount()}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AccountDetailsDialogFooter;
