/* Copyright */
export const HEX_PREFIX = "hex:";
// Attributes in IoT core need to match this regex
const DEVICE_ATTRIBUTE_REGEX = /^[a-zA-Z0-9_.,@/:#-]*$/;
export function isDeviceAttributeValid(value) {
    return DEVICE_ATTRIBUTE_REGEX.test(value);
}
export function toHex(value) {
    return HEX_PREFIX + Buffer.from(value).toString("hex");
}
export function fromHex(value) {
    return Buffer.from(value.slice(HEX_PREFIX.length), "hex").toString();
}
export function isHex(value) {
    return value.startsWith(HEX_PREFIX);
}
