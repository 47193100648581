/* Copyright */
import * as React from "react";
import { Box, Grid } from "@mui/material";
import MainAppBar from "../appbar/main-appbar";
import Footer from "../footer/footer";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box className="layout-root">
      <MainAppBar />
      <Grid container className="layout-content-container">
        {children}
      </Grid>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
