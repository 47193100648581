/* Copyright */

import { Chip, TableCell, TableRow } from "@mui/material";
import { MailboxMessage } from "@sade/data-access";
import React from "react";
import TestIds from "../../../../test-ids/test-ids";
import { resolveMailboxMessageLabel, resolveMailboxMessageStateIconColor } from "../../../../utils/mailboxUtils";
import { formatTimestamp } from "../../../../utils/utils";

type MailboxTableRowProps = {
  message: MailboxMessage;
  tableRowIndex?: number;
};

const MailboxTableRow: React.FC<MailboxTableRowProps> = ({ message, tableRowIndex }) => {
  return (
    <TableRow sx={{ cursor: "pointer" }} data-testid={TestIds.DeviceMailboxDialog.TableRow} hover>
      <TableCell data-testid={`${TestIds.DeviceMailboxDialog.TableColumnCreated}-${tableRowIndex}`}>
        {formatTimestamp(message.createdTimestamp)}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceMailboxDialog.TableColumnTopic}-${tableRowIndex}`}>
        {message.actionType}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceMailboxDialog.TableColumnRead}-${tableRowIndex}`}>
        {message.readTimestamp ? formatTimestamp(message.readTimestamp) : "-"}
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceMailboxDialog.TableColumnStatus}-${tableRowIndex}`}>
        <Chip
          size="small"
          label={resolveMailboxMessageLabel(message)}
          sx={{
            backgroundColor: resolveMailboxMessageStateIconColor(message),
            color: message.readTimestamp ? "white" : "black",
          }}
        />
      </TableCell>
      <TableCell data-testid={`${TestIds.DeviceMailboxDialog.TableColumnBody}-${tableRowIndex}`}>
        {message.body}
      </TableCell>
    </TableRow>
  );
};

export default MailboxTableRow;
