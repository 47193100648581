/* Copyright */
import { Box, Chip } from "@mui/material";
import { LocationMethod } from "@sade/data-access";
import * as React from "react";
import theme from "../../../../app/theme";
import { translations } from "../../../../generated/translationHelper";
import { SelectedEventFixType } from "../../../../utils/eventUtils";

type SelectingFixButtonsProps = {
  selectedFixes: SelectedEventFixType;
  handleChangeSelectedFix: (fix: string) => void;
};

const SelectingFixButtons: React.FC<SelectingFixButtonsProps> = ({ selectedFixes, handleChangeSelectedFix }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", mt: 2, mr: 2 }}>
      <Chip
        size="medium"
        label={translations.events.texts.satelliteFix()}
        onClick={(): void => handleChangeSelectedFix(LocationMethod.Satellite)}
        sx={{
          mr: 2,
          backgroundColor: selectedFixes.satellite ? theme.palette.primary.light : "",
        }}
      />
      <Chip
        size="medium"
        label={translations.events.texts.wifiFix()}
        onClick={(): void => handleChangeSelectedFix(LocationMethod.WiFi)}
        sx={{
          mr: 2,
          backgroundColor: selectedFixes.wifi ? theme.palette.purple.dark : "",
        }}
      />
      <Chip
        size="medium"
        label={translations.events.texts.networkFix()}
        onClick={(): void => handleChangeSelectedFix(LocationMethod.Network)}
        sx={{
          backgroundColor: selectedFixes.network ? theme.palette.orange.dark : "",
        }}
      />
    </Box>
  );
};

export default SelectingFixButtons;
