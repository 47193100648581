/* Copyright */
import { Divider, ListItem, ListItemIcon, ListItemText, SvgIconProps, Typography } from "@mui/material";
import * as React from "react";

import theme from "../../../app/theme";
import { eventLevel } from "../../../utils/eventUtils";
import { formatTimeFromTimestamp } from "../../../utils/utils";
import { EventDetailsDialogType } from "../event-details/event-details-dialog";

type EventsListItemProps = {
  icon?: React.ElementType<SvgIconProps>;
  id: string;
  eventName: string;
  eventTime: Date;
  level: eventLevel;
  index: number;
  onOpenEventDetailsDialog: (eventId: string, dialogType: EventDetailsDialogType) => void;
};

const EventsListItem: React.FC<EventsListItemProps> = ({
  icon: Icon,
  id,
  level,
  eventName,
  eventTime,
  index,
  onOpenEventDetailsDialog,
}) => {
  return (
    <React.Fragment>
      <ListItem
        key={`${eventName}-${index}`}
        onClick={(): void => onOpenEventDetailsDialog(id, "detailed")}
        dense
        sx={{
          cursor: "pointer",
          bgcolor:
            level === eventLevel.ALERT
              ? theme.palette.error.light
              : level === eventLevel.WARNING
              ? theme.palette.orange.light
              : "",
        }}
      >
        <ListItemIcon>
          {Icon && (
            <Icon
              sx={{
                color:
                  level === eventLevel.ALERT
                    ? theme.palette.error.main
                    : level === eventLevel.WARNING
                    ? theme.palette.warning.dark
                    : "",
              }}
            />
          )}
        </ListItemIcon>
        <ListItemText primary={eventName} />
        <Typography variant="body2">{formatTimeFromTimestamp(eventTime, true)}</Typography>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

export default EventsListItem;
