/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { OtaManager } from "@sade/data-access";
import { OtaPackageInfo } from "@sade/data-access/src/generated/gqlDevice";
import * as React from "react";
import theme from "../../../../app/theme";
import { SeverityType } from "../../../../context/snackbar-context";
import { translations } from "../../../../generated/translationHelper";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import TestIds from "../../../../test-ids/test-ids";

type DeviceActionDialogProps = {
  open: boolean;
  deviceId: string;
  onClose: () => void;
};

const DeviceOtaUpdateDialog: React.FC<DeviceActionDialogProps> = ({ open, deviceId, onClose }) => {
  const [selectedOtaPackageId, setSelectedOtaPackageId] = React.useState<string>("");
  const [otaPackages, setOtaPackages] = React.useState<OtaPackageInfo[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const fetchOtaPackagesData = React.useCallback(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const result = await OtaManager.getInstance().getOtaPackages();
        setOtaPackages(result);
      } catch (error) {
        setOtaPackages([]);
      } finally {
        setIsLoading(false);
      }
    };

    return fetchData();
  }, []);

  React.useEffect(() => {
    if (open) fetchOtaPackagesData();
  }, [open, fetchOtaPackagesData]);

  const handleCloseClick = (): void => {
    onClose();
  };

  const handleUpdateClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await OtaManager.getInstance().triggerLibrisDeviceOtaUpdate([deviceId], selectedOtaPackageId);
      showSnackbar(translations.otaUpdateDialog.texts.successText(), SeverityType.Success);
      onClose();
    } catch (error) {
      let errorCode = "";
      if (error instanceof Error) {
        errorCode = error.message;
      }
      showSnackbar(
        translations.otaUpdateDialog.texts.updateFail(),
        SeverityType.Error,
        undefined,
        undefined,
        errorCode
      );
    } finally {
      setIsLoading(false);
    }
    onClose();
  };

  const handleOtaPackageChange = (event: SelectChangeEvent): void => {
    setSelectedOtaPackageId(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.otaUpdateDialog.texts.title({ deviceId })}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.DeviceOtaUpdateDialog.CloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography sx={{ textAlign: "start", marginBottom: "2rem" }}>
          {translations.otaUpdateDialog.texts.info({ deviceId })}
        </Typography>
        <FormControl className="input" sx={{ textAlign: "start" }} fullWidth>
          <InputLabel id="ota-package-select-label" shrink>
            {translations.sendBulkOtasDialog.texts.otaPackage()}
          </InputLabel>
          <Select
            id="ota-package-select"
            size="small"
            onChange={handleOtaPackageChange}
            labelId="ota-package-select-label"
            disabled={isLoading}
            value={selectedOtaPackageId}
            data-testid={TestIds.DeviceOtaUpdateDialog.OpenOtaPackageSelection}
          >
            {otaPackages?.map((otaPackage) => (
              <MenuItem key={otaPackage.otaId} value={otaPackage.otaId}>
                {otaPackage.otaPackageName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          color={"primary"}
          disabled={isLoading || !selectedOtaPackageId}
          sx={{ color: theme.palette.common.white }}
          onClick={handleUpdateClick}
          data-testid={TestIds.DeviceOtaUpdateDialog.UpdateButton}
          startIcon={<UpdateIcon />}
        >
          {translations.common.texts.update()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleCloseClick}
          sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
          data-testid={TestIds.DeviceOtaUpdateDialog.CloseButton}
        >
          {translations.common.buttons.close()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceOtaUpdateDialog;
