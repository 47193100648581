/** Copyright */

const GOOGLE_GEOCODE_API = "https://maps.googleapis.com/maps/api/geocode";

export type GeoLocation = {
  latitude?: number;
  longitude?: number;
};

/**
 * This method uses Google Geocode API to get the latitude and longitude of the given address
 * @link https://developers.google.com/maps/documentation/geocoding/start
 * @param address Address that needs to be geocoded to get the latitude and longitude
 * @returns GeoLocation object with latitude and longitude
 */
export const geocodeAddress = async (address: string): Promise<GeoLocation> => {
  try {
    const apiKey = process.env.REACT_APP_GEOCODE_API_KEY;
    if (!apiKey) throw new Error("Google Geocode API key is not available.");
    const response = await fetch(`${GOOGLE_GEOCODE_API}/json?key=${apiKey}&address=${address}`);
    const json = await response.json();
    const { status, error_message } = json;

    if (status === "OK") {
      return {
        latitude: json.results[0].geometry.location.lat,
        longitude: json.results[0].geometry.location.lng,
      };
    }

    throw new Error(`Geocoding failed: ${error_message}. Server returned status code ${status}.`);
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Geocoding request failed: ${error.message}`);
    }
    throw new Error(`Geocoding request failed with unknown error: ${error}`);
  }
};
