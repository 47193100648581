/* Copyright */
import * as React from "react";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { getPath, getSsoPath } from "../../utils/ssoPathUtil";
import { Paths } from "../../utils/utils";
import CleanLayout from "../layout/CleanLayout";
import Layout from "../layout/Layout";
import Loading from "../loading/loading";

const PrivateRoute: React.FC<{ component: React.ComponentType; clean?: boolean }> = ({ component, clean = false }) => {
  const { isLoadingAuthenticatedUser, isChangingRealm, authenticatedUser, getUserData } = useAuthenticatedUser();
  const [initializing, setInitializing] = React.useState(true);
  const { sso } = useParams();
  // Need to test if user with challengeName "NEW_PASSWORD_REQUIRED" can access these protected routes
  // or will AuthWrapper.isCurrentUserAuthenticated handle those.
  // Just in case someone tries to access with direct url...

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        // make sure user data is loaded in the authenticated user context before proceeding to the private routes
        await getUserData();
      } finally {
        setInitializing(false);
      }
    })();
  }, [getUserData]);

  if (initializing || isLoadingAuthenticatedUser || isChangingRealm) {
    return <Loading />;
  }

  if (authenticatedUser) {
    const ssoPath = getSsoPath();
    // if url does not match what we got from token, redirect to the correct url
    if (ssoPath?.provider !== sso) {
      const destination = sso ? location.pathname.replace(`/sso/${sso}/`, "") : location.pathname.replace("/", "");
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, path] = Object.entries(Paths).find(([, value]) => value === destination) ?? [];
      return <Navigate replace to={path ? getPath(path) : getPath(Paths.ROOT)} />;
    }
    return clean ? (
      <CleanLayout>{React.createElement(component)}</CleanLayout>
    ) : (
      <Layout>{React.createElement(component)}</Layout>
    );
  } else {
    return <Navigate replace to={getPath(Paths.LOGIN)} />;
  }
};

export default PrivateRoute;
