/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { validate as isUuid } from "uuid";
import { DevicesActionRequestDocument, DevicesDeleteDocument, DevicesEncryptDocument, DevicesMailboxMessagesListDocument, DevicesMailboxMessagesPurgeDocument, DevicesOrganizationSetDocument, DevicesPeripheralAddDocument, DevicesPeripheralRemoveDocument, DevicesPeripheralsListDocument, DevicesResetDocument, DevicesRetireDocument, DevicesStatesGetDocument, DevicesUpdateDocument, } from "../../generated/gqlDevice";
import { AuthWrapper } from "../auth";
import { narrowDownAttributeTypes } from "../backend/AWSBackend";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { fromHex, isHex } from "../private-utils/hex";
import { throwGQLError } from "../private-utils/throwGQLError";
import { Device, DeviceAttributeName, } from "./Device";
import { ShadowSubscriptionManager } from "./ShadowSubscriptionManager";
/**
 * Base-class for typed AWS Thing implementations. Do not create this directly.
 *
 * This class is no longer abstract since TypeScript does not allow for run-time type comparison against an
 * abstract class (since abstract things do not exist in TypeScript at run-time).
 */
export class AWSThing extends Device {
    /*
     * DO NOT CALL DIRECTLY
     *
     * This constructor needs to be public so {@link EntityRelationCache} can use it for type checks.
     */
    constructor(type, backend, params) {
        super();
        this.type = type;
        this.backend = backend;
        this.stateListener = {
            getId: () => {
                return this.getId();
            },
            onState: (timestamp, version, current, next, connectionState) => {
                this.setState(timestamp, version, current, next, connectionState);
            },
        };
        this.deviceId = params.deviceId;
        this.attributes = params.attributes;
    }
    static instanceOf(value) {
        return value instanceof AWSThing;
    }
    getAttribute(key) {
        var _a, _b;
        const value = (_b = (_a = this.attributes) === null || _a === void 0 ? void 0 : _a.find((attribute) => attribute.key === key)) === null || _b === void 0 ? void 0 : _b.value;
        return value ? (isHex(value) ? fromHex(value) : value) : undefined;
    }
    getAttributes() {
        var _a;
        return (_a = this.attributes) !== null && _a !== void 0 ? _a : [];
    }
    getDeviceStatus() {
        const status = this.getAttribute(DeviceAttributeName.status);
        if (!status)
            return;
        return status;
    }
    /*
     * Customization:
     * Current implementation returns all receivers from canSee field
     * which user is allowed use.
     * Modify this if access needs different limits.
     */
    async getReceivers() {
        var _a, _b;
        const canSee = this.getAttribute("canSee");
        const deviceReceivers = canSee === null || canSee === void 0 ? void 0 : canSee.slice(1, canSee.length - 1).split(",").map((value) => (value.endsWith(":") ? value.slice(0, value.length - 1) : value)).filter((value) => value.startsWith("ORG/") || isUuid(value));
        // filter receivers user has no access
        const userReceivers = (_a = (await AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.canSee;
        return ((_b = deviceReceivers === null || deviceReceivers === void 0 ? void 0 : deviceReceivers.filter((r) => userReceivers === null || userReceivers === void 0 ? void 0 : userReceivers.some((allowed) => allowed === r || r.startsWith(allowed)))) !== null && _b !== void 0 ? _b : []);
    }
    getId() {
        return this.deviceId;
    }
    getType() {
        return this.type;
    }
    getState() {
        return this.state;
    }
    getLatestEvent() {
        return this.latestEvent;
    }
    /**
     * Get device name.
     *
     * Name is stored in device attributes and is possible hex-encoded.
     * @returns Device name in UTF-8
     */
    getName() {
        // TODO: Could be removed, handled by generic getAttribute method
        const namePossiblyInHex = this.getAttribute(DeviceAttributeName.name);
        if (namePossiblyInHex) {
            return isHex(namePossiblyInHex) ? fromHex(namePossiblyInHex) : namePossiblyInHex;
        }
    }
    /**
     * Add observer for device state updates
     *
     * Customization:
     * Current implementation registers for all receivers device returns.
     * Modify this list if state access needs different limits.
     *
     * @param observer
     */
    // TODO: Consider moving this functionality inside DeviceState
    async addObserver(observer) {
        super.addObserver(observer);
        ShadowSubscriptionManager.instance.addListener(this.stateListener, await this.getReceivers());
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        ShadowSubscriptionManager.instance.removeListener(this.stateListener);
    }
    async init() {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const deviceStateResponse = await client.query(DevicesStatesGetDocument, {
            deviceId: this.deviceId,
        });
        const { desired, reported, timestamp, version, connectionState } = (_a = deviceStateResponse.data.devicesStatesGet) !== null && _a !== void 0 ? _a : {};
        this.state = this.createState(timestamp, version, reported ? JSON.parse(reported) : undefined, desired ? JSON.parse(desired) : undefined, connectionState
            ? { connected: connectionState.connected, updatedTimestamp: connectionState.updatedTimestamp }
            : undefined);
    }
    setState(timestamp, version, current, next, connectionState) {
        var _a, _b;
        if (version && ((_a = this.state) === null || _a === void 0 ? void 0 : _a.version) && version < this.state.version) {
            console.log("New state version " + version + " is older than " + ((_b = this.state) === null || _b === void 0 ? void 0 : _b.version));
            return;
        }
        // TODO: Consider implementing a method that just updates the existing state
        this.state = this.createState(timestamp, version, current, next, connectionState);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDeviceStateUpdated) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
    }
    setLatestEvent(event) {
        this.latestEvent = event;
    }
    async updateAttributes(attributes) {
        var _a, _b, _c;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.mutate(DevicesUpdateDocument, {
            deviceId: this.deviceId,
            attributes,
        });
        this.attributes = narrowDownAttributeTypes((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesUpdate) === null || _b === void 0 ? void 0 : _b.attr) !== null && _c !== void 0 ? _c : []);
    }
    async delete() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesDeleteDocument, { deviceId: this.deviceId });
    }
    async reset() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesResetDocument, { deviceIds: [this.deviceId] });
    }
    async retire() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesRetireDocument, { deviceIds: [this.deviceId] });
    }
    async listMailboxMessages() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesMailboxMessagesListDocument, { deviceId: this.deviceId });
        if (!response.data.devicesMailboxMessagesList) {
            throwGQLError(response, "Getting mailbox messages failed");
        }
        return response.data.devicesMailboxMessagesList.map((message) => (Object.assign(Object.assign({}, message), { createdTimestamp: new Date(message.createdTimestamp), readTimestamp: message.readTimestamp ? new Date(message.readTimestamp) : undefined, ackedTimestamp: message.ackedTimestamp ? new Date(message.ackedTimestamp) : undefined })));
    }
    async purgeMailboxMessages() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesMailboxMessagesPurgeDocument, { deviceIds: [this.deviceId] });
    }
    /**
     * Encrypt a message.
     *
     * Encryption API uses public key (RSA) encryption where the public key is derived from device certificate.
     * @param message Message to encrypt
     * @returns Encrypted message
     */
    async encrypt(message) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesEncryptDocument, { deviceId: this.deviceId, message });
        if (!response.data.devicesEncrypt) {
            throwGQLError(response, "Encryption failed");
        }
        return response.data.devicesEncrypt;
    }
    async listPeripherals() {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesPeripheralsListDocument, { deviceId: this.deviceId });
        if (!response.data.devicesPeripheralsList) {
            throwGQLError(response, "Getting device peripherals failed");
        }
        return response.data.devicesPeripheralsList;
    }
    async addPeripheral(peripheralType, serialNumber) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.mutate(DevicesPeripheralAddDocument, {
            deviceId: this.deviceId,
            peripheralType,
            peripheralSerialNumber: serialNumber,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesPeripheralAdd)) {
            throwGQLError(response, "Adding device peripheral failed");
        }
        return response.data.devicesPeripheralAdd;
    }
    async removePeripheral(peripheralType, serialNumber) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.mutate(DevicesPeripheralRemoveDocument, {
            deviceId: this.deviceId,
            peripheralType,
            peripheralSerialNumber: serialNumber,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesPeripheralRemove)) {
            throwGQLError(response, "Removing device peripheral failed");
        }
        return response.data.devicesPeripheralRemove;
    }
    async requestAction(action) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesActionRequestDocument, { deviceIds: [this.deviceId], action, sendWakeUpSMS: true });
    }
    async setOrganization(newOrganizationId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        await client.mutate(DevicesOrganizationSetDocument, {
            deviceIds: [this.deviceId],
            newOrganizationId,
        });
        // Update attribute list with new organization
        this.attributes = (_a = this.attributes) === null || _a === void 0 ? void 0 : _a.map((attribute) => {
            if (attribute.key === DeviceAttributeName.organization) {
                return Object.assign(Object.assign({}, attribute), { value: newOrganizationId });
            }
            return attribute;
        });
    }
    // OVERRIDE THIS
    createState(_timestamp, _version, _reported, _desired, _connectionState) {
        throw new Error("AWSThing MUST NOT be created directly");
    }
    // OVERRIDE THIS
    getIcon() {
        throw new Error("AWSThing MUST NOT be created directly");
    }
}
