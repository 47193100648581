/* Copyright*/
import { EventsAttachmentsListDocument, EventsCallAnswerDocument, EventsCallEndDocument, EventsCallReportDocument, } from "../../generated/gqlEvents";
import { AppSyncClientFactory, Service } from "../backend";
import { throwGQLError } from "../private-utils/throwGQLError";
import { Event } from "./Event";
export class AWSEvent extends Event {
    async endOngoingCallEvent() {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.mutate(EventsCallEndDocument, {
            eventId: this.id,
            patientId: this.patientId,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsCallEnd)) {
            throwGQLError(response, "Failed to end call event");
        }
        this.callEndedAt = response.data.eventsCallEnd.callEndedAt
            ? new Date(response.data.eventsCallEnd.callEndedAt)
            : undefined;
    }
    async answerCallEvent() {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.mutate(EventsCallAnswerDocument, {
            eventId: this.id,
            patientId: this.patientId,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsCallAnswer)) {
            throwGQLError(response, "Failed to answer call event");
        }
        this.callAnsweredAt = response.data.eventsCallAnswer.callAnsweredAt
            ? new Date(response.data.eventsCallAnswer.callAnsweredAt)
            : undefined;
        this.callAnsweredBy = response.data.eventsCallAnswer.callAnsweredBy;
    }
    async addCallEventReport(fallDetectionDisposition, report) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.mutate(EventsCallReportDocument, {
            eventId: this.id,
            patientId: this.patientId,
            fallDetectionDisposition,
            report,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsCallReport)) {
            throwGQLError(response, "Failed to add call event report");
        }
        this.fallDetectionDisposition = response.data.eventsCallReport.fallDetectionDisposition;
    }
    async getAttachments() {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.query(EventsAttachmentsListDocument, {
            eventId: this.id,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.eventsAttachmentsList)) {
            throwGQLError(response, "Failed to get event attachments");
        }
        return response.data.eventsAttachmentsList;
    }
}
