/* Copyright */
import { Box } from "@mui/material";
import {
  AWSLatestData,
  AWSLatestPatientData,
  BackendFactory,
  Device,
  DeviceAttributeName,
  DeviceOperatorPlan,
  DeviceStatus,
  LatestDataObserver,
  Maybe,
  Organization,
  OrganizationParameterHierarchy,
  RoleIdentifier,
} from "@sade/data-access";
import React from "react";
import { useParams } from "react-router-dom";
import { useEverThereContext } from "../../context/everThere-context";
import { SeverityType } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import { PatientInfo, getErrorMessage } from "../../utils/utils";
import AccessControl from "../access-control/access-control";
import DeviceViewContent from "./device-view-content/device-view-content";
import DeviceViewHeader from "./device-view-header/device-view-header";

const DeviceView: React.FC = () => {
  const { deviceId } = useParams();
  const { showSnackbar } = useSnackbar();
  const backend = BackendFactory.getBackend();
  const organizationBackend = BackendFactory.getOrganizationBackend();

  // States
  const [isLoadingDetails, setIsLoadingDetails] = React.useState<boolean>(false);
  const [device, setDevice] = React.useState<Device>();
  const [latestData, setLatestData] = React.useState<Maybe<AWSLatestData>>(undefined);
  const [deviceOrganization, setDeviceOrganization] = React.useState<Maybe<Organization>>(undefined);
  const [patient, setPatient] = React.useState<Maybe<PatientInfo>>(undefined);
  const [parameterHierarchy, setParameterHierarchy] = React.useState<Maybe<OrganizationParameterHierarchy>>();
  // A trigger for updating device state in "DeviceSettingsContent" component once "AdvancedSettingsDialog" has changed it
  const [deviceStateUpdateCount, setDeviceStateUpdateCount] = React.useState<number>(0);
  const [operatorPlans, setOperatorPlans] = React.useState<DeviceOperatorPlan[]>([]);

  // Ref for data that should not trigger re-render
  const latestPatientDataRef = React.useRef<Maybe<AWSLatestPatientData>>(undefined);

  const { isLoadingEverThere, getEverThereData } = useEverThereContext();
  const pollingTimeout = React.useRef<Maybe<NodeJS.Timeout>>();

  const latestDataObserver: LatestDataObserver = React.useMemo(() => {
    return {
      onLatestDataUpdate: (data: AWSLatestData): void => {
        setLatestData(data);
      },
    };
  }, []);

  const showStateSnackbar = (state?: DeviceStatus): void => {
    switch (state) {
      case DeviceStatus.activating:
        showSnackbar(
          translations.device.texts.deviceActivating(),
          SeverityType.Info,
          undefined,
          undefined,
          translations.device.texts.deviceActivatingMessage()
        );
        break;
      case DeviceStatus.deactivating:
        showSnackbar(
          translations.device.texts.deviceDeactivating(),
          SeverityType.Info,
          undefined,
          undefined,
          translations.device.texts.deviceDeactivatingMessage()
        );
        break;
      default:
    }
  };

  const getDeviceData = async (refresh?: boolean): Promise<void> => {
    if (!deviceId) return;

    setIsLoadingDetails(true);
    try {
      const fetchPatientData = async (patientId: string): Promise<void> => {
        const patientData = await organizationBackend.getPatient(patientId);
        if (!patientData) {
          return;
        }

        const latestPatientData = await patientData.getLatestData();
        setLatestData(latestPatientData?.getData());

        const patientName = ((patientData.getFirstName() ?? "") + " " + (patientData.getLastName() ?? "")).trim();
        setPatient({
          id: patientId,
          name: patientName.length ? patientName : translations.common.texts.unknown(),
          customerId: patientData?.getCustomerId(),
        });

        if (latestPatientDataRef.current?.isObservedBy(latestDataObserver)) {
          latestPatientDataRef.current?.removeObserver(latestDataObserver);
        }
        latestPatientDataRef.current = latestPatientData;
        latestPatientDataRef.current?.addObserver(latestDataObserver);
      };

      const fetchParametersHierarchy = async (device: Device): Promise<void> => {
        const organization = await organizationBackend.getOrganization(
          device.getAttribute(DeviceAttributeName.organization) ?? ""
        );

        setDeviceOrganization(organization);
        setParameterHierarchy(await organization?.getParameterHierarchy());
      };

      const fetchOperatorPlans = async (): Promise<void> => setOperatorPlans(await backend.getOperatorPlans());

      const device = await backend.getDevice(deviceId, refresh);
      if (!device) {
        throw new Error("Device not found");
      }
      setDevice(device);

      const patientId = device.getAttribute(DeviceAttributeName.patientId);
      await Promise.all([
        fetchOperatorPlans(),
        patientId && fetchPatientData(patientId),
        fetchParametersHierarchy(device),
      ]);

      const deviceStatus = device.getDeviceStatus();
      showStateSnackbar(deviceStatus);

      if (deviceStatus === DeviceStatus.activating || deviceStatus === DeviceStatus.deactivating) {
        if (!pollingTimeout.current) {
          pollingTimeout.current = setInterval(() => {
            getDeviceData(true);
          }, 60000);
        }
      } else {
        if (pollingTimeout.current) {
          clearInterval(pollingTimeout.current);
          pollingTimeout.current = undefined;
        }
      }
    } catch (error) {
      if (getErrorMessage(error) === "Device not found") {
        showSnackbar(translations.common.texts.deviceNotFound({ deviceId }), SeverityType.Error);
      } else {
        showSnackbar(translations.common.texts.unableToPerformAction(), SeverityType.Error);
      }
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const handleReload = (delay?: number): void => {
    if (delay) {
      setTimeout(() => {
        getDeviceData(true);
      }, delay);
    } else {
      getDeviceData(true);
    }
  };

  React.useEffect(() => {
    getDeviceData();
    return (): void => {
      latestPatientDataRef.current?.removeObserver(latestDataObserver);
      if (pollingTimeout.current) {
        clearInterval(pollingTimeout.current);
        pollingTimeout.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => patient && getEverThereData(patient.id), [patient, getEverThereData]);

  const isLoading = isLoadingDetails || isLoadingEverThere;

  // Pass the optional `device` state to the child components together with the `isLoadingState`
  // so that the child components can show the sceleton cards properly.
  return (
    <Box sx={{ padding: "2rem" }}>
      <AccessControl
        roles={[
          RoleIdentifier.PowerUser,
          RoleIdentifier.Supervisor,
          RoleIdentifier.Operator,
          RoleIdentifier.DealerManager,
          RoleIdentifier.DealerAgent,
          RoleIdentifier.WarehouseInstaller,
        ]}
      >
        <DeviceViewHeader
          isLoading={isLoading}
          deviceData={device}
          parameterHierarchy={parameterHierarchy}
          onReload={handleReload}
          onDeviceStateUpdate={(): void => setDeviceStateUpdateCount(deviceStateUpdateCount + 1)}
        />
        <DeviceViewContent
          isLoading={isLoading}
          isLoadingDetails={isLoadingDetails}
          device={device}
          latestData={latestData}
          deviceOrganization={deviceOrganization}
          patient={patient}
          parameterHierarchy={parameterHierarchy}
          deviceStateUpdateCount={deviceStateUpdateCount}
          onDetailsUpdated={getDeviceData}
          operatorPlans={operatorPlans}
        />
      </AccessControl>
    </Box>
  );
};

export default DeviceView;
