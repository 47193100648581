/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { BackendFactory, CreateTeamParameters, Device, DeviceAttributeName } from "@sade/data-access";
import * as React from "react";
import { useEverThereContext } from "../../../../../context/everThere-context";
import { SeverityType } from "../../../../../context/snackbar-context";
import { translations } from "../../../../../generated/translationHelper";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import TestIds from "../../../../../test-ids/test-ids";
import { LanguageMap } from "../../../../../utils/utils";
import { isValidEmail } from "../../../../../utils/validation";
import CustomTextField from "../../../../common/textfield";

type InviteNewEverThereUserDialogProps = {
  open: boolean;
  device?: Device;
  onClose: () => void;
};

const InviteNewEverThereUserDialog: React.FC<InviteNewEverThereUserDialogProps> = ({ open, device, onClose }) => {
  const backend = BackendFactory.getOrganizationBackend();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [inviteLanguage, setInviteLanguage] = React.useState<string>(LanguageMap.ENGLISH);
  const [emailError, setEmailError] = React.useState<string>("");
  const { showSnackbar } = useSnackbar();
  const { getEverThereData, everThereData } = useEverThereContext();

  const deviceId = device?.getId() ?? "";
  const members = everThereData?.getMembers() ?? [];
  const role = members.length === 0 ? translations.common.texts.teamManager() : translations.common.texts.teamMember();

  const handleCloseClick = (): void => {
    setEmail("");
    setInviteLanguage(LanguageMap.ENGLISH);
    setEmailError("");
    onClose();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailError("");
    setEmail(event.target.value);
  };

  const handleLanguageChange = (event: SelectChangeEvent): void => {
    setInviteLanguage(event.target.value);
  };

  const handleSubmitClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (isValidEmail(email)) {
        if (members.length === 0) {
          const params: CreateTeamParameters = {
            teamManagerEmail: email,
            inviteLanguage,
            deviceId,
          };
          await backend.createTeam(params);
          getEverThereData(device?.getAttribute(DeviceAttributeName.patientId) ?? "");
        } else {
          await everThereData?.addTeamMember(email, inviteLanguage);
        }
        showSnackbar(
          translations.common.texts.invitationSuccessHeader(),
          SeverityType.Success,
          undefined,
          undefined,
          translations.common.texts.invitationSuccessInfo({ email, role })
        );
        handleCloseClick();
      } else {
        setEmailError(translations.common.texts.emailFormatNotValid());
      }
    } catch (error) {
      console.error("error", error);
      showSnackbar(
        translations.common.texts.invitationFailedHeader(),
        SeverityType.Error,
        undefined,
        undefined,
        translations.common.texts.invitationFailedInfo({ email })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = !email || emailError.length > 0 || isLoading;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DeviceEverThereNewMemberDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.deviceEverThereInviteNewUserDialog.texts.title()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceEverThereNewMemberDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {translations.deviceEverThereInviteNewUserDialog.texts.subtitle()}
          <Box sx={{ mt: 4 }}>
            <CustomTextField
              label={translations.common.texts.email()}
              id="email"
              errorMessage={emailError}
              testId={TestIds.DeviceEverThereNewMemberDialog.EmailInput}
              onChange={handleEmailChange}
              placeholder={translations.addNewAccountDialog.inputs.emailPlaceholder()}
              onKeyDown={(e): void => {
                if (e.key === "Enter" && !isSubmitDisabled) handleSubmitClick();
              }}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel shrink>{translations.common.texts.role()}</InputLabel>
              <Typography variant="body1" sx={{ mt: 1, ml: 0.5 }}>
                {role}
              </Typography>
            </FormControl>
          </Box>
          <FormControl sx={{ mt: 4 }}>
            <InputLabel shrink>{translations.common.texts.language()}</InputLabel>
            <RadioGroup
              sx={{ mt: 1, ml: 2 }}
              id="language"
              value={inviteLanguage ?? ""}
              onChange={handleLanguageChange}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label={`${translations.common.texts.english()} (${translations.common.texts.unitedStates()})`}
              />
              <FormControlLabel
                value="fr"
                control={<Radio />}
                label={`${translations.common.texts.french()} (${translations.common.texts.canada()}) `}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmitClick}
            data-testid={TestIds.DeviceEverThereNewMemberDialog.SendInviteButton}
            disabled={isSubmitDisabled}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.sendInvite()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            data-testid={TestIds.DeviceEverThereNewMemberDialog.ActionCloseButton}
          >
            {translations.common.texts.close()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InviteNewEverThereUserDialog;
