/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import { EventReport } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import MissedFallReportForm from "./missed-fall-report-form";

type MissedFallReportDialogProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmitForm: (report: EventReport) => void;
};

const MissedFallReportDialog: React.FC<MissedFallReportDialogProps> = ({ open, isLoading, onClose, onSubmitForm }) => {
  const [reportData, setReportData] = React.useState<EventReport>({
    occurredAt: "",
    deviceWornType: "",
    fallExplanation: "",
    fallPosition: "",
    revocerType: "",
    fallCause: "",
    walkingAid: "",
    surface: "",
    remarks: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedReportData = { ...reportData, [event.target.id]: event.target.value };
    setReportData(updatedReportData);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const updatedReportData = { ...reportData, [event.target.name]: event.target.value };
    setReportData(updatedReportData);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth data-testid={TestIds.MissedFallReportDialog.Dialog}>
        <DialogTitle variant="h5">
          {translations.missedFallReport.texts.missedFallReportTitle()}
          <IconButton
            data-testid={TestIds.MissedFallReportDialog.CloseButton}
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MissedFallReportForm
            reportData={reportData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", ml: 2, mb: 2 }}>
          <Button
            variant="contained"
            onClick={(): void => onSubmitForm(reportData)}
            color="primary"
            disabled={isLoading}
            data-testid={TestIds.MissedFallReportDialog.SubmitButton}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.buttons.submit()}
          </Button>
          <Button variant="outlined" onClick={onClose} data-testid={TestIds.MissedFallReportDialog.ActionCancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MissedFallReportDialog;
