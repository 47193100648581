/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Event, EventAttachment, Maybe } from "@sade/data-access";
import * as React from "react";

import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { DeviceEvent, formatEvent } from "../../../utils/eventUtils";
import { formatTimestamp } from "../../../utils/utils";
import TableEmptyRow from "../../common/table-empty-row";
import DraggableComponent, { DRAGGABLE_DIALOG_HANDLE } from "../../dialogs/draggable-component";

export type EventDetailsDialogType = "detailed" | "simple";

type EventDetailsDialogProps = {
  open: boolean;
  event?: Event;
  dialogType: EventDetailsDialogType;
  handleClose: () => void;
};

const EventDetailsDialog: React.FC<EventDetailsDialogProps> = ({ open, handleClose, event, dialogType }) => {
  const [formattedEvent, setFormattedEvent] = React.useState<DeviceEvent>();
  const [attachments, setAttachments] = React.useState<Maybe<EventAttachment[]>>([]);

  const parameters: string = event?.metadata && JSON.parse(event?.metadata);

  React.useEffect(() => {
    const fetchAttachments = async (): Promise<void> => {
      const result = await event?.getAttachments();
      setAttachments(result);
    };

    fetchAttachments();
  }, [event]);

  React.useEffect(() => {
    if (event) {
      setFormattedEvent(formatEvent(event));
    }
  }, [event]);

  const subtitle = (): string => {
    if (formattedEvent?.eventTimestamp && formattedEvent?.address) {
      return `${formatTimestamp(formattedEvent.eventTimestamp)} | ${formattedEvent?.address}`;
    }
    if (formattedEvent?.eventTimestamp) {
      return formatTimestamp(formattedEvent.eventTimestamp);
    }
    if (formattedEvent?.address) {
      return formattedEvent?.address;
    }
    return "";
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        scroll="paper"
        maxWidth="md"
        hideBackdrop
        PaperComponent={DraggableComponent}
        aria-labelledby={DRAGGABLE_DIALOG_HANDLE}
        data-testid={TestIds.EventDetailsDialog.Dialog}
      >
        <DialogTitle variant="h5" style={{ cursor: "move" }}>
          {formattedEvent?.eventName}
          <Typography variant="body2">{subtitle()}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            data-testid={TestIds.EventDetailsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {dialogType === "detailed" && (
          <DialogContent sx={{ width: "30rem" }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {translations.common.texts.eventParameters()}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{translations.common.texts.parameterName()}</TableCell>
                    <TableCell>{translations.common.texts.value()}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parameters !== null && Object.keys(parameters).length ? (
                    Object.entries(parameters).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableEmptyRow colspan={2} infoText={translations.events.texts.noEventParameters()} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {attachments && attachments.length > 0 && (
              <React.Fragment>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {translations.common.texts.attachments()}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{translations.common.texts.name()}</TableCell>
                        <TableCell>{translations.common.texts.type()}</TableCell>
                        <TableCell>{translations.common.texts.size()}</TableCell>
                        <TableCell>{translations.common.texts.date()}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attachments.map((attachment, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <a href={attachment.downloadUrl} download={attachment.fileName}>
                              {attachment.fileName}
                            </a>
                          </TableCell>
                          <TableCell>{attachment.type}</TableCell>
                          <TableCell>{attachment.fileSize}</TableCell>
                          <TableCell>{formatTimestamp(Date.parse(attachment.timestamp))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            )}
          </DialogContent>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default EventDetailsDialog;
