/* Copyright*/
import { Patient } from "./Patient";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { EventsGetDocument, PatientsEventsGetLatestDocument } from "../../generated/gqlEvents";
import { PatientsUpdateDocument } from "../../generated/gqlUsers";
import { throwGQLError } from "../private-utils/throwGQLError";
import { AWSEvent } from "../events/AWSEvent";
import { AWSEventSet } from "../events/AWSEventSet";
import { AWSLatestPatientData } from "../events/AWSLatestPatientData";
const USER_ID_PREFIX = "USER/";
export class AWSPatient extends Patient {
    constructor(backend, id, parameters) {
        super(id, parameters);
        this.backend = backend;
        this.entityType = AWSPatient;
    }
    async updateAttributes(params) {
        var _a, _b, _c;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(PatientsUpdateDocument, {
            payload: Object.assign(Object.assign({ id: this.getId() }, params), { birthDate: (_a = params.birthDate) === null || _a === void 0 ? void 0 : _a.toISOString() }),
        });
        if (!((_b = response.data) === null || _b === void 0 ? void 0 : _b.patientsUpdate)) {
            throwGQLError(response, "Failed to update patient");
        }
        if (params.firstName)
            this.firstName = params.firstName;
        if (params.lastName)
            this.lastName = params.lastName;
        if (params.birthDate)
            this.birthDate = params.birthDate;
        if (params.gender)
            this.gender = params.gender;
        if (params.address1)
            this.address1 = params.address1;
        if (params.address2)
            this.address2 = params.address2;
        if (params.city)
            this.city = params.city;
        if (params.state)
            this.state = params.state;
        if (params.zipCode)
            this.zipCode = params.zipCode;
        if (params.phoneNumber)
            this.phoneNumber = params.phoneNumber;
        if (params.title)
            this.title = params.title;
        if (params.wearer)
            this.wearer = this.toUserId(params.wearer);
        // These can be set to undefined
        this.height = params.height;
        this.weight = params.weight;
        this.customerId = params.customerId;
        // Update wearer in the related Team
        if (params.wearer) {
            const team = await this.getTeam();
            const wearer = this.toUserId(params.wearer);
            if (team) {
                // Get the device IMEI for the patient through the team
                const deviceId = (_c = team.getDevices().find((device) => device.patientId === this.getId())) === null || _c === void 0 ? void 0 : _c.deviceId;
                if (deviceId) {
                    // Only one user can wear a device at a time, clear any old wearers in the team.
                    // NOTE: This should really cover members across all teams, but we don't have a way to do that yet.
                    team.getMembers().forEach((member) => {
                        if (member.wearerOfDevice === deviceId) {
                            member.wearerOfDevice = undefined;
                        }
                    });
                    // Is the new wearer part of the team?
                    const teamMember = team.getMembers().find((member) => member.userId === wearer);
                    if (teamMember) {
                        teamMember.wearerOfDevice = deviceId;
                    }
                }
            }
        }
    }
    async getEvent(eventId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const getEventResponse = await client.query(EventsGetDocument, {
            eventId,
        });
        if (!((_a = getEventResponse.data) === null || _a === void 0 ? void 0 : _a.eventsGet)) {
            throwGQLError(getEventResponse, "Failed to get event");
        }
        return new AWSEvent(getEventResponse.data.eventsGet);
    }
    async getLatestEvents(numberOfEvents) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const response = await client.query(PatientsEventsGetLatestDocument, {
            patientId: this.getId(),
            numberOfEvents,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.patientsEventsGetLatest)) {
            throwGQLError(response, "Failed to get latest event");
        }
        return response.data.patientsEventsGetLatest.events.map((event) => {
            return new AWSEvent(event);
        });
    }
    async getEventSet(params) {
        if (this.eventSet && params === this.eventSet.getQueryParams()) {
            return this.eventSet;
        }
        if (this.eventSet) {
            this.eventSet.changeQueryParams(params);
        }
        else {
            // Fetch new event set
            this.eventSet = new AWSEventSet(this, params);
        }
        await this.eventSet.fetch();
        return this.eventSet;
    }
    async getLatestData() {
        if (!this.latestData) {
            this.latestData = new AWSLatestPatientData(this);
            await this.latestData.fetch();
        }
        return this.latestData;
    }
    async getTeam() {
        if (this.teamId) {
            return this.backend.getTeam(this.teamId);
        }
    }
    async onRelationChange(change) {
        console.log("Patient relation change", change);
    }
    toUserId(id) {
        if (!id.startsWith(USER_ID_PREFIX)) {
            return USER_ID_PREFIX + id;
        }
        return id;
    }
}
