/* Copyright */
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Button,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SeverityType } from "../../../context/snackbar-context";
import { Maybe, AuthenticatedUser, AuthWrapper, isErrorWithCode, User, CognitoAccountStatus } from "@sade/data-access";
import ProfileInfoForm from "./profile-info-form";
import ChangePasswordForm from "./change-password-form";
import { validatePassword, trimPhoneNumber } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";
import UnsavedChangesDialog from "../unsaved-changes-dialog/unsaved-changes-dialog";
import SmsVerificationDialog from "../sms-verification-dialog/sms-verification-dialog";

type ProfileDialogProps = {
  open: boolean;
  handleClose: () => void;
  currentUser: Maybe<AuthenticatedUser>;
  userData: Maybe<User>;
  rdUserFlag: boolean;
};

const ProfileDialog: React.FC<ProfileDialogProps> = ({ open, handleClose, currentUser, userData, rdUserFlag }) => {
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isProfileInfoDirty, setIsProfileInfoDirty] = React.useState<boolean>(false);
  const [isPasswordInfoDirty, setIsPasswordInfoDirty] = React.useState<boolean>(false);
  const [isSavingPasswords, setIsSavingPasswords] = React.useState<boolean>(false);
  const [isSavingProfile, setIsSavingProfile] = React.useState<boolean>(false);
  const [profileInfo, setProfileInfo] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    countryCode: "",
    phoneNumber: "",
    language: "",
  });
  const [originalPhoneNumber, setOriginalPhoneNumber] = React.useState("");
  const [trimmedPhoneNumber, setTrimmedPhoneNumber] = React.useState("");
  const [phoneNumberVerified, setPhoneNumberVerified] = React.useState<boolean>(false);
  const [passwordInfo, setPasswordInfo] = React.useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [errors, setErrors] = React.useState({
    generalError: "",
    currentPasswordError: "",
    newPasswordError: "",
    phoneNumberError: "",
  });
  const [success, setSuccess] = React.useState("");
  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState<boolean>(false);
  const [smsVerificationDialogOpen, setSmsVerificationDialogOpen] = React.useState<boolean>(false);

  const clearErrorsAndSuccess = (): void => {
    setErrors({
      generalError: "",
      currentPasswordError: "",
      newPasswordError: "",
      phoneNumberError: "",
    });
    setSuccess("");
  };

  const clearPasswordData = (): void => {
    const passwordData = {
      current: "",
      new: "",
      confirm: "",
    };
    setPasswordInfo(passwordData);
  };

  const initialize = React.useCallback(() => {
    if (currentUser) {
      let countryCode = "+1";
      let phoneNumber = "";

      if (currentUser.attributes.phone_number) {
        if (rdUserFlag) {
          const first2 = currentUser.attributes.phone_number.slice(0, 2);
          // This is for cases where country code of Finland (+358) has been used during development
          if (first2 !== countryCode) {
            countryCode = currentUser.attributes.phone_number.slice(0, 4);
          }
        }
        phoneNumber = currentUser.attributes.phone_number.substring(countryCode.length);
      }

      const updatedProfileInfo = {
        firstName: currentUser.attributes.given_name,
        lastName: currentUser.attributes.family_name,
        email: currentUser.attributes.email,
        country: currentUser.attributes["custom:country"],
        countryCode: countryCode,
        language: currentUser.attributes["custom:language"],
        phoneNumber: phoneNumber,
      };
      setProfileInfo(updatedProfileInfo);
      phoneNumber && setOriginalPhoneNumber(currentUser.attributes.phone_number);
    }

    if (userData) {
      const phoneVerified = userData.getDetails().phoneVerified;
      setPhoneNumberVerified(phoneVerified);
    }

    clearPasswordData();
    clearErrorsAndSuccess();
    setIsProfileInfoDirty(false);
    setIsPasswordInfoDirty(false);
    setShowPassword(false);
  }, [currentUser, userData, rdUserFlag]);

  React.useEffect(() => {
    if (!open) {
      initialize();
    }
  }, [open, initialize]);

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    } else if (success) {
      showSnackbar(success, SeverityType.Success);
    }
  }, [errors, success, showSnackbar]);

  React.useEffect(() => {
    if (originalPhoneNumber) {
      const updatedPhoneNumber = profileInfo.countryCode.concat(profileInfo.phoneNumber);

      if (originalPhoneNumber === updatedPhoneNumber) {
        setPhoneNumberVerified(true);
      } else {
        setPhoneNumberVerified(false);
      }
    }
  }, [originalPhoneNumber, profileInfo.countryCode, profileInfo.phoneNumber]);

  const handleCloseClick = (): void => {
    if (isProfileInfoDirty || isPasswordInfoDirty) {
      setUnsavedDataDialogOpen(true);
    } else {
      handleClose();
    }
  };

  const handleCloseSmsVerificationDialog = (): void => {
    const updatedPhoneNumber = profileInfo.countryCode.concat(profileInfo.phoneNumber);
    setOriginalPhoneNumber(updatedPhoneNumber);
    setSmsVerificationDialogOpen(false);
  };

  const handleCloseUnsavedDataDialog = (): void => {
    setUnsavedDataDialogOpen(false);
  };

  const handleErrors = (code?: string): void => {
    switch (code) {
      case "TooManyRequestsException":
      case "LimitExceededException":
        setErrors({
          generalError: translations.common.texts.tooManyAttempts(),
          currentPasswordError: "",
          newPasswordError: "",
          phoneNumberError: "",
        });
        break;
      case "InternalErrorException":
        setErrors({
          generalError: translations.common.texts.networkError(),
          currentPasswordError: "",
          newPasswordError: "",
          phoneNumberError: "",
        });
        break;
      case "NotAuthorizedException":
        setErrors({
          generalError: "",
          currentPasswordError: translations.common.texts.currentPasswordIncorrent(),
          newPasswordError: "",
          phoneNumberError: "",
        });
        break;
      case "InvalidPasswordException":
        setErrors({
          generalError: "",
          currentPasswordError: "",
          newPasswordError: translations.common.texts.passwordDoesNotMeetRequirements(),
          phoneNumberError: "",
        });
        break;
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          currentPasswordError: "",
          newPasswordError: "",
          phoneNumberError: "",
        });
        break;
    }
  };

  // #region Update user information

  const handleProfileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...profileInfo, [event.target.id]: event.target.value };
    setProfileInfo(updatedRegistrationInfo);
    setIsProfileInfoDirty(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...profileInfo, [event.target.name]: event.target.value };
    setProfileInfo(updatedRegistrationInfo);
    setIsProfileInfoDirty(true);
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedRegistrationInfo = { ...profileInfo, language: event.target.value };
    setProfileInfo(updatedRegistrationInfo);
    setIsProfileInfoDirty(true);
  };

  const handleSaveChangesClick = async (): Promise<void> => {
    clearErrorsAndSuccess();
    let finalPhoneNumber = undefined;

    if (
      (profileInfo.phoneNumber.length > 0 && profileInfo.phoneNumber.length < 7) ||
      (currentUser?.attributes.phone_number && !profileInfo.phoneNumber)
    ) {
      setErrors({
        ...errors,
        generalError: "",
        newPasswordError: "",
        phoneNumberError: translations.common.texts.invalidPhoneNumber(),
      });
      return;
    }
    if (profileInfo.phoneNumber) {
      finalPhoneNumber = trimPhoneNumber(profileInfo.phoneNumber, profileInfo.countryCode);
      if (!finalPhoneNumber) {
        setErrors({
          ...errors,
          generalError: "",
          newPasswordError: "",
          phoneNumberError: translations.common.texts.invalidPhoneNumber(),
        });
        return;
      } else {
        setTrimmedPhoneNumber(finalPhoneNumber);
      }
    }

    try {
      setIsSavingProfile(true);

      await AuthWrapper.updateAttributes({
        given_name: profileInfo.firstName,
        family_name: profileInfo.lastName,
        "custom:language": profileInfo.language,
        "custom:country": profileInfo.country,
      });

      if (finalPhoneNumber && originalPhoneNumber !== finalPhoneNumber) {
        await AuthWrapper.setPhoneNumber(finalPhoneNumber);
        setSmsVerificationDialogOpen(true);
      }

      setSuccess(translations.common.texts.profileInfoUpdated());
      setIsProfileInfoDirty(false);
    } catch (error) {
      if (isErrorWithCode(error)) {
        handleErrors(error.code);
      }
    } finally {
      setIsSavingProfile(false);
    }
  };

  const isSaveChangesButtonDisabled =
    !profileInfo.firstName || !profileInfo.lastName || !profileInfo.country || !isProfileInfoDirty || isSavingProfile;

  // #endregion

  // #region Change Password

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrorsAndSuccess();
    const updatedPasswordInfo = { ...passwordInfo, [event.target.id]: event.target.value };
    setPasswordInfo(updatedPasswordInfo);
  };

  const handleChangePasswordClick = async (): Promise<void> => {
    clearErrorsAndSuccess();

    if (passwordInfo.new !== passwordInfo.confirm) {
      setErrors({
        ...errors,
        newPasswordError: translations.common.texts.passwordsDoNotMatch(),
      });
      return;
    }

    if (!validatePassword(passwordInfo.new)) {
      setErrors({
        ...errors,
        newPasswordError: translations.common.texts.passwordDoesNotMeetRequirements(),
      });
      return;
    }

    try {
      setIsSavingPasswords(true);
      await AuthWrapper.submitNewPassword(passwordInfo.current, passwordInfo.new);
      setSuccess(translations.common.texts.passwordChanged());
      clearPasswordData();
      setIsPasswordInfoDirty(false);
    } catch (error) {
      if (isErrorWithCode(error)) {
        handleErrors(error.code);
      }
    } finally {
      setIsSavingPasswords(false);
    }
  };

  const isChangePasswordButtonDisabled =
    !passwordInfo.current || !passwordInfo.new || !passwordInfo.confirm || isSavingPasswords;

  React.useEffect(() => {
    if (!isChangePasswordButtonDisabled) {
      setIsPasswordInfoDirty(true);
    }
  }, [isChangePasswordButtonDisabled]);

  // #endregion

  const confirmChanges = async (): Promise<void> => {
    if (!isChangePasswordButtonDisabled) {
      await handleChangePasswordClick();
    }
    if (!isSaveChangesButtonDisabled) {
      await handleSaveChangesClick();
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleCloseClick} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle variant="h5">
          {translations.myProfile.texts.myProfile()}
          <IconButton
            data-testid={TestIds.ProfileDialog.CloseProfileDialog}
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProfileInfoForm
            isExternalUser={userData?.getDetails().status === CognitoAccountStatus.ExternalProvider}
            profileInfo={profileInfo}
            phoneNumberError={errors.phoneNumberError}
            phoneNumberVerified={phoneNumberVerified}
            handleProfileInputChange={handleProfileInputChange}
            handleSelectChange={handleSelectChange}
            handleLanguageChange={handleLanguageChange}
            rdUserFlag={rdUserFlag}
          />
          <Divider sx={{ mt: "1.5rem" }} />
          {userData?.getDetails().status !== CognitoAccountStatus.ExternalProvider && (
            <ChangePasswordForm
              isLoading={isSavingPasswords}
              showPassword={showPassword}
              currentPassword={passwordInfo.current}
              newPassword={passwordInfo.new}
              confirmPassword={passwordInfo.confirm}
              currentPasswordError={errors.currentPasswordError}
              newPasswordError={errors.newPasswordError}
              isChangePasswordButtonDisabled={isChangePasswordButtonDisabled}
              handleClickShowPassword={handleClickShowPassword}
              handlePasswordInputChange={handlePasswordInputChange}
              handleChangePasswordClick={handleChangePasswordClick}
            />
          )}
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            disabled={isSaveChangesButtonDisabled}
            onClick={handleSaveChangesClick}
            startIcon={isSavingProfile && <CircularProgress size={16} color="secondary" />}
            data-testid={TestIds.ProfileDialog.SaveChangesButton}
          >
            {translations.common.buttons.saveChanges()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            sx={{ mt: 3, ml: 2 }}
            data-testid={TestIds.ProfileDialog.CancelButton}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogContent>
      </Dialog>
      <SmsVerificationDialog
        open={smsVerificationDialogOpen}
        handleClose={handleCloseSmsVerificationDialog}
        countryCode={profileInfo.countryCode}
        phoneNumber={profileInfo.phoneNumber}
        trimmedPhoneNumber={trimmedPhoneNumber}
      />
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleCloseUnsavedDataDialog}
        handleSave={confirmChanges}
        handleDiscard={handleClose}
      />
    </React.Fragment>
  );
};

export default ProfileDialog;
