/* Copyright */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { translations } from "../../generated/translationHelper";
import TestIds from "../../test-ids/test-ids";
import UnsavedChangesDialog from "../dialogs/unsaved-changes-dialog/unsaved-changes-dialog";
import ConfirmDeletePartnerDialog from "../dialogs/partners/confirm-delete-partner-dialog/confirm-delete-partner-dialog";

type PartnerDetailsViewHeaderProps = {
  isAddNewMode: boolean;
  isSaving: boolean;
  isSavingDisabled: boolean;
  partnerName?: string;
  handleSaveClick: () => Promise<void>;
  handleDeletePartnerClick: () => Promise<void>;
};

const PartnerDetailsViewHeader: React.FC<PartnerDetailsViewHeaderProps> = ({
  isAddNewMode,
  isSaving,
  isSavingDisabled,
  partnerName,
  handleSaveClick,
  handleDeletePartnerClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = React.useState<boolean>(false);
  const [isConfirmDeletePartnerDialogOpen, setIsConfirmDeletePartnerDialogOpen] = React.useState<boolean>(false);

  const handleToggleUnsavedChangesDialogOpen = (): void => {
    setIsUnsavedChangesDialogOpen(!isUnsavedChangesDialogOpen);
  };

  const handleToggleConfirmDeletePartnerDialogOpen = (): void => {
    setIsConfirmDeletePartnerDialogOpen(!isConfirmDeletePartnerDialogOpen);
  };

  const handleBackClick = (): void => {
    if (!isSavingDisabled) {
      handleToggleUnsavedChangesDialogOpen();
    } else {
      navigate(-1);
    }
  };

  const handleDiscardChangesClick = (): void => {
    navigate(-1);
  };

  const handleDeleteClick = (): void => {
    handleDeletePartnerClick();
  };

  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              size="small"
              startIcon={<ArrowBackIcon />}
              onClick={handleBackClick}
              sx={{ alignSelf: "flex-start", mb: 1 }}
              data-testid={TestIds.PartnerDetails.BackButton}
            >
              {translations.common.buttons.backToList()}
            </Button>
            <Typography
              noWrap={isSmallViewPort && true}
              variant={isSmallViewPort ? "h6" : "h4"}
              sx={{ maxWidth: isSmallViewPort ? "200px" : "100%" }}
            >
              {isAddNewMode ? translations.partners.texts.addNewPartner() : partnerName}
            </Typography>
          </Box>
          <Box>
            {isAddNewMode ? (
              <Button
                variant="contained"
                onClick={handleSaveClick}
                data-testid={TestIds.PartnerDetails.CreateButton}
                disabled={isSavingDisabled}
                size="small"
                startIcon={isSaving && <CircularProgress size={16} color="secondary" />}
              >
                {translations.partners.buttons.createPartner()}
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  variant="contained"
                  onClick={handleSaveClick}
                  data-testid={TestIds.PartnerDetails.SaveButton}
                  sx={{ mr: 2 }}
                  disabled={isSavingDisabled}
                  size={isSmallViewPort ? "small" : "medium"}
                  startIcon={isSaving && <CircularProgress size={16} color="secondary" />}
                >
                  {translations.common.buttons.saveChanges()}
                </Button>
                <Button
                  variant="contained"
                  startIcon={!isSmallViewPort && <DeleteIcon />}
                  onClick={handleToggleConfirmDeletePartnerDialogOpen}
                  data-testid={TestIds.PartnerDetails.DeleteButton}
                  color="error"
                  size={isSmallViewPort ? "small" : "medium"}
                >
                  {translations.partners.buttons.deletePartner()}
                </Button>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
      <UnsavedChangesDialog
        open={isUnsavedChangesDialogOpen}
        handleClose={handleToggleUnsavedChangesDialogOpen}
        handleSave={handleSaveClick}
        handleDiscard={handleDiscardChangesClick}
      />
      <ConfirmDeletePartnerDialog
        open={isConfirmDeletePartnerDialogOpen}
        handleClose={handleToggleConfirmDeletePartnerDialogOpen}
        handleConfirm={handleDeleteClick}
      />
    </React.Fragment>
  );
};

export default PartnerDetailsViewHeader;
