/* Copyright */
import * as React from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTextField from "../../common/textfield";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";

type ProfileInfoFormProps = {
  profileInfo: {
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    countryCode: string;
    language: string;
    phoneNumber: string;
  };
  phoneNumberError: string;
  phoneNumberVerified: boolean;
  handleProfileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  handleLanguageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rdUserFlag: boolean;
  isExternalUser: boolean;
};

const ProfileInfoForm: React.FC<ProfileInfoFormProps> = ({
  profileInfo,
  phoneNumberError,
  phoneNumberVerified,
  handleProfileInputChange,
  handleSelectChange,
  handleLanguageChange,
  rdUserFlag,
  isExternalUser,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <form autoComplete="off">
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              disabled={isExternalUser}
              label={translations.common.inputs.firstName()}
              id="firstName"
              errorMessage=""
              testId={TestIds.ProfileDialog.FirstName}
              value={profileInfo.firstName}
              onChange={handleProfileInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              disabled={isExternalUser}
              label={translations.common.inputs.lastName()}
              id="lastName"
              errorMessage=""
              testId={TestIds.ProfileDialog.LastName}
              value={profileInfo.lastName}
              onChange={handleProfileInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "1.5rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth data-testid={TestIds.ProfileDialog.Email}>
              <InputLabel shrink>{translations.common.inputs.email()}</InputLabel>
              <Typography variant="body2" sx={{ mt: 1, ml: 0.5 }}>
                {profileInfo.email}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "1px", alignItems: "end" }}>
          <Grid item xs={12} md={6}>
            <FormControl className="input" sx={{ textAlign: "start", mt: 3 }} fullWidth>
              <InputLabel id="country-label" shrink>
                {translations.common.inputs.country()}
              </InputLabel>
              <Select
                name="country"
                size="small"
                labelId="country-label"
                value={profileInfo.country ?? ""}
                inputProps={{ "data-testid": TestIds.ProfileDialog.Country }}
                onChange={handleSelectChange}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="United States">{translations.common.texts.unitedStates()}</MenuItem>
                <MenuItem value="Canada">{translations.common.texts.canada()}</MenuItem>
              </Select>
              <FormHelperText>{String(" ")}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex" }}>
            <FormControl className="country-code" sx={{ mr: 2 }}>
              <InputLabel id="country-code-label" shrink></InputLabel>
              <Select
                name="countryCode"
                size="small"
                labelId="country-code-label"
                value={profileInfo.countryCode}
                data-testid={TestIds.ProfileDialog.CountryCode}
                onChange={handleSelectChange}
                autoComplete="tel-country-code"
              >
                <MenuItem value="+1">+1</MenuItem>
                {rdUserFlag && <MenuItem value="+358">+358</MenuItem>}
              </Select>
            </FormControl>
            <FormControl className="phone-number" sx={{ flexGrow: 1 }}>
              <InputLabel id="phone-number-label" shrink>
                {translations.common.inputs.phone()}
              </InputLabel>
              <TextField
                id="phoneNumber"
                className="input"
                inputProps={{ "data-testid": TestIds.ProfileDialog.Phone }}
                value={profileInfo.phoneNumber}
                onChange={handleProfileInputChange}
                error={phoneNumberError.length > 0}
                helperText={phoneNumberError.length > 0 ? phoneNumberError : " "}
              ></TextField>
              {phoneNumberVerified && phoneNumberError.length === 0 && (
                <Typography variant="caption" sx={{ mt: -2.5, ml: 1.5, color: theme.palette.success.main }}>
                  {translations.common.texts.verified()}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "0.5rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl data-testid={TestIds.ProfileDialog.Language}>
              <InputLabel shrink>{translations.common.texts.language()}</InputLabel>
              <RadioGroup
                sx={{ mt: 1, ml: 2 }}
                id="language"
                value={profileInfo.language ?? ""}
                onChange={handleLanguageChange}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label={`${translations.common.texts.english()} (${translations.common.texts.unitedStates()})`}
                />
                <FormControlLabel
                  value="fr"
                  control={<Radio />}
                  label={`${translations.common.texts.french()} (${translations.common.texts.canada()}) `}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProfileInfoForm;
