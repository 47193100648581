/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";

type PurgeMailboxConfirmationDialogProps = {
  open: boolean;
  deviceId: string;
  onClose: () => void;
  onPurge: () => void;
};

const PurgeMailboxConfirmationDialog: React.FC<PurgeMailboxConfirmationDialogProps> = ({
  open,
  deviceId,
  onClose,
  onPurge,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.mailboxDialog.texts.purgeConfirmTitle()}
        <IconButton
          data-testid={TestIds.DeviceMailboxDialog.PurgeCancel}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.mailboxDialog.texts.purgeConfirmInfo({ deviceId }),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={onPurge}
          color="error"
          data-testid={TestIds.DeviceMailboxDialog.PurgeConfirm}
        >
          {translations.mailboxDialog.texts.purgeMailbox()}
        </Button>
        <Button variant="outlined" onClick={onClose} data-testid={TestIds.DeviceMailboxDialog.PurgeCancel}>
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurgeMailboxConfirmationDialog;
