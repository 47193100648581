/* Copyright */
import React from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { translations } from "../../../generated/translationHelper";
import TableEmptyRow from "../../common/table-empty-row";
import TableLoadingRow from "../../common/table-loading-row";
import TestIds from "../../../test-ids/test-ids";
import PartnersTableRow from "./partners-table-row";
import { PartnerDetails } from "../../../utils/utils";

type PartnersTableProps = {
  isLoading: boolean;
  fetched: boolean;
  partners: PartnerDetails[];
  handleRowClick: (partnerId: string) => void;
};

const PartnersTable: React.FC<PartnersTableProps> = ({ isLoading, fetched, partners, handleRowClick }) => {
  return (
    <Box sx={{ overflow: "auto", maxHeight: { xs: "65%", md: "80%", lg: "90%", xl: "95%" }, maxWidth: "100%" }}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader data-testid={TestIds.Partners.PartnersTable}>
          <TableHead>
            <TableRow>
              <TableCell>{translations.partners.texts.partnerName()}</TableCell>
              <TableCell>{translations.partners.texts.realmName()}</TableCell>
              <TableCell>{translations.partners.texts.networkId()}</TableCell>
              <TableCell>{translations.common.texts.language()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.length > 0
              ? partners.map((partner, index) => (
                  <PartnersTableRow
                    key={index}
                    partner={partner}
                    handleRowClick={handleRowClick}
                    tableRowIndex={index}
                  />
                ))
              : fetched && <TableEmptyRow colspan={4} />}
            {isLoading && <TableLoadingRow colspan={4} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PartnersTable;
