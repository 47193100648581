/* Copyright */
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  AWSLatestData,
  Device,
  DeviceOperatorPlan,
  DeviceStatus,
  Organization,
  OrganizationParameterHierarchy,
  RoleIdentifier,
} from "@sade/data-access";
import React from "react";
import { useAccessControl } from "../../../hooks/useAccessControl";
import { deviceSupportsSettingsAndActions, enableEdit } from "../../../utils/deviceDetailsUtils";
import { PatientInfo } from "../../../utils/utils";
import DeviceViewContentDetails from "./device-view-content-details/device-view-content-details";
import DeviceViewContentEventsLocations from "./device-view-content-events-locations/device-view-content-events-locations";
import DeviceViewContentEverThere from "./device-view-content-ever-there/device-view-content-ever-there";
import DeviceViewContentPeripherals from "./device-view-content-peripheral/device-view-content-peripherals";
import DeviceViewContentSettings from "./device-view-content-settings";
import DeviceViewContentStatus from "./device-view-content-status";

type DeviceViewContentProps = {
  isLoading: boolean;
  isLoadingDetails: boolean;
  device?: Device;
  deviceOrganization?: Organization;
  latestData?: AWSLatestData;
  patient?: PatientInfo;
  parameterHierarchy?: OrganizationParameterHierarchy;
  deviceStateUpdateCount: number;
  operatorPlans: DeviceOperatorPlan[];
  onDetailsUpdated: (updated: boolean) => void;
};

const DeviceViewContent: React.FC<DeviceViewContentProps> = ({
  isLoading,
  isLoadingDetails,
  device,
  deviceOrganization,
  latestData,
  patient,
  parameterHierarchy,
  operatorPlans,
  onDetailsUpdated,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraLargeViewPort = useMediaQuery(theme.breakpoints.up("xl"));

  const isAuthorizedForPeripheralsAndEverThere = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.WarehouseInstaller,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent
  );

  const showForDevice = (device?: Device, patientInfo?: PatientInfo, forAllDevicesTypes?: boolean): boolean => {
    if (!device) return false;
    if (!forAllDevicesTypes && !deviceSupportsSettingsAndActions(device)) return false;
    const state = device?.getDeviceStatus();
    if (!state) return false;
    switch (state) {
      case DeviceStatus.activating:
      case DeviceStatus.retired:
      case DeviceStatus.unsuccesful:
        return false;
      case DeviceStatus.unmanaged:
        return patientInfo ? true : false;
      default:
        return true;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={4}>
        <DeviceViewContentDetails
          isLoading={isLoadingDetails}
          device={device}
          deviceOrganization={deviceOrganization}
          patient={patient}
          setDetailsUpdated={onDetailsUpdated}
          parameterHierarchy={parameterHierarchy}
          operatorPlans={operatorPlans}
        />
        {showForDevice(device, patient) && (
          <DeviceViewContentStatus
            disabled={!enableEdit(device?.getDeviceStatus())}
            isLoading={isLoadingDetails}
            latestData={latestData}
          />
        )}
        {showForDevice(device, patient) && isSmallViewPort && (
          <DeviceViewContentSettings
            isLoading={isLoading}
            device={device}
            deviceOrganization={deviceOrganization}
            parameterHierarchy={parameterHierarchy}
          />
        )}
        {showForDevice(device, patient, true) && isSmallViewPort && (
          <DeviceViewContentEventsLocations device={device} isLoadingDetails={isLoadingDetails} />
        )}
        {showForDevice(device, patient) && !isExtraLargeViewPort && isAuthorizedForPeripheralsAndEverThere && (
          <DeviceViewContentEverThere device={device} isLoadingDetails={isLoadingDetails} />
        )}
        {showForDevice(device, patient) && !isExtraLargeViewPort && isAuthorizedForPeripheralsAndEverThere && (
          <DeviceViewContentPeripherals device={device} isLoading={isLoading} />
        )}
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        {showForDevice(device, patient) && !isSmallViewPort && (
          <DeviceViewContentSettings
            isLoading={isLoading}
            device={device}
            deviceOrganization={deviceOrganization}
            parameterHierarchy={parameterHierarchy}
          />
        )}
        {showForDevice(device, patient, true) && !isSmallViewPort && (
          <DeviceViewContentEventsLocations device={device} isLoadingDetails={isLoadingDetails} />
        )}
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        {showForDevice(device, patient) && isExtraLargeViewPort && isAuthorizedForPeripheralsAndEverThere && (
          <DeviceViewContentEverThere device={device} isLoadingDetails={isLoadingDetails} />
        )}
        {showForDevice(device, patient) && isExtraLargeViewPort && isAuthorizedForPeripheralsAndEverThere && (
          <DeviceViewContentPeripherals device={device} isLoading={isLoading} />
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceViewContent;
