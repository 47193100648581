/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer/BaseObservable";
import { AuthListener } from "../auth/AuthListener";
import { AuthWrapper } from "../auth/AuthWrapper";
export class ReceiverManager extends BaseObservable {
    constructor() {
        super();
        this.standardReceivers = [];
        this.customReceivers = new Set();
        this.authHandler = async (event) => {
            if (event === "SignedIn") {
                this.standardReceivers = await ReceiverManager.getStandardReceivers();
                this.notifyReceiversChanged();
            }
            else if (event === "SignedOut") {
                this.standardReceivers = [];
                this.customReceivers = new Set();
                this.notifyReceiversChanged();
            }
        };
        this.authListener = new AuthListener(this.authHandler, true);
    }
    static get instance() {
        if (!ReceiverManager.__instance) {
            ReceiverManager.__instance = new ReceiverManager();
        }
        return ReceiverManager.__instance;
    }
    static async getStandardReceivers() {
        var _a;
        const userId = (_a = (await AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.userId;
        if (!userId)
            console.error("Failed to resolved receiver 'userId'");
        return userId ? [userId.replace("USER/", "")] : [];
    }
    // override
    addObserver(observer) {
        super.addObserver(observer);
        observer.onReceiversChanged(this.getFullReceiverList());
    }
    addReceivers(...receivers) {
        const notify = receivers
            // the order of the OR statements are important due to short-circuit evaluation
            .reduce((result, receiver) => this.addCustomReceiver(receiver) || result, false);
        if (notify)
            this.notifyReceiversChanged();
    }
    removeReceivers(...receivers) {
        const notify = receivers
            // the order of the OR statements are important due to short-circuit evaluation
            .reduce((result, receiver) => this.removeCustomReceiver(receiver) || result, false);
        if (notify)
            this.notifyReceiversChanged();
    }
    replaceReceivers({ toRemove = [], toAdd = [] }) {
        // remove replicated receivers - no need to remove and add them back again
        const add = toAdd.filter((a) => !toRemove.includes(a));
        const remove = toRemove.filter((a) => !toAdd.includes(a));
        // the order of the OR statements are important due to short-circuit evaluation
        let notify = add.reduce((result, receiver) => this.addCustomReceiver(receiver) || result, false);
        notify = remove.reduce((result, receiver) => this.removeCustomReceiver(receiver) || result, notify);
        if (notify)
            this.notifyReceiversChanged();
    }
    getFullReceiverList() {
        return [...this.standardReceivers, ...this.customReceivers];
    }
    addCustomReceiver(receiver) {
        if (this.customReceivers.has(receiver))
            return false;
        this.customReceivers.add(receiver);
        return true;
    }
    removeCustomReceiver(receiver) {
        return this.customReceivers.delete(receiver);
    }
    notifyReceiversChanged() {
        const receivers = this.getFullReceiverList();
        this.notifyAction((observer) => observer.onReceiversChanged(receivers));
    }
}
