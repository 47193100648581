/* Copyright */
import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTextField from "../../common/textfield";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { Nullable, UserSearchField } from "@sade/data-access";

export type SearchCriteria = {
  searchBy?: string;
  searchString?: string;
};

type AccountsViewSearchProps = {
  isLoading: boolean;
  searchCriteria: SearchCriteria;
  handleSearchCriteriaChange: (internalSearchCriteria: SearchCriteria) => void;
};

const AccountsViewSearch: React.FC<AccountsViewSearchProps> = ({
  isLoading,
  searchCriteria,
  handleSearchCriteriaChange,
}) => {
  const [internalSearchCriteria, setInternalSearchCriteria] = React.useState<SearchCriteria>({});
  const [searchTimeout, setSearchTimeout] = React.useState<Nullable<NodeJS.Timeout>>(null);

  React.useEffect(() => {
    setInternalSearchCriteria(searchCriteria);
  }, [searchCriteria]);

  const handleInternalSearchCriteriaChange = (internalSearchCriteria: SearchCriteria): void => {
    if (searchTimeout) clearTimeout(searchTimeout);

    if (internalSearchCriteria.searchBy != null || internalSearchCriteria.searchString != null) {
      setSearchTimeout(
        setTimeout(() => {
          handleSearchCriteriaChange(internalSearchCriteria);
        }, 1500)
      );
    }
  };

  const handleSearchByChange = (event: SelectChangeEvent<string>): void => {
    const updatedSearchCriteria = { ...internalSearchCriteria, searchBy: event.target.value };
    setInternalSearchCriteria(updatedSearchCriteria);
    handleInternalSearchCriteriaChange(updatedSearchCriteria);
  };

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedSearchCriteria = { ...internalSearchCriteria, searchString: event.target.value };
    setInternalSearchCriteria(updatedSearchCriteria);
    handleInternalSearchCriteriaChange(updatedSearchCriteria);
  };

  const handleEnterClick = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (searchTimeout) clearTimeout(searchTimeout);
      handleSearchCriteriaChange(internalSearchCriteria);
    }
  };

  const handleSearchClick = (): void => {
    if (searchTimeout) clearTimeout(searchTimeout);
    handleSearchCriteriaChange(internalSearchCriteria);
  };

  const isSearchFieldDisabled = isLoading || !internalSearchCriteria.searchBy;

  return (
    <Box sx={{ display: "flex", flex: 1, mr: { xs: 0, lg: 4 } }}>
      <FormControl className="input" sx={{ textAlign: "start", mr: 1, maxWidth: "180px" }} fullWidth>
        <InputLabel id="search-by-label" shrink>
          {translations.accounts.inputs.searchBy()}
        </InputLabel>
        <Select
          id="searchBy"
          size="small"
          labelId="search-by-label"
          data-testid={TestIds.Accounts.SearchDropdown}
          inputProps={{ "data-testid": TestIds.Accounts.SearchBySelect }}
          onChange={handleSearchByChange}
          disabled={isLoading}
          value={internalSearchCriteria.searchBy ?? ""}
        >
          <MenuItem value={UserSearchField.FirstName} data-testid={TestIds.Accounts.SearchByFirstName}>
            {translations.common.texts.firstName()}
          </MenuItem>
          <MenuItem value={UserSearchField.LastName} data-testid={TestIds.Accounts.SearchByLastName}>
            {translations.common.texts.lastName()}
          </MenuItem>
          <MenuItem value={UserSearchField.Email} data-testid={TestIds.Accounts.SearchByEmail}>
            {translations.common.texts.email()}
          </MenuItem>
        </Select>
        <FormHelperText>{String(" ")}</FormHelperText>
      </FormControl>
      <CustomTextField
        label={translations.common.inputs.search()}
        id="searchString"
        type="search"
        testId={TestIds.Accounts.Search}
        onChange={handleSearchStringChange}
        onKeyDown={handleEnterClick}
        placeholder={translations.accounts.inputs.searchUsers()}
        disabled={isSearchFieldDisabled}
        value={internalSearchCriteria.searchString ?? ""}
        inputRef={(input): void => input && input.focus()}
        InputProps={{
          sx: {
            "& input": {
              textOverflow: "ellipsis",
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="search" onClick={handleSearchClick} edge="start" disabled={isSearchFieldDisabled}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default AccountsViewSearch;
