/* Copyright */
import { Grid } from "@mui/material";
import {
  DeviceState,
  Maybe,
  Organization,
  OrganizationParameterHierarchy,
  ParameterValueType,
} from "@sade/data-access";
import * as React from "react";
import { DeviceSettings, OrganizationInfo, ParameterNames } from "../../../utils/utils";
import DeviceSettingsContentLeftGrid from "./device-settings-content-left-grid";
import DeviceSettingsContentRightGrid from "./device-settings-content-right-grid";

type DeviceSettingsContentProps = {
  deviceState?: DeviceState;
  deviceOrganization: Maybe<Organization>;
  parameterHierarchy?: OrganizationParameterHierarchy;
  deviceSettings: DeviceSettings;
  organizationNameMap: OrganizationInfo[];
  onSettingChanged: (param: ParameterNames, value: ParameterValueType) => void;
};

const DeviceSettingsContent: React.FC<DeviceSettingsContentProps> = ({
  deviceState,
  deviceOrganization,
  parameterHierarchy,
  deviceSettings,
  organizationNameMap,
  onSettingChanged,
}) => {
  return (
    <Grid container spacing={2}>
      <DeviceSettingsContentLeftGrid
        settings={deviceSettings}
        deviceState={deviceState}
        parameterHierarchy={parameterHierarchy}
        organizationNameMap={organizationNameMap}
        deviceOrganization={deviceOrganization}
        onSettingChanged={onSettingChanged}
      />
      <DeviceSettingsContentRightGrid
        settings={deviceSettings}
        deviceState={deviceState}
        parameterHierarchy={parameterHierarchy}
        organizationNameMap={organizationNameMap}
        deviceOrganization={deviceOrganization}
        onSettingChanged={onSettingChanged}
      />
    </Grid>
  );
};

export default DeviceSettingsContent;
