/* Copyright */
import React from "react";
import { Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DevicesIcon from "../../assets/icons/devices-icon";
import CachedIcon from "@mui/icons-material/Cached";
import { translations } from "../../generated/translationHelper";
import { getPath } from "../../utils/ssoPathUtil";
import { useAccessControl } from "../../hooks/useAccessControl";
import { RoleIdentifier } from "@sade/data-access";
import { Paths } from "../../utils/utils";
import TestIds from "../../test-ids/test-ids";

type AdminMenuProps = {
  menuAnchorEl: HTMLElement | null;
  handleCloseAdminMenu: () => void;
  handleAdminMenuItemClick: (route: string) => void;
};

const AdminMenu: React.FC<AdminMenuProps> = ({ menuAnchorEl, handleCloseAdminMenu, handleAdminMenuItemClick }) => {
  const hasDeviceReportsRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.Viewer);
  const hasBulkActionsRights = useAccessControl(RoleIdentifier.PowerUser);

  const isSuperUser = useAccessControl();

  return (
    <Box>
      <Menu sx={{ mt: -1, ml: -9 }} anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleCloseAdminMenu}>
        {hasDeviceReportsRights && (
          <MenuItem
            onClick={(): void => handleAdminMenuItemClick(getPath(Paths.DEVICE_REPORTS))}
            data-testid={TestIds.AdminMenu.DeviceReports}
          >
            <ListItemIcon>
              <ContentPasteOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }} primary={translations.common.texts.deviceReports()}></ListItemText>
          </MenuItem>
        )}
        {hasBulkActionsRights && (
          <MenuItem
            onClick={(): void => handleAdminMenuItemClick(getPath(Paths.DEVICE_BULK_ACTIONS))}
            data-testid={TestIds.AdminMenu.DeviceBulkActions}
          >
            <ListItemIcon sx={{ paddingLeft: 0.75 }}>
              <DevicesIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }} primary={translations.common.texts.deviceBulkActions()}></ListItemText>
          </MenuItem>
        )}
        {isSuperUser && (
          <MenuItem
            onClick={(): void => handleAdminMenuItemClick(getPath(Paths.OTA_PACKAGES))}
            data-testid={TestIds.AdminMenu.OtaPackages}
          >
            <ListItemIcon>
              <CachedIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }} primary={translations.adminMenu.texts.otaUpdates()}></ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default AdminMenu;
