/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { AuthWrapper } from "../auth/AuthWrapper";
import { Service } from "../backend/AppSyncClientProvider";
import { AWSOrganization } from "./AWSOrganization";
import { AWSUser } from "./AWSUser";
import { InviteLanguage, OrganizationsApiKeysAddDocument, OrganizationsApiKeysListDocument, OrganizationsApiKeysRemoveDocument, OrganizationsCreateDocument, OrganizationsGetDocument, OrganizationsRolesAssignDocument, OrganizationsUsersAddDocument, OrganizationsUsersRemoveDocument, PatientsGetDocument, PatientsTeamGetDocument, ResultType, RolesListDocument, TeamsCreateDocument, TeamsDeleteDocument, TeamsGetDocument, TeamsInvitationsAcceptDocument, TeamsInvitationsDeclineDocument, TeamsInvitationsListDocument, UsersCreateDocument, UsersDeleteDocument, UsersEnableDocument, UsersGetDocument, UsersRolesListDocument, } from "../../generated/gqlUsers";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { EntityRelationCache, } from "../private-utils/EntityRelationCache";
import { throwGQLError } from "../private-utils/throwGQLError";
import { AuthListener } from "../auth/AuthListener";
import { AsyncCache } from "../private-utils/AsyncCache";
import { Role } from "./Role";
import { isDefined } from "../../common";
import { AWSTeam } from "./AWSTeam";
import { AWSPatient } from "./AWSPatient";
import { UserInvitationStatus } from "./Team";
// TODO:  method for cache pruning - or an actual cache
//        also, cache could be extracted with the pruning code
//        also, would graphql cache be enough here?
//        the cache should also be invalidated when the user logs out - is there a way to listen for that?
export class AWSOrganizationBackend {
    constructor() {
        // this is public so AWSEntities can reach into it
        this.entityRelationCache = new EntityRelationCache();
        this.cache = new AsyncCache();
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.cache.clear();
                this.entityRelationCache.clear();
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
    }
    async getOrganization(organizationId) {
        const fetchOrganization = async () => {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(OrganizationsGetDocument, { organizationId });
            if ((_a = response.data.organizationsGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSOrganization(this, response.data.organizationsGet);
            }
        };
        return this.cache.get(organizationId, fetchOrganization);
    }
    async getUser(userId, refreshCache) {
        const fetchUser = async () => {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(UsersGetDocument, { userId });
            if ((_a = response.data.usersGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSUser(this, {
                    id: response.data.usersGet.id,
                    email: response.data.usersGet.email,
                    homeOrganization: response.data.usersGet.homeOrganizationId,
                    details: response.data.usersGet.details,
                });
            }
        };
        if (refreshCache)
            await this.cache.delete(userId);
        return this.cache.get(userId, fetchUser);
    }
    async getRole(id) {
        const roles = await this.listRoles();
        return roles.find((role) => role.identifier === id);
    }
    async listRoles() {
        const fetchRoles = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(RolesListDocument, {});
            if (response.data.rolesList) {
                return response.data.rolesList.roles.map((role) => Role.fromGraphQL(role));
            }
            else {
                throwGQLError(response, "Could not fetch Roles");
            }
        };
        const roles = await this.cache.get(AWSOrganizationBackend.ROLE_CACHE_KEY, fetchRoles);
        return roles !== null && roles !== void 0 ? roles : [];
    }
    async getCurrentHomeOrganization() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        if (!claims) {
            throw new Error("No authenticated user");
        }
        const organization = await this.getOrganization(claims.homeOrganizationId);
        if (!organization) {
            throw new Error("Could not resolve home organization of current user");
        }
        return organization;
    }
    async getCurrentUser(refreshCache) {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        if (!claims) {
            return;
        }
        return await this.getUser(claims.userId, refreshCache);
    }
    async getTeam(id, refreshCache) {
        const fetchTeam = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(TeamsGetDocument, { teamId: id });
            if (response.data.teamsGet) {
                return new AWSTeam(this, {
                    id: response.data.teamsGet.id,
                    name: response.data.teamsGet.name,
                    devices: response.data.teamsGet.devices,
                    members: response.data.teamsGet.teamMembers,
                });
            }
        };
        if (refreshCache)
            await this.cache.delete(id);
        const team = await this.cache.get(id, fetchTeam);
        // If currently authenticated user is part of the team, then link the user to the team
        const user = await this.getCurrentUser();
        if (user && (team === null || team === void 0 ? void 0 : team.getMembers().find((member) => member.userId === user.getId()))) {
            this.entityRelationCache.link(user, team);
        }
        return team;
    }
    async createTeam(params) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsCreateDocument, {
            payload: {
                name: params.name,
                teamManagerId: params.teamManagerId,
                teamManagerEmail: params.teamManagerEmail,
                deviceId: params.deviceId,
                inviteLanguage: params.inviteLanguage,
            },
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsCreate)) {
            throwGQLError(response, "Failed to create team");
        }
        const newTeam = new AWSTeam(this, {
            id: response.data.teamsCreate.id,
            name: response.data.teamsCreate.name,
            devices: response.data.teamsCreate.devices,
            members: response.data.teamsCreate.teamMembers,
        });
        this.cache.set(newTeam.getId(), newTeam);
        // If currently authenticated user is part of the team, then link the user to the team
        const user = await this.getCurrentUser();
        if (user && (newTeam === null || newTeam === void 0 ? void 0 : newTeam.getMembers().find((member) => member.userId === user.getId()))) {
            this.entityRelationCache.link(user, newTeam);
        }
        // TODO: Link patient to the team?
        return newTeam;
    }
    async deleteTeam(id) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsDeleteDocument, { teamId: id });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete team");
        }
        await this.cleanEntityFromCaches(id);
    }
    async getPatient(patientId) {
        const fetchPatient = async () => {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(PatientsGetDocument, { patientId });
            if ((_a = response.data.patientsGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSPatient(this, response.data.patientsGet.id, Object.assign(Object.assign({}, response.data.patientsGet), { birthDate: response.data.patientsGet.birthDate ? new Date(response.data.patientsGet.birthDate) : undefined }));
            }
        };
        return this.cache.get(patientId, fetchPatient);
    }
    async getPatientTeam(patientId) {
        var _a, _b, _c;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(PatientsTeamGetDocument, { patientId });
        if ((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.patientsTeamGet) === null || _b === void 0 ? void 0 : _b.teams) === null || _c === void 0 ? void 0 : _c.length) {
            if (response.data.patientsTeamGet.teams.length > 1) {
                throw new Error("Patient has more than one team");
            }
            return new AWSTeam(this, {
                id: response.data.patientsTeamGet.teams[0].id,
                name: response.data.patientsTeamGet.teams[0].name,
                devices: response.data.patientsTeamGet.teams[0].devices,
                members: response.data.patientsTeamGet.teams[0].teamMembers,
            });
        }
    }
    async listTeamInvitations(userId, refreshCache) {
        var _a;
        const fetchTeamInvitations = async () => {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(TeamsInvitationsListDocument, { userId });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsInvitationsList)) {
                throwGQLError(response, "Failed to list team invitations");
            }
            return response.data.teamsInvitationsList.teamInvitations;
        };
        if (refreshCache) {
            await this.cache.delete(`${userId}-invitations`);
        }
        const invitations = (_a = (await this.cache.get(`${userId}-invitations`, fetchTeamInvitations))) !== null && _a !== void 0 ? _a : [];
        return invitations;
    }
    async acceptTeamInvitation(teamId, userId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsInvitationsAcceptDocument, { teamId, userId });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsInvitationsAccept) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsInvitationsAccept) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to accept invitation");
        }
        // Update cache
        const team = await this.getTeam(teamId);
        if (team) {
            const member = team.getMembers().find((member) => member.userId === userId);
            if (member)
                member.invitationStatus = UserInvitationStatus.Accepted;
        }
    }
    async declineTeamInvitation(teamId, userId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(TeamsInvitationsDeclineDocument, { teamId, userId });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.teamsInvitationsDecline) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.teamsInvitationsDecline) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to decline invitation");
        }
        // Update cache
        const team = await this.getTeam(teamId);
        if (team) {
            const member = team.getMembers().find((member) => member.userId === userId);
            if (member)
                member.invitationStatus = UserInvitationStatus.Declined;
        }
    }
    async addOrganizationApiKey(organizationId, apiKeyId, secretKey) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsApiKeysAddDocument, {
            organizationId,
            keyId: apiKeyId,
            secretKey,
        });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsApiKeysAdd) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.organizationsApiKeysAdd) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : `Failed to add API key to organization ${organizationId}`);
        }
    }
    async removeOrganizationApiKey(apiKeyId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsApiKeysRemoveDocument, { keyId: apiKeyId });
        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsApiKeysRemove) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(response, (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.organizationsApiKeysRemove) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : `Failed to remove API key ${apiKeyId}`);
        }
    }
    async getOrganizationApiKeys(apiKeyIds) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(OrganizationsApiKeysListDocument, { keyIds: apiKeyIds });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsApiKeysList)) {
            throwGQLError(response, `Failed to get API keys ${apiKeyIds.join(", ")}`);
        }
        return response.data.organizationsApiKeysList;
    }
    //
    // OrganizationBackend implementation ends:
    // Next are internal method for AWS-implementation classes to use for backend communication
    // and caching
    //
    async createOrganization(owner, parameters) {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsCreateDocument, {
            payload: {
                name: parameters.name,
                parentOrganizationId: (_a = parameters.organizationId) !== null && _a !== void 0 ? _a : owner.getId(),
                realmName: parameters.realmName,
                netsuiteId: parameters.netsuiteId,
                networkId: parameters.networkId,
                patientTextUrl: parameters.patientTextUrl,
                ssoConfiguration: parameters.ssoConfiguration,
                apiKeyIds: parameters.apiKeyIds,
            },
        });
        if (!((_b = response.data) === null || _b === void 0 ? void 0 : _b.organizationsCreate)) {
            throwGQLError(response, "Failed to create organization");
        }
        const newOrganization = new AWSOrganization(this, response.data.organizationsCreate);
        this.cache.set(newOrganization.getId(), newOrganization);
        return newOrganization;
    }
    async createUser(owner, parameters) {
        var _a, _b;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(UsersCreateDocument, {
            payload: {
                email: parameters.email.toLowerCase(),
                organizationId: owner.getId(),
                roles: parameters.roles.map((role) => role.identifier),
                resendInvitation: (_a = parameters.resendInvitation) !== null && _a !== void 0 ? _a : false,
                inviteLanguage: InviteLanguage[parameters.inviteLanguage],
            },
        });
        if (!((_b = response.data) === null || _b === void 0 ? void 0 : _b.usersCreate)) {
            throwGQLError(response, "Failed to create new user");
        }
        const newUser = new AWSUser(this, {
            id: response.data.usersCreate.id,
            email: response.data.usersCreate.email,
            homeOrganization: owner,
            details: {
                enabled: response.data.usersCreate.details.enabled,
                status: response.data.usersCreate.details.status,
                emailVerified: response.data.usersCreate.details.emailVerified,
                phoneVerified: response.data.usersCreate.details.phoneVerified,
                firstName: response.data.usersCreate.details.firstName,
                lastName: response.data.usersCreate.details.lastName,
                phoneNumber: response.data.usersCreate.details.phoneNumber,
                country: response.data.usersCreate.details.country,
                language: response.data.usersCreate.details.language,
            },
        });
        this.cache.set(newUser.getId(), newUser);
        return newUser;
    }
    /**
     * Add a user to an organization.
     *
     * @param organization The organization to add the user to
     * @param user The user to add to the organization
     * @param roles The roles to assign to the user in the organization
     * @throws Error if the user could not be added to the organization
     */
    async addUserToOrganization(organization, user, roles) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsUsersAddDocument, {
            organizationId: organization.getId(),
            userId: user.getId(),
            roles,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsUsersAdd)) {
            throwGQLError(response, "Failed to add user to organization");
        }
        else {
            const success = response.data.organizationsUsersAdd.result === ResultType.Ok;
            if (!success) {
                const reason = response.data.organizationsUsersAdd.failureReason;
                throw new Error(`Failed to add user to organization ${reason ? ": " + reason : ""}`);
            }
            this.entityRelationCache.link(organization, user);
        }
    }
    /**
     * Remove a user from an organization.
     *
     * @param organization The organization to remove the user from
     * @param user The user to remove from the organization
     * @throws Error if the user could not be removed from the organization
     */
    async removeUserFromOrganization(organization, user) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsUsersRemoveDocument, {
            userId: user.getId(),
            organizationId: organization.getId(),
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsUsersRemove)) {
            throwGQLError(response, "Failed to remove user from organization");
        }
        else {
            const success = response.data.organizationsUsersRemove.result === ResultType.Ok;
            if (!success) {
                const reason = response.data.organizationsUsersRemove.failureReason;
                throw new Error(`Failed to remove user from organization ${reason ? ": " + reason : ""}`);
            }
            this.entityRelationCache.unlink(organization, user);
        }
    }
    /**
     * Sends a request to replace user's current roles within the given organization with new roles.
     *
     * @param userId - user
     * @param organizationId - context organization
     * @param roles - role identifiers
     * @throws if user/organization/roles do not exist
     * @throws on internal service failure
     */
    async assignUserOrganizationRoles(userId, organizationId, roles) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsRolesAssignDocument, {
            userId,
            organizationId,
            roles,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsRolesAssign)) {
            throwGQLError(response);
        }
        else {
            const success = ((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.organizationsRolesAssign) === null || _c === void 0 ? void 0 : _c.result) === ResultType.Ok;
            if (!success) {
                const reason = (_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.organizationsRolesAssign) === null || _e === void 0 ? void 0 : _e.failureReason;
                throw new Error(`Failed to set roles${reason ? ": " + reason : ""}`);
            }
        }
    }
    /**
     * Retrieves a map from organization IDs to roles for the given user.
     * @param userId - user
     * @param organizationId - root organization of the subtree
     * @returns an object with a map from organization IDs to Role objects, and
     *          inherited roles active in the parameter organization.
     *          If some roles are no longer valid those are dropped from the result.
     * @throws if unable to retrieve roles
     */
    async getUserRoles(userId, organizationId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        // TODO: nextToken
        const response = await client.query(UsersRolesListDocument, {
            userId,
            organizationId,
        });
        console.log("getUserRoles", userId, response);
        const { usersRolesList } = response.data;
        if (!usersRolesList) {
            throwGQLError(response, "Failed to retrieve user's roles");
        }
        // construct fresh Roles from the role definitions that are part of the response
        const activeRoleMap = usersRolesList.activeRoles.reduce((map, gqlRole) => {
            const role = Role.fromGraphQL(gqlRole);
            map.set(role.identifier, role);
            return map;
        }, new Map());
        const roleTree = new Map();
        usersRolesList.roleGrants.forEach((grant) => {
            if (!grant.roles)
                return;
            const roles = grant.roles.map((roleId) => activeRoleMap.get(roleId)).filter(isDefined);
            if (roles.length !== grant.roles.length) {
                console.error(`Could not find roles for all identifiers: ${grant.roles.join(", ")}`);
            }
            roleTree.set(grant.organizationId, roles);
        });
        const inheritedRoles = ((_a = usersRolesList.inheritedRoles) !== null && _a !== void 0 ? _a : [])
            .map((roleId) => activeRoleMap.get(roleId))
            .filter(isDefined);
        return { roleTree, inheritedRoles };
    }
    /**
     * Deletes user from the backend (and local caches).
     *
     * @param userId - user
     * @throws if unable to delete user (permissions, does not exist, etc)
     */
    async deleteUser(userId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const result = await client.mutate(UsersDeleteDocument, { userId });
        if (((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.usersDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(result, (_e = (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.usersDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete user");
        }
        else {
            await this.cleanEntityFromCaches(userId);
        }
    }
    /**
     * Disables/enables user on the backend.
     *
     * @param userId - user
     * @param enabled - whether to enable or disable user
     * @throws if unable to enable/disable user (permissions, does not exist, etc)
     */
    async setUserAccountStatus(userId, enabled) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const result = await client.mutate(UsersEnableDocument, { userId, enabled });
        if (((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.usersEnable) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(result, (_e = (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.usersEnable) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to set user account status");
        }
    }
    // TODO: make private by moving backend calls from AWSOrganization here
    async cleanEntityFromCaches(id) {
        const entity = await this.cache.delete(id);
        if (entity) {
            this.entityRelationCache.remove(entity);
        }
    }
}
AWSOrganizationBackend.ROLE_CACHE_KEY = "roles-async-cache-key";
