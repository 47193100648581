/* Copyright */

import React from "react";

React;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginRoute from "./components/access-control/login-route";
import PrivateRoute from "./components/access-control/private-route";
import AccountsView from "./components/accounts-view/accounts-view";
import ActiveCallView from "./components/active-call-view/active-call-view";
import DeviceReports from "./components/admin/device-reports-view/device-reports-view";
import OtaPackages from "./components/admin/ota-packages-view/ota-packages-view";
import DeviceView from "./components/device-view/device-view";
import DevicesView from "./components/devices-view/devices-view";
import ForgotPasswordView from "./components/forgot-password-view/forgot-password-view";
import TemporaryLandingPage from "./components/landing-page/landing-page";
import AuthenticationLayout from "./components/layout/authentication/authentication-layout";
import FederatedLoginForm from "./components/login-view/federated-login-form";
import LoginForm from "./components/login-view/login-form";
import MonitoringView from "./components/monitoring-view/monitoring-view";
import PartnerDetailsView from "./components/partner-details-view/partner-details-view";
import PartnersView from "./components/partners-view/partners-view";
import RegistrationView from "./components/registration-view/registration-view";
import ReportsView from "./components/reports-view/reports-view";
import { Paths } from "./utils/utils";

import AppProviders from "./app/AppProviders";
import DeviceBulkActionsView from "./components/admin/device-bulk-actions-view/device-bulk-actions-view";
import DeviceEventsLocationsView from "./components/events-and-locations-view/device-events-locations-view";

const appRoutes = (prefix: string): JSX.Element => {
  return (
    <>
      <Route path={`${prefix}${Paths.ROOT}`} element={<PrivateRoute component={TemporaryLandingPage} />} />
      <Route path={`${prefix}${Paths.MONITORING}`} element={<PrivateRoute component={MonitoringView} />} />
      <Route
        path={`${prefix}${Paths.ACTIVE_CALL}`}
        element={<PrivateRoute component={ActiveCallView} clean={true} />}
      />
      <Route path={`${prefix}${Paths.DEVICES}`} element={<PrivateRoute component={DevicesView} />} />
      <Route path={`${prefix}${Paths.DEVICE}`} element={<PrivateRoute component={DeviceView} />} />
      <Route
        path={`${prefix}${Paths.EVENTS_AND_LOCATIONS}`}
        element={<PrivateRoute component={DeviceEventsLocationsView} clean={true} />}
      />
      <Route path={`${prefix}${Paths.ACCOUNTS}`} element={<PrivateRoute component={AccountsView} />} />
      <Route path={`${prefix}${Paths.REPORTS}`} element={<PrivateRoute component={ReportsView} />} />
      <Route path={`${prefix}${Paths.PARTNERS}`} element={<PrivateRoute component={PartnersView} />} />
      <Route path={`${prefix}${Paths.PARTNER}`} element={<PrivateRoute component={PartnerDetailsView} />} />
      <Route path={`${prefix}${Paths.PARTNER_ADD}`} element={<PrivateRoute component={PartnerDetailsView} />} />
      <Route path={`${prefix}${Paths.OTA_PACKAGES}`} element={<PrivateRoute component={OtaPackages} />} />
      <Route path={`${prefix}${Paths.DEVICE_REPORTS}`} element={<PrivateRoute component={DeviceReports} />} />
      <Route
        path={`${prefix}${Paths.DEVICE_BULK_ACTIONS}`}
        element={<PrivateRoute component={DeviceBulkActionsView} />}
      />
      <Route
        path={`${prefix}${Paths.LOGIN}`}
        element={<LoginRoute normalLogin={LoginForm} ssoLogin={FederatedLoginForm} props={{ logout: false }} />}
      />
      <Route
        path={`${prefix}${Paths.LOGOUT}`}
        element={<LoginRoute normalLogin={LoginForm} ssoLogin={FederatedLoginForm} props={{ logout: true }} />}
      />
      <Route
        path={`${prefix}${Paths.REGISTRATION}`}
        element={
          <AuthenticationLayout>
            <RegistrationView initialProgress="" />
          </AuthenticationLayout>
        }
      ></Route>
      <Route
        path={`${prefix}${Paths.FORGOTPASSWORD}`}
        element={
          <AuthenticationLayout>
            <ForgotPasswordView initialProgress="" />
          </AuthenticationLayout>
        }
      ></Route>
    </>
  );
};

function App(): JSX.Element {
  return (
    <AppProviders>
      <BrowserRouter>
        <Routes>
          <Route path={"/sso/:sso"}>{appRoutes("")}</Route>
          <Route>{appRoutes("/")}</Route>
        </Routes>
      </BrowserRouter>
    </AppProviders>
  );
}

export default App;
