/* Copyright */
import React from "react";

const CellularSignal1Bars: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 16H6V21H3V16Z" fill="#0288D1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H19V20H20V4ZM18 3V21H21V3H18ZM15 8H14V20H15V8ZM13 7V21H16V7H13ZM9 13H10V20H9V13ZM8 21V12H11V21H8Z"
        fill="#0288D1"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default CellularSignal1Bars;
