/* Copyright */
import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Maybe, Role, User } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import Loading from "../../loading/loading";
import AddRealmAccessDialogContent from "./add-realm-access-dialog-content";
import UnsavedChangesDialog from "../unsaved-changes-dialog/unsaved-changes-dialog";
import { OrganizationInfo, UserRealmRole, getChildOrganizations } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";

type AddRealmAccessDialogProps = {
  open: boolean;
  handleClose: () => void;
  userData: Maybe<User>;
  userRealmAccessData: UserRealmRole[];
  handleSubmit: (realm: string, role: string) => void;
  isAddingRealmAccess: boolean;
  isLoadingAssignableRoles: boolean;
  assignableRoles: Role[];
  selectedRealm: string;
  selectedRole: string;
  handleSelectedRealmChange: (realmId: string) => void;
  handleSelectedRoleChange: (event: SelectChangeEvent) => void;
};

const AddRealmAccessDialog: React.FC<AddRealmAccessDialogProps> = ({
  open,
  handleClose,
  userData,
  userRealmAccessData,
  handleSubmit,
  isAddingRealmAccess,
  isLoadingAssignableRoles,
  assignableRoles,
  selectedRealm,
  selectedRole,
  handleSelectedRealmChange,
  handleSelectedRoleChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [realms, setRealms] = React.useState<OrganizationInfo[]>([]);

  const [unsavedDataDialogOpen, setUnsavedDataDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const getChildOrganizationList = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
        setRealms(childOrganizationList);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getChildOrganizationList();
  }, [currentOrganization, userRealmAccessData]);

  React.useEffect(() => {
    if (isAddingRealmAccess) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isAddingRealmAccess]);

  const isSubmitDisabled = !selectedRealm || !selectedRole || isLoading;

  const handleCloseClick = (): void => {
    if (!isSubmitDisabled) {
      setUnsavedDataDialogOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmChanges = async (): Promise<void> => {
    await handleSubmit(selectedRealm, selectedRole);
    handleClose();
  };

  const handleDiscardChanges = (): void => {
    handleClose();
  };

  const handleCloseUnsavedDataDialog = (): void => {
    setUnsavedDataDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.AddRealmAccessDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.common.texts.addRealmAccess()}
          <IconButton
            data-testid={TestIds.AddRealmAccessDialog.CloseButton}
            aria-label="close"
            onClick={handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loading />
          ) : (
            <AddRealmAccessDialogContent
              userData={userData}
              realms={realms}
              userRealmAccessData={userRealmAccessData}
              roles={assignableRoles}
              isLoadingAssignableRoles={isLoadingAssignableRoles}
              selectedRealm={selectedRealm}
              selectedRole={selectedRole}
              handleRealmChange={handleSelectedRealmChange}
              handleRoleChange={handleSelectedRoleChange}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="contained"
            disabled={isSubmitDisabled}
            onClick={(): void => handleSubmit(selectedRealm, selectedRole)}
            data-testid={TestIds.AddRealmAccessDialog.SubmitButton}
            startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
          >
            {translations.common.texts.addRealmAccess()}
          </Button>
          <Button variant="outlined" onClick={handleCloseClick} data-testid={TestIds.AddRealmAccessDialog.CancelButton}>
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
      <UnsavedChangesDialog
        open={unsavedDataDialogOpen}
        handleClose={handleCloseUnsavedDataDialog}
        handleSave={handleConfirmChanges}
        handleDiscard={handleDiscardChanges}
      />
    </React.Fragment>
  );
};

export default AddRealmAccessDialog;
