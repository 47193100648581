/* Copyright */
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { AWSLatestData, Device, DeviceState, Event, FallDetectionDisposition, Patient } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import { LocationData } from "../../../utils/mapUtils";
import { getPatientUrlText } from "../../../utils/patientTextUrl";
import Loading from "../../loading/loading";
import DeviceCard from "./device-card";
import DeviceWearerCard from "./device-wearer-card";
import EventCard from "./event-card/event-card";
import EventMapCard from "./event-map-card/event-map-card";
import LocationHistoryCard from "./location-history-card/location-history-card";

type ActiveCallViewContentProps = {
  isLoading: boolean;
  patient?: Patient;
  event?: Event;
  device?: Device;
  deviceState?: DeviceState;
  latestData?: AWSLatestData;
  locations?: LocationData[];
  handleSelectedLocationsToShowChange: (location: LocationData) => void;
  toggleIsMissedFallReportDialogOpen: () => void;
  fallConfirmed?: FallDetectionDisposition;
  setFallConfirmed: (fallDetectionDisposition?: FallDetectionDisposition) => void;
};

const ActiveCallViewContent: React.FC<ActiveCallViewContentProps> = ({
  isLoading,
  patient,
  event,
  device,
  deviceState,
  latestData,
  locations,
  handleSelectedLocationsToShowChange,
  toggleIsMissedFallReportDialogOpen,
  fallConfirmed,
  setFallConfirmed,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumViewPort = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isLargeViewPort = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeViewPort = useMediaQuery(theme.breakpoints.up("xl"));

  const [wearerName, setWearerName] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>();

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (!patient || !device) return;
      const patientInfo = await getPatientUrlText(patient, device);
      setAddress(patientInfo?.display_address);
      setWearerName(patientInfo?.display_name ?? translations.common.texts.unknown());
    })();
  }, [patient, device]);

  return (
    <React.Fragment>
      {isLoading || !event ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <DeviceWearerCard wearerName={wearerName} address={address} />
            <EventCard
              event={event}
              toggleIsMissedFallReportDialogOpen={toggleIsMissedFallReportDialogOpen}
              fallConfirmed={fallConfirmed}
              setFallConfirmed={setFallConfirmed}
            />
            <DeviceCard device={device} deviceState={deviceState} latestData={latestData} />
            {/* {(isSmallViewPort || isMediumViewPort || isLargeViewPort) && <OrientationActivityCard event={event} />} */}
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={5}>
            <EventMapCard callEvent={event} locationsOnMap={locations} homeAddress={address} />
            {(isSmallViewPort || isMediumViewPort || isLargeViewPort) && (
              <LocationHistoryCard
                locationsOnHistoryList={locations}
                handleSelectedLocationsToShowChange={handleSelectedLocationsToShowChange}
              />
            )}
          </Grid>
          <Grid item xs={12} md={0} lg={0} xl={4}>
            {/* {isExtraLargeViewPort && <OrientationActivityCard event={event} />} */}
            {isExtraLargeViewPort && (
              <LocationHistoryCard
                locationsOnHistoryList={locations}
                handleSelectedLocationsToShowChange={handleSelectedLocationsToShowChange}
              />
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ActiveCallViewContent;
