/* Copyright */
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import { DeviceSearchField, DeviceStatus, Nullable, RoleIdentifier } from "@sade/data-access";
import React from "react";
import { useLocation } from "react-router-dom";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import TestIds from "../../../test-ids/test-ids";
import CustomTextField from "../../common/textfield";
import ActivateNewDevicesDialog from "../../dialogs/device/activate-new-devices/activate-new-devices-dialog";
import DevicesViewFilters from "./devices-view-filters";

type DevicesViewFilterBarProps = {
  isLoading: boolean;
  searchString: string;
  selectedSearchField: DeviceSearchField;
  selectedRealms: string[];
  selectedStatuses: DeviceStatus[];
  handleSearchStringChange: (searchCriteria: string) => void;
  handleSearchFieldChange: (searchField: DeviceSearchField) => void;
  handleRealmSelectChange: (realms: string[]) => void;
  handleStatusSelectChange: (selectedStatuses: DeviceStatus[]) => void;
};

const DevicesViewFilterBar: React.FC<DevicesViewFilterBarProps> = ({
  isLoading,
  searchString,
  selectedSearchField,
  selectedRealms,
  selectedStatuses,
  handleSearchStringChange,
  handleSearchFieldChange,
  handleRealmSelectChange,
  handleStatusSelectChange,
}) => {
  const { state } = useLocation();
  const [isActivateNewDevicesDialogOpen, setIsActivateNewDevicesDialogOpen] = React.useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = React.useState<string>(searchString);

  const searchTimeoutRef = React.useRef<Nullable<NodeJS.Timeout>>(null);

  React.useEffect(() => {
    if (!state) {
      setSearchCriteria(searchString);
    }

    return (): void => {
      if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => setSearchCriteria(searchString), [searchString]);

  const handleToggleOpenActivateNewDialog = (): void => {
    setIsActivateNewDevicesDialogOpen(!isActivateNewDevicesDialogOpen);
  };

  const handleSearchStringOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchCriteria(event.target.value);

    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);

    if (event.target.value !== searchString) {
      searchTimeoutRef.current = setTimeout(() => {
        handleSearchStringChange(event.target.value);
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column-reverse", lg: "row" },
        alignItems: { xl: "center" },
      }}
    >
      <Box sx={{ display: "flex", flex: 1, flexDirection: { xs: "column", lg: "row" } }}>
        <Box sx={{ display: "flex", flex: 1, mr: 1 }}>
          <FormControl className="input" sx={{ textAlign: "start", mr: 1, minWidth: "10rem" }}>
            <InputLabel id="search-by-label" shrink disabled={isLoading}>
              {translations.common.inputs.searchBy()}
            </InputLabel>
            <Select
              id="searchBy"
              size="small"
              fullWidth={true}
              value={selectedSearchField}
              onChange={(event): void => handleSearchFieldChange(event.target.value as DeviceSearchField)}
              renderValue={(selected): string =>
                translations.devices.inputs[selected.toLowerCase() as keyof typeof translations.devices.inputs]?.()
              }
              data-testid={TestIds.Devices.SearchDropdown}
            >
              {(Object.keys(DeviceSearchField) as Array<keyof typeof DeviceSearchField>).map((searchField, index) => (
                <MenuItem
                  key={index}
                  value={DeviceSearchField[searchField]}
                  data-testid={DeviceSearchField[searchField]}
                >
                  <Radio checked={selectedSearchField === DeviceSearchField[searchField]} />
                  <ListItemText
                    primary={translations.devices.inputs[
                      searchField.toLowerCase() as keyof typeof translations.devices.inputs
                    ]?.()}
                    data-option-index={index}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{String(" ")}</FormHelperText>
          </FormControl>
          <CustomTextField
            label={translations.common.inputs.search()}
            id="searchString"
            type="search"
            testId={TestIds.Devices.Search}
            onChange={handleSearchStringOnChange}
            placeholder={translations.common.texts.searchPlaceholder()}
            value={searchCriteria}
            inputRef={(input): void => input && input.focus()}
            InputProps={{
              sx: {
                "& input": {
                  textOverflow: "ellipsis",
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="search" edge="start">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            sx={{
              flex: 1,
              mb: { xs: "1.5rem", lg: 0 },
            }}
          />
        </Box>
        <DevicesViewFilters
          isLoading={isLoading}
          handleRealmSelectChange={handleRealmSelectChange}
          selectedRealms={selectedRealms}
          handleStatusSelectChange={handleStatusSelectChange}
          selectedStatuses={selectedStatuses}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: { xs: "flex-end", xl: "flex-start" }, ml: 4, mb: { xs: 3, xl: 2 } }}>
        {useAccessControl(
          RoleIdentifier.PowerUser,
          RoleIdentifier.DealerManager,
          RoleIdentifier.WarehouseInstaller
        ) && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleToggleOpenActivateNewDialog}
            data-testid={TestIds.Devices.ActivateNewButton}
          >
            {translations.devices.buttons.activateNew()}
          </Button>
        )}
      </Box>
      {isActivateNewDevicesDialogOpen && (
        <ActivateNewDevicesDialog open={isActivateNewDevicesDialogOpen} onClose={handleToggleOpenActivateNewDialog} />
      )}
    </Box>
  );
};

export default DevicesViewFilterBar;
