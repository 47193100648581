/* Copyright */
import * as React from "react";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../generated/translationHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { AuthWrapper, isErrorWithCode } from "@sade/data-access";
import { SeverityType } from "../../../context/snackbar-context";
import TestIds from "../../../test-ids/test-ids";

type SmsVerificationDialogProps = {
  open: boolean;
  handleClose: () => void;
  countryCode: string;
  phoneNumber: string;
  trimmedPhoneNumber: string;
};

const SmsVerificationDialog: React.FC<SmsVerificationDialogProps> = ({
  open,
  handleClose,
  countryCode,
  phoneNumber,
  trimmedPhoneNumber,
}) => {
  const AuthInputRef = React.useRef<AuthCodeRef>(null);
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [errors, setErrors] = React.useState({
    generalError: "",
    codeError: "",
  });
  const [success, setSuccess] = React.useState("");

  React.useEffect(() => {
    if (errors.generalError) {
      showSnackbar(errors.generalError, SeverityType.Error);
    } else if (success) {
      showSnackbar(success, SeverityType.Success);
    }
  }, [errors, success, showSnackbar]);

  const clearErrorsAndSuccess = (): void => {
    setErrors({
      generalError: "",
      codeError: "",
    });
    setSuccess("");
  };

  const handleErrors = (code?: string): void => {
    switch (code) {
      case "LimitExceededException":
        setErrors({
          generalError: translations.common.texts.tooManyAttempts(),
          codeError: "",
        });
        break;
      case "Network error":
        setErrors({
          generalError: translations.common.texts.networkError(),
          codeError: "",
        });
        break;
      case "CodeMismatchException":
        setErrors({
          generalError: "",
          codeError: translations.forgotPasswordResetPassword.texts.invalidCode(),
        });
        break;
      case "ExpiredCodeException":
        setErrors({
          generalError: "",
          codeError: translations.forgotPasswordResetPassword.texts.codeExpired(),
        });
        break;
      case "AliasExistsException":
        setErrors({
          generalError: "",
          codeError: translations.common.texts.phoneNumberTaken(),
        });
        break;
      default:
        setErrors({
          generalError: translations.common.texts.unableToPerformAction(),
          codeError: "",
        });
        break;
    }
  };

  const handleCodeChange = (code: string): void => {
    setCode(code);
  };

  const handleResendClick = async (): Promise<void> => {
    AuthInputRef.current?.clear();
    clearErrorsAndSuccess();
    try {
      setIsLoading(true);
      await AuthWrapper.setPhoneNumber(trimmedPhoneNumber);
      setSuccess(translations.smsVerification.texts.verificationCodeReSent());
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await AuthWrapper.verifyPhoneNumber(code);
      setSuccess(translations.common.texts.profileInfoUpdated());
      handleClose();
    } catch (error) {
      if (isErrorWithCode(error)) handleErrors(error.code);
    } finally {
      setIsLoading(false);
    }
  };

  const infoText = translations.smsVerification.texts
    .subtitle1()
    .concat(" ", translations.smsVerification.texts.subtitle2({ countryCode, phoneNumber }));

  const isVerifyButtonDisabled = code.length !== 6 || isLoading;

  return (
    <Dialog open={open} data-testid={TestIds.SmsVerificationDialog.Dialog}>
      <DialogTitle variant="h5">
        {translations.smsVerification.texts.title()}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          data-testid={TestIds.SmsVerificationDialog.CloseButton}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, display: "flex", flexDirection: "column" }}>
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: infoText,
          }}
        />
        <AuthCode
          allowedCharacters="numeric"
          onChange={handleCodeChange}
          containerClassName="verification-code-container"
          inputClassName={errors.codeError.length > 0 ? "verification-code-input-error" : "verification-code-input"}
          ref={AuthInputRef}
        />
        {errors.codeError.length > 0 && <Typography className="verification-code-error">{errors.codeError}</Typography>}
        <Link
          href="#"
          underline="hover"
          variant="body1"
          onClick={handleResendClick}
          data-testid={TestIds.SmsVerificationDialog.ResendCodeLink}
          sx={{ alignSelf: "flex-end", mt: errors.codeError.length > 0 ? 1 : 2 }}
        >
          {translations.smsVerification.buttons.resendCode()}
        </Link>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", m: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmitClick}
          disabled={isVerifyButtonDisabled}
          data-testid={TestIds.SmsVerificationDialog.SubmitButton}
        >
          {translations.smsVerification.buttons.submit()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsVerificationDialog;
