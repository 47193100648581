/* Copyright */
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { Maybe } from "@sade/data-access";
import React, { useEffect } from "react";
import { LocationData } from "../../../../utils/mapUtils";
import { calculateFromNow } from "../../../../utils/utils";

const TIMESTAMP_UPDATE_INTERVAL_ONE_MINUTE = 60000;

type LocationHistoryTableProps = {
  locationsOnHistoryList: Maybe<LocationData[]>;
  handleSelectedLocationsToShowChange: (location: LocationData) => void;
};

const LocationHistoryTable: React.FC<LocationHistoryTableProps> = ({
  locationsOnHistoryList,
  handleSelectedLocationsToShowChange,
}) => {
  const elementMaxHeight = window.innerHeight - 470;

  const [timeFrom, setTimeFrom] = React.useState<Map<string, string>>(new Map());

  useEffect(() => {
    const updateTimes = (): void => {
      const times: Map<string, string> = new Map();
      locationsOnHistoryList?.forEach((location) => {
        times.set(location.event.id, location.event.timestamp ? calculateFromNow(location.event.timestamp) : "");
      });
      setTimeFrom(times);
    };
    updateTimes();
    const interval = setInterval(() => {
      updateTimes();
    }, TIMESTAMP_UPDATE_INTERVAL_ONE_MINUTE);
    return (): void => {
      clearInterval(interval);
    };
  }, [locationsOnHistoryList]);

  return (
    <TableContainer sx={{ overflow: "auto", maxHeight: elementMaxHeight }}>
      <Table size="small">
        <TableBody>
          {locationsOnHistoryList?.map((location) => (
            <TableRow key={Number(location.event.timestamp)}>
              <TableCell>
                <Typography variant="body2">{location.index}</Typography>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={location.showOnMap}
                  onChange={(): void => handleSelectedLocationsToShowChange(location)}
                ></Checkbox>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {location.event.address ?? `${location.event.latitude}, ${location.event.longitude}`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{timeFrom.get(location.event.id)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LocationHistoryTable;
