/* Copyright */
import { Box, Typography } from "@mui/material";
import { Event, LocationMethod, Maybe, Nullable } from "@sade/data-access";
import * as React from "react";
import { renderToString } from "react-dom/server";

import theme from "../../../../app/theme";
import AnyFixIcon from "../../../../assets/icons/Legend-LatestFix-AnyType.svg";
import HomeIcon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-HomeAddress.svg";
import NetworkFixIcon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-NetworkFix.svg";
import SatelliteFixIcon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-SatelliteFix.svg";
import WifiFixIcon from "../../../../assets/icons/active-call-map-icons/ActiveCallMapIcon-WifiFix.svg";
import { resolveCallEventName } from "../../../../utils/eventUtils";
import { geocodeAddress } from "../../../../utils/geocodeUtils";
import { LocationData, customMapStyle, getLocationHistoryMarker } from "../../../../utils/mapUtils";
import { formatTimestamp } from "../../../../utils/utils";
import EventsMapNoLocationDataOverlay from "../../../common/events-map-no-location-data-overlay";
import EventMapDefinitionOverlay from "./event-map-definition-overlay";

const DEFAULT_CENTER = { lat: 40.840211, lng: -97.497453 };
const DEFAULT_ZOOM = 4;

type EventMapProps = {
  callEvent: Event;
  locationsOnMap: Maybe<LocationData[]>;
  homeAddress?: string;
};

const EventMap: React.FC<EventMapProps> = ({ callEvent, locationsOnMap, homeAddress }) => {
  const [initialized, setInitialized] = React.useState<boolean>(false);

  const infoWindowsRef = React.useRef(new Map());
  const ref = React.useRef<Nullable<HTMLDivElement>>(null);
  const mapRef = React.useRef<Nullable<google.maps.Map>>(null);
  const polyLine = React.useRef<Nullable<google.maps.Polyline>>(null);
  const accuracyCircle = React.useRef<Nullable<google.maps.Circle>>(null);
  const markersRef = React.useRef<google.maps.Marker[]>([]);
  const zoomRef = React.useRef<number>();
  const callEventMarker = React.useRef<google.maps.Marker>();
  const homeMarker = React.useRef<google.maps.Marker>();

  const resolveMapIcon = (location: LocationData): string | undefined => {
    if (location.index === 0) {
      switch (true) {
        case location.event.method === LocationMethod.Satellite:
          return SatelliteFixIcon;
        case location.event.method === LocationMethod.WiFi:
          return WifiFixIcon;
        case location.event.method === LocationMethod.Network:
          return NetworkFixIcon;
        default:
          return AnyFixIcon;
      }
    } else {
      return getLocationHistoryMarker(location.index);
    }
  };

  React.useEffect(() => {
    (async (): Promise<void> => {
      if (!homeAddress) return;
      try {
        const location = await geocodeAddress(homeAddress);
        if (location.latitude == null || location.longitude == null) return;
        homeMarker.current = new google.maps.Marker({
          position: new google.maps.LatLng({ lat: location.latitude, lng: location.longitude }),
          icon: HomeIcon,
          map: mapRef.current!,
        });
      } catch (error) {
        console.error(`Geocoding request failed with unknown error: ${error}`);
      }
    })();
  }, [homeAddress]);

  const infoWindowContent = (
    index?: number,
    header?: string,
    address?: string,
    timestamp?: Date
  ): React.ReactElement => {
    return (
      <Box>
        {index} {header ?? ""}
        <br />
        <br />
        <Typography variant="caption">{address}</Typography>
        <br />
        <Typography variant="caption">{timestamp && formatTimestamp(timestamp)}</Typography>
      </Box>
    );
  };

  const addLocationMarkers = React.useCallback((primaryEvent: Event, locationData: LocationData[]) => {
    const newMarkerLocations: google.maps.LatLng[] = [];
    const createInfoWindow = (marker: google.maps.Marker, content: string): void => {
      // If the marker already has an info window, don't create a new one
      // otherwise we'll end up with multiple info windows for same event
      // we need to update the content though
      if (infoWindowsRef.current.has(marker)) {
        infoWindowsRef.current.get(marker).setContent(content);
        return;
      }

      const infoWindow = new google.maps.InfoWindow({
        content,
      });

      marker.addListener("click", () => {
        infoWindowsRef.current.forEach((window) => window.close());
        infoWindow.open(mapRef.current!, marker);
      });

      infoWindowsRef.current.set(marker, infoWindow);
    };

    if (primaryEvent.latitude != null && primaryEvent.longitude != null) {
      const position = new google.maps.LatLng({ lat: primaryEvent.latitude, lng: primaryEvent.longitude });
      callEventMarker.current
        ? callEventMarker.current.set("position", position)
        : (callEventMarker.current = new google.maps.Marker({
            position,
            icon:
              primaryEvent.method === LocationMethod.Satellite
                ? SatelliteFixIcon
                : primaryEvent.method === LocationMethod.WiFi
                ? WifiFixIcon
                : primaryEvent.method === LocationMethod.Network
                ? NetworkFixIcon
                : AnyFixIcon,
            map: mapRef.current!,
          }));
      newMarkerLocations.push(position);

      if (primaryEvent.accuracy) {
        accuracyCircle.current
          ? accuracyCircle.current.set("center", position)
          : (accuracyCircle.current = new google.maps.Circle({
              center: position,
              radius: primaryEvent.accuracy,
              map: mapRef.current!,
              fillColor: theme.palette.primary.light,
              fillOpacity: 0.2,
              strokeColor: theme.palette.primary.light,
              strokeOpacity: 0.5,
              strokeWeight: 1,
            }));
      }

      createInfoWindow(
        callEventMarker.current,
        renderToString(
          infoWindowContent(1, resolveCallEventName(primaryEvent), primaryEvent.address, primaryEvent.updatedTimestamp)
        )
      );
    }

    const locations = locationData.filter(
      (location) => location.event.latitude != null && location.event.longitude != null && location.showOnMap
    );

    // If amount of visible locationData have changed, clear the old markers
    if (markersRef.current.length !== locations.length) {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    }

    locations.map((location, index) => {
      const position = new google.maps.LatLng({ lat: location.event.latitude!, lng: location.event.longitude! });
      let marker = index < markersRef.current.length ? markersRef.current[index] : undefined;
      if (marker) {
        marker.set("position", position);
        marker.set("icon", resolveMapIcon(location));
      } else {
        marker = new google.maps.Marker({
          position,
          icon: resolveMapIcon(location),
          map: mapRef.current!,
        });
        markersRef.current.push(marker);
      }

      createInfoWindow(
        marker,
        renderToString(infoWindowContent(location.index, undefined, location.event.address, location.event.timestamp))
      );

      newMarkerLocations.splice(location.index - 1, 0, position);

      mapRef.current!.setZoom(zoomRef.current ?? 16);
    });

    polyLine.current
      ? polyLine.current.setPath(newMarkerLocations)
      : (polyLine.current = new google.maps.Polyline({
          path: newMarkerLocations,
          geodesic: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: mapRef.current!,
        }));
  }, []);

  React.useEffect(() => {
    if (ref.current) {
      mapRef.current = new google.maps.Map(ref.current!, {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        streetViewControl: false,
        fullscreenControl: false,
        styles: customMapStyle,
      });

      mapRef.current.addListener("zoom_changed", () => {
        zoomRef.current = mapRef.current?.getZoom();
      });
    }

    setInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const resolveMapCenter = (event: Event): void => {
      if (event.latitude == null || event.longitude == null) return;
      const center = new google.maps.LatLng({ lat: event.latitude, lng: event.longitude });

      // Only recenter map if the location has changed, otherwise the user will be unable to pan the map
      // because the map will keep recentering to the same location
      if (
        center &&
        (center.lat() !== callEventMarker.current?.get("position").lat() ||
          center.lng() !== callEventMarker.current?.get("position").lng())
      ) {
        mapRef.current!.setCenter(center);
      }
    };

    if (initialized) {
      resolveMapCenter(callEvent);
      addLocationMarkers(callEvent, locationsOnMap ?? []);
    }
  }, [addLocationMarkers, callEvent, initialized, locationsOnMap]);

  const elementHeight = window.innerHeight - 150;

  const locationInfoAvailable =
    (callEvent.latitude != null && callEvent.longitude != null) ||
    locationsOnMap?.some(
      (location: LocationData) => location.event.latitude != null && location.event.longitude != null
    );

  return (
    <Box sx={{ position: "relative" }}>
      <Box ref={ref} sx={{ height: elementHeight, borderRadius: 1 }} />
      <Box sx={{ left: 0, bottom: 0, position: "absolute", zIndex: 1000, mb: 2, ml: 2 }}>
        <EventMapDefinitionOverlay />
      </Box>
      {!locationInfoAvailable && <EventsMapNoLocationDataOverlay />}
    </Box>
  );
};

export default EventMap;
