/* Copyright */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import CustomTextField from "../../../common/textfield";
import { PeripheralType } from "@sade/data-access";
import { Peripheral } from "../../../../utils/utils";

type DevicePeripheralsCompanionPendantAttachDialogProps = {
  open: boolean;
  handleClose: (peripheral?: Peripheral) => void;
};

const DevicePeripheralsCompanionPendantAttachDialog: React.FC<DevicePeripheralsCompanionPendantAttachDialogProps> = ({
  open,
  handleClose,
}) => {
  const [serialError, setSerialError] = React.useState<string>("");
  const [serialnumber, setSerialnumber] = React.useState<string>("");
  const [isBusy, setIsBusy] = React.useState<boolean>(false);

  const resetSettings = (): void => {
    setSerialError("");
    setSerialnumber("");
    setIsBusy(false);
  };

  const handleCloseClick = (): void => {
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      resetSettings();
    }
  }, [open]);

  const handleAttachClick = async (): Promise<void> => {
    setIsBusy(true);
    handleClose({ serialNumber: serialnumber, type: PeripheralType.BasicPendant });
  };

  const handleSerialChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSerialError("");
    setSerialnumber(event.target.value);
  };

  const validateForm = (): boolean => {
    return serialnumber.length > 0 ? true : false;
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        data-testid={TestIds.DevicePeripheralsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.devicePeripherals.texts.attachCompanionPendant()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl className="input" sx={{ textAlign: "start", mt: 3 }} fullWidth>
            <CustomTextField
              label={translations.common.texts.serialNumber()}
              id="pendant-serialnumber"
              errorMessage={serialError}
              testId={TestIds.AddNewAccountDialog.EmailInput}
              onChange={handleSerialChange}
              placeholder={translations.common.texts.serialNumber()}
              value={serialnumber}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="outlined"
            onClick={handleAttachClick}
            data-testid={TestIds.DevicePeripheralsDialog.AttachButton}
            disabled={isBusy || !validateForm()}
          >
            {translations.devicePeripherals.buttons.attach()}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CancelButton}
            disabled={isBusy}
          >
            {translations.common.buttons.cancel()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DevicePeripheralsCompanionPendantAttachDialog;
