/* Copyright */
import {
  Maybe,
  OrganizationParameterHierarchy,
  ParameterName,
  ParameterValueType,
  Parameters,
} from "@sade/data-access";
import { OrganizationInfo, ParameterNames, PartnerParameter } from "./utils";

export type ParameterOrigin = {
  parameterName: string;
  parameterValue: ParameterValueType;
  organizationId: string;
};

export type ParameterOriginWithName = {
  organizationName: string;
} & ParameterOrigin;

export type ExternalDeviceIdentifierParameter = {
  pattern: string;
  label: string;
  placeholder: string;
};

const DEFAULT_PARAMETER_ORIGINATOR = "-" as const;
const DEFAULT_FEATURE_STATE = true;

/**
 * Map organization parameters to a list of {@link ParameterOrigin} objects.
 *
 * @param orgParams - The organization parameters to be mapped.
 * @returns An array of {@link ParameterOrigin} objects.
 */
export const mapOrganizationParametersToList = (orgParams: { [key: string]: Parameters }): ParameterOrigin[] => {
  const parameterList: ParameterOrigin[] = [];
  Object.entries(orgParams).forEach(([orgId, parameters]) => {
    Object.entries(parameters).forEach(([parameterName, parameterValue]) => {
      parameterList.push({
        parameterName,
        parameterValue,
        organizationId: orgId,
      });
    });
  });

  return parameterList;
};

/**
 * Retrieve the originator of a parameter.
 *
 * If the parameter is overridden on the device level, it returns "Device-specific".
 * Otherwise, it retrieves the organization where the parameter is defined and returns the full name of the organization.
 *
 * @param parameterName - The name of the parameter.
 * @param parameterHierarchy - The parameter hierarchy.
 * @param organizationNameMap - The map of organization names.
 * @returns The originator of the parameter.
 */
export const getParameterOriginator = (
  parameterName: ParameterNames,
  parameterHierarchy: Maybe<OrganizationParameterHierarchy>,
  organizationNameMap: OrganizationInfo[]
): ParameterOriginWithName => {
  const defaultParameterOriginator = {
    organizationId: DEFAULT_PARAMETER_ORIGINATOR,
    organizationName: DEFAULT_PARAMETER_ORIGINATOR,
    parameterName: parameterName,
    parameterValue: "",
  } as ParameterOriginWithName;

  if (!parameterHierarchy?.deviceParameters) return defaultParameterOriginator;

  const originator = mapOrganizationParametersToList(parameterHierarchy.deviceParameters).find(
    (item) => item.parameterName === parameterName
  );

  if (!originator) return defaultParameterOriginator;

  const organizationName =
    organizationNameMap?.find((item) => item.id === originator?.organizationId)?.fullName ??
    DEFAULT_PARAMETER_ORIGINATOR;

  return {
    ...originator,
    organizationName,
  };
};

/**
 * Parse the given value to correct type.
 *
 * @param value - The value to be parsed.
 * @param type - The type to be parsed to.
 * @returns The parsed value.
 */
export const parseParameterValueType = (value: string, type: ParameterValueType): ParameterValueType => {
  switch (type) {
    case "string":
      return value;
    case "number":
      return Number(value);
    case "boolean":
      return value.toLowerCase() === "true";
    case "string[]":
      return value.split(",");
    case "json":
      return value === "" ? value : JSON.parse(value);
    default:
      return value;
  }
};

/**
 * Check if a given feature is enabled or not.
 *
 * Features are enabled/disabled through system parameters.
 * @param featureName - The name of the feature to check.
 * @returns A boolean indicating whether the feature is enabled or not.
 */
export const isFeatureEnabled = (
  featureName: ParameterNames,
  parameterHierarchy?: OrganizationParameterHierarchy
): boolean => {
  if (!parameterHierarchy?.systemParameters) return DEFAULT_FEATURE_STATE;

  const parameterOrigins = mapOrganizationParametersToList(parameterHierarchy?.systemParameters);
  const featureParameter = parameterOrigins.find((parameter) => parameter.parameterName === featureName);

  if (!(typeof featureParameter?.parameterValue === "boolean")) return DEFAULT_FEATURE_STATE;
  return featureParameter.parameterValue;
};

/**
 * Retrieve the value of a parameter from the parameter hierarchy.
 * @param parameterName - The name of the parameter to retrieve.
 * @param parameterHierarchy - The hierarchy of organization parameters.
 * @param parameterType - The type of the parameter to retrieve.
 * @returns The value of the parameter, or undefined if not found.
 */
export const getParameterFromHierarchy = (
  parameterName: ParameterNames,
  parameterHierarchy: OrganizationParameterHierarchy,
  parameterType: "device" | "system"
): Maybe<ParameterValueType> => {
  let parameterOrigins: ParameterOrigin[] = [];
  if (parameterType === "device" && parameterHierarchy.deviceParameters) {
    parameterOrigins = mapOrganizationParametersToList(parameterHierarchy.deviceParameters);
  }
  if (parameterType === "system" && parameterHierarchy.systemParameters) {
    parameterOrigins = mapOrganizationParametersToList(parameterHierarchy.systemParameters);
  }

  const parameter = parameterOrigins.find((parameter) => parameter.parameterName === parameterName);

  return parameter?.parameterValue;
};

/**
 * Filter out parameters which are disabled by system parameters.
 * @param params List of parameters to filter
 * @param parameterHierarchy The hierarchy of organization parameters
 * @returns List of parameters which are not disabled by system parameters
 */
export const filterBasedOnSystemParameters = <T extends (ParameterName | PartnerParameter)[]>(
  params: T,
  parameterHierarchy: OrganizationParameterHierarchy
): T => {
  let result: T = [...params] as T;

  // Return true if parameter names match
  const nameMatch = (param: ParameterName | PartnerParameter, parameterName: ParameterNames): boolean => {
    if ("name" in param) {
      return param.name === parameterName;
    }
    // if param is of type PartnerParameter, then check the parameterName
    return param.parameterName === parameterName;
  };

  if (!isFeatureEnabled(ParameterNames.SILENT_MODE_ENABLED, parameterHierarchy)) {
    result = result.filter(
      (param) =>
        !nameMatch(param, ParameterNames.SILENT_MODE_ENABLED) &&
        !nameMatch(param, ParameterNames.FALL_DETECTION_ENABLED)
    ) as T;
  }

  if (!isFeatureEnabled(ParameterNames.EVERTHERE_ENABLED, parameterHierarchy)) {
    result = result.filter((param) => !nameMatch(param, ParameterNames.EVERTHERE_ENABLED)) as T;
  }

  if (!isFeatureEnabled(ParameterNames.FIND_MY_DEVICE_ENABLED, parameterHierarchy)) {
    result = result.filter((param) => !nameMatch(param, ParameterNames.FIND_MY_DEVICE_ENABLED)) as T;
  }

  if (!isFeatureEnabled(ParameterNames.DEMO_MODE_ENABLED, parameterHierarchy)) {
    result = result.filter((param) => !nameMatch(param, ParameterNames.DEMO_MODE_ENABLED)) as T;
  }

  return result;
};
