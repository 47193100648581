/* Copyright */
import {
  Box,
  Card,
  CardContent,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { AWSLatestData, getSignalStrengthLevel } from "@sade/data-access";
import React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { formatTimestamp } from "../../../utils/utils";
import BatteryLevel from "../../common/battery-level";
import CardEmptyState from "../../common/card-empty-state";
import NetworkSignal from "../../common/network-signal";
import CardContentSkeleton from "../../loading/card-content-skeleton";

type DeviceViewContentStatusProps = {
  isLoading: boolean;
  latestData?: AWSLatestData;
  disabled?: boolean;
};

const DeviceViewContentStatus: React.FC<DeviceViewContentStatusProps> = ({ isLoading, latestData, disabled }) => {
  const [signalStrength, setSignalStrength] = React.useState<number>(0);

  React.useEffect(() => {
    if (latestData && latestData.rssi != null && latestData.rsrp != null && latestData.networkType != null) {
      setSignalStrength(getSignalStrengthLevel(latestData.rssi, latestData.rsrp, latestData.networkType));
    }
  }, [latestData]);

  return (
    <Card sx={{ mb: 3 }} variant="outlined" data-testid={TestIds.DeviceDetails.StatusCard}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, ml: 3, mr: 4, mb: 2 }}>
        <Typography variant="h5">{translations.common.texts.status()}</Typography>
        <Typography variant="caption">
          {latestData?.updatedTime ? formatTimestamp(latestData.updatedTime) : ""}
        </Typography>
      </Box>
      {isLoading && !latestData ? (
        <CardContentSkeleton />
      ) : (
        <CardContent>
          {latestData ? (
            <Table
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <InputLabel sx={{ fontSize: "12px", mb: 1 }}>{translations.common.texts.batteryLevel()}</InputLabel>
                  </TableCell>
                  <TableCell>
                    {latestData?.batteryLevel && (
                      <BatteryLevel disabled={disabled} batteryLevel={latestData.batteryLevel} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <InputLabel sx={{ fontSize: "12px", mb: 1 }}>
                      {translations.common.texts.networkSignal()}
                    </InputLabel>
                  </TableCell>
                  <TableCell>
                    {latestData?.batteryLevel && (
                      <NetworkSignal
                        disabled={disabled}
                        signalStrength={signalStrength}
                        networkType={latestData?.networkType ?? ""}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <CardEmptyState emptyStateText={translations.deviceStatus.texts.emptyStateText()} />
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default DeviceViewContentStatus;
