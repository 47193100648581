/* Copyright */
import * as React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";

type SaveChangesDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

const SaveChangesDialog: React.FC<SaveChangesDialogProps> = ({ open, onClose, onSave }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.advancedSettingsDialog.texts.saveChangesTitle()}
        <IconButton
          data-testid={TestIds.DeviceAdvancedSettingsDialog.SaveChangesDialogClose}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{translations.advancedSettingsDialog.texts.saveChangesInfo()}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "start", m: 2 }}>
        <Button
          variant="contained"
          onClick={(): void => onSave()}
          data-testid={TestIds.DeviceAdvancedSettingsDialog.SaveChangesDialogSave}
        >
          {translations.common.buttons.saveChanges()}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          data-testid={TestIds.DeviceAdvancedSettingsDialog.SaveChangesDialogCancel}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveChangesDialog;
