/* Copyright */
import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import DashboardIcon from "../../../../assets/icons/dashboard-icon";
import DevicesIcon from "../../../../assets/icons/devices-icon";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { useAccessControl } from "../../../../hooks/useAccessControl";
import { RoleIdentifier } from "@sade/data-access";
import { getPath } from "../../../../utils/ssoPathUtil";
import DeviceBulkActionsDialog from "../../../dialogs/device-bulk-actions-dialog/device-bulk-actions-dialog";
import { Paths } from "../../../../utils/utils";

const NavigationDrawerItemList: React.FC = () => {
  const theme = useTheme();
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [isDeviceBulkActionsDialogOpen, setIsDeviceBulkActionsDialogOpen] = React.useState<boolean>(false);

  const toggleIsDeviceBulkActionsDialogOpen = (): void => {
    setIsDeviceBulkActionsDialogOpen(!isDeviceBulkActionsDialogOpen);
  };

  const handleAdminOpen = (): void => {
    setAdminOpen(!adminOpen);
  };

  const hasMonitoringViewRights = useAccessControl(RoleIdentifier.Supervisor, RoleIdentifier.Operator);

  const hasDevicesViewRights = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.Supervisor,
    RoleIdentifier.Operator,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent,
    RoleIdentifier.WarehouseInstaller
  );

  const hasAccountsViewRights = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.Supervisor,
    RoleIdentifier.DealerManager,
    RoleIdentifier.DealerAgent
  );

  const hasReportsViewRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.DealerManager);

  const hasPartnersViewRights = useAccessControl(RoleIdentifier.PowerUser);

  const hasAdminRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.Viewer);

  const hasDeviceReportsRights = useAccessControl(RoleIdentifier.PowerUser, RoleIdentifier.Viewer);

  const hasBulkActionsRights = useAccessControl(RoleIdentifier.PowerUser);

  const isSuperUser = useAccessControl();

  return (
    <List sx={{ pl: 1 }} data-testid={TestIds.Navigation.NavigationDrawerItemList}>
      {hasMonitoringViewRights && (
        <ListItemButton component={Link} to={getPath(Paths.MONITORING)}>
          <ListItemIcon sx={{ paddingLeft: 0.75 }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={translations.appbar.links.monitoring()} />
        </ListItemButton>
      )}
      {hasDevicesViewRights && (
        <ListItemButton component={Link} to={getPath(Paths.DEVICES)}>
          <ListItemIcon sx={{ paddingLeft: 0.75 }}>
            <DevicesIcon />
          </ListItemIcon>
          <ListItemText primary={translations.common.texts.devices()} />
        </ListItemButton>
      )}
      {hasAccountsViewRights && (
        <ListItemButton component={Link} to={getPath(Paths.ACCOUNTS)}>
          <ListItemIcon>
            <GroupOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={translations.appbar.links.accounts()} />
        </ListItemButton>
      )}
      {hasReportsViewRights && (
        <ListItemButton component={Link} to={getPath(Paths.REPORTS)}>
          <ListItemIcon>
            <ArticleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={translations.appbar.links.reports()} />
        </ListItemButton>
      )}
      {hasPartnersViewRights && (
        <ListItemButton component={Link} to={getPath(Paths.PARTNERS)}>
          <ListItemIcon>
            <AccountTreeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={translations.appbar.links.partners()} />
        </ListItemButton>
      )}
      <ListItemButton href={getPath(Paths.SUPPORT)}>
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        <ListItemText primary={translations.appbar.links.support()} />
      </ListItemButton>
      <ListItemButton component={Link} to={getPath(Paths.WHERE_TO_BUY)}>
        <ListItemIcon>
          <OpenInNewIcon />
        </ListItemIcon>
        <ListItemText primary={translations.common.links.whereToBuy()} />
      </ListItemButton>
      {hasAdminRights && (
        <React.Fragment>
          <ListItemButton onClick={handleAdminOpen}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={translations.appbar.links.admin()} />
            {adminOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={adminOpen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 7 }}>
              {hasDeviceReportsRights && (
                <ListItemButton component="a" href={getPath(Paths.DEVICE_REPORTS)}>
                  <ListItemText
                    secondary={translations.adminMenu.texts.sendDeviceCommands()}
                    secondaryTypographyProps={{ color: theme.palette.secondary.dark }}
                  />
                </ListItemButton>
              )}
              {hasBulkActionsRights && (
                <ListItemButton onClick={toggleIsDeviceBulkActionsDialogOpen}>
                  <ListItemText
                    secondary={translations.common.texts.deviceBulkActions()}
                    secondaryTypographyProps={{ color: theme.palette.secondary.dark }}
                  />
                </ListItemButton>
              )}
              {isSuperUser && (
                <ListItemButton component="a" href={getPath(Paths.OTA_PACKAGES)}>
                  <ListItemText
                    secondary={translations.adminMenu.texts.otaUpdates()}
                    secondaryTypographyProps={{ color: theme.palette.secondary.dark }}
                  />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          <DeviceBulkActionsDialog
            open={isDeviceBulkActionsDialogOpen}
            handleClose={toggleIsDeviceBulkActionsDialogOpen}
          />
        </React.Fragment>
      )}
    </List>
  );
};

export default NavigationDrawerItemList;
