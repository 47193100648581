/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { AuthWrapper } from "./AuthWrapper";
import { Hub } from "@aws-amplify/core";
export class AuthListener {
    constructor(callback, notifyRetroactively) {
        this.removeHandle = Hub.listen("auth", (data) => AuthListener.listener(data.payload.event, callback));
        if (notifyRetroactively) {
            // if the has authenticated before the listener is registered,
            // retroactively notify the listener creator about the situation
            AuthWrapper.isCurrentUserAuthenticated()
                .then((authenticated) => {
                if (authenticated) {
                    callback("SignedIn");
                }
            }, void 0)
                .catch((error) => console.error(error));
        }
    }
    static listener(event, callback) {
        switch (event) {
            case "signIn":
                callback("SignedIn");
                break;
            case "signUp":
                callback("SignedUp");
                break;
            case "signOut":
                callback("SignedOut");
                break;
            case "signIn_failure":
                callback("SignInFailed");
                break;
        }
    }
    removeListener() {
        var _a;
        (_a = this.removeHandle) === null || _a === void 0 ? void 0 : _a.call(this);
        this.removeHandle = undefined;
    }
}
