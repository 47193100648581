/* Copyright */
import { Box, SelectChangeEvent } from "@mui/material";
import {
  BackendFactory,
  EventPushType,
  Maybe,
  Organization,
  OrganizationApiKeyInfo,
  OrganizationParameterHierarchy,
  OrganizationParameterSet,
  ParameterName,
  ParameterType,
  ParameterValueType,
  Parameters,
  PushEventConfiguration,
  RoleIdentifier,
  isBaseOrganizationId,
} from "@sade/data-access";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuthenticatedUser } from "../../context/authenticated-user-context";
import { SeverityType, SnackbarHorizontalPosition, SnackbarVerticalPosition } from "../../context/snackbar-context";
import { translations } from "../../generated/translationHelper";
import { useSnackbar } from "../../hooks/useSnackbar";
import TestIds from "../../test-ids/test-ids";
import { parseParameterValueType } from "../../utils/parameterUtils";
import {
  EventPushSettings,
  OrganizationInfo,
  PartnerDetails,
  PartnerParameter,
  generateRandomKey,
  getChildOrganizations,
} from "../../utils/utils";
import { validateUrl } from "../../utils/validation";
import AccessControl from "../access-control/access-control";
import Loading from "../loading/loading";
import PartnerDetailsViewBasicSettings from "./partner-details-view-basic-settings";
import PartnerDetailsViewHeader from "./partner-details-view-header";
import PartnerDetailsViewParameterSettings from "./partner-details-view-parameter-settings";
import PartnerDetailsViewPushSettings from "./partner-details-view-push-settings";

const PartnerDetailsView: React.FC = () => {
  const { partnerId } = useParams();
  const { currentOrganization } = useAuthenticatedUser();
  const backend = BackendFactory.getOrganizationBackend();
  const parameterBackend = BackendFactory.getParameterBackend();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [partner, setPartner] = React.useState<Organization>();
  const [partnerDetails, setPartnerDetails] = React.useState<PartnerDetails>({
    parentId: "",
    parentName: "",
    partnerName: "",
    realmName: "",
    networkId: "",
    netsuiteId: "",
  });
  const [savedEventPushSettings, setSavedEventPushSettings] = React.useState<Maybe<PushEventConfiguration>>();
  const [eventPushSettings, setEventPushSettings] = React.useState<EventPushSettings>({
    enabled: false,
    pushUrl1: "",
    pushUrl2: "",
    pushUrl3: "",
    eventPushType: EventPushType.Put,
    pushRules: "",
    pushConfiguration: "",
  });
  const [deviceParameters, setDeviceParameters] = React.useState<PartnerParameter[]>([
    { parameterName: "", parameterValue: "", parameterType: "string" },
  ]);
  const [systemParameters, setSystemParameters] = React.useState<PartnerParameter[]>([
    { parameterName: "", parameterValue: "", parameterType: "string" },
  ]);
  const [deviceParameterOptions, setDeviceParameterOptions] = React.useState<ParameterName[]>();
  const [systemParameterOptions, setSystemParameterOptions] = React.useState<ParameterName[]>();
  const [childOrganizations, setChildOrganizations] = React.useState<OrganizationInfo[]>([]);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  // Manage validation state for each parameter. Key is the parameter name and value is the validation result
  // (true = validation good, false = validation failed).
  const [parameterValidationMap, setParameterValidationMap] = React.useState<{ [key: string]: boolean }>({});
  // Currently only one API key is supported, but in future it is possible that there will be more than one.
  // So we use an array here, to prepare for that.
  const [partnerApiKeys, setPartnerApiKeys] = React.useState<OrganizationApiKeyInfo[]>([]);
  const [parameterHierarchy, setParameterHierarchy] = React.useState<Maybe<OrganizationParameterHierarchy>>();
  const originalKeys = React.useRef<OrganizationApiKeyInfo[]>([]);

  const fullPartnerId = partnerId && "ORG/" + partnerId;

  const isAddNewMode = !fullPartnerId;

  const isParametersEmpty = (parameters: Parameters): boolean => {
    return Object.keys(parameters).length === 0;
  };

  const typedParameters = (): OrganizationParameterSet => ({
    deviceParameters: Object.fromEntries(
      deviceParameters
        .filter((param) => param.parameterName !== "")
        .map((param) => [
          param.parameterName,
          typeof param.parameterValue === "object"
            ? param.parameterValue
            : parseParameterValueType(String(param.parameterValue), param.parameterType),
        ])
    ),
    systemParameters: Object.fromEntries(
      systemParameters
        .filter((param) => param.parameterName !== "")
        .map((param) => [
          param.parameterName,
          typeof param.parameterValue === "object"
            ? param.parameterValue
            : parseParameterValueType(String(param.parameterValue), param.parameterType),
        ])
    ),
  });

  /**
   * Initialize the state for partner device and system parameters.
   * @param partnerParameters Parameters from the partner
   * @param deviceParameterOptions Supported device parameters
   * @param systemParameterOptions Supported system parameters
   */
  const handleInitialParameters = (
    partnerParameters: OrganizationParameterSet,
    deviceParameterOptions: ParameterName[],
    systemParameterOptions: ParameterName[]
  ): void => {
    if (partnerParameters.deviceParameters && !isParametersEmpty(partnerParameters.deviceParameters)) {
      const deviceParams: PartnerParameter[] = Object.entries(partnerParameters.deviceParameters).map((param) => ({
        parameterName: param[0],
        parameterValue: param[1],
        // Add the parameter value type so that the value can be validated
        parameterType: deviceParameterOptions.find((option) => option.name === param[0])?.valueType ?? "string",
      }));

      setDeviceParameters(deviceParams);
    }
    if (partnerParameters.systemParameters && !isParametersEmpty(partnerParameters.systemParameters)) {
      const systemParams: PartnerParameter[] = Object.entries(partnerParameters.systemParameters).map((param) => ({
        parameterName: param[0],
        parameterValue: param[1],
        // Add the parameter value type so that the value can be validated
        parameterType: systemParameterOptions.find((option) => option.name === param[0])?.valueType ?? "string",
      }));

      setSystemParameters(systemParams);
    }
  };

  /**
   * Get list of supported device, api keys and system parameters as well as the partner parameters and initialize the states.
   * @param partner Partner organization
   */
  const initializeParametersAndApiKeys = async (partner: Maybe<Organization>): Promise<void> => {
    const apiKeyIds = partner?.getApiKeyIds();
    const [deviceParameterOptions, systemParameterOptions, partnerParameters, apiKeys] = await Promise.all([
      parameterBackend.listDeviceParameters(),
      parameterBackend.listSystemParameters(),
      partner?.getParameters(),
      apiKeyIds?.length ? await backend.getOrganizationApiKeys(apiKeyIds) : [],
    ]);
    // Add META parameters to the system parameter list
    const systemAndMetaParameterOptions = systemParameterOptions.concat(
      deviceParameterOptions.map((param) => ({
        name: `META_${param.name}`,
        legacyName: param.legacyName,
        type: ParameterType.system,
        valueType: "json",
      }))
    );

    originalKeys.current = apiKeys;
    setPartnerApiKeys([...originalKeys.current]);
    setDeviceParameterOptions(deviceParameterOptions);
    setSystemParameterOptions(systemAndMetaParameterOptions);

    partnerParameters &&
      handleInitialParameters(partnerParameters, deviceParameterOptions, systemAndMetaParameterOptions);
  };

  React.useEffect(
    () => {
      (async (): Promise<void> => {
        setIsLoading(true);

        try {
          let partner: Maybe<Organization>;
          if (fullPartnerId) {
            partner = await backend.getOrganization(fullPartnerId ?? "");
            setPartner(partner);
            const [partnerParent, parameterHierarchy] = await Promise.all([
              partner?.getParentOrganization(),
              partner?.getParameterHierarchy(),
            ]);
            setPartnerDetails({
              parentId: partnerParent?.getId() ?? "",
              parentName: partnerParent?.getName() ?? "",
              partnerName: partner?.getName() ?? "",
              realmName: partner?.getRealmName() ?? "",
              networkId: partner?.getNetworkId() ?? "",
              netsuiteId: partner?.getNetsuiteId() ?? "",
              patientTextUrl: partner?.getPatientTextUrl() ?? "",
              ssoIdentityProvider: partner?.getSsoConfiguration()?.identityProvider ?? "",
              ssoDefaultRole: partner?.getSsoConfiguration()?.defaultRole ?? "",
            });
            setParameterHierarchy(parameterHierarchy);
            const eventPushSettings = await partner?.getEventPushConfiguration();
            setSavedEventPushSettings(eventPushSettings);
            if (eventPushSettings) {
              const urlCount = eventPushSettings?.url.length ?? 0;
              setEventPushSettings({
                enabled: eventPushSettings.enabled,
                pushUrl1: urlCount > 0 ? eventPushSettings.url[0] : "",
                pushUrl2: urlCount > 1 ? eventPushSettings.url[1] : "",
                pushUrl3: urlCount > 2 ? eventPushSettings.url[2] : "",
                eventPushType: eventPushSettings.type ?? "",
                pushRules: eventPushSettings.rule ?? "",
                pushConfiguration: eventPushSettings.configuration ?? "",
              });
            }
          } else {
            const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
            setChildOrganizations(childOrganizationList);
          }

          await initializeParametersAndApiKeys(partner);
        } catch (error) {
          console.error(error);
          showSnackbar(translations.common.texts.generalDataFetchError(), SeverityType.Error);
        } finally {
          setIsLoading(false);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fullPartnerId, currentOrganization]
  );

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const updatedPartnerDetails = { ...partnerDetails, [event.target.name]: event.target.value };
    setPartnerDetails(updatedPartnerDetails);
    setIsDirty(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedPartnerDetails = { ...partnerDetails, [event.target.id]: event.target.value };
    setPartnerDetails(updatedPartnerDetails);
    setIsDirty(true);
  };

  const handleOrganizationCreation = async (): Promise<void> => {
    const partner = await currentOrganization?.createOrganization({
      name: partnerDetails.partnerName,
      organizationId: partnerDetails.parentId,
      realmName: partnerDetails.realmName,
      networkId: partnerDetails.networkId,
      netsuiteId: partnerDetails.netsuiteId ?? "",
      patientTextUrl: partnerDetails.patientTextUrl,
      ssoConfiguration: partnerDetails.ssoIdentityProvider
        ? {
            identityProvider: partnerDetails.ssoIdentityProvider,
            defaultRole: partnerDetails.ssoDefaultRole !== "" ? partnerDetails.ssoDefaultRole : undefined,
          }
        : undefined,
      apiKeyIds: partnerApiKeys.map((apiKey) => apiKey.keyId),
    });

    await partner?.updateParameters(typedParameters());

    if (eventPushSettings.enabled) {
      const urls = [eventPushSettings.pushUrl1, eventPushSettings.pushUrl2, eventPushSettings.pushUrl3].filter(
        (url) => url.length > 0
      );
      await partner?.updateEventPushConfiguration({
        enabled: eventPushSettings.enabled,
        url: urls,
        type: eventPushSettings.eventPushType,
        rule: eventPushSettings.pushRules,
        configuration: eventPushSettings.pushConfiguration,
      });
    }

    if (partnerApiKeys.length > 0) {
      partnerApiKeys.forEach((apiKey) =>
        backend.addOrganizationApiKey(partner?.getId() ?? "", apiKey.keyId, apiKey.secretKey)
      );
    }
  };

  const handleOrganizationUpdate = async (): Promise<void> => {
    const updatePromise = partner?.updateDetails({
      name: partnerDetails.partnerName,
      realmName: partnerDetails.realmName,
      networkId: partnerDetails.networkId,
      netsuiteId: partnerDetails.netsuiteId ?? "",
      patientTextUrl: partnerDetails.patientTextUrl,
      ssoConfiguration: partnerDetails.ssoIdentityProvider
        ? {
            identityProvider: partnerDetails.ssoIdentityProvider,
            defaultRole: partnerDetails.ssoDefaultRole !== "" ? partnerDetails.ssoDefaultRole : undefined,
          }
        : undefined,
      apiKeyIds: partnerApiKeys.map((apiKey) => apiKey.keyId),
    });
    const updateParamsPromise = partner?.updateParameters(typedParameters());
    await Promise.all([updatePromise, updateParamsPromise]);

    if (eventPushSettings.enabled || (savedEventPushSettings && eventPushSettings.enabled === false)) {
      const urls = [eventPushSettings.pushUrl1, eventPushSettings.pushUrl2, eventPushSettings.pushUrl3].filter(
        (url) => url.length > 0
      );
      await partner?.updateEventPushConfiguration({
        enabled: eventPushSettings.enabled,
        url: urls,
        type: eventPushSettings.eventPushType,
        rule: eventPushSettings.pushRules,
        configuration: eventPushSettings.pushConfiguration,
      });
    }
    if (partner?.getId()) {
      const keysToAdd = partnerApiKeys.filter((apiKey) => !originalKeys.current.includes(apiKey));
      const keysToRemove = originalKeys.current.filter((apiKey) => !partnerApiKeys.includes(apiKey));
      // Add and remove API keys if needed
      await Promise.all([
        keysToAdd.map((apiKey) => backend.addOrganizationApiKey(partner.getId(), apiKey.keyId, apiKey.secretKey)),
        keysToRemove.map((apiKey) => backend.removeOrganizationApiKey(apiKey.keyId)),
      ]);
    }
  };

  const handleSaveClick = async (): Promise<void> => {
    setIsSaving(true);
    try {
      if (isAddNewMode) {
        await handleOrganizationCreation();
      } else {
        await handleOrganizationUpdate();
      }

      showSnackbar(
        translations.common.texts.generalSaveSucceed(),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
      navigate(-1);
    } catch (error) {
      console.error(error);
      showSnackbar(
        translations.common.texts.generalSaveError(),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeletePartnerClick = async (): Promise<void> => {
    setIsSaving(true);
    try {
      await partner?.delete();
      showSnackbar(
        translations.partners.texts.partnerDeletionSuccessful(),
        SeverityType.Success,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
      navigate(-1);
    } catch (error) {
      console.error(error);
      showSnackbar(
        translations.partners.texts.partnerDeletionFailed(),
        SeverityType.Error,
        SnackbarVerticalPosition.Bottom,
        SnackbarHorizontalPosition.Center
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleToggleEventPushEnabled = (): void => {
    setIsDirty(true);
    setEventPushSettings({ ...eventPushSettings, enabled: !eventPushSettings.enabled });
  };

  const handlePushTypeSelectChange = (event: SelectChangeEvent): void => {
    const updatedEventPushSettings = { ...eventPushSettings, [event.target.name]: event.target.value };
    setEventPushSettings(updatedEventPushSettings);
    setIsDirty(true);
  };

  const handlePushSettingInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedEventPushSettings = { ...eventPushSettings, [event.target.id]: event.target.value };
    setEventPushSettings(updatedEventPushSettings);
    setIsDirty(true);
  };

  const stateMap: { [key: string]: [PartnerParameter[], React.Dispatch<React.SetStateAction<PartnerParameter[]>>] } = {
    device: [deviceParameters, setDeviceParameters],
    system: [systemParameters, setSystemParameters],
  };

  const handleAddParameter = (parameterType: string): void => {
    // Initially the empty parameter will have value type "string". This need to be changed once the user selects the parameter name.
    const newParameter: PartnerParameter = { parameterName: "", parameterValue: "", parameterType: "string" };

    const [currentParameters, setCurrentParameters] = stateMap[parameterType];

    const updatedParameters = [...currentParameters, newParameter];
    setCurrentParameters(updatedParameters);
  };

  const handleRemoveParameter = (parameterType: ParameterType, parameterName: string): void => {
    const [currentParameters, setCurrentParameters] = stateMap[parameterType];

    if (parameterName) {
      let updatedParameters = currentParameters.filter((param) => param.parameterName !== parameterName);

      if (updatedParameters.length === 0) {
        updatedParameters = [...updatedParameters, { parameterName: "", parameterValue: "", parameterType: "string" }];
      }
      setCurrentParameters(updatedParameters);
      setIsDirty(true);
    }
  };

  const handleParameterNameChange = (parameterType: ParameterType, parameterName: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentParameters, setCurrentParameters] = stateMap[parameterType];

    // Find out the parameter value type from the options
    const parameterValueType =
      parameterType === ParameterType.device
        ? deviceParameterOptions?.find((option) => option.name === parameterName)?.valueType ?? "string"
        : systemParameterOptions?.find((option) => option.name === parameterName)?.valueType ?? "string";

    if (currentParameters.some((param) => param.parameterName === parameterName)) {
      alert(translations.common.texts.existingParameterAlert());
    } else {
      setCurrentParameters((prevParameters) =>
        prevParameters.map((parameter) => {
          if (parameter.parameterName === "") {
            return { ...parameter, parameterName, parameterType: parameterValueType };
          } else {
            return parameter;
          }
        })
      );
    }
  };

  const handleParameterValueChange = (
    parameterType: ParameterType,
    parameterName: string,
    parameterValue: ParameterValueType
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentParameters, setCurrentParameters] = stateMap[parameterType];

    setCurrentParameters((prevParameters) =>
      prevParameters.map((parameter) => {
        if (parameter.parameterName === parameterName) {
          return { ...parameter, parameterValue };
        } else {
          return parameter;
        }
      })
    );
    setIsDirty(true);
  };

  const handleAddApiKey = (): void => {
    // generate a random API key
    setIsDirty(true);
    setPartnerApiKeys([...partnerApiKeys, { keyId: uuidv4(), secretKey: generateRandomKey(), organizationId: "" }]);
  };

  const handleRemoveApiKey = (keyId: string): void => {
    setIsDirty(true);
    const index = partnerApiKeys.indexOf(
      partnerApiKeys.find((apiKey) => apiKey.keyId === keyId) as OrganizationApiKeyInfo
    );
    if (index > -1) {
      partnerApiKeys.splice(index, 1);
      setPartnerApiKeys(partnerApiKeys);
    }
  };

  const handleParameterValueValidation = (parameter: string, validationResult: boolean): void => {
    setParameterValidationMap((prevMap) => ({ ...prevMap, [parameter]: validationResult }));
  };

  const validateUrls = (): boolean => {
    if (!eventPushSettings.pushUrl1 || !validateUrl(eventPushSettings.pushUrl1)) return false;
    if (eventPushSettings.pushUrl2.length > 0 && !validateUrl(eventPushSettings.pushUrl2)) return false;
    if (eventPushSettings.pushUrl3.length > 0 && !validateUrl(eventPushSettings.pushUrl3)) return false;
    return true;
  };

  // collect all mandatory checks here
  const isSavingDisabled =
    isSaving ||
    !isDirty ||
    // Root organization does not have parent but must be editable. For other realms the parent must be selected.
    (!partnerDetails.parentId && fullPartnerId && !isBaseOrganizationId(fullPartnerId)) ||
    !partnerDetails.partnerName ||
    !partnerDetails.realmName ||
    (eventPushSettings.enabled &&
      (!validateUrls() || !eventPushSettings.eventPushType || !eventPushSettings.pushRules)) ||
    (partnerDetails.patientTextUrl && !validateUrl(partnerDetails.patientTextUrl)) ||
    Object.values(parameterValidationMap).some((validationResult) => validationResult === false);

  return (
    <Box sx={{ padding: "2rem", overflow: "hidden" }} data-testid={TestIds.PartnerDetails.Dialog}>
      <AccessControl roles={[RoleIdentifier.PowerUser]}>
        {isLoading ? (
          <Loading height={36} />
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flex: 1 }}>
              <PartnerDetailsViewHeader
                isAddNewMode={isAddNewMode}
                isSaving={isSaving}
                isSavingDisabled={isSavingDisabled}
                partnerName={partnerDetails.partnerName}
                handleSaveClick={handleSaveClick}
                handleDeletePartnerClick={handleDeletePartnerClick}
              />
            </Box>
            <Box sx={{ overflow: "auto", maxHeight: "95%", maxWidth: "100%", pr: 3 }}>
              <PartnerDetailsViewBasicSettings
                isAddNewMode={isAddNewMode}
                partnerDetails={partnerDetails}
                childOrganizations={childOrganizations}
                parameterHierarchy={parameterHierarchy}
                handleSelectChange={handleSelectChange}
                handleInputChange={handleInputChange}
              />
              <PartnerDetailsViewPushSettings
                eventPushEnabled={eventPushSettings.enabled}
                handleToggleEventPushEnabled={handleToggleEventPushEnabled}
                eventPushSettings={eventPushSettings}
                handleInputChange={handlePushSettingInputChange}
                handleSelectChange={handlePushTypeSelectChange}
              />
              <PartnerDetailsViewParameterSettings
                deviceParameterOptions={deviceParameterOptions}
                systemParameterOptions={systemParameterOptions}
                deviceParameters={deviceParameters}
                systemParameters={systemParameters}
                handleAddParameter={handleAddParameter}
                handleRemoveParameter={handleRemoveParameter}
                handleParameterNameChange={handleParameterNameChange}
                handleParameterValueChange={handleParameterValueChange}
                handleParameterValueValidation={handleParameterValueValidation}
                handleAddApiKey={handleAddApiKey}
                handleRemoveApiKey={handleRemoveApiKey}
                partnerApiKeyParameters={partnerApiKeys}
              />
            </Box>
          </React.Fragment>
        )}
      </AccessControl>
    </Box>
  );
};

export default PartnerDetailsView;
