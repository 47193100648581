/* Copyright */
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type CardEmptyStateProps = {
  emptyStateText: string;
};

const CardEmptyState: React.FC<CardEmptyStateProps> = ({ emptyStateText }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="body1" sx={{ color: theme.palette.secondary.main }}>
        {emptyStateText}
      </Typography>
    </Box>
  );
};

export default CardEmptyState;
