/* Copyright */
import { FormControl, InputLabel, TextFieldProps } from "@mui/material";
import * as React from "react";
import PhoneInput from "react-phone-number-input/input";

type PhoneNumberTextfieldProps = {
  label: string;
  id: string;
  fullWidth?: boolean;
  value: string | undefined;
  handlePhoneNumberChange: (phoneNumber: string) => void;
  testId?: string;
  disabled?: boolean;
} & TextFieldProps;

const PhoneNumberTextfield: React.FC<PhoneNumberTextfieldProps> = ({
  label,
  id,
  fullWidth = true,
  value,
  handlePhoneNumberChange,
  testId,
  disabled,
}) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel htmlFor={id} shrink disabled={disabled}>
        {label}
      </InputLabel>
      <PhoneInput
        country="US"
        value={value}
        onChange={(value: string): void => (value ? handlePhoneNumberChange(value) : void 0)}
        className="phone-number-textfield"
        data-testid={testId}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default PhoneNumberTextfield;
