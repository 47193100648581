/* Copyright */
import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Loading from "../loading/loading";

type TableLoadingRowProps = {
  colspan: number;
};

const TableLoadingRow: React.FC<TableLoadingRowProps> = ({ colspan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colspan} align="center">
        <Loading height={24} />
      </TableCell>
    </TableRow>
  );
};

export default TableLoadingRow;
