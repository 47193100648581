/* Copyright */
import * as React from "react";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { Typography, Link, InputAdornment, IconButton, Button, CircularProgress } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { translations } from "../../generated/translationHelper";
import CustomTextField from "../common/textfield";
import PasswordRequirements from "../common/password-requirements";
import TestIds from "../../test-ids/test-ids";

type ResetPasswordProps = {
  email: string;
  isLoading: boolean;
  codeError: string;
  newPasswordError: string;
  isButtonDisabled: boolean;
  handleCodeOnChange: (code: string) => void;
  handleResend: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePasswordClick: () => void;
};

const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
  email,
  isLoading,
  codeError,
  newPasswordError,
  isButtonDisabled,
  handleCodeOnChange,
  handleResend,
  handleInputChange,
  handleChangePasswordClick,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const AuthInputRef = React.useRef<AuthCodeRef>(null);

  const handleResendClick = (): void => {
    AuthInputRef.current?.clear();
    handleResend();
  };

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  return (
    <React.Fragment>
      <Typography variant="h5" className="title">
        {translations.forgotPasswordResetPassword.texts.title()}
      </Typography>
      <Typography variant="body1" className="title">
        {translations.forgotPasswordResetPassword.texts.subtitle({ email })}
      </Typography>
      <AuthCode
        allowedCharacters="numeric"
        onChange={handleCodeOnChange}
        containerClassName="enter-code-container"
        inputClassName={codeError.length > 0 ? "enter-code-input-error" : "enter-code-input"}
        ref={AuthInputRef}
      />
      {codeError.length > 0 && <Typography className="enter-code-error">{codeError}</Typography>}
      <Link
        href="#"
        underline="hover"
        variant="body1"
        className="sparse-link"
        onClick={handleResendClick}
        data-testid={TestIds.ForgotPasswordForm.ForgotPasswordResendCode}
        sx={{ mt: codeError.length > 0 ? 0 : 1 }}
      >
        {translations.forgotPasswordResetPassword.links.resend()}
      </Link>
      <CustomTextField
        label={translations.forgotPasswordResetPassword.inputs.newPassword()}
        id="newPassword"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        onChange={handleInputChange}
        errorMessage={newPasswordError}
        testId={TestIds.NewPasswordForm.CreateNewPassword}
        className="sparse-input"
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleChangePasswordClick();
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomTextField
        label={translations.forgotPasswordResetPassword.inputs.confirmNewPassword()}
        id="confirmNewPassword"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        onChange={handleInputChange}
        errorMessage={newPasswordError}
        testId={TestIds.NewPasswordForm.ConfirmNewPassword}
        onKeyDown={(e): void => {
          if (e.key === "Enter" && !isButtonDisabled) handleChangePasswordClick();
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <PasswordRequirements />
      <Button
        data-testid={TestIds.NewPasswordForm.SubmitNewPassword}
        size="large"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={handleChangePasswordClick}
        className={"sparse-button"}
        startIcon={isLoading && <CircularProgress size={16} color="secondary" />}
      >
        {translations.common.buttons.changePassword()}
      </Button>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
