/* Copyright */
import { Chip, TableCell, TableRow } from "@mui/material";
import React from "react";
import TestIds from "../../../test-ids/test-ids";
import { DeviceListItem, resolveDeviceStatusChipColor } from "../../../utils/utils";

type DevicesTableRowProps = {
  device: DeviceListItem;
  tableRowIndex?: number;
  handleRowClick: (deviceId: string) => void;
};

const DevicesTableRow: React.FC<DevicesTableRowProps> = ({ device, tableRowIndex, handleRowClick }) => {
  return (
    <TableRow
      onClick={(): void => handleRowClick(device.IMEI)}
      sx={{ cursor: "pointer" }}
      data-testid={TestIds.DevicesTable.TableRow}
      hover
    >
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceStatus}-${tableRowIndex}`}>
        {device.status && (
          <Chip
            size="small"
            label={device.status}
            sx={{
              backgroundColor: resolveDeviceStatusChipColor(device.status),
              color: "white",
            }}
          />
        )}
      </TableCell>
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceName}-${tableRowIndex}`}>{device.deviceName}</TableCell>
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceImei}-${tableRowIndex}`}>{device.IMEI}</TableCell>
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceCallerId}-${tableRowIndex}`}>{device.callerId}</TableCell>
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceIccId}-${tableRowIndex}`}>{device.ICCID}</TableCell>
      <TableCell data-testid={`${TestIds.DevicesTable.DeviceRealmName}-${tableRowIndex}`}>{device.realmName}</TableCell>
    </TableRow>
  );
};

export default DevicesTableRow;
