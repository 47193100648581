/* Copyright */
import * as React from "react";
import { Box, Divider, IconButton, InputAdornment, List, ListItemButton, ListItemText } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { translations } from "../../../generated/translationHelper";
import CustomTextField from "../../common/textfield";
import { Nullable } from "@sade/data-access";
import { UserOrganizationWithRole } from "../../../utils/utils";
import TestIds from "../../../test-ids/test-ids";

type RealmAccessSelectorDialogSearchProps = {
  isLoading: boolean;
  currentOrganizationId: string;
  filteredOrganizationRoles: UserOrganizationWithRole[];
  handleSearch: (searchString: string) => void;
  handleSelect: (orgId: string) => void;
};

const RealmAccessSelectorDialogSearch: React.FC<RealmAccessSelectorDialogSearchProps> = ({
  isLoading,
  currentOrganizationId,
  filteredOrganizationRoles,
  handleSearch,
  handleSelect,
}) => {
  const [searchString, setSearchString] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState<Nullable<NodeJS.Timeout>>(null);

  React.useEffect(() => {
    if (searchTimeout) clearTimeout(searchTimeout);

    setSearchTimeout(
      setTimeout(() => {
        handleSearch(searchString);
      }, 1500)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, handleSearch]);

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchString(event.target.value);
  };

  const handleEnterClick = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (searchTimeout) clearTimeout(searchTimeout);
      setSearchString(searchString);
    }
  };

  const handleSearchClick = (): void => {
    if (searchTimeout) clearTimeout(searchTimeout);
    setSearchString(searchString);
  };

  const isSearchButtonDisabled = !searchString || isLoading;

  return (
    <Box>
      <CustomTextField
        label={""}
        id="searchString"
        type="search"
        testId={TestIds.RealmAccessSelectorDialog.RealmInput}
        onChange={handleSearchStringChange}
        onKeyDown={handleEnterClick}
        placeholder={translations.accountRealmSelector.texts.searchPlaceholder()}
        value={searchString}
        inputRef={(input): void => input && input.focus()}
        InputProps={{
          sx: {
            "& input": {
              textOverflow: "ellipsis",
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="search"
                onClick={handleSearchClick}
                edge="start"
                disabled={isSearchButtonDisabled}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ padding: 2 }}
      />
      <Divider />
      <List sx={{ padding: 0 }}>
        {filteredOrganizationRoles.map((organizationRole, index) => (
          <Box key={organizationRole.id}>
            <ListItemButton
              onClick={(): void => handleSelect(organizationRole.id)}
              disabled={isLoading || organizationRole.id === currentOrganizationId}
              data-testid={`${TestIds.RealmAccessSelectorDialog.UserRealm}-${index}`}
            >
              <ListItemText primary={organizationRole.fullName} secondary={organizationRole.role} />
              <KeyboardArrowRightIcon color="secondary" />
            </ListItemButton>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default RealmAccessSelectorDialogSearch;
