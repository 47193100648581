/* Copyright */
import React from "react";

import { Box, Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { DeviceStatus, Maybe } from "@sade/data-access";
import { useAuthenticatedUser } from "../../../context/authenticated-user-context";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { OrganizationInfo, getChildOrganizations } from "../../../utils/utils";
import RealmFilterPicker from "../../common/realm-filter-picker";

type DevicesViewFiltersProps = {
  isLoading: boolean;
  handleRealmSelectChange: (realms: string[]) => void;
  selectedRealms: string[];
  handleStatusSelectChange: (selectedStatuses: DeviceStatus[]) => void;
  selectedStatuses: DeviceStatus[];
};

const DevicesViewFilters: React.FC<DevicesViewFiltersProps> = ({
  isLoading,
  handleRealmSelectChange,
  selectedRealms,
  handleStatusSelectChange,
  selectedStatuses,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [childOrganizations, setChildOrganizations] = React.useState<Maybe<OrganizationInfo[]>>();

  React.useEffect(() => {
    const getChildOrganizationList = async (): Promise<void> => {
      const childOrganizationList = await getChildOrganizations(currentOrganization?.getId() ?? "");
      setChildOrganizations(childOrganizationList);
    };
    getChildOrganizationList();
  }, [currentOrganization]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { xs: "100%", lg: "50rem" },
      }}
    >
      <RealmFilterPicker
        isLoading={isLoading}
        filters={selectedRealms}
        handleFilterChange={handleRealmSelectChange}
        childOrganizations={childOrganizations}
        testId={TestIds.Devices.OpenRealmSelection}
      />

      <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
        <InputLabel id="filter-by-status-label" shrink disabled={isLoading}>
          {translations.common.inputs.status()}
        </InputLabel>
        <Select
          id="status"
          size="small"
          multiple
          labelId="filter-by-status-label"
          data-testid={TestIds.Devices.OpenStatusSelection}
          inputProps={{ "data-testid": TestIds.Devices.StatusSelect }}
          value={selectedStatuses}
          onChange={(event): void => handleStatusSelectChange(event.target.value as DeviceStatus[])}
          renderValue={(selected): string =>
            selected
              .map((value) =>
                translations.devices.inputs[value.toLowerCase() as keyof typeof translations.devices.inputs]?.()
              )
              .join(", ")
          }
        >
          {(Object.keys(DeviceStatus) as Array<keyof typeof DeviceStatus>).map((state, index) => (
            <MenuItem key={index} value={DeviceStatus[state]}>
              <Checkbox checked={selectedStatuses.indexOf(DeviceStatus[state]) > -1} />
              <ListItemText
                primary={translations.devices.inputs[
                  state.toLowerCase() as keyof typeof translations.devices.inputs
                ]?.()}
                data-option-index={index}
              />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{String(" ")}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default DevicesViewFilters;
