/* Copyright */
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { RoleIdentifier, Role } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { AccountFilters } from "../../../utils/utils";

type AccountsViewRolePickerProps = {
  isLoading: boolean;
  allRoles: Role[];
  filters: AccountFilters;
  handleFilterChange: (data: Partial<AccountFilters>) => void;
  testId?: string;
};

const AccountsViewRolePicker: React.FC<AccountsViewRolePickerProps> = ({
  isLoading,
  allRoles,
  filters,
  handleFilterChange,
  testId,
}) => {
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSelectedRoles(filters.roles.map((role) => allRoles.find((r) => r.identifier === role)?.name ?? ""));
  }, [filters, allRoles]);

  const handleRoleSelectChange = (event: SelectChangeEvent<typeof selectedRoles>): void => {
    const {
      target: { value },
    } = event;
    const selectedRoles = typeof value === "string" ? value.split(",") : value;

    const roles: RoleIdentifier[] = [];
    const selectedRolesList = allRoles?.filter((role) => selectedRoles.includes(role.name));
    selectedRolesList?.forEach((selectedRole) => {
      const indexOfRoleIdentifier = (Object.values(RoleIdentifier) as string[]).indexOf(selectedRole.identifier);
      const role = Object.values(RoleIdentifier)[indexOfRoleIdentifier];
      roles.push(role);
    });

    handleFilterChange({
      roles: roles,
    });
  };

  return (
    <FormControl className="input" sx={{ textAlign: "start", mr: 1 }} fullWidth>
      <InputLabel id="filter-by-role-label" shrink disabled={isLoading}>
        {translations.common.inputs.role()}
      </InputLabel>
      <Select
        id="role"
        size="small"
        multiple
        labelId="filter-by-role-label"
        data-testid={testId}
        inputProps={{ "data-testid": TestIds.AccountsFilterBar.RoleSelect }}
        value={selectedRoles}
        onChange={handleRoleSelectChange}
        renderValue={(selected): string => selected.join(", ")}
        disabled={isLoading}
      >
        {allRoles?.map((role, index) => (
          <MenuItem key={index} value={role.name} data-option-index={index}>
            <Checkbox checked={selectedRoles.indexOf(role.name) > -1} />
            <ListItemText primary={role.name} data-option-index={index} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{String(" ")}</FormHelperText>
    </FormControl>
  );
};

export default AccountsViewRolePicker;
