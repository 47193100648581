/* Copyright */

import {
  CloudUploadOutlined,
  DeleteForever,
  KeyOutlined,
  LockResetOutlined,
  MailOutlined,
  MusicNoteOutlined,
  PowerSettingsNew,
  RestartAltOutlined,
  SettingsApplications,
  ShowerOutlined,
  SummarizeOutlined,
  Update,
} from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  BackendFactory,
  Device,
  DeviceAction,
  DeviceAttributeName,
  DeviceStatus,
  Maybe,
  Nullable,
  OrganizationParameterHierarchy,
  RoleIdentifier,
  SimAction,
  isError,
} from "@sade/data-access";
import React from "react";
import theme from "../../../app/theme";
import DeactivateIcon from "../../../assets/icons/deactivate-icon";
import { SeverityType } from "../../../context/snackbar-context";
import { translations } from "../../../generated/translationHelper";
import { useAccessControl } from "../../../hooks/useAccessControl";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TestIds from "../../../test-ids/test-ids";
import { deviceSupportsSettingsAndActions } from "../../../utils/deviceDetailsUtils";
import { ParameterNames, getErrorMessage } from "../../../utils/utils";
import DeviceActionDialog, { DeviceActionButtonSeverity } from "../../dialogs/device/actions/device-actions-dialog";
import ActivateDeviceDialog from "../../dialogs/device/activate-device/activate-device-dialog";
import AdvancedSettingsDialog from "../../dialogs/device/advanced-settings/advanced-settings-dialog";
import MailboxDialog from "../../dialogs/device/mailbox/mailbox-dialog";
import DeviceOtaMonitorDialog from "../../dialogs/device/ota-monitor/ota-monitor-dialog";
import DeviceOtaUpdateDialog from "../../dialogs/device/ota-update/ota-update-dialog";

export type DeviceActivationParams = {
  keepExistingPatient: boolean;
  externalId1?: string;
  externalId2?: string;
  externalId3?: string;
};

type DeviceViewMaintenanceMenuProps = {
  device?: Device;
  isLoading: boolean;
  parameterHierarchy?: OrganizationParameterHierarchy;
  onReload: (delay?: number) => void;
  onDeviceStateUpdate: () => void;
};

const MaintenanceMenu: React.FC<DeviceViewMaintenanceMenuProps> = ({
  device,
  isLoading,
  parameterHierarchy,
  onReload,
  onDeviceStateUpdate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const [deviceStatus, setDeviceStatus] = React.useState<Maybe<DeviceStatus>>(device?.getDeviceStatus());
  const [resetDeviceDialogOpen, setResetDeviceDialogOpen] = React.useState<boolean>(false);
  const [activationDialogOpen, setActivationDialogOpen] = React.useState<boolean>(false);
  const [deactivationDialogOpen, setDeactivationDialogOpen] = React.useState<boolean>(false);
  const [disableDialogOpen, setDisableDialogOpen] = React.useState<boolean>(false);
  const [flushQueueDialogOpen, setFlushQueueDialogOpen] = React.useState<boolean>(false);
  const [sendLogsDialogOpen, setSendLogsDialogOpen] = React.useState<boolean>(false);
  const [rebootDialogOpen, setRebootDialogOpen] = React.useState<boolean>(false);
  const [resetAudioDialogOpen, setResetAudioDialogOpen] = React.useState<boolean>(false);
  const [resetKeyDialogOpen, setResetKeyDialogOpen] = React.useState<boolean>(false);
  const [otaMonitorDialogOpen, setOtaMonitorDialogOpen] = React.useState<boolean>(false);
  const [otaUpdateDialogOpen, setOtaUpdateDialogOpen] = React.useState<boolean>(false);
  const [advancedSettingsDialogOpen, setAdvancedSettingsDialogOpen] = React.useState<boolean>(false);
  const [mailboxDialogOpen, setMailboxDialogOpen] = React.useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setDeviceStatus(device?.getDeviceStatus());
  }, [device]);

  const handleOpenMenu = (event: React.SyntheticEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleDeviceActivation = async (params: DeviceActivationParams): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        // Update external device identifiers if provided
        if (params.externalId1 || params.externalId2 || params.externalId3) {
          const attributes = [];
          if (params.externalId1) {
            attributes.push({ key: DeviceAttributeName.externalId1, value: params.externalId1 });
          }
          if (params.externalId2) {
            attributes.push({ key: DeviceAttributeName.externalId2, value: params.externalId2 });
          }
          if (params.externalId3) {
            attributes.push({ key: DeviceAttributeName.externalId3, value: params.externalId3 });
          }
          await device.updateAttributes(attributes);
        }
      } catch (error) {
        if (isError(error) && error.message.match(/device external identifier is not unique/i)) {
          showSnackbar(
            translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed(),
            SeverityType.Error,
            undefined,
            undefined,
            translations.common.texts.identifierNotUniqueInfo()
          );
        } else {
          showSnackbar(
            translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed(),
            SeverityType.Error,
            undefined,
            undefined,
            translations.common.texts.unableToPerformAction()
          );
        }

        // Stop here
        return;
      }

      try {
        const backend = BackendFactory.getBackend();
        await backend.requestSimManagementAction({
          deviceIds: [device.getId()],
          simAction: SimAction.Activate,
          keepExistingPatient: params.keepExistingPatient,
        });
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestSent(), SeverityType.Success);
        onReload(2000);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.activateDeviceRequestFailedError({
            deviceId: device.getId(),
            error: getErrorMessage(error),
          })
        );
      }
    }
  };

  const handleFlushQueue = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        await device.requestAction(DeviceAction.FlushQueues);
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.flushQueueRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.flushQueueRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.flushQueueRequestFailedError({
            deviceId: device.getId(),
            error: getErrorMessage(error),
          })
        );
      }
    }
  };

  const handleResetAudio = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        const state = device.getState();
        if (!state) {
          throw "Device state is not available";
        }
        state.updateParameter(ParameterNames.FIRST_TIME_AUDIO_PLAYED, false);
        await state.store();
        await device.requestAction(DeviceAction.UpdateConfiguration);
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.resetAudioRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.resetAudioRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.requestFailedError({ error: getErrorMessage(error) })
        );
      }
    }
  };

  const handleResetKey = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        await device.requestAction(DeviceAction.ResetKey);
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.resetKeyRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.resetKeyRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.requestFailedError({ error: getErrorMessage(error) })
        );
      }
    }
  };

  const handleSendLogs = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        await device.requestAction(DeviceAction.SendLogs);
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.sendLogsRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.sendLogsRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.sendLogsRequestFailedError({
            deviceId: device.getId(),
            error: getErrorMessage(error),
          })
        );
      }
    }
  };

  const handleReboot = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        await device.requestAction(DeviceAction.Reboot);
        showSnackbar(translations.deviceDetailsMaintenanceMenu.texts.rebootRequestSent(), SeverityType.Success);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.rebootRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.requestFailedError({ error: getErrorMessage(error) })
        );
      }
    }
  };

  const handleDeviceReset = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        await device.reset();
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.resetDeviceRequestSent(),
          SeverityType.Success,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.resetDeviceRequestSentMessage()
        );
        onReload(2000);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.resetDeviceRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.requestFailedError({ error: getErrorMessage(error) })
        );
      }
    }
  };

  const handleDeviceDeactivation = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        const backend = BackendFactory.getBackend();
        await backend.requestSimManagementAction({
          deviceIds: [device.getId()],
          simAction: SimAction.Deactivate,
        });
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestSent(),
          SeverityType.Success
        );
        onReload(2000);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.deactivateDeviceRequestFailedError({
            deviceId: device.getId(),
            error: getErrorMessage(error),
          })
        );
      }
    }
  };

  const handleDeviceDisablePermanently = async (): Promise<void> => {
    if (device) {
      handleCloseMenu();
      try {
        const statusAttribute = [{ key: DeviceAttributeName.status, value: DeviceStatus.retired }];
        await device.updateAttributes(statusAttribute);
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.disablePermanentlyRequestSent(),
          SeverityType.Success
        );
        onReload(2000);
      } catch (error) {
        showSnackbar(
          translations.deviceDetailsMaintenanceMenu.texts.disablePermanentlyRequestFailed(),
          SeverityType.Error,
          undefined,
          undefined,
          translations.deviceDetailsMaintenanceMenu.texts.requestFailedError({ error: getErrorMessage(error) })
        );
      }
    }
  };

  const toggleDeviceResetDialog = (): void => {
    setResetDeviceDialogOpen(!resetDeviceDialogOpen);
    handleCloseMenu();
  };

  const toggleDeviceActivationDialog = (): void => {
    setActivationDialogOpen(!activationDialogOpen);
    handleCloseMenu();
  };

  const toggleDeviceDeactivationDialog = (): void => {
    setDeactivationDialogOpen(!deactivationDialogOpen);
    handleCloseMenu();
  };

  const toggleDeviceDisableDialog = (): void => {
    setDisableDialogOpen(!disableDialogOpen);
    handleCloseMenu();
  };

  const toggleFlushQueueDialog = (): void => {
    setFlushQueueDialogOpen(!flushQueueDialogOpen);
    handleCloseMenu();
  };

  const toggleResetAudioDialog = (): void => {
    setResetAudioDialogOpen(!resetAudioDialogOpen);
    handleCloseMenu();
  };

  const toggleResetKeyDialog = (): void => {
    setResetKeyDialogOpen(!resetKeyDialogOpen);
    handleCloseMenu();
  };

  const toggleOtaUpdateDialog = (): void => {
    setOtaUpdateDialogOpen(!otaUpdateDialogOpen);
    handleCloseMenu();
  };

  const toggleSendLogsDialog = (): void => {
    setSendLogsDialogOpen(!sendLogsDialogOpen);
    handleCloseMenu();
  };

  const toggleOtaMonitorDialog = (): void => {
    setOtaMonitorDialogOpen(!otaMonitorDialogOpen);
    handleCloseMenu();
  };

  const toggleRebootDialog = (): void => {
    setRebootDialogOpen(!rebootDialogOpen);
    handleCloseMenu();
  };

  const toggleAdvancedSettingsDialog = (): void => {
    setAdvancedSettingsDialogOpen(!advancedSettingsDialogOpen);
    handleCloseMenu();
  };

  const toggleMailboxDialog = (): void => {
    setMailboxDialogOpen(!mailboxDialogOpen);
    handleCloseMenu();
  };

  const powerUserAccess = useAccessControl(RoleIdentifier.PowerUser);

  const dealerManagerAndWarehouseInstallerAccess = useAccessControl(
    RoleIdentifier.PowerUser,
    RoleIdentifier.DealerManager,
    RoleIdentifier.WarehouseInstaller
  );

  const renderResetDeviceItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetDevice} onClick={toggleDeviceResetDialog}>
          <ListItemIcon>
            <LockResetOutlined style={{ color: theme.palette.error.main }} />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1", color: theme.palette.error.main }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.resetDevice()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetDeviceItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderDeactivateItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      dealerManagerAndWarehouseInstallerAccess && (
        <MenuItem
          data-testid={TestIds.DeviceDetailsMaintenanceMenu.DeactivateDevice}
          onClick={toggleDeviceDeactivationDialog}
        >
          <ListItemIcon>
            <DeactivateIcon fill={theme.palette.error.main} />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1", color: theme.palette.error.main }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.deactivateDevice()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.DeactivateDeviceItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderActivateItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      (status === DeviceStatus.unmanaged || status === DeviceStatus.unsuccesful) &&
      dealerManagerAndWarehouseInstallerAccess && (
        <MenuItem
          data-testid={TestIds.DeviceDetailsMaintenanceMenu.ActivateDevice}
          onClick={toggleDeviceActivationDialog}
        >
          <ListItemIcon>
            <PowerSettingsNew style={{ color: theme.palette.success.main }} />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1", color: theme.palette.success.main }}
            primary={
              device?.getAttribute(DeviceAttributeName.patientId)
                ? translations.deviceDetailsMaintenanceMenu.texts.reactivateDevice()
                : translations.deviceDetailsMaintenanceMenu.texts.activateDevice()
            }
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.ActivateDeviceItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderFlushQueueItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.FlushQueue} onClick={toggleFlushQueueDialog}>
          <ListItemIcon>
            <ShowerOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.flushQueue()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.FlushQueueItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderResetAudioItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      dealerManagerAndWarehouseInstallerAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetAudio} onClick={toggleResetAudioDialog}>
          <ListItemIcon>
            <MusicNoteOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.resetAudio()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetAudioItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderResetKeyItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetKey} onClick={toggleResetKeyDialog}>
          <ListItemIcon>
            <KeyOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.resetKey()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.ResetKeyItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderAdvancedDeviceSettingsItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem
          data-testid={TestIds.DeviceDetailsMaintenanceMenu.AdvancedDeviceSettings}
          onClick={toggleAdvancedSettingsDialog}
        >
          <ListItemIcon>
            <SettingsApplications />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.advancedDeviceSettings()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.AdvancedDeviceSettingsItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderUpdateFirmwareItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.UpdateFirmware} onClick={toggleOtaUpdateDialog}>
          <ListItemIcon>
            <Update />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.updateFirmware()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.UpdateFirmwareItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderOtaMonitorItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.OtaMonitor} onClick={toggleOtaMonitorDialog}>
          <ListItemIcon>
            <SummarizeOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.otaMonitor()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.OtaMonitorItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderSendLogsItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.SendLogs} onClick={toggleSendLogsDialog}>
          <ListItemIcon>
            <CloudUploadOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.sendLogs()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.SendLogsItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderRebootItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      dealerManagerAndWarehouseInstallerAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.Reboot} onClick={toggleRebootDialog}>
          <ListItemIcon>
            <RestartAltOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.reboot()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.RebootItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderDisablePermanentlyItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.unmanaged &&
      dealerManagerAndWarehouseInstallerAccess && (
        <MenuItem
          data-testid={TestIds.DeviceDetailsMaintenanceMenu.DisablePermanently}
          onClick={toggleDeviceDisableDialog}
        >
          <ListItemIcon>
            <DeleteForever style={{ color: theme.palette.error.main }} />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1", color: theme.palette.error.main }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.disablePermanently()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.DisablePermanentlyItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  const renderMailboxItem = (status?: DeviceStatus): React.ReactNode => {
    return (
      status === DeviceStatus.active &&
      powerUserAccess && (
        <MenuItem data-testid={TestIds.DeviceDetailsMaintenanceMenu.Mailbox} onClick={toggleMailboxDialog}>
          <ListItemIcon>
            <MailOutlined />
          </ListItemIcon>
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: "body1" }}
            primary={translations.deviceDetailsMaintenanceMenu.texts.mailbox()}
            data-testid={TestIds.DeviceDetailsMaintenanceMenu.MailboxItem}
          ></ListItemText>
        </MenuItem>
      )
    );
  };

  return (
    <Box sx={{ alignSelf: "center" }}>
      <Box>
        <Tooltip title={translations.deviceDetailsMaintenanceMenu.texts.tooltip()} arrow>
          <Button
            variant="contained"
            startIcon={<PowerSettingsNewIcon />}
            disabled={isLoading}
            onClick={handleOpenMenu}
            sx={{ mb: { xs: 2 } }}
            data-testid={TestIds.DeviceDetails.MaintenanceButton}
          >
            {translations.common.buttons.maintenance()}
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        data-testid={TestIds.DeviceDetailsMaintenanceMenu.MaintenanceMenu}
      >
        {device && deviceSupportsSettingsAndActions(device) && (
          <>
            {renderFlushQueueItem(deviceStatus)}
            {renderOtaMonitorItem(deviceStatus)}
            {renderSendLogsItem(deviceStatus)}
            {renderResetKeyItem(deviceStatus)}
            {renderResetAudioItem(deviceStatus)}
            {renderAdvancedDeviceSettingsItem(deviceStatus)}
            {renderUpdateFirmwareItem(deviceStatus)}
            {renderRebootItem(deviceStatus)}
            {renderActivateItem(deviceStatus)}
            {renderMailboxItem(deviceStatus)}
            {deviceStatus !== DeviceStatus.retired && <Divider />}
            {renderResetDeviceItem(deviceStatus)}
          </>
        )}
        {renderDeactivateItem(deviceStatus)}
        {renderDisablePermanentlyItem(deviceStatus)}
      </Menu>
      <ActivateDeviceDialog
        open={activationDialogOpen}
        device={device}
        parameterHierarchy={parameterHierarchy}
        onClose={toggleDeviceActivationDialog}
        onContinue={handleDeviceActivation}
      ></ActivateDeviceDialog>
      <DeviceActionDialog
        open={resetDeviceDialogOpen}
        title={translations.deviceActions.texts.resetDeviceTitle({ deviceId: device?.getId() ?? "" })}
        message={translations.deviceActions.texts.resetDeviceContent()}
        proceedButtonText={translations.deviceActions.buttons.resetDeviceButton({
          deviceId: device?.getId() ?? "",
        })}
        buttonSeverity={DeviceActionButtonSeverity.HIGH}
        discardButtonText={translations.common.buttons.cancel()}
        icon={LockResetOutlined}
        onClose={toggleDeviceResetDialog}
        onProceed={handleDeviceReset}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={deactivationDialogOpen}
        title={translations.deviceActions.texts.deactivateDeviceTitle({ deviceId: device?.getId() ?? "" })}
        message={translations.deviceActions.texts.deactivateDeviceContent()}
        proceedButtonText={translations.deviceActions.buttons.deactivateDeviceButton()}
        buttonSeverity={DeviceActionButtonSeverity.HIGH}
        discardButtonText={translations.common.buttons.cancel()}
        icon={PowerSettingsNewIcon}
        onClose={toggleDeviceDeactivationDialog}
        onProceed={handleDeviceDeactivation}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={disableDialogOpen}
        title={translations.deviceActions.texts.disableDeviceTitle()}
        message={translations.deviceActions.texts.disableDeviceContent({ deviceId: device?.getId() ?? "" })}
        proceedButtonText={translations.deviceActions.buttons.disableDeviceButton()}
        buttonSeverity={DeviceActionButtonSeverity.HIGH}
        discardButtonText={translations.common.buttons.cancel()}
        icon={PowerSettingsNewIcon}
        onClose={toggleDeviceDisableDialog}
        onProceed={handleDeviceDisablePermanently}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={flushQueueDialogOpen}
        title={translations.deviceActions.texts.flushQueueTitle()}
        message={translations.deviceActions.texts.flushQueueContent()}
        proceedButtonText={translations.deviceActions.buttons.flushQueueButton()}
        discardButtonText={translations.common.buttons.cancel()}
        onClose={toggleFlushQueueDialog}
        onProceed={handleFlushQueue}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={resetAudioDialogOpen}
        title={translations.deviceActions.texts.resetAudioTitle()}
        message={translations.deviceActions.texts.resetAudioContent()}
        proceedButtonText={translations.deviceActions.buttons.resetAudioButton()}
        discardButtonText={translations.common.buttons.cancel()}
        icon={MusicNoteOutlined}
        onClose={toggleResetAudioDialog}
        onProceed={handleResetAudio}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={rebootDialogOpen}
        title={translations.deviceActions.texts.rebootTitle({ deviceId: device?.getId() ?? "" })}
        message={translations.deviceActions.texts.rebootContent()}
        proceedButtonText={translations.deviceActions.buttons.rebootButton()}
        discardButtonText={translations.common.buttons.cancel()}
        icon={RestartAltOutlined}
        onClose={toggleRebootDialog}
        onProceed={handleReboot}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={sendLogsDialogOpen}
        title={translations.deviceActions.texts.sendLogsTitle()}
        message={translations.deviceActions.texts.sendLogsContent()}
        proceedButtonText={translations.deviceActions.buttons.sendLogsButton()}
        discardButtonText={translations.common.buttons.cancel()}
        icon={CloudUploadOutlined}
        onClose={toggleSendLogsDialog}
        onProceed={handleSendLogs}
      ></DeviceActionDialog>
      <DeviceActionDialog
        open={resetKeyDialogOpen}
        title={translations.deviceActions.texts.resetKeyTitle()}
        message={translations.deviceActions.texts.resetKeyContent()}
        proceedButtonText={translations.deviceActions.buttons.resetKeyButton()}
        buttonSeverity={DeviceActionButtonSeverity.HIGH}
        discardButtonText={translations.common.buttons.cancel()}
        icon={KeyOutlined}
        onClose={toggleResetKeyDialog}
        onProceed={handleResetKey}
      ></DeviceActionDialog>
      <DeviceOtaMonitorDialog
        open={otaMonitorDialogOpen}
        deviceId={device?.getId() ?? ""}
        handleClose={toggleOtaMonitorDialog}
      ></DeviceOtaMonitorDialog>
      <DeviceOtaUpdateDialog
        open={otaUpdateDialogOpen}
        deviceId={device?.getId() ?? ""}
        onClose={toggleOtaUpdateDialog}
      ></DeviceOtaUpdateDialog>
      <AdvancedSettingsDialog
        open={advancedSettingsDialogOpen}
        deviceId={device?.getId() ?? ""}
        deviceState={device?.getState()}
        parameterHierarchy={parameterHierarchy}
        onClose={toggleAdvancedSettingsDialog}
        onDeviceStateUpdate={onDeviceStateUpdate}
      ></AdvancedSettingsDialog>
      <MailboxDialog open={mailboxDialogOpen} device={device} onClose={toggleMailboxDialog} />
    </Box>
  );
};

export default MaintenanceMenu;
