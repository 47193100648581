/* Copyright */
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Maybe, Role, User } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../generated/translationHelper";
import TestIds from "../../../test-ids/test-ids";
import { OrganizationInfo, UserRealmRole } from "../../../utils/utils";
import RealmPicker from "../../common/realm-picker";
import Loading from "../../loading/loading";

type AddRealmAccessDialogContentProps = {
  userData: Maybe<User>;
  realms: OrganizationInfo[];
  userRealmAccessData: UserRealmRole[];
  isLoadingAssignableRoles: boolean;
  roles: Role[];
  selectedRealm: string;
  selectedRole: string;
  handleRealmChange: (realmId: string) => void;
  handleRoleChange: (event: SelectChangeEvent<string>) => void;
};

const AddRealmAccessDialogContent: React.FC<AddRealmAccessDialogContentProps> = ({
  userData,
  realms,
  userRealmAccessData,
  isLoadingAssignableRoles,
  roles,
  selectedRealm,
  selectedRole,
  handleRealmChange,
  handleRoleChange,
}) => {
  const [usableRealms, setUsableRealms] = React.useState<OrganizationInfo[]>([]);
  const firstName = userData?.getDetails().firstName ?? "";
  const lastName = userData?.getDetails().lastName ?? "";
  const userEmailAddress = userData?.getEmail() ?? "";

  React.useEffect(() => {
    const realmList = realms.filter((realm) => {
      return !userRealmAccessData.some((ura) => realm.id.concat(":").startsWith(ura.realmId.concat(":")));
    });

    setUsableRealms(realmList);
  }, [realms, userRealmAccessData]);

  return (
    <Box>
      <Box sx={{ mt: 2, mb: 4 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.addRealmAccessDialog.texts.subtitle({ firstName, lastName, userEmailAddress }),
          }}
        />
      </Box>
      {!usableRealms.length && (
        <Box sx={{ mt: 2, mb: 4 }}>
          <Typography variant="button">{translations.addRealmAccessDialog.texts.noRealmsInfo()}</Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <FormControl className="input" sx={{ textAlign: "start" }}>
          <RealmPicker
            childOrganizations={usableRealms}
            onRealmPicked={handleRealmChange}
            testId={TestIds.AddRealmAccessDialog.RealmSelect}
          />
        </FormControl>

        <FormControl className="input" sx={{ textAlign: "start", mt: 4 }}>
          <InputLabel id="role-label" shrink disabled={!selectedRealm}>
            {translations.common.texts.role()}
          </InputLabel>
          <Select
            id="role-select"
            size="small"
            onChange={handleRoleChange}
            labelId="role-label"
            value={selectedRole}
            disabled={!selectedRealm}
            data-testid={TestIds.AddRealmAccessDialog.RoleSelect}
          >
            {isLoadingAssignableRoles && <Loading height={24} marginTop={2} />}
            {roles?.map((role, index) => (
              <MenuItem key={role.identifier} value={role.name} data-option-index={index}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default AddRealmAccessDialogContent;
