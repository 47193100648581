/* Copyright */
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { translations } from "../../generated/translationHelper";
import theme from "../../app/theme";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SeverityType } from "../../context/snackbar-context";
import { Maybe } from "@sade/data-access";

type FileUploadProps = {
  infoText?: string;
  showNoFileInfo?: boolean;
  acceptedFileType?: string;
  handleFileChange: (file?: Maybe<File>) => void;
  handleRemoveFile?: () => void;
  hasDropZone?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = ({
  infoText,
  showNoFileInfo,
  acceptedFileType,
  handleFileChange,
  handleRemoveFile,
  hasDropZone = true,
}) => {
  const { showSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = React.useState<Maybe<File>>(undefined);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    handleFileChange(selectedFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    const uploadedFile = acceptedFiles[0];
    if (acceptedFileType) {
      if (uploadedFile && uploadedFile.type === acceptedFileType) {
        setSelectedFile(uploadedFile);
      } else {
        showSnackbar(translations.common.texts.fileNotCorrectTypeError(), SeverityType.Error);
      }
    } else {
      setSelectedFile(uploadedFile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedFile(event?.target?.files?.[0]);
  };

  const resetFileInput = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteFile = (): void => {
    if (handleRemoveFile) {
      handleRemoveFile();
    }
    setSelectedFile(undefined);
    resetFileInput();
  };

  const renderFileInput = (): React.ReactNode => {
    if (hasDropZone) {
      return (
        <Box
          {...getRootProps()}
          sx={{
            backgroundColor: theme.palette.grey[100],
            border: "2px dashed #cccccc",
            borderRadius: "4px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography sx={{ color: theme.palette.secondary.main }}>
              {translations.common.texts.dragDropFileInfo()}
            </Typography>
          ) : (
            <Typography sx={{ color: theme.palette.secondary.main }}>{infoText}</Typography>
          )}
        </Box>
      );
    } else {
      return (
        <Button variant="contained" component="label" htmlFor="file-upload-input">
          {translations.common.texts.chooseFile()}
          <input id="file-upload-input" type="file" hidden ref={fileInputRef} onChange={handleFileSelect} />
        </Button>
      );
    }
  };

  return (
    <React.Fragment>
      {renderFileInput()}
      <Box sx={{ mt: 1 }}>
        {selectedFile ? (
          <React.Fragment>
            <Typography variant="body1">
              <b>{translations.deviceBulkActions.texts.file()}</b>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography variant="body1">
                <li>{selectedFile?.name}</li>
              </Typography>
              <IconButton aria-label="search" onClick={handleDeleteFile} size="small" color="error" sx={{ ml: 1 }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </React.Fragment>
        ) : (
          showNoFileInfo && <Typography variant="body1">{translations.common.texts.nofileChosen()}</Typography>
        )}
      </Box>
    </React.Fragment>
  );
};

export default FileUpload;
