/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Device, DeviceAttributeName, Maybe, OrganizationParameterHierarchy } from "@sade/data-access";
import * as React from "react";
import theme from "../../../../app/theme";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import { ExternalDeviceIdentifierParameter, getParameterFromHierarchy } from "../../../../utils/parameterUtils";
import { ParameterNames } from "../../../../utils/utils";
import CustomTextFieldWithValidation from "../../../common/custom-textfield-with-validation";
import { DeviceActivationParams } from "../../../device-view/device-view-header/device-view-maintenance-menu";

type ActivateDeviceDialogProps = {
  open: boolean;
  device?: Device;
  parameterHierarchy?: OrganizationParameterHierarchy;
  onClose: () => void;
  onContinue: (params: DeviceActivationParams) => void;
};

const ActivateDeviceDialog: React.FC<ActivateDeviceDialogProps> = ({
  open,
  device,
  parameterHierarchy,
  onClose,
  onContinue,
}) => {
  const [keepExistingPatient, setKeepExistingPatient] = React.useState<boolean>(true);
  // External ID value
  const [externalId1, setExternalId1] = React.useState<string>("");
  const [externalId2, setExternalId2] = React.useState<string>("");
  const [externalId3, setExternalId3] = React.useState<string>("");
  // External ID parameter value
  const [externalDeviceIdentifier1, setExternalDeviceIdentifier1] =
    React.useState<Maybe<ExternalDeviceIdentifierParameter>>();
  const [externalDeviceIdentifier2, setExternalDeviceIdentifier2] =
    React.useState<Maybe<ExternalDeviceIdentifierParameter>>();
  const [externalDeviceIdentifier3, setExternalDeviceIdentifier3] =
    React.useState<Maybe<ExternalDeviceIdentifierParameter>>();
  // External ID validation error
  const [externalId1Error, setExternalId1Error] = React.useState<boolean>(false);
  const [externalId2Error, setExternalId2Error] = React.useState<boolean>(false);
  const [externalId3Error, setExternalId3Error] = React.useState<boolean>(false);

  const [disableButton, setDisableButton] = React.useState<boolean>(true);

  const hasPatient = device?.getAttribute(DeviceAttributeName.patientId) !== undefined;

  React.useEffect(() => {
    if (!parameterHierarchy) return;

    setExternalDeviceIdentifier1(
      getParameterFromHierarchy(
        ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_1,
        parameterHierarchy,
        "system"
      ) as ExternalDeviceIdentifierParameter
    );
    setExternalDeviceIdentifier2(
      getParameterFromHierarchy(
        ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_2,
        parameterHierarchy,
        "system"
      ) as ExternalDeviceIdentifierParameter
    );
    setExternalDeviceIdentifier3(
      getParameterFromHierarchy(
        ParameterNames.EXTERNAL_DEVICE_IDENTIFIER_3,
        parameterHierarchy,
        "system"
      ) as ExternalDeviceIdentifierParameter
    );
  }, [parameterHierarchy]);

  React.useEffect(() => {
    if (!open) return;

    setDisableButton(externalId1Error || externalId2Error || externalId3Error);
  }, [open, externalId1Error, externalId2Error, externalId3Error]);

  React.useEffect(() => {
    if (device) {
      setExternalId1(device.getAttribute(DeviceAttributeName.externalId1) ?? "");
      setExternalId2(device.getAttribute(DeviceAttributeName.externalId2) ?? "");
      setExternalId3(device.getAttribute(DeviceAttributeName.externalId3) ?? "");
    }
  }, [device]);

  const resetState = (): void => {
    setKeepExistingPatient(true);
    setExternalId1(device?.getAttribute(DeviceAttributeName.externalId1) ?? "");
    setExternalId2(device?.getAttribute(DeviceAttributeName.externalId2) ?? "");
    setExternalId3(device?.getAttribute(DeviceAttributeName.externalId3) ?? "");
  };

  const handleClose = (): void => {
    onClose();
    resetState();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h5">
        {translations.deviceActions.texts.activateDeviceTitle({ deviceId: device?.getId() ?? "" })}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
          data-testid={TestIds.DeviceActivateDialog.CloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Typography variant="body1">{translations.deviceActions.texts.activateDeviceContent()}</Typography>

        {hasPatient && (
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                name="create-new-patient"
                checked={keepExistingPatient}
                onChange={(_event, checked): void => setKeepExistingPatient(checked)}
                data-testid={TestIds.DeviceActivateDialog.KeepExistingPatientCheckbox}
              />
            }
            label={translations.deviceActions.texts.keepExistingPatient()}
            labelPlacement="end"
          />
        )}

        {externalDeviceIdentifier1 || externalDeviceIdentifier2 || externalDeviceIdentifier3 ? (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              {externalDeviceIdentifier1 && (
                <CustomTextFieldWithValidation
                  label={externalDeviceIdentifier1?.label}
                  id="externalDeviceIdentifier1"
                  value={externalId1}
                  validationPattern={externalDeviceIdentifier1?.pattern}
                  placeholder={externalDeviceIdentifier1?.placeholder}
                  required
                  onValueChange={(_id, value, isError): void => {
                    setExternalId1(value);
                    setExternalId1Error(isError);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {externalDeviceIdentifier2 && (
                <CustomTextFieldWithValidation
                  label={externalDeviceIdentifier2?.label}
                  id="externalDeviceIdentifier2"
                  value={externalId2}
                  validationPattern={externalDeviceIdentifier2?.pattern}
                  placeholder={externalDeviceIdentifier2?.placeholder}
                  required
                  onValueChange={(_id, value, isError): void => {
                    setExternalId2(value);
                    setExternalId2Error(isError);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {externalDeviceIdentifier3 && (
                <CustomTextFieldWithValidation
                  label={externalDeviceIdentifier3?.label}
                  id="externalDeviceIdentifier3"
                  value={externalId3}
                  validationPattern={externalDeviceIdentifier3?.pattern}
                  placeholder={externalDeviceIdentifier3?.placeholder}
                  required
                  onValueChange={(_id, value, isError): void => {
                    setExternalId3(value);
                    setExternalId3Error(isError);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", ml: 2, mb: 2, mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: theme.palette.common.white }}
          onClick={(): void => {
            onContinue({ keepExistingPatient, externalId1, externalId2, externalId3 });
            handleClose();
          }}
          data-testid={TestIds.DeviceActivateDialog.ContinueButton}
          startIcon={<PowerSettingsNewIcon />}
          disabled={disableButton}
        >
          {translations.deviceActions.buttons.activateDeviceButton()}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{ borderColor: theme.palette.primary.main, color: theme.palette.primary.main }}
          data-testid={TestIds.DeviceActivateDialog.DiscardButton}
        >
          {translations.common.buttons.cancel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateDeviceDialog;
