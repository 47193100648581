/* Copyright */
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { Device, PeripheralInfo } from "@sade/data-access";
import * as React from "react";
import { translations } from "../../../../generated/translationHelper";
import TestIds from "../../../../test-ids/test-ids";
import DevicePeripheralsCompanionPendant from "./device-peripherals-companion-pendant";
import DevicePeripheralsMHealthDevice from "./device-peripherals-mhealth-device";

type DevicePeripheralsDialogProps = {
  open: boolean;
  peripherals: PeripheralInfo[];
  device?: Device;
  onClose: () => void;
  onRefresh: () => void;
};

const DevicePeripheralsDialog: React.FC<DevicePeripheralsDialogProps> = ({
  open,
  peripherals,
  device,
  onClose,
  onRefresh,
}) => {
  const handleCloseClick = (): void => {
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        scroll="paper"
        maxWidth="md"
        fullWidth
        data-testid={TestIds.DevicePeripheralsDialog.Dialog}
      >
        <DialogTitle variant="h5">
          {translations.devicePeripherals.texts.devicePeripherals()}
          <IconButton
            aria-label="close"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CloseButton}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DevicePeripheralsCompanionPendant peripherals={peripherals} device={device} onRefresh={onRefresh} />
          <DevicePeripheralsMHealthDevice peripherals={peripherals} device={device} onRefresh={onRefresh} />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{ justifyContent: "start", m: 2 }}>
          <Button
            variant="outlined"
            onClick={handleCloseClick}
            data-testid={TestIds.DevicePeripheralsDialog.CancelButton}
          >
            {translations.common.texts.close()}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DevicePeripheralsDialog;
