/* Copyright*/
import { NumeraDevice } from "../NumeraDeviceBase";
export class Libris3 extends NumeraDevice {
    constructor(backend, params) {
        super(Libris3.type, backend, params);
    }
    static instanceOf(device) {
        return device instanceof Libris3;
    }
}
Libris3.type = "Libris3";
